import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import useSiteContext from 'hooks/useSiteContext';
import Button from 'uikit/Button';
import Icon from 'uikit/Icon';

import GoogleClassRoomLogo from './images/google-classroom.svg';
import MicrosoftTeamsLogo from './images/Microsoft_Office_Teams_(2018–present).svg';
import WhatsappLogo from './images/whatsapp.svg';
import styles from './Social.module.scss';

const cx = classNames.bind(styles);

const Social = ({ className = null, course }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/Cover/CourseShare/Social',
  });

  const { isInTeams, isOnMobile } = useSiteContext();

  const linkPath = course.moodle.isMoodle ? 'moodle' : 'join';

  const link = `${process.env.REACT_APP_WEB_URL}/${linkPath}/${course.accessCode}`;

  const whatsappLink = isOnMobile ? 'whatsapp://' : 'https://api.whatsapp.com/';

  return (
    <ul className={cx('social', className)}>
      {!isInTeams && (
        <li>
          <Button
            as="a"
            className={cx('social-button')}
            href={`https://teams.microsoft.com/share?url=${encodeURIComponent(
              `${link}?from=3`,
            )}&msgText=${encodeURIComponent(t('join-on-wooflash'))}`}
            icon={
              <img className={cx('teams')} alt="" src={MicrosoftTeamsLogo} />
            }
            rel="noopener noreferrer"
            size="large"
            target="_blank"
            tooltip={t('teams')}
          />
        </li>
      )}
      <li>
        <Button
          as="a"
          className={cx('social-button')}
          href={`https://classroom.google.com/u/0/share?url=${encodeURIComponent(
            `${link}?from=4`,
          )}`}
          icon={
            <img className={cx('classroom')} alt="" src={GoogleClassRoomLogo} />
          }
          rel="noopener noreferrer"
          size="large"
          target="_blank"
          tooltip={t('classroom')}
        />
      </li>
      <li>
        <Button
          as="a"
          className={cx('social-button', 'facebook')}
          href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            `${link}?from=5`,
          )}&text=${encodeURIComponent(t('join-on-wooflash'))}`}
          icon={<Icon name="facebook-f" variant="brand" />}
          rel="noopener noreferrer"
          size="large"
          target="_blank"
          tooltip={t('facebook')}
        />
      </li>
      <li>
        <Button
          as="a"
          className={cx('social-button', 'twitter')}
          href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
            `${link}?from=6`,
          )}&text=${encodeURIComponent(t('join-on-wooflash'))}`}
          icon={<Icon name="twitter" variant="brand" />}
          rel="noopener noreferrer"
          size="large"
          target="_blank"
          tooltip={t('twitter')}
        />
      </li>
      <li>
        <Button
          as="a"
          className={cx('social-button', 'whatsapp')}
          href={`${whatsappLink}send?text=${encodeURIComponent(
            t('join-on-wooflash-link', {
              link: `${link}?from=7`,
            }),
          )}`}
          icon={<img className={cx('whatsapp')} alt="" src={WhatsappLogo} />}
          rel="noopener noreferrer"
          size="large"
          target="_blank"
          tooltip={t('whatsapp')}
        />
      </li>
      {isOnMobile && (
        <li>
          <Button
            as="a"
            className={cx('social-button', 'messenger')}
            href={`fb-messenger://share?link=${encodeURIComponent(
              `${link}?from=8`,
            )}`}
            icon={<Icon name="facebook-messenger" variant="brand" />}
            rel="noopener noreferrer"
            size="large"
            target="_blank"
            tooltip={t('messenger')}
          />
        </li>
      )}
    </ul>
  );
};

Social.propTypes = {
  className: PropTypes.string,
  course: PropTypes.object.isRequired,
};

export default Social;
