import PropTypes from 'prop-types';
import QRCode from 'qrcode';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import EDITOR_STATUSES from 'constants/editorStatuses';
import { mutationEditorClear } from 'gql/mutations/editor';
import { useQueryLocalEditor } from 'gql/queries/local';
import { useQueryMe } from 'gql/queries/me';

import GroupShare from './GroupShare';

const GroupShareContainer = ({ course, ...props }) => {
  const { t } = useTranslation();
  const {
    data: {
      editor: { status: editorStatus, groupEditing },
    },
  } = useQueryLocalEditor();
  const {
    data: { me },
  } = useQueryMe();
  const isCourseInvitationRestricted =
    me?.organization?.settings?.isCourseInvitationRestricted;
  const isModalOpen = editorStatus === EDITOR_STATUSES.GROUP_SHARE;
  const [isDisplayed, setIsDisplayed] = useState(true);
  const canvasRef = useCallback(
    (element) => {
      if (element && groupEditing) {
        QRCode.toCanvas(
          element,
          `${process.env.REACT_APP_WEB_URL}/join/${course.accessCode}/${groupEditing.id}`,
          (err) => {
            if (err) {
              setIsDisplayed(false);
            }
          },
        );
      }
    },
    [course, groupEditing],
  );
  return (
    <GroupShare
      canvasRef={canvasRef}
      course={course}
      group={groupEditing}
      handleClose={mutationEditorClear}
      isCourseInvitationRestricted={isCourseInvitationRestricted}
      isDisplayed={isDisplayed}
      isModalOpen={isModalOpen}
      t={t}
      {...props}
    />
  );
};

GroupShareContainer.propTypes = {
  course: PropTypes.object.isRequired,
};

export default GroupShareContainer;
