import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import CourseJoin from 'components/Shared/CourseJoin';
import LoadingScreen from 'components/Shared/LoadingScreen';
import MenuContainer from 'components/Shared/MenuContainer';
import Button from 'uikit/Button';
import Icon from 'uikit/Icon';
import Menu, { MenuItem } from 'uikit//Menu';

import CourseCard from './CourseCard';
import CoursesGAR from './CoursesGAR';
import Finder from './Finder';
import FolderDelete from './FolderDelete';
import FolderEdit from './FolderEdit';
import Empty from './Empty';
import styles from './Dashboard.module.scss';

const cx = classNames.bind(styles);

const Dashboard = ({
  courses,
  dashboardDisplayStyle,
  filter,
  folders,
  handleCourseCreate,
  handleCourseJoinOpen,
  handleCourseJoinClose,
  handleDisplayStyleChange,
  handleFolderCreate,
  handleFilterChange,
  handleSearchChange,
  isCourseCreating,
  isCourseJoinDisplayed,
  isEditionDisabled,
  isEmpty,
  isGAR,
  isLoading,
  isRoleStudent,
  me,
  pinnedCourses,
  search,
  t,
}) => (
  <MenuContainer
    header={
      !isEditionDisabled && (
        <>
          <h1 className={cx('dashboard-title')}>{t('Dashboard/title')}</h1>
          <div className={cx('search')}>
            <Icon name="search" />
            <input
              aria-label={t('Dashboard/aria-search')}
              className={cx('search-input')}
              onChange={handleSearchChange}
              placeholder={t('Dashboard/search-placeholder')}
              size="15"
              type="text"
              value={search}
            />
          </div>
        </>
      )
    }
    title={
      <p className={cx('dashboard-hello')}>
        {t('Shared/MenuContainer/hello')}
        <span>{me.firstName}</span> 👋
      </p>
    }
  >
    {isLoading ? (
      <LoadingScreen />
    ) : (
      <>
        {pinnedCourses.length > 0 && !isEditionDisabled && (
          <div className={cx('quick')}>
            <div className={cx('section-title-container')}>
              <h2 className={cx('section-title')}>
                {t('Dashboard/quick-access')}
              </h2>
              <p className={cx('number')}>{pinnedCourses.length}</p>
            </div>
            <div className={cx('favorites-placeholder')}>
              <div className={cx('favorites-container')}>
                <div className={cx('favorites')}>
                  {pinnedCourses.map((course) => (
                    <CourseCard course={course} key={course.id} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
        <div className={cx('section-title-container-small')}>
          {!isEditionDisabled && (
            <h2 className={cx('section-title')}>
              {search &&
                `${t('Dashboard/search-in', {
                  search,
                })} `}
              {filter === 'all'
                ? t('Dashboard/title-all')
                : filter === 'teacher'
                ? t('Dashboard/title-teacher')
                : t('Dashboard/title-student')}
            </h2>
          )}
          <div className={cx('section-header')}>
            <div className={cx('actions')}>
              {!isEditionDisabled && (
                <>
                  <button
                    className={cx('action-create')}
                    data-testid="create-course-button"
                    onClick={handleCourseCreate}
                    type="button"
                  >
                    <div className={cx('icon-container')}>
                      <Icon
                        className={cx('icon')}
                        name={isCourseCreating ? 'spinner' : 'plus'}
                      />
                    </div>
                    {t('Dashboard/create-course')}
                  </button>
                  <button
                    className={cx('action-join')}
                    data-testid="join-course-button"
                    icon="user-plus"
                    id="course-join-button"
                    onClick={handleCourseJoinOpen}
                    type="button"
                  >
                    <div
                      className={cx('icon-container', 'icon-container-join')}
                    >
                      <Icon
                        className={cx('icon', 'icon-join')}
                        name="book-medical"
                      />
                    </div>
                    {t('Dashboard/join-course')}
                  </button>
                </>
              )}
            </div>
            <div className={cx('options')}>
              <Menu
                className={cx('plus-menu')}
                toggle={<Button icon="plus" intent="primary" />}
              >
                <MenuItem icon="plus" onClick={handleCourseCreate}>
                  {t('Dashboard/create-course')}
                </MenuItem>
                <MenuItem icon="user-plus" onClick={handleCourseJoinOpen}>
                  {t('Dashboard/join-course')}
                </MenuItem>
                {!isEmpty && (
                  <MenuItem icon="folder-plus" onClick={handleFolderCreate}>
                    {t('Dashboard/add-folder')}
                  </MenuItem>
                )}
              </Menu>
              {!isEditionDisabled && (
                <Menu
                  toggle={
                    <Button
                      icon="sliders-h"
                      intent={filter !== 'all' ? 'primary' : 'white'}
                      tooltip={t('Dashboard/filter')}
                    />
                  }
                >
                  <MenuItem
                    isActive={filter === 'all'}
                    onClick={() => handleFilterChange('all')}
                  >
                    {t('Dashboard/filter-all')}
                  </MenuItem>
                  <MenuItem
                    isActive={filter === 'teacher'}
                    onClick={() => handleFilterChange('teacher')}
                  >
                    {t('Dashboard/filter-teacher')}
                  </MenuItem>
                  <MenuItem
                    isActive={filter === 'student'}
                    onClick={() => handleFilterChange('student')}
                  >
                    {t('Dashboard/filter-student')}
                  </MenuItem>
                </Menu>
              )}
              <Button
                className={cx('display')}
                icon={dashboardDisplayStyle === 'list' ? 'th' : 'list'}
                intent="white"
                onClick={handleDisplayStyleChange}
                tooltip={
                  dashboardDisplayStyle === 'list'
                    ? t('Dashboard/display-grid')
                    : t('Dashboard/display-list')
                }
              />
              {dashboardDisplayStyle === 'list' &&
                !isEmpty &&
                !isEditionDisabled && (
                  <Button
                    className={cx('display', 'folder-button')}
                    icon="folder-plus"
                    intent="white"
                    onClick={handleFolderCreate}
                    tooltip={t('Dashboard/add-folder')}
                  />
                )}
            </div>
          </div>
        </div>
        {isEmpty ? (
          isEditionDisabled ? null : (
            <Empty />
          )
        ) : (
          <div className={cx('courses', `style-${dashboardDisplayStyle}`)}>
            {dashboardDisplayStyle === 'list' ? (
              <Finder courses={courses} folders={folders} />
            ) : (
              courses.map((course) => (
                <CourseCard course={course} key={course.id} />
              ))
            )}
          </div>
        )}
        {isGAR && isRoleStudent && <CoursesGAR />}
      </>
    )}
    <CourseJoin
      isModalOpen={isCourseJoinDisplayed}
      handleModalClose={handleCourseJoinClose}
    />
    <FolderEdit />
    <FolderDelete courses={courses} />
  </MenuContainer>
);

Dashboard.propTypes = {
  courses: PropTypes.arrayOf(PropTypes.object).isRequired,
  dashboardDisplayStyle: PropTypes.string.isRequired,
  filter: PropTypes.string.isRequired,
  folders: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleCourseCreate: PropTypes.func.isRequired,
  handleCourseJoinClose: PropTypes.func.isRequired,
  handleCourseJoinOpen: PropTypes.func.isRequired,
  handleFolderCreate: PropTypes.func.isRequired,
  handleDisplayStyleChange: PropTypes.func.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  handleSearchChange: PropTypes.func.isRequired,
  isCourseCreating: PropTypes.bool.isRequired,
  isCourseJoinDisplayed: PropTypes.bool.isRequired,
  isEditionDisabled: PropTypes.bool,
  isEmpty: PropTypes.bool.isRequired,
  isGAR: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isRoleStudent: PropTypes.bool.isRequired,
  me: PropTypes.object,
  pinnedCourses: PropTypes.arrayOf(PropTypes.object).isRequired,
  search: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

Dashboard.defaultProps = {
  me: undefined,
  isEditionDisabled: undefined,
};

export default Dashboard;
