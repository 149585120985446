import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import ChapterCard from './ChapterCard/ChapterCard';
import styles from './Chapters.module.scss';

const cx = classNames.bind(styles);

const Chapters = ({ course }) => (
  <div>
    {course.chapters.map((chapter) => (
      <ChapterCard
        chapter={chapter}
        className={cx('chapter')}
        course={course}
        key={chapter.id}
      />
    ))}
  </div>
);

Chapters.propTypes = {
  course: PropTypes.object.isRequired,
};

export default Chapters;
