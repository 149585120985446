import { useField } from 'formik';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import Editor from './Editor';

const EditorContainer = (props) => {
  const { t } = useTranslation();
  const [{ value: image }, , { setValue: setImage }] = useField('image');
  const handleImageDelete = useCallback(() => {
    setImage(null);
  }, [setImage]);
  const [{ value: zones }, , { setValue: setZones }] = useField('zones');
  const [{ value: legends }, , { setValue: setLegends }] = useField('legends');
  const handleLegendAdd = useCallback(() => {
    const newLegend = {
      x: 0.06,
      y: 0.06,
      content: [],
    };
    setLegends([...legends, newLegend]);
  }, [setLegends, legends]);
  const handleLegendDelete = useCallback(
    (index) => {
      const filteredlegends = legends.filter(
        (item, itemIndex) => itemIndex !== index,
      );
      setLegends(filteredlegends);
    },
    [setLegends, legends],
  );
  const handleZoneAdd = useCallback(() => {
    const newZone = {
      h: 0.1,
      r: null,
      w: 0.1,
      x: 0.01,
      y: 0.01,
      shapeType: 'rectangle',
    };
    setZones([...zones, newZone]);
  }, [setZones, zones]);
  return (
    <Editor
      handleImageDelete={handleImageDelete}
      handleLegendAdd={handleLegendAdd}
      handleLegendDelete={handleLegendDelete}
      handleLegendsSet={setLegends}
      handleZoneAdd={handleZoneAdd}
      handleZonesSet={setZones}
      image={image}
      legends={legends}
      t={t}
      zones={zones}
      {...props}
    />
  );
};

EditorContainer.validationSchema = (t) =>
  yup.object().shape({
    tags: yup.array().of(yup.string().trim().lowercase().max(256)).compact(),
    title: yup
      .string()
      .trim()
      .transform((v) => v || t('Questions/LabelOnImage/Editor/default-title')),
    image: yup
      .string()
      .trim()
      .required(t('Questions/LabelOnImage/Editor/image-error-required'))
      .url(t('Questions/LabelOnImage/Editor/image-error-required')),
    zones: yup.array().of(
      yup.object().shape({
        shapeType: yup.string().trim().required().oneOf(['rectangle']),
        x: yup.number().required(),
        y: yup.number().required(),
        w: yup.number().nullable(),
        h: yup.number().nullable(),
        r: yup.number().nullable(),
      }),
    ),
    legends: yup
      .array()
      .of(
        yup.object().shape({
          x: yup.number().required(),
          y: yup.number().required(),
          content: yup
            .array()
            .of(yup.string().trim().required())
            .test(
              'length',
              t('Questions/LabelOnImage/Editor/answer-error-required'),
              (content) => content.length >= 1,
            ),
        }),
      )
      .test(
        'length',
        t('Questions/LabelOnImage/Editor/legends-error-required'),
        (legends) => legends.length >= 1,
      ),
    feedback: yup.string().trim(),
    ignoreCase: yup.boolean().default(true),
    ignoreAccents: yup.boolean().default(true),
    ignorePunctuation: yup.boolean().default(true),
  });

export default EditorContainer;
