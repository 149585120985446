import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import sanitize from 'lib/sanitize';
import Button from 'uikit/Button';
import Icon from 'uikit/Icon';
import FormattedText from 'uikit/FormattedText';

import styles from './Recto.module.scss';

const cx = classNames.bind(styles);

const Recto = ({
  answer,
  className,
  dndRef,
  handleMoveDown,
  handleMoveUp,
  handleSortEnd,
  isDisabled,
  question,
  t,
  theme,
}) => (
  <div className={cx('container', className, `theme-${theme}`)}>
    <FormattedText as="h1" className={cx('title')}>
      {question.title}
    </FormattedText>
    {!isDisabled && (
      <div className={cx('instructions')}>
        {t('Questions/Sorting/Recto/instructions')}
      </div>
    )}
    <DragDropContext
      onDragEnd={handleSortEnd}
      sensors={[
        (api) => {
          // eslint-disable-next-line no-param-reassign
          dndRef.current = api;
        },
      ]}
    >
      <Droppable droppableId="items">
        {(providedItems, snapshotItems) => (
          <div
            className={cx('zone', {
              isDraggingOver: snapshotItems.isDraggingOver,
            })}
            ref={providedItems.innerRef}
            {...providedItems.droppableProps}
          >
            {answer.map((idx, index) => {
              const item = question.content.items.find((i) => i.idx === idx);
              return (
                <Draggable
                  aria-describedby="aria-drag-description"
                  draggableId={index.toString()}
                  index={index}
                  isDragDisabled={isDisabled}
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                >
                  {(providedItem, snapshotItem) => (
                    <div
                      className={cx('item', {
                        isDisabled,
                        isDragging: snapshotItem.isDragging,
                      })}
                      ref={providedItem.innerRef}
                      {...providedItem.draggableProps}
                      {...providedItem.dragHandleProps}
                    >
                      <p
                        className={cx('aria-label')}
                        id="aria-drag-description"
                      >
                        {t('Dashboard/CourseItem/aria-drag-description')}
                      </p>
                      <div className={cx('drag')} role="button">
                        <Icon name="arrows-v" />
                      </div>
                      <FormattedText className={cx('content')}>
                        {item?.title}
                      </FormattedText>
                      <Button
                        aria-label={t('Questions/Sorting/Recto/move-down', {
                          name: sanitize.string(item?.title),
                        })}
                        className={cx('action')}
                        icon="arrow-down"
                        isDisabled={
                          isDisabled ||
                          index === question.content.items.length - 1
                        }
                        onClick={() => handleMoveDown(index)}
                      />
                      <Button
                        aria-label={t('Questions/Sorting/Recto/move-up', {
                          name: sanitize.string(item?.title),
                        })}
                        className={cx('action')}
                        icon="arrow-up"
                        isDisabled={isDisabled || index === 0}
                        onClick={() => handleMoveUp(index)}
                      />
                    </div>
                  )}
                </Draggable>
              );
            })}
            {providedItems.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  </div>
);

Recto.propTypes = {
  answer: PropTypes.arrayOf(PropTypes.number).isRequired,
  className: PropTypes.string,
  dndRef: PropTypes.object.isRequired,
  handleMoveDown: PropTypes.func.isRequired,
  handleMoveUp: PropTypes.func.isRequired,
  handleSortEnd: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  question: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
};

Recto.defaultProps = {
  className: undefined,
  isDisabled: false,
};

export default Recto;
