import * as DOMPurify from 'dompurify';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { LaTeX2HTML, ASCIIMath2HTML, highlight } from 'helpers/text';
import useSiteContext from 'hooks/useSiteContext';
import { reportError } from 'lib/tracking';

import FormattedText from './FormattedText';

function handleImageClick(evt) {
  evt.preventDefault();
  evt.stopPropagation();
  window.dispatchEvent(
    new CustomEvent('lightbox', {
      detail: {
        src: evt.target.src,
      },
    }),
  );
}

const FormattedTextContainer = ({ children, isAlreadyFormatted, ...props }) => {
  const { isGAR, isOnMobile } = useSiteContext();
  const [ref, setRef] = useState(null);
  useEffect(() => {
    if (ref) {
      const images = ref.getElementsByTagName('img');
      // eslint-disable-next-line no-restricted-syntax
      for (const image of images) {
        image.addEventListener('click', handleImageClick);
      }
      const links = ref.getElementsByTagName('a');
      // eslint-disable-next-line no-restricted-syntax
      for (const link of links) {
        link.target = '_blank';
        if (isGAR) {
          if (
            !(link.href || '').startsWith(
              'https://sortie-confiance.gar.education.fr',
            )
          ) {
            link.href = `https://sortie-confiance.gar.education.fr?urlDest=${encodeURIComponent(
              link.href,
            )}`;
          }
        }
      }
    }
  }, [children, isGAR, ref]);
  const html = useMemo(() => {
    if (isAlreadyFormatted) {
      return undefined;
    }
    const purified = DOMPurify.sanitize(children, {
      ADD_TAGS: ['iframe'],
    });
    return purified
      .replace(/\$(.*?)\$/g, LaTeX2HTML)
      .replace(/`(.*?)`/g, ASCIIMath2HTML)
      .replace(/<math>(.*?)<\/math>/g, ASCIIMath2HTML)
      .replace(/&lt;math&gt;(.*?)&lt;\/math&gt;/g, ASCIIMath2HTML)
      .replace(/<pre><code([^>]*)>(.+?)<\/code><\/pre>/gs, highlight);
  }, [children, isAlreadyFormatted]);
  const handleRefMount = useCallback((r) => {
    setRef(r);
  }, []);
  // In mobile devices the thumbnail of the video is not automatically loaded.
  // So we need to force the loading of the video by playing it, to get at least the first frame of the video.
  // Then we pause it, so it doesn't autoplay.
  useEffect(() => {
    if (!isOnMobile) return;
    const videos = window.document.querySelectorAll('video');
    let videosArray = Array.from(videos);
    videosArray.map(async (video) => {
      // A first interaction is needed by the browser to allow the video to play.
      video.muted = true;
      const playPromise = video.play();
      if (playPromise !== undefined) {
        playPromise
          .then((_) => {
            video.pause();
            video.muted = false;
          })
          .catch((err) => {
            reportError('video', 'mobile loading', err);
          });
      }
    });
  }, [isOnMobile]);
  return (
    <FormattedText
      handleRefMount={handleRefMount}
      html={html}
      isAlreadyFormatted={isAlreadyFormatted}
      {...props}
    >
      {children}
    </FormattedText>
  );
};

FormattedTextContainer.propTypes = {
  children: PropTypes.string,
  isAlreadyFormatted: PropTypes.bool,
};

FormattedTextContainer.defaultProps = {
  children: '',
  isAlreadyFormatted: false,
};

export default FormattedTextContainer;
