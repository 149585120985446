import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import useModal from 'hooks/useModal';

import InputImage from './InputImage';

const InputImageContainer = ({ name, ...props }) => {
  const { t } = useTranslation();
  const { isModalOpen, handleModalClose, handleModalOpen } = useModal(false);
  const [, , { setValue }] = useField(name);
  const handleSelect = useCallback(
    (url) => {
      setValue(url);
      handleModalClose();
    },
    [handleModalClose, setValue],
  );
  return (
    <InputImage
      handleModalClose={handleModalClose}
      handleModalOpen={handleModalOpen}
      handleSelect={handleSelect}
      isModalOpen={isModalOpen}
      name={name}
      t={t}
      {...props}
    />
  );
};

InputImageContainer.propTypes = {
  name: PropTypes.string.isRequired,
};

export default InputImageContainer;
