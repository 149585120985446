import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import storeJS from 'store';
import { useTranslation } from 'react-i18next';

import LoadingScreen from 'components/Shared/LoadingScreen';
import { useQueryResultsStudyClassificationMatrixStatistics } from 'gql/queries/results';
import Button from 'uikit/Button';
import CleanedText from 'uikit/CleanedText';
import Icon from 'uikit/Icon';

import initiatePlot from './initiatePlot';
import Pagination from './Pagination/Pagination';
import styles from './ClassificationMatrix.module.scss';

const cx = classNames.bind(styles);

const INITIAL_LIMIT = 20;

const ClassificationMatrix = ({
  className = null,
  course,
  groupId = null,
  matrix,
  studentId = null,
  totalStudents,
  type,
}) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/Statistics/ClassificationMatrix',
  });

  const { getResultsStudyClassificationMatrixStatistics, data } =
    useQueryResultsStudyClassificationMatrixStatistics();

  const [isSectionOpen, SetIsSectionOpen] = useState(true);

  const [selection, setSelection] = useState(null);

  const [isMatrixLoading, setIsMatrixLoading] = useState(false);

  const [matrixData, setMatrixData] = useState(matrix);

  const [pageSize, setPageSize] = useState(INITIAL_LIMIT);

  const [offset, setOffset] = useState(0);

  useEffect(() => {
    setMatrixData(matrix);
  }, [matrix]);

  useEffect(() => {
    if (!!data && !!data.resultsStudyClassificationMatrixStatistics) {
      setMatrixData(
        data.resultsStudyClassificationMatrixStatistics.classificationMatrix,
      );
    }
  }, [data]);

  const handlePlotInit = useCallback(
    (ref) => initiatePlot({ ref, matrixData, setSelection, t }),
    [matrixData, t],
  );

  const handlePageLoad = async (loadOffset, loadPageSize) => {
    try {
      setIsMatrixLoading(true);
      await getResultsStudyClassificationMatrixStatistics({
        variables: {
          courseId: course.id,
          offset: loadOffset,
          limit: loadPageSize,
          studentId,
          groupId,
        },
      });
      setOffset(loadOffset);
    } catch (err) {
      reportError('classificationMatrix', 'page load', err);
    } finally {
      setIsMatrixLoading(false);
    }
  };

  const handlePageSizeUpdate = async (event) => {
    const newPageSize = Number(event.target.value);
    const newOffset = Math.floor(offset / newPageSize) * newPageSize;
    await handlePageLoad(newOffset, newPageSize);
    setPageSize(newPageSize);
    setOffset(newOffset);
  };

  const handleSectionToggle = () => {
    SetIsSectionOpen(!isSectionOpen);
  };

  const nStudents =
    type === 'full'
      ? totalStudents
      : type === 'group'
      ? course.groups.find((group) => group.id === groupId).students.length
      : 1;

  const nPages =
    (nStudents / pageSize) % 1 === 0
      ? Math.floor(nStudents / pageSize)
      : Math.floor(nStudents / pageSize) + 1;

  const currentPage = Math.floor(offset / pageSize) + 1;

  const canNextPage = currentPage < nPages;

  const canPreviousPage = currentPage > 1;

  return (
    <div
      aria-label={t('aria-matrix-description')}
      className={cx('container', className, { isSectionOpen })}
    >
      <button
        className={cx('header')}
        onClick={handleSectionToggle}
        type="button"
      >
        <div className={cx('title-container')}>
          <Icon className={cx('title-icon')} name="chart-mixed" />
          <h2 className={cx('title')}>{t('title')}</h2>
        </div>
        <div className={cx('header-right')}>
          <Button
            as="a"
            className={cx('download-button')}
            href={`${
              process.env.REACT_APP_API_URL
            }/rest/study-stats/excel?courseId=${
              course.id
            }&authToken=${storeJS.get('token')}`}
            target="blank"
          >
            <Icon className={cx('download-button-icon')} name="download" />
            <span className={cx('download-button-text')}>{t('download')}</span>
          </Button>
          <Icon
            className={cx('toggle-button')}
            name={isSectionOpen ? 'chevron-up' : 'chevron-down'}
          />
        </div>
      </button>
      {isSectionOpen && (
        <div className={cx('chart-container')}>
          <Pagination
            canNextPage={canNextPage}
            canPreviousPage={canPreviousPage}
            handlePageLoad={handlePageLoad}
            handlePageSizeUpdate={handlePageSizeUpdate}
            nPages={nPages}
            nStudents={nStudents}
            offset={offset}
            pageSize={pageSize}
          />
          <div className={cx('chart')}>
            {isMatrixLoading ? <LoadingScreen /> : <svg ref={handlePlotInit} />}
          </div>
          <div
            className={cx('tooltip', {
              isVisible: !!selection,
            })}
          >
            {selection && (
              <div>
                <p className={cx('student')}>{selection.student}</p>
                <CleanedText className={cx('question')}>
                  {selection.question}
                </CleanedText>
                <p className={cx('stat', 'total')}>
                  <span>{selection.trials}</span>
                  {t('trials', {
                    count: selection.trials,
                  })}
                </p>
                <p className={cx('stat', 'correct')}>
                  <span>{selection.correct}</span>
                  {t('correct', {
                    count: selection.correct,
                  })}
                </p>
                <p className={cx('stat', 'wrong')}>
                  <span>{selection.wrong}</span>
                  {t('wrong', {
                    count: selection.wrong,
                  })}
                </p>
                <p className={cx('stat', 'unknown')}>
                  <span>{selection.unknown}</span>
                  {t('unknown', {
                    count: selection.unknown,
                  })}
                </p>
                <p className={cx('history-title')}>{t('history')}:</p>
                <p className={cx('history')}>
                  {selection.history.map((answer, i) =>
                    answer === 'correct' ? (
                      <Icon
                        className={cx('icon', 'correct')}
                        key={`correct-icon-${i}`}
                        name="check"
                      />
                    ) : answer === 'wrong' ? (
                      <Icon
                        className={cx('icon', 'wrong')}
                        key={`wrong-icon-${i}`}
                        name="times"
                      />
                    ) : answer === 'unknown' ? (
                      <Icon
                        className={cx('icon', 'unknown')}
                        key={`unknown-icon-${i}`}
                        name="question"
                      />
                    ) : null,
                  )}
                </p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

ClassificationMatrix.propTypes = {
  className: PropTypes.string,
  course: PropTypes.object.isRequired,
  groupId: PropTypes.string,
  matrix: PropTypes.object.isRequired,
  studentId: PropTypes.string,
  totalStudents: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
};

export default ClassificationMatrix;
