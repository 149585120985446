import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useMutationCoursePin } from 'gql/mutations/course';
import useSiteContext from 'hooks/useSiteContext';

import CourseItem from './CourseItem';

const CourseItemContainer = ({ course, ...props }) => {
  const { t } = useTranslation();
  const { isEditionDisabled } = useSiteContext();
  const mutationCoursePin = useMutationCoursePin();
  const handleCoursePin = useCallback(
    async (evt) => {
      try {
        evt.preventDefault();
        evt.stopPropagation();
        await mutationCoursePin(course);
      } catch (err) {
        //
      }
    },
    [course, mutationCoursePin],
  );
  return (
    <CourseItem
      course={course}
      handleCoursePin={handleCoursePin}
      isEditionDisabled={isEditionDisabled}
      t={t}
      {...props}
    />
  );
};

CourseItemContainer.propTypes = {
  course: PropTypes.object.isRequired,
};

export default CourseItemContainer;
