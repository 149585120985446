import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'uikit/Button';
import InputText from 'uikit/InputText';
import InputTimer from 'uikit/InputTimer';

import VideoPreview from '../VideoPreview';
import styles from './VideoLink.module.scss';

const cx = classNames.bind(styles);

const VideoLink = ({
  'data-testid': dataTestId,
  endTime,
  handleLinkChange,
  handleLinkReset,
  setEndTime,
  setStartTime,
  startTime,
  t,
  videoLink,
}) => (
  <>
    <div className={cx('link-input-container')}>
      <InputText
        aria-label={t('uikit/VideoPicker/VideoLink/aria-link')}
        className={cx('link')}
        data-testid={dataTestId}
        name="videoLink"
        onChange={handleLinkChange}
        placeholder={t('uikit/VideoPicker/VideoLink/link-placeholder')}
      />
      {videoLink && (
        <Button
          aria-label={t('uikit/VideoPicker/VideoLink/delete')}
          className={cx('link-reset-button')}
          icon="times"
          onClick={handleLinkReset}
        />
      )}
    </div>
    {videoLink && (
      <>
        {videoLink.startsWith('https://youtu.be/') ? (
          <div className={cx('video-preview')}>
            <iframe
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className={cx('iframe')}
              frameBorder="0"
              height="315"
              src={videoLink
                .replace('https://youtu.be/', 'https://www.youtube.com/embed/')
                .replace(/t=(\d+?)[s]/, 'start=$1')}
              title="YouTube video player"
              width="560"
            />
          </div>
        ) : (
          <VideoPreview
            video={
              videoLink.startsWith('https://www.youtube.com/watch?v=')
                ? videoLink
                    .replace(
                      'https://www.youtube.com/watch?v=',
                      'https://www.youtube.com/embed/',
                    )
                    .replace(/&t=(\d+?)[s]/, '?start=$1')
                : videoLink
            }
          />
        )}
      </>
    )}
    {videoLink &&
      (videoLink.startsWith('https://youtu.be/') ||
        videoLink.startsWith('https://www.youtube.com/watch?v=')) && (
        <div className={cx('timer')}>
          <InputTimer
            className={cx('input-timer')}
            label={t('uikit/VideoPicker/VideoLink/start-time-label')}
            name="startTime"
            setTotalSeconds={setStartTime}
            totalSeconds={startTime}
          />
          <InputTimer
            className={cx('input-timer')}
            label={t('uikit/VideoPicker/VideoLink/end-time-label')}
            name="endTime"
            setTotalSeconds={setEndTime}
            totalSeconds={endTime}
          />
        </div>
      )}
  </>
);

VideoLink.propTypes = {
  'data-testid': PropTypes.string,
  endTime: PropTypes.number,
  handleLinkChange: PropTypes.func.isRequired,
  handleLinkReset: PropTypes.func.isRequired,
  setEndTime: PropTypes.func.isRequired,
  setStartTime: PropTypes.func.isRequired,
  startTime: PropTypes.number,
  t: PropTypes.func.isRequired,
  videoLink: PropTypes.string,
};

VideoLink.defaultProps = {
  'data-testid': undefined,
  endTime: undefined,
  startTime: undefined,
  videoLink: undefined,
};

export default VideoLink;
