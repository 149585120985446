import classNames from 'classnames/bind';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import * as yup from 'yup';

import LanguageSelector from 'components/Shared/LanguageSelector';
import { useMutationMeTag, useMutationMeUpdate } from 'gql/mutations/me';
import extract from 'helpers/extract';
import { trackEvent, reportError } from 'lib/tracking';
import Button from 'uikit/Button';
import InputText from 'uikit/InputText';

import ILLUSTRATION from './images/illustration.svg';
import styles from './Profile.module.scss';

const cx = classNames.bind(styles);

const Profile = ({ handleStepNext, me, onBack }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'App/Onboarding/Profile',
  });

  const location = useLocation();

  const mutationMeUpdate = useMutationMeUpdate();

  const mutationMeTag = useMutationMeTag();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        firstName: yup
          .string()
          .required(t('first-name-error-required'))
          .trim()
          .max(256, t('first-name-error-required')),
        lastName: yup
          .string()
          .required(t('last-name-error-required'))
          .trim()
          .max(256, t('last-name-error-required')),
      }),
    [t],
  );

  const isExpert = location.pathname.includes('/expert/');

  const handleTourClose = async () => {
    try {
      await mutationMeTag(['tour:1']);
      trackEvent('tag', 'tour:1');
    } catch (err) {
      reportError('tag', 'tour:1', err);
    }
  };

  const handleProfileUpdate = async (values, form) => {
    try {
      form.setStatus(null);
      const variables = extract(values, validationSchema);
      await mutationMeUpdate(variables);
      if (isExpert) {
        await handleTourClose();
      }
      handleStepNext();
      trackEvent('onboarding', 'profile-info');
    } catch (err) {
      form.setStatus(err.message);
      reportError('onboarding', 'profile-info', err);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={me}
      onSubmit={handleProfileUpdate}
      validationSchema={validationSchema}
    >
      {({ isSubmitting, status }) => (
        <Form className={cx('container')}>
          <div
            className={cx('form-container')}
            // Specific style applied to the form container to handle height issue on mobile
            // src: https://chanind.github.io/javascript/2019/09/28/avoid-100vh-on-mobile-web.html
            style={{ height: window.innerHeight }}
          >
            <div className={cx('header')}>
              <Button
                aria-label={t('back')}
                className={cx('back')}
                icon="arrow-left"
                intent="gray"
                onClick={onBack}
              />
              <div className={cx('points')}>
                {[1, 2, 3, 4, 5, 6].map((element) => (
                  <div className={cx('point')} key={element} />
                ))}
              </div>
              <LanguageSelector className={cx('language')} intent="square" />
            </div>
            <div className={cx('form-container')}>
              <div className={cx('form')}>
                <img
                  alt=""
                  className={cx('illustration')}
                  aria-hidden="true"
                  src={ILLUSTRATION}
                />
                <div className={cx('form-content')}>
                  <h1 className={cx('title')}>{t(`title-${me.role}`)}</h1>
                  {status && (
                    <p className={cx('error')} role="alert">
                      {t('onboarding-error')}
                    </p>
                  )}
                  <InputText
                    className={cx('field')}
                    data-testid="profile-first-name"
                    intent="gray"
                    placeholder={t('first-name-label')}
                    name="firstName"
                  />
                  <InputText
                    className={cx('field')}
                    data-testid="profile-last-name"
                    intent="gray"
                    placeholder={t('last-name-label')}
                    name="lastName"
                  />
                </div>
                <Button
                  className={cx('action')}
                  data-testid="update-profile-info-button"
                  intent="primary"
                  isLoading={isSubmitting}
                  size="large"
                  type="submit"
                >
                  {t('update-profile-info')}
                </Button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

Profile.propTypes = {
  handleStepNext: PropTypes.func.isRequired,
  me: PropTypes.object.isRequired,
  onBack: PropTypes.func.isRequired,
};

export default Profile;
