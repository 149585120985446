import { withTranslation } from 'react-i18next';
import * as yup from 'yup';

import Editor from './Editor';

const EditorContainer = withTranslation()(Editor);

EditorContainer.validationSchema = (t) =>
  yup.object().shape({
    tags: yup.array().of(yup.string().trim().lowercase().max(256)).compact(),
    title: yup
      .string()
      .trim()
      .transform((v) => v || t('Questions/Video/Editor/default-title')),
    video: yup
      .string()
      .trim()
      .required(t('Questions/Video/Editor/video-error-required'))
      .url(t('Questions/Video/Editor/video-error-required')),
  });

export default EditorContainer;
