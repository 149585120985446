import PropTypes from 'prop-types';
import React from 'react';

const Collapse = ({ children, className, handleToggle, openIndex }) => (
  <div className={className} role="tablist">
    {React.Children.map(
      children,
      (child, index) =>
        child &&
        React.cloneElement(child, {
          ...child.props,
          isOpen: openIndex === index,
          handleToggle: () => handleToggle(index),
        }),
    )}
  </div>
);

Collapse.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  handleToggle: PropTypes.func.isRequired,
  openIndex: PropTypes.number,
};

Collapse.defaultProps = {
  className: undefined,
  openIndex: undefined,
};

export default Collapse;
