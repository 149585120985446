import * as dayjs from 'dayjs';
import classNames from 'classnames/bind';
import PERMISSIONS from 'constants/permissions';
import PropTypes from 'prop-types';
import React from 'react';
import Avatar from 'uikit/Avatar';

import Button from 'uikit/Button';
import Icon from 'uikit/Icon';
import Menu from 'uikit//Menu';

import styles from './Participants.module.scss';

const cx = classNames.bind(styles);

const Participants = ({
  filteredParticipants,
  handleSearchChange,
  participants,
  search,
  t,
}) => (
  <div className={cx('participants')}>
    <div className={cx('participants-highlight')}>
      {participants.map(
        (participant, index) =>
          index < 3 && (
            // eslint-disable-next-line react/no-array-index-key
            <div className={cx('avatar-container')} key={index}>
              <Avatar
                isTeacher={participant.level >= PERMISSIONS.COLLABORATOR}
                profile={participant}
                useInitial
                size={36}
              />
            </div>
          ),
      )}
    </div>
    <Menu
      isHideOnClickDeactivated
      toggle={
        <Button
          className={cx('participants-number')}
          tooltip={t('Course/Teacher/Participants/tooltip')}
        >
          {participants.length > 3 ? (
            `+${participants.length - 3}`
          ) : (
            <Icon name="eye" />
          )}
        </Button>
      }
    >
      <div className={cx('list-container')}>
        <h1 className={cx('list-title')}>
          {t('Course/Teacher/Participants/title')}
        </h1>
        <div className={cx('search')}>
          <Icon name="search" />
          <input
            aria-label={t('Course/Teacher/Participants/aria-search')}
            className={cx('search-input')}
            onChange={handleSearchChange}
            placeholder={t('Course/Teacher/Participants/search-placeholder')}
            size="15"
            type="text"
            value={search}
          />
        </div>
        <div className={cx('participants-list')}>
          {filteredParticipants.map((participant, index) => {
            const lastConnection = dayjs(participant.lastConnection);
            const now = dayjs(new Date());
            const years = now.diff(lastConnection, 'year');
            const months = now.diff(lastConnection, 'month');
            const days = now.diff(lastConnection, 'day');
            const hours = now.diff(lastConnection, 'hour');
            const minutes = now.diff(lastConnection, 'minute');
            return (
              // eslint-disable-next-line react/no-array-index-key
              <div className={cx('participant')} key={index}>
                <div
                  className={cx('status', {
                    isActive: participant.isActive,
                  })}
                />
                <Avatar
                  isTeacher={participant.level >= PERMISSIONS.COLLABORATOR}
                  profile={participant}
                  useInitial
                />
                <div className={cx('information')}>
                  <p className={cx('name')}>
                    {`${participant.firstName} ${participant.lastName[0]}.`}
                  </p>
                  {participant.lastConnection && (
                    <p className={cx('last-connection')}>
                      {years > 0
                        ? t('Course/Teacher/Participants/years', {
                            count: years,
                          })
                        : months > 0
                        ? t('Course/Teacher/Participants/months', {
                            count: months,
                          })
                        : days > 0
                        ? t('Course/Teacher/Participants/days', {
                            count: days,
                          })
                        : hours > 0
                        ? t('Course/Teacher/Participants/hours', {
                            count: hours,
                          })
                        : t('Course/Teacher/Participants/minutes', {
                            count: minutes,
                          })}
                    </p>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Menu>
  </div>
);

Participants.propTypes = {
  filteredParticipants: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleSearchChange: PropTypes.func.isRequired,
  participants: PropTypes.arrayOf(PropTypes.object).isRequired,
  search: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default Participants;
