import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import VideoPicker from './VideoPicker';

const VideoPickerContainer = ({ name, ...props }) => {
  const { t } = useTranslation();
  const [, , { setValue: setVideo }] = useField(name);
  const [, , { setValue: setVideoToImport }] = useField('videoToImport');
  const [, , { setValue: setVideoPreview }] = useField('videoPreview');
  const [, , { setValue: setVideoLink }] = useField('videoLink');
  const [, , { setValue: setStartTime }] = useField('startTime');
  const [, , { setValue: setEndTime }] = useField('endTime');
  const [videoDuration, setVideoDuration] = useState(' ');
  const handleDeselect = useCallback(() => {
    setVideo('');
    setVideoLink(null);
    setEndTime(null);
    setStartTime(null);
    setVideoToImport(null);
    setVideoPreview(null);
  }, [
    setEndTime,
    setStartTime,
    setVideo,
    setVideoLink,
    setVideoPreview,
    setVideoToImport,
  ]);
  return (
    <VideoPicker
      handleDeselect={handleDeselect}
      name={name}
      setVideoDuration={setVideoDuration}
      t={t}
      videoDuration={videoDuration}
      {...props}
    />
  );
};
VideoPickerContainer.propTypes = {
  name: PropTypes.string.isRequired,
};

export default VideoPickerContainer;
