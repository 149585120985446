import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from 'recharts';

import CleanedText from 'uikit/CleanedText';

import styles from './Aggregate.module.scss';

const cx = classNames.bind(styles);

const Aggregate = ({ aggregation, className, question, questionInfo, t }) => (
  <div className={cx('container', className)}>
    <div className={cx('background')}>
      <div className={cx('header')}>
        <div className={cx('title-container')}>
          <p className={cx('icon', `background-${questionInfo.color}`)}>
            <img alt="" src={questionInfo.icon} />
          </p>
          <CleanedText as="p" className={cx('title')}>
            {question?.title}
          </CleanedText>
        </div>
        <p
          className={cx('answers-numbers', {
            'answers-numbers-wrong': aggregation.nCorrectAnswers === 0,
          })}
        >
          <span
            className={cx('correct-answers', {
              isEmpty: aggregation.nCorrectAnswers === 0,
            })}
          >
            {t('Questions/GuessNumber/Aggregate/correct-answers', {
              count: aggregation.nCorrectAnswers,
            })}
          </span>
          <span
            className={cx('total-answers', {
              'total-answers-wrong': aggregation.nCorrectAnswers === 0,
            })}
          >
            {` / ${aggregation.nTotalAnswers}`}
          </span>
        </p>
      </div>
      <div className={cx('chart')}>
        <ResponsiveContainer>
          <BarChart
            data={aggregation.aggregatedAnswers.map((answer) => ({
              name: answer.title,
              occurrence: answer.occurrence,
            }))}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            fontSize="1vw"
            fontWeight={700}
          >
            <CartesianGrid opacity=".2" strokeDasharray="0" vertical={false} />
            <Tooltip />
            <XAxis
              dataKey="name"
              tick={({ payload, ...props }) => (
                <text className={cx('chart-axis')} dy="15" {...props}>
                  {payload.value}
                </text>
              )}
            />
            <Bar dataKey="occurrence" fill="#adf399">
              <LabelList
                className={cx('chart-label')}
                dataKey="occurrence"
                position="top"
              />
              {aggregation.aggregatedAnswers.map((i) => (
                <Cell
                  key={i.title}
                  fill={
                    aggregation.minCorrect && aggregation.maxCorrect
                      ? aggregation.minCorrect <= i.title &&
                        aggregation.maxCorrect >= i.title
                        ? '#1bc489'
                        : '#656686'
                      : i.title === question.content.answer
                      ? '#1bc489'
                      : '#656686'
                  }
                />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
      <div className={cx('stats')}>
        {['minimum', 'mean', 'maximum', 'median', 'deviation', 'variance'].map(
          (stat, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className={cx('stat-container')} key={index}>
              <div className={cx('stat-value')}>
                {Math.round(aggregation[stat] * 100) / 100}
              </div>
              <div className={cx('stat-title')}>
                {t(`Questions/GuessNumber/${stat}`)}
              </div>
            </div>
          ),
        )}
      </div>
      <div className={cx('good-answer-container')}>
        <p className={cx('good-answer-title')}>
          {t('Questions/GuessNumber/Aggregate/good-answers')}
        </p>
        <p className={cx('good-answer')}>
          {aggregation.minCorrect && aggregation.maxCorrect
            ? t('Questions/GuessNumber/Aggregate/good-answers-between', {
                maxCorrect: aggregation.maxCorrect,
                minCorrect: aggregation.minCorrect,
              })
            : question.content.answer}
        </p>
      </div>
    </div>
  </div>
);

Aggregate.propTypes = {
  aggregation: PropTypes.object.isRequired,
  className: PropTypes.string,
  question: PropTypes.object.isRequired,
  questionInfo: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

Aggregate.defaultProps = {
  className: undefined,
};

export default Aggregate;
