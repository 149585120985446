import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import sanitize from 'lib/sanitize';
import Feedback from 'questions/Shared/Feedback';
import FormattedText from 'uikit/FormattedText';
import Icon from 'uikit/Icon';

import styles from './Verso.module.scss';

const cx = classNames.bind(styles);

const Verso = ({ className, question, t, theme }) => (
  <div className={cx('container', className, `theme-${theme}`)}>
    <FormattedText className={cx('title')}>{question.title}</FormattedText>
    {question.content.choices.map((choice) => {
      const solution = question.solution.find((c) => c.idx === choice.idx);
      const icon = question.content.multipleChoices ? 'square' : 'circle';
      return (
        <div aria-hidden={true} key={choice.idx}>
          <div
            className={cx('choice', {
              isCorrect: solution?.correct,
              isWrong: !solution?.correct && solution?.user,
            })}
          >
            <Icon
              className={cx('icon')}
              name={solution?.user ? `check-${icon}` : icon}
              variant="solid"
            />
            <FormattedText className={cx('choice-title')}>
              {choice.title}
            </FormattedText>
          </div>
          {(solution.feedback || choice.feedback) && (
            <div className={cx('feedback', 'choice-feedback')}>
              <div className={cx('feedback-icon')}>
                <Icon name="info-circle" variant="duotone" />
              </div>
              <FormattedText>
                {solution.feedback || choice.feedback}
              </FormattedText>
            </div>
          )}
        </div>
      );
    })}
    <p className={cx('aria-label')}>
      <span>{t('Questions/MCQ/Verso/aria-solution')}</span>
      {question.content.choices.map((choice, index) => (
        <>
          <span>
            {t(
              `Questions/MCQ/Verso/aria-${
                question.solution[index].isCorrect ? 'correct' : 'wrong'
              }`,
              {
                name: sanitize.string(choice.title),
              },
            )}
          </span>
          {question.solution[index].feedback && (
            <span>
              {`${t('Questions/MCQ/Verso/aria-feedback')}: ${sanitize.string(
                question.solution[index].feedback,
              )}`}
            </span>
          )}
        </>
      ))}
    </p>
    {question.content.feedback && (
      <Feedback feedback={question.content.feedback} />
    )}
  </div>
);

Verso.propTypes = {
  className: PropTypes.string,
  question: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
};

Verso.defaultProps = {
  className: undefined,
};

export default Verso;
