import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import EDITOR_STATUSES from 'constants/editorStatuses';
import { mutationEditorClear } from 'gql/mutations/editor';
import { useMutationFolderDelete } from 'gql/mutations/folder';
import { useQueryLocalEditor } from 'gql/queries/local';
import { trackEvent, reportError } from 'lib/tracking';

import FolderDelete from './FolderDelete';

const FolderDeleteContainer = ({ courses }) => {
  const { t } = useTranslation();
  const {
    data: {
      editor: { status: editorStatus, folderEditing },
    },
  } = useQueryLocalEditor();
  const isModalOpen = editorStatus === EDITOR_STATUSES.FOLDER_DELETE;
  const [status, setStatus] = useState(undefined);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    setStatus(undefined);
  }, [isModalOpen]);
  const mutationFolderDelete = useMutationFolderDelete();
  const handleFolderDelete = useCallback(async () => {
    try {
      setStatus(undefined);
      setLoading(true);
      await mutationFolderDelete(folderEditing);
      mutationEditorClear();
      trackEvent('folder', 'delete');
    } catch (err) {
      setStatus(err.message);
      reportError('folder', 'delete', err);
    } finally {
      setLoading(false);
    }
  }, [folderEditing, mutationFolderDelete]);
  return (
    <FolderDelete
      handleClose={mutationEditorClear}
      handleFolderDelete={handleFolderDelete}
      isLoading={isLoading}
      isModalOpen={isModalOpen}
      status={status}
      t={t}
    />
  );
};

FolderDeleteContainer.propTypes = {
  courses: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default FolderDeleteContainer;
