import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import FileCard from './FileCard/FileCard';
import styles from './Files.module.scss';

const cx = classNames.bind(styles);

const Files = ({ chapterId = null, className = null, course, ...props }) => {
  const filteredFiles =
    course.files?.filter((file) =>
      chapterId ? file.chapterId === chapterId : !file.chapterId,
    ) ?? [];

  return (
    <div className={className}>
      {filteredFiles.map((file) => (
        <FileCard className={cx('file')} key={file.id} file={file} {...props} />
      ))}
    </div>
  );
};

Files.propTypes = {
  chapterId: PropTypes.string,
  className: PropTypes.string,
  course: PropTypes.object.isRequired,
};

export default Files;
