import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import Context from 'lib/context';

import Recto from './Recto';

const RectoContainer = ({ question, ...props }) => {
  const { t } = useTranslation();

  const { theme } = useContext(Context);

  const [{ value: answer }, , { setValue: setAnswer }] = useField('answer');

  // Setup answer with already correct answers
  useEffect(() => {
    const updatedAnswer = answer.map((answerItem, index) => {
      const itemSolution = question.solution ? question.solution[index] : null;
      if (itemSolution && itemSolution.isCorrect) {
        if (itemSolution.isForced) {
          return itemSolution.correct[0] || '';
        }
        return itemSolution.user;
      }
      return answerItem;
    });
    setAnswer(updatedAnswer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const legends = question.content.legends.map((legend, index) => {
    const isCorrect = question.solution
      ? question.solution[index].isCorrect
      : false;
    return {
      ...legend,
      isCorrect,
    };
  });

  const isExistingAnsweredLegend = legends.some((legend) => legend.isCorrect);

  return (
    <Recto
      {...props}
      isExistingAnsweredLegend={isExistingAnsweredLegend}
      legends={legends}
      question={question}
      t={t}
      theme={theme}
    />
  );
};

RectoContainer.propTypes = {
  question: PropTypes.object.isRequired,
};

RectoContainer.defaultAnswer = (question) =>
  (question.content.legends || []).map(() => '');

RectoContainer.validationSchema = () =>
  yup.object().shape({
    answer: yup.array().of(yup.string().trim()),
  });

export default RectoContainer;
