import DEFAULT_CANVAS_PROPERTIES from '../../Shared/SlideBuilder/data/defaultCanvasProperties';

const BREAK_POINTS = {
  preview: {
    S: 800,
    M: 1312,
    L: 1220,
  },
  recto: {
    small: 950,
  },
};

const LARGEST_CANVAS_WIDTH = 920;

const getRectoNewCanvasWidth = (isPreview, menuState) => {
  if (isPreview) {
    if (window.innerWidth < BREAK_POINTS.preview.S) {
      return (
        window.innerWidth * DEFAULT_CANVAS_PROPERTIES.rectoSmallRatio[menuState]
      );
    }
    if (window.innerWidth < BREAK_POINTS.preview.M) {
      return (
        window.innerWidth *
        DEFAULT_CANVAS_PROPERTIES.rectoMediumRatio[menuState]
      );
    }
    if (window.innerWidth < BREAK_POINTS.preview.L) {
      return (
        window.innerWidth * DEFAULT_CANVAS_PROPERTIES.rectoLargeRatio[menuState]
      );
    }
    if (
      window.innerWidth * DEFAULT_CANVAS_PROPERTIES.rectoLargeRatio[menuState] <
      LARGEST_CANVAS_WIDTH
    ) {
      return (
        window.innerWidth * DEFAULT_CANVAS_PROPERTIES.rectoLargeRatio[menuState]
      );
    }
    return LARGEST_CANVAS_WIDTH;
  } else if (window.innerWidth < BREAK_POINTS.recto.small) {
    return (
      window.innerWidth * DEFAULT_CANVAS_PROPERTIES.rectoSmallRatio[menuState]
    );
  }
  return LARGEST_CANVAS_WIDTH;
};

export default getRectoNewCanvasWidth;
