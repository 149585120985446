import React, { useContext } from 'react';
import { useLocation } from 'react-router';

import Context from 'lib/context';

import CheckBoxTrueFalse from './CheckBoxTrueFalse';

const CheckBoxTrueFalseContainer = (props) => {
  const { theme } = useContext(Context);
  const { pathname } = useLocation();
  const isStudying = pathname.includes('/study');
  return <CheckBoxTrueFalse theme={isStudying ? theme : {}} {...props} />;
};

export default CheckBoxTrueFalseContainer;
