import classNames from 'classnames/bind';
import React from 'react';
import { useTranslation } from 'react-i18next';

import imageEmpty from './images/empty.svg';
import styles from './Empty.module.scss';

const cx = classNames.bind(styles);

const Empty = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/TestResults/Empty',
  });

  return (
    <div className={cx('container')}>
      <img alt="" aria-hidden="true" className={cx('image')} src={imageEmpty} />
      <p>{t('no-sessions')}</p>
    </div>
  );
};

export default Empty;
