import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import Context from 'lib/context';

import Verso from './Verso';

const VersoContainer = (props) => {
  const { t } = useTranslation();
  const { theme } = useContext(Context);
  return <Verso t={t} theme={theme} {...props} />;
};

VersoContainer.defaultAnswer = () => undefined;

VersoContainer.defaultSolution = (question) => {
  const maxIndex = question.content.experts.indexOf(
    Math.max(...question.content.experts),
  );
  return {
    user: null,
    correct: maxIndex,
  };
};

export default VersoContainer;
