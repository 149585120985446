import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useQueryMe } from 'gql/queries/me';
import { useMutationChapterCreate } from 'gql/mutations/chapter';
import {
  mutationEditorChapterUpdate,
  mutationEditorFileImport,
  mutationEditorQuestionCreate,
  mutationEditorQuestionCreateFromIA,
  mutationEditorQuestionImport,
  mutationEditorQuestionTypeChange,
} from 'gql/mutations/editor';
import useHotkeys from 'hooks/useHotkeys';
import { trackEvent, reportError } from 'lib/tracking';
import Button from 'uikit/Button';
import Menu, { MenuItem } from 'uikit/Menu';
import Tooltip from 'uikit/Tooltip';

import ActionsMobile from './ActionsMobile/ActionsMobile';
import styles from './Actions.module.scss';

const cx = classNames.bind(styles);

const Actions = ({ className = null, course }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/Editor/Actions',
  });

  const mutationChapterCreate = useMutationChapterCreate();

  const { isLoading: isLoadingMe, data: dataMe } = useQueryMe();

  useHotkeys('shift+n', mutationEditorQuestionCreate);

  useHotkeys('shift+i', mutationEditorQuestionImport);

  useHotkeys('shift+c', handleChapterCreate);

  async function handleChapterCreate() {
    try {
      const {
        data: { chapterCreate },
      } = await mutationChapterCreate({
        courseId: course.id,
        title: t('new-chapter-title'),
      });
      mutationEditorChapterUpdate(chapterCreate);
      trackEvent('chapter', 'create');
    } catch (err) {
      reportError('chapter', 'create', err);
    }
  }

  function handleQuestionCreate(type) {
    mutationEditorQuestionCreate();
    if (type) {
      mutationEditorQuestionTypeChange(type);
    }
  }

  const wantsAI = isLoadingMe ? false : dataMe.me.wantsAI;

  return (
    <>
      <div className={cx('large-screen', className)}>
        <div className={cx('secondary-actions')}>
          <Button
            icon="folder-plus"
            intent="white"
            onClick={handleChapterCreate}
            tooltip={t('create-chapter')}
          />
          <Button
            icon="file-download"
            intent="white"
            onClick={mutationEditorQuestionImport}
            tooltip={t('import-question')}
          />
          <Button
            icon="download"
            intent="white"
            onClick={mutationEditorFileImport}
            tooltip={t('upload-file')}
          />
        </div>
        <div className={cx('main-actions')}>
          {wantsAI && (
            <Tooltip tooltip={t('from-ia-tooltip')}>
              <Button
                className={cx('main-action')}
                icon="wand-sparkles"
                intent="ia-light"
                onClick={mutationEditorQuestionCreateFromIA}
              >
                {t('create-from-ia')}
              </Button>
            </Tooltip>
          )}
          <Button
            className={cx('main-action')}
            data-testid="create-question-button-large"
            icon="plus"
            id="create-question-button-large"
            intent="white"
            onClick={() => handleQuestionCreate(null)}
          >
            {t('create-question')}
          </Button>
        </div>
      </div>
      <div className={cx('medium-screen')}>
        <Menu
          placement="bottom-end"
          toggle={
            <Button
              aria-label={t('new')}
              data-testid="create-button-medium"
              icon="plus"
              id="create-button-medium"
              intent="primary"
            />
          }
        >
          <MenuItem
            data-testid="create-question-button-medium"
            icon="plus"
            onClick={() => handleQuestionCreate(null)}
          >
            {t('create-question')}
          </MenuItem>
          <MenuItem
            icon="wand-sparkles"
            onClick={mutationEditorQuestionCreateFromIA}
          >
            {t('create-from-ia')}
          </MenuItem>
          <MenuItem icon="folder-plus" onClick={handleChapterCreate}>
            {t('create-chapter')}
          </MenuItem>
          <MenuItem icon="file-download" onClick={mutationEditorQuestionImport}>
            {t('import-question')}
          </MenuItem>
          <MenuItem icon="download" onClick={mutationEditorFileImport}>
            {t('upload-file')}
          </MenuItem>
        </Menu>
      </div>
      <ActionsMobile
        handleChapterCreate={handleChapterCreate}
        handleFileImport={mutationEditorFileImport}
        handleQuestionCreate={handleQuestionCreate}
        handleQuestionCreateFromIA={mutationEditorQuestionCreateFromIA}
        handleQuestionImport={mutationEditorQuestionImport}
      />
    </>
  );
};

Actions.propTypes = {
  className: PropTypes.string,
  course: PropTypes.object.isRequired,
};

export default Actions;
