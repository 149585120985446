import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import { useMutationCourseUpdate } from 'gql/mutations/course';
import extract from 'helpers/extract';
import { trackEvent, reportError } from 'lib/tracking';

import CourseTitle from './CourseTitle';

const CourseTitleContainer = ({ course, ...props }) => {
  const { t } = useTranslation();
  const mutationCourseUpdate = useMutationCourseUpdate();
  const [isEditing, setEditing] = useState(false);
  const handleTitleMount = useCallback((ref) => {
    if (ref) {
      ref.select();
    }
  }, []);
  const handleTitleEdit = useCallback(() => {
    setEditing(true);
  }, []);
  const handleEditDone = useCallback(
    async (values) => {
      try {
        const title = extract(values.title, yup.string().trim().max(256));
        if (title === course.title) {
          setEditing(false);
          return;
        }
        await mutationCourseUpdate(course, {
          title,
        });
        trackEvent('course', 'update');
      } catch (err) {
        reportError('course', 'update', err);
      } finally {
        setEditing(false);
      }
    },
    [course, mutationCourseUpdate],
  );
  return (
    <CourseTitle
      {...props}
      course={course}
      handleTitleEdit={handleTitleEdit}
      handleEditDone={handleEditDone}
      handleTitleMount={handleTitleMount}
      isEditing={isEditing}
      t={t}
    />
  );
};

CourseTitleContainer.propTypes = {
  course: PropTypes.object.isRequired,
};

export default CourseTitleContainer;
