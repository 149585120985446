import { withTranslation } from 'react-i18next';
import * as yup from 'yup';

import Editor from './Editor';

const EditorContainer = withTranslation()(Editor);

EditorContainer.validationSchema = (t) =>
  yup.object().shape({
    tags: yup.array().of(yup.string().trim().lowercase().max(256)).compact(),
    title: yup
      .string()
      .trim()
      .transform(
        (v) => v || t('Questions/FillInTheBlanks/Editor/default-title'),
      ),
    text: yup
      .string()
      .trim()
      .required(t('Questions/FillInTheBlanks/Editor/text-error-required'))
      .test(
        'blanks',
        t('Questions/FillInTheBlanks/Editor/blanks-error-required'),
        (text) =>
          Array.from(
            text.matchAll(/\[{2}.*?\]*\]{2}|\{{2}.*?\}*\}{2}/g),
            () => 'blank',
          ).length > 0,
      ),
    feedback: yup.string().trim(),
    ignoreCase: yup.boolean().default(true),
    ignoreAccents: yup.boolean().default(true),
    ignorePunctuation: yup.boolean().default(true),
  });

export default EditorContainer;
