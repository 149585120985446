import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import VideoLink from './VideoLink';

const VideoLinkContainer = ({ name, ...props }) => {
  const { t } = useTranslation();
  const [, , { setValue: setVideo }] = useField(name);
  const [{ value: videoLink }, , { setValue: setVideoLink }] =
    useField('videoLink');
  const [{ value: startTime }, , { setValue: setStartTime }] =
    useField('startTime');
  const [{ value: endTime }, , { setValue: setEndTime }] = useField('endTime');
  useField('videoLink');
  const handleLinkReset = useCallback(() => {
    setVideo('');
    setVideoLink('');
    setEndTime(null);
    setStartTime(null);
  }, [setEndTime, setStartTime, setVideo, setVideoLink]);
  const handleLinkChange = useCallback(
    (event) => {
      if (event.target.value === '') {
        handleLinkReset();
      } else {
        setVideoLink(event.target.value);
      }
    },
    [handleLinkReset, setVideoLink],
  );
  useEffect(() => {
    if (videoLink) {
      const linkValue = videoLink.startsWith('https://youtu.be/')
        ? videoLink
            .replace('https://youtu.be/', 'https://www.youtube.com/embed/')
            // delete start time if existing
            .replace(/t=(\d+?)[s]/, '')
        : videoLink.startsWith('https://www.youtube.com/watch?v=')
        ? videoLink
            .replace(
              'https://www.youtube.com/watch?v=',
              'https://www.youtube.com/embed/',
            )
            // delete start time if existing
            .replace(/&t=(\d+?)[s]/, '')
        : (videoLink || '').trim();
      const linkWithTimer = linkValue.includes('?')
        ? `${linkValue}${startTime ? `&start=${startTime}` : ''}${
            endTime && (!startTime || endTime > startTime)
              ? `&end=${endTime}`
              : ''
          }`
        : `${linkValue}${startTime || endTime ? '?' : ''}${
            startTime ? `start=${startTime}` : ''
          }${startTime && endTime && endTime > startTime ? '&' : ''}${
            endTime && (!startTime || endTime > startTime)
              ? `end=${endTime}`
              : ''
          }`;
      setVideo(linkWithTimer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endTime, startTime, videoLink]);
  return (
    <VideoLink
      endTime={endTime}
      handleLinkChange={handleLinkChange}
      handleLinkReset={handleLinkReset}
      setEndTime={setEndTime}
      setStartTime={setStartTime}
      startTime={startTime}
      t={t}
      videoLink={videoLink}
      {...props}
    />
  );
};

VideoLinkContainer.propTypes = {
  name: PropTypes.string.isRequired,
};

export default VideoLinkContainer;
