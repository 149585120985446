import { fabric } from 'fabric';

import RotateIcon from '../images/rotate.svg';
import TrashIcon from '../images/trash.svg';

const deleteObject = (eventData, transform) => {
  const { target } = transform;
  const { canvas } = target;
  canvas.remove(target);
  canvas.requestRenderAll();
};

// Delete icon
const deleteIcon = document.createElement('img');
deleteIcon.src = TrashIcon;
const renderDeleteIcon = (ctx, left, top, styleOverride, fabricObject) => {
  const size = 50;
  ctx.save();
  ctx.translate(left, top);
  ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
  ctx.drawImage(deleteIcon, -size / 2, -size / 2, size, size);
  ctx.restore();
};

// Rotation icon
const rotateIcon = document.createElement('img');
rotateIcon.src = RotateIcon;
const renderRotateIcon = (ctx, left, top, styleOverride, fabricObject) => {
  const size = 50;
  ctx.save();
  ctx.translate(left, top);
  ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
  ctx.drawImage(rotateIcon, -size / 2, -size / 2, size, size);
  ctx.restore();
};

// Controls properties
fabric.Object.prototype.transparentCorners = false;
fabric.Object.prototype.cornerColor = '#BFF6EC';
fabric.Object.prototype.cornerStyle = 'circle';
fabric.Object.prototype.cornerSize = 12;

// Delete control properties
fabric.Object.prototype.controls.deleteControl = new fabric.Control({
  x: 0.5,
  y: 0,
  offsetX: 40,
  offsetY: 0,
  cursorStyle: 'pointer',
  mouseUpHandler: deleteObject,
  render: renderDeleteIcon,
  cornerSize: 24,
});
fabric.Textbox.prototype.controls.deleteControl = new fabric.Control({
  x: 0.5,
  y: 0,
  offsetX: 40,
  offsetY: 0,
  cursorStyle: 'pointer',
  mouseUpHandler: deleteObject,
  render: renderDeleteIcon,
  cornerSize: 24,
});

// Rotation control properties
fabric.Object.prototype.controls.mtr = new fabric.Control({
  x: 0,
  y: -0.5,
  offsetX: 0,
  offsetY: -40,
  cursorStyle: 'grab',
  actionHandler: fabric.controlsUtils.rotationWithSnapping,
  actionName: 'rotate',
  render: renderRotateIcon,
  cornerSize: 38,
});
fabric.Textbox.prototype.controls.mtr = new fabric.Control({
  x: 0,
  y: -0.5,
  offsetX: 0,
  offsetY: -40,
  cursorStyle: 'grab',
  actionHandler: fabric.controlsUtils.rotationWithSnapping,
  actionName: 'rotate',
  render: renderRotateIcon,
  cornerSize: 38,
});
