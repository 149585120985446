import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useQueryCourse } from 'gql/queries/course';

import Exam from './Exam';

const ExamContainer = (props) => {
  const { t } = useTranslation();
  const { courseId } = useParams();
  const { isLoading, data } = useQueryCourse({
    variables: {
      courseId,
    },
  });
  return <Exam course={data?.course} isLoading={isLoading} t={t} {...props} />;
};

export default ExamContainer;
