import EDITOR_STATUSES from 'constants/editorStatuses';
import { editorVar } from 'gql/local';

export const initialState = {
  status: null,
  questionsSelected: [],
  chapterSelected: null,
  commentSelected: null,
  questionType: null,
  questionEditing: null,
  folderEditing: null,
  chapterEditing: null,
  gameEditing: null,
  collaboratorEditing: null,
  groupEditing: null,
  studentEditing: null,
};

export function mutationEditorReset() {
  editorVar(initialState);
}

export function mutationEditorClear() {
  const editor = editorVar();
  editorVar({
    ...editor,
    status: null,
    questionsSelected: [],
    questionType: null,
    questionEditing: null,
    folderEditing: null,
    chapterEditing: null,
    gameEditing: null,
    collaboratorEditing: null,
    groupEditing: null,
    studentEditing: null,
  });
}

export function mutationEditorQuestionCreate(questionType) {
  const editor = editorVar();
  editorVar({
    ...editor,
    status: EDITOR_STATUSES.QUESTION_CREATE,
    questionsSelected: [],
    questionType: typeof questionType === 'string' ? questionType : null,
    questionEditing: {
      content: {},
    },
  });
}

export function mutationEditorQuestionCreateFromIA() {
  const editor = editorVar();
  editorVar({
    ...editor,
    status: EDITOR_STATUSES.QUESTION_CREATE_FROM_IA,
    questionsSelected: [],
    questionType: null,
    questionEditing: null,
  });
}

export function mutationEditorQuestionPropose(questionType) {
  const editor = editorVar();
  editorVar({
    ...editor,
    status: EDITOR_STATUSES.QUESTION_PROPOSE,
    questionsSelected: [],
    questionType: typeof questionType === 'string' ? questionType : null,
    questionEditing: {
      content: {},
    },
  });
}

export function mutationEditorQuestionVerify(question) {
  const editor = editorVar();
  editorVar({
    ...editor,
    status: EDITOR_STATUSES.QUESTION_APPROVE,
    questionsSelected: [question],
    questionType: question.type,
    questionEditing: question,
  });
}

export function mutationEditorQuestionUpdate(question) {
  const editor = editorVar();
  editorVar({
    ...editor,
    status: EDITOR_STATUSES.QUESTION_UPDATE,
    questionsSelected: [question],
    chapterSelected:
      editor.chapterSelected && editor.chapterSelected.id === question.chapterId
        ? editor.chapterSelected
        : null,
    questionType: question.type,
    questionEditing: question,
  });
}

export function mutationEditorQuestionImport() {
  const editor = editorVar();
  editorVar({
    ...editor,
    status: EDITOR_STATUSES.QUESTION_IMPORT,
  });
}

export function mutationEditorQuestionImportPreview(question) {
  const editor = editorVar();
  editorVar({
    ...editor,
    questionEditing: question,
  });
}

export function mutationEditorQuestionSelect(question) {
  const editor = editorVar();
  const questionsSelected = editor.questionsSelected.some(
    (q) => q.id === question.id,
  )
    ? editor.questionsSelected.filter((q) => q.id !== question.id)
    : [...editor.questionsSelected, question];
  editorVar({
    ...editor,
    status: questionsSelected.length ? EDITOR_STATUSES.QUESTION_MULTI : null,
    questionsSelected,
  });
}

export function mutationEditorQuestionSelectMultiple({
  lastQuestion,
  question,
  questions,
}) {
  const editor = editorVar();
  const questionsToSelect = questions
    .filter((q) =>
      lastQuestion.order < question.order
        ? q.order > lastQuestion.order && q.order <= question.order
        : q.order < lastQuestion.order && q.order >= question.order,
    )
    .filter(
      (qToSelect) =>
        !editor.questionsSelected.some((q) => q.id === qToSelect.id),
    );
  const questionsSelected = [...editor.questionsSelected, ...questionsToSelect];
  editorVar({
    ...editor,
    status: questionsSelected.length ? EDITOR_STATUSES.QUESTION_MULTI : null,
    questionsSelected,
  });
}

export function mutationEditorQuestionTypeChange(questionType) {
  const editor = editorVar();
  editorVar({
    ...editor,
    questionType,
  });
}

export function mutationEditorQuestionMove(question) {
  const editor = editorVar();
  editorVar({
    ...editor,
    status: EDITOR_STATUSES.QUESTION_MOVE,
    questionsSelected: [question],
    questionEditing: question,
  });
}

export function mutationEditorQuestionsMove() {
  const editor = editorVar();
  editorVar({
    ...editor,
    status: EDITOR_STATUSES.QUESTIONS_MOVE,
  });
}

export function mutationEditorQuestionPreview(question) {
  const editor = editorVar();
  editorVar({
    ...editor,
    status: EDITOR_STATUSES.QUESTION_PREVIEW,
    questionsSelected: [question],
    questionEditing: question,
  });
}

export function mutationEditorQuestionComments(question) {
  const editor = editorVar();
  editorVar({
    ...editor,
    status: EDITOR_STATUSES.QUESTION_COMMENTS,
    questionsSelected: [question],
    commentSelected: null,
    questionEditing: question,
  });
}

export function mutationEditorStudentErrors(question) {
  const editor = editorVar();
  editorVar({
    ...editor,
    status: EDITOR_STATUSES.STUDENT_ERRORS,
    questionEditing: question,
  });
}

export function mutationEditorAggregatedAnswers(question) {
  const editor = editorVar();
  editorVar({
    ...editor,
    status: EDITOR_STATUSES.AGGREGATED_ANSWERS,
    questionEditing: question,
  });
}

export function mutationEditorQuestionCommentsChange(comment) {
  const editor = editorVar();
  editorVar({
    ...editor,
    commentSelected: comment,
  });
}

export function mutationEditorFolderUpdate(folder) {
  const editor = editorVar();
  editorVar({
    ...editor,
    status: EDITOR_STATUSES.FOLDER_UPDATE,
    folderEditing: folder,
  });
}

export function mutationEditorFolderDelete(folder) {
  const editor = editorVar();
  editorVar({
    ...editor,
    status: EDITOR_STATUSES.FOLDER_DELETE,
    folderEditing: folder,
  });
}

export function mutationEditorChapterCreate() {
  const editor = editorVar();
  editorVar({
    ...editor,
    status: EDITOR_STATUSES.CHAPTER_CREATE,
    questionsSelected: [],
    chapterSelected: null,
    questionEditing: null,
    chapterEditing: {},
  });
}

export function mutationEditorChapterUpdate(chapter) {
  const editor = editorVar();
  editorVar({
    ...editor,
    status: EDITOR_STATUSES.CHAPTER_UPDATE,
    questionsSelected: [],
    chapterSelected: chapter,
    questionEditing: null,
    chapterEditing: chapter,
  });
}

export function mutationEditorChapterSelect(chapter) {
  const editor = editorVar();
  editorVar({
    ...editor,
    chapterSelected: editor.chapterSelected?.id === chapter.id ? null : chapter,
  });
}

export function mutationEditorChapterMove(chapter) {
  const editor = editorVar();
  editorVar({
    ...editor,
    status: EDITOR_STATUSES.CHAPTER_MOVE,
    questionsSelected: [],
    chapterSelected:
      editor.chapterSelected?.id === chapter.id ? null : editor.chapterSelected,
    questionEditing: null,
    chapterEditing: chapter,
  });
}

export function mutationEditorGameCreate() {
  const editor = editorVar();
  editorVar({
    ...editor,
    status: EDITOR_STATUSES.GAME_CREATE,
    questionsSelected: [],
    chapterSelected: null,
  });
}

export function mutationEditorGameDelete(game) {
  const editor = editorVar();
  editorVar({
    ...editor,
    status: EDITOR_STATUSES.GAME_DELETE,
    questionsSelected: [],
    chapterSelected: null,
    gameEditing: game,
  });
}

export function mutationEditorGameUpdate(game) {
  const editor = editorVar();
  editorVar({
    ...editor,
    status: EDITOR_STATUSES.GAME_UPDATE,
    questionsSelected: [],
    chapterSelected: null,
    gameEditing: game,
  });
}

export function mutationEditorCollaboratorsInvite() {
  const editor = editorVar();
  editorVar({
    ...editor,
    status: EDITOR_STATUSES.COLLABORATORS_INVITE,
  });
}

export function mutationEditorCollaboratorDelete(collaborator) {
  const editor = editorVar();
  editorVar({
    ...editor,
    status: EDITOR_STATUSES.COLLABORATOR_DELETE,
    questionsSelected: [],
    chapterSelected: null,
    collaboratorEditing: collaborator,
  });
}

export function mutationEditorGroupUpdate(group) {
  const editor = editorVar();
  editorVar({
    ...editor,
    status: EDITOR_STATUSES.GROUP_UPDATE,
    groupEditing: group,
  });
}

export function mutationEditorGroupShare(group) {
  const editor = editorVar();
  editorVar({
    ...editor,
    status: EDITOR_STATUSES.GROUP_SHARE,
    groupEditing: group,
  });
}

export function mutationEditorGroupDelete(group) {
  const editor = editorVar();
  editorVar({
    ...editor,
    status: EDITOR_STATUSES.GROUP_DELETE,
    groupEditing: group,
  });
}

export function mutationEditorStudentDelete(student) {
  const editor = editorVar();
  editorVar({
    ...editor,
    status: EDITOR_STATUSES.STUDENT_DELETE,
    questionsSelected: [],
    chapterSelected: null,
    studentEditing: student,
  });
}

export function mutationEditorFileImport() {
  const editor = editorVar();
  editorVar({
    ...editor,
    status: EDITOR_STATUSES.FILE_IMPORT,
  });
}
