import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import InputTimer from './InputTimer';

const InputTimerContainer = ({ setTotalSeconds, totalSeconds, ...props }) => {
  const { t } = useTranslation();
  const minutes =
    !totalSeconds || totalSeconds < 0 ? 0 : Math.floor(totalSeconds / 60);
  const seconds = !totalSeconds || totalSeconds < 0 ? 0 : totalSeconds % 60;
  const handleChangeMinutes = useCallback(
    ({ target: { value: newMinutes } }) => {
      // Don't cast `newMinutes` to a number here because it might be null or '',
      // otherwise it will be transformed to `0` and it will be impossible to clear
      // the input.
      const formattedNewMinutes = (newMinutes || '')
        .replace(/[^\d]d/g, '')
        .trim();
      // Cast both value to number (because they might be null or '').
      setTotalSeconds(60 * Number(formattedNewMinutes) + Number(seconds));
    },
    [seconds, setTotalSeconds],
  );
  const handleChangeSeconds = useCallback(
    ({ target: { value: newSeconds } }) => {
      // Don't cast `newSeconds` to a number here because it might be null or '',
      // otherwise it will be transformed to `0` and it will be impossible to clear
      // the input.
      const formattedNewSeconds = (newSeconds || '')
        .replace(/[^\d]d/g, '')
        .trim();
      // Cast both value to number (because they might be null or '').
      return setTotalSeconds(
        60 * Number(minutes) + Number(formattedNewSeconds),
      );
    },
    [minutes, setTotalSeconds],
  );
  const handleIncrementTimer = useCallback(
    (evt, increment) => {
      if (evt) {
        evt.preventDefault();
      }
      const newValue = (totalSeconds || 0) + increment;
      return setTotalSeconds(newValue);
    },
    [setTotalSeconds, totalSeconds],
  );
  return (
    <InputTimer
      handleChangeMinutes={handleChangeMinutes}
      handleChangeSeconds={handleChangeSeconds}
      handleIncrementTimer={handleIncrementTimer}
      minutes={minutes}
      seconds={seconds}
      t={t}
      {...props}
    />
  );
};

InputTimerContainer.propTypes = {
  totalSeconds: PropTypes.number,
  setTotalSeconds: PropTypes.func.isRequired,
};

InputTimerContainer.defaultProps = {
  totalSeconds: undefined,
};

export default InputTimerContainer;
