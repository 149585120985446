import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useQueryCourseParticipants } from 'gql/queries/course';

import Participants from './Participants';

const ParticipantsContainer = ({ courseId, ...props }) => {
  const { t } = useTranslation();
  const { data } = useQueryCourseParticipants({
    variables: {
      courseId,
    },
  });
  const [search, setSearch] = useState('');
  const handleSearchChange = useCallback((evt) => {
    setSearch(evt.target.value);
  }, []);
  const filteredParticipants = useMemo(
    () =>
      data
        ? data.course.participants.filter(
            (participant) =>
              !search ||
              `${participant.firstName.toLowerCase()} ${participant.lastName.toLowerCase()}`.includes(
                search.trim().toLowerCase(),
              ),
          )
        : [],
    [data, search],
  );
  return (
    data?.course.participants.length > 0 && (
      <Participants
        {...props}
        filteredParticipants={filteredParticipants}
        handleSearchChange={handleSearchChange}
        participants={data.course.participants}
        search={search}
        t={t}
      />
    )
  );
};

ParticipantsContainer.propTypes = {
  courseId: PropTypes.string.isRequired,
};

export default ParticipantsContainer;
