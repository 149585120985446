import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'uikit/Button';
import Icon from 'uikit/Icon';
import InputText from 'uikit/InputText';
import Modal from 'uikit/Modal';

import styles from './ExpertOpinionEditor.module.scss';

const cx = classNames.bind(styles);

const ExpertOpinionEditor = ({
  className,
  columnIndex,
  editingLabel,
  feedbacks,
  handleFeedbackAdd,
  handleFeedbackComplete,
  handleFeedbackRemove,
  handleKeyDown,
  handleLabelEdit,
  handleLabelDone,
  handleModalClose,
  handleModalOpen,
  isModalOpen,
  labels,
  questionType,
  t,
}) => (
  <div className={cx('expert-container')}>
    {editingLabel === columnIndex ? (
      <InputText
        autoFocus
        className={cx('label')}
        key={columnIndex}
        name={`labels.${columnIndex}`}
        onBlur={handleLabelDone}
        onKeyDown={handleKeyDown}
        placeholder={t(
          `Questions/${questionType}/experts-${columnIndex}-label`,
        )}
      />
    ) : (
      <button
        className={cx('label')}
        key={columnIndex}
        onClick={() => handleLabelEdit(columnIndex)}
        type="button"
      >
        <label htmlFor={`field-${columnIndex}`}>
          {labels[columnIndex] ||
            t(`Questions/${questionType}/experts-${columnIndex}-label`)}
          <Icon className={cx('edit')} name="pencil" />
        </label>
      </button>
    )}
    <InputText
      className={cx('field', 'expert')}
      data-testid={`experts.${columnIndex}`}
      id={`field-${columnIndex}`}
      key={columnIndex}
      min="0"
      name={`experts.${columnIndex}`}
      shouldHideError
      type="number"
      variant="large-number"
    />
    <Button
      className={cx(className, 'button', { primary: feedbacks.length > 0 })}
      onClick={handleModalOpen}
    >
      <span>
        {t('Questions/Shared/ExpertOpinionEditor/add-feedbacks', {
          count: feedbacks.length,
        })}
      </span>
    </Button>
    <Modal isOpen={isModalOpen} onExit={handleModalClose}>
      <div className={cx('modal', 'container')}>
        <Button
          aria-label={t('Questions/Shared/ExpertOpinionEditor/aria-close')}
          className={cx('close')}
          icon="times"
          onClick={handleModalClose}
        />
        <h1 className={cx('title')}>
          <span> {t('Questions/Shared/ExpertOpinionEditor/title')}</span>
          {labels[columnIndex] ||
            t(`Questions/${questionType}/experts-${columnIndex}-label`)}
        </h1>
        {feedbacks.map((feedback, index) => (
          //  eslint-disable-next-line react/no-array-index-key
          <div key={index}>
            <div className={cx('list-element')}>
              {index === feedbacks.length ? (
                <InputText
                  className={cx('Feedback-complete')}
                  name="tmp"
                  onChange={handleFeedbackComplete}
                />
              ) : (
                <InputText
                  className={cx('fill')}
                  label={
                    feedback.id
                      ? `${feedback.firstName} ${feedback.lastName}`
                      : t('Questions/Shared/ExpertOpinionEditor/you')
                  }
                  name={`expertFeedbacks.${columnIndex}.${index}.feedback`}
                  shouldHideError
                />
              )}
              {index !== feedbacks.length && (
                <Button
                  className={cx('delete')}
                  icon="trash-alt"
                  onClick={() => handleFeedbackRemove(index)}
                  tooltip={t(
                    'Questions/Shared/ExpertOpinionEditor/delete-feedback',
                  )}
                  variant="regular"
                />
              )}
            </div>
          </div>
        ))}
        <Button
          className={cx('add', { large: feedbacks.length })}
          icon="plus"
          intent="primary"
          onClick={handleFeedbackAdd}
        >
          {!feedbacks.length &&
            t('Questions/Shared/ExpertOpinionEditor/add-comment')}
        </Button>
      </div>
    </Modal>
  </div>
);

ExpertOpinionEditor.propTypes = {
  className: PropTypes.string,
  columnIndex: PropTypes.number.isRequired,
  editingLabel: PropTypes.number,
  feedbacks: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleFeedbackAdd: PropTypes.func.isRequired,
  handleFeedbackComplete: PropTypes.func.isRequired,
  handleFeedbackRemove: PropTypes.func.isRequired,
  handleLabelDone: PropTypes.func.isRequired,
  handleLabelEdit: PropTypes.func.isRequired,
  handleKeyDown: PropTypes.func.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  handleModalOpen: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  questionType: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

ExpertOpinionEditor.defaultProps = {
  className: undefined,
  editingLabel: undefined,
};

export default ExpertOpinionEditor;
