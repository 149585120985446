import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './Pin.module.scss';

const cx = classNames.bind(styles);

const Pin = ({ result, sizeData }) => (
  <div
    className={cx('pin', {
      correct: result === 'correct',
    })}
    style={{
      left: `${sizeData.left}px`,
      top: `${sizeData.top}px`,
    }}
  >
    +
  </div>
);

Pin.propTypes = {
  result: PropTypes.string.isRequired,
  sizeData: PropTypes.object.isRequired,
};

export default Pin;
