const EDITOR_STATUSES = {
  AGGREGATED_ANSWERS: 'aggregated-answers',
  CHAPTER_CREATE: 'chapter-create',
  CHAPTER_MOVE: 'chapter-move',
  CHAPTER_UPDATE: 'chapter-update',
  COLLABORATOR_DELETE: 'collaborator-delete',
  COLLABORATORS_INVITE: 'collaborators-invite',
  FILE_IMPORT: 'file-import',
  FOLDER_DELETE: 'folder-delete',
  FOLDER_UPDATE: 'folder-update',
  GAME_CREATE: 'game-create',
  GAME_DELETE: 'game-delete',
  GAME_UPDATE: 'game-update',
  GROUP_DELETE: 'group-delete',
  GROUP_SHARE: 'group-share',
  GROUP_UPDATE: 'group-update',
  QUESTION_APPROVE: 'question-approve',
  QUESTION_COMMENTS: 'question-comments',
  QUESTION_CREATE: 'question-create',
  QUESTION_CREATE_FROM_IA: 'question-create-from-ia',
  QUESTION_IMPORT: 'question-import',
  QUESTION_MOVE: 'question-move',
  QUESTION_MULTI: 'question-multi',
  QUESTION_PREVIEW: 'question-preview',
  QUESTION_PROPOSE: 'question-propose',
  QUESTION_UPDATE: 'question-update',
  QUESTIONS_MOVE: 'questions-move',
  STUDENT_DELETE: 'student-delete',
  STUDENT_ERRORS: 'student-errors',
};

export default EDITOR_STATUSES;
