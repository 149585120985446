import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import ChapterCard from './ChapterCard';

const ChapterCardContainer = ({ chapter, ...props }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const isQuestionBank = pathname.includes('/public-course');
  const [isExpanded, setExpanded] = useState(false);
  const handleChapterExpandedToggle = useCallback((evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    setExpanded((open) => !open);
  }, []);
  return (
    <ChapterCard
      chapter={chapter}
      handleChapterExpandedToggle={handleChapterExpandedToggle}
      isExpanded={isExpanded}
      isQuestionBank={isQuestionBank}
      t={t}
      {...props}
    />
  );
};

ChapterCardContainer.propTypes = {
  chapter: PropTypes.object.isRequired,
};

export default ChapterCardContainer;
