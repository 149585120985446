import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import Context from 'lib/context';

import Verso from './Verso';

const VersoContainer = (props) => {
  const { t } = useTranslation();
  const { theme } = useContext(Context);
  return <Verso {...props} t={t} theme={theme} />;
};

VersoContainer.defaultAnswer = (question) =>
  question.content.choices.map((choice) => ({
    idx: choice.idx,
    answer: false,
  }));

VersoContainer.defaultSolution = (question) =>
  question.content.choices.map((choice) => ({
    idx: choice.idx,
    user: null,
    correct: choice.answer,
    isCorrect: false,
    feedback: '',
  }));

export default VersoContainer;
