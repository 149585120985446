import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import BACKGROUNDS from 'constants/backgrounds';
import TEXTS from 'constants/texts';
import Options from 'questions/Shared/Options';
import Tags from 'questions/Shared/Tags';
import Icon from 'uikit/Icon';
import InputError from 'uikit/InputError';
import ImagePicker from 'uikit/ImagePicker';

import styles from './Editor.module.scss';

const cx = classNames.bind(styles);

const Editor = ({
  background,
  className,
  handleBackgroundSelect,
  handleImageSelect,
  handleModalClose,
  handleModalOpen,
  handleTextColorSelect,
  isModalOpen,
  t,
  theme,
  textColor,
}) => (
  <>
    <div className={cx('container', className)}>
      <p className={cx('label')}>
        {t('Questions/HomeScreen/Editor/background-label')}
      </p>
      {theme && background !== theme && (
        <p className={cx('error')}>
          {t('Questions/HomeScreen/Editor/background-alert')}
        </p>
      )}
      <div className={cx('background-options')}>
        {BACKGROUNDS.map((bg) => (
          <label htmlFor={`background-${bg}`} key={bg}>
            <input
              aria-labelledby={`checkbox-background-${bg}-select`}
              checked={
                background === bg ||
                (bg === 'image-picker' &&
                  !BACKGROUNDS.includes(background) &&
                  background !== null)
              }
              className={cx('check')}
              id={`background-${bg}`}
              type="checkbox"
              onChange={
                bg === 'image-picker'
                  ? handleModalOpen
                  : () => handleBackgroundSelect(bg)
              }
            />
            <span
              className={cx('checkbox', `background-${bg}`, {
                isChecked:
                  background === bg ||
                  (bg === 'image-picker' &&
                    !BACKGROUNDS.includes(background) &&
                    background !== ''),
              })}
            >
              {bg === 'image-picker' && <Icon name="image" />}
            </span>
          </label>
        ))}
      </div>
      <p className={cx('label')}>
        {t('Questions/HomeScreen/Editor/text-color-label')}
      </p>
      <div className={cx('background-options')}>
        {TEXTS.map((color) => (
          <label htmlFor={`color-${color}`} key={color}>
            <input
              aria-labelledby={`checkbox-color-${color}-select`}
              checked={textColor === color}
              className={cx('check')}
              id={`color-${color}`}
              type="checkbox"
              onChange={() => handleTextColorSelect(color)}
            />
            <span
              className={cx('checkbox', `background-${color}`, {
                isChecked: textColor === color,
              })}
            />
          </label>
        ))}
      </div>
      <InputError className={cx('error')} name="background" />
      <Options className={cx('options')}>
        <Tags />
      </Options>
    </div>
    <ImagePicker
      current={BACKGROUNDS.includes(background) ? null : background}
      handleModalClose={handleModalClose}
      isModalOpen={isModalOpen}
      onSelect={handleImageSelect}
    />
  </>
);

Editor.propTypes = {
  background: PropTypes.string,
  className: PropTypes.string,
  handleBackgroundSelect: PropTypes.func.isRequired,
  handleImageSelect: PropTypes.func.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  handleModalOpen: PropTypes.func.isRequired,
  handleTextColorSelect: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
  textColor: PropTypes.string,
};

Editor.defaultProps = {
  className: undefined,
  background: undefined,
  textColor: undefined,
};

export default Editor;
