import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import sanitize from '../../../lib/sanitize';
import Verso from './Verso';

const VersoContainer = ({ question, ...props }) => {
  const { t } = useTranslation();
  const handleSpeech = useCallback(
    (content) => {
      const msg = new SpeechSynthesisUtterance();
      const { speechSynthesis } = window;
      msg.text = sanitize.string(
        content === 'title' ? question.title : question.content.feedback,
      );
      msg.lang =
        content === 'title'
          ? question.content.titleLang
          : question.content.feedbackLang;
      msg.voice = speechSynthesis
        .getVoices()
        .find((voice) => voice.lang.includes(msg.lang.slice(0, 2)));
      window.speechSynthesis.speak(msg);
      window.speechSynthesis.speak(msg);
    },
    [question],
  );

  return (
    <Verso {...props} handleSpeech={handleSpeech} question={question} t={t} />
  );
};

VersoContainer.propTypes = {
  question: PropTypes.object.isRequired,
};
VersoContainer.defaultAnswer = () => undefined;

VersoContainer.defaultSolution = () => null;

export default VersoContainer;
