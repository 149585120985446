import classNames from 'classnames/bind';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import Icon from 'uikit/Icon';
import ImagePicker from 'uikit/ImagePicker';

import styles from './InputImage.module.scss';

const cx = classNames.bind(styles);

const InputImage = ({
  className,
  handleModalClose,
  handleModalOpen,
  handleSelect,
  isDisabled,
  isModalOpen,
  name,
  t,
}) => {
  const [{ value }, meta] = useField(name);
  return (
    <>
      <button
        aria-label={t('uikit/InputImage/select-image')}
        className={cx(
          'container',
          {
            hasError: meta.touched && meta.error,
            isDisabled,
          },
          className,
        )}
        disabled={isDisabled}
        onClick={handleModalOpen}
        style={{
          backgroundImage: `url("${value}")`,
        }}
        type="button"
      >
        {!value && (
          <>
            <Icon name="image" />
            <p>{t('uikit/InputImage/select-image')}</p>
          </>
        )}
        {meta.touched && meta.error && (
          <p className={cx('error-message')} id={`error-${name}`} role="alert">
            {meta.error}
          </p>
        )}
      </button>
      <ImagePicker
        current={value}
        handleModalClose={handleModalClose}
        isModalOpen={isModalOpen}
        onSelect={handleSelect}
      />
    </>
  );
};

InputImage.propTypes = {
  className: PropTypes.string,
  handleModalClose: PropTypes.func.isRequired,
  handleModalOpen: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  isModalOpen: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

InputImage.defaultProps = {
  className: undefined,
  isDisabled: false,
};

export default InputImage;
