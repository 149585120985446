import classNames from 'classnames/bind';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';

import Icon from 'uikit/Icon';

import styles from './InputText.module.scss';

const cx = classNames.bind(styles);

const InputText = React.forwardRef(
  (
    {
      'aria-label': ariaLabel,
      className,
      'data-testid': dataTestId,
      helper,
      icon,
      intent,
      isDisabled,
      label,
      maxRows,
      minRows,
      name,
      placeholder,
      shouldHideError,
      type,
      variant,
      ...props
    },
    ref,
  ) => {
    const [field, meta] = useField(name);
    return (
      <label
        className={cx(
          'container',
          {
            isDisabled,
            hasError: meta.touched && meta.error,
          },
          className,
          `intent-${intent}`,
        )}
        htmlFor={name}
      >
        {label || helper ? (
          <div className={cx('title')}>
            {label ? (
              <div className={cx('label')} id={`inputtext-${name}-label`}>
                {label}
              </div>
            ) : (
              <div className={cx('aria-label')} id={`inputtext-${name}-label`}>
                {ariaLabel || name}
              </div>
            )}
            <div className={cx('helper')} id={`inputtext-${name}-helper`}>
              {helper}
            </div>
          </div>
        ) : (
          <div className={cx('aria-label')} id={`inputtext-${name}-label`}>
            {ariaLabel || name}
          </div>
        )}
        {type === 'textarea' ? (
          <div className={cx('textarea-container')}>
            <TextareaAutosize
              aria-label={name}
              aria-labelledby={`inputtext-${name}-label`}
              aria-describedby={`inputtext-${name}-helper`}
              className={cx('textarea')}
              disabled={isDisabled}
              id={name}
              maxRows={maxRows}
              minRows={minRows}
              name={name}
              placeholder={placeholder}
              rows={1}
              {...field}
              {...props}
            />
          </div>
        ) : (
          <div className={cx('input-container', variant)}>
            {icon && <Icon className={cx('icon')} name={icon} />}
            <input
              aria-label={placeholder || label}
              aria-labelledby={`inputtext-${name}-label`}
              aria-describedby={`inputtext-${name}-helper`}
              className={cx('input', { 'small-padding': icon })}
              data-testid={dataTestId}
              disabled={isDisabled}
              id={name}
              name={name}
              placeholder={placeholder}
              ref={ref}
              type={type}
              {...field}
              {...props}
            />
          </div>
        )}
        {!shouldHideError && meta.touched && meta.error && (
          <p className={cx('error-message')} id={`error-${name}`} role="alert">
            {meta.error}
          </p>
        )}
      </label>
    );
  },
);

InputText.propTypes = {
  'aria-label': PropTypes.string,
  className: PropTypes.string,
  'data-testid': PropTypes.string,
  helper: PropTypes.node,
  icon: PropTypes.string,
  intent: PropTypes.oneOf(['default', 'gray']),
  isDisabled: PropTypes.bool,
  label: PropTypes.node,
  maxRows: PropTypes.number,
  minRows: PropTypes.number,
  name: PropTypes.string.isRequired,
  shouldHideError: PropTypes.bool,
  type: PropTypes.string,
  variant: PropTypes.string,
};

InputText.defaultProps = {
  'aria-label': null,
  className: undefined,
  'data-testid': null,
  helper: undefined,
  icon: undefined,
  intent: 'default',
  isDisabled: false,
  label: undefined,
  maxRows: undefined,
  minRows: 1,
  placeholder: undefined,
  shouldHideError: false,
  type: 'text',
  variant: undefined,
};

export default InputText;
