import React, { useContext, useMemo } from 'react';

import { useQueryLocalStudy } from 'gql/queries/local';
import Context from 'lib/context';

import Verso from './Verso';

const VersoContainer = ({ question, ...props }) => {
  const { theme } = useContext(Context);

  const {
    data: {
      study: { questions },
    },
  } = useQueryLocalStudy();

  const isScrollable = useMemo(
    () =>
      question.solution.length +
        question.solution.filter((solution) => !solution.isCorrect).length >
      8,
    [question],
  );

  const previousAnswers = question.isRepeated ? questions.find(q => q.id === question.id).solution : [];

  const answers = question.solution.map((answer, index) => ({
    ...answer,
    isForced: previousAnswers[index]?.isForced ?? false,
  }));

  return (
    <Verso
      {...props}
      answers={answers}
      isScrollable={isScrollable}
      question={question}
      theme={theme}
    />
  );
};

VersoContainer.defaultAnswer = (question) =>
  (question.content.legends || []).map(() => '');

VersoContainer.defaultSolution = (question) =>
  question.content.legends.map((blank) => ({
    user: null,
    correct: blank.content,
    isCorrect: false,
  }));

export default VersoContainer;
