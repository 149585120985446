import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import sanitize from 'lib/sanitize';
import Button from 'uikit/Button';
import CleanedText from 'uikit/CleanedText';
import Icon from 'uikit/Icon';

import styles from './QuestionCard.module.scss';

const cx = classNames.bind(styles);

const QuestionCard = ({
  allowPreview,
  className,
  handleQuestionPreview,
  handleQuestionSelect,
  isSelected,
  question,
  questionInfo,
  t,
}) => (
  <div className={cx('container', className)}>
    <label htmlFor={question.id}>
      <input
        aria-label={sanitize.string(question.title)}
        checked={isSelected}
        className={cx('check')}
        id={question.id}
        type="checkbox"
        onChange={handleQuestionSelect}
      />
      <span
        className={cx('checkbox', {
          isChecked: isSelected,
        })}
      >
        <Icon name="check" />
      </span>
    </label>
    <p className={cx('icon')}>
      <img alt="" src={questionInfo.icon} />
    </p>
    <CleanedText className={cx('title')}>{question.title}</CleanedText>
    {allowPreview && (
      <Button
        aria-label={t('uikit/InputQuestionTree/QuestionCard/preview', {
          name: sanitize.string(question.title),
        })}
        icon="eye"
        onClick={() => handleQuestionPreview(question)}
      />
    )}
  </div>
);

QuestionCard.propTypes = {
  allowPreview: PropTypes.bool,
  className: PropTypes.string,
  handleQuestionPreview: PropTypes.func,
  handleQuestionSelect: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  question: PropTypes.object.isRequired,
  questionInfo: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

QuestionCard.defaultProps = {
  allowPreview: undefined,
  className: undefined,
  handleQuestionPreview: undefined,
};

export default QuestionCard;
