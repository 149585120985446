import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { notify } from 'uikit/Notifications';

import DirectLink from './DirectLink';

const DirectLinkContainer = ({ course, group, ...props }) => {
  const { t } = useTranslation();
  const link = course.moodle.isMoodle
    ? `${process.env.REACT_APP_WEB_URL}/moodle/${course.accessCode}`
    : group
    ? `${process.env.REACT_APP_WEB_URL}/join/${course.accessCode}/${group.id}?from=1`
    : `${process.env.REACT_APP_WEB_URL}/join/${course.accessCode}?from=1`;
  const handleCopy = useCallback(() => {
    document.getElementById('share-link').select();
    document.execCommand('copy');
    notify('success', t('Course/Teacher/Shared/DirectLink/link-copied'));
  }, [t]);
  return <DirectLink handleCopy={handleCopy} link={link} t={t} {...props} />;
};

DirectLinkContainer.propTypes = {
  course: PropTypes.object.isRequired,
  group: PropTypes.object,
};

DirectLinkContainer.defaultProps = {
  group: undefined,
};

export default DirectLinkContainer;
