import { PropTypes } from 'prop-types';
import classNames from 'classnames/bind';

import FormattedText from 'uikit/FormattedText';
import Icon from 'uikit/Icon';

import ELEPHANT_IMAGE from './images/elephant.svg';
import styles from './Feedback.module.scss';

const cx = classNames.bind(styles);

const Feedback = ({ hasMascotFlag, feedback, theme }) => (
  <div className={cx(`theme-${theme}`)}>
    {hasMascotFlag ? (
      <div className={cx('mascot-feedback')}>
        <img src={ELEPHANT_IMAGE} alt="" />
        <div className={cx('mascot-feedback-content')}>
          <FormattedText>{feedback}</FormattedText>
        </div>
      </div>
    ) : (
      <div className={cx('feedback')}>
        <div className={cx('feedback-icon')}>
          <Icon name="info-circle" variant="duotone" />
        </div>
        <FormattedText>{feedback}</FormattedText>
      </div>
    )}
  </div>
);

Feedback.propTypes = {
  hasMascotFlag: PropTypes.bool.isRequired,
  feedback: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
};

export default Feedback;
