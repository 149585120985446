import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';

import Collapse from './Collapse';
import CollapseItem from './CollapseItem';

const CollapseContainer = ({ defaultIndex, ...props }) => {
  const [openIndex, setOpenIndex] = useState(defaultIndex);
  const handleToggle = useCallback(
    (index) => {
      setOpenIndex(openIndex === index ? undefined : index);
    },
    [openIndex],
  );
  return (
    <Collapse handleToggle={handleToggle} openIndex={openIndex} {...props} />
  );
};

CollapseContainer.propTypes = {
  defaultIndex: PropTypes.number,
};

CollapseContainer.defaultProps = {
  defaultIndex: undefined,
};

CollapseContainer.Item = CollapseItem;

export default CollapseContainer;
