import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useModal from 'hooks/useModal';

import ExpertOpinionEditor from './ExpertOpinionEditor';

const ExpertOpinionEditorContainer = ({ columnIndex, ...props }) => {
  const { t } = useTranslation();
  const [{ value: labels }] = useField('labels');
  const [editingLabel, setEditingLabel] = useState(undefined);
  const handleLabelDone = useCallback(() => {
    setEditingLabel(null);
  }, []);
  const handleKeyDown = useCallback((e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setEditingLabel(null);
    }
  }, []);
  const { isModalOpen, handleModalOpen, handleModalClose } = useModal(false);
  const [{ value: expertFeedbacks }, , { setValue: setExpertFeedbacks }] =
    useField('expertFeedbacks');
  const feedbacks = expertFeedbacks[columnIndex];
  const handleFeedbackAdd = useCallback(() => {
    setExpertFeedbacks(
      expertFeedbacks.map((columnFeedbacks, index) =>
        index === columnIndex
          ? [
              ...columnFeedbacks,
              {
                id: undefined,
                firstName: undefined,
                lastName: undefined,
                feedback: '',
              },
            ]
          : columnFeedbacks,
      ),
    );
  }, [columnIndex, expertFeedbacks, setExpertFeedbacks]);
  const handleFeedbackComplete = useCallback(
    (model) => {
      if (model) {
        setExpertFeedbacks(
          expertFeedbacks.map((columnFeedbacks, index) =>
            index === columnIndex
              ? [
                  ...columnFeedbacks,
                  {
                    id: undefined,
                    firstName: undefined,
                    lastName: undefined,
                    feedback: model.nativeEvent.data,
                  },
                ]
              : columnFeedbacks,
          ),
        );
      }
    },
    [columnIndex, expertFeedbacks, setExpertFeedbacks],
  );
  const handleFeedbackRemove = useCallback(
    (feedbackIndex) => {
      const newFeedbacks = expertFeedbacks.map((columnFeedbacks, index) =>
        index === columnIndex
          ? columnFeedbacks
              .map((fb, i) =>
                i === feedbackIndex ? { ...fb, feedback: '' } : fb,
              )
              .filter((fb) => fb.id || fb.feedback)
          : columnFeedbacks,
      );
      setExpertFeedbacks(newFeedbacks);
    },
    [columnIndex, expertFeedbacks, setExpertFeedbacks],
  );
  return (
    <ExpertOpinionEditor
      columnIndex={columnIndex}
      editingLabel={editingLabel}
      feedbacks={feedbacks}
      handleLabelDone={handleLabelDone}
      handleLabelEdit={setEditingLabel}
      handleKeyDown={handleKeyDown}
      handleFeedbackAdd={handleFeedbackAdd}
      handleFeedbackComplete={handleFeedbackComplete}
      handleFeedbackRemove={handleFeedbackRemove}
      handleModalClose={handleModalClose}
      handleModalOpen={handleModalOpen}
      isModalOpen={isModalOpen}
      labels={labels}
      t={t}
      {...props}
    />
  );
};

ExpertOpinionEditorContainer.propTypes = {
  columnIndex: PropTypes.number.isRequired,
};

export default ExpertOpinionEditorContainer;
