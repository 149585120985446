import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import useSiteContext from 'hooks/useSiteContext';

import InputRich2 from './InputRich2';

const InputRich2Container = (props) => {
  const { t } = useTranslation();
  const { isExternal } = useSiteContext();
  const froalaRef = useRef();
  const handleTemplatesClose = useCallback(() => {
    const sideBars = document.getElementsByClassName(
      'DesignToolbar_container__XbZ9Y',
    );
    const sideBarLimits = document.getElementsByClassName(
      'DesignToolbar_container-limits__X0iIW',
    );
    for (let i = 0; i < sideBars.length; i += 1) {
      sideBars[i]?.classList.remove('DesignToolbar_open__vcKEG');
      sideBarLimits[i]?.classList.remove('DesignToolbar_limits-large__buB5E');
    }
  }, []);
  return (
    <InputRich2
      froalaRef={froalaRef}
      handleTemplatesClose={handleTemplatesClose}
      isExternal={isExternal}
      t={t}
      {...props}
    />
  );
};

export default InputRich2Container;
