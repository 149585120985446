import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { getQuestionInfo } from 'questions';
import { mutationEditorQuestionComments } from 'gql/mutations/editor';
import QuestionCard from './QuestionCard';

const QuestionCardContainer = ({ question, ...props }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const isQuestionBank = pathname.includes('/public-course');
  const questionInfo = getQuestionInfo(question.type);
  const handleQuestionComments = useCallback(() => {
    mutationEditorQuestionComments(question);
  }, [question]);
  return (
    <QuestionCard
      question={question}
      questionInfo={questionInfo}
      handleQuestionComments={handleQuestionComments}
      isQuestionBank={isQuestionBank}
      t={t}
      {...props}
    />
  );
};

QuestionCardContainer.propTypes = {
  question: PropTypes.object.isRequired,
};

export default QuestionCardContainer;
