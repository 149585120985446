import classNames from 'classnames/bind';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Icon from 'uikit/Icon';

import styles from './InputFile.module.scss';

const cx = classNames.bind(styles);

const InputFile = ({
  className,
  fileName,
  handleDragEnter,
  handleDragLeave,
  handleImport,
  helper,
  isDisabled,
  isDroppable,
  label,
  name,
  ...props
}) => {
  const { t } = useTranslation();
  const [, meta] = useField(name);
  return (
    <label
      className={cx(
        'container',
        {
          isDisabled,
          hasError: meta.touched && meta.error,
        },
        className,
      )}
      htmlFor={name}
    >
      {(label || helper) && (
        <div className={cx('title')} id={`inputfile-${name}-label`}>
          <div className={cx('label')}>{label}</div>
          <div className={cx('helper')}>{helper}</div>
        </div>
      )}
      <div
        className={cx('dropzone', { isDroppable })}
        onDragEnter={handleDragEnter}
        onDragEnd={handleDragLeave}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragEnter}
        onDrop={handleImport}
      >
        <label aria-label={t('uikit/InputFile/import-file')} htmlFor="file">
          <input
            accept="*"
            id="file"
            name="file"
            onChange={handleImport}
            type="file"
            {...props}
          />
          <div className={cx('input-content')}>
            <Icon className={cx('icon')} name="cloud-upload" />
            <p className={cx('text')}>
              <strong>{fileName || t('uikit/InputFile/import-file')}</strong>
            </p>
            {meta.touched && meta.error && (
              <p
                className={cx('error-message')}
                id={`error-${name}`}
                role="alert"
              >
                {meta.error}
              </p>
            )}
          </div>
        </label>
      </div>
    </label>
  );
};

InputFile.propTypes = {
  className: PropTypes.string,
  fileName: PropTypes.string,
  handleDragEnter: PropTypes.func.isRequired,
  handleDragLeave: PropTypes.func.isRequired,
  handleImport: PropTypes.func.isRequired,
  helper: PropTypes.node,
  isDisabled: PropTypes.bool,
  isDroppable: PropTypes.bool.isRequired,
  label: PropTypes.node,
  name: PropTypes.string.isRequired,
};

InputFile.defaultProps = {
  className: undefined,
  fileName: undefined,
  helper: undefined,
  isDisabled: false,
  label: undefined,
};

export default InputFile;
