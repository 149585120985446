import React from 'react';

import useSiteContext from 'hooks/useSiteContext';

import InputMath from './InputMath';

const InputMathContainer = (props) => {
  const { isExternal } = useSiteContext();
  return <InputMath isExternal={isExternal} {...props} />;
};

export default InputMathContainer;
