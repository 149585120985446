import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import CheckBoxTrueFalse from 'uikit/CheckBoxTrueFalse';
import FormattedText from 'uikit/FormattedText';

import styles from './Recto.module.scss';

const cx = classNames.bind(styles);

const Recto = ({ className, isDisabled, question, t, theme }) => (
  <div className={cx('container', className, `theme-${theme}`)}>
    <FormattedText as="h1" className={cx('title')}>
      {question.title}
    </FormattedText>
    {!isDisabled && (
      <div className={cx('instructions')}>
        {t('Questions/TrueOrFalse/Recto/true-or-false-instructions')}
      </div>
    )}
    {question.content.choices.map((choice, index) => {
      const answerIndex = question.answer
        ? question.answer.findIndex((a) => a.idx === choice.idx)
        : index;
      // eslint-disable-next-line
      return (
        <div className={cx('choice')} key={choice.idx}>
          <FormattedText className={cx('choice-title')}>
            {choice.title}
          </FormattedText>
          <CheckBoxTrueFalse
            isDisabled={isDisabled}
            name={`answer.${answerIndex}.answer`}
            shouldHideError
          />
        </div>
      );
    })}
  </div>
);

Recto.propTypes = {
  className: PropTypes.string,
  isDisabled: PropTypes.bool.isRequired,
  question: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
};

Recto.defaultProps = {
  className: undefined,
};

export default Recto;
