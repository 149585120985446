import classNames from 'classnames/bind';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import storeJS from 'store';
import * as yup from 'yup';

import LanguageSelector from 'components/Shared/LanguageSelector';
import COUNTRIES from 'constants/countries';
import { useMutationMeOnboard, useMutationMeUpdate } from 'gql/mutations/me';
import extract from 'helpers/extract';
import orderAlphabetically from 'helpers/orderAlphabetically';
import { trackEvent, reportError } from 'lib/tracking';
import Button from 'uikit/Button';
import CheckBox from 'uikit/CheckBox';
import InputText from 'uikit/InputText';
import Select from 'uikit/Select';

import styles from './PersonalInfo.module.scss';

const cx = classNames.bind(styles);

const PersonalInfo = ({ me, onBack }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'App/Onboarding/PersonalInfo',
  });

  const mutationMeUpdate = useMutationMeUpdate();

  const mutationMeOnboard = useMutationMeOnboard();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        firstName: yup
          .string()
          .required(t('first-name-error-required'))
          .trim()
          .max(256, t('first-name-error-required')),
        lastName: yup
          .string()
          .required(t('last-name-error-required'))
          .trim()
          .max(256, t('last-name-error-required')),
        wantsMarketing: yup.boolean().default(false),
        institution: yup.string().trim().max(256),
        branch: yup.string().trim().max(256),
        country: yup.string().trim().max(256),
      }),
    [t],
  );

  const countries = orderAlphabetically(
    COUNTRIES[me.language === 'fr' ? 'fr' : 'en'],
  );

  const handlePersonalInfoUpdate = async (values, form) => {
    try {
      form.setStatus(null);
      const variables = extract(values, validationSchema);
      await mutationMeUpdate(variables);
      const referralCode = storeJS.get('referralCode');
      const referralDate = storeJS.get('referralDate');
      await mutationMeOnboard({
        referralCode,
        referralDate,
      });
      if (window.$crisp && window.$crisp.push) {
        window.$crisp.push(['safe', true]);
        window.$crisp.push([
          'set',
          'user:nickname',
          [`${values.firstName} ${values.lastName}`],
        ]);
        window.$crisp.push(['set', 'user:email', [me.email]]);
        window.$crisp.push(['set', 'user:avatar', [me.picture]]);
        window.$crisp.push(['set', 'user:company', [values.institution]]);
        window.$crisp.push([
          'set',
          'session:segments',
          [
            [
              'onboarded',
              `language-${me.language}`,
              `sector-${me.sector}`,
              `role-${me.role}`,
              `marketing-${values.wantsMarketing ? 'on' : 'off'}`,
              ...me.tags,
            ],
          ],
        ]);
        window.$crisp.push([
          'set',
          'session:data',
          [
            [
              ['id', me.id],
              ['firstName', values.firstName],
              ['lastName', values.lastName],
              ['username', me.username],
              ['email', me.email],
              ['language', me.language],
              ['sector', me.sector],
              ['role', me.role],
              ['institution', values.institution],
              ['branch', values.branch],
            ],
          ],
        ]);
        if (me.role !== 'teacher') {
          window.$crisp.push(['do', 'chat:hide']);
        }
        window.$crisp.push(['set', 'session:event', ['user:onboarded']]);
      }
      trackEvent('onboarding', 'info');
    } catch (err) {
      form.setStatus(err.message);
      reportError('onboarding', 'info', err);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={me}
      onSubmit={handlePersonalInfoUpdate}
      validationSchema={validationSchema}
    >
      {({ isSubmitting, status }) => (
        <Form className={cx('container')}>
          <div
            className={cx('form-container')}
            // Specific style applied to the form container to handle height issue on mobile
            // src: https://chanind.github.io/javascript/2019/09/28/avoid-100vh-on-mobile-web.html
            style={{ height: window.innerHeight }}
          >
            <div className={cx('header')}>
              <Button
                aria-label={t('back')}
                className={cx('back')}
                icon="arrow-left"
                intent="gray"
                onClick={onBack}
              />
              <div className={cx('points')}>
                {[1, 2, 3].map((element) => (
                  <div className={cx('point')} key={element} />
                ))}
              </div>
              <LanguageSelector className={cx('language')} intent="square" />
            </div>
            <div className={cx('form')}>
              <div className={cx('form-content')}>
                <h1 className={cx('title')}>{t('title')}</h1>
                {status && (
                  <p className={cx('error')} role="alert">
                    {t('onboarding-error')}
                  </p>
                )}
                <InputText
                  className={cx('field')}
                  data-testid="first-name"
                  intent="gray"
                  placeholder={t('first-name-label')}
                  name="firstName"
                />
                <InputText
                  className={cx('field')}
                  data-testid="last-name"
                  intent="gray"
                  placeholder={t('last-name-label')}
                  name="lastName"
                />
                {me.sector === 'lower' ? (
                  <InputText
                    className={cx('field')}
                    intent="gray"
                    placeholder={t('school-label')}
                    name="institution"
                  />
                ) : me.sector === 'higher' ? (
                  <>
                    <InputText
                      className={cx('field')}
                      intent="gray"
                      placeholder={t('institution-label')}
                      name="institution"
                    />
                    <InputText
                      className={cx('field')}
                      intent="gray"
                      placeholder={t('branch-label')}
                      name="branch"
                    />
                  </>
                ) : (
                  <InputText
                    className={cx('field')}
                    intent="gray"
                    placeholder={t('company-label')}
                    name="institution"
                  />
                )}
                <Select className={cx('field')} intent="gray" name="country">
                  <option value="">{t('country')}</option>
                  {countries.map((country) => (
                    <option key={country.key} value={country.key}>
                      {country.name}
                    </option>
                  ))}
                </Select>
                <CheckBox
                  intent="gray"
                  label={t(`marketing-label-${me.role}`)}
                  name="wantsMarketing"
                />
              </div>
              <Button
                className={cx('action')}
                data-testid="update-personal-info-button"
                intent="primary"
                isLoading={isSubmitting}
                size="large"
                type="submit"
              >
                {t('update-personal-info')}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

PersonalInfo.propTypes = {
  me: PropTypes.object.isRequired,
  onBack: PropTypes.func.isRequired,
};

export default PersonalInfo;
