import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import EDITOR_STATUSES from 'constants/editorStatuses';
import {
  mutationEditorAggregatedAnswers,
  mutationEditorClear,
  mutationEditorQuestionUpdate,
  mutationEditorQuestionSelect,
  mutationEditorQuestionSelectMultiple,
  mutationEditorQuestionMove,
  mutationEditorQuestionPreview,
  mutationEditorQuestionComments,
  mutationEditorStudentErrors,
} from 'gql/mutations/editor';
import {
  useMutationQuestionDelete,
  useMutationQuestionDuplicate,
} from 'gql/mutations/question';
import { useQueryLocalEditor } from 'gql/queries/local';
import { useQueryMe } from 'gql/queries/me';
import useSiteContext from 'hooks/useSiteContext';
import sanitize from 'lib/sanitize';
import { trackEvent, reportError } from 'lib/tracking';
import { getQuestionInfo } from 'questions';
import Button from 'uikit/Button';
import CleanedText from 'uikit/CleanedText';
import Icon from 'uikit/Icon';
import Menu, { MenuItem } from 'uikit/Menu';
import { notify } from 'uikit/Notifications';
import Tooltip from 'uikit/Tooltip';

import styles from './QuestionCard.module.scss';

const cx = classNames.bind(styles);

const QuestionCard = ({
  className = null,
  course,
  isSelected,
  nQuestionsSelected,
  position,
  question,
  questions,
  questionsSelected,
}) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/Editor/Finder/QuestionCard',
  });

  const mutationQuestionDelete = useMutationQuestionDelete();

  const mutationQuestionDuplicate = useMutationQuestionDuplicate();

  const questionRef = useRef(null);

  const { isNewQuestionEditorUIDisplayed } = useSiteContext();

  const { data } = useQueryMe();

  const {
    data: {
      editor: { status: editorStatus },
    },
  } = useQueryLocalEditor();

  useEffect(() => {
    const shouldScroll =
      isNewQuestionEditorUIDisplayed &&
      [
        EDITOR_STATUSES.QUESTION_APPROVE,
        EDITOR_STATUSES.QUESTION_CREATE,
        EDITOR_STATUSES.QUESTION_PREVIEW,
        EDITOR_STATUSES.QUESTION_UPDATE,
      ].includes(editorStatus);
    if (isSelected && questionRef.current && shouldScroll) {
      questionRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [editorStatus, isNewQuestionEditorUIDisplayed, isSelected, questionRef]);

  const handleAnswersAggregate = (event) => {
    event.stopPropagation();
    mutationEditorAggregatedAnswers(question);
  };

  const handleExpertLinkCopy = (event) => {
    event.stopPropagation();
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_WEB_URL}/expert/${course.id}/${question.id}`,
    );
    notify('success', t('expert-link-duplicated'));
  };

  const handleQuestionClick = () => {
    mutationEditorQuestionUpdate(question);
  };

  const handleQuestionComments = (event) => {
    event.stopPropagation();
    mutationEditorQuestionComments(question);
  };

  const handleQuestionDelete = async (event) => {
    event.stopPropagation();
    try {
      mutationEditorClear();
      await mutationQuestionDelete(question);
      trackEvent('question', 'delete');
    } catch (err) {
      notify('error', t('question-delete-error'));
      reportError('question', 'delete', err);
    }
  };

  const handleQuestionDuplicate = async (event) => {
    event.stopPropagation();
    try {
      await mutationQuestionDuplicate(question);
      trackEvent('question', 'duplicate');
    } catch (err) {
      notify('error', t('question-duplicate-error'));
      reportError('question', 'duplicate', err);
    }
  };

  const handleQuestionMove = (event) => {
    event.stopPropagation();
    mutationEditorQuestionMove(question);
  };

  const handleQuestionPreview = (event) => {
    event.stopPropagation();
    mutationEditorQuestionPreview(question);
  };

  const handleQuestionSelect = (evt) => {
    evt.preventDefault();
    document.getSelection().removeAllRanges();
    const lastSelectedQuestionFromSameChapter = questionsSelected
      .filter(
        (questionSelected) => questionSelected.chapterId === question.chapterId,
      )
      .pop();
    if (evt.shiftKey && lastSelectedQuestionFromSameChapter) {
      mutationEditorQuestionSelectMultiple({
        lastQuestion: lastSelectedQuestionFromSameChapter,
        question,
        questions,
      });
    } else {
      mutationEditorQuestionSelect(question);
    }
  };

  const handleQuestionUpdate = () => {
    mutationEditorQuestionUpdate(question);
  };

  const handleStudentErrors = (event) => {
    event.stopPropagation();
    mutationEditorStudentErrors(question);
  };

  const questionInfo = getQuestionInfo(question.type);

  const menuItems = [
    {
      isVisible: true,
      icon: 'edit',
      function: handleQuestionUpdate,
      translation: t('update-question'),
    },
    {
      isVisible: true,
      icon: 'eye',
      function: handleQuestionPreview,
      translation: t('preview-question'),
    },
    {
      isVisible: true,
      icon: 'exclamation-square',
      function: handleStudentErrors,
      translation: t('student-errors'),
    },
    {
      isVisible: true,
      icon: 'seal-question',
      function: handleAnswersAggregate,
      translation: t('aggregated-answers'),
    },
    {
      isVisible: true,
      icon: 'arrow-square-right',
      function: handleQuestionMove,
      translation: t('move-question'),
    },
    {
      isVisible: true,
      icon: 'copy',
      function: handleQuestionDuplicate,
      translation: t('duplicate-question'),
    },
    {
      isVisible: questionInfo.expert,
      icon: 'user-tie',
      function: handleExpertLinkCopy,
      translation: t('copy-expert-link'),
    },
    {
      isVisible: true,
      icon: 'trash-alt',
      function: handleQuestionDelete,
      translation: t('delete-question'),
    },
  ];

  const me = data?.me;

  return (
    <div ref={questionRef}>
      <Draggable
        aria-label={sanitize.string(question.title)}
        draggableId={question.id}
        index={question.order}
      >
        {(providedQuestion, snapshotQuestion) => (
          <div
            {...providedQuestion.draggableProps}
            className={cx(
              'container',
              {
                isDragging: snapshotQuestion.isDragging,
                isSelected,
              },
              className,
            )}
            ref={providedQuestion.innerRef}
            role="button"
          >
            <p
              className={cx('aria-drag-description')}
              id="question-aria-drag-description"
            >
              {t('aria-drag-description')}
            </p>
            <button
              aria-label={t('edit-question', {
                name: sanitize.string(question.title),
              })}
              className={cx('aria-edit-button')}
              onClick={handleQuestionClick}
              type="button"
            />
            {snapshotQuestion.isDragging && nQuestionsSelected > 1 && (
              <div className={cx('number')}>{nQuestionsSelected}</div>
            )}
            <div
              {...providedQuestion.dragHandleProps}
              aria-describedby="question-aria-drag-description"
              aria-label={t('question-move', {
                name: sanitize.string(question.title),
              })}
              className={cx('sort')}
              role="button"
            >
              <Icon name="bars" />
            </div>
            <button
              aria-label={t('select-question', {
                name: sanitize.string(question.title),
              })}
              className={cx('checkbox-container')}
              htmlFor={`checkbox${question.id}`}
              onClick={handleQuestionSelect}
              type="button"
            >
              <span
                className={cx('checkbox', {
                  isChecked: isSelected,
                })}
              >
                <Icon name="check" />
              </span>
            </button>
            <div
              className={cx('clickable-area')}
              data-testid={`question-card-${sanitize.string(question.title)}`}
              onClick={handleQuestionClick}
              role="button"
              tabIndex={-1}
            >
              <p className={cx('icon', `background-${questionInfo.color}`)}>
                {snapshotQuestion.isDragging ? (
                  <Icon name="sort" />
                ) : (
                  <img alt="" src={questionInfo.icon} />
                )}
              </p>
              <p className={cx('position')}>{position}.</p>
              <CleanedText as="p" className={cx('title')}>
                {question.title}
              </CleanedText>
              {question.author && question.author.id !== me.id && (
                <div className={cx('author')}>
                  <Icon name="user-graduate" />
                  <span className={cx('author-name')}>
                    {`${question.author.firstName || ''} ${
                      question.author.lastName || ''
                    }`}
                  </span>
                </div>
              )}
              {course.isExam && !questionInfo.test && (
                <Tooltip tooltip={t('disabled-exam')}>
                  <Icon
                    className={cx('disabled')}
                    name="exclamation-triangle"
                  />
                </Tooltip>
              )}
              {!course.isExam && !questionInfo.study && (
                <Tooltip tooltip={t('disabled-study')}>
                  <Icon
                    className={cx('disabled')}
                    name="exclamation-triangle"
                  />
                </Tooltip>
              )}
              {question.comments.length > 0 && (
                <>
                  <Button
                    aria-label={t('aria-comments')}
                    className={cx('action')}
                    icon="comment"
                    size="small"
                    onClick={handleQuestionComments}
                  />
                  {question.comments.some((comment) => !comment.isRead) && (
                    <div className={cx('comment-notification')} />
                  )}
                </>
              )}
              <Menu
                placement="bottom-end"
                toggle={
                  <Button
                    aria-label={t('aria-options', {
                      name: sanitize.string(question.title),
                    })}
                    className={cx('action')}
                    icon="ellipsis-v"
                    size="small"
                    tooltip={t('options')}
                  />
                }
              >
                {menuItems
                  .filter((menuItem) => menuItem.isVisible)
                  .map((menuItem) => (
                    <MenuItem
                      icon={menuItem.icon}
                      key={`menu-item-key-${menuItem.translation}`}
                      onClick={menuItem.function}
                    >
                      {menuItem.translation}
                    </MenuItem>
                  ))}
              </Menu>
            </div>
          </div>
        )}
      </Draggable>
    </div>
  );
};

QuestionCard.propTypes = {
  className: PropTypes.string,
  course: PropTypes.object.isRequired,
  isExam: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  nQuestionsSelected: PropTypes.number.isRequired,
  position: PropTypes.number.isRequired,
  question: PropTypes.object.isRequired,
  questions: PropTypes.arrayOf(PropTypes.object).isRequired,
  questionsSelected: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default QuestionCard;
