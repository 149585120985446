import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useMutationCoursePin } from 'gql/mutations/course';
import tutorialIllustrationsChecker from 'helpers/tutorialIllustrationsChecker';

import CourseCard from './CourseCard';

const CourseCardContainer = ({ course, ...props }) => {
  const { t } = useTranslation();
  const { needTutorialIllustration1, needTutorialIllustration2 } =
    tutorialIllustrationsChecker(course.id);
  const mutationCoursePin = useMutationCoursePin();
  const handleCoursePin = useCallback(
    async (evt) => {
      try {
        evt.preventDefault();
        evt.stopPropagation();
        await mutationCoursePin(course);
      } catch (err) {
        //
      }
    },
    [course, mutationCoursePin],
  );
  const illustrationIndex = useMemo(
    () => parseInt(course.id.substring(0, 8).replace(/\D/g, ''), 10) % 4 || 0,
    [course],
  );
  return (
    <CourseCard
      course={course}
      handleCoursePin={handleCoursePin}
      illustrationIndex={illustrationIndex}
      needTutorialIllustration1={needTutorialIllustration1}
      needTutorialIllustration2={needTutorialIllustration2}
      t={t}
      {...props}
    />
  );
};

CourseCardContainer.propTypes = {
  course: PropTypes.object.isRequired,
};

export default CourseCardContainer;
