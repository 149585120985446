import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import Questions from './Questions';

const QuestionsContainer = ({ chapterId, course, ...props }) => {
  const questions = useMemo(
    () =>
      (chapterId === null
        ? course.questions
        : course.chapters.find((c) => c.id === chapterId)?.questions) || [],
    [chapterId, course],
  );
  return <Questions isExam={course.isExam} questions={questions} {...props} />;
};

QuestionsContainer.propTypes = {
  chapterId: PropTypes.string,
  course: PropTypes.object.isRequired,
};

QuestionsContainer.defaultProps = {
  chapterId: null,
};

export default React.memo(QuestionsContainer);
