import classNames from 'classnames/bind';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AutosizeInput from 'react-input-autosize';

import styles from './Input.module.scss';

const cx = classNames.bind(styles);

const Recto = ({ inputIndex, isDisabled }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Questions/FillInTheBlanks/Recto',
  });

  return (
    <>
      <label className={cx('aria-label')} htmlFor={`answer.${inputIndex - 1}`}>
        {t('input', { number: inputIndex })}
      </label>
      <Field
        as={AutosizeInput}
        autoCapitalize="off"
        autoComplete="off"
        autoCorrect="off"
        autoFocus={inputIndex === 1}
        className={cx('blank', 'field')}
        disabled={isDisabled}
        key={inputIndex - 1}
        id={`answer.${inputIndex - 1}`}
        name={`answer.${inputIndex - 1}`}
        spellCheck="false"
      />
    </>
  );
};

Recto.propTypes = {
  inputIndex: PropTypes.number.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default Recto;
