import parse from 'html-react-parser';

import { LaTeX2HTML, ASCIIMath2HTML, highlight } from 'helpers/text';

const text2React = (text, options) => {
  const t = text
    .replace(/\$(.*?)\$/g, LaTeX2HTML)
    .replace(/`(.*?)`/g, ASCIIMath2HTML)
    .replace(/<math>(.*?)<\/math>/g, ASCIIMath2HTML)
    .replace(/&lt;math&gt;(.*?)&lt;\/math&gt;/g, ASCIIMath2HTML)
    .replace(/<pre><code([^>]*)>(.+?)<\/code><\/pre>/gs, highlight)
    .replace(/\[{2}.*?\]*\]{2}/g, '<input />')
    .replace(
      /\{{2}(.*?\}*)\}{2}/g,
      (_, x) => `<select data="${encodeURIComponent(x)}"></select>`,
    );
  return parse(t, options);
};

export default text2React;
