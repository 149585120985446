import React from 'react';

import { useQueryMe } from 'gql/queries/me';

const OpenDyslexicContainer = () => {
  const { isLoading, data } = useQueryMe();
  const isDyslexic = isLoading
    ? false
    : data.me.tags.includes('accessibility:dyslexia');
  return !isDyslexic ? null : (
    <style>
      {`
        body,
        .fr-view{
          font-family: OpenDyslexic, Nunito, Arial, sans-serif !important;
        }
      `}
    </style>
  );
};

export default OpenDyslexicContainer;
