import { fabric } from 'fabric';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import useModal from 'hooks/useModal';

import MediaToolbar from './MediaToolbar';
import { saveHistory } from '../config/history';

const MediaToolbarContainer = ({ fabricRef, ...props }) => {
  const { t } = useTranslation();
  const { isModalOpen, handleModalClose, handleModalOpen } = useModal(false);
  const handleImageAdd = useCallback(
    (imageUrl) => {
      saveHistory(fabricRef);
      fabric.Image.fromURL(imageUrl, (img) => {
        img.set({ snapAngle: 15 });
        fabricRef.current.add(img).centerObject(img);
      });
      handleModalClose();
    },
    [fabricRef, handleModalClose],
  );
  return (
    <MediaToolbar
      fabricRef={fabricRef}
      handleImageAdd={handleImageAdd}
      handleModalClose={handleModalClose}
      handleModalOpen={handleModalOpen}
      isModalOpen={isModalOpen}
      t={t}
      {...props}
    />
  );
};

MediaToolbarContainer.propTypes = {
  fabricRef: PropTypes.object,
};

MediaToolbarContainer.defaultProps = {
  fabricRef: undefined,
};

export default MediaToolbarContainer;
