import classNames from 'classnames/bind';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'uikit/Button';
import Icon from 'uikit/Icon';

import styles from './ContributeCard.module.scss';

const cx = classNames.bind(styles);

const ContributeCard = () => {
  const { t } = useTranslation('', {
    keyPrefix:
      'Course/Teacher/Editor/QuestionImport/QuestionImportPublicCourses',
  });

  return (
    <div className={cx('container')}>
      <div className={cx('icon')}>
        <Icon name="sad-tear" />
      </div>
      <div className={cx('text')}>
        <p className={cx('title')}>{t('ContributeCard/title')}</p>
        <p className={cx('subtitle')}>{t('ContributeCard/subtitle')}</p>
      </div>
      <Button as="a" href={t('ContributeCard/helpdesk-link')} intent="primary">
        {t('ContributeCard/contribute')}
      </Button>
    </div>
  );
};

export default ContributeCard;
