import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import Context from 'lib/context';

import Recto from './Recto';

const RectoContainer = ({ isDisabled, question, ...props }) => {
  const { t } = useTranslation();
  const { theme } = useContext(Context);
  const [selectedSourceIndex, setSelectedSourceIndex] = useState(-1);
  const [{ value: answer }, , { setValue: setAnswer }] = useField('answer');
  const handleSourceSelect = useCallback(
    (sourceIndex) => {
      if (!isDisabled) {
        setSelectedSourceIndex(sourceIndex);
      }
    },
    [isDisabled],
  );
  const handleDestinationSelect = useCallback(
    (destinationIdx) => {
      setAnswer(
        answer.map((destinationsIdx, sourceIndex) =>
          sourceIndex === selectedSourceIndex
            ? destinationsIdx.includes(destinationIdx)
              ? destinationsIdx.filter(
                  (SelectedDestinationId) =>
                    SelectedDestinationId !== destinationIdx,
                )
              : [...destinationsIdx, destinationIdx]
            : destinationsIdx.filter(
                (SelectedDestinationId) =>
                  SelectedDestinationId !== destinationIdx,
              ),
        ),
      );
    },
    [answer, selectedSourceIndex, setAnswer],
  );
  const handleDestinationRemove = useCallback(
    (sourceIndex, destinationIdx) => {
      if (!isDisabled) {
        setAnswer(
          answer.map((destinationsIdx, index) =>
            sourceIndex === index
              ? destinationsIdx.filter((idx) => idx !== destinationIdx)
              : destinationsIdx,
          ),
        );
      }
      handleSourceSelect(-1);
    },
    [answer, handleSourceSelect, isDisabled, setAnswer],
  );
  const sources =
    question.content.categories.sources ||
    question.content.categories.map((category) => ({
      idx: category.idx,
      title: category.source,
    }));
  const destinations =
    question.content.categories.destinations ||
    question.content.categories.flatMap((category) => category.destinations);
  const selectedSource =
    selectedSourceIndex === -1 ? undefined : sources[selectedSourceIndex];
  return (
    <Recto
      answer={answer}
      destinations={destinations}
      handleDestinationRemove={handleDestinationRemove}
      handleDestinationSelect={handleDestinationSelect}
      handleSourceSelect={handleSourceSelect}
      isDisabled={isDisabled}
      question={question}
      selectedSource={selectedSource}
      selectedSourceIndex={selectedSourceIndex}
      sources={sources}
      t={t}
      theme={theme}
      {...props}
    />
  );
};

RectoContainer.propTypes = {
  isDisabled: PropTypes.bool,
  question: PropTypes.object.isRequired,
};

RectoContainer.defaultProps = {
  isDisabled: false,
};

RectoContainer.defaultAnswer = (question) =>
  question.content.categories.sources
    ? question.content.categories.sources.map(() => [])
    : question.content.categories.map(() => []);

RectoContainer.validationSchema = () =>
  yup.object().shape({
    answer: yup
      .array()
      .of(yup.array().of(yup.number().integer()).required())
      .test('atLeastOne', (arr) => arr.some((v) => v.length)),
  });

export default RectoContainer;
