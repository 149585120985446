import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import { getDenormalizedPinPosition } from 'questions/Shared/utils/images';
import Pin from './Pin';
import styles from './ImageContainer.module.scss';

const cx = classNames.bind(styles);

const ImageContainer = ({
  answer,
  className,
  handleImageLoaded,
  handlePinDelete,
  image,
  imageRef,
  imageSrc,
  wrapperRef,
  handlePinAdd,
}) => (
  <div className={cx('container', className)} ref={wrapperRef}>
    <button className={cx('wrapper')} onClick={handlePinAdd} type="button">
      <img
        alt="img"
        className={cx('image')}
        draggable="false"
        onLoad={handleImageLoaded}
        ref={imageRef}
        src={imageSrc}
        style={{
          width: image.width,
          height: image.height,
        }}
      />
    </button>
    {image.diagonal && (
      <Pin
        handleDelete={handlePinDelete}
        sizeData={getDenormalizedPinPosition(answer, image.diagonal)}
      />
    )}
  </div>
);

ImageContainer.propTypes = {
  answer: PropTypes.object,
  className: PropTypes.string,
  handleImageLoaded: PropTypes.func.isRequired,
  handlePinAdd: PropTypes.func.isRequired,
  handlePinDelete: PropTypes.func.isRequired,
  image: PropTypes.object.isRequired,
  imageRef: PropTypes.object.isRequired,
  imageSrc: PropTypes.string.isRequired,
  wrapperRef: PropTypes.object.isRequired,
};

ImageContainer.defaultProps = {
  answer: undefined,
  className: undefined,
};

export default ImageContainer;
