import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { FOLDER_DATA } from 'gql/fragments';

export const FOLDER_CREATE = gql`
  mutation FolderCreate($title: String!) {
    folderCreate(title: $title) {
      ...FolderData
    }
  }
  ${FOLDER_DATA}
`;

export function useMutationFolderCreate() {
  const [mutation] = useMutation(FOLDER_CREATE);
  return useCallback(
    (variables) =>
      mutation({
        variables,
        update(cache, { data: { folderCreate } }) {
          const newFolderRef = cache.writeFragment({
            data: folderCreate,
            fragment: FOLDER_DATA,
            fragmentName: 'FolderData',
          });
          cache.modify({
            fields: {
              foldersMe: (existingFoldersRef = [], { readField }) =>
                [...existingFoldersRef, newFolderRef].sort((a, b) =>
                  readField('title', a).localeCompare(
                    readField('title', b),
                    undefined,
                    {
                      numeric: true,
                      sensitivity: 'base',
                    },
                  ),
                ),
            },
          });
        },
      }),
    [mutation],
  );
}

export const FOLDER_DELETE = gql`
  mutation FolderDelete($folderId: ID!) {
    folderDelete(folderId: $folderId)
  }
`;

export function useMutationFolderDelete() {
  const [mutation] = useMutation(FOLDER_DELETE);
  return useCallback(
    (folder) =>
      mutation({
        variables: {
          folderId: folder.id,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          folderDelete: true,
        },
        update(cache) {
          cache.modify({
            fields: {
              foldersMe: (existingFoldersRef = [], { readField }) =>
                existingFoldersRef.filter(
                  (f) => readField('id', f) !== folder.id,
                ),
              coursesMe: (existingCoursesRef = [], { readField }) =>
                existingCoursesRef.forEach((course) => {
                  if (readField('folderId', course) === folder.id) {
                    return { ...course, folderId: null };
                  }
                  return course;
                }),
            },
          });
        },
      }),
    [mutation],
  );
}

export const FOLDER_UPDATE = gql`
  mutation FolderUpdate($folderId: ID!, $title: String) {
    folderUpdate(folderId: $folderId, title: $title) {
      ...FolderData
    }
  }
  ${FOLDER_DATA}
`;

export function useMutationFolderUpdate() {
  const [mutation] = useMutation(FOLDER_UPDATE);
  return useCallback(
    (folder, variables) =>
      mutation({
        variables: {
          ...variables,
          folderId: folder.id,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          folderUpdate: {
            ...folder,
            ...variables,
            __typename: 'Folder',
          },
        },
      }),
    [mutation],
  );
}
