import DEFAULT_CANVAS_PROPERTIES from '../data/defaultCanvasProperties';

const BREAK_POINTS = {
  XS: 800,
  S: 1130,
  M: 1312,
  L: 1650,
};

const getNewCanvasWidth = (menuState) => {
  if (window.innerWidth > BREAK_POINTS.L) {
    return (
      window.innerWidth *
      DEFAULT_CANVAS_PROPERTIES.editorExtraLargeRatio[menuState]
    );
  }
  if (window.innerWidth > BREAK_POINTS.M) {
    return (
      window.innerWidth *
      DEFAULT_CANVAS_PROPERTIES.editorLargeRatio[menuState]
    );
  }
  if (window.innerWidth > BREAK_POINTS.S) {
    return (
      window.innerWidth *
      DEFAULT_CANVAS_PROPERTIES.editorMediumRatio[menuState]
    );
  }
  if (window.innerWidth > BREAK_POINTS.XS) {
    return (
      window.innerWidth *
      DEFAULT_CANVAS_PROPERTIES.editorSmallRatio[menuState]
    );
  }
  return (
    window.innerWidth *
    DEFAULT_CANVAS_PROPERTIES.editorExtraSmallRatio[menuState]
  );
};

export default getNewCanvasWidth;
