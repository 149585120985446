import classNames from 'classnames/bind';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { notify } from 'uikit/Notifications';
import { useMutationCourseUpdate } from 'gql/mutations/course';
import extract from 'helpers/extract';
import { trackEvent, reportError } from 'lib/tracking';
import Button from 'uikit/Button';
import Icon from 'uikit/Icon';
import InputText from 'uikit/InputText';

import styles from './Code.module.scss';

const cx = classNames.bind(styles);

const Code = ({ className = null, course }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/Cover/CourseShare/Code',
  });

  const mutationCourseUpdate = useMutationCourseUpdate();

  const [isEditing, setIsEditing] = useState(false);

  const handleCopy = () => {
    document.getElementById('share-code').select();
    document.execCommand('copy');
    notify('success', t('code-copied'));
  };

  const handleCodeMount = (ref) => {
    if (ref) {
      ref.select();
    }
  };

  const handleCodeEdit = () => {
    setIsEditing(true);
  };

  const handleEditDone = async (values, form) => {
    try {
      const accessCode = extract(
        values.accessCode,
        yup.string().trim().uppercase().required().min(1).max(256),
      );
      if (accessCode === course.accessCode) {
        setIsEditing(false);
        return;
      }
      await mutationCourseUpdate(course, {
        accessCode,
      });
      setIsEditing(false);
      trackEvent('course', 'update');
    } catch (err) {
      form.setStatus('500');
      reportError('course', 'update', err);
    }
  };

  return (
    <div className={cx('container', className)}>
      <div className={cx('icon')}>
        <Icon name="books" />
      </div>
      <div className={cx('grow')}>
        <p className={cx('title')}>{t('code')}</p>
        {isEditing ? (
          <Formik
            initialValues={{
              accessCode: course.accessCode,
            }}
            onSubmit={handleEditDone}
          >
            {({ isSubmitting, status, submitForm }) => (
              <Form>
                <div className={cx('content')}>
                  <InputText
                    autoFocus
                    className={cx('primary')}
                    id="input-code"
                    name="accessCode"
                    onBlur={submitForm}
                    ref={handleCodeMount}
                  />
                  <Button
                    icon="check"
                    isLoading={isSubmitting}
                    type="submit"
                    tooltip={t('validate-code')}
                  />
                </div>
                {status && (
                  <div className={cx('error')} role="alert">
                    {t('alreay-in-use')}
                  </div>
                )}
              </Form>
            )}
          </Formik>
        ) : (
          <div className={cx('content')}>
            <p className={cx('primary', 'code')}>{course.accessCode}</p>
            <Button
              aria-label={t('aria-edit-code')}
              className={cx('secondary')}
              icon="pencil"
              onClick={handleCodeEdit}
              tooltip={t('edit-code')}
            />
            <Button aria-label={t('aria-copy-code')} onClick={handleCopy}>
              {t('copy-code')}
            </Button>
            <input
              aria-hidden="true"
              className={cx('hidden')}
              data-testid="existing-course-access-code-input"
              id="share-code"
              readOnly
              type="text"
              value={course.accessCode}
              tabIndex={-1}
            />
          </div>
        )}
      </div>
    </div>
  );
};

Code.propTypes = {
  className: PropTypes.string,
  course: PropTypes.object.isRequired,
};

export default Code;
