import storeJS from 'store';

const defaultValues = {
  tmp: '',
  tags: [],
  title: '',
  answer: '',
  answerInput: '',
  answers: [],
  choices: [
    { idx: 0, title: '', answer: false, feedback: '' },
    { idx: 1, title: '', answer: false, feedback: '' },
  ],
  image: '',
  audio: '',
  video: '',
  file: '',
  items: [
    { idx: 0, title: '' },
    { idx: 1, title: '' },
  ],
  matches: [
    { idx: 0, source: '', destination: '' },
    { idx: 1, source: '', destination: '' },
  ],
  categories: [
    { idx: 0, source: '', destinations: [{ idx: 0, title: '' }] },
    { idx: 1, source: '', destinations: [{ idx: 0, title: '' }] },
  ],
  text: '',
  zones: [],
  legends: [],
  subtitle: '',
  additionalInformation: '',
  experts: [0, 0, 0, 0, 0],
  expertFeedbacks: [[], [], [], [], []],
  labels: ['', '', '', '', ''],
  feedback: '',
  feedbacks: false,
  slideContent: '',
  ignoreCase: true,
  ignoreAccents: true,
  ignorePunctuation: true,
  randomize: true,
  randomizeFlashcard: false,
  multipleChoices: true,
  wantPreview: false,
  wantRecreate: false,
  wantQuestionChange: false,
  background: 'white',
  textColor: 'black',
  toleranceValue: null,
  toleranceValueInput: '',
  toleranceType: 'NONE',
  chapterId: null,
  grade: null,
  studentFeedback: '',
  notifyStudent: true,
  titleLang: storeJS.get('titleLang') || 'fr',
  feedbackLang: storeJS.get('feedbackLang') || 'en-GB',
  useSpeech: storeJS.get('useSpeech') || true,
  answerType: 'video',
};

export default defaultValues;
