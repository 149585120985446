import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import QuestionCard from '../QuestionCard';
import styles from './Standalone.module.scss';

const cx = classNames.bind(styles);

const Standalone = ({ allowPreview, className, questions }) => (
  <div className={className}>
    {questions.map((question) => (
      <QuestionCard
        allowPreview={allowPreview}
        className={cx('question')}
        key={question.id}
        question={question}
      />
    ))}
  </div>
);

Standalone.propTypes = {
  allowPreview: PropTypes.bool,
  className: PropTypes.string,
  questions: PropTypes.arrayOf(PropTypes.object).isRequired,
};

Standalone.defaultProps = {
  allowPreview: undefined,
  className: undefined,
};

export default Standalone;
