import classNames from 'classnames/bind';
import { Formik, Form } from 'formik';
import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import storeJS from 'store';
import * as yup from 'yup';

import Container from 'components/Auth/Shared/Container/Container';
import ForceSSO from 'components/Auth/Shared/ForceSSO';
import Social from 'components/Auth/Shared/Social/Social';
import SSO from 'components/Auth/Shared/SSO/SSO';
import { useMutationAuthLogin } from 'gql/mutations/auth';
import extract from 'helpers/extract';
import { trackEvent, reportError } from 'lib/tracking';
import Button from 'uikit/Button';
import InputText from 'uikit/InputText';

import styles from './Login.module.scss';

const cx = classNames.bind(styles);

const Login = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'Auth/Login',
  });

  const { state } = useLocation();

  const defaultUsername = state?.username || '';

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        username: yup
          .string()
          .trim()
          .lowercase()
          .required(t('username-error-required'))
          .max(256, t('username-error-required')),
        password: yup
          .string()
          .trim()
          .required(t('password-error-required'))
          .max(256, t('password-error-required')),
      }),
    [t],
  );

  const mutationAuthLogin = useMutationAuthLogin(
    storeJS.get('redirectTo') || '/',
  );

  const handleLogin = async (values, form) => {
    try {
      form.setStatus(null);
      const variables = extract(values, validationSchema);
      await mutationAuthLogin(variables);
      trackEvent('auth', 'login');
    } catch (err) {
      form.setStatus(err.message);
      reportError('auth', 'login', err);
    }
  };

  return (
    <Container
      action={
        <Button as={Link} data-testid="register-link" to="/auth/register">
          {t('register')}
        </Button>
      }
    >
      <Formik
        initialValues={{
          username: defaultUsername,
          password: '',
          organization: null,
        }}
        onSubmit={handleLogin}
        validateOnBlur={false}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, status, values }) => (
          <Form className={cx('container')}>
            <div className={cx('section')}>
              <h1 className={cx('title')}>{t('title')}</h1>
              <div className={cx('subtitle-container')}>
                <p className={cx('subtitle')}>{t('subtitle')}</p>
                <Link
                  to={{
                    pathname: '/auth/register',
                    state: {
                      email: values.username,
                    },
                  }}
                >
                  <p className={cx('link')}>{t('register')}</p>
                </Link>
              </div>
              {status === 'Not Found' ? (
                <p className={cx('error')} role="alert">
                  <Trans i18nKey="login-error-username-not-found" t={t}>
                    login-error-username-not-found
                    <Link
                      to={{
                        pathname: '/auth/register',
                        state: {
                          username: values.username,
                        },
                      }}
                    >
                      login-error-username-not-found
                    </Link>
                    login-error-username-not-found
                  </Trans>
                </p>
              ) : (
                status && (
                  <p className={cx('error')} role="alert">
                    {t('login-error')}
                  </p>
                )
              )}
              <InputText
                className={cx('field')}
                data-testid="username"
                icon="at"
                name="username"
                placeholder={t('username-label')}
              />
              {(!values.organization ||
                (values.organization && !values.organization.forceSSO)) && (
                <>
                  <InputText
                    icon="lock"
                    data-testid="password"
                    name="password"
                    type="password"
                    placeholder={t('password-label')}
                  />
                  <Link
                    to={{
                      pathname: '/auth/forgot-password',
                      state: {
                        email: values.username,
                      },
                    }}
                  >
                    <p className={cx('helper', 'forgot-password')}>
                      {t('forgot-password')}
                    </p>
                  </Link>
                  {!!values.username && !!values.password && (
                    <Button
                      className={cx('action')}
                      id="login-button"
                      intent="primary"
                      isLoading={isSubmitting}
                      type="submit"
                      size="large"
                    >
                      {t('login')}
                    </Button>
                  )}
                </>
              )}
              <Social />
            </div>
            <SSO />
            <ForceSSO />
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default Login;
