import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import PERMISSIONS from 'constants/permissions';
import extract from 'helpers/extract';
import {
  useMutationCommentDelete,
  useMutationCommentSolve,
  useMutationCommentUpdate,
  useMutationCommentRead,
} from 'gql/mutations/comment';
import { useMutationCommentReplyCreate } from 'gql/mutations/commentReply';
import { mutationEditorQuestionCommentsChange } from 'gql/mutations/editor';
import { useQueryLocalEditor } from 'gql/queries/local';
import { trackEvent, reportError } from 'lib/tracking';

import Comment from './Comment';

const CommentContainer = ({ comment, course, ...props }) => {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const handleEditToggle = useCallback(() => {
    setIsEditing((s) => !s);
  }, []);
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        content: yup
          .string()
          .trim()
          .required(
            t('Shared/Comment/QuestionComments/Comment/content-error-required'),
          ),
      }),
    [t],
  );
  const mutationCommentReplyCreate = useMutationCommentReplyCreate();
  const handleCommentReply = useCallback(
    async (values, { resetForm }) => {
      try {
        const variables = extract(values, validationSchema);
        await mutationCommentReplyCreate({
          ...variables,
          commentId: comment.id,
        });
        trackEvent('commentReply', 'create');
        resetForm();
      } catch (err) {
        reportError('commentReply', 'create', err);
      }
    },
    [comment, mutationCommentReplyCreate, validationSchema],
  );
  const mutationCommentUpdate = useMutationCommentUpdate();
  const handleEdit = useCallback(
    async (values) => {
      try {
        const variables = extract(values, validationSchema);
        await mutationCommentUpdate(comment, variables);
        trackEvent('comment', 'update');
      } catch (err) {
        reportError('comment', 'update', err);
      } finally {
        setIsEditing(false);
      }
    },
    [comment, mutationCommentUpdate, validationSchema],
  );
  const mutationCommentDelete = useMutationCommentDelete();
  const handleDelete = useCallback(async () => {
    try {
      await mutationCommentDelete(comment);
      trackEvent('comment', 'delete');
    } catch (err) {
      reportError('comment', 'delete', err);
    }
  }, [comment, mutationCommentDelete]);
  const mutationCommentRead = useMutationCommentRead();
  const handleThreadOpen = useCallback(() => {
    if (!comment.isRead) {
      mutationCommentRead(comment);
    }
    mutationEditorQuestionCommentsChange(comment);
  }, [comment, mutationCommentRead]);
  const handleThreadClose = useCallback(() => {
    mutationEditorQuestionCommentsChange(null);
  }, []);
  const {
    data: {
      editor: { commentSelected },
    },
  } = useQueryLocalEditor();
  const isThreadOpen = useMemo(
    () => commentSelected?.id === comment.id,
    [comment, commentSelected],
  );
  const isVisible = useMemo(
    () => !commentSelected || isThreadOpen,
    [commentSelected, isThreadOpen],
  );
  const canSolve = course.level >= PERMISSIONS.COLLABORATOR;
  const mutationCommentSolve = useMutationCommentSolve();
  const handleCommentSolve = useCallback(async () => {
    try {
      await mutationCommentSolve(comment);
      handleThreadClose();
      trackEvent('comment', 'solve');
    } catch (err) {
      reportError('comment', 'solve', err);
    }
  }, [comment, handleThreadClose, mutationCommentSolve]);
  return (
    isVisible && (
      <Comment
        canSolve={canSolve}
        comment={comment}
        handleCommentReply={handleCommentReply}
        handleCommentSolve={handleCommentSolve}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
        handleEditToggle={handleEditToggle}
        handleThreadClose={handleThreadClose}
        handleThreadOpen={handleThreadOpen}
        isEditing={isEditing}
        isThreadOpen={isThreadOpen}
        validationSchema={validationSchema}
        t={t}
        {...props}
      />
    )
  );
};

CommentContainer.propTypes = {
  comment: PropTypes.object.isRequired,
  course: PropTypes.object.isRequired,
};

export default CommentContainer;
