import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'uikit/Button';
import Icon from 'uikit/Icon';
import { notify } from 'uikit/Notifications';

import styles from './ShareEmbed.module.scss';
import HelpTooltip from 'uikit/HelpTooltip';

const cx = classNames.bind(styles);

const ShareEmbed = ({ className = null, course }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Shared/ShareEmbed',
  });

  const embed = `<iframe allowfullscreen frameborder="0" height="100%" mozallowfullscreen src="${process.env.REACT_APP_WEB_URL}/join/${course.accessCode}?from=10" style="min-height: 550px; min-width: 300px" width="100%"></iframe>`;

  const handleCopy = () => {
    document.getElementById('share-embed').select();
    document.execCommand('copy');
    notify('success', t('copied'));
  };

  return (
    <div className={cx('container', className)}>
      <div className={cx('icon')}>
        <Icon name="code" />
      </div>
      <div className={cx('grow')}>
        <div className={cx('title-container')}>
          <p className={cx('title')}>{t('title')}</p>
          <HelpTooltip tooltip={t('subtitle')} />
        </div>
        <div className={cx('content')}>
          <p className={cx('embed')}>{embed}</p>
          <Button aria-label={t('aria-copy-embed')} onClick={handleCopy}>
            {t('copy-embed')}
          </Button>
          <input
            aria-hidden="true"
            className={cx('hidden')}
            id="share-embed"
            readOnly
            type="text"
            value={embed}
            tabIndex={-1}
          />
        </div>
      </div>
    </div>
  );
};

ShareEmbed.propTypes = {
  className: PropTypes.string,
  course: PropTypes.object.isRequired,
};

export default ShareEmbed;
