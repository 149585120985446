/* eslint-disable react/prop-types */
import classNames from 'classnames/bind';
import React from 'react';
import { withTranslation } from 'react-i18next';

import styles from './Filters.module.scss';

const cx = classNames.bind(styles);

export const TextFilter = withTranslation()(
  ({ column: { filterValue, setFilter }, t }) => (
    <input
      className={cx('text')}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={t('Shared/Filters/search')}
      value={filterValue || ''}
    />
  ),
);

export const RangeFilter = withTranslation()(
  ({ column: { filterValue = [], setFilter }, t }) => (
    <div className={cx('range')}>
      <input
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            val ? parseInt(val, 10) : undefined,
            old[1],
          ]);
        }}
        min={0}
        max={100}
        placeholder="Min"
        step={1}
        type="number"
        value={filterValue[0] || ''}
      />
      <span className={cx('to')}>{t('Shared/Filters/to')}</span>
      <input
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            old[0],
            val ? parseInt(val, 10) : undefined,
          ]);
        }}
        min={0}
        max={100}
        placeholder="Max"
        step={1}
        type="number"
        value={filterValue[1] || ''}
      />
    </div>
  ),
);
