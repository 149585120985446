import classNames from 'classnames/bind';
import { useField } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useQuerySSOs } from 'gql/queries/sso';
import Button from 'uikit/Button';
import Icon from 'uikit/Icon';
import Menu, { MenuItem } from 'uikit/Menu';

import styles from './SSO.module.scss';

const cx = classNames.bind(styles);

const SSO = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'Auth/Shared/SSO',
  });

  const { isLoading, data } = useQuerySSOs();

  const [{ value: organization }] = useField('organization');

  const [institution, setInstitution] = useState('');

  const [selectedSSO, setSelectedSSO] = useState();

  const handleInputBlur = (event) => {
    if (event.relatedTarget?.className !== 'MenuItem_container__jModb') {
      setInstitution(selectedSSO?.name || '');
    }
  };

  const handleInputChange = (event) => {
    setInstitution(event.target.value);
  };

  const handleInputFocus = () => {
    setInstitution('');
  };

  const handleSSOSelect = (sso) => {
    setSelectedSSO(sso);
    setInstitution(sso.name);
  };

  const ssos = isLoading ? [] : data.SSOs;

  const displayedSSOs =
    organization?.SSOs.length > 0 ? organization?.SSOs : ssos;

  return (
    <div className={cx('container')}>
      {organization &&
      (organization.SSOs.length > 0 || organization.forceSSO) ? (
        <p className={cx('or')}>
          <span>{t('recommended-ssos')}</span>
        </p>
      ) : (
        <p className={cx('or')}>
          <span>{t('or')}</span>
        </p>
      )}
      <div className={cx('section')}>
        <h1 className={cx('title')}>{t('title')}</h1>
        <Menu
          placement="top"
          toggle={
            <div className={cx('input-container')}>
              <input
                className={cx('input')}
                onBlur={handleInputBlur}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                placeholder={t('institution')}
                value={institution}
              />
              <Icon name="chevron-down" />
            </div>
          }
        >
          <div className={cx('menu-intern')}>
            {displayedSSOs
              .filter(
                (sso) =>
                  sso.name.toLowerCase().includes(institution?.toLowerCase()) ||
                  !institution,
              )
              .map((sso) => (
                <MenuItem
                  onClick={() => handleSSOSelect(sso)}
                  key={sso.specifier}
                >
                  {sso.name}
                </MenuItem>
              ))}
          </div>
        </Menu>
        {selectedSSO && (
          <Button
            as="a"
            className={cx('button')}
            href={`${process.env.REACT_APP_API_URL}/api/auth/${selectedSSO.type}/${selectedSSO.specifier}/login`}
            intent="primary"
            size="large"
          >
            {t('login')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default SSO;
