import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import SUPPORTED_LANGUAGES from 'constants/speechSynthesisSupportedLanguages';
import Options from 'questions/Shared/Options';
import Tags from 'questions/Shared/Tags';
import InputRich2 from 'uikit/InputRich2';
import Select from 'uikit/Select';
import Switch from 'uikit/Switch';

import styles from './Editor.module.scss';

const cx = classNames.bind(styles);

const Editor = ({
  className,
  handleFeedbackLangChange,
  handleTitleLangChange,
  handleUseSpeechChange,
  t,
  useSpeech,
}) => (
  <div className={cx('container', className)}>
    {useSpeech && (
      <Select
        className={cx('select')}
        label={t('Questions/Flashcard/Editor/title-language-label')}
        name="titleLang"
        onChange={handleTitleLangChange}
      >
        {SUPPORTED_LANGUAGES.map((language) => (
          <option
            disabled={language.code === ''}
            key={language.code}
            value={language.code}
          >
            {t(language.name)}
          </option>
        ))}
      </Select>
    )}
    <InputRich2
      data-testid="feedback"
      label={t('Questions/Flashcard/Editor/verso-label')}
      name="feedback"
      placeholder={t('Questions/Flashcard/Editor/verso-placeholder')}
    />
    {useSpeech && (
      <Select
        className={cx('select', 'select-feedback')}
        label={t('Questions/Flashcard/Editor/feedback-language-label')}
        name="feedbackLang"
        onChange={handleFeedbackLangChange}
      >
        {SUPPORTED_LANGUAGES.map((language) => (
          <option
            disabled={language.code === ''}
            key={language.code}
            value={language.code}
          >
            {t(language.name)}
          </option>
        ))}
      </Select>
    )}
    <Options className={cx('options')}>
      <Tags className={cx('field')} />
      <Switch
        className={cx('field')}
        label={t('Questions/Flashcard/Editor/use-speech')}
        name="useSpeech"
        onChange={handleUseSpeechChange}
      />
      <Switch
        className={cx('field')}
        label={t('Questions/Flashcard/Editor/randomize-label')}
        name="randomizeFlashcard"
      />
    </Options>
  </div>
);

Editor.propTypes = {
  className: PropTypes.string,
  handleFeedbackLangChange: PropTypes.func.isRequired,
  handleTitleLangChange: PropTypes.func.isRequired,
  handleUseSpeechChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  useSpeech: PropTypes.bool.isRequired,
};

Editor.defaultProps = {
  className: undefined,
};

export default Editor;
