import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useMutationCourseUpdate } from 'gql/mutations/course';
import tutorialIllustrationsChecker from 'helpers/tutorialIllustrationsChecker';
import { trackEvent, reportError } from 'lib/tracking';

import CourseTheme from './CourseTheme';

const CourseThemeContainer = ({ course, ...props }) => {
  const { t } = useTranslation();
  const mutationCourseUpdate = useMutationCourseUpdate();
  const { needTutorialIllustration1, needTutorialIllustration2 } =
    tutorialIllustrationsChecker(course.id);
  const handleThemeUpdate = useCallback(
    async (background) => {
      try {
        await mutationCourseUpdate(course, {
          themeBackground: background,
        });
        trackEvent('course', 'update');
      } catch (err) {
        reportError('course', 'update', err);
      }
    },
    [course, mutationCourseUpdate],
  );
  return (
    <CourseTheme
      {...props}
      course={course}
      handleThemeUpdate={handleThemeUpdate}
      needTutorialIllustration1={needTutorialIllustration1}
      needTutorialIllustration2={needTutorialIllustration2}
      t={t}
    />
  );
};

CourseThemeContainer.propTypes = {
  course: PropTypes.object.isRequired,
};

export default CourseThemeContainer;
