import { useField } from 'formik';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { useQueryMe } from 'gql/queries/me';

import Editor from './Editor';

const EditorContainer = (props) => {
  const { t } = useTranslation();
  const { data: meData } = useQueryMe();
  const [{ value: choices }, , { setValue: setChoices }] = useField('choices');
  const handleChoiceAdd = useCallback(() => {
    setChoices([
      ...choices,
      {
        idx: choices.length,
        title: '',
        answer: false,
        feedback: '',
      },
    ]);
  }, [choices, setChoices]);
  const handleChoiceComplete = useCallback(
    (model) => {
      if (model) {
        setChoices([
          ...choices,
          {
            idx: choices.length,
            title: model,
            answer: false,
            feedback: '',
          },
        ]);
      }
    },
    [choices, setChoices],
  );
  const handleChoiceRemove = useCallback(
    (index) => {
      const newChoices = choices.filter((a, i) => i !== index);
      if (newChoices.length < 1) {
        newChoices.push({
          idx: newChoices.length,
          title: '',
          answer: false,
        });
      }
      setChoices(newChoices);
    },
    [choices, setChoices],
  );
  const [{ value: addFeedbacks }, , { setValue: setAddFeedbacks }] =
    useField('feedbacks');
  useEffect(() => {
    const hasFeedback = choices.find((choice) => !!choice.feedback);
    if (hasFeedback) {
      setAddFeedbacks(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleFeedbacksToggle = useCallback(() => {
    setAddFeedbacks(!addFeedbacks);
    if (addFeedbacks) {
      setChoices(choices.map((choice) => ({ ...choice, feedback: '' })));
    }
  }, [addFeedbacks, choices, setAddFeedbacks, setChoices]);
  const validationSchema = useMemo(
    () => EditorContainer.validationSchema(t),
    [t],
  );
  const hasAIFeedbackAccess = useMemo(
    () => meData?.me.flags?.includes('ai-feedback') && meData?.me.wantsAI,
    [meData],
  );
  return (
    <Editor
      choices={choices}
      addFeedbacks={addFeedbacks}
      handleChoiceAdd={handleChoiceAdd}
      handleChoiceComplete={handleChoiceComplete}
      handleChoiceRemove={handleChoiceRemove}
      handleFeedbacksToggle={handleFeedbacksToggle}
      hasAIFeedbackAccess={hasAIFeedbackAccess}
      t={t}
      validationSchema={validationSchema}
      {...props}
    />
  );
};

EditorContainer.validationSchema = (t) =>
  yup.object().shape({
    tags: yup.array().of(yup.string().trim().lowercase().max(256)).compact(),
    title: yup
      .string()
      .trim()
      .required(t('Questions/MCQ/Editor/title-error-required')),
    choices: yup
      .array()
      .of(
        yup.object().shape({
          idx: yup.number().required().integer().min(0),
          title: yup.string().trim().required(),
          answer: yup.boolean().default(false),
          feedback: yup.string().trim(),
        }),
      )
      .compact()
      .transform((choices) =>
        choices
          .filter((choice) => !!choice.title)
          .map((choice, index) => ({
            ...choice,
            idx: index,
          })),
      )
      .test(
        'length',
        t('Questions/MCQ/Editor/choices-error-length'),
        (choices) => choices.length >= 2,
      )
      .test(
        'answer',
        t('Questions/MCQ/Editor/choices-error-answer'),
        (choices) => choices.filter((choice) => !!choice.answer).length >= 1,
      )
      .test(
        'multiple answer',
        t('Questions/MCQ/Editor/multiple-choices-error-length'),
        (choices, context) =>
          context.parent.multipleChoices ||
          choices.filter((choice) => !!choice.answer).length === 1,
      ),
    feedback: yup.string().trim(),
    randomize: yup.boolean().default(true),
    multipleChoices: yup.boolean().default(false),
  });

export default EditorContainer;
