import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import CleanedText from 'uikit/CleanedText';
import HelpTooltip from 'uikit/HelpTooltip';
import Icon from 'uikit/Icon';
import ImageContainer from '../Shared/ImageContainer';

import styles from './Aggregate.module.scss';

const cx = classNames.bind(styles);

const Aggregate = ({ aggregation, className, question, questionInfo, t }) => (
  <div className={cx('container', className)}>
    <div className={cx('background')}>
      <div className={cx('header')}>
        <div className={cx('title-container')}>
          <p className={cx('icon', `background-${questionInfo.color}`)}>
            <img alt="" src={questionInfo.icon} />
          </p>
          <CleanedText as="p" className={cx('title')}>
            {question?.title}
          </CleanedText>
        </div>
        <p
          className={cx('answers-numbers', {
            'answers-numbers-wrong': aggregation.nCorrectAnswers === 0,
          })}
        >
          <span
            className={cx('correct-answers', {
              isEmpty: aggregation.nCorrectAnswers === 0,
            })}
          >
            {t('Questions/LabelOnImage/Aggregate/correct-answers', {
              count: aggregation.nCorrectAnswers,
            })}
          </span>
          <span
            className={cx('total-answers', {
              'total-answers-wrong': aggregation.nCorrectAnswers === 0,
            })}
          >
            {` / ${aggregation.nTotalAnswers}`}
          </span>
        </p>
      </div>
      <div className={cx('image-legends-container')}>
        <ImageContainer
          className={cx('image')}
          imageSrc={question.content.image}
          legends={question.content.legends}
          solution={[]}
          zones={question.content.zones}
        />
        <div className={cx('legends')}>
          {question.content.legends.map((legend, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className={cx('legend')} key={index}>
              <div className={cx('line')}>
                <div className={cx('legend-index')}>{index + 1}</div>
                <div className={cx('report')}>
                  <div className={cx('solution')}>
                    <p className={cx('text', 'right')}>
                      {legend.content.join(',') || '___'}
                    </p>
                    <div className={cx('right')}>
                      {aggregation.aggregatedAnswers[index].nRight}
                    </div>
                    <Icon className={cx('right')} name="user-check" />
                  </div>
                </div>
              </div>
              <div className={cx('wrong-answers')}>
                <HelpTooltip
                  className={cx('tooltip')}
                  tooltip={t(
                    'Questions/LabelOnImage/Aggregate/frequent-answers',
                  )}
                />
                <div className={cx('errors')}>
                  {aggregation.aggregatedAnswers[
                    index
                  ].frequentWrongAnswers.map((wrongAnswer, i) => (
                    <span className={cx('error')} key={wrongAnswer.title}>
                      <span className={cx('error-title')}>
                        {wrongAnswer.title}
                      </span>
                      <span className={cx('error-occurrence')}>
                        {wrongAnswer.occurrence}
                      </span>
                      {i + 1 !==
                        aggregation.aggregatedAnswers[index]
                          .frequentWrongAnswers.length && <span>{' ,'}</span>}
                    </span>
                  ))}
                </div>
                <div className={cx('wrong')}>
                  {aggregation.aggregatedAnswers[index].nWrong}
                </div>
                <Icon className={cx('wrong')} name="user-times" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
);

Aggregate.propTypes = {
  aggregation: PropTypes.object.isRequired,
  className: PropTypes.string,
  question: PropTypes.object.isRequired,
  questionInfo: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

Aggregate.defaultProps = {
  className: undefined,
};

export default Aggregate;
