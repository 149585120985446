const getAvailableHeight = () => {
  const header = document.getElementsByTagName('header')[0];
  const headerStyle = header.currentStyle || window.getComputedStyle(header);
  const footer = document.getElementById('self-assess-footer');
  const canvas = document.getElementsByTagName('canvas')[0];
  const canvasStyle = canvas.currentStyle || window.getComputedStyle(canvas);
  const headerHeight = header.offsetHeight;
  const headerMarginBottom = parseInt(headerStyle.marginBottom);
  const slidePadding = parseInt(canvasStyle.paddingTop) * 2;
  const footerMarginTop = 50;
  const footerHeight = footer.offsetHeight;
  const availableHeight =
    window.innerHeight -
    headerHeight -
    headerMarginBottom -
    slidePadding -
    footerMarginTop -
    footerHeight;
  return availableHeight;
};

export default getAvailableHeight;
