import classNames from 'classnames/bind';
import { Formik, Form } from 'formik';
import queryString from 'query-string';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import * as yup from 'yup';

import Container from 'components/Auth/Shared/Container/Container';
import { useMutationAuthResetPassword } from 'gql/mutations/auth';
import extract from 'helpers/extract';
import { trackEvent, reportError } from 'lib/tracking';
import Button from 'uikit/Button';
import InputText from 'uikit/InputText';

import styles from './ResetPassword.module.scss';

const cx = classNames.bind(styles);

const ResetPassword = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'Auth/ResetPassword',
  });

  const mutationAuthResetPassword = useMutationAuthResetPassword();

  const { search } = useLocation();

  const [isDone, setDone] = useState(false);

  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .trim()
      .required(t('password-error-required'))
      .max(256, t('password-error-required')),
  });

  const query = queryString.parse(search);

  const handleResetPassword = async (values, form) => {
    try {
      form.setStatus(null);
      const { password } = extract(values, validationSchema);
      await mutationAuthResetPassword({
        password,
        recoveryToken: query.recoveryToken,
      });
      setDone(true);
      trackEvent('auth', 'reset-password');
    } catch (err) {
      form.setStatus(err.message);
      reportError('auth', 'reset-password', err);
    }
  };

  return (
    <Container
      action={
        <Button as={Link} size="large" to="/auth/login">
          {t('back')}
        </Button>
      }
    >
      {isDone ? (
        <div className={cx('container')}>
          <h1 className={cx('title')}>{t('success')}</h1>
          <Button as={Link} intent="primary" size="large" to="/auth/login">
            {t('back')}
          </Button>
        </div>
      ) : (
        <Formik
          initialValues={{
            password: '',
          }}
          onSubmit={handleResetPassword}
          validateOnBlur={false}
          validationSchema={validationSchema}
        >
          {({ isSubmitting, status }) => (
            <Form className={cx('container')}>
              <div className={cx('section')}>
                <h1 className={cx('title')}>{t('title')}</h1>
                {status === '429' ? (
                  <p className={cx('error')} role="alert">
                    {t('reset-password-error-code-expired')}
                  </p>
                ) : (
                  status && (
                    <p className={cx('error')} role="alert">
                      {t('reset-password-error')}
                    </p>
                  )
                )}
                <InputText
                  className={cx('field')}
                  icon="lock"
                  name="password"
                  placeholder={t('password-label')}
                  type="password"
                />
                <Button
                  intent="primary"
                  isLoading={isSubmitting}
                  size="large"
                  type="submit"
                >
                  {t('reset-password')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </Container>
  );
};

export default ResetPassword;
