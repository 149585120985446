import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import storeJS from 'store';

import Button from 'uikit/Button';
import { MenuItem } from 'uikit/Menu';
import Modal from 'uikit/Modal';

import styles from './CourseExport.module.scss';

const cx = classNames.bind(styles);

const CourseExport = ({
  className,
  course,
  handleModalClose,
  handleModalOpen,
  isModalOpen,
  t,
}) => (
  <>
    <MenuItem
      className={className}
      icon="file-export"
      onClick={handleModalOpen}
    >
      {t('Course/Teacher/Cover/CourseExport/export-course')}
    </MenuItem>
    <Modal isOpen={isModalOpen} onExit={handleModalClose}>
      <div className={cx('modal')}>
        <Button
          aria-label={t('Course/Teacher/Cover/CourseExport/aria-close')}
          className={cx('close')}
          icon="times"
          onClick={handleModalClose}
        />
        <h1 className={cx('title')}>
          {t('Course/Teacher/Cover/CourseExport/title')}
        </h1>
        <Button
          as="a"
          className={cx('action')}
          href={`${process.env.REACT_APP_API_URL}/rest/courses/${
            course.id
          }/export-excel?authToken=${storeJS.get('token')}`}
          icon="file-excel"
          target="blank"
          size="large"
        >
          {t('Course/Teacher/Cover/CourseExport/export-excel')}
        </Button>
      </div>
    </Modal>
  </>
);

CourseExport.propTypes = {
  className: PropTypes.string,
  course: PropTypes.object.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  handleModalOpen: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

CourseExport.defaultProps = {
  className: undefined,
};

export default CourseExport;
