import classNames from 'classnames/bind';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'uikit/Button';
import InputText from 'uikit/InputText';
import PERMISSIONS from 'constants/permissions';

import styles from './CourseIntroduction.module.scss';

const cx = classNames.bind(styles);

const CourseIntroduction = ({
  className,
  course,
  handleEditDone,
  handleIntroductionEdit,
  handleIntroductionMount,
  isEditing,
  t,
}) => (
  <div className={cx('container', className)}>
    {isEditing ? (
      <Formik
        initialValues={{
          introduction: course.introduction,
        }}
        onSubmit={handleEditDone}
      >
        {({ isSubmitting, submitForm }) => (
          <Form className={cx('form')}>
            <InputText
              className={cx('field')}
              name="introduction"
              onBlur={submitForm}
              ref={handleIntroductionMount}
            />
            <Button icon="check" isLoading={isSubmitting} type="submit" />
          </Form>
        )}
      </Formik>
    ) : course.level >= PERMISSIONS.OWNER ? (
      <div className={cx('introduction-container')}>
        <h2 className={cx('introduction')}>
          {course.introduction ||
            t('Course/Cover/CourseIntroduction/add-introduction')}
        </h2>
        <Button
          aria-label={t('Course/Teacher/Cover/CourseIntroduction/edit')}
          className={cx('edit-button')}
          icon="pencil"
          onClick={handleIntroductionEdit}
          size="small"
        />
      </div>
    ) : (
      course.introduction && (
        <h1 className={cx('introduction')}>{course.introduction}</h1>
      )
    )}
  </div>
);

CourseIntroduction.propTypes = {
  className: PropTypes.string,
  course: PropTypes.object.isRequired,
  handleEditDone: PropTypes.func.isRequired,
  handleIntroductionEdit: PropTypes.func.isRequired,
  handleIntroductionMount: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

CourseIntroduction.defaultProps = {
  className: undefined,
};

export default CourseIntroduction;
