import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import TourSteps from 'components/App/Onboarding/NewTour/TourSteps/TourSteps';

import ContentCreationMethod from './ContentCreationMethod/ContentCreationMethod';
import Cover from './Cover';
import Editor from './Editor/Editor';
import Games from './Games/Games';
import Participants from './Participants';
import QuestionCreateFromIA from './QuestionCreateFromIA';
import Settings from './Settings';
import Statistics from './Statistics/Statistics';
import TestResults from './TestResults/TestResults';

const Teacher = ({ className, course }) => (
  <div className={className}>
    <Cover />
    <Switch>
      <Route
        exact
        path={[
          '/course/:courseId/participants',
          '/course/:courseId/collaborators',
          '/course/:courseId/students',
        ]}
        component={Participants}
      />
      <Route exact path="/course/:courseId/" component={Editor} />
      <Route exact path="/course/:courseId/games" component={Games} />
      <Route exact path="/course/:courseId/statistics" component={Statistics} />
      <Route exact path="/course/:courseId/settings" component={Settings} />
      <Route
        exact
        path="/course/:courseId/test-results"
        component={TestResults}
      />
      <Route render={() => <Redirect to={`/course/${course.id}/`} />} />
    </Switch>
    <ContentCreationMethod course={course} />
    <QuestionCreateFromIA course={course} />
    <TourSteps course={course} />
  </div>
);

Teacher.propTypes = {
  className: PropTypes.string,
  course: PropTypes.object.isRequired,
};

Teacher.defaultProps = {
  className: undefined,
};

export default Teacher;
