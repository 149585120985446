import React from 'react';
import { useTranslation } from 'react-i18next';

import LANGUAGES from 'constants/languages';
import { mutationAppLanguageUpdate } from 'gql/mutations/app';
import { useQueryLocalApp } from 'gql/queries/local';

import LanguageSelector from './LanguageSelector';

const LanguageSelectorContainer = (props) => {
  const { t } = useTranslation();
  const {
    data: {
      app: { isMenuExpanded, language },
    },
  } = useQueryLocalApp();
  const userLanguage = LANGUAGES.find((l) => l.key === language)?.translation;
  return (
    <LanguageSelector
      {...props}
      handleLanguageChange={mutationAppLanguageUpdate}
      isMenuExpanded={isMenuExpanded}
      language={language}
      t={t}
      userLanguage={userLanguage}
    />
  );
};

export default LanguageSelectorContainer;
