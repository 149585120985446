import classNames from 'classnames/bind';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import FroalaEditor from 'react-froala-wysiwyg';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

import 'froala-editor/js/languages/fr';

import CleanedText from 'uikit/CleanedText';
import InputText from 'uikit/InputText';

import './plugins/greek';
import './plugins/ops';
import './plugins/physics';
import './plugins/relation';
import './plugins/symbols';

import styles from './InputMath.module.scss';

const cx = classNames.bind(styles);

const InputMath = ({
  autoFocus,
  className,
  helper,
  isDisabled,
  isExternal,
  label,
  name,
  placeholder,
  shouldHideError,
}) => {
  const { i18n } = useTranslation();
  const [{ value }, meta, { setValue }] = useField(name);
  return (
    <label
      className={cx(
        'container',
        {
          isDisabled,
          hasError: meta.touched && meta.error,
        },
        className,
      )}
      htmlFor={name}
    >
      {(label || helper) && (
        <div className={cx('title')}>
          <div className={cx('label')}>{label}</div>
          <div className={cx('helper')}>{helper}</div>
        </div>
      )}
      {isExternal ? (
        <div>
          <InputText className={cx('cefield')} name={name} />
          <div className={cx('equation')}>
            <CleanedText>{`\` ${value} \``}</CleanedText>
          </div>
        </div>
      ) : (
        <div className={cx('field')}>
          <FroalaEditor
            config={{
              key: process.env.REACT_APP_FROALA_KEY,
              charCounterCount: false,
              attribution: false,
              autofocus: autoFocus,
              htmlAllowComments: false,
              htmlAllowedAttrs: [],
              htmlAllowedEmptyTags: [],
              htmlAllowedTags: [],
              htmlExecuteScripts: false,
              multiLine: false,
              pastePlain: true,
              placeholderText: placeholder,
              pluginsEnabled: [
                'opsPlugin',
                'greekPlugin',
                'symbolsPlugin',
                'relationPlugin',
                'physicsPlugin',
              ],
              shortcutsEnabled: [],
              toolbarButtons: [
                'insertOps',
                'insertGreek',
                'insertSymbols',
                'insertRelation',
                'insertPhysics',
              ],
              imageUpload: false,
              imagePasteProcess: false,
              language: i18n.language,
              videoUpload: false,
              events: {
                initialized() {
                  if (isDisabled) {
                    // eslint-disable-next-line react/no-this-in-sfc
                    this.edit.off();
                  }
                },
              },
            }}
            model={`<p>${value}</p>`}
            onModelChange={(model) =>
              setValue(model.replace(/<\/?[^>]+>/g, ''))
            }
          />
          <div className={cx('equation')}>
            <CleanedText>{`\` ${value} \``}</CleanedText>
          </div>
        </div>
      )}
      {!shouldHideError && meta.touched && meta.error && (
        <p className={cx('error-message')} id={`error-${name}`} role="alert">
          {meta.error}
        </p>
      )}
    </label>
  );
};

InputMath.propTypes = {
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  helper: PropTypes.node,
  isDisabled: PropTypes.bool,
  isExternal: PropTypes.bool.isRequired,
  label: PropTypes.node,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  shouldHideError: PropTypes.bool,
};

InputMath.defaultProps = {
  autoFocus: false,
  className: undefined,
  helper: undefined,
  isDisabled: false,
  label: undefined,
  placeholder: undefined,
  shouldHideError: false,
};

export default InputMath;
