import { fabric } from 'fabric';

import DEFAULT_CANVAS_PROPERTIES from '../data/defaultCanvasProperties';
import resizeImage from './resizeImage';

const loadBackground = (fabricRef, template) => {
  fabric.Image.fromURL(template.backgroundImage, (img) => {
    resizeImage(
      img,
      DEFAULT_CANVAS_PROPERTIES.width,
      DEFAULT_CANVAS_PROPERTIES.height,
    );
    fabricRef.current.setBackgroundImage(img);
    fabricRef.current.renderAll();
  });
};

export default loadBackground;
