import classNames from 'classnames/bind';
import { Form, Formik } from 'formik';
import React, { Suspense, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import * as yup from 'yup';

import extract from 'helpers/extract';
import {
  mutationAppCookiesConsent,
  mutationAppRefresh,
} from 'gql/mutations/app';
import { useQueryLocalApp } from 'gql/queries/local';
import { useQueryMe } from 'gql/queries/me';
import useSiteContext from 'hooks/useSiteContext';
import Button from 'uikit/Button';
import HelpTooltip from 'uikit/HelpTooltip';
import Icon from 'uikit/Icon';
import Switch from 'uikit/Switch';

import styles from './CookieConsent.module.scss';

const cx = classNames.bind(styles);

const CookieConsent = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'App/CookieConsent',
  });

  const { isGAR, isInNative } = useSiteContext();

  const {
    data: {
      app: {
        isAuthenticated,
        cookieConsentBasic,
        cookieConsentFunctionality,
        cookieConsentPerformance,
        cookieConsentTracking,
      },
    },
  } = useQueryLocalApp();

  const { isLoading, data } = useQueryMe({
    skip: !isAuthenticated,
  });

  const [hasCustomized, setHasCustomized] = useState(false);

  useEffect(() => {
    if (cookieConsentBasic && !isGAR && !isInNative) {
      (function gtm(w, d, s, l, i) {
        // eslint-disable-next-line no-param-reassign
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        w[l].push({ cookieFunctionality: cookieConsentFunctionality });
        w[l].push({ cookiePerformance: cookieConsentPerformance });
        w[l].push({ cookieTracking: cookieConsentTracking });
        const f = d.getElementsByTagName(s)[0];
        const j = d.createElement(s);
        const dl = l !== 'dataLayer' ? `&l=${l}` : '';
        j.async = true;
        j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', process.env.REACT_APP_GTM_ID);
    }
  }, [
    cookieConsentBasic,
    cookieConsentFunctionality,
    cookieConsentPerformance,
    cookieConsentTracking,
    isGAR,
    isInNative,
  ]);

  useEffect(() => {
    if (cookieConsentBasic && !isLoading && !isGAR && !isInNative) {
      window.CRISP_READY_TRIGGER = () => {
        window.$crisp.push(['safe', true]);
        window.$crisp.push([
          'set',
          'user:nickname',
          [`${data.me.firstName} ${data.me.lastName}`],
        ]);
        window.$crisp.push(['set', 'user:email', [data.me.email]]);
        window.$crisp.push(['set', 'user:avatar', [data.me.picture]]);
        window.$crisp.push(['set', 'user:company', [data.me.institution]]);
        window.$crisp.push([
          'set',
          'session:segments',
          [
            [
              `language-${data.me.language}`,
              `sector-${data.me.sector}`,
              `role-${data.me.role}`,
              `marketing-${data.me.wantsMarketing ? 'on' : 'off'}`,
              ...data.me.tags,
            ],
          ],
        ]);
        window.$crisp.push([
          'set',
          'session:data',
          [
            [
              ['id', data.me.id],
              ['firstName', data.me.firstName],
              ['lastName', data.me.lastName],
              ['username', data.me.username],
              ['email', data.me.email],
              ['language', data.me.language],
              ['sector', data.me.sector],
              ['role', data.me.role],
              ['institution', data.me.institution],
              ['branch', data.me.branch],
            ],
          ],
        ]);
        if (data.me.role !== 'teacher') {
          window.$crisp.push(['do', 'chat:hide']);
        }
      };
      if (window.Sentry) {
        window.Sentry.setUser({
          id: data.me.id,
        });
      }
    }
  }, [data, isGAR, isInNative, isLoading, cookieConsentBasic]);

  function handleAcceptAll() {
    setCookieConsent({
      cookieConsentFunctionality: true,
      cookieConsentPerformance: true,
      cookieConsentTracking: true,
    });
  }

  function handleAcceptEssential() {
    setCookieConsent({
      cookieConsentFunctionality: false,
      cookieConsentPerformance: false,
      cookieConsentTracking: false,
    });
  }

  function handleBack() {
    setHasCustomized(false);
  }

  function handleCustomize() {
    setHasCustomized(true);
  }

  function setCookieConsent(values) {
    const consentCookies = extract(
      values,
      yup.object().shape({
        cookieConsentFunctionality: yup.boolean(),
        cookieConsentPerformance: yup.boolean(),
        cookieConsentTracking: yup.boolean(),
      }),
    );
    mutationAppCookiesConsent({
      cookieConsentBasic: true,
      ...consentCookies,
    });
    mutationAppRefresh();
  }

  return cookieConsentBasic || isGAR || isInNative ? null : (
    <Suspense fallback={null}>
      <CSSTransition
        classNames={{
          exit: cx('container-exit'),
          exitActive: cx('container-exit-active'),
        }}
        in
        mountOnEnter
        timeout={250}
        unmountOnExit
      >
        <aside className={cx('container')}>
          <div className={cx('icon-container')}>
            <Icon className={cx('icon')} name="cookie-bite" />
          </div>
          <p className={cx('title')}>
            <Trans as="p" i18nKey="App/CookieConsent/title">
              App/CookieConsent/title
              <a href="https://www.wooflash.com/fr/politique-de-confidentialite">
                App/CookieConsent/title
              </a>
              App/CookieConsent/title
            </Trans>
          </p>
          {hasCustomized ? (
            <Formik
              enableReinitialize
              initialValues={{
                cookieConsentBasic: true,
                cookieConsentFunctionality: false,
                cookieConsentPerformance: false,
                cookieConsentTracking: false,
              }}
              onSubmit={setCookieConsent}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className={cx('customisationWrapper')}>
                    <Switch
                      className={cx('switch')}
                      isDisabled
                      label={
                        <>
                          {t('essential-cookies')}
                          <HelpTooltip
                            className={cx('tooltip')}
                            tooltip={t('essential-explanation')}
                          />
                        </>
                      }
                      name="cookieConsentBasic"
                    />
                    <Switch
                      className={cx('switch')}
                      label={
                        <>
                          {t('functionality-cookies')}
                          <HelpTooltip
                            className={cx('tooltip')}
                            tooltip={t('functionality-explanation')}
                          />
                        </>
                      }
                      name="cookieConsentFunctionality"
                    />
                    <Switch
                      className={cx('switch')}
                      label={
                        <>
                          {t('performance-cookies')}
                          <HelpTooltip
                            className={cx('tooltip')}
                            tooltip={t('performance-explanation')}
                          />
                        </>
                      }
                      name="cookieConsentPerformance"
                    />
                    <Switch
                      className={cx('switch')}
                      label={
                        <>
                          {t('tracking-cookies')}
                          <HelpTooltip
                            className={cx('tooltip')}
                            tooltip={t('tracking-explanation')}
                          />
                        </>
                      }
                      name="cookieConsentTracking"
                    />
                    <div className={cx('actions')}>
                      <Button
                        className={cx('action')}
                        onClick={handleBack}
                        size="large"
                        type="button"
                      >
                        {t('back')}
                      </Button>
                      <Button
                        className={cx('action')}
                        intent="primary"
                        isLoading={isSubmitting}
                        size="large"
                        type="submit"
                      >
                        {t('confirm')}
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          ) : (
            <div className={cx('main-actions')}>
              <Button onClick={handleAcceptAll}>{t('accept-all')}</Button>
              <Button
                className={cx('main-action')}
                data-testid="cookie-consent-essential"
                onClick={handleAcceptEssential}
              >
                {t('reject-all')}
              </Button>
              <Button className={cx('main-action')} onClick={handleCustomize}>
                {t('customize')}
              </Button>
            </div>
          )}
        </aside>
      </CSSTransition>
    </Suspense>
  );
};

export default CookieConsent;
