import { gql, useQuery } from '@apollo/client';

import { NOTIFICATION_DATA } from 'gql/fragments';

export const QUERY_NOTIFICATIONS_ME = gql`
  query ($limit: Int, $offset: Int) {
    notificationsMe(limit: $limit, offset: $offset) {
      ...NotificationData
    }
  }
  ${NOTIFICATION_DATA}
`;

export function useQueryNotificationsMe(options) {
  const { loading, data, ...props } = useQuery(QUERY_NOTIFICATIONS_ME, options);
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}
