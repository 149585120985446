import t from 'helpers/t';

const LANGUAGES = [
  {
    key: 'fr',
    translation: t('Global/language/french'),
  },
  {
    key: 'en',
    translation: t('Global/language/english'),
  },
  {
    key: 'nl',
    translation: t('Global/language/dutch'),
  },
  {
    key: 'it',
    translation: t('Global/language/italiano'),
  },
];

export default LANGUAGES;
