import { useField } from 'formik';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import Editor from './Editor';

const EditorContainer = (props) => {
  const { t } = useTranslation();
  const [{ value: answer }] = useField('answer');
  const [{ value: toleranceValue }] = useField('toleranceValue');
  const [{ value: toleranceType }] = useField('toleranceType');
  const maxCorrect = useMemo(() => {
    if (toleranceType === 'ABSOLUTE' && (answer || answer === 0)) {
      const max = answer + toleranceValue;
      return Number(max.toFixed(3));
    }
    if (toleranceType === 'RELATIVE' && answer && answer !== 0) {
      const max = answer + (answer * toleranceValue) / 100;
      return Number(max.toFixed(3));
    }
    return null;
  }, [answer, toleranceValue, toleranceType]);
  const minCorrect = useMemo(() => {
    if (toleranceType === 'ABSOLUTE' && (answer || answer === 0)) {
      const min = answer - toleranceValue;
      return Number(min.toFixed(3));
    }
    if (toleranceType === 'RELATIVE' && answer && answer !== 0) {
      const min = answer - (answer * toleranceValue) / 100;
      return Number(min.toFixed(3));
    }
    return null;
  }, [answer, toleranceValue, toleranceType]);
  return (
    <Editor maxCorrect={maxCorrect} minCorrect={minCorrect} t={t} {...props} />
  );
};

EditorContainer.validationSchema = (t) =>
  yup.object().shape({
    tags: yup.array().of(yup.string().trim().lowercase().max(256)).compact(),
    title: yup
      .string()
      .trim()
      .required(t('Questions/GuessNumber/Editor/title-error-required')),
    answer: yup
      .number()
      .required(t('Questions/GuessNumber/Editor/answer-error-required')),
    toleranceValue: yup.number().nullable().min(0),
    toleranceType: yup
      .string()
      .oneOf(['NONE', 'ABSOLUTE', 'RELATIVE'])
      .required(),
    feedback: yup.string().trim(),
  });

export default EditorContainer;
