import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';

import Tabs from './Tabs';

const TabsContainer = ({ children, defaultIndex, onChange, ...props }) => {
  const [activeTab, setActiveTab] = useState(defaultIndex);
  const handleTabChange = useCallback(
    (index) => {
      setActiveTab(index);
      if (onChange) {
        onChange(index);
      }
    },
    [onChange],
  );
  return (
    <Tabs activeTab={activeTab} handleTabChange={handleTabChange} {...props}>
      {children}
    </Tabs>
  );
};

TabsContainer.propTypes = {
  children: PropTypes.instanceOf(Array).isRequired,
  defaultIndex: PropTypes.number,
  onChange: PropTypes.func,
};

TabsContainer.defaultProps = {
  defaultIndex: 0,
  onChange: undefined,
};

// eslint-disable-next-line react/prop-types
TabsContainer.Tab = ({ children }) => <div role="tabpanel">{children}</div>;

export default TabsContainer;
