import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import FormattedText from 'uikit/FormattedText';
import Icon from 'uikit/Icon';

import AudioPicker from 'uikit/AudioPicker';
import VideoPicker from 'uikit/VideoPicker';
import styles from './Recto.module.scss';

const cx = classNames.bind(styles);

const Recto = ({
  className,
  handleMessageDisplay,
  handleMessageHide,
  isDisabled,
  isRecording,
  question,
  t,
  theme,
}) => (
  <div className={cx('container', className, `theme-${theme}`)}>
    {isRecording && (
      <div className={cx('message', { active: isRecording })}>
        <Icon className={cx('message-icon')} name="video" />
        <p>{t('Questions/Interview/Recto/recording-message')}</p>
      </div>
    )}
    <FormattedText as="h1" className={cx('title')}>
      {question.title}
    </FormattedText>
    {!isDisabled && (
      <div className={cx('instructions')}>
        {t('Questions/Interview/Recto/instructions')}
      </div>
    )}
    {question.content.answerType === 'video' ? (
      <VideoPicker
        disableLink
        name="answer"
        onRecordStart={handleMessageDisplay}
        onRecordStop={handleMessageHide}
      />
    ) : (
      <AudioPicker
        disableLink
        name="answer"
        onRecordStart={handleMessageDisplay}
        onRecordStop={handleMessageHide}
      />
    )}
  </div>
);

Recto.propTypes = {
  className: PropTypes.string,
  handleMessageDisplay: PropTypes.func.isRequired,
  handleMessageHide: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isRecording: PropTypes.bool.isRequired,
  question: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
};

Recto.defaultProps = {
  className: undefined,
};

export default Recto;
