import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Feedback from 'questions/Shared/Feedback';
import FormattedText from 'uikit/FormattedText';

import styles from './Verso.module.scss';

const cx = classNames.bind(styles);

const Verso = ({ className, question, t }) => (
  <div className={cx('container', className)}>
    <FormattedText className={cx('title')}>{question.title}</FormattedText>
    <div className={cx('answer')}>
      <p className={cx('label')}>{t('Questions/Essay/Recto/student-answer')}</p>
      <p className={cx('space')}>
        <FormattedText>{question.solution.user || '___'}</FormattedText>
      </p>
      {question.content.feedback && (
        <Feedback feedback={question.content.feedback} />
      )}
    </div>
  </div>
);

Verso.propTypes = {
  className: PropTypes.string,
  question: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

Verso.defaultProps = {
  className: undefined,
};

export default Verso;
