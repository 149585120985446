import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { mutationEditorCollaboratorDelete } from 'gql/mutations/editor';

import CollaboratorCard from './CollaboratorCard';

const CollaboratorCardContainer = ({ collaborator, ...props }) => {
  const { t } = useTranslation();
  const handleCollaboratorDelete = useCallback(() => {
    mutationEditorCollaboratorDelete(collaborator);
  }, [collaborator]);
  return (
    <CollaboratorCard
      collaborator={collaborator}
      handleCollaboratorDelete={handleCollaboratorDelete}
      t={t}
      {...props}
    />
  );
};

CollaboratorCardContainer.propTypes = {
  collaborator: PropTypes.object.isRequired,
};

export default CollaboratorCardContainer;
