import froalaeditor from 'froala-editor';

froalaeditor.PLUGINS.templates = (editor) => {
  function open() {
    const sideBars = document.getElementsByClassName(
      'DesignToolbar_container__XbZ9Y',
    );
    const sideBarsArray = [].slice.call(sideBars);
    const sideBar = sideBarsArray.find((sb) =>
      sb.id.includes(editor.fieldName),
    );
    sideBar?.classList.add('DesignToolbar_open__vcKEG');
    const sideBarLimits = document.getElementsByClassName(
      'DesignToolbar_container-limits__X0iIW',
    );
    const sideBarLimitsArray = [].slice.call(sideBarLimits);
    const sideBarLimit = sideBarLimitsArray.find((sbl) =>
      sbl.id.includes(editor.fieldName),
    );
    sideBarLimit?.classList.add('DesignToolbar_limits-large__buB5E');
  }
  return {
    open,
  };
};

froalaeditor.DefineIcon('templates', {
  FA5NAME: 'grid-2',
  template: 'font_awesome_5',
});

froalaeditor.DefineIcon('imageReplace', {
  FA5NAME: 'pen',
  template: 'font_awesome_5',
});

froalaeditor.RegisterCommand('imageReplace', {
  title: "Remplacer l'image",
  callback: function callback(cmd) {
    this.image.replace();
  },
});

froalaeditor.RegisterCommand('templates', {
  title: 'Utiliser une mise en page',
  callback() {
    this.templates.open();
  },
});
