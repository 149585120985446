import PropTypes from 'prop-types';
import React, { useCallback, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import {
  mutationEditorFolderDelete,
  mutationEditorFolderUpdate,
} from 'gql/mutations/editor';

import FolderCard from './FolderCard';

const FolderCardContainer = ({ courses, folder, ...props }) => {
  const { t } = useTranslation();
  const filteredCourses = useMemo(
    () => courses.filter((course) => course.folderId === folder.id) || [],
    [folder, courses],
  );
  const filteredCoursesIds = filteredCourses.map((course) => course.id);
  const { state } = useLocation();
  const [isExpanded, setExpanded] = useState(
    filteredCoursesIds.includes(state?.currentDisplayedCourseId),
  );
  const handleFolderExpandedToggle = useCallback((evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    setExpanded((s) => !s);
  }, []);
  const handleFolderUpdate = useCallback(
    (evt) => {
      evt.preventDefault();
      evt.stopPropagation();
      mutationEditorFolderUpdate(folder);
    },
    [folder],
  );
  const handleFolderDelete = useCallback(
    (evt) => {
      evt.preventDefault();
      evt.stopPropagation();
      mutationEditorFolderDelete(folder);
    },
    [folder],
  );
  return (
    <FolderCard
      courses={filteredCourses}
      folder={folder}
      handleFolderDelete={handleFolderDelete}
      handleFolderExpandedToggle={handleFolderExpandedToggle}
      handleFolderUpdate={handleFolderUpdate}
      isExpanded={isExpanded}
      t={t}
      {...props}
    />
  );
};

FolderCardContainer.propTypes = {
  courses: PropTypes.arrayOf(PropTypes.object).isRequired,
  folder: PropTypes.object.isRequired,
};

export default React.memo(FolderCardContainer);
