import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import Icon from 'uikit/Icon';

import MenuTooltip from '../MenuTooltip';
import styles from './MenuElement.module.scss';

const cx = classNames.bind(styles);

const MenuElement = ({
  asATag,
  className,
  dataTestId,
  icon,
  isActive,
  isBeta,
  isMenuExpanded,
  isSmall,
  t,
  target,
  title,
}) => {
  const Component = asATag ? 'a' : Link;
  const props = asATag ? { href: target } : { to: target };
  return (
    <MenuTooltip isMenuExpanded={isMenuExpanded} tooltip={title}>
      <Component
        {...props}
        aria-label={title}
        className={cx(
          'container',
          {
            isActive,
            isSmall,
            collapsed: !isMenuExpanded,
          },
          className,
        )}
        data-testid={dataTestId}
      >
        <Icon className={cx('icon')} name={icon} />
        <span className={cx('text-container')}>
          <span className={cx('text')}>{title}</span>
          {isBeta && (
            <span className={cx('text-beta')}>
              {t('Shared/MenuContainer/beta')}
            </span>
          )}
        </span>
      </Component>
    </MenuTooltip>
  );
};

MenuElement.propTypes = {
  asATag: PropTypes.bool,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  icon: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  isBeta: PropTypes.bool,
  isMenuExpanded: PropTypes.bool.isRequired,
  isSmall: PropTypes.bool,
  t: PropTypes.func.isRequired,
  target: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

MenuElement.defaultProps = {
  asATag: false,
  className: undefined,
  dataTestId: undefined,
  isBeta: false,
  isSmall: false,
};

export default MenuElement;
