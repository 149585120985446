import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { TAG_DATA } from 'gql/fragments';

export const TAGS_CREATE = gql`
  mutation TagsCreate($tags: [String]!) {
    tagsCreate(tags: $tags) {
      ...TagData
    }
  }
  ${TAG_DATA}
`;

export function useMutationTagsCreate() {
  const [mutation] = useMutation(TAGS_CREATE);
  return useCallback(
    (variables) =>
      mutation({
        variables,
        update(cache, { data: { tagsCreate } }) {
          cache.modify({
            fields: {
              tags: (existingTagsRef = [], { readField }) => {
                const newTags = tagsCreate.filter(
                  (tag) =>
                    !existingTagsRef.some(
                      (tagRef) => readField('id', tagRef) === tag.id,
                    ),
                );
                const newTagsRef = newTags.map((newTag) =>
                  cache.writeFragment({
                    id: `Tag:${newTag.id}`,
                    data: newTag,
                    fragment: TAG_DATA,
                  }),
                );
                return [...existingTagsRef, ...newTagsRef];
              },
            },
          });
        },
      }),
    [mutation],
  );
}
