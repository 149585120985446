import React from 'react';

import useSiteContext from 'hooks/useSiteContext';

import Participants from './Participants';

const ParticipantsContainer = (props) => {
  const { isGAR, isTNECanope, isRoleTeacher } = useSiteContext();
  return (
    <Participants
      isGAR={isGAR}
      isRoleTeacher={isRoleTeacher}
      isTNECanope={isTNECanope}
      {...props}
    />
  );
};

export default ParticipantsContainer;
