import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import AudioPicker from './AudioPicker';

const AudioPickerContainer = ({ name, ...props }) => {
  const { t } = useTranslation();
  const [, , { setValue: setAudio }] = useField(name);
  const [{ value: audioToImport }, , { setValue: setAudioToImport }] =
    useField('audioToImport');
  const [, , { setValue: setAudioPreview }] = useField('audioPreview');
  const handleDeselect = useCallback(() => {
    setAudio('');
    setAudioToImport(null);
    setAudioPreview(null);
  }, [setAudio, setAudioPreview, setAudioToImport]);
  return (
    <AudioPicker
      audioToImport={audioToImport}
      handleDeselect={handleDeselect}
      name={name}
      t={t}
      {...props}
    />
  );
};

AudioPickerContainer.propTypes = {
  name: PropTypes.string.isRequired,
};

export default AudioPickerContainer;
