import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Options from 'questions/Shared/Options';
import Tags from 'questions/Shared/Tags';
import Button from 'uikit/Button';
import Icon from 'uikit/Icon';
import InputError from 'uikit/InputError';
import InputRich from 'uikit/InputRich';
import Tooltip from 'uikit/Tooltip';

import styles from './Editor.module.scss';

const cx = classNames.bind(styles);

const Editor = ({
  className,
  handleCategoryAdd,
  handleCategoryRemove,
  handleDestinationAdd,
  handleDestinationRemove,
  categories,
  t,
}) => (
  <div className={cx('container', className)}>
    <p className={cx('label')}>
      {t('Questions/Categorization/Editor/categories')}
    </p>
    <div className={cx('field')}>
      <InputError name="categories" />
      {categories.map((category, categoryIndex) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className={cx('category-element')} key={categoryIndex}>
          <div className={cx('list-element')}>
            <InputRich
              name={`categories.${categoryIndex}.source`}
              placeholder={
                categoryIndex < 2 &&
                t(
                  `Questions/Categorization/Editor/category${
                    categoryIndex + 1
                  }-placeholder`,
                )
              }
            />
            <Button
              icon="trash-alt"
              onClick={() => handleCategoryRemove(categoryIndex)}
              tooltip={t('Questions/Categorization/Editor/delete-category')}
              variant="regular"
            />
          </div>
          <div className={cx('line')} />
          {[...category.destinations, ''].map((_, destinationIndex) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className={cx('list-element')} key={destinationIndex}>
              {destinationIndex === category.destinations.length ? (
                <Button
                  className={cx('destination-add')}
                  icon="plus"
                  intent="outline"
                  onClick={() => handleDestinationAdd(categoryIndex)}
                  size="small"
                  tooltip={t('Questions/Categorization/Editor/destination-add')}
                />
              ) : (
                <Icon className={cx('arrow')} name="arrow-turn-down-right" />
              )}
              {destinationIndex === category.destinations.length ? (
                <InputRich
                  className={cx('destination-complete')}
                  name="tmp"
                  onChange={(model) =>
                    handleDestinationAdd(categoryIndex, model)
                  }
                />
              ) : (
                <InputRich
                  className={cx('fill')}
                  name={`categories.${categoryIndex}.destinations.${destinationIndex}.title`}
                  placeholder={
                    categoryIndex < 2 &&
                    t(
                      `Questions/Categorization/Editor/category${
                        categoryIndex + 1
                      }-destination${destinationIndex + 1}-placeholder`,
                    )
                  }
                />
              )}
              {destinationIndex !== category.destinations.length && (
                <Button
                  icon="trash-alt"
                  onClick={() =>
                    handleDestinationRemove(categoryIndex, destinationIndex)
                  }
                  tooltip={t(
                    'Questions/Categorization/Editor/delete-destination',
                  )}
                  variant="regular"
                />
              )}
            </div>
          ))}
        </div>
      ))}
      <Button
        className={cx('category-add')}
        icon="plus"
        intent="outline"
        onClick={handleCategoryAdd}
      >
        {t('Questions/Categorization/Editor/category-add')}
      </Button>
    </div>
    <InputRich
      helper={
        <Tooltip tooltip={t('Questions/Categorization/Editor/feedback-helper')}>
          <Icon name="info-circle" />
        </Tooltip>
      }
      label={t('Questions/Categorization/feedback-label')}
      name="feedback"
    />
    <Options className={cx('options')}>
      <Tags />
    </Options>
  </div>
);

Editor.propTypes = {
  className: PropTypes.string,
  handleCategoryAdd: PropTypes.func.isRequired,
  handleCategoryRemove: PropTypes.func.isRequired,
  handleDestinationAdd: PropTypes.func.isRequired,
  handleDestinationRemove: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  t: PropTypes.func.isRequired,
};

Editor.defaultProps = {
  className: undefined,
};

export default Editor;
