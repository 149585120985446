import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import DirectLink from 'components/Course/Shared/DirectLink';
import Email from 'components/Course/Shared/Email';
import Button from 'uikit/Button';
import Modal from 'uikit/Modal';

import styles from './GroupShare.module.scss';

const cx = classNames.bind(styles);

const GroupShare = ({
  canvasRef,
  course,
  group,
  handleClose,
  isCourseInvitationRestricted = false,
  isDisplayed,
  isModalOpen,
  t,
}) => (
  <Modal isOpen={isModalOpen} onExit={handleClose}>
    <div className={cx('modal')}>
      <Button
        aria-label={t('Course/Teacher/Students/GroupShare/aria-close')}
        className={cx('close')}
        icon="times"
        onClick={handleClose}
      />
      <h1 className={cx('title')}>
        {t('Course/Teacher/Students/GroupShare/title')}
      </h1>
      <div className={cx('warning-container')}>
        {course.isExam && course.examStartTime === course.examStopTime && (
          <p className={cx('warning')}>
            {t('Course/Teacher/Cover/CourseShare/warning-settings')}
          </p>
        )}
        {isCourseInvitationRestricted && (
          <p className={cx('warning')}>
            {t(
              'Course/Teacher/Cover/CourseShare/warning-invitations-restricted',
            )}
          </p>
        )}
      </div>
      <DirectLink course={course} group={group} />
      <Email className={cx('email')} course={course} group={group} />
      {isDisplayed && <canvas className={cx('qrcode')} ref={canvasRef} />}
    </div>
  </Modal>
);

GroupShare.propTypes = {
  canvasRef: PropTypes.func.isRequired,
  course: PropTypes.object.isRequired,
  group: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  isCourseInvitationRestricted: PropTypes.bool,
  isDisplayed: PropTypes.bool.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

GroupShare.defaultProps = {
  group: null,
};

export default GroupShare;
