import { fabric } from 'fabric';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import storeJS from 'store';

import TextToolbar from './TextToolbar';
import DEFAULT_CANVAS_PROPERTIES from '../data/defaultCanvasProperties';
import THEMES from '../data/themes';
import { saveHistory } from '../config/history';

const TextToolbarContainer = ({ fabricRef, ...props }) => {
  const { t } = useTranslation();
  const handleTextAdd = useCallback(
    (type) => {
      saveHistory(fabricRef);
      const theme = THEMES[storeJS.get('slide')?.themeIndex];
      const text = new fabric.Textbox(
        t(`Questions/Shared/SlideBuilder/TextToolbar/${type}`),
        type === 'title'
          ? {
              ...DEFAULT_CANVAS_PROPERTIES.titleProps,
              ...theme?.titleProps,
              // if we don't add it,
              // design changes on one title element
              // are applied to every titles in the canvas
              styles: [],
            }
          : type === 'subtitle'
          ? {
              ...DEFAULT_CANVAS_PROPERTIES.subtitleProps,
              ...theme?.subtitleProps,
              // if we don't add it,
              // design changes on one subtitle element
              // are applied to every subtitles in the canvas
              styles: [],
            }
          : {
              ...DEFAULT_CANVAS_PROPERTIES.textProps,
              ...theme?.textProps,
              // if we don't add it,
              // design changes on one text element
              // are applied to every texts in the canvas
              styles: [],
            },
      );
      fabricRef.current.add(text).setActiveObject(text).renderAll();
    },
    [fabricRef, t],
  );
  const [textFormula, setTextFormula] = useState();
  const handleFormulaChange = useCallback((event) => {
    setTextFormula(event.target.value);
  }, []);
  const [formula, setFormula] = useState();
  const handleFormulaSave = useCallback((html) => {
    setFormula(html.outerHTML);
  }, []);
  const handleFormulaAdd = useCallback(() => {
    saveHistory(fabricRef);
    fabric.loadSVGFromString(formula, (object) => {
      const obj = fabric.util.groupSVGElements(object, {
        ...DEFAULT_CANVAS_PROPERTIES.SVGProps,
      });
      // eslint-disable-next-line no-underscore-dangle
      obj._objects.forEach((character) =>
        character.set({
          fill: '#1d254f',
        }),
      );
      fabricRef.current.add(obj).renderAll();
      setTextFormula('');
    });
  }, [fabricRef, formula]);
  return (
    <TextToolbar
      fabricRef={fabricRef}
      handleFormulaAdd={handleFormulaAdd}
      handleFormulaChange={handleFormulaChange}
      handleFormulaSave={handleFormulaSave}
      handleTextAdd={handleTextAdd}
      t={t}
      textFormula={textFormula}
      {...props}
    />
  );
};

TextToolbarContainer.propTypes = {
  fabricRef: PropTypes.object,
};

TextToolbarContainer.defaultProps = {
  fabricRef: undefined,
};

export default TextToolbarContainer;
