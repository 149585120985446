const getPlugins = (selectIndex) => [
  {
    name: 'hideOnClick',
    defaultValue: true,
    fn(instance) {
      return {
        onCreate() {
          instance.popper.addEventListener('click', () => {
            instance.hide();
          });
        },
      };
    },
  },
  {
    name: 'showOnCreate',
    defaultValue: true,
    fn(instance) {
      return {
        onCreate() {
          if (selectIndex === 0) {
            instance.show();
          }
        },
      };
    },
  },
  {
    name: 'hideOnPopperBlur',
    defaultValue: true,
    fn(instance) {
      return {
        onCreate() {
          instance.popper.addEventListener('focusout', (event) => {
            if (
              instance.props.hideOnPopperBlur &&
              event.relatedTarget &&
              !instance.popper.contains(event.relatedTarget)
            ) {
              instance.hide();
            }
          });
        },
      };
    },
  },
];

export default getPlugins;
