import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'uikit/Button';
import { MenuItem } from 'uikit/Menu';
import Modal from 'uikit/Modal';

import styles from './CourseDuplicate.module.scss';

const cx = classNames.bind(styles);

const CourseDuplicate = ({
  className,
  handleCourseDuplicate,
  handleModalClose,
  handleModalOpen,
  isLoading,
  isModalOpen,
  status,
  t,
}) => (
  <>
    <MenuItem className={className} icon="copy" onClick={handleModalOpen}>
      {t('Course/Teacher/Cover/CourseDuplicate/duplicate-course')}
    </MenuItem>
    <Modal isOpen={isModalOpen} onExit={handleModalClose}>
      <div className={cx('modal')}>
        <Button
          aria-label={t('Course/Teacher/Cover/CourseDuplicate/aria-close')}
          className={cx('close')}
          icon="times"
          onClick={handleModalClose}
        />
        <h1 className={cx('title')}>
          {t('Course/Teacher/Cover/CourseDuplicate/title')}
        </h1>
        <p className={cx('subtitle')}>
          {t('Course/Teacher/Cover/CourseDuplicate/subtitle')}
        </p>
        {status && (
          <p className={cx('error')} role="alert">
            {t('Course/Teacher/Cover/CourseDuplicate/course-duplicate-error')}
          </p>
        )}
        <Button
          className={cx('action')}
          intent="primary"
          isLoading={isLoading}
          onClick={handleCourseDuplicate}
          size="large"
        >
          {t('Course/Teacher/Cover/CourseDuplicate/confirm-duplicate-course')}
        </Button>
      </div>
    </Modal>
  </>
);

CourseDuplicate.propTypes = {
  className: PropTypes.string,
  handleCourseDuplicate: PropTypes.func.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  handleModalOpen: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  status: PropTypes.string,
  t: PropTypes.func.isRequired,
};

CourseDuplicate.defaultProps = {
  className: undefined,
  status: undefined,
};

export default CourseDuplicate;
