import { gql, useQuery } from '@apollo/client';

import { TAG_DATA } from 'gql/fragments';

export const QUERY_TAGS = gql`
  {
    tags {
      ...TagData
    }
  }
  ${TAG_DATA}
`;

export function useQueryTags(options) {
  const { loading, data, ...props } = useQuery(QUERY_TAGS, options);
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}
