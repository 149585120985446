import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import Expert from './Expert';

const ExpertContainer = ({ question, ...props }) => {
  const { t } = useTranslation();
  const [{ value: answer }, , { setValue: setAnswer }] = useField('answer');
  return (
    <Expert
      answer={answer}
      handleChoiceSelect={setAnswer}
      question={question}
      t={t}
      {...props}
    />
  );
};

ExpertContainer.propTypes = {
  question: PropTypes.object.isRequired,
};

ExpertContainer.validationSchema = (t) =>
  yup.object().shape({
    answer: yup
      .number()
      .required(t('Questions/JCT/Expert/answer-error'))
      .min(0, t('Questions/JCT/Expert/answer-error'))
      .max(3, t('Questions/JCT/Expert/answer-error')),
    feedback: yup.string().trim().max(1024),
  });

export default ExpertContainer;
