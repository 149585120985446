import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { apiUploadsS3 } from 'api/upload';
import useModal from 'hooks/useModal';
import Context from 'lib/context';
import { reportError } from 'lib/tracking';

import VideoImporter from './VideoImporter';

const VideoImporterContainer = ({ name, ...props }) => {
  const { t } = useTranslation();
  const { theme } = useContext(Context);
  const { pathname } = useLocation();
  const isStudying = pathname.includes('/study');
  const { isModalOpen, handleModalClose, handleModalOpen } = useModal(false);
  const [, , { setValue: setVideo }] = useField(name);
  const [{ value: videoToImport }, , { setValue: setVideoToImport }] =
    useField('videoToImport');
  const [{ value: videoPreview }, , { setValue: setVideoPreview }] =
    useField('videoPreview');
  const [isDroppable, setDroppable] = useState(false);
  const handleDragEnter = useCallback((evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    setDroppable(true);
  }, []);
  const handleDragLeave = useCallback((evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    setDroppable(false);
  }, []);
  const handleVideoReset = useCallback(() => {
    setVideoToImport(null);
    setVideoPreview(null);
  }, [setVideoPreview, setVideoToImport]);
  const handleImport = useCallback(async (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    const files = evt.dataTransfer ? evt.dataTransfer.files : evt.target.files;
    setDroppable(false);
    if (!files || !files.length || !files[0]) {
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (e) => {
      setVideoToImport(files[0]);
      setVideoPreview(e.target.result);
    };
  }, [setVideoPreview, setVideoToImport]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSelect = useCallback(async () => {
    try {
      setIsSubmitting(true);
      const filePath = await apiUploadsS3(videoToImport);
      setVideo(filePath);
      handleModalClose();
      setIsSubmitting(false);
    } catch (err) {
      reportError('video', 'upload', err);
    }
  }, [handleModalClose, setVideo, videoToImport]);
  const handleModalCloseAndReset = useCallback(() => {
    setVideoPreview('');
    setVideoToImport('');
    handleModalClose();
  }, [handleModalClose, setVideoPreview, setVideoToImport]);
  return (
    <VideoImporter
      handleDragEnter={handleDragEnter}
      handleDragLeave={handleDragLeave}
      handleImport={handleImport}
      handleModalCloseAndReset={handleModalCloseAndReset}
      handleModalOpen={handleModalOpen}
      handleSelect={handleSelect}
      handleVideoReset={handleVideoReset}
      isDroppable={isDroppable}
      isModalOpen={isModalOpen}
      isSubmitting={isSubmitting}
      name={name}
      t={t}
      theme={isStudying ? theme : {}}
      videoPreview={videoPreview}
      videoToImport={videoToImport}
      {...props}
    />
  );
};

VideoImporterContainer.propTypes = {
  name: PropTypes.string.isRequired,
};

export default VideoImporterContainer;
