import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
  mutationEditorGameDelete,
  mutationEditorGameUpdate,
} from 'gql/mutations/editor';
import Button from 'uikit/Button';
import Icon from 'uikit/Icon';

import GameLeaderboard from './GameLeaderboard/GameLeaderboard';
import styles from './GameCard.module.scss';

const cx = classNames.bind(styles);

const GameCard = ({ className = null, game }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/Games/GameCard',
  });

  const handleGameDelete = () => mutationEditorGameDelete(game);

  const handleGameUpdate = () => mutationEditorGameUpdate(game);

  return (
    <div className={cx('container', className)}>
      <div className={cx('logo')}>
        {game.type === 'water-level' ? <Icon name="swimmer" /> : null}
      </div>
      <h3 className={cx('title')}>{game.title}</h3>
      <Button as={Link} icon="gamepad" intent="primary" to={`/play/${game.id}`}>
        {t('play')}
      </Button>
      <GameLeaderboard className={cx('action')} game={game} />
      <Button
        className={cx('action')}
        icon="pencil"
        onClick={handleGameUpdate}
        tooltip={t('update')}
      />
      <Button
        className={cx('action')}
        icon="trash-alt"
        onClick={handleGameDelete}
        tooltip={t('delete')}
        variant="regular"
      />
    </div>
  );
};

GameCard.propTypes = {
  className: PropTypes.string,
  game: PropTypes.object.isRequired,
};

export default GameCard;
