import classNames from 'classnames/bind';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import Icon from 'uikit/Icon';

import styles from './InputTimer.module.scss';

const cx = classNames.bind(styles);

const InputTimer = React.forwardRef(
  (
    {
      className,
      handleChangeMinutes,
      handleChangeSeconds,
      handleIncrementTimer,
      helper,
      isDisabled,
      label,
      minutes,
      name,
      seconds,
      shouldHideError,
      t,
      ...props
    },
    ref,
  ) => {
    const [{ value, onChange, ...field }, meta] = useField(name);
    return (
      <label
        className={cx(
          'container',
          {
            isDisabled,
            hasError: meta.touched && meta.error,
          },
          className,
        )}
        htmlFor={name}
      >
        {(label || helper) && (
          <div className={cx('title')} id={`inputnumber-${name}-label`}>
            <div className={cx('label')}>{label}</div>
            <div className={cx('helper')}>{helper}</div>
          </div>
        )}
        <div className={cx('input-container')} ref={ref} role="group">
          <input
            aria-labelledby={`input-timer-minutes-${name}-label`}
            className={cx('input')}
            disabled={isDisabled}
            id={name}
            min={0}
            name={name}
            onChange={handleChangeMinutes}
            placeholder="MIN"
            type="number"
            value={minutes}
            {...field}
            {...props}
          />
          <span className={cx('colon')}>:</span>
          <input
            aria-labelledby={`input-timer-seconds-${name}-label`}
            className={cx('input')}
            disabled={isDisabled}
            id={name}
            min={0}
            name={name}
            onChange={handleChangeSeconds}
            placeholder="SEC"
            type="number"
            value={seconds}
            {...field}
            {...props}
          />
          <div className={cx('spin')}>
            <button
              className={cx('button')}
              onClick={(event) => handleIncrementTimer(event, 10)}
              type="button"
            >
              <Icon name="plus" />
            </button>
            <button
              className={cx('button')}
              onClick={(event) => handleIncrementTimer(event, -10)}
              type="button"
            >
              <Icon name="minus" />
            </button>
          </div>
        </div>
        {!shouldHideError && meta.touched && meta.error && (
          <p className={cx('error-message')} id={`error-${name}`} role="alert">
            {meta.error}
          </p>
        )}
      </label>
    );
  },
);

InputTimer.propTypes = {
  className: PropTypes.string,
  handleChangeMinutes: PropTypes.func.isRequired,
  handleChangeSeconds: PropTypes.func.isRequired,
  handleIncrementTimer: PropTypes.func.isRequired,
  helper: PropTypes.node,
  isDisabled: PropTypes.bool,
  label: PropTypes.node,
  minutes: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  seconds: PropTypes.number.isRequired,
  shouldHideError: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

InputTimer.defaultProps = {
  className: undefined,
  helper: undefined,
  isDisabled: false,
  label: undefined,
  shouldHideError: false,
};

export default InputTimer;
