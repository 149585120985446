import { fabric } from 'fabric';
import storeJS from 'store';

import changeTheme from './changeTheme';
import TEMPLATES from '../data/templates';
import loadBackground from './loadBackground';

const changeTemplate = ({ fabricRef, t, templateIndex, currentSlide }) => {
  const oldSlide = [...currentSlide];
  const textBoxes = TEMPLATES[templateIndex].textBoxes?.map((object) => {
    const oldElement = oldSlide.find((oldObject, index) => {
      const shouldReplaceByOldElement =
        oldObject.fontWeight === object.fontWeight &&
        oldObject.type !== 'group' &&
        oldObject.text !==
          t('Questions/Shared/SlideBuilder/TextToolbar/title') &&
        oldObject.text !==
          t('Questions/Shared/SlideBuilder/TextToolbar/subtitle') &&
        !oldObject.text?.includes('Lorem ipsum');
      if (shouldReplaceByOldElement) {
        oldSlide.splice(index, 1);
      }
      return shouldReplaceByOldElement;
    });
    if (oldElement) {
      return {
        ...object,
        text: oldElement.text,
        width: oldElement.width,
        height: oldElement.height,
        left: object.left + (object.width / 2 - oldElement.width / 2),
        top: object.top + (object.height / 2 - oldElement.height / 2),
      };
    }
    return {
      ...object,
      // without this step translation keys are
      // not translated during loadFromJSON function
      text: t(object.text),
    };
  });
  const remainingOldSlide = oldSlide.filter(
    (oldObject) =>
      (oldObject.type !== 'group' ||
        oldObject.objects?.every((subObject) => subObject.type === 'path')) &&
      oldObject.text !== t('Questions/Shared/SlideBuilder/TextToolbar/title') &&
      oldObject.text !==
        t('Questions/Shared/SlideBuilder/TextToolbar/subtitle') &&
      !oldObject.text?.includes('Lorem ipsum') &&
      !oldObject.src?.includes('imagePlaceholder'),
  );
  const remainingOldObjects = remainingOldSlide.filter(
    (o) => o.type !== 'i-text',
  );
  const remainingOldTextBoxes = remainingOldSlide.filter(
    (o) => o.type === 'i-text',
  );
  const template = {
    ...TEMPLATES[templateIndex],
    objects: TEMPLATES[templateIndex].objects
      ? [...TEMPLATES[templateIndex].objects, ...remainingOldObjects]
      : remainingOldObjects,
    textBoxes:
      textBoxes && remainingOldTextBoxes
        ? [...textBoxes, ...remainingOldTextBoxes]
        : textBoxes || remainingOldTextBoxes,
  };
  fabricRef.current.loadFromJSON(template, () => {
    // Apply template background image
    if (template.backgroundImage) {
      loadBackground(fabricRef, template);
    }
    if (storeJS.get('slide')?.backgroundColor) {
      fabricRef.current.setBackgroundColor(
        storeJS.get('slide')?.backgroundColor,
      );
    } else if (!storeJS.get('slide')?.themeIndex) {
      fabricRef.current.setBackgroundColor(
        TEMPLATES[templateIndex].backgroundColor,
      );
    }
    // Apply texts
    template.textBoxes?.map((object) => {
      const text = new fabric.Textbox(object.text, {
        ...object,
        // if we don't add it,
        // design changes on one subtitle element
        // are applied to every subtitles in the canvas
        styles: [],
      });
      fabricRef.current.add(text);
      return null;
    });
    // Apply last theme used on the template content
    if (storeJS.get('slide')?.themeIndex) {
      const index = storeJS.get('slide').themeIndex;
      changeTheme(fabricRef, index);
    }
    fabricRef.current.renderAll();
  });
};

export default changeTemplate;
