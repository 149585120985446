import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import { useQueryLocalEditor } from 'gql/queries/local';

import QuestionCard from './QuestionCard/QuestionCard';
import styles from './Questions.module.scss';

const cx = classNames.bind(styles);

const Questions = ({ chapterId = null, course, ...props }) => {
  const {
    data: {
      editor: { questionsSelected },
    },
  } = useQueryLocalEditor();

  const qs =
    (chapterId === null
      ? course.questions
      : course.chapters.find((c) => c.id === chapterId)?.questions) || [];

  const questions = [...qs].sort((q1, q2) =>
    q1.type === 'HomeScreen' && q2.type === 'HomeScreen'
      ? q1.order - q2.order
      : q2.type === 'HomeScreen'
      ? 1
      : 0,
  );

  const selectedIds = questionsSelected.map((question) => question.id);

  return (
    <div>
      {questions.map((question, index) => (
        <QuestionCard
          {...props}
          className={cx('question')}
          course={course}
          isSelected={selectedIds.includes(question.id)}
          key={question.id}
          nQuestionsSelected={selectedIds.length}
          position={index + 1}
          question={question}
          questions={questions}
          questionsSelected={questionsSelected}
        />
      ))}
    </div>
  );
};

Questions.propTypes = {
  chapterId: PropTypes.string,
  course: PropTypes.object.isRequired,
};

export default React.memo(Questions);
