import * as dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { Suspense, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import {
  useMutationAuthResendVerification,
  useMutationAuthSetEmail,
} from 'gql/mutations/auth';
import { useMutationMeUpdate } from 'gql/mutations/me';
import { useQueryMe } from 'gql/queries/me';
import extract from 'helpers/extract';
import { trackEvent, reportError } from 'lib/tracking';

const AccountVerification = React.lazy(() => import('./AccountVerification'));

const AccountVerificationContainer = (props) => {
  const { t } = useTranslation();
  const { isLoading, data } = useQueryMe();
  const metadataMe = useMemo(
    () => (isLoading ? {} : data.me.metadata),
    [data, isLoading],
  );
  const isOpen = isLoading
    ? false
    : data.me.tags.includes('onboarded') &&
      !data.me.isVerified &&
      !dayjs().isSame(data.me.registeredAt, 'day') &&
      !dayjs().isSame(
        metadataMe?.accountVerification || new Date(2014, 1, 1, 1, 0, 0, 0),
        'day',
      );
  const mutationMeUpdate = useMutationMeUpdate();
  const handleModalClose = useCallback(async () => {
    try {
      await mutationMeUpdate({
        metadata: {
          ...metadataMe,
          accountVerification: new Date(),
        },
      });
      trackEvent('me:update', 'metadata:accountVerification');
    } catch (err) {
      reportError('me:update', 'metadata:accountVerification', err);
    }
  }, [metadataMe, mutationMeUpdate]);
  const hasEmail = isLoading ? false : !!data.me.email;
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup
          .string()
          .trim()
          .lowercase()
          .required(t('App/AccountVerification/email-error-required'))
          .email(t('App/AccountVerification/email-error-required'))
          .max(256, t('App/AccountVerification/email-error-required')),
      }),
    [t],
  );
  const mutationAuthSetEmail = useMutationAuthSetEmail();
  const mutationAuthResendVerification = useMutationAuthResendVerification();
  const [hasSetEmail, setSetEmail] = useState(false);
  const handleSetEmail = useCallback(
    async (values, form) => {
      try {
        form.setStatus(null);
        const variables = extract(values, validationSchema);
        await mutationAuthSetEmail(variables);
        await mutationAuthResendVerification(values);
        setSetEmail(true);
        trackEvent('auth', 'set-email');
      } catch (err) {
        form.setStatus(err.message);
        reportError('auth', 'set-email', err);
      }
    },
    [mutationAuthSetEmail, mutationAuthResendVerification, validationSchema],
  );
  const email = isLoading ? undefined : data.me.email;
  const [hasResentEmail, setResentEmail] = useState(false);
  const handleResendVerification = useCallback(async () => {
    try {
      await mutationAuthResendVerification({
        email,
      });
      setResentEmail(true);
      trackEvent('auth', 'resend-verification');
    } catch (err) {
      reportError('auth', 'resend-verification', err);
    }
  }, [email, mutationAuthResendVerification]);
  return isOpen ? (
    <Suspense fallback={null}>
      <AccountVerification
        handleModalClose={handleModalClose}
        handleResendVerification={handleResendVerification}
        handleSetEmail={handleSetEmail}
        hasEmail={hasEmail}
        hasSetEmail={hasSetEmail}
        hasResentEmail={hasResentEmail}
        t={t}
        validationSchema={validationSchema}
        {...props}
      />
    </Suspense>
  ) : null;
};

AccountVerificationContainer.propTypes = {
  className: PropTypes.string,
};

AccountVerificationContainer.defaultProps = {
  className: undefined,
};

export default AccountVerificationContainer;
