import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './Verso.module.scss';

const cx = classNames.bind(styles);

const Verso = ({ canvasRef, className }) => (
  <div className={cx('container', className)}>
    <canvas className={cx('canvas')} ref={canvasRef} />
  </div>
);

Verso.propTypes = {
  canvasRef: PropTypes.object,
  className: PropTypes.string,
};

Verso.defaultProps = {
  canvasRef: undefined,
  className: undefined,
};

export default Verso;
