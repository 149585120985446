const COLORS = [
  { name: 'purple', hex: '#906ef0' },
  { name: 'blue', hex: '#146aff' },
  { name: 'turquoise', hex: '#32cdfb' },
  { name: 'red', hex: '#ed343f' },
  { name: 'green', hex: '#6bdd6a' },
  { name: 'orange', hex: '#eb9500' },
  { name: 'yellow', hex: '#ffb500' },
  { name: 'pink', hex: '#f35e9a' },
  { name: 'black', hex: '#1d254f' },
  { name: 'white', hex: '#fff' },
  { name: 'transparent', hex: '#f5f6fa' },
  { name: 'image-picker', hex: '#fff' },
];

export default COLORS;
