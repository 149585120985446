import classNames from 'classnames/bind';
import React, { Suspense } from 'react';

import { useQueryMe } from 'gql/queries/me';
import Modal from 'uikit/Modal';

import Role from './Role/Role';
import Sector from './Sector/Sector';
import styles from './Migrations.module.scss';

const cx = classNames.bind(styles);

const Migrations = () => {
  const { isLoading, data } = useQueryMe();

  const me = data.me;

  const shouldFillRole = isLoading
    ? false
    : data.me.tags.includes('onboarded') &&
      !['student', 'teacher'].includes(data.me.role);

  const shouldFillSector = isLoading
    ? false
    : data.me.tags.includes('onboarded') &&
      !['lower', 'higher', 'business'].includes(data.me.sector);

  return !shouldFillRole && !shouldFillSector ? null : (
    <Suspense fallback={null}>
      {shouldFillSector ? (
        <Modal isOpen>
          <div className={cx('modal')}>
            <Sector />
          </div>
        </Modal>
      ) : shouldFillRole ? (
        <Modal isOpen>
          <div className={cx('modal')}>
            <Role me={me} />
          </div>
        </Modal>
      ) : null}
    </Suspense>
  );
};

export default Migrations;
