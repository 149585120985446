import classNames from 'classnames/bind';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import ContentEditable from 'react-contenteditable';
import FroalaEditor from 'react-froala-wysiwyg';

import { useTranslation } from 'react-i18next';
import storeJS from 'store';

import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

import 'froala-editor/js/languages/fr';
import 'froala-editor/js/languages/nl';

import 'froala-editor/js/plugins/align.min';

import 'froala-editor/js/plugins/colors.min';
import 'froala-editor/css/plugins/colors.min.css';

import 'froala-editor/js/plugins/emoticons.min';
import 'froala-editor/css/plugins/emoticons.min.css';

import 'froala-editor/js/plugins/draggable.min';
import 'froala-editor/css/plugins/draggable.min.css';

import 'froala-editor/js/third_party/font_awesome.min';

import 'froala-editor/js/plugins/image.min';
import 'froala-editor/css/plugins/image.min.css';

import 'froala-editor/js/plugins/inline_class.min';

import 'froala-editor/js/plugins/line_breaker.min';
import 'froala-editor/css/plugins/line_breaker.min.css';

import 'froala-editor/js/plugins/link.min';

import 'froala-editor/js/plugins/lists.min';

import 'froala-editor/js/plugins/paragraph_format.min';

import 'froala-editor/js/plugins/special_characters.min';
import 'froala-editor/css/plugins/special_characters.min.css';

import 'froala-editor/js/plugins/table.min';
import 'froala-editor/css/plugins/table.min.css';

import 'froala-editor/js/plugins/url.min';

import 'froala-editor/js/plugins/video.min';
import 'froala-editor/css/plugins/video.min.css';

import 'froala-editor/js/plugins/word_paste.min';

import './plugins/audio';
import './plugins/blank';
import './plugins/blankWithChoices';
import './plugins/code';
import './plugins/embedded';
import './plugins/math';
import './plugins/templates/index';

import { apiUploadsS3 } from 'api/upload';

import DesignToolbar from './plugins/templates/DesignToolbar';
import styles from './InputRich2.module.scss';

const cx = classNames.bind(styles);

function toolbarButtonsGenerator(isFillInTheBlanks) {
  return {
    moreText: {
      buttons: [
        isFillInTheBlanks && 'insertBlank',
        isFillInTheBlanks && 'insertBlankWithChoices',
        'templates',
        'bold',
        'italic',
        'underline',
        'textColor',
        'inlineClass',
        'align',
        'formatUL',
        'strikeThrough',
        'subscript',
        'superscript',
        'backgroundColor',
        'clearFormatting',
        'paragraphFormat',
        'formatOL',
        'outdent',
        'indent',
      ],
      buttonsVisible: 6,
    },
    moreRich: {
      buttons: [
        'insertLink',
        'insertMath',
        'insertImage',
        'insertVideo',
        'insertAudio',
        'insertEmbedded',
        'insertCode',
        'emoticons',
        'fontAwesome',
        'specialCharacters',
        'insertHR',
        'insertTable',
      ],
      buttonsVisible: 5,
    },
  };
}

const InputRich2 = ({
  autoFocus,
  className,
  'data-testid': dataTestId,
  froalaRef,
  handleTemplatesClose,
  helper,
  isDisabled,
  isExternal,
  isFillInTheBlanks,
  label,
  name,
  onChange,
  placeholder,
  shouldHideError,
  t,
}) => {
  const { i18n } = useTranslation();
  const [{ value }, meta, { setValue }] = useField(name);
  useEffect(() => {
    if (froalaRef?.current?.editor?.$placeholder) {
      froalaRef.current.editor.opts.placeholderText = placeholder;
      froalaRef.current.editor.placeholder.refresh();

    }
  }, [froalaRef, placeholder]);
  return (
    <label
      className={cx(
        'container',
        {
          isDisabled,
          hasError: meta.touched && meta.error,
        },
        className,
      )}
      htmlFor={name}
    >
      {(label || helper) && (
        <div className={cx('title')}>
          <div className={cx('label')}>{label}</div>
          <div className={cx('helper')}>{helper}</div>
        </div>
      )}
      {isExternal ? (
        <div className={cx('cefield')}>
          <ContentEditable
            disabled={isDisabled}
            html={value}
            onChange={(evt) => setValue(evt.target.value)}
          />
        </div>
      ) : (
        <div className={cx('input-builder-container')}>
          <div className={cx('field')} data-testid={dataTestId}>
            <FroalaEditor
              className={cx('editor')}
              config={{
                key: process.env.REACT_APP_FROALA_KEY,
                charCounterCount: false,
                emoticonsUseImage: false,
                fileUpload: false,
                attribution: false,
                autofocus: autoFocus,
                htmlAllowComments: false,
                htmlExecuteScripts: false,
                inlineClasses: {
                  'fr-class-title': t('uikit/InputRich2/title'),
                  'fr-class-subtitle': t('uikit/InputRich2/subtitle'),
                  'fr-class-text': t('uikit/InputRich2/text'),
                },
                placeholderText: placeholder,
                requestHeaders: {
                  Authorization: `Bearer ${storeJS.get('token')}`,
                },
                spellcheck: false,
                toolbarBottom: true,
                toolbarButtons: toolbarButtonsGenerator(isFillInTheBlanks),
                toolbarButtonsMD: toolbarButtonsGenerator(isFillInTheBlanks),
                toolbarButtonsSM: toolbarButtonsGenerator(isFillInTheBlanks),
                toolbarButtonsXS: toolbarButtonsGenerator(isFillInTheBlanks),
                toolbarSticky: false,
                imageAllowedTypes: ['jpeg', 'jpg', 'png', 'gif', 'svg', 'webp'],
                imageCORSProxy: null,
                imageDefaultWidth: 500,
                imageEditButtons: [
                  'imageReplace',
                  'imageCaption',
                  'imageRemove',
                ],
                imageInsertButtons: [
                  'imageBack',
                  '|',
                  'imageUpload',
                  'imageByURL',
                ],
                zIndex: 999,
                imagePasteProcess: true,
                imageResizeWithPercent: false,
                imageAddNewLine: true,
                imageUploadRemoteUrls: false,
                language: i18n.language,
                linkAlwaysBlank: true,
                linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
                linkInsertButtons: ['linkBack'],
                paragraphFormat: {
                  N: 'Normal',
                  H1: 'Heading 1',
                  H2: 'Heading 2',
                  H3: 'Heading 3',
                },
                paragraphMultipleStyles: false,
                listAdvancedTypes: false,
                videoDefaultWidth: 600,
                videoEditButtons: ['videoReplace', 'videoRemove'],
                videoInsertButtons: [
                  'videoBack',
                  '|',
                  'videoUpload',
                  'videoByURL',
                  'videoEmbed',
                ],
                videoMaxSize: 1024 * 1024 * 500,
                events: {
                  initialized() {
                    const editor = this;
                    editor.fieldName = name;
                    editor?.toolbar?.hide();
                    if (isDisabled) {
                      editor?.edit?.off();
                    }
                    if (autoFocus) {
                      editor?.toolbar?.show();
                    }
                  },
                  focus: function onFocus() {
                    const editor = this;
                    editor?.toolbar?.show();
                  },
                  blur: function onBlur() {
                    const editor = this;
                    editor?.toolbar?.hide();
                    handleTemplatesClose();
                  },
                  'image.beforeUpload': function beforeUpload(imgs) {
                    const editor = this;
                    async function up(img) {
                      try {
                        if (!img || !img.type) {
                          return;
                        }
                        editor.events.disableBlur();
                        const filePath = await apiUploadsS3(img);
                        editor.image.remove();
                        editor.image.insert(filePath);
                      } catch (err) {
                        //
                      } finally {
                        editor.events.enableBlur();
                      }
                    }
                    [...imgs].forEach(up);
                    return false;
                  },
                  'video.beforeUpload': function beforeUpload(videos) {
                    const editor = this;
                    async function up(video) {
                      try {
                        if (!video || !video.type) {
                          return;
                        }
                        editor.events.disableBlur();
                        const filePath = await apiUploadsS3(video);
                        editor.video.insert(
                          `<video src="${filePath}" style="width: 600px;" controls="" class="fr-draggable">Votre navigateur ne supporte pas les vidéos au format HTML5.</video>`,
                        );
                      } catch (err) {
                        //
                      } finally {
                        editor.events.enableBlur();
                      }
                    }
                    [...videos].forEach(up);
                    return false;
                  },
                },
                immediateReactModelUpdate: !!onChange,
              }}
              model={value}
              onModelChange={(model) => {
                if (onChange) {
                  onChange(model);
                } else {
                  setValue(model);
                }
              }}
              immediateReactModelUpdate={!!onChange}
              ref={froalaRef}
            />
          </div>
        </div>
      )}
      {!shouldHideError && meta.touched && meta.error && (
        <p className={cx('error-message')} id={`error-${name}`} role="alert">
          {meta.error}
        </p>
      )}
      <DesignToolbar froalaRef={froalaRef} name={name} />
    </label>
  );
};

InputRich2.propTypes = {
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  'data-testid': PropTypes.string,
  froalaRef: PropTypes.object,
  handleTemplatesClose: PropTypes.func.isRequired,
  helper: PropTypes.node,
  isDisabled: PropTypes.bool,
  isExternal: PropTypes.bool.isRequired,
  isFillInTheBlanks: PropTypes.bool,
  label: PropTypes.node,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  shouldHideError: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

InputRich2.defaultProps = {
  autoFocus: false,
  className: undefined,
  'data-testid': undefined,
  froalaRef: undefined,
  helper: undefined,
  isDisabled: false,
  isFillInTheBlanks: false,
  label: undefined,
  onChange: undefined,
  placeholder: undefined,
  shouldHideError: false,
};

export default InputRich2;
