import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Icon from 'uikit/Icon';

import styles from './Zone.module.scss';

const cx = classNames.bind(styles);

const Zone = ({
  handleDelete,
  handleMouseMove,
  handleStartGrab,
  handleStartResize,
  isActive,
  shapeType,
  sizeData,
}) => (
  <div
    className={cx('zone', `shape-${shapeType}`, {
      isActive,
    })}
    style={{
      width: `${sizeData.width}px`,
      height: `${sizeData.height}px`,
      left: `${sizeData.left}px`,
      top: `${sizeData.top}px`,
    }}
  >
    <button
      className={cx('move-button')}
      onMouseDown={handleStartGrab}
      onTouchStart={handleStartGrab}
      onMouseMove={handleMouseMove}
      onTouchMove={handleMouseMove}
      style={{
        width: `${sizeData.width}px`,
        height: `${sizeData.height}px`,
        left: `${sizeData.left}px`,
        top: `${sizeData.top}px`,
      }}
      type="button"
    >
      {' '}
    </button>
    {isActive && (
      <>
        <button
          className={cx('resize')}
          onMouseDown={handleStartResize}
          onTouchStart={handleStartResize}
          onMouseMove={handleMouseMove}
          onTouchMove={handleMouseMove}
          type="button"
        >
          <Icon name={shapeType === 'circle' ? 'arrows-alt-v' : 'arrows-alt'} />
        </button>
        <button className={cx('delete')} onClick={handleDelete} type="button">
          <Icon name="trash-alt" variant="regular" />
        </button>
      </>
    )}
  </div>
);

Zone.propTypes = {
  handleDelete: PropTypes.func.isRequired,
  handleMouseMove: PropTypes.func.isRequired,
  handleStartGrab: PropTypes.func.isRequired,
  handleStartResize: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  shapeType: PropTypes.string.isRequired,
  sizeData: PropTypes.object.isRequired,
};

export default Zone;
