import t from 'helpers/t';

const CATEGORIES = [
  {
    key: 'agriculture',
    translation: t('Global/category/agriculture'),
  },
  {
    key: 'anthropology',
    translation: t('Global/category/anthropology'),
  },
  {
    key: 'architecture',
    translation: t('Global/category/architecture'),
  },
  {
    key: 'art',
    translation: t('Global/category/art'),
  },
  {
    key: 'biology',
    translation: t('Global/category/biology'),
  },
  {
    key: 'civic-education',
    translation: t('Global/category/civic-education'),
  },
  {
    key: 'computer-science',
    translation: t('Global/category/computer-science'),
  },
  {
    key: 'communication',
    translation: t('Global/category/communication'),
  },
  {
    key: 'design',
    translation: t('Global/category/design'),
  },
  {
    key: 'ecology',
    translation: t('Global/category/ecology'),
  },
  {
    key: 'economics',
    translation: t('Global/category/economics'),
  },
  {
    key: 'education',
    translation: t('Global/category/education'),
  },
  {
    key: 'engineering',
    translation: t('Global/category/engineering'),
  },
  {
    key: 'geology',
    translation: t('Global/category/geology'),
  },
  {
    key: 'health',
    translation: t('Global/category/health'),
  },
  {
    key: 'history-geography',
    translation: t('Global/category/history-geography'),
  },
  {
    key: 'journalism',
    translation: t('Global/category/journalism'),
  },
  {
    key: 'language',
    translation: t('Global/category/language'),
  },
  {
    key: 'law',
    translation: t('Global/category/law'),
  },
  {
    key: 'literature',
    translation: t('Global/category/literature'),
  },
  {
    key: 'logic',
    translation: t('Global/category/logic'),
  },
  {
    key: 'management',
    translation: t('Global/category/management'),
  },
  {
    key: 'mathematics',
    translation: t('Global/category/mathematics'),
  },
  {
    key: 'music',
    translation: t('Global/category/music'),
  },
  {
    key: 'physic-chemistry',
    translation: t('Global/category/physic-chemistry'),
  },
  {
    key: 'philosophy',
    translation: t('Global/category/philosophy'),
  },
  {
    key: 'political-sciences',
    translation: t('Global/category/political-sciences'),
  },
  {
    key: 'psychology',
    translation: t('Global/category/psychology'),
  },
  {
    key: 'religion',
    translation: t('Global/category/religion'),
  },
  {
    key: 'science',
    translation: t('Global/category/science'),
  },
  {
    key: 'sport',
    translation: t('Global/category/sport'),
  },
  {
    key: 'social-sciences',
    translation: t('Global/category/social-sciences'),
  },
  {
    key: 'technology',
    translation: t('Global/category/technology'),
  },
  {
    key: 'other',
    translation: t('Global/category/other'),
  },
];

export default CATEGORIES;
