import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './LegendPin.module.scss';

const cx = classNames.bind(styles);

const LegendPin = ({
  handleMouseMove,
  handleStartGrab,
  index,
  isActive,
  positionData,
}) => (
  <button
    className={cx('legend-pin', {
      isActive,
    })}
    onMouseDown={handleStartGrab}
    onTouchStart={handleStartGrab}
    onMouseMove={handleMouseMove}
    onTouchMove={handleMouseMove}
    style={{ left: `${positionData.left}px`, top: `${positionData.top}px` }}
    type="button"
  >
    {index + 1}
  </button>
);

LegendPin.propTypes = {
  index: PropTypes.number.isRequired,
  isActive: PropTypes.bool.isRequired,
  handleMouseMove: PropTypes.func.isRequired,
  handleStartGrab: PropTypes.func.isRequired,
  positionData: PropTypes.object.isRequired,
};

export default LegendPin;
