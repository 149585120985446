import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Icon from 'uikit/Icon';

import DesignToolbar from './DesignToolbar';
import MediaToolbar from './MediaToolbar';
import TextToolbar from './TextToolbar';
import SecondaryToolbar from './SecondaryToolbar';
import styles from './SlideBuilder.module.scss';

const cx = classNames.bind(styles);

const SlideBuilder = ({
  activeMenu,
  canvasRef,
  className,
  fabricRef,
  handleToolbarChange,
  isDisplayedInColumn,
  selectedObject,
}) => (
  <div className={cx('container', className, { column: isDisplayedInColumn })}>
    <SecondaryToolbar fabricRef={fabricRef} selectedObject={selectedObject} />
    <div className={cx('canvas-container', { column: isDisplayedInColumn })}>
      <canvas className={cx('canvas')} ref={canvasRef} />
    </div>
    <div className={cx('toolbar')}>
      <div className={cx('toolbar-menu-container')}>
        <div className={cx('toolbar-menu')}>
          {['template', 'text', 'image'].map((menu) => (
            <button
              className={cx('toolbar-menu-button', {
                isActive: activeMenu === menu,
              })}
              key={menu}
              onClick={() => handleToolbarChange(menu)}
              type="button"
            >
              <Icon
                className={cx('icon')}
                name={
                  menu === 'template'
                    ? 'grid-2'
                    : menu === 'image'
                    ? 'file-download'
                    : menu
                }
              />
            </button>
          ))}
        </div>
      </div>
      <div className={cx('toolbar-content')}>
        {activeMenu === 'template' ? (
          <DesignToolbar
            fabricRef={fabricRef}
            isDisplayedInColumn={isDisplayedInColumn}
          />
        ) : activeMenu === 'text' ? (
          <TextToolbar
            fabricRef={fabricRef}
            isDisplayedInColumn={isDisplayedInColumn}
          />
        ) : (
          <MediaToolbar
            fabricRef={fabricRef}
            isDisplayedInColumn={isDisplayedInColumn}
          />
        )}
      </div>
    </div>
  </div>
);

SlideBuilder.propTypes = {
  activeMenu: PropTypes.oneOf(['template', 'text', 'image']).isRequired,
  canvasRef: PropTypes.object,
  className: PropTypes.string,
  fabricRef: PropTypes.object,
  handleToolbarChange: PropTypes.func.isRequired,
  isDisplayedInColumn: PropTypes.bool.isRequired,
  selectedObject: PropTypes.object,
};

SlideBuilder.defaultProps = {
  canvasRef: undefined,
  className: undefined,
  fabricRef: undefined,
  selectedObject: undefined,
};

export default SlideBuilder;
