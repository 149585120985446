import React, { useCallback, useEffect, useRef, useState } from 'react';

import { getDimensions } from 'questions/Shared/utils/images';
import ImageContainer from './ImageContainer';

const ImageContainerContainer = (props) => {
  const wrapperRef = useRef(null);
  const imageRef = useRef(null);
  const [image, setImage] = useState({
    width: null,
    height: null,
    diagonal: null,
  });
  const handleImageLoaded = useCallback(() => {
    const [width, height, diagonal] = getDimensions(wrapperRef, imageRef);
    setImage({
      width,
      height,
      diagonal,
    });
  }, []);
  const handleScreenResize = useCallback(() => {
    if (wrapperRef && imageRef) {
      handleImageLoaded();
    }
  }, [handleImageLoaded]);
  useEffect(() => {
    window.addEventListener('resize', handleScreenResize);
    return () => {
      window.removeEventListener('resize', handleScreenResize);
    };
  }, [handleScreenResize]);
  return (
    <ImageContainer
      handleImageLoaded={handleImageLoaded}
      image={image}
      imageRef={imageRef}
      wrapperRef={wrapperRef}
      {...props}
    />
  );
};

export default ImageContainerContainer;
