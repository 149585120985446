import * as dayjs from 'dayjs';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import storeJS from 'store';

import { useQueryLocalApp } from 'gql/queries/local';
import { useQueryMe } from 'gql/queries/me';
import { useQueryNotificationsMe } from 'gql/queries/notification';
import { mutationAppToggleMenu } from 'gql/mutations/app';
import isToday from 'helpers/isToday';
import useSiteContext from 'hooks/useSiteContext';

import MenuContainer from './MenuContainer';

const MenuContainerContainer = (props) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [isMenuVisible, setMenuVisible] = useState(false);
  const closeButtonRef = useRef();
  const handleMenuVisibleChange = useCallback(() => {
    setMenuVisible((v) => !v);
  }, []);
  useEffect(() => {
    if (isMenuVisible === true) {
      closeButtonRef.current?.focus();
    }
  }, [closeButtonRef, isMenuVisible]);
  const { isLoading: isLoadingMe, data: dataMe } = useQueryMe();
  const { isLoading, data } = useQueryNotificationsMe({
    variables: {
      offset: 0,
      limit: 10,
    },
  });
  const metadataMe = isLoadingMe ? null : dataMe.me.metadata;
  const notificationLastRead = metadataMe?.notificationLastRead;
  const nUnreadNotifications = useMemo(() => {
    const notifications = isLoading ? [] : data.notificationsMe;
    if (notificationLastRead) {
      return notifications.filter(
        (notification) =>
          !notification.isRead &&
          dayjs(notification.deliverAt).isAfter(notificationLastRead),
      ).length;
    }
    return notifications.filter((notification) => !notification.isRead).length;
  }, [data, isLoading, notificationLastRead]);
  const { isEditionDisabled, isGAR, isTNECanope, isInTeams, isRoleTeacher } =
    useSiteContext();
  const me = dataMe?.me;
  const isRegisteredToday = dayjs(me?.registeredAt).isSame(
    dayjs(new Date()),
    'day',
  );
  const isInstitutionPopUpVisible = useMemo(
    () =>
      me &&
      me.tags.includes('tour:3') &&
      !me.tags.includes('institution-asked') &&
      !me.institution &&
      !isRegisteredToday,
    [me, isRegisteredToday],
  );
  const isTutorialsPopUpVisible = useMemo(
    () =>
      me &&
      me.tags.includes('tour:3') &&
      !me.tags.includes('tutorials') &&
      isRegisteredToday &&
      pathname === '/',
    [me, isRegisteredToday, pathname],
  );
  const {
    data: {
      app: { isMenuExpanded },
    },
  } = useQueryLocalApp();
  const isWarningDisplayed = me.organization?.settings?.isWarningDisplayed;
  const isOrganizationWarningClosedToday = isToday(
    storeJS.get('organizationWarningLastClose'),
  );
  const [isOrganizationWarningDisplayed, setIsOrganizationWarningDisplayed] =
    useState(() => isWarningDisplayed && !isOrganizationWarningClosedToday);
  const handleWarningClose = () => {
    storeJS.set('organizationWarningLastClose', new Date());
    setIsOrganizationWarningDisplayed(false);
  };
  return (
    <MenuContainer
      {...props}
      closeButtonRef={closeButtonRef}
      handleMenuVisibleChange={handleMenuVisibleChange}
      onWarningClose={handleWarningClose}
      isEditionDisabled={isEditionDisabled}
      isGAR={isGAR}
      isLoading={isLoadingMe}
      isMenuExpanded={isMenuExpanded}
      isMenuVisible={isMenuVisible}
      isInstitutionPopUpVisible={isInstitutionPopUpVisible}
      isInTeams={isInTeams}
      isTNECanope={isTNECanope}
      isTutorialsPopUpVisible={isTutorialsPopUpVisible}
      isOrganizationWarningDisplayed={isOrganizationWarningDisplayed}
      isRoleTeacher={isRoleTeacher}
      me={me}
      nUnreadNotifications={nUnreadNotifications}
      onMenuToggle={mutationAppToggleMenu}
      t={t}
    />
  );
};

export default MenuContainerContainer;
