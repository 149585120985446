import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Aggregate from './Aggregate';

const AggregateContainer = ({ aggregation, ...props }) => {
  const { t } = useTranslation();
  const [currentAnswer, setCurrentAnswer] = useState(0);
  const handleAnswerPrevious = useCallback(() => {
    setCurrentAnswer(Math.max(currentAnswer - 1, 0));
  }, [currentAnswer]);
  const handleAnswerNext = useCallback(async () => {
    setCurrentAnswer(
      Math.min(currentAnswer + 1, aggregation.aggregatedAnswers.length - 1),
    );
  }, [aggregation, currentAnswer]);
  const [mediaDuration, setMediaDuration] = useState(' ');
  return (
    <Aggregate
      aggregation={aggregation}
      currentAnswer={currentAnswer}
      handleAnswerNext={handleAnswerNext}
      handleAnswerPrevious={handleAnswerPrevious}
      setMediaDuration={setMediaDuration}
      t={t}
      mediaDuration={mediaDuration}
      {...props}
    />
  );
};

AggregateContainer.propTypes = {
  aggregation: PropTypes.object.isRequired,
};

export default AggregateContainer;
