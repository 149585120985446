import { gql, useLazyQuery } from '@apollo/client';

import { ORGANIZATION_DATA, SSO_DATA } from 'gql/fragments';

export const QUERY_EMAIL_TO_ORGANIZATION = gql`
  query ($email: String) {
    emailToOrganization(email: $email) {
      ...OrganizationData
      SSOs {
        ...SSOData
      }
    }
  }
  ${ORGANIZATION_DATA}
  ${SSO_DATA}
`;

export function useQueryEmailToOrganization(options) {
  const [fetchQuery, { loading, data, ...props }] = useLazyQuery(
    QUERY_EMAIL_TO_ORGANIZATION,
    options,
  );
  return {
    fetchQuery,
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}
