import * as dayjs from 'dayjs';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import history from 'lib/history';
import Button from 'uikit/Button';

import styles from './TestSessionCard.module.scss';

const cx = classNames.bind(styles);

const TestSessionCard = ({ className = null, testSession, testSessions }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/TestResults/TestSessionCard',
  });

  const handleTestResultView = () => {
    history.push({
      pathname: `/test-review/${testSession.courseId}`,
      state: {
        accountId: testSession.accountId,
        accountIds: testSessions.map((session) => session.accountId),
      },
    });
  };

  const isOngoing = dayjs().isBetween(
    testSession.startTime,
    testSession.stopTime,
  );

  const userName = `${testSession.lastName && testSession.lastName[0]}${
    testSession.firstName && testSession.firstName[0]
  }`.toUpperCase();

  return (
    <div className={cx('container', className)}>
      <div
        aria-hidden="true"
        className={cx('logo')}
        style={{
          backgroundImage: `url("${testSession.picture}")`,
        }}
      >
        {!testSession.picture && userName}
      </div>
      <h3 className={cx('title')}>
        {`${testSession.lastName.toUpperCase()}, ${testSession.firstName}`}
      </h3>
      {isOngoing && <p className={cx('ongoing')}>{t('ongoing')}</p>}
      <Button
        className={cx('action')}
        icon="eye"
        onClick={handleTestResultView}
      >
        {t('view')}
      </Button>
    </div>
  );
};

TestSessionCard.propTypes = {
  className: PropTypes.string,
  testSession: PropTypes.object.isRequired,
  testSessions: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default TestSessionCard;
