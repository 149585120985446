import * as yup from 'yup';
import { useField } from 'formik';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Editor from './Editor';

const EditorContainer = (props) => {
  const { t } = useTranslation();
  const [{ value: answerType }, , { setValue: setAnswerType }] =
    useField('answerType');
  const handleAnswerType = useCallback((type) => {
    setAnswerType(type);
  }, [setAnswerType]);
  return (
    <Editor
      answerType={answerType}
      handleAnswerType={handleAnswerType}
      t={t}
      {...props}
    />
  );
};

EditorContainer.validationSchema = (t) =>
  yup.object().shape({
    tags: yup.array().of(yup.string().trim().lowercase().max(256)).compact(),
    title: yup
      .string()
      .trim()
      .required(t('Questions/Essay/Editor/title-error-required')),
    answerType: yup.string().trim().required().oneOf(['video', 'audio']),
    feedback: yup.string().trim(),
  });

export default EditorContainer;
