import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Feedback from 'questions/Shared/Feedback';
import FormattedText from 'uikit/FormattedText';
import Icon from 'uikit/Icon';

import styles from './Verso.module.scss';

const cx = classNames.bind(styles);

const Verso = ({ className, matches, question, theme }) => (
  <div
    className={cx(
      'container',
      className,
      `theme-${theme}`,
    )}
  >
    <FormattedText className={cx('title')}>{question.title}</FormattedText>
    {matches.map((match, sourceIndex) => {
      const solution = question.solution[sourceIndex];
      const user = matches.find((m) => m.idx === solution?.user);
      const correct = matches.find((m) => m.idx === solution?.correct);
      return (
        // eslint-disable-next-line react/no-array-index-key
        <div className={cx('match')} key={match.idx}>
          <FormattedText className={cx('match-title')}>
            {match.source}
          </FormattedText>
          <div className={cx('answers')}>
            {!solution?.isCorrect && (
              <div className={cx('answer', 'wrong')}>
                <Icon className={cx('icon')} name="times" />
                <FormattedText className={cx('text')}>
                  {user?.destination || '___'}
                </FormattedText>
              </div>
            )}
            <div className={cx('answer', 'correct')}>
              <Icon className={cx('icon')} name="check" />
              <FormattedText className={cx('text')}>
                {correct?.destination || '___'}
              </FormattedText>
            </div>
          </div>
        </div>
      );
    })}
    {question.content.feedback && (
     <Feedback feedback={question.content.feedback} />
    )}
  </div>
);

Verso.propTypes = {
  className: PropTypes.string,
  matches: PropTypes.arrayOf(PropTypes.object).isRequired,
  question: PropTypes.object.isRequired,
  theme: PropTypes.string.isRequired,
};

Verso.defaultProps = {
  className: undefined,
};

export default Verso;
