import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import DesignToolbar from './DesignToolbar';
import IMAGE_PLACEHOLDER_LARGE from '../../../images/imagePlaceholderLarge.svg';

const DesignToolbarContainer = ({ froalaRef, name, ...props }) => {
  const { t } = useTranslation();
  const [, , { setValue }] = useField(name);
  const titleText = `<p class="fr-class-title fr-class-horizontal-center">${t(
    'uikit/InputRich2/data/texts/title',
  )}</p>`;
  const subTitleText = `<p class="fr-class-subtitle fr-class-horizontal-center">${t(
    'uikit/InputRich2/data/texts/subtitle',
  )}</p>`;
  const text = `<p class="fr-class-text">${t(
    'uikit/InputRich2/data/texts/text',
  )}</p>`;
  const setImage = (imageUrl) =>
    `<p><img src=${imageUrl} style="width: 100%; max-height: 400px" class="fr-fil fr-fic fr-dib"></br></p>`;

  const handleTemplateChange = useCallback(
    (index) => {
      const templates = [
        `${titleText}<p class="fr-class-hidden"></p>`,
        `${titleText}</br>${subTitleText}`,
        `${titleText}</br><div class="fr-class-horizontal-center">${text}${text}${text}</div>`,
        `<div class="fr-class-columns"><div class="fr-class-column-50">${titleText}</div><div class="fr-class-column-50 fr-class-horizontal-center">${text}${text}${text}${text}${text}</div></div>`,
        `${titleText}</br><div class="fr-class-columns"><div class="fr-class-column-33 fr-class-horizontal-center">${text}</div><div class="fr-class-column-33 fr-class-horizontal-center">${text}</div><div class="fr-class-column-33 fr-class-horizontal-center">${text}</div></div>`,
        `${titleText}</br><div class="fr-class-columns"><div class="fr-class-column-33 fr-class-horizontal-center"><div class="fr-class-number fr-class-horizontal-center">1</div></br>${text}</div><div class="fr-class-column-33 fr-class-horizontal-center"><div class="fr-class-number fr-class-horizontal-center">2</div></br>${text}</div><div class="fr-class-column-33 fr-class-horizontal-center"><div class="fr-class-number fr-class-horizontal-center">3</div></br>${text}</div></div>`,
        `<div class="fr-class-columns"><div class="fr-class-column-50">${titleText}</div><div class="fr-class-column-50"><div class="fr-class-flex"><div class="fr-class-number fr-class-horizontal-center" style="margin: 10px;">1</div>${text}</div><div class="fr-class-flex"><div class="fr-class-number fr-class-horizontal-center" style="margin: 10px;">2</div>${text}</div><div class="fr-class-flex"><div class="fr-class-number fr-class-horizontal-center" style="margin: 10px;">3</div>${text}</div><div class="fr-class-flex"><div class="fr-class-number fr-class-horizontal-center" style="margin: 10px;">4</div>${text}</div><div class="fr-class-flex"><div class="fr-class-number fr-class-horizontal-center" style="margin: 10px;">5</div>${text}</div></div></div>`,
        `<div class="fr-class-columns"><div class="fr-class-column-50"><div class="fr-class-vertical-center">${titleText}</br><div class="fr-class-horizontal-center">${text}${text}${text}</div></div></div><div class="fr-class-column-50"><div class="fr-class-vertical-center">${setImage(
          IMAGE_PLACEHOLDER_LARGE,
        )}</div></div></div>`,
        `<div class="fr-class-columns"><div class="fr-class-column-50"><div class="fr-class-vertical-center">${setImage(
          IMAGE_PLACEHOLDER_LARGE,
        )}</div></div><div class="fr-class-column-50"><div class="fr-class-vertical-center">${titleText}</br><div class="fr-class-horizontal-center">${text}${text}${text}</div></div></div></div>`,
        `${setImage(IMAGE_PLACEHOLDER_LARGE)}`,
      ];
      const {
        current: { editor },
      } = froalaRef;
      editor?.html.set(templates[index]);
      setValue(templates[index]);
    },
    [froalaRef, setValue, subTitleText, titleText, text],
  );
  const sideBarRef = useRef();
  const sideBarLimitsRef = useRef();
  const handleTemplatesClose = useCallback(() => {
    if (sideBarLimitsRef.current && sideBarRef.current) {
      sideBarLimitsRef.current?.classList.remove(
        'DesignToolbar_limits-large__buB5E',
      );
      sideBarRef.current?.classList.remove('DesignToolbar_open__vcKEG');
    }
  }, [sideBarLimitsRef, sideBarRef]);
  useEffect(() => {
    const sideBarRefCurrent = sideBarRef.current;
    if (sideBarRef && sideBarRef.current) {
      sideBarRefCurrent.addEventListener('mousedown', (event) => {
        event.preventDefault();
      });
      return () => {
        sideBarRefCurrent?.removeEventListener('mousedown', (event) => {
          event.preventDefault();
        });
      };
    }
    return null;
  }, [sideBarRef]);
  return (
    <DesignToolbar
      handleTemplateChange={handleTemplateChange}
      handleTemplatesClose={handleTemplatesClose}
      name={name}
      sideBarLimitsRef={sideBarLimitsRef}
      sideBarRef={sideBarRef}
      t={t}
      {...props}
    />
  );
};

DesignToolbarContainer.propTypes = {
  froalaRef: PropTypes.object,
  name: PropTypes.string.isRequired,
};

DesignToolbarContainer.defaultProps = {
  froalaRef: undefined,
};

export default DesignToolbarContainer;
