import classNames from 'classnames/bind';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import PERMISSIONS from 'constants/permissions';
import { useMutationQuestionImportCourse } from 'gql/mutations/question';
import { useQueryCoursesMe, useQueryCourseContent } from 'gql/queries/course';
import extract from 'helpers/extract';
import { trackEvent, reportError } from 'lib/tracking';
import Button from 'uikit/Button';
import InputQuestionTree from 'uikit/InputQuestionTree';
import InputError from 'uikit/InputError';
import Select from 'uikit/Select';

import styles from './QuestionImportCourse.module.scss';

const cx = classNames.bind(styles);

const QuestionImportCourse = ({ course, handleModalClose }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/Editor/QuestionImport/QuestionImportCourse',
  });

  const mutationQuestionImportCourse = useMutationQuestionImportCourse();

  const [selectedCourseId, setSelectedCourseId] = useState(null);

  const { isLoading: isLoadingCourses, data: dataCourses } =
    useQueryCoursesMe();

  const { isLoading: isLoadingCourse, data: dataCourse } =
    useQueryCourseContent({
      skip: !selectedCourseId,
      variables: {
        courseId: selectedCourseId,
      },
    });

  async function handleQuestionsImport(values, form) {
    try {
      form.setStatus(null);
      const variables = extract(values, validationSchema);
      const {
        data: { questionImportCourse },
      } = await mutationQuestionImportCourse({
        ...variables,
        courseId: course.id,
      });
      handleModalClose(questionImportCourse);
      trackEvent('question', 'import-course');
    } catch (err) {
      form.setStatus('500');
      reportError('question', 'import-course', err);
    }
  }

  const courses = isLoadingCourses
    ? []
    : dataCourses.coursesMe.filter((c) => c.level >= PERMISSIONS.COLLABORATOR);

  const selectedCourse = isLoadingCourse ? null : dataCourse.course;

  const validationSchema = yup.object().shape({
    chapterId: yup
      .string()
      .trim()
      .matches(
        /^|([0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})$/i,
      ),
    selectedCourseId: yup
      .string()
      .trim()
      .required(t('course-error-required'))
      .matches(
        /^|([0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})$/i,
      ),
    questionIds: yup
      .array()
      .of(yup.string().required().trim().uuid())
      .compact()
      .test(
        'length',
        t('questions-error-required'),
        (questions) => questions.length > 0,
      ),
  });

  const chapters = course.chapters;

  return (
    <>
      <Formik
        initialValues={{
          chapterId: '',
          selectedCourseId: '',
          questionIds: [],
        }}
        onSubmit={handleQuestionsImport}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, status, setFieldValue }) => (
          <Form className={cx('subpage')}>
            <div className={cx('subtitle')}>
              <p>{t('subtitle')}</p>
            </div>
            {status === '500' && (
              <p className={cx('error')} role="alert">
                {t('question-import-error')}
              </p>
            )}
            <Select
              className={cx('field')}
              icon="book-open"
              label={t('chapter-label')}
              name="chapterId"
            >
              <option value="">{t('no-chapter')}</option>
              {chapters.map((chapter) => (
                <option key={chapter.id} value={chapter.id}>
                  {chapter.title}
                </option>
              ))}
            </Select>
            <Select
              className={cx('field')}
              icon="book"
              onChange={(evt) => {
                setSelectedCourseId(evt.target.value);
                setFieldValue('selectedCourseId', evt.target.value);
              }}
              name="selectedCourseId"
              label={t('from-course-label')}
            >
              <option value="">{t('no-course')}</option>
              {courses.map((course) => (
                <option key={course.id} value={course.id}>
                  {course.title}
                </option>
              ))}
            </Select>
            {selectedCourse && (
              <>
                <InputQuestionTree
                  className={cx('field')}
                  shouldSelectAll
                  course={selectedCourse}
                />
                <InputError name="questionIds" />
              </>
            )}
            <div className={cx('actions')}>
              <div />
              <Button
                className={cx('action')}
                intent="primary"
                isLoading={isSubmitting}
                size="large"
                type="submit"
              >
                {t('confirm-import-questions')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

QuestionImportCourse.propTypes = {
  course: PropTypes.object.isRequired,
  handleModalClose: PropTypes.func.isRequired,
};

export default QuestionImportCourse;
