import froalaeditor from 'froala-editor';

froalaeditor.POPUP_TEMPLATES['symbolsPlugin.popup'] = '[_CUSTOM_LAYER_]';

froalaeditor.PLUGINS.symbolsPlugin = (editor) => {
  function initPopup() {
    const render = (str) =>
      window.katex
        ? window.katex.renderToString(str, {
            throwOnError: false,
          })
        : str;
    const template = {
      custom_layer: `
        <div class="fr-popup fr-active">
          <div class="fr-icon-container fr-sc-container">
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addSymbols" data-param1="a^(b)">${render(
              'a^{b}',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addSymbols" data-param1="a_(b)">${render(
              'a_{b}',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addSymbols" data-param1="sqrt(x)">${render(
              '\\sqrt{x}',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addSymbols" data-param1="root(a)(x)">${render(
              '\\sqrt[a]{x}',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addSymbols" data-param1="∫">${render(
              '∫',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addSymbols" data-param1="∫_(a)^(b)">${render(
              '∫_{a}^{b}',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addSymbols" data-param1="∮">${render(
              '∮',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addSymbols" data-param1="∮_(a)^(b)">${render(
              '∮_{a}^{b}',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addSymbols" data-param1="∑">${render(
              '∑',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addSymbols" data-param1="∑_(a)^(b)">${render(
              '∑_{a}^{b}',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addSymbols" data-param1="∏">${render(
              '∏',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addSymbols" data-param1="∏_(a)^(b)">${render(
              '∏_{a}^{b}',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addSymbols" data-param1="∂">${render(
              '∂',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addSymbols" data-param1="∇">${render(
              '∇',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addSymbols" data-param1="∞">${render(
              '∞',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addSymbols" data-param1="vec(x)">${render(
              '\\vec{x}',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addSymbols" data-param1="|x|">${render(
              '|x|',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addSymbols" data-param1="|| vec(x) ||">${render(
              '\\lVert \\vec{x} \\rVert',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addSymbols" data-param1="ZZ">${render(
              '\\mathbb{Z}',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addSymbols" data-param1="NN">${render(
              '\\mathbb{N}',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addSymbols" data-param1="QQ">${render(
              '\\mathbb{Q}',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addSymbols" data-param1="RR">${render(
              '\\mathbb{R}',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addSymbols" data-param1="CC">${render(
              '\\mathbb{C}',
            )}</span>
          </div>
        </div>`,
    };
    return editor.popups.create('symbolsPlugin.popup', template);
  }
  function showPopup() {
    let $popup = editor.popups.get('symbolsPlugin.popup');
    if (!$popup) {
      $popup = initPopup();
    }
    editor.popups.setContainer('symbolsPlugin.popup', editor.$tb);
    const $btn = editor.$tb.find('.fr-command[data-cmd="insertSymbols"]');
    const { left } = $btn.offset();
    const top =
      $btn.offset().top +
      (editor.opts.toolbarBottom ? 10 : $btn.outerHeight() - 10);
    editor.popups.show('symbolsPlugin.popup', left, top, $btn.outerHeight());
  }
  function hidePopup() {
    editor.popups.hide('symbolsPlugin.popup');
  }
  return {
    showPopup,
    hidePopup,
  };
};

froalaeditor.DefineIcon('symbolsIcon', {
  FA5NAME: 'integral',
  template: 'font_awesome_5',
});

froalaeditor.RegisterCommand('insertSymbols', {
  title: 'Symboles',
  icon: 'symbolsIcon',
  showOnMobile: true,
  undo: false,
  focus: false,
  callback: function callback() {
    this.symbolsPlugin.showPopup();
  },
});

froalaeditor.RegisterCommand('addSymbols', {
  undo: true,
  focus: true,
  callback: function callback(cmd, val) {
    this.html.insert(` ${val} `);
    this.symbolsPlugin.hidePopup();
  },
});
