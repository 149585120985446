import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'uikit/Button';
import Icon from 'uikit/Icon';
import InputRich from 'uikit/InputRich';
import Tooltip from 'uikit/Tooltip';

import styles from './FeedbackWithIA.module.scss';

const cx = classNames.bind(styles);

const FeedbackWithIA = ({
  className,
  errorType,
  handleFeedbackGenerate,
  isLoading,
  t,
}) => (
  <div className={cx('container', className)}>
    <div className={cx('header')}>
      <div className={cx('title')}>
        <div className={cx('label')}>
          {t('Questions/Shared/FeedbackWithIA/feedback-label')}
        </div>
        <Tooltip tooltip={t('Questions/Shared/FeedbackWithIA/feedback-helper')}>
          <Icon name="info-circle" />
        </Tooltip>
      </div>
      <Tooltip tooltip={t('Questions/Shared/FeedbackWithIA/from-ia-tooltip')}>
        <div className={cx('ia-button-container')}>
          <Button
            className={cx('ia-button')}
            isDisabled={isLoading}
            icon="wand-sparkles"
            intent="ia-light"
            onClick={handleFeedbackGenerate}
          >
            <span className={cx('ia-button-text-large')}>
              {t('Questions/Shared/FeedbackWithIA/generate-feedback')}
            </span>
            <span className={cx('ia-button-text-small')}>
              {t('Questions/Shared/FeedbackWithIA/generate-feedback-small')}
            </span>
          </Button>
        </div>
      </Tooltip>
    </div>
    {errorType && (
      <p className={cx('error-message')}>
        {t(`Questions/Shared/FeedbackWithIA/error-message-${errorType}`)}
      </p>
    )}
    {isLoading ? (
      <p className={cx('feedback-placeholder')}>
        {t('Questions/Shared/FeedbackWithIA/feedback-loading')}
      </p>
    ) : (
      <InputRich name="feedback" />
    )}
  </div>
);

FeedbackWithIA.propTypes = {
  className: PropTypes.string,
  errorType: PropTypes.string,
  handleFeedbackGenerate: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

FeedbackWithIA.defaultProps = {
  className: undefined,
  errorType: null,
};

export default FeedbackWithIA;
