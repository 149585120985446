import classNames from 'classnames/bind';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import FormattedText from 'uikit/FormattedText';

import QuestionText from './QuestionText/QuestionText';
import styles from './Recto.module.scss';

const cx = classNames.bind(styles);

const Recto = ({ className = null, isDisabled, question }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Questions/FillInTheBlanks/Recto',
  });

  const [{ value: answer }, , { setValue: setAnswer }] = useField('answer');

  // If the question has already been answered during this study session
  // set the correct answers from the previous try in the corresponding blanks
  useEffect(() => {
    const updatedAnswer = answer.map((answerItem, index) => {
      const itemSolution = question.solution ? question.solution[index] : null;
      if (itemSolution && itemSolution.isCorrect) {
        return itemSolution.user;
      }
      return answerItem;
    });
    setAnswer(updatedAnswer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle Enter key press
  useEffect(() => {
    const handleEnter = (event) => {
      const isTargetInput = event.target.tagName === 'INPUT';
      const isTargetSelect = event.target.id.split('-').shift() === 'blank';
      const isKeyEnter = event.key === 'Enter';
      const isKeyInteger = Number.isInteger(Number(event.key));
      // If the target is an input and the key pressed is Enter
      // prevent the validation of the question
      if (isTargetInput && isKeyEnter) {
        event.preventDefault();
      }
      // If the target is a select and the key pressed is a number
      // simulate a click on the corresponding choice button
      if (isTargetSelect && isKeyInteger) {
        const blankIndex = event.target.id.split('-').pop();
        const choiceButton = document.getElementById(
          `choice-${blankIndex}-${event.key - 1}`,
        );
        if (choiceButton) {
          event.preventDefault();
          choiceButton.click();
        }
      }
    };

    window.addEventListener('keydown', (event) => handleEnter(event));
    return () => {
      window.removeEventListener('keydown', handleEnter);
    };
  }, []);

  return (
    <div className={cx('container', className)}>
      <FormattedText as="h1" className={cx('title')}>
        {question.title}
      </FormattedText>
      {!isDisabled && (
        <div className={cx('instructions')}>{t('instructions')}</div>
      )}
      <QuestionText isDisabled={isDisabled} question={question} />
    </div>
  );
};

Recto.propTypes = {
  className: PropTypes.string,
  isDisabled: PropTypes.bool.isRequired,
  question: PropTypes.object.isRequired,
};

Recto.defaultAnswer = (question) =>
  (question.content.text.match(/\[{2}.*?\]*\]{2}|\{{2}.*?\}*\}{2}/g) || []).map(
    () => '',
  );

Recto.validationSchema = () =>
  yup.object().shape({
    answer: yup.array().of(yup.string().trim()),
  });

export default Recto;
