import classNames from 'classnames/bind';
import * as dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import LoadingScreen from 'components/Shared/LoadingScreen';
import Button from 'uikit/Button';
import FormattedText from 'uikit/FormattedText';
import Icon from 'uikit/Icon';
import Modal from 'uikit/Modal';

import ArtImage from 'images/categories/art.svg';
import ComputerScienceImage from 'images/categories/computer-science.svg';
import HealthImage from 'images/categories/health.svg';
import LanguageImage from 'images/categories/language.svg';
import ManagementImage from 'images/categories/management.svg';
import MathematicsImage from 'images/categories/mathematics.svg';
import OtherImage from 'images/categories/other.svg';
import ScienceImage from 'images/categories/science.svg';
import styles from './ExamStart.module.scss';

const cx = classNames.bind(styles);

const IMAGES = {
  art: ArtImage,
  'computer-science': ComputerScienceImage,
  health: HealthImage,
  language: LanguageImage,
  management: ManagementImage,
  mathematics: MathematicsImage,
  other: OtherImage,
  science: ScienceImage,
};

const ExamStart = ({
  className,
  course,
  handleModalClose,
  handleModalOpen,
  isLoading,
  isModalOpen,
  isTeacher,
  t,
  testStatus,
  ...props
}) => (
  <>
    <button
      className={cx('button', className, {
        isTeacher,
      })}
      onClick={handleModalOpen}
      type="button"
      {...props}
    >
      {isTeacher
        ? t('Course/Shared/ExamStart/exam-try')
        : t('Course/Shared/ExamStart/exam-start')}
      {!isTeacher && <Icon className={cx('chevron')} name="chevron-right" />}
    </button>
    <Modal isOpen={isModalOpen} onExit={handleModalClose}>
      <div className={cx('modal')}>
        <Button
          aria-label={t('Course/Shared/ExamStart/aria-close')}
          className={cx('close')}
          icon="times"
          onClick={handleModalClose}
        />
        <h1 className={cx('title', 'centered-title')}>{course.title}</h1>
        <div
          className={cx('image-container', `theme-${course.themeBackground}`)}
        >
          <div
            className={cx('illustration')}
            style={{
              backgroundImage: `url(${
                IMAGES[course.category] || IMAGES.other
              })`,
            }}
          />
        </div>
        {isLoading ? (
          <LoadingScreen />
        ) : (
          <>
            {testStatus.status === 408 ? (
              <p className={cx('subtitle')}>
                {t('Course/Shared/ExamStart/session-expired')}
              </p>
            ) : testStatus.status === 202 ? (
              <>
                <p className={cx('subtitle')}>
                  {t('Course/Shared/ExamStart/session-resume')}
                </p>
                <Button
                  as={Link}
                  className={cx('action')}
                  intent="primary"
                  size="large"
                  to={`/test/${course.id}`}
                >
                  {t('Course/Shared/ExamStart/resume')}
                </Button>
              </>
            ) : testStatus.status === 403 ? (
              <>
                <p className={cx('subtitle')}>
                  {t('Course/Shared/ExamStart/session-not-available')}
                </p>
                <p className={cx('info')}>
                  {t('Course/Shared/ExamStart/start-time')}
                </p>
                <p>{dayjs(course.examStartTime).format('LLLL')}</p>
                <p className={cx('info')}>
                  {t('Course/Shared/ExamStart/stop-time')}
                </p>
                <p>{dayjs(course.examStopTime).format('LLLL')}</p>
                {course.examDurationMinutes > 0 && (
                  <>
                    <p className={cx('info')}>
                      {t('Course/Shared/ExamStart/duration')}
                    </p>
                    <p>{`${course.examDurationMinutes} minutes`}</p>
                  </>
                )}
                {course.examInstructions && (
                  <>
                    <p className={cx('info')}>
                      {t('Course/Shared/ExamStart/instructions')}
                    </p>
                    <FormattedText>{course.examInstructions}</FormattedText>
                  </>
                )}
              </>
            ) : testStatus.status === 201 ? (
              <>
                <p className={cx('subtitle')}>
                  {t('Course/Shared/ExamStart/session-start')}
                </p>
                <p className={cx('info')}>
                  {t('Course/Shared/ExamStart/start-time')}
                </p>
                <p>{dayjs(course.examStartTime).format('LLLL')}</p>
                <p className={cx('info')}>
                  {t('Course/Shared/ExamStart/stop-time')}
                </p>
                <p>{dayjs(course.examStopTime).format('LLLL')}</p>
                {course.examDurationMinutes > 0 && (
                  <>
                    <p className={cx('info')}>
                      {t('Course/Shared/ExamStart/duration')}
                    </p>
                    <p>{`${course.examDurationMinutes} minutes`}</p>
                  </>
                )}
                {course.examInstructions && (
                  <>
                    <p className={cx('info')}>
                      {t('Course/Shared/ExamStart/instructions')}
                    </p>
                    <FormattedText>{course.examInstructions}</FormattedText>
                  </>
                )}
                <Button
                  as={Link}
                  className={cx('action')}
                  intent="primary"
                  size="large"
                  to={`/test/${course.id}`}
                >
                  {t('Course/Shared/ExamStart/start')}
                </Button>
              </>
            ) : testStatus.status === 203 ? (
              <>
                <p className={cx('subtitle')}>
                  {t('Course/Shared/ExamStart/special-access')}
                </p>
                <div className={cx('actions')}>
                  <Button
                    onClick={handleModalClose}
                    size="large"
                  >
                    {t('Course/Shared/ExamStart/back')}
                  </Button>
                  <Button
                    as={Link}
                    intent="primary"
                    size="large"
                    to={`/test/${course.id}`}
                  >
                    {t('Course/Shared/ExamStart/start')}
                  </Button>
                </div>
              </>
            ) : (
              testStatus.status && (
                <p className={cx('subtitle')}>{t('Shared/ExamStart/error')}</p>
              )
            )}
          </>
        )}
      </div>
    </Modal>
  </>
);

ExamStart.propTypes = {
  className: PropTypes.string,
  course: PropTypes.object.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  handleModalOpen: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  isTeacher: PropTypes.bool,
  t: PropTypes.func.isRequired,
  testStatus: PropTypes.object,
};

ExamStart.defaultProps = {
  className: undefined,
  testStatus: undefined,
  isTeacher: false,
};

export default ExamStart;
