import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import Feedback from 'questions/Shared/Feedback';
import CleanedText from 'uikit/CleanedText';
import FormattedText from 'uikit/FormattedText';
import Icon from 'uikit/Icon';

import styles from './Verso.module.scss';
import text2React from '../text2React';

const cx = classNames.bind(styles);

const Verso = ({ className, question, t, theme }) => {
  const questionText = useMemo(() => {
    let i = 0;
    return text2React(question.content.text, {
      // eslint-disable-next-line consistent-return
      replace: ({ name }) => {
        if (name === 'input' || name === 'select') {
          i += 1;
          const solution = question.solution[i - 1];
          return (
            <span key={i}>
              {!solution?.isCorrect && (
                <>
                  <strong
                    aria-describedby="wrong-description"
                    className={cx('answer', 'wrong')}
                  >
                    {solution?.user || '___'}
                  </strong>
                  <Icon className={cx('chevron')} name="chevron-right" />
                </>
              )}
              <strong
                aria-describedby="correct-description"
                className={cx('answer', 'correct')}
              >
                <CleanedText>
                  {solution?.exact || solution?.correct.join(', ') || '___'}
                </CleanedText>
              </strong>
            </span>
          );
        }
      },
    });
  }, [question]);
  return (
    <div className={cx('container', className, `theme-${theme}`)}>
      <FormattedText className={cx('title')}>{question.title}</FormattedText>
      <FormattedText className={cx('text')} isAlreadyFormatted>
        {questionText}
      </FormattedText>
      {question.content.feedback && (
        <Feedback feedback={question.content.feedback} />
      )}
      <p id="wrong-description" className={cx('aria-label')}>
        {t('Questions/FillInTheBlanks/Verso/aria-wrong-answer')}
      </p>
      <p id="correct-description" className={cx('aria-label')}>
        {t('Questions/FillInTheBlanks/Verso/aria-correct-answer')}
      </p>
    </div>
  );
};

Verso.propTypes = {
  className: PropTypes.string,
  question: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
};

Verso.defaultProps = {
  className: undefined,
};

export default Verso;
