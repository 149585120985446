import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { getDimensions } from 'questions/Shared/utils/images';
import ImageContainer from './ImageContainer';

const ImageContainerContainer = ({ legends, ...props }) => {
  const wrapperRef = useRef(null);
  const imageRef = useRef(null);
  const [image, setImage] = useState({
    width: null,
    height: null,
    diagonal: null,
  });
  const handleImageLoaded = useCallback(() => {
    const [width, height, diagonal] = getDimensions(wrapperRef, imageRef);
    setImage({
      width,
      height,
      diagonal,
    });
  }, [imageRef, wrapperRef]);
  const handleScreenResize = useCallback(() => {
    if (wrapperRef && imageRef) {
      handleImageLoaded();
    }
  }, [handleImageLoaded, imageRef, wrapperRef]);
  useEffect(() => {
    window.addEventListener('resize', handleScreenResize);
    return () => {
      window.removeEventListener('resize', handleScreenResize);
    };
  }, [handleScreenResize]);
  return (
    <ImageContainer
      handleImageLoaded={handleImageLoaded}
      image={image}
      imageRef={imageRef}
      legends={legends}
      wrapperRef={wrapperRef}
      {...props}
    />
  );
};

ImageContainerContainer.propTypes = {
  legends: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ImageContainerContainer;
