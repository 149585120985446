import { useField } from 'formik';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import Context from 'lib/context';

import Recto from './Recto';

const RectoContainer = (props) => {
  const { t } = useTranslation();
  const { theme } = useContext(Context);
  const [{ value: answer }, , { setValue: setAnswer }] = useField('answer');
  return (
    <Recto
      answer={answer}
      handleChoiceSelect={setAnswer}
      t={t}
      theme={theme}
      {...props}
    />
  );
};

RectoContainer.defaultAnswer = () => undefined;

RectoContainer.validationSchema = (t) =>
  yup.object().shape({
    answer: yup
      .number()
      .required(t('Questions/SCT/Recto/answer-error'))
      .min(0, t('Questions/SCT/Recto/answer-error'))
      .max(4, t('Questions/SCT/Recto/answer-error')),
  });

export default RectoContainer;
