import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Options from 'questions/Shared/Options';
import Tags from 'questions/Shared/Tags';
import Icon from 'uikit/Icon';
import InputRich from 'uikit/InputRich';
import InputTextArray from 'uikit/InputTextArray';
import Switch from 'uikit/Switch';
import Tooltip from 'uikit/Tooltip';

import styles from './Editor.module.scss';

const cx = classNames.bind(styles);

const Editor = ({ className, t }) => (
  <div className={cx('container', className)}>
    <InputTextArray
      className={cx('field')}
      data-testid="answers"
      label={t('Questions/ShortAnswer/Editor/answers-label')}
      name="answers"
      options={[]}
      placeholder={t('Questions/ShortAnswer/Editor/answers-placeholder')}
    />
    <InputRich
      helper={
        <Tooltip tooltip={t('Questions/ShortAnswer/Editor/feedback-helper')}>
          <Icon name="info-circle" />
        </Tooltip>
      }
      label={t('Questions/ShortAnswer/feedback-label')}
      name="feedback"
      placeholder={t('Questions/ShortAnswer/Editor/feedback-placeholder')}
    />
    <Options className={cx('options')}>
      <Switch
        className={cx('field')}
        label={t('Questions/ShortAnswer/Editor/ignore-case-label')}
        name="ignoreCase"
      />
      <Switch
        className={cx('field')}
        label={t('Questions/ShortAnswer/Editor/ignore-accents-label')}
        name="ignoreAccents"
      />
      <Switch
        className={cx('field')}
        label={t('Questions/ShortAnswer/Editor/ignore-punctuation-label')}
        name="ignorePunctuation"
      />
      <Tags />
    </Options>
  </div>
);

Editor.propTypes = {
  className: PropTypes.string,
  t: PropTypes.func.isRequired,
};

Editor.defaultProps = {
  className: undefined,
};

export default Editor;
