import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  getDimensions,
  getDenormalizedPinPosition,
  getNormalizedPinPosition,
  getDenormalizedZonePosition,
  getNormalizedZonePosition,
  getEventCursorPosition,
} from 'questions/Shared/utils/images';
import ImageContainer from './ImageContainer';

const ZONE_MIN_SIZE = 25;

const ImageContainerContainer = ({
  handleLegendsSet,
  handleZonesSet,
  legends,
  zones,
  ...props
}) => {
  const wrapperRef = useRef(null);
  const imageRef = useRef(null);
  const [image, setImage] = useState({
    width: null,
    height: null,
    diagonal: null,
  });
  const handleImageLoaded = useCallback(() => {
    const [width, height, diagonal] = getDimensions(wrapperRef, imageRef);
    setImage({
      width,
      height,
      diagonal,
    });
  }, [imageRef, wrapperRef]);
  const handleScreenResize = useCallback(() => {
    if (wrapperRef && imageRef) {
      handleImageLoaded();
    }
  }, [handleImageLoaded, imageRef, wrapperRef]);
  const [activeElementRef, setActiveElementRef] = useState(null);
  const [data, setData] = useState({
    mouse: {
      positionX: null,
      positionY: null,
      fingerId: null,
    },
    draggingMode: null,
    updatedElementRef: null,
    updatedElement: null,
    basePosition: {
      baseLeft: undefined,
      baseTop: undefined,
      baseHeight: undefined,
      baseWidth: undefined,
    },
  });
  const handleZoneDelete = useCallback(
    (index) => {
      const filteredZones = zones.filter(
        (item, itemIndex) => itemIndex !== index,
      );
      handleZonesSet(filteredZones);
      setActiveElementRef(null);
      setData({
        mouse: {
          positionX: null,
          positionY: null,
          fingerId: null,
        },
        draggingMode: null,
        updatedElementRef: null,
        updatedElement: null,
        basePosition: {
          baseLeft: undefined,
          baseTop: undefined,
          baseHeight: undefined,
          baseWidth: undefined,
        },
      });
    },
    [handleZonesSet, zones],
  );
  const handleElementUpdate = useCallback(
    (ref, updatedData) => {
      if (ref.type === 'zone') {
        const normalizedData = getNormalizedZonePosition(
          updatedData,
          image.diagonal,
          zones[ref.index].shapeType,
        );
        const updatedZones = [...zones];
        updatedZones[ref.index] = {
          ...updatedZones[ref.index],
          ...normalizedData,
        };
        handleZonesSet(updatedZones);
      } else {
        const normalizedData = getNormalizedPinPosition(
          updatedData,
          image.diagonal,
        );
        const updatedLegends = [...legends];
        updatedLegends[ref.index] = {
          ...updatedLegends[ref.index],
          ...normalizedData,
        };
        handleLegendsSet(updatedLegends);
      }
    },
    [image, legends, handleLegendsSet, handleZonesSet, zones],
  );
  const handleMouseDown = useCallback(
    (event, index, dragMode, type) => {
      event.stopPropagation();
      const [positionX, positionY, fingerId] = getEventCursorPosition(event);
      const elementPosition =
        type === 'zone'
          ? getDenormalizedZonePosition(zones[index], image.diagonal)
          : type === 'legend'
          ? getDenormalizedPinPosition(legends[index], image.diagonal)
          : imageRef.current.getBoundingClientRect();
      setActiveElementRef({
        type,
        index,
      });
      setData({
        mouse: {
          positionX,
          positionY,
          fingerId,
        },
        draggingMode: dragMode,
        updatedElementRef: {
          type,
          index,
        },
        updatedElement:
          type === 'zone'
            ? zones[index]
            : type === 'legend'
            ? legends[index]
            : null,
        basePosition: {
          baseLeft: elementPosition.left,
          baseTop: elementPosition.top,
          baseHeight: elementPosition.height,
          baseWidth: elementPosition.width,
        },
      });
    },
    [image, imageRef, legends, zones],
  );
  const handleMouseUp = useCallback(
    (event) => {
      const {
        mouse: { positionX, positionY, fingerId },
        draggingMode,
        basePosition,
      } = data;
      const [clientX, clientY] = getEventCursorPosition(event, fingerId);
      const distance = Math.sqrt(
        (clientX - positionX) ** 2 + (clientY - positionY) ** 2,
      );
      if (draggingMode === 'create' && distance < 10) {
        const position = getNormalizedPinPosition(
          {
            left: clientX - basePosition.baseLeft,
            top: clientY - basePosition.baseTop,
          },
          image.diagonal,
        );
        const newLegend = {
          x: position.x,
          y: position.y,
          content: [],
        };
        handleLegendsSet([...legends, newLegend]);
      }
      setData({
        mouse: {
          positionX: null,
          positionY: null,
          fingerId: null,
        },
        draggingMode: null,
        updatedElementRef: null,
        updatedElement: null,
        basePosition: {
          baseLeft: undefined,
          baseTop: undefined,
          baseHeight: undefined,
          baseWidth: undefined,
        },
      });
    },
    [data, image, legends, handleLegendsSet],
  );
  const handleMouseMove = useCallback(
    (event) => {
      const {
        mouse: { positionX, positionY, fingerId },
        draggingMode,
        updatedElementRef,
        updatedElement,
        basePosition,
      } = data;
      const [clientX, clientY] = getEventCursorPosition(event, fingerId);
      if (draggingMode === 'create') {
        const distance = Math.sqrt(
          (clientX - positionX) ** 2 + (clientY - positionY) ** 2,
        );
        if (distance < 10) {
          return;
        }
        const startPosition = getNormalizedPinPosition(
          {
            left: positionX - basePosition.baseLeft,
            top: positionY - basePosition.baseTop,
          },
          image.diagonal,
        );
        const endPosition = getNormalizedPinPosition(
          {
            left: clientX - basePosition.baseLeft,
            top: clientY - basePosition.baseTop,
          },
          image.diagonal,
        );
        const newZone = {
          h: Math.abs(endPosition.y - startPosition.y),
          r: null,
          w: Math.abs(endPosition.x - startPosition.x),
          x: Math.min(startPosition.x, endPosition.x),
          y: Math.min(startPosition.y, endPosition.y),
          shapeType: 'rectangle',
        };
        if (newZone.h < 0.01 || newZone.w < 0.01) {
          return;
        }
        const zonePosition = getDenormalizedZonePosition(
          newZone,
          image.diagonal,
        );
        setActiveElementRef({
          type: 'zone',
          index: zones.length,
        });
        setData((d) => ({
          ...d,
          draggingMode: 'resize',
          updatedElementRef: {
            type: 'zone',
            index: zones.length,
          },
          updatedElement: newZone,
          basePosition: {
            baseLeft: zonePosition.left,
            baseTop: zonePosition.top,
            baseHeight: zonePosition.height,
            baseWidth: zonePosition.width,
          },
        }));
        handleZonesSet([...zones, newZone]);
        return;
      }
      if (!draggingMode || !updatedElement) {
        return;
      }
      const elementPosition =
        updatedElementRef.type === 'zone'
          ? getDenormalizedZonePosition(updatedElement, image.diagonal)
          : getDenormalizedPinPosition(updatedElement, image.diagonal);
      const offsetX = clientX - positionX;
      const offsetY = positionY - clientY;
      const elementWidth = basePosition.baseWidth || 0;
      const elementHeight = basePosition.baseHeight || 0;
      const maxWidth = wrapperRef.current.offsetWidth;
      const maxHeight = wrapperRef.current.offsetHeight;
      if (draggingMode === 'position') {
        let newLeftPosition = basePosition.baseLeft + offsetX;
        let newTopPosition = basePosition.baseTop - offsetY;
        if (newLeftPosition <= 0 - elementWidth / 2) {
          newLeftPosition = 0 - elementWidth / 2;
        } else if (newLeftPosition >= maxWidth - elementWidth / 2) {
          newLeftPosition = maxWidth - elementWidth / 2;
        }
        if (newTopPosition <= 0 - elementHeight / 2) {
          newTopPosition = 0 - elementHeight / 2;
        } else if (newTopPosition >= maxHeight - elementHeight / 2) {
          newTopPosition = maxHeight - elementHeight / 2;
        }
        handleElementUpdate(updatedElementRef, {
          width: elementPosition.width,
          height: elementPosition.height,
          left: newLeftPosition,
          top: newTopPosition,
        });
      } else if (draggingMode === 'resize') {
        const dimensions = {
          left: elementPosition.left,
          top: elementPosition.top,
        };
        let newWidth;
        let newHeight;
        newWidth = basePosition.baseWidth + offsetX;
        newHeight = basePosition.baseHeight - offsetY;
        if (newWidth >= maxWidth - elementPosition.left) {
          newWidth = maxWidth - elementPosition.left;
        } else if (newWidth < ZONE_MIN_SIZE) {
          newWidth = ZONE_MIN_SIZE;
        }
        if (newHeight >= maxHeight - elementPosition.top) {
          newHeight = maxHeight - elementPosition.top;
        } else if (newHeight < ZONE_MIN_SIZE) {
          newHeight = ZONE_MIN_SIZE;
        }
        dimensions.width = newWidth;
        dimensions.height = newHeight;
        handleElementUpdate(updatedElementRef, dimensions);
      }
    },
    [data, image, handleElementUpdate, handleZonesSet, zones],
  );
  useEffect(() => {
    window.addEventListener('resize', handleScreenResize);
    return () => {
      window.removeEventListener('resize', handleScreenResize);
    };
  }, [handleScreenResize]);
  useEffect(() => {
    window.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('touchend', handleMouseUp);
    return () => {
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('touchend', handleMouseUp);
    };
  }, [handleMouseUp]);
  return (
    <ImageContainer
      activeElementRef={activeElementRef}
      handleImageLoaded={handleImageLoaded}
      handleMouseDown={handleMouseDown}
      handleMouseMove={handleMouseMove}
      handleMouseUp={handleMouseUp}
      handleZoneDelete={handleZoneDelete}
      image={image}
      imageRef={imageRef}
      legends={legends}
      wrapperRef={wrapperRef}
      zones={zones}
      {...props}
    />
  );
};

ImageContainerContainer.propTypes = {
  handleLegendsSet: PropTypes.func.isRequired,
  handleZonesSet: PropTypes.func.isRequired,
  legends: PropTypes.arrayOf(PropTypes.object).isRequired,
  zones: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ImageContainerContainer;
