import classNames from 'classnames/bind';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import LoadingScreen from 'components/Shared/LoadingScreen';
import Button from 'uikit/Button';
import Switch from 'uikit/Switch';

import CourseResetParticipants from './CourseResetParticipants';
import Empty from './Empty';
import Finder from './Finder';
import FinderGAR from './FinderGAR';
import GroupDelete from './GroupDelete';
import GroupEdit from './GroupEdit';
import GroupShare from './GroupShare';
import StudentDelete from './StudentDelete';

import styles from './Students.module.scss';

const cx = classNames.bind(styles);

const Students = ({
  className,
  course,
  handleStudentCollaborationToggle,
  handleGroupCreate,
  isEmpty,
  isGAR,
  isLoading,
  nStudents,
  t,
}) =>
  isLoading ? (
    <LoadingScreen />
  ) : (
    <>
      <div className={cx('container', className)}>
        <div className={cx('header')}>
          <h3 className={cx('title')}>
            {t('Course/Teacher/Students/title')}
            <span className={cx('count')}>{nStudents}</span>
          </h3>
          <div className={cx('actions')}>
            <Formik
              enableReinitialize
              initialValues={{
                isStudentCollaborationEnabled:
                  course.isStudentCollaborationEnabled,
              }}
              onSubmit={() => null}
            >
              {() => (
                <Form>
                  <Switch
                    className={cx('switch')}
                    label={t('Course/Teacher/Students/student-collaboration')}
                    name="isStudentCollaborationEnabled"
                    onChange={handleStudentCollaborationToggle}
                  />
                </Form>
              )}
            </Formik>
            <div className={cx('actions-right')}>
              {!isGAR && (
                <Button icon="plus" intent="white" onClick={handleGroupCreate}>
                  {t('Course/Teacher/Students/create-group')}
                </Button>
              )}
              <CourseResetParticipants course={course} />
            </div>
          </div>
        </div>
        {isGAR ? (
          <FinderGAR course={course} />
        ) : isEmpty ? (
          <Empty />
        ) : (
          <Finder course={course} />
        )}
      </div>
      {!isGAR && (
        <>
          <GroupDelete />
          <GroupEdit />
          <GroupShare course={course} />
        </>
      )}
      <StudentDelete />
    </>
  );

Students.propTypes = {
  className: PropTypes.string,
  course: PropTypes.object,
  handleStudentCollaborationToggle: PropTypes.func.isRequired,
  handleGroupCreate: PropTypes.func.isRequired,
  isEmpty: PropTypes.bool.isRequired,
  isGAR: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  nStudents: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

Students.defaultProps = {
  className: undefined,
  course: undefined,
};

export default Students;
