import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './LegendPin.module.scss';

const cx = classNames.bind(styles);

const LegendPin = ({ index, isCorrect, isReport, positionData }) => (
  <div
    className={cx('legend-pin', {
      isCorrect,
      isReport,
    })}
    style={{ left: `${positionData.left}px`, top: `${positionData.top}px` }}
  >
    {index + 1}
  </div>
);

LegendPin.propTypes = {
  index: PropTypes.number.isRequired,
  isCorrect: PropTypes.bool,
  isReport: PropTypes.bool.isRequired,
  positionData: PropTypes.object.isRequired,
};

LegendPin.defaultProps = {
  isCorrect: undefined,
};

export default LegendPin;
