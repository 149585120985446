import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import storeJS from 'store';

import { saveHistory } from '../config/history';
import changeTemplate from '../helpers/changeTemplate';
import changeTheme from '../helpers/changeTheme';
import DesignToolbar from './DesignToolbar';

const DesignToolbarContainer = ({ fabricRef, ...props }) => {
  const { t } = useTranslation();
  const selectedThemeIndex = storeJS.get('slide')?.themeIndex;
  const handleThemeChange = useCallback(
    (index) => {
      saveHistory(fabricRef);
      changeTheme(fabricRef, index);
      fabricRef.current.renderAll();
      storeJS.set('slide', {
        ...storeJS.get('slide'),
        themeIndex: index,
        backgroundColor: undefined,
      });
    },
    [fabricRef],
  );
  const [{ value: currentSlideContent }] = useField('slideContent');
  const handleTemplateChange = useCallback(
    (templateIndex) => {
      saveHistory(fabricRef);
      changeTemplate({
        fabricRef,
        t,
        templateIndex,
        currentSlide: JSON.parse(currentSlideContent).objects,
      });
      fabricRef.current.renderAll();
    },
    [currentSlideContent, fabricRef, t],
  );
  return (
    <DesignToolbar
      handleTemplateChange={handleTemplateChange}
      handleThemeChange={handleThemeChange}
      selectedThemeIndex={selectedThemeIndex}
      t={t}
      {...props}
    />
  );
};

DesignToolbarContainer.propTypes = {
  fabricRef: PropTypes.object,
};

DesignToolbarContainer.defaultProps = {
  fabricRef: undefined,
};

export default DesignToolbarContainer;
