import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import focusModalTitle from 'helpers/focusModalTitle';

import Modal from './Modal';

const ModalContainer = ({ isOpen, ...props }) => {
  useEffect(() => {
    if (isOpen) {
      setTimeout(() => focusModalTitle(), 500);
    }
  }, [isOpen]);
  return <Modal {...props} isOpen={isOpen} />;
};

ModalContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};

export default ModalContainer;
