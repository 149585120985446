import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './LegendPin.module.scss';

const cx = classNames.bind(styles);

const LegendPin = ({ index, isCorrect, positionData, theme }) => (
  <div
    className={cx('legend-pin', `theme-${theme}`, { isCorrect })}
    style={{ left: `${positionData.left}px`, top: `${positionData.top}px` }}
  >
    {index + 1}
  </div>
);
LegendPin.propTypes = {
  index: PropTypes.number.isRequired,
  isCorrect: PropTypes.bool.isRequired,
  positionData: PropTypes.object.isRequired,
  theme: PropTypes.string.isRequired,
};

export default LegendPin;
