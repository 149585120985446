import classNames from 'classnames/bind';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import storeJS from 'store';

import LoadingScreen from 'components/Shared/LoadingScreen';
import { mutationEditorReset } from 'gql/mutations/editor';
import { useMutationLMSGradeSynchronize } from 'gql/mutations/lms';
import { useQueryCourseTestSessions } from 'gql/queries/course';
import { trackEvent, reportError } from 'lib/tracking';
import { notify } from 'uikit/Notifications';
import Button from 'uikit/Button';

import Empty from './Empty/Empty';
import TestSessionCard from './TestSessionCard/TestSessionCard';
import styles from './TestResults.module.scss';

const cx = classNames.bind(styles);

const TestResults = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/TestResults',
  });

  const { courseId } = useParams();

  const mutationLMSGradeSynchronize = useMutationLMSGradeSynchronize();

  const { isLoading, data } = useQueryCourseTestSessions({
    variables: {
      courseId,
    },
  });

  useEffect(() => {
    mutationEditorReset();
    return () => {
      mutationEditorReset();
    };
  }, []);

  const handleGradeSynchronize = async () => {
    try {
      await mutationLMSGradeSynchronize({ courseId });
      trackEvent('LMS', 'grade synchronize');
      notify('success', t('grade-synchronized'));
    } catch (err) {
      reportError('LMS', 'grade synchronize', err);
    }
  };

  const course = data?.course;

  const isEmpty = isLoading ? false : data.course.testSessions.length === 0;

  return isLoading ? (
    <LoadingScreen />
  ) : (
    <div className={cx('container')}>
      <div className={cx('header')}>
        <h3 className={cx('title')}>
          {t('title')}
          <span className={cx('count')}>{course.testSessions.length}</span>
        </h3>
        <div>
          {course.moodle.isMoodle && !isEmpty && (
            <Button
              className={cx('lms')}
              intent="primary"
              onClick={handleGradeSynchronize}
            >
              {t('moodle-grade-sync')}
            </Button>
          )}
          <Button
            as="a"
            className={cx('action')}
            href={`${
              process.env.REACT_APP_API_URL
            }/rest/test/results-excel?courseId=${
              course.id
            }&authToken=${storeJS.get('token')}`}
            icon="download"
            intent="white"
            target="blank"
          >
            {t('download')}
          </Button>
        </div>
      </div>
      {isEmpty && <Empty />}
      {course.testSessions.map((testSession) => (
        <TestSessionCard
          className={cx('testSession')}
          key={testSession.id}
          testSession={testSession}
          testSessions={course.testSessions}
        />
      ))}
    </div>
  );
};

export default TestResults;
