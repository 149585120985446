import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import LoadingScreen from 'components/Shared/LoadingScreen';
import MenuContainer from 'components/Shared/MenuContainer';
import PERMISSIONS from 'constants/permissions';

import BackButton from './Shared/BackButton';
import Student from './Student';
import Teacher from './Teacher/Teacher';
import styles from './Course.module.scss';

const cx = classNames.bind(styles);

const Course = ({ course = null, isInTeams, isLoading }) => (
  <MenuContainer headerAction={!isInTeams && <BackButton course={course} />}>
    <div className={cx('header')}>
      {!isInTeams && <BackButton course={course} />}
    </div>
    {isLoading ? (
      <LoadingScreen />
    ) : course.level >= PERMISSIONS.COLLABORATOR ? (
      <Teacher course={course} />
    ) : (
      <Student course={course} />
    )}
  </MenuContainer>
);

Course.propTypes = {
  course: PropTypes.object,
  isInTeams: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default Course;
