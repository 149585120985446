import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import DirectLink from 'components/Course/Shared/DirectLink';
import Email from 'components/Course/Shared/Email';
import { useQueryMe } from 'gql/queries/me';
import useModal from 'hooks/useModal';
import useSiteContext from 'hooks/useSiteContext';
import { getQuestionInfo } from 'questions';
import Button from 'uikit/Button';
import Modal from 'uikit/Modal';
import Tabs from 'uikit/Tabs';

import Code from './Code/Code';
import ExpertLink from './ExpertLink/ExpertLink';
import GAR from './GAR/GAR';
import QRCode from './QRCode/QRCode';
import ShareEmbed from './ShareEmbed/ShareEmbed';
import SharePublic from './SharePublic/SharePublic';
import Social from './Social/Social';
import styles from './CourseShare.module.scss';

const cx = classNames.bind(styles);

const CourseShare = ({ className = null, course }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/Cover/CourseShare',
  });

  const { isGAR, isRoleTeacher } = useSiteContext();

  const { isModalOpen, handleModalOpen, handleModalClose } = useModal(false);

  const {
    data: { me },
  } = useQueryMe();

  const isCourseInvitationRestricted =
    me?.organization?.settings?.isCourseInvitationRestricted;

  const isExpertLinkDisplayed =
    course.questions.some((question) => {
      const questionInfo = getQuestionInfo(question.type);
      return questionInfo.expert;
    }) ||
    course.chapters.some((chapter) =>
      chapter.questions.some((question) => {
        const questionInfo = getQuestionInfo(question.type);
        return questionInfo.expert;
      }),
    );

  const mainButtonIntent =
    course.questions.length ||
    course.chapters.some((chapter) => chapter.questions.length)
      ? 'primary'
      : 'default';

  const isNotGARStudent = !isGAR || (isGAR && isRoleTeacher);

  return (
    <>
      <Button
        className={cx('share-button', className)}
        data-testid="open-share-modal-button"
        icon="link"
        id="open-share-modal-button"
        intent={mainButtonIntent}
        onClick={handleModalOpen}
      >
        {t('share-course')}
      </Button>
      <Modal isOpen={isModalOpen} onExit={handleModalClose}>
        <div className={cx('modal')}>
          <Button
            aria-label={t('aria-close')}
            className={cx('close')}
            icon="times"
            onClick={handleModalClose}
          />
          <h1 className={cx('title')}>{t('title')}</h1>
          <Tabs className={cx('tabs')}>
            {isGAR && (
              <Tabs.Tab label={t('share-classes')}>
                <GAR course={course} handleModalClose={handleModalClose} />
              </Tabs.Tab>
            )}
            {isNotGARStudent && (
              <Tabs.Tab label={t('share-student')}>
                <div className={cx('warning-container')}>
                  {course.isExam &&
                    course.examStartTime === course.examStopTime && (
                      <p className={cx('warning')}>{t('warning-settings')}</p>
                    )}
                  {isCourseInvitationRestricted && (
                    <p className={cx('warning')}>
                      {t('warning-invitations-restricted')}
                    </p>
                  )}
                </div>
                <DirectLink course={course} />
                <Code className={cx('code')} course={course} />
                {isExpertLinkDisplayed && <ExpertLink course={course} />}
                {!course.moodle.isMoodle && (
                  <Email className={cx('email')} course={course} />
                )}
                <QRCode course={course} />
                <Social className={cx('social')} course={course} />
              </Tabs.Tab>
            )}
            {isNotGARStudent && (
              <Tabs.Tab label={t('share-public')}>
                <SharePublic
                  course={course}
                  handleModalClose={handleModalClose}
                />
              </Tabs.Tab>
            )}
            {isNotGARStudent && (
              <Tabs.Tab label={t('share-embed')}>
                <ShareEmbed course={course} />
              </Tabs.Tab>
            )}
          </Tabs>
        </div>
      </Modal>
    </>
  );
};

CourseShare.propTypes = {
  className: PropTypes.string,
  course: PropTypes.object.isRequired,
};

export default CourseShare;
