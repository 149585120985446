import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Options from 'questions/Shared/Options';
import Tags from 'questions/Shared/Tags';
import Button from 'uikit/Button';
import CheckBoxTrueFalse from 'uikit/CheckBoxTrueFalse';
import Icon from 'uikit/Icon';
import InputError from 'uikit/InputError';
import InputRich from 'uikit/InputRich';
import Switch from 'uikit/Switch';
import Tooltip from 'uikit/Tooltip';

import styles from './Editor.module.scss';

const cx = classNames.bind(styles);

const Editor = ({
  addFeedbacks,
  choices,
  className,
  handleChoiceAdd,
  handleChoiceComplete,
  handleChoiceRemove,
  handleFeedbacksToggle,
  t,
}) => (
  <div className={cx('container', className)}>
    <p className={cx('label')}>{t('Questions/TrueOrFalse/Editor/choices')}</p>
    <div className={cx('field')}>
      <InputError name="choices" />
      {[...choices, ''].map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index}>
          <div className={cx('list-element', 'choices')}>
            {index === choices.length ? (
              <Button
                className={cx('choice-add')}
                icon="plus"
                intent="outline"
                onClick={handleChoiceAdd}
                size="small"
                tooltip={t('Questions/TrueOrFalse/Editor/choice-add')}
              />
            ) : (
              <CheckBoxTrueFalse
                className={cx('checkboxes')}
                name={`choices.${index}.answer`}
              />
            )}
            {index === choices.length ? (
              <InputRich
                className={cx('choice-complete')}
                name="tmp"
                onChange={handleChoiceComplete}
                placeholder={t(
                  'Questions/TrueOrFalse/Editor/add-choice-placeholder',
                )}
              />
            ) : (
              <InputRich
                className={cx('fill')}
                name={`choices.${index}.title`}
                placeholder={t(
                  `Questions/TrueOrFalse/Editor/choice${index + 1}-placeholder`,
                )}
                shouldHideError
              />
            )}
            {index !== choices.length && (
              <Button
                icon="trash-alt"
                intent="outline"
                onClick={() => handleChoiceRemove(index)}
                tooltip={t('Questions/TrueOrFalse/Editor/delete-choice')}
                variant="regular"
              />
            )}
          </div>
          {addFeedbacks && (
            <InputRich
              className={cx('choice-feedback')}
              name={`choices.${index}.feedback`}
              placeholder={t('Questions/TrueOrFalse/choice-feedback-label')}
            />
          )}
        </div>
      ))}
    </div>
    <InputRich
      helper={
        <Tooltip tooltip={t('Questions/TrueOrFalse/Editor/feedback-helper')}>
          <Icon name="info-circle" />
        </Tooltip>
      }
      label={t('Questions/TrueOrFalse/feedback-label')}
      name="feedback"
    />
    <Options className={cx('options')}>
      <Switch
        className={cx('field')}
        label={t('Questions/TrueOrFalse/Editor/randomize-label')}
        name="randomize"
      />
      <Switch
        className={cx('field')}
        label={t('Questions/TrueOrFalse/Editor/feedbacks')}
        name="feedbacks"
        onClick={handleFeedbacksToggle}
      />
      <Tags />
    </Options>
  </div>
);

Editor.propTypes = {
  addFeedbacks: PropTypes.bool,
  choices: PropTypes.arrayOf(PropTypes.object).isRequired,
  className: PropTypes.string,
  handleChoiceAdd: PropTypes.func.isRequired,
  handleChoiceComplete: PropTypes.func.isRequired,
  handleChoiceRemove: PropTypes.func.isRequired,
  handleFeedbacksToggle: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

Editor.defaultProps = {
  className: undefined,
  addFeedbacks: undefined,
};

export default Editor;
