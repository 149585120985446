import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'uikit/Button';
import ImagePicker from 'uikit/ImagePicker';

import styles from './MediaToolbar.module.scss';

const cx = classNames.bind(styles);

const MediaToolbar = ({
  className,
  handleImageAdd,
  handleModalClose,
  handleModalOpen,
  isDisplayedInColumn,
  isModalOpen,
  t,
}) => (
  <div className={cx('container', className, { column: isDisplayedInColumn })}>
    <p className={cx('title')}>
      {t('Questions/Shared/SlideBuilder/MediaToolbar/media-title')}
    </p>
    <div className={cx('image-actions')}>
      <Button className={cx('action')} onClick={handleModalOpen}>
        {t('Questions/Shared/SlideBuilder/MediaToolbar/image-add')}
      </Button>
    </div>
    <ImagePicker
      handleModalClose={handleModalClose}
      isModalOpen={isModalOpen}
      onSelect={handleImageAdd}
    />
  </div>
);

MediaToolbar.propTypes = {
  className: PropTypes.string,
  handleImageAdd: PropTypes.func.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  handleModalOpen: PropTypes.func.isRequired,
  isDisplayedInColumn: PropTypes.bool.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

MediaToolbar.defaultProps = {
  className: undefined,
};

export default MediaToolbar;
