import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { mutationEditorQuestionImportPreview } from 'gql/mutations/editor';
import { getQuestionInfo } from 'questions';

import QuestionCard from './QuestionCard';

const QuestionCardContainer = ({ question, ...props }) => {
  const { t } = useTranslation();
  const questionInfo = getQuestionInfo(question.type);
  const [{ value }, , { setValue }] = useField('questionIds');
  const isSelected = useMemo(
    () => value.includes(question.id),
    [question, value],
  );
  const handleQuestionSelect = useCallback(() => {
    if (isSelected) {
      setValue(value.filter((questionId) => questionId !== question.id));
    } else {
      setValue([...value, question.id]);
    }
  }, [isSelected, question, setValue, value]);
  return (
    <QuestionCard
      {...props}
      handleQuestionPreview={mutationEditorQuestionImportPreview}
      handleQuestionSelect={handleQuestionSelect}
      isSelected={isSelected}
      question={question}
      questionInfo={questionInfo}
      t={t}
    />
  );
};

QuestionCardContainer.propTypes = {
  question: PropTypes.object.isRequired,
};

export default QuestionCardContainer;
