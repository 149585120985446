import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import GroupCard from '../GroupCard';
import styles from './Groups.module.scss';

const cx = classNames.bind(styles);

const Groups = ({ className, course }) => (
  <div className={className}>
    {course.groups.map((group) => (
      <GroupCard
        course={course}
        className={cx('group')}
        group={group}
        key={group.id}
      />
    ))}
  </div>
);

Groups.propTypes = {
  className: PropTypes.string,
  course: PropTypes.object.isRequired,
};

Groups.defaultProps = {
  className: undefined,
};

export default Groups;
