import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import QuestionCard from '../QuestionCard';
import styles from './Questions.module.scss';

const cx = classNames.bind(styles);

const Questions = ({ className, questions, ...props }) => (
  <div className={className}>
    {questions.map((question, index) => (
      <QuestionCard
        className={cx('question')}
        key={question.id}
        position={index + 1}
        question={question}
        {...props}
      />
    ))}
  </div>
);

Questions.propTypes = {
  className: PropTypes.string,
  questions: PropTypes.arrayOf(PropTypes.object).isRequired,
};

Questions.defaultProps = {
  className: undefined,
};

export default React.memo(Questions);
