import froalaeditor from 'froala-editor';

import { apiUploadsS3 } from 'api/upload';

const MISSING_LINK = 1;
const ERROR_DURING_UPLOAD = 2;
const BAD_RESPONSE = 4;
const BAD_FILE_TYPE = 8;

const errorMessages = {
  [MISSING_LINK]: 'No link in upload response.',
  [ERROR_DURING_UPLOAD]: 'Error during file upload.',
  [BAD_RESPONSE]: 'Parsing response failed.',
  [BAD_FILE_TYPE]: 'Unsupported file type - please provide an audio file.',
};

froalaeditor.POPUP_TEMPLATES['audio.insert'] =
  '[_BUTTONS_][_BY_URL_LAYER_][_UPLOAD_LAYER_][_RECORD_LAYER_][_PROGRESS_BAR_]';

froalaeditor.PLUGINS.audio = (editor) => {
  function initPopup() {
    const template = {
      buttons: `
          <div class="fr-buttons">
            ${editor.button.buildList([
              'audioUpload',
              'audioByURL',
              'audioRecord',
            ])}
          </div>`,
      upload_layer: `
          <div
            class="fr-audio-upload-layer fr-file-upload-layer fr-layer"
            id="drop-zone">
            <strong>
              ${editor.language.translate('Drop audio')}
            </strong>
            <br />
            (${editor.language.translate('or click')})
            <div class="fr-form">
              <input
                accept="audio/*"
                id="upload-input"
                type="file" />
            </div>
          </div>
          <div
            class="fr-audio-upload-layer fr-layer"
            id="insert-file"
            hidden>
            <p id="selected-file"></p>
            <div class="fr-action-buttons">
              <button
                class="fr-command fr-submit"
                data-cmd="audioInsertByUpload"
                id="insert-button"
                type="button">
                ${editor.language.translate('Insert')}
              </button>
            </div>
          </div>`,
      by_url_layer: `
          <div class="fr-audio-by-url-layer fr-layer">
            <div class="fr-input-line">
              <input
                id="fr-audio-url"
                placeholder="${editor.language.translate(
                  'Paste in an audio URL',
                )}"
                type="text"  />
            </div>
            <div class="fr-action-buttons">
              <button
                class="fr-command fr-submit"
                data-cmd="audioInsertByURL"
                type="button">
                ${editor.language.translate('Insert')}
              </button>
            </div>
          </div>`,
      record_layer: `
          <div class="fr-audio-record-layer fr-file-record-layer fr-layer">
            <div
              class="fr-form"
              style="display:flex; flex-direction:column; justify-content:center;">
              <button
                class="fr-command"
                data-cmd="audioInsertByRecord"
                id="fr-audio-record-start"
                style="display:block; border:none; background:none;"
                type="button">
                <i class="fas fa-play" style="font-size:50px; color:#0098f7;"></i>
              </button>
              <p
                id="fr-audio-record-message-start"
                style="display:block; margin-top: 10px;text-align:center;" >
                ${editor.language.translate('Click to record')}
              </p>
              <button
                class="fr-command"
                id="fr-audio-record-stop"
                style="display:none; border:none; background:none;"
                type="button">
                <i class="fas fa-stop-circle" style="font-size:50px; color:#fb2b46;"></i>
              </button>
              <p
                id="fr-audio-record-message-ongoing"
                style="display:none; margin-top: 10px;text-align:center;" >
                ${editor.language.translate('Recording...')}
              </p>
            </div>
          </div>`,
      progress_bar: `
          <div class="fr-audio-progress-bar-layer fr-layer">
            <strong class="fr-message">
              ${editor.language.translate('Uploading')}
            </strong>
            <div class="fr-loader">
              <span class="fr-progress" />
            </div>
            <div class="fr-action-buttons">
              <button
                class="fr-command fr-dismiss"
                data-cmd="audioDismissError"
                type="button">
                ${editor.language.translate('OK')}
              </button>
            </div>
          </div>`,
    };
    const $popup = editor.popups.create('audio.insert', template);
    editor.events.$on(
      $popup,
      'dragover dragenter',
      '.fr-audio-upload-layer',
      () => {
        document
          .querySelector('.fr-audio-upload-layer')
          .classList.add('fr-drop');
        return false;
      },
      true,
    );
    editor.events.$on(
      $popup,
      'dragleave dragend',
      '.fr-audio-upload-layer',
      () => {
        document
          .querySelector('.fr-audio-upload-layer')
          .classList.remove('fr-drop');
        return false;
      },
      true,
    );
    editor.events.$on(
      $popup,
      'drop',
      '.fr-audio-upload-layer',
      (e) => {
        e.preventDefault();
        e.stopPropagation();
        document
          .querySelector('.fr-audio-upload-layer')
          .classList.remove('fr-drop');
        const dt = e.originalEvent.dataTransfer;
        if (dt && dt.files && dt.files.length) {
          const audioUploadInput = document.getElementById('upload-input');
          const dropZone = document.getElementById('drop-zone');
          const insertFile = document.getElementById('insert-file');
          const selectedFile = document.getElementById('selected-file');
          audioUploadInput.files = dt.files;
          dropZone.setAttribute('hidden', true);
          insertFile.removeAttribute('hidden');
          selectedFile.innerText = dt.files[0].name;
        }
      },
      true,
    );
    editor.events.$on(
      $popup,
      'change',
      '.fr-audio-upload-layer input[type="file"]',
      (e) => {
        if (e.currentTarget.files && e.currentTarget.files.length) {
          const audioUploadInput = document.getElementById('upload-input');
          const dropZone = document.getElementById('drop-zone');
          const insertFile = document.getElementById('insert-file');
          const selectedFile = document.getElementById('selected-file');
          dropZone.setAttribute('hidden', true);
          insertFile.removeAttribute('hidden');
          selectedFile.innerText = audioUploadInput.files[0].name;
        }
      },
      true,
    );
    return $popup;
  }

  function showPopup() {
    let $popup = editor.popups.get('audio.insert');
    if (!$popup) {
      $popup = editor.audio.initPopup();
    }
    editor.popups.setContainer('audio.insert', editor.$tb);
    const $btn = editor.$tb.find('.fr-command[data-cmd="insertAudio"]');
    const { left } = $btn.offset();
    const top =
      $btn.offset().top +
      (editor.opts.toolbarBottom ? 10 : $btn.outerHeight() - 10);
    editor.popups.show('audio.insert', left, top, $btn.outerHeight());
    editor.audio.showLayer('audio-upload');
  }

  function hidePopup() {
    editor.popups.hide('audio.insert');
  }

  function showLayer(name) {
    const $popup = editor.popups.get('audio.insert');
    $popup.find('.fr-layer').removeClass('fr-active');
    $popup.find(`.fr-${name}-layer`).addClass('fr-active');
  }

  function showProgressBar(message) {
    const $popup = editor.popups.get('audio.insert');
    $popup.find('.fr-layer.fr-active').removeClass('fr-active');
    $popup.find('.fr-audio-progress-bar-layer').addClass('fr-active');
    $popup.find('.fr-buttons').hide();
    if (message) {
      editor.audio.showProgressMessage(message);
    }
  }

  function showProgressMessage(message) {
    const $popup = editor.popups.get('audio.insert');
    const $layer = $popup.find('.fr-audio-progress-bar-layer');
    $layer.find('strong').text(editor.language.translate(message));
    $layer.find('div').addClass('fr-indeterminate');
  }

  function throwError(code) {
    editor.audio.showProgressBar(errorMessages[code]);
  }

  function hideProgressBar() {
    const $popup = editor.popups.get('audio.insert');
    if (!$popup) {
      return;
    }
    $popup.find('.fr-layer.fr-active').removeClass('fr-active');
    $popup.find('.fr-audio-progress-bar-layer').removeClass('fr-active');
    $popup.find('.fr-buttons').show();
    editor.popups.hide('audio.insert');
  }

  function insertHtmlAudio(link) {
    const audioTag = document.createElement('audio');
    audioTag.setAttribute('controls', 'controls');
    audioTag.setAttribute('controlsList', 'nodownload');
    audioTag.setAttribute('src', link);
    const spanTag = document.createElement('span');
    spanTag.setAttribute('contenteditable', false);
    spanTag.setAttribute('class', 'fr-video fr-dvb fr-draggable');
    spanTag.appendChild(audioTag);
    const pTag = document.createElement('p');
    pTag.appendChild(spanTag);
    editor.html.insert(pTag.innerHTML);
    editor.undo.saveStep();
    editor.audio.hideProgressBar();
    const dropZone = document.getElementById('drop-zone');
    dropZone.removeAttribute('hidden');
    const insertFile = document.getElementById('insert-file');
    insertFile.setAttribute('hidden', true);
    const audioUploadInput = document.getElementById('upload-input');
    audioUploadInput.value = '';
    audioUploadInput.files = null;
    editor.audio.hidePopup();
  }

  async function upload(incommingAudio = null) {
    const audioUploadInput = document.getElementById('upload-input');
    const audio = incommingAudio || audioUploadInput.files[0];
    if (!audio) {
      return;
    }
    if (!audio.type.startsWith('audio/')) {
      editor.audio.throwError(BAD_FILE_TYPE);
      return;
    }
    try {
      editor.audio.showProgressMessage('Uploading...');
      editor.audio.showProgressBar();
      const filePath = await apiUploadsS3(audio);
      editor.audio.insertHtmlAudio(filePath);
    } catch (err) {
      editor.audio.throwError(BAD_RESPONSE);
    }
    editor.audio.hideProgressBar();
  }

  function insertByURL() {
    const audioURLInput = document.getElementById(`fr-audio-url`);
    if (audioURLInput) {
      const value = audioURLInput.value.trim();
      if (value) {
        editor.audio.insertHtmlAudio(value);
        audioURLInput.value = '';
      }
    }
  }

  function recordStart() {
    navigator.mediaDevices
      .getUserMedia({
        audio: true,
      })
      .then((stream) => {
        const mediaRecorder = new MediaRecorder(stream);
        mediaRecorder.start();
        const btnRecordStart = document.getElementById('fr-audio-record-start');
        const btnRecordMessageStart = document.getElementById(
          'fr-audio-record-message-start',
        );
        const btnRecordStop = document.getElementById('fr-audio-record-stop');
        const btnRecordMessageOngoing = document.getElementById(
          'fr-audio-record-message-ongoing',
        );
        btnRecordStart.style.display = 'none';
        btnRecordMessageStart.style.display = 'none';
        btnRecordStop.style.display = 'block';
        btnRecordMessageOngoing.style.display = 'block';
        btnRecordStop.addEventListener('click', () => {
          mediaRecorder.stop();
          stream.getTracks().forEach((track) => {
            track.stop();
          });
        });
        const chuck = [];
        mediaRecorder.addEventListener('dataavailable', (e) => {
          chuck.push(e.data);
        });
        mediaRecorder.addEventListener('stop', () => {
          const blob = new Blob(chuck, { type: 'audio/ogg;codecs=opus' });
          editor.audio.upload(blob);
          btnRecordStart.style.display = 'block';
          btnRecordMessageStart.style.display = 'block';
          btnRecordStop.style.display = 'none';
          btnRecordMessageOngoing.style.display = 'none';
        });
      });
  }

  return {
    initPopup,
    showPopup,
    hidePopup,
    showLayer,
    showProgressBar,
    showProgressMessage,
    throwError,
    hideProgressBar,
    insertHtmlAudio,
    upload,
    insertByURL,
    recordStart,
  };
};

froalaeditor.DefineIcon('insertAudio', {
  FA5NAME: 'volume-up',
  template: 'font_awesome_5',
});

froalaeditor.RegisterCommand('insertAudio', {
  title: 'Insérer du son',
  callback() {
    this.audio.showPopup();
  },
});

froalaeditor.DefineIcon('audioUpload', {
  FA5NAME: 'cloud-upload',
  template: 'font_awesome_5',
});

froalaeditor.RegisterCommand('audioUpload', {
  title: 'Upload Audio',
  callback() {
    this.audio.showLayer('audio-upload');
  },
});

froalaeditor.DefineIcon('audioByURL', {
  FA5NAME: 'link',
  template: 'font_awesome_5',
});

froalaeditor.RegisterCommand('audioByURL', {
  title: 'By URL',
  callback() {
    this.audio.showLayer('audio-by-url');
  },
});

froalaeditor.RegisterCommand('audioInsertByURL', {
  callback() {
    this.audio.insertByURL();
  },
});

froalaeditor.RegisterCommand('audioInsertByUpload', {
  callback() {
    this.audio.upload();
  },
});

froalaeditor.RegisterCommand('audioInsertByRecord', {
  callback() {
    this.audio.recordStart();
  },
});

froalaeditor.DefineIcon('audioRecord', {
  FA5NAME: 'microphone',
  template: 'font_awesome_5',
});

froalaeditor.RegisterCommand('audioRecord', {
  title: 'Record Audio',
  callback() {
    this.audio.showLayer('audio-record');
  },
});

froalaeditor.RegisterCommand('audioDismissError', {
  title: 'OK',
  callback() {
    this.audio.hideProgressBar();
  },
});
