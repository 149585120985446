import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const Icon = React.forwardRef(
  (
    {
      className,
      fixedWidth,
      flip,
      name,
      primaryColor,
      primaryOpacity,
      pulse,
      rotate,
      secondaryColor,
      secondaryOpacity,
      size,
      spin,
      swapOpacity,
      variant,
      style,
      ...props
    },
    ref,
  ) => (
    <i
      aria-hidden="true"
      className={classNames(
        `fa-flip-${flip}`,
        `fa-${name}`,
        `fa-rotate-${rotate}`,
        `fa-${size}`,
        {
          'fa-fw': fixedWidth,
          'fa-pulse': pulse,
          'fa-spin': spin,
          fas: variant === 'solid',
          far: variant === 'regular',
          fal: variant === 'light',
          fab: variant === 'brand',
          fad: variant === 'duotone',
          'fa-swap-opacity': swapOpacity,
        },
        className,
      )}
      ref={ref}
      style={{
        '--fa-primary-color': primaryColor,
        '--fa-primary-opacity': primaryOpacity,
        '--fa-secondary-color': secondaryColor,
        '--fa-secondary-opacity': secondaryOpacity,
        ...style,
      }}
      {...props}
    />
  ),
);

Icon.propTypes = {
  className: PropTypes.string,
  fixedWidth: PropTypes.bool,
  flip: PropTypes.oneOf(['none', 'horizontal', 'vertical', 'both']),
  name: PropTypes.string.isRequired,
  primaryColor: PropTypes.string,
  primaryOpacity: PropTypes.number,
  pulse: PropTypes.bool,
  rotate: PropTypes.oneOf([0, 90, 180, 270]),
  secondaryColor: PropTypes.string,
  secondaryOpacity: PropTypes.number,
  size: PropTypes.oneOf([
    'xs',
    'sm',
    'md',
    'lg',
    '2x',
    '3x',
    '5x',
    '7x',
    '10x',
  ]),
  spin: PropTypes.bool,
  swapOpacity: PropTypes.bool,
  variant: PropTypes.oneOf(['solid', 'regular', 'light', 'brand', 'duotone']),
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
};

Icon.defaultProps = {
  className: undefined,
  fixedWidth: false,
  flip: 'none',
  primaryColor: undefined,
  primaryOpacity: undefined,
  pulse: false,
  rotate: 0,
  secondaryColor: undefined,
  secondaryOpacity: undefined,
  size: 'md',
  spin: false,
  swapOpacity: false,
  variant: 'solid',
  style: {},
};

export default Icon;
