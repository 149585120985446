import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Icon from 'uikit/Icon';
import styles from './Pin.module.scss';

const cx = classNames.bind(styles);

const Pin = ({ handleDelete, sizeData }) => (
  <div
    className={cx('pin')}
    style={{
      left: `${sizeData.left}px`,
      top: `${sizeData.top}px`,
    }}
  >
    +
    <button className={cx('delete')} onClick={handleDelete} type="button">
      <Icon name="trash-alt" variant="regular" />
    </button>
  </div>
);

Pin.propTypes = {
  handleDelete: PropTypes.func.isRequired,
  sizeData: PropTypes.object.isRequired,
};

export default Pin;
