import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import CleanedText from 'uikit/CleanedText';
import FormattedText from 'uikit/FormattedText';
import Icon from 'uikit/Icon';

import styles from './Aggregate.module.scss';

const cx = classNames.bind(styles);

const Aggregate = ({ aggregation, className, question, questionInfo, t }) => (
  <div className={cx('container', className)}>
    <div className={cx('background')}>
      <div className={cx('header')}>
        <div className={cx('title-container')}>
          <p className={cx('icon', `background-${questionInfo.color}`)}>
            <img alt="" src={questionInfo.icon} />
          </p>
          <CleanedText as="p" className={cx('title')}>
            {question?.title}
          </CleanedText>
        </div>
        <p
          className={cx('answers-numbers', {
            isEmpty: aggregation.nCorrectAnswers === 0,
          })}
        >
          <span
            className={cx('correct-answers', {
              isEmpty: aggregation.nCorrectAnswers === 0,
            })}
          >
            {t('Questions/TrueOrFalse/Aggregate/correct-answers', {
              count: aggregation.nCorrectAnswers,
            })}
          </span>
          <span
            className={cx('total-answers', {
              'total-answers-wrong': aggregation.nCorrectAnswers === 0,
            })}
          >
            {` / ${aggregation.nTotalAnswers}`}
          </span>
        </p>
      </div>
      {question.content.choices.map((choice, index) => {
        const success =
          (aggregation.aggregatedAnswers[index] * 100) /
          aggregation.nTotalAnswers;
        return (
          <div className={cx('choice')} key={choice.idx}>
            <div className={cx('checkbox')}>
              <div className={cx('buttons')}>
                <div
                  className={cx('button', 'left', {
                    isChecked: choice.answer === true,
                  })}
                >
                  <Icon name="check" />
                </div>
                <div
                  className={cx('button', 'right', {
                    isChecked: choice.answer === false,
                  })}
                >
                  <Icon name="times" />
                </div>
              </div>
            </div>
            <FormattedText className={cx('choice-title')}>
              {choice.title}
            </FormattedText>
            <div className={cx('gauge')}>
              <div
                className={cx('success')}
                style={{
                  width: `${success}%`,
                }}
              />
              <div className={cx('animation')} />
              <p className={cx('percentage')}>{`${Math.round(success)}%`}</p>
            </div>
            <p
              className={cx('good-answers', {
                isEmpty: aggregation.aggregatedAnswers[index] === 0,
              })}
            >
              {t('Questions/TrueOrFalse/Aggregate/good-answers', {
                count: aggregation.aggregatedAnswers[index],
              })}
            </p>
          </div>
        );
      })}
    </div>
  </div>
);

Aggregate.propTypes = {
  aggregation: PropTypes.object.isRequired,
  className: PropTypes.string,
  question: PropTypes.object.isRequired,
  questionInfo: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

Aggregate.defaultProps = {
  className: undefined,
};

export default Aggregate;
