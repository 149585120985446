import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import extract from 'helpers/extract';
import {
  useMutationCommentReplyUpdate,
  useMutationCommentReplyDelete,
} from 'gql/mutations/commentReply';
import { trackEvent, reportError } from 'lib/tracking';

import CommentReply from './CommentReply';

const CommentReplyContainer = ({ commentReply, ...props }) => {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const handleEditToggle = useCallback(() => {
    setIsEditing((s) => !s);
  }, []);
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        content: yup
          .string()
          .trim()
          .required(
            t(
              'Shared/Comment/QuestionComments/Comment/CommentReply/content-error-required',
            ),
          ),
      }),
    [t],
  );
  const mutationCommentReplyUpdate = useMutationCommentReplyUpdate();
  const handleEdit = useCallback(
    async (values) => {
      try {
        const variables = extract(values, validationSchema);
        await mutationCommentReplyUpdate(commentReply, variables);
        trackEvent('commentReply', 'update');
      } catch (err) {
        reportError('commentReply', 'update', err);
      } finally {
        setIsEditing(false);
      }
    },
    [commentReply, mutationCommentReplyUpdate, validationSchema],
  );
  const mutationCommentReplyDelete = useMutationCommentReplyDelete();
  const handleDelete = useCallback(async () => {
    try {
      await mutationCommentReplyDelete(commentReply);
      trackEvent('commentReply', 'delete');
    } catch (err) {
      reportError('commentReply', 'delete', err);
    }
  }, [commentReply, mutationCommentReplyDelete]);
  return (
    <CommentReply
      commentReply={commentReply}
      handleDelete={handleDelete}
      handleEdit={handleEdit}
      handleEditToggle={handleEditToggle}
      isEditing={isEditing}
      validationSchema={validationSchema}
      {...props}
    />
  );
};

CommentReplyContainer.propTypes = {
  commentReply: PropTypes.object.isRequired,
};

export default CommentReplyContainer;
