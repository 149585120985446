import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'uikit/Button';
import Modal from 'uikit/Modal';

import styles from './FolderDelete.module.scss';

const cx = classNames.bind(styles);

const GroupDelete = ({
  handleClose,
  handleFolderDelete,
  isLoading,
  isModalOpen,
  status,
  t,
}) => (
  <Modal isOpen={isModalOpen} onExit={handleClose}>
    <div className={cx('container')}>
      <Button
        aria-label={t('Dashboard/FolderDelete/aria-close')}
        className={cx('close')}
        icon="times"
        onClick={handleClose}
      />
      <h1 className={cx('title')}>{t('Dashboard/FolderDelete/title')}</h1>
      <p className={cx('subtitle')}>{t('Dashboard/FolderDelete/subtitle')}</p>
      {status && (
        <p className={cx('error')} role="alert">
          {t('Dashboard/FolderDelete/folder-delete-error')}
        </p>
      )}
      <Button
        className={cx('action')}
        intent="danger"
        isLoading={isLoading}
        onClick={handleFolderDelete}
        size="large"
      >
        {t('Dashboard/FolderDelete/confirm-delete-folder')}
      </Button>
    </div>
  </Modal>
);

GroupDelete.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleFolderDelete: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  status: PropTypes.string,
  t: PropTypes.func.isRequired,
};

GroupDelete.defaultProps = {
  status: undefined,
};

export default GroupDelete;
