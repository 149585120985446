import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'uikit/Button';
import FormattedText from 'uikit/FormattedText';
import Icon from 'uikit/Icon';
import Modal from 'uikit/Modal';

import styles from './Recto.module.scss';

const cx = classNames.bind(styles);

const Recto = ({
  answer,
  className,
  handleDestinationSelect,
  handleSourceRemove,
  handleSourceSelect,
  isDisabled,
  matches,
  question,
  selectedSource,
  t,
  theme,
}) => (
  <>
    <div className={cx('container', className, `theme-${theme}`)}>
      <FormattedText as="h1" className={cx('title')}>
        {question.title}
      </FormattedText>
      {!isDisabled && (
        <div className={cx('instructions')}>
          {t('Questions/Matching/Recto/instructions')}
        </div>
      )}
      {matches.map((match, sourceId) => {
        const matched = matches.find((m) => m.idx === answer[sourceId]);
        return (
          <div
            className={cx('match', {
              isDisabled,
            })}
            key={match.idx}
          >
            {/* eslint-disable-next-line */}
            <button
              className={cx('inner')}
              onClick={() => handleSourceSelect(sourceId)}
              type="button"
            >
              <FormattedText className={cx('match-title')}>
                {match.source}
              </FormattedText>
              {matched ? (
                <FormattedText className={cx('match-destination')}>
                  {matched.destination}
                </FormattedText>
              ) : (
                <p>{t('Questions/Matching/Recto/match')}</p>
              )}
            </button>
            {matched && (
              <button
                aria-label={t('Questions/Matching/Recto/aria-remove-matching')}
                className={cx('remove')}
                onClick={() => handleSourceRemove(sourceId)}
                type="button"
              >
                <Icon name="times" />
              </button>
            )}
          </div>
        );
      })}
    </div>
    <Modal isOpen={!!selectedSource} onExit={() => handleSourceSelect(-1)}>
      <div className={cx('modal')}>
        <Button
          aria-label={t('Questions/Matching/Recto/aria-close')}
          className={cx('close')}
          icon="times"
          onClick={() => handleSourceSelect(-1)}
        />
        <FormattedText className={cx('modal-title')}>
          {selectedSource?.source}
        </FormattedText>
        {matches.map((match) => (
          // eslint-disable-next-line
          <button
            className={cx('match', 'inner', {
              isSelected: answer.includes(match.idx),
            })}
            key={match.idx}
            onClick={() => handleDestinationSelect(match.idx)}
          >
            <FormattedText tabIndex={-1}>{match.destination}</FormattedText>
          </button>
        ))}
      </div>
    </Modal>
  </>
);

Recto.propTypes = {
  answer: PropTypes.arrayOf(PropTypes.number).isRequired,
  className: PropTypes.string,
  handleDestinationSelect: PropTypes.func.isRequired,
  handleSourceRemove: PropTypes.func.isRequired,
  handleSourceSelect: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  matches: PropTypes.arrayOf(PropTypes.object).isRequired,
  question: PropTypes.object.isRequired,
  selectedSource: PropTypes.object,
  t: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
};

Recto.defaultProps = {
  className: undefined,
  isDisabled: false,
  selectedSource: undefined,
};

export default Recto;
