import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { ME_DATA } from 'gql/fragments';
import { mutationAppLogIn, mutationAppLogOut } from 'gql/mutations/app';

export const AUTH_LOGIN = gql`
  mutation AuthLogin($username: String!, $password: String!) {
    authLogin(username: $username, password: $password)
  }
`;

export function useMutationAuthLogin(redirectTo) {
  const [mutation] = useMutation(AUTH_LOGIN, {
    onCompleted: async ({ authLogin: token }) => {
      try {
        await mutationAppLogIn(token, redirectTo);
      } catch (err) {
        //
      }
    },
  });
  return useCallback(
    (variables) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}

export const AUTH_LOGOUT = gql`
  mutation AuthLogout {
    authLogout
  }
`;

export function useMutationAuthLogout() {
  const [mutation] = useMutation(AUTH_LOGOUT, {
    ignoreResults: true,
    onCompleted: async () => {
      try {
        await mutationAppLogOut();
      } catch (err) {
        //
      }
    },
  });
  return mutation;
}

export const AUTH_RECOVER_PASSWORD = gql`
  mutation AuthRecoverPassword($email: String!) {
    authRecoverPassword(email: $email)
  }
`;

export function useMutationAuthRecoverPassword() {
  const [mutation] = useMutation(AUTH_RECOVER_PASSWORD, {
    ignoreResults: true,
  });
  return useCallback(
    (variables) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}

export const AUTH_REGISTER = gql`
  mutation AuthRegister($username: String!, $password: String!) {
    authRegister(username: $username, password: $password)
  }
`;

export function useMutationAuthRegister(redirectTo) {
  const [mutation] = useMutation(AUTH_REGISTER, {
    onCompleted: async ({ authRegister: token }) => {
      try {
        await mutationAppLogIn(token, redirectTo);
      } catch (err) {
        //
      }
    },
  });
  return useCallback(
    (variables) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}

export const AUTH_RESEND_VERIFICATION = gql`
  mutation AuthResendVerification($email: String!) {
    authResendVerification(email: $email)
  }
`;

export function useMutationAuthResendVerification() {
  const [mutation] = useMutation(AUTH_RESEND_VERIFICATION, {
    ignoreResults: true,
  });
  return useCallback(
    (variables) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}

export const AUTH_RESET_PASSWORD = gql`
  mutation AuthResetPassword($password: String!, $recoveryToken: String!) {
    authResetPassword(password: $password, recoveryToken: $recoveryToken)
  }
`;

export function useMutationAuthResetPassword() {
  const [mutation] = useMutation(AUTH_RESET_PASSWORD, {
    ignoreResults: true,
  });
  return useCallback(
    (variables) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}

export const AUTH_PASSWORD_UPDATE = gql`
  mutation AuthUpdatePassword($password: String!, $newPassword: String!) {
    authUpdatePassword(password: $password, newPassword: $newPassword)
  }
`;

export function useMutationAuthUpdatePassword() {
  const [mutation] = useMutation(AUTH_PASSWORD_UPDATE, {
    ignoreResults: true,
  });
  return useCallback(
    (variables) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}

export const AUTH_CHANGE_EMAIL = gql`
  mutation AuthChangeEmail($email: String!) {
    authChangeEmail(email: $email)
  }
`;

export function useMutationAuthChangeEmail() {
  const [mutation] = useMutation(AUTH_CHANGE_EMAIL, {
    ignoreResults: true,
  });
  return useCallback(
    (variables) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}

export const AUTH_VERIFY = gql`
  mutation AuthVerify($verificationToken: String!) {
    authVerify(verificationToken: $verificationToken)
  }
`;

export function useMutationAuthVerify(redirectTo) {
  const [mutation] = useMutation(AUTH_VERIFY, {
    onCompleted: async ({ authVerify: token }) => {
      try {
        await mutationAppLogIn(token, redirectTo);
      } catch (err) {
        //
      }
    },
  });
  return useCallback(
    (variables) =>
      mutation({
        variables,
        update(cache) {
          cache.modify({
            fields: {
              me: (me = {}, { readField }) => {
                cache.writeFragment({
                  id: `Account:${readField('id', me)}`,
                  fragment: gql`
                    fragment AccountUpdateVerified on Account {
                      isVerified
                    }
                  `,
                  data: {
                    isVerified: true,
                  },
                });
                return me;
              },
            },
          });
        },
      }),
    [mutation],
  );
}

export const AUTH_CHANGE_EMAIL_CONFIRM = gql`
  mutation AuthChangeEmailConfirm($wantedEmailToken: String!) {
    authChangeEmailConfirm(wantedEmailToken: $wantedEmailToken) {
      ...MeData
    }
  }
  ${ME_DATA}
`;

export function useMutationAuthChangeEmailConfirm() {
  const [mutation] = useMutation(AUTH_CHANGE_EMAIL_CONFIRM);
  return useCallback(
    (variables) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}

export const AUTH_SET_EMAIL = gql`
  mutation AuthSetEmail($email: String!) {
    authSetEmail(email: $email) {
      ...MeData
    }
  }
  ${ME_DATA}
`;

export function useMutationAuthSetEmail() {
  const [mutation] = useMutation(AUTH_SET_EMAIL);
  return useCallback(
    (variables) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}
