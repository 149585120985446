import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useMutationCourseDuplicate,
  useMutationCourseResetParticipants,
} from 'gql/mutations/course';
import useModal from 'hooks/useModal';
import history from 'lib/history';
import { trackEvent, reportError } from 'lib/tracking';
import { notify } from 'uikit/Notifications';

import CourseResetParticipants from './CourseResetParticipants';

const CourseResetParticipantsContainer = ({ course, ...props }) => {
  const { t } = useTranslation();

  const { handleModalClose, handleModalOpen, isModalOpen } = useModal();

  const mutationCourseDuplicate = useMutationCourseDuplicate();

  const mutationCourseResetParticipants = useMutationCourseResetParticipants();

  const [status, setStatus] = useState(undefined);

  const [isResetLoading, setIsResetLoading] = useState(false);

  const [isDuplicationLoading, setIsDuplicationLoading] = useState(false);

  useEffect(() => {
    setStatus(undefined);
  }, [isModalOpen]);

  const handleCourseResetParticipants = async () => {
    try {
      setStatus(undefined);
      setIsResetLoading(true);
      await mutationCourseResetParticipants(course.id);
      handleModalClose();
      trackEvent('course', 'reset-participants');
    } catch (err) {
      setStatus(err.message);
      reportError('course', 'reset-participants', err);
    } finally {
      setIsResetLoading(false);
    }
  };

  const handleCourseDuplicate = async () => {
    try {
      setStatus(undefined);
      setIsDuplicationLoading(true);
      const {
        data: { courseDuplicate },
      } = await mutationCourseDuplicate({
        course,
        prefix: t(
          'Course/Teacher/Participants/Students/CourseResetParticipants/copy-of',
        ),
      });
      handleModalClose();
      notify(
        'success',
        t(
          'Course/Teacher/Participants/Students/CourseResetParticipants/course-duplicate-success',
        ),
      );
      history.push(`/course/${courseDuplicate.id}/`);
      trackEvent('course', 'duplicate');
    } catch (err) {
      setStatus('500');
      reportError('course', 'duplicate', err);
    } finally {
      setIsDuplicationLoading(false);
    }
  };

  return (
    <CourseResetParticipants
      {...props}
      onCourseDuplicate={handleCourseDuplicate}
      onCourseResetParticipants={handleCourseResetParticipants}
      onModalClose={handleModalClose}
      onModalOpen={handleModalOpen}
      isResetLoading={isResetLoading}
      isDuplicationLoading={isDuplicationLoading}
      isModalOpen={isModalOpen}
      status={status}
      t={t}
    />
  );
};

CourseResetParticipantsContainer.propTypes = {
  course: PropTypes.object.isRequired,
};

export default CourseResetParticipantsContainer;
