import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import PERMISSIONS from 'constants/permissions';
import { useMutationCourseInvite } from 'gql/mutations/course';
import extract from 'helpers/extract';
import { trackEvent, reportError } from 'lib/tracking';
import { notify } from 'uikit/Notifications';

import Email from './Email';

const EmailContainer = ({ course, group, ...props }) => {
  const { t } = useTranslation();
  const [unInvited, setUnInvited] = useState([]);
  const mutationCourseInvite = useMutationCourseInvite();
  const handleStudentsInvite = useCallback(
    async (values, form) => {
      try {
        const usernames = extract(
          values.usernames,
          yup.string().trim().lowercase().required(),
        );
        const {
          data: {
            courseInvite: { notSentInvitations },
          },
        } = await mutationCourseInvite({
          courseId: course.id,
          groupId: group ? group.id : null,
          level: PERMISSIONS.STUDENT,
          usernames,
        });
        setUnInvited(notSentInvitations);
        if (notSentInvitations.length === 0) {
          notify('success', t('Course/Shared/Email/students-invite-success'));
        }
        trackEvent('students', 'invite');
      } catch (err) {
        reportError('students', 'invite', err);
      } finally {
        form.setFieldValue('usernames', '');
      }
    },
    [course, group, mutationCourseInvite, t],
  );
  return (
    <Email
      handleStudentsInvite={handleStudentsInvite}
      t={t}
      unInvited={unInvited}
      {...props}
    />
  );
};

EmailContainer.propTypes = {
  course: PropTypes.object.isRequired,
  group: PropTypes.object,
};

EmailContainer.defaultProps = {
  group: undefined,
};

export default EmailContainer;
