import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { GAME_DATA } from 'gql/fragments';

export const GAME_CREATE = gql`
  mutation GameCreate(
    $courseId: ID!
    $chapterId: ID
    $type: String!
    $title: String!
  ) {
    gameCreate(
      courseId: $courseId
      chapterId: $chapterId
      type: $type
      title: $title
    ) {
      ...GameData
    }
  }
  ${GAME_DATA}
`;

export function useMutationGameCreate() {
  const [mutation] = useMutation(GAME_CREATE);
  return useCallback(
    (variables) =>
      mutation({
        variables,
        update(cache, { data: { gameCreate } }) {
          const newGameRef = cache.writeFragment({
            data: gameCreate,
            fragment: GAME_DATA,
          });
          cache.modify({
            id: `Course:${gameCreate.courseId}`,
            fields: {
              games: (existingGamesRef = [], { readField }) =>
                [...existingGamesRef, newGameRef].sort((a, b) =>
                  readField('title', a).localeCompare(
                    readField('title', b),
                    undefined,
                    {
                      numeric: true,
                      sensitivity: 'base',
                    },
                  ),
                ),
            },
          });
        },
      }),
    [mutation],
  );
}

export const GAME_DELETE = gql`
  mutation GameDelete($gameId: ID!) {
    gameDelete(gameId: $gameId)
  }
`;

export function useMutationGameDelete() {
  const [mutation] = useMutation(GAME_DELETE);
  return useCallback(
    (game) =>
      mutation({
        variables: {
          gameId: game.id,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          gameDelete: true,
        },
        update(cache) {
          cache.modify({
            id: `Course:${game.courseId}`,
            fields: {
              games: (existingGamesRef = [], { readField }) =>
                existingGamesRef.filter((c) => readField('id', c) !== game.id),
            },
          });
        },
      }),
    [mutation],
  );
}

export const GAME_DUPLICATE = gql`
  mutation GameDuplicate($gameId: ID!) {
    gameDuplicate(gameId: $gameId) {
      ...GameData
    }
  }
  ${GAME_DATA}
`;

export function useMutationGameDuplicate() {
  const [mutation] = useMutation(GAME_DUPLICATE);
  return useCallback(
    (game) =>
      mutation({
        variables: {
          gameId: game.id,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          gameDuplicate: {
            ...game,
            id: Math.random().toString(10),
            __typename: 'Game',
          },
        },
        update(cache, { data: { gameDuplicate } }) {
          const newGameRef = cache.writeFragment({
            data: gameDuplicate,
            fragment: GAME_DATA,
          });
          cache.modify({
            id: `Course:${game.courseId}`,
            fields: {
              games: (existingGamesRef = [], { readField }) =>
                [...existingGamesRef, newGameRef].sort((a, b) =>
                  readField('title', a).localeCompare(
                    readField('title', b),
                    undefined,
                    {
                      numeric: true,
                      sensitivity: 'base',
                    },
                  ),
                ),
            },
          });
        },
      }),
    [mutation],
  );
}

export const GAME_UPDATE = gql`
  mutation GameUpdate(
    $gameId: ID!
    $chapterId: ID
    $type: String
    $title: String
  ) {
    gameUpdate(
      gameId: $gameId
      chapterId: $chapterId
      type: $type
      title: $title
    ) {
      ...GameData
    }
  }
  ${GAME_DATA}
`;

export function useMutationGameUpdate() {
  const [mutation] = useMutation(GAME_UPDATE);
  return useCallback(
    (game, variables) =>
      mutation({
        variables: {
          ...variables,
          gameId: game.id,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          gameUpdate: {
            ...game,
            ...variables,
            __typename: 'Game',
          },
        },
        update(cache, { data: { gameUpdate } }) {
          cache.modify({
            id: `Course:${gameUpdate.courseId}`,
            fields: {
              games: (existingGamesRef = [], { readField }) =>
                [...existingGamesRef].sort((a, b) =>
                  readField('title', a).localeCompare(
                    readField('title', b),
                    undefined,
                    {
                      numeric: true,
                      sensitivity: 'base',
                    },
                  ),
                ),
            },
          });
        },
      }),
    [mutation],
  );
}
