import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import imageStudents from './images/students.svg';
import styles from './Empty.module.scss';

const cx = classNames.bind(styles);

const Empty = ({ className, t }) => (
  <div className={cx('container', className)}>
    <img
      alt=""
      aria-hidden="true"
      className={cx('image')}
      src={imageStudents}
    />
    <p>{t('Course/Teacher/Students/Empty/no-students')}</p>
  </div>
);

Empty.propTypes = {
  className: PropTypes.string,
  t: PropTypes.func.isRequired,
};

Empty.defaultProps = {
  className: undefined,
};

export default Empty;
