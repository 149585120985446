import { useField } from 'formik';
import { useEffect } from 'react';

import { useQueryEmailToOrganization } from 'gql/queries/organization';

const ForceSSOContainer = () => {
  const [{ value: username }] = useField('username');
  const [, , { setValue: setOrganization }] = useField('organization');
  const { fetchQuery, error, isLoading, data } = useQueryEmailToOrganization();
  useEffect(() => {
    try {
      const delay = setTimeout(() => {
        if (/^.+@.+\..+$/g.test(username)) {
          fetchQuery({
            variables: {
              email: username,
            },
          });
        } else {
          setOrganization(null);
        }
      }, 1000);
      return () => clearTimeout(delay);
    } catch (err) {
      //
    }
    return null;
    // if we add setOrganization to the dependencies,
    // fetchQuery is called each second
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username]);
  useEffect(() => {
    if (error) {
      setOrganization(null);
    } else if (!isLoading) {
      setOrganization(data.emailToOrganization);
    }
    // if we add setOrganization to the dependencies,
    // the page freeze when the useEffect is called.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, data, isLoading]);
  return null;
};

export default ForceSSOContainer;
