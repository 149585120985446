import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';

import Groups from './Groups';
import Standalone from './Standalone';

import styles from './Finder.module.scss';

const cx = classNames.bind(styles);

const Finder = ({ className, course, handleSort }) => (
  <div className={cx(className)}>
    <DragDropContext onDragEnd={handleSort}>
      <Groups course={course} />
      <Standalone className={cx('group')} course={course} />
    </DragDropContext>
  </div>
);

Finder.propTypes = {
  className: PropTypes.string,
  course: PropTypes.object.isRequired,
  handleSort: PropTypes.func.isRequired,
};

Finder.defaultProps = {
  className: undefined,
};

export default Finder;
