import React, { useContext } from 'react';

import Context from 'lib/context';

import LegendPin from './LegendPin';

const LegendPinContainer = (props) => {
  const { theme } = useContext(Context);
  return <LegendPin theme={theme} {...props} />;
};

export default LegendPinContainer;
