import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'uikit/Button';
import FormattedText from 'uikit/FormattedText';
import Icon from 'uikit/Icon';
import Modal from 'uikit/Modal/Modal';

import VideoPreview from '../VideoPreview';
import styles from './VideoImporter.module.scss';

const cx = classNames.bind(styles);

const VideoImporter = ({
  className,
  handleDragEnter,
  handleDragLeave,
  handleImport,
  handleModalCloseAndReset,
  handleModalOpen,
  handleSelect,
  handleVideoReset,
  isDroppable,
  isModalOpen,
  isSubmitting,
  t,
  theme,
  videoPreview,
  videoToImport,
}) => (
  <div
    className={cx(
      className,
      'container',
      `theme-${theme}`,
    )}
  >
    <Button
      className={cx('open-modal-button')}
      icon="cloud-arrow-up"
      intent="white"
      onClick={handleModalOpen}
    >
      {t('uikit/VideoPicker/VideoImporter/import')}
    </Button>
    <Modal
      className={cx(`theme-${theme}`)}
      isOpen={isModalOpen}
      onExit={handleModalCloseAndReset}
    >
      <div className={cx('modal', 'large')}>
        <div className={cx('header')}>
          <h1 className={cx('title')}>
            {t('uikit/VideoPicker/VideoImporter/import')}
          </h1>
          <Button
            aria-label={t('uikit/VideoPicker/VideoImporter/aria-close')}
            icon="times"
            onClick={handleModalCloseAndReset}
          />
        </div>
        <div
          className={cx('dropzone', {
            isDroppable,
          })}
          onDragEnter={handleDragEnter}
          onDragEnd={handleDragLeave}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragEnter}
          onDrop={handleImport}
        >
          <label
            aria-label={t('uikit/VideoPicker/VideoImporter/import')}
            htmlFor="file"
          >
            <input
              accept="video/*"
              id="file"
              name="file"
              onChange={handleImport}
              type="file"
            />
            <div className={cx('input-content')}>
              {videoPreview ? (
                <VideoPreview video={videoPreview} />
              ) : (
                <div className={cx('preview')}>
                  <div className={cx('plus-container')}>
                    <div className={cx('plus-icon')}>+</div>
                  </div>
                  <Icon className={cx('preview-icon')} name="camera" />
                </div>
              )}
              {!videoPreview && (
                <FormattedText className={cx('preview-text')}>
                  {t('uikit/VideoPicker/VideoImporter/import-video')}
                </FormattedText>
              )}
            </div>
          </label>
        </div>
        {videoToImport && (
          <>
            <div className={cx('file-info')}>
              <p className={cx('file-name')}>{`${videoToImport.name} (${
                Math.round((videoToImport.size / 1e6) * 10) / 10
              }MB)`}</p>
              <Button
                aria-label={t('uikit/VideoPicker/VideoImporter/delete')}
                className={cx('link-reset-button')}
                icon="times"
                onClick={handleVideoReset}
                size="small"
              />
            </div>
            <Button
              className={cx('action', 'select-button')}
              intent="primary"
              isLoading={isSubmitting}
              onClick={handleSelect}
              size="large"
            >
              {t('uikit/VideoPicker/VideoImporter/select')}
            </Button>
          </>
        )}
      </div>
    </Modal>
  </div>
);

VideoImporter.propTypes = {
  className: PropTypes.string,
  handleDragEnter: PropTypes.func.isRequired,
  handleDragLeave: PropTypes.func.isRequired,
  handleImport: PropTypes.func.isRequired,
  handleVideoReset: PropTypes.func.isRequired,
  handleModalCloseAndReset: PropTypes.func.isRequired,
  handleModalOpen: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
  isDroppable: PropTypes.bool.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
  videoPreview: PropTypes.string,
  videoToImport: PropTypes.object,
};

VideoImporter.defaultProps = {
  className: undefined,
  videoPreview: undefined,
  videoToImport: undefined,
};

export default VideoImporter;
