import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  mutationEditorGroupDelete,
  mutationEditorGroupShare,
  mutationEditorGroupUpdate,
} from 'gql/mutations/editor';

import GroupCard from './GroupCard';

const GroupCardContainer = ({ className, group, ...props }) => {
  const { t } = useTranslation();
  const [isExpanded, setExpanded] = useState(false);
  const handleGroupExpandedToggle = useCallback((evt) => {
    evt.stopPropagation();
    setExpanded((state) => !state);
  }, []);
  const handleGroupUpdate = useCallback(() => {
    mutationEditorGroupUpdate(group);
  }, [group]);
  const handleGroupDelete = useCallback(() => {
    mutationEditorGroupDelete(group);
  }, [group]);
  const handleGroupShare = useCallback(() => {
    mutationEditorGroupShare(group);
  }, [group]);
  return (
    <GroupCard
      className={className}
      group={group}
      handleGroupDelete={handleGroupDelete}
      handleGroupExpandedToggle={handleGroupExpandedToggle}
      handleGroupShare={handleGroupShare}
      handleGroupUpdate={handleGroupUpdate}
      isExpanded={isExpanded}
      t={t}
      {...props}
    />
  );
};

GroupCardContainer.propTypes = {
  className: PropTypes.string,
  group: PropTypes.object.isRequired,
};

GroupCardContainer.defaultProps = {
  className: undefined,
};

export default GroupCardContainer;
