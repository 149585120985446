import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import CleanedText from 'uikit/CleanedText';
import Icon from 'uikit/Icon';

import styles from './Aggregate.module.scss';

const cx = classNames.bind(styles);

const Aggregate = ({ aggregation, className, question, questionInfo, t }) => (
  <div className={cx('container', className)}>
    <div className={cx('background')}>
      <div className={cx('header')}>
        <div className={cx('title-container')}>
          <p className={cx('icon', `background-${questionInfo.color}`)}>
            <img alt="" src={questionInfo.icon} />
          </p>
          <CleanedText as="p" className={cx('title')}>
            {question?.title}
          </CleanedText>
        </div>
        <p
          className={cx('answers-numbers', {
            'answers-numbers-wrong': aggregation.nCorrectAnswers === 0,
          })}
        >
          <span
            className={cx('correct-answers', {
              isEmpty: aggregation.nCorrectAnswers === 0,
            })}
          >
            {t('Questions/Math/Aggregate/correct-answers', {
              count: aggregation.nCorrectAnswers,
            })}
          </span>
          <span
            className={cx('total-answers', {
              'total-answers-wrong': aggregation.nCorrectAnswers === 0,
            })}
          >
            {` / ${aggregation.nTotalAnswers}`}
          </span>
        </p>
      </div>
      <p className={cx('answers-title')}>
        {t('Questions/Math/Aggregate/frequent-answers')}
      </p>
      <div className={cx('user-answers-container')}>
        {aggregation.aggregatedAnswers.map((answer, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className={cx('answer')} key={index}>
            <CleanedText as="div" className={cx('answer-title')}>
              {`\` ${answer.title} \``}
            </CleanedText>
            <div className={cx('occurrence')}>
              <div className={cx('occurrence-number')}>{answer.occurrence}</div>
              <Icon className={cx('occurrence-icon')} name="user" />
            </div>
          </div>
        ))}
      </div>
      <div className={cx('good-answer-container')}>
        <p className={cx('good-answer-title')}>
          {t('Questions/Math/Aggregate/good-answers')}
        </p>
        <CleanedText as="div" className={cx('good-answer')}>
          {`\` ${question.content.answer} \``}
        </CleanedText>
      </div>
    </div>
  </div>
);

Aggregate.propTypes = {
  aggregation: PropTypes.object.isRequired,
  className: PropTypes.string,
  question: PropTypes.object.isRequired,
  questionInfo: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

Aggregate.defaultProps = {
  className: undefined,
};

export default Aggregate;
