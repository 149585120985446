import { gql, useQuery } from '@apollo/client';

import { FOLDER_DATA } from 'gql/fragments';

export const QUERY_FOLDERS_ME = gql`
  {
    foldersMe {
      ...FolderData
    }
  }
  ${FOLDER_DATA}
`;

export function useQueryFoldersMe(options) {
  const { loading, data, ...props } = useQuery(QUERY_FOLDERS_ME, options);
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}
