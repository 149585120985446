import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import FormattedText from 'uikit/FormattedText';
import InputText from 'uikit/InputText';

import ImageContainer from './ImageContainer';
import styles from './Recto.module.scss';

const cx = classNames.bind(styles);

const Recto = ({
  className,
  isExistingAnsweredLegend,
  legends,
  isDisabled,
  question,
  t,
  theme,
}) => (
  <div className={cx('container', className, `theme-${theme}`)}>
    <FormattedText as="h1" className={cx('title')}>
      {question.title}
    </FormattedText>
    {!isDisabled && (
      <div className={cx('instructions')}>
        {t('Questions/LabelOnImage/Recto/instructions')}
      </div>
    )}
    <div className={cx('image-legends-container')}>
      <ImageContainer
        className={cx('image')}
        imageSrc={question.content.image}
        legends={legends}
        zones={question.content.zones}
      />
      <div
        className={cx('legends', {
          scroll: question.content.legends.length > 8,
        })}
      >
        {/* unanswered legends */}
        {legends.map((legend, index) => {
          if (!legend.isCorrect) {
            return (
              <div className={cx('legend')} key={`unAnsweredLegends-${index}`}>
                <p className={cx('legend-index')}>{index + 1}</p>
                <InputText
                  aria-label={t('Questions/LabelOnImage/Recto/legend', {
                    number: index + 1,
                  })}
                  autoCapitalize="off"
                  autoComplete="off"
                  autoCorrect="off"
                  className={cx('legend-input')}
                  name={`answer.${index}`}
                />
              </div>
            );
          }
          return null;
        })}
        {/* already answered legends */}
        {isExistingAnsweredLegend && (
          <div className={cx('answeredLegends')}>
            {legends.map((legend, index) => {
              const legendAnswer = question.solution[index];
              if (legend.isCorrect) {
                return (
                  <div
                    className={cx('legend')}
                    key={`answeredLegends-${index}`}
                  >
                    <div className={cx('legend-index', 'answeredLegend-index')}>
                      {index + 1}
                    </div>
                    <div className={cx('answeredLegend-answer')}>
                      {legendAnswer.isForced
                        ? legendAnswer.correct.join(',') || '___'
                        : legendAnswer.user}
                    </div>
                  </div>
                );
              }
              return null;
            })}
          </div>
        )}
      </div>
    </div>
  </div>
);

Recto.propTypes = {
  className: PropTypes.string,
  isExistingAnsweredLegend: PropTypes.bool.isRequired,
  legends: PropTypes.arrayOf(PropTypes.object).isRequired,
  isDisabled: PropTypes.bool.isRequired,
  question: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
};

Recto.defaultProps = {
  className: undefined,
};

export default Recto;
