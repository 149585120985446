import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Options from 'questions/Shared/Options';
import Tags from 'questions/Shared/Tags';
import FormattedText from 'uikit/FormattedText';
import Icon from 'uikit/Icon';
import InputRich from 'uikit/InputRich';
import Switch from 'uikit/Switch';
import Tooltip from 'uikit/Tooltip';

import styles from './Editor.module.scss';

const cx = classNames.bind(styles);

const Editor = ({ className, t }) => (
  <div className={cx('container', className)}>
    <label className={cx('label')} htmlFor="FillInTheBlanks_Text">
      {t('Questions/FillInTheBlanks/Editor/text-label')}
    </label>
    <FormattedText className={cx('description')}>
      {t('Questions/FillInTheBlanks/Editor/text-description')}
    </FormattedText>
    <InputRich
      className={cx('field', 'fitb-text')}
      id="FillInTheBlanks_Text"
      isFillInTheBlanks
      name="text"
      placeholder={t('Questions/FillInTheBlanks/Editor/text-placeholder')}
    />
    <InputRich
      helper={
        <Tooltip
          tooltip={t('Questions/FillInTheBlanks/Editor/feedback-helper')}
        >
          <Icon name="info-circle" />
        </Tooltip>
      }
      label={t('Questions/FillInTheBlanks/feedback-label')}
      name="feedback"
    />
    <Options className={cx('options')}>
      <Switch
        className={cx('field')}
        label={t('Questions/FillInTheBlanks/Editor/ignore-case-label')}
        name="ignoreCase"
      />
      <Switch
        className={cx('field')}
        label={t('Questions/FillInTheBlanks/Editor/ignore-accents-label')}
        name="ignoreAccents"
      />
      <Switch
        className={cx('field')}
        label={t('Questions/FillInTheBlanks/Editor/ignore-punctuation-label')}
        name="ignorePunctuation"
      />
      <Tags />
    </Options>
  </div>
);

Editor.propTypes = {
  className: PropTypes.string,
  t: PropTypes.func.isRequired,
};

Editor.defaultProps = {
  className: undefined,
};

export default Editor;
