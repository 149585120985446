import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import TutorialIllustration1 from 'images/tutorials/illustrationTutorial1.svg';
import TutorialIllustration2 from 'images/tutorials/illustrationTutorial2.svg';
import Button from 'uikit/Button';
import SubPage from 'uikit/SubPage/SubPage';

import imageStudy from './images/study.svg';
import styles from './CourseStudyModal.module.scss';

const cx = classNames.bind(styles);

const CourseStudyModal = ({
  course,
  handleCourseStudy,
  handleModalClose,
  isModalOpen,
  needTutorialIllustration1,
  needTutorialIllustration2,
  t,
  theme,
}) => (
  <SubPage isFullScreen isOpen={isModalOpen} onExit={handleModalClose}>
    <div className={cx('container', `theme-${theme}`)}>
      <div className={cx('header')}>
        <div className={cx('image-container')}>
          {needTutorialIllustration1 || needTutorialIllustration2 ? (
            <img
              alt=""
              className={cx('image')}
              src={
                needTutorialIllustration1
                  ? TutorialIllustration1
                  : TutorialIllustration2
              }
            />
          ) : (
            <>
              <div className={cx('image-background')} />
              <img alt="" className={cx('image')} src={imageStudy} />
              <div className={cx('image-line')} />
            </>
          )}
        </div>
        <div className={cx('text')}>
          <h1 className={cx('title-container')}>
            <span className={cx('welcome-in')}>
              {t(
                'Shared/CourseStudy/CourseStudyModal/welcome-in',
              ).toUpperCase()}
            </span>
            <span className={cx('title')}>{course?.title}</span>
          </h1>
          <Button
            className={cx('action')}
            intent="primary"
            onClick={() => handleCourseStudy(null)}
          >
            {t('Shared/CourseStudy/CourseStudyModal/confirm-study-course')}
          </Button>
        </div>
      </div>
      {course?.chapters.length > 0 && (
        <h2 className={cx('study-by-chapter')}>
          {t('Shared/CourseStudy/CourseStudyModal/study-by-chapter')}
        </h2>
      )}
      {course?.chapters.map((chapter) => (
        <div className={cx('chapter-card')} key={chapter.id}>
          <div className={cx('chapter-title')}>{chapter.title}</div>
          <Button
            aria-label={t(
              'Shared/CourseStudy/CourseStudyModal/aria-study-chapter',
              { title: chapter.title },
            )}
            icon="user-graduate"
            intent="gray"
            onClick={() => handleCourseStudy(chapter.id)}
          >
            {t('Shared/CourseStudy/CourseStudyModal/study')}
          </Button>
        </div>
      ))}
    </div>
  </SubPage>
);

CourseStudyModal.propTypes = {
  course: PropTypes.object,
  handleCourseStudy: PropTypes.func.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  needTutorialIllustration1: PropTypes.bool.isRequired,
  needTutorialIllustration2: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
};

CourseStudyModal.defaultProps = {
  course: undefined,
};

export default CourseStudyModal;
