const formatMediaDuration = (duration, setDuration) => {
  if (duration === Infinity || Number.isNaN(duration)) return;
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration - hours * 3600) / 60);
  const seconds = Math.floor(duration - hours * 3600 - minutes * 60);
  if (setDuration) {
    setDuration(
      `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${
        seconds < 10 ? '0' : ''
      }${seconds}`,
    );
  }
};

export default formatMediaDuration;
