import storeJS from 'store';

import i18n from 'lib/i18n';

/**
 * Perform a generic request
 * @param {String} url the URL to query
 * @param {String} method the method used to query the server
 * @param {object|array} payload the data to send to the server for POST and PUT methods
 * @returns {Object} the response JSON data for GET, POST, and PUT methods
 */
const fetchURL = async (url, method, payload = null) => {
  const language = i18n.language || 'en';
  const fetchOptions = {
    mode: 'cors',
    credentials: 'omit',
    headers: {
      Accept: 'application/json',
      'X-Language': language,
    },
  };
  const token = storeJS.get('token');
  if (token) {
    fetchOptions.headers.Authorization = `Bearer ${token}`;
  }
  if (method === 'POST' || method === 'PUT') {
    fetchOptions.method = method;
    fetchOptions.url = url;
    fetchOptions.headers['Content-Type'] = 'application/json;charset=UTF-8';
    fetchOptions.body = JSON.stringify(payload || {});
  } else if (method === 'GET' || method === 'DELETE') {
    const querystring = new URLSearchParams(payload || {});
    fetchOptions.method = method;
    fetchOptions.url = `${url}?${querystring.toString()}`;
  } else if (method === 'RAW') {
    fetchOptions.method = 'POST';
    fetchOptions.url = url;
    fetchOptions.body = payload;
  } else {
    throw new Error(`Request unknown method "${method}".`);
  }
  const response = await fetch(fetchOptions.url, fetchOptions);
  if (!response || !response.ok) {
    throw new Error(response.status);
  }
  const jsonResponse = await response.json();
  if (jsonResponse.status !== 200 || jsonResponse.error !== null) {
    throw new Error(jsonResponse.status);
  }
  return jsonResponse.data;
};

/**
 * Perform a GET request
 * @param {String} url the URL to query
 * @param {Object} querystring the query string to add to the URL
 * @returns {Object} the response JSON data
 */
export const GET = (url, querystring = {}) => fetchURL(url, 'GET', querystring);

/**
 * Perform a POST request
 * @param {String} url the URL to query
 * @param {Object} body the request JSON data
 * @returns {Object} the response JSON data
 */
export const POST = (url, body = {}) => fetchURL(url, 'POST', body);

/**
 * Perform a PUT request
 * @param {String} url the URL to query
 * @param {Object} body the request JSON data
 * @returns {Object} the response JSON data
 */
export const PUT = (url, body = {}) => fetchURL(url, 'PUT', body);

/**
 * Perform a DELETE request
 * @param {String} url the URL to query
 * @param {Object} querystring the query string to add to the URL
 */
export const DELETE = (url, querystring = {}) =>
  fetchURL(url, 'DELETE', querystring);

/**
 * Perform a POST request to upload a file
 * @param {String} url the URL to query
 * @param {Object} payload the payload
 * @returns {Object} the response JSON data
 */
export const RAW = (url, payload) => fetchURL(url, 'RAW', payload);
