import classNames from 'classnames/bind';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useQueryLocalEditor } from 'gql/queries/local';
import Icon from 'uikit/Icon';
import InputRich2 from 'uikit/InputRich2';
import Tooltip from 'uikit/Tooltip';

import styles from './QuestionTitleEditor.module.scss';

const cx = classNames.bind(styles);

const QuestionTitleEditor = () => {
  const {
    data: {
      editor: { questionType },
    },
  } = useQueryLocalEditor();

  const { t } = useTranslation('', {
    keyPrefix: `Questions/${questionType}/Editor`,
  });

  const IsTitleRequired = !['PDFOrPPT', 'Slide2'].includes(questionType);

  const isHelperRequired = ['HomeScreen', 'Interview'].includes(questionType);

  return (
    IsTitleRequired && (
      <InputRich2
        className={cx('title-input')}
        data-testid="title"
        helper={
          isHelperRequired && (
            <Tooltip tooltip={t('title-helper')}>
              <Icon name="info-circle" />
            </Tooltip>
          )
        }
        label={t('title-label')}
        name="title"
        placeholder={t('title-placeholder')}
      />
    )
  );
};

export default QuestionTitleEditor;
