import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'uikit/Button/Button';

import TEMPLATE1 from './images/bg-basic1.svg';
import TEMPLATE2 from './images/bg-basic2.svg';
import TEMPLATE3 from './images/bg-basic3.svg';
import TEMPLATE4 from './images/bg-basic4.svg';
import TEMPLATE5 from './images/bg-basic5.svg';
import TEMPLATE6 from './images/bg-basic6.svg';
import TEMPLATE7 from './images/bg-basic7.svg';
import TEMPLATE8 from './images/bg-basic8.svg';
import TEMPLATE9 from './images/bg-basic9.svg';
import TEMPLATE10 from './images/bg-basic10.svg';
import styles from './DesignToolbar.module.scss';

const cx = classNames.bind(styles);

const TEMPLATES = [
  TEMPLATE1,
  TEMPLATE2,
  TEMPLATE3,
  TEMPLATE4,
  TEMPLATE5,
  TEMPLATE6,
  TEMPLATE7,
  TEMPLATE8,
  TEMPLATE9,
  TEMPLATE10,
];

const DesignToolbar = ({
  className,
  handleTemplateChange,
  handleTemplatesClose,
  name,
  sideBarLimitsRef,
  sideBarRef,
  t,
}) => (
  <div
    className={cx('container-limits')}
    id={`sidebar-limits-${name}`}
    ref={sideBarLimitsRef}
  >
    <div
      className={cx('container', className)}
      id={`sidebar-${name}`}
      ref={sideBarRef}
    >
      <div className={cx('header')}>
        <Button
          className={cx('button')}
          icon="times"
          onClick={handleTemplatesClose}
          size="small"
          tabIndex={-1}
        />
        <p className={cx('title')}>
          {t('uikit/InputRich2/DesignToolbar/title')}
        </p>
      </div>
      <div className={cx('templates')}>
        {TEMPLATES.map((template, index) => (
          <button
            aria-label={t('uikit/InputRich2/DesignToolbar/template', { index })}
            className={cx('button-container')}
            onClick={() => handleTemplateChange(index)}
            key={index}
            tabIndex={-1}
            type="button"
          >
            <img
              alt=""
              className={cx('template-illustration')}
              src={template}
            />
          </button>
        ))}
      </div>
    </div>
  </div>
);

DesignToolbar.propTypes = {
  className: PropTypes.string,
  handleTemplateChange: PropTypes.func.isRequired,
  handleTemplatesClose: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  sideBarLimitsRef: PropTypes.object,
  sideBarRef: PropTypes.object,
  t: PropTypes.func.isRequired,
};

DesignToolbar.defaultProps = {
  className: undefined,
  sideBarLimitsRef: undefined,
  sideBarRef: undefined,
};

export default DesignToolbar;
