import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { apiUploadsS3 } from 'api/upload';
import { reportError } from 'lib/tracking';

import ImagePicker from './ImagePicker';

const ImagePickerContainer = ({
  current,
  handleModalClose,
  onSelect,
  ...props
}) => {
  const { t } = useTranslation();
  const [isDroppable, setDroppable] = useState(false);
  const handleDragEnter = useCallback((evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    setDroppable(true);
  }, []);
  const handleDragLeave = useCallback((evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    setDroppable(false);
  }, []);
  const [importedImage, setImportedImage] = useState(null);
  const [importedPreview, setImportedPreview] = useState(null);
  const handleImportedImageReset = useCallback(() => {
    if (importedPreview) {
      setImportedImage(null);
      setImportedPreview(null);
    }
  }, [importedPreview]);
  const handleLinkChange = useCallback(
    (event, setFieldValue) => {
      setFieldValue('link', event.target.value);
      handleImportedImageReset();
    },
    [handleImportedImageReset],
  );
  const handleLinkReset = useCallback((setFieldValue) => {
    setFieldValue('link', '');
  }, []);
  const handleImport = useCallback(async (evt, setFieldValue) => {
    evt.preventDefault();
    evt.stopPropagation();
    const files = evt.dataTransfer ? evt.dataTransfer.files : evt.target.files;
    setDroppable(false);
    if (!files || !files.length || !files[0]) {
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (e) => {
      setImportedImage(files[0]);
      setImportedPreview(e.target.result);
    };
    handleLinkReset(setFieldValue);
  }, [handleLinkReset]);
  const handleSelect = useCallback(
    async ({ link }) => {
      try {
        const linkValue = (link || '').trim();
        if (importedImage) {
          const filePath = await apiUploadsS3(importedImage);
          onSelect(filePath);
        } else if (linkValue) {
          onSelect(linkValue);
        } else {
          onSelect(current);
        }
        handleImportedImageReset();
      } catch (err) {
        reportError('image', 'upload', err);
      }
    },
    [current, handleImportedImageReset, importedImage, onSelect],
  );
  const handleModalCloseAndReset = useCallback(() => {
    handleImportedImageReset();
    handleModalClose();
  }, [handleImportedImageReset, handleModalClose]);
  return (
    <ImagePicker
      current={current}
      handleDragEnter={handleDragEnter}
      handleDragLeave={handleDragLeave}
      handleImport={handleImport}
      handleImportedImageReset={handleImportedImageReset}
      handleLinkChange={handleLinkChange}
      handleLinkReset={handleLinkReset}
      handleModalCloseAndReset={handleModalCloseAndReset}
      handleSelect={handleSelect}
      importedImage={importedImage}
      importedPreview={importedPreview}
      isDroppable={isDroppable}
      t={t}
      {...props}
    />
  );
};

ImagePickerContainer.propTypes = {
  current: PropTypes.string,
  handleModalClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

ImagePickerContainer.defaultProps = {
  current: undefined,
};

export default ImagePickerContainer;
