import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import AdaptiveImage from './images/adaptive.svg';
import LinearImage from './images/linear.svg';
import ExamImage from './images/examen.svg';
import styles from './CourseTypes.module.scss';

const cx = classNames.bind(styles);

const CourseTypes = ({
  className,
  current,
  display,
  handleTypeUpdate,
  isInSettings,
  t,
}) => (
  <div
    className={cx(
      'choices',
      {
        isSmall: display === 'small',
      },
      className,
    )}
  >
    <button
      className={cx('choice', 'adaptive', {
        isInSettings,
        isActive: current === 'adaptive',
        isInactive: current !== 'adaptive',
      })}
      data-testid="adaptive-course-button"
      onClick={() => handleTypeUpdate('adaptive')}
      type="button"
    >
      <div className={cx('recommended')}>
        {t('Course/Teacher/CourseMethod/recommended')}
      </div>
      <div className={cx('picture-container')}>
        <img className={cx('picture')} alt="" src={AdaptiveImage} />
      </div>
      <div className={cx('description')}>
        <h2 className={cx('choice-title')}>
          {t('Course/Teacher/CourseMethod/adaptive-title')}
        </h2>
        <p className={cx('choice-subtitle')}>
          {t('Course/Teacher/CourseMethod/adaptive-subtitle')}
        </p>
      </div>
    </button>
    <button
      className={cx('choice', 'linear', {
        isInSettings,
        isActive: current === 'linear',
        isInactive: current !== 'linear',
      })}
      data-testid="linear-course-button"
      onClick={() => handleTypeUpdate('linear')}
      type="button"
    >
      <div className={cx('picture-container')}>
        <img className={cx('picture')} alt="" src={LinearImage} />
      </div>
      <div className={cx('description')}>
        <h2 className={cx('choice-title')}>
          {t('Course/Teacher/CourseMethod/linear-title')}
        </h2>
        <p className={cx('choice-subtitle')}>
          {t('Course/Teacher/CourseMethod/linear-subtitle')}
        </p>
      </div>
    </button>
    <button
      className={cx('choice', 'exam', {
        isInSettings,
        isActive: current === 'exam',
        isInactive: current !== 'exam',
      })}
      onClick={() => handleTypeUpdate('exam')}
      type="button"
    >
      <div className={cx('picture-container')}>
        <img className={cx('picture')} alt="" src={ExamImage} />
      </div>
      <div className={cx('description')}>
        <h2 className={cx('choice-title')}>
          {t('Course/Teacher/CourseMethod/exam-title')}
        </h2>
        <p className={cx('choice-subtitle')}>
          {t('Course/Teacher/CourseMethod/exam-subtitle')}
        </p>
      </div>
    </button>
  </div>
);

CourseTypes.propTypes = {
  className: PropTypes.string,
  current: PropTypes.string,
  display: PropTypes.string,
  handleTypeUpdate: PropTypes.func.isRequired,
  isInSettings: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

CourseTypes.defaultProps = {
  className: undefined,
  current: undefined,
  display: 'large',
  isInSettings: false,
};

export default CourseTypes;
