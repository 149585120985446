import classNames from 'classnames/bind';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { apiUploadsS3 } from 'api/upload';
import EDITOR_STATUSES from 'constants/editorStatuses';
import { mutationEditorClear } from 'gql/mutations/editor';
import { useMutationFileCreate } from 'gql/mutations/file';
import { useQueryLocalEditor } from 'gql/queries/local';
import extract from 'helpers/extract';
import { trackEvent, reportError } from 'lib/tracking';
import Button from 'uikit/Button';
import InputFile from 'uikit/InputFile';
import { notify } from 'uikit/Notifications';
import Select from 'uikit/Select';
import SubPage from 'uikit/SubPage/SubPage';

import styles from './FileImport.module.scss';

const cx = classNames.bind(styles);

const FileImport = ({ course }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/Editor/FileImport',
  });

  const mutationFileCreate = useMutationFileCreate();

  const {
    data: {
      editor: { status: editorStatus },
    },
  } = useQueryLocalEditor();

  async function handleFileImport(values, form) {
    try {
      form.setStatus(null);
      const { file, chapterId } = extract(values, validationSchema);
      const filePath = await apiUploadsS3(file);
      await mutationFileCreate({
        courseId: course.id,
        chapterId,
        name: file.name,
        link: filePath,
      });
      notify('success', t('success'));
      mutationEditorClear();
      trackEvent('file', 'create');
    } catch (err) {
      if (err.message === '422') {
        form.setStatus('422');
      } else {
        form.setStatus('500');
      }
      reportError('file', 'create', err);
    }
  }

  const validationSchema = yup.object().shape({
    chapterId: yup
      .string()
      .trim()
      .matches(
        /^|([0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})$/i,
      ),
    file: yup.mixed().required(t('file-error-required')),
  });

  const isModalOpen = editorStatus === EDITOR_STATUSES.FILE_IMPORT;

  return (
    <SubPage
      isOpen={isModalOpen}
      onExit={mutationEditorClear}
      subtitle={course.title}
      title={t('title')}
    >
      <Formik
        enableReinitialize
        initialValues={{
          chapterId: '',
          file: null,
        }}
        onSubmit={handleFileImport}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, status }) => (
          <Form className={cx('subpage')}>
            {(status === '500' || status === '422') && (
              <p className={cx('error')} role="alert">
                {t(
                  status === '500'
                    ? 'file-import-error'
                    : 'unauthorized-file-type-error',
                )}
              </p>
            )}
            <Select
              className={cx('field')}
              icon="book-open"
              label={t('chapter-label')}
              name="chapterId"
            >
              <option value="">{t('no-chapter')}</option>
              {course.chapters.map((chapter) => (
                <option key={chapter.id} value={chapter.id}>
                  {chapter.title}
                </option>
              ))}
            </Select>
            <InputFile
              className={cx('field')}
              icon="file-code"
              label={t('file-label')}
              name="file"
            />
            <div className={cx('actions')}>
              <div />
              <Button
                className={cx('action')}
                icon="check"
                intent="primary"
                isLoading={isSubmitting}
                size="large"
                type="submit"
              >
                {t('save-file')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </SubPage>
  );
};

FileImport.propTypes = {
  course: PropTypes.object.isRequired,
};

export default FileImport;
