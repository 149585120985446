import classNames from 'classnames/bind';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'uikit/Button';
import InputText from 'uikit/InputText';
import Modal from 'uikit/Modal';

import styles from './FolderEdit.module.scss';

const cx = classNames.bind(styles);

const FolderEdit = ({
  handleClose,
  handleSave,
  initialValues,
  isModalOpen,
  t,
  validationSchema,
}) => (
  <Modal isOpen={isModalOpen} onExit={handleClose}>
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSave}
      validationSchema={validationSchema}
    >
      {({ isSubmitting, status }) => (
        <Form className={cx('modal', 'large')}>
          <Button
            aria-label={t('Dashboard/FolderEdit/aria-close')}
            className={cx('close')}
            icon="times"
            onClick={handleClose}
          />
          <h1 className={cx('title')}>{t('Dashboard/FolderEdit/title')}</h1>
          {status === '500' && (
            <p className={cx('error')} role="alert">
              {t('Dashboard/FolderEdit/folder-edit-error')}
            </p>
          )}
          <InputText
            autoFocus
            className={cx('field')}
            label={t('Dashboard/FolderEdit/title-label')}
            name="title"
            maxLength={256}
          />
          <Button
            className={cx('action')}
            intent="primary"
            isLoading={isSubmitting}
            size="large"
            type="submit"
          >
            {t('Dashboard/FolderEdit/save-folder')}
          </Button>
        </Form>
      )}
    </Formik>
  </Modal>
);

FolderEdit.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  validationSchema: PropTypes.object.isRequired,
};

export default FolderEdit;
