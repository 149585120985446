import classNames from 'classnames/bind';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'uikit/Button';
import InputText from 'uikit/InputText';

import InstitutionIcon from './images/institution.svg';
import styles from './InstitutionPopUp.module.scss';

const cx = classNames.bind(styles);

const InstitutionPopUp = ({
  handlePopUpClose,
  handleSubmit,
  t,
  validationSchema,
}) => (
  <div className={cx('container')}>
    <div className={cx('header')}>
      <div className={cx('icon-container')}>
        <img alt="" className={cx('icon')} src={InstitutionIcon} />
      </div>
      <Button
        className={cx('close')}
        icon="times"
        onClick={handlePopUpClose}
        size="small"
      />
    </div>
    <p className={cx('title')}>
      {t('Shared/MenuContainer/InstitutionPopUp/title')}
    </p>
    <p className={cx('subtitle')}>
      {t('Shared/MenuContainer/InstitutionPopUp/subtitle')}
    </p>
    <Formik
      initialValues={{
        institution: '',
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ isSubmitting }) => (
        <Form>
          <InputText
            className={cx('input')}
            name="institution"
            placeholder={t('Shared/MenuContainer/InstitutionPopUp/placeholder')}
          />
          <Button
            className={cx('submit')}
            intent="dark"
            isLoading={isSubmitting}
            size="large"
            type="submit"
          >
            {t('Shared/MenuContainer/InstitutionPopUp/submit')}
          </Button>
        </Form>
      )}
    </Formik>
  </div>
);

InstitutionPopUp.propTypes = {
  handlePopUpClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  validationSchema: PropTypes.object.isRequired,
};

export default InstitutionPopUp;
