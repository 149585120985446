import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import SubPage from 'uikit/SubPage/SubPage';

import styles from './QuestionCreateFromIA.module.scss';

const cx = classNames.bind(styles);

const QuestionCreateFromIA = ({
  data,
  handleClose,
  isLoading,
  isModalOpen,
  language,
}) => (
  <SubPage headerSize="default" isOpen={isModalOpen} onExit={handleClose}>
    {isLoading ? undefined : (
      <iframe
        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        className={cx('iframe')}
        frameBorder="0"
        height="315"
        src={`${process.env.REACT_APP_QUIZ_WIZARD_URL}/create-content/source?wooflash=true&language=${language}&authToken=${data?.woogen.token}`}
        title="YouTube video player"
        width="560"
      />
    )}
  </SubPage>
);

QuestionCreateFromIA.propTypes = {
  data: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired,
};

QuestionCreateFromIA.defaultProps = {
  data: undefined,
};
export default QuestionCreateFromIA;
