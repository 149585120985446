import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import BestPlayer from './BestPlayer';

import styles from './GamePodium.module.scss';

const cx = classNames.bind(styles);

const GamePodium = ({ className, game }) => (
  <div className={cx(className)}>
    {game.playStats?.ranking.length > 0 && (
      <div className={cx('podium')}>
        {game.playStats.ranking.map((player, index) => (
          <BestPlayer
            key={player.playSessionId}
            player={player}
            rank={index + 1}
            isLeaderBoardFull={game.playStats.ranking.length > 2}
          />
        ))}
      </div>
    )}
  </div>
);

GamePodium.propTypes = {
  className: PropTypes.string,
  game: PropTypes.object.isRequired,
};

GamePodium.defaultProps = {
  className: null,
};

export default GamePodium;
