import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useMutationCourseJoinByCode } from 'gql/mutations/course';
import history from 'lib/history';
import { trackEvent, reportError } from 'lib/tracking';

import CourseItem from './CourseItem';

const CourseItemContainer = ({ course, ...props }) => {
  const { t } = useTranslation();
  const mutationCourseJoinByCode = useMutationCourseJoinByCode();
  const handleCourseJoin = useCallback(async () => {
    try {
      const {
        data: { courseJoinByCode },
      } = await mutationCourseJoinByCode({
        accessCode: course.accessCode,
        from: 9,
      });
      history.push(`/course/${courseJoinByCode.id}/`);
      trackEvent('course', 'join');
    } catch (err) {
      reportError('course', 'join', err);
    }
  }, [course, mutationCourseJoinByCode]);
  return (
    <CourseItem
      course={course}
      handleCourseJoin={handleCourseJoin}
      t={t}
      {...props}
    />
  );
};

CourseItemContainer.propTypes = {
  course: PropTypes.object.isRequired,
};

export default CourseItemContainer;
