import { gql, useQuery } from '@apollo/client';

import {
  PROFILE_DATA,
  QUESTION_DATA,
  COMMENT_DATA,
  COMMENT_REPLY_DATA,
  STUDY_ANSWER_DATA,
} from 'gql/fragments';

export const QUERY_QUESTIONS_EXPERT = gql`
  query ($courseId: ID!, $questionId: ID) {
    question(courseId: $courseId, questionId: $questionId) {
      ...QuestionData
    }
  }

  ${QUESTION_DATA}
`;

export function useQueryQuestionsExpert(options) {
  const { loading, data, ...props } = useQuery(QUERY_QUESTIONS_EXPERT, options);
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

export const QUERY_QUESTION_COMMENTS = gql`
  query ($questionId: ID!) {
    question(questionId: $questionId) {
      ...QuestionData
      comments {
        ...CommentData
        account {
          ...ProfileData
        }
        studyAnswer {
          ...StudyAnswerData
        }
        commentReplies {
          ...CommentReplyData
          account {
            ...ProfileData
          }
        }
      }
    }
  }
  ${QUESTION_DATA}
  ${COMMENT_DATA}
  ${COMMENT_REPLY_DATA}
  ${PROFILE_DATA}
  ${STUDY_ANSWER_DATA}
`;

export function useQueryQuestionComments(options) {
  const { loading, data, ...props } = useQuery(
    QUERY_QUESTION_COMMENTS,
    options,
  );
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}
