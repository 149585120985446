import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useTranslation } from 'react-i18next';
import { useTable, useSortBy, useFilters } from 'react-table';

import { TextFilter, RangeFilter } from 'components/Shared/Filters';
import { useQueryResultsStudyQuestionStatistics } from 'gql/queries/results';
import { getQuestionInfo } from 'questions';
import CleanedText from 'uikit/CleanedText';
import HelpTooltip from 'uikit/HelpTooltip';
import Icon from 'uikit/Icon';

import styles from './Questions.module.scss';

const cx = classNames.bind(styles);

const LIMIT = 20;

const Questions = ({
  className = null,
  course,
  groupId = null,
  nQuestions,
  questions,
  studentId = null,
}) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/Statistics/Questions',
  });

  const [isSectionOpen, SetIsSectionOpen] = useState(false);

  const [questionsData, setQuestionsData] = useState(questions);

  const [hasMore, setHasMore] = useState(nQuestions > LIMIT);

  const [offset, setOffset] = useState(0);

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'index',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        accessor: 'type',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => {
          const questionInfo = getQuestionInfo(value);
          return <img alt="" className={cx('icon')} src={questionInfo.icon} />;
        },
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: t('_title'),
        accessor: 'title',
        Filter: TextFilter,
        filter: 'text',
      },
      {
        Header: t('rate'),
        accessor: 'rate',
        Cell: ({ value }) => `${value}%`,
        Filter: RangeFilter,
        filter: 'between',
      },
    ],
    [t],
  );

  const data = useMemo(
    () =>
      questionsData.map((question, index) => ({
        ...question,
        index: index + 1,
      })),
    [questionsData],
  );

  const filterTypes = useMemo(
    () => ({
      text: (rows, id, filterValue) =>
        rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .includes(String(filterValue).toLowerCase())
            : true;
        }),
    }),
    [],
  );

  const table = useTable(
    {
      columns,
      data,
      filterTypes,
    },
    useFilters,
    useSortBy,
  );

  const { fetchMore } = useQueryResultsStudyQuestionStatistics({
    variables: {
      offset,
      limit: LIMIT,
      courseId: course.id,
      groupId,
      studentId,
    },
  });

  useEffect(() => {
    setQuestionsData(questions);
  }, [questions]);

  const handleLoadMore = async () => {
    try {
      setHasMore(false);
      const {
        data: { resultsStudyQuestionStatistics },
      } = await fetchMore({
        variables: {
          offset: offset + LIMIT,
          limit: LIMIT,
        },
      });
      setQuestionsData([
        ...questionsData,
        ...resultsStudyQuestionStatistics.questions,
      ]);
      setOffset((o) => o + LIMIT);
      setHasMore(nQuestions > offset + LIMIT);
    } catch (err) {
      reportError('statistics questions', 'load more', err);
      setHasMore(false);
    }
  };

  const handleSectionToggle = () => {
    SetIsSectionOpen(!isSectionOpen);
  };

  return (
    <div className={cx('container', className, { isSectionOpen })}>
      <button
        className={cx('header')}
        onClick={handleSectionToggle}
        type="button"
      >
        <div className={cx('title-container')}>
          <Icon className={cx('title-icon')} name="question-circle" />
          <h2 className={cx('title')}>
            {t('title')}
            <span className={cx('count')}>{nQuestions}</span>
          </h2>
        </div>
        <Icon
          className={cx('toggle-button')}
          name={isSectionOpen ? 'chevron-up' : 'chevron-down'}
        />
      </button>
      {isSectionOpen && (
        <div className={cx('content')}>
          <InfiniteScroll
            pageStart={0}
            loadMore={handleLoadMore}
            hasMore={hasMore}
            useWindow={false}
          >
            <table className={cx('table')} {...table.getTableProps()}>
              <thead>
                {table.headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        <div
                          className={cx('header')}
                          {...column.getSortByToggleProps()}
                        >
                          {column.render('Header')}
                          {column.id === 'rate' && (
                            <HelpTooltip
                              className={cx('tooltip')}
                              tooltip={t('success-tooltip')}
                            />
                          )}
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...table.getTableBodyProps()}>
                {table.rows.map((row) => {
                  table.prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        if (
                          typeof cell.value === 'string' &&
                          cell.value.includes('$')
                        ) {
                          return (
                            <td className={cx('data')} {...cell.getCellProps()}>
                              <CleanedText>{cell.value}</CleanedText>
                            </td>
                          );
                        }
                        return (
                          <td className={cx('data')} {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </InfiniteScroll>
        </div>
      )}
    </div>
  );
};

Questions.propTypes = {
  className: PropTypes.string,
  course: PropTypes.object.isRequired,
  groupId: PropTypes.string,
  nQuestions: PropTypes.number.isRequired,
  questions: PropTypes.arrayOf(PropTypes.object).isRequired,
  studentId: PropTypes.string,
};

export default Questions;
