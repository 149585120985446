import { useField } from 'formik';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import Editor from './Editor';

const EditorContainer = (props) => {
  const { t } = useTranslation();
  const [{ value: categories }, , { setValue: setCategories }] =
    useField('categories');
  const handleCategoryAdd = useCallback(() => {
    setCategories([
      ...categories,
      {
        idx: categories.length,
        source: '',
        destinations: [{ idx: 0, title: '' }],
      },
    ]);
  }, [categories, setCategories]);
  const handleCategoryRemove = useCallback(
    (index) => {
      const newCategories = categories.filter((category, i) => i !== index);
      if (newCategories.length < 1) {
        newCategories.push({
          idx: newCategories.length,
          source: '',
          destinations: [{ idx: 0, title: '' }],
        });
      }
      setCategories(newCategories);
    },
    [categories, setCategories],
  );
  const handleDestinationAdd = useCallback(
    (categoryIndex, model) => {
      const newCategories = categories.map((category, index) =>
        categoryIndex === index
          ? {
              ...category,
              destinations: [
                ...category.destinations,
                {
                  idx: category.destinations.length,
                  title: model || '',
                },
              ],
            }
          : category,
      );
      setCategories(newCategories);
    },
    [categories, setCategories],
  );
  const handleDestinationRemove = useCallback(
    (categoryIndex, destinationIndex) => {
      const newCategories = categories.map((category, index) =>
        categoryIndex === index
          ? {
              ...category,
              destinations: category.destinations.filter(
                (a, i) => i !== destinationIndex,
              ),
            }
          : category,
      );
      if (newCategories[categoryIndex].destinations.length < 1) {
        newCategories[categoryIndex].destinations.push({
          idx: 0,
          title: '',
        });
      }
      setCategories(newCategories);
    },
    [categories, setCategories],
  );
  return (
    <Editor
      handleCategoryAdd={handleCategoryAdd}
      handleCategoryRemove={handleCategoryRemove}
      handleDestinationAdd={handleDestinationAdd}
      handleDestinationRemove={handleDestinationRemove}
      categories={categories}
      t={t}
      {...props}
    />
  );
};

EditorContainer.validationSchema = (t) =>
  yup.object().shape({
    tags: yup.array().of(yup.string().trim().lowercase().max(256)).compact(),
    title: yup
      .string()
      .trim()
      .transform(
        (v) => v || t('Questions/Categorization/Editor/default-title'),
      ),
    categories: yup
      .array()
      .of(
        yup.object().shape({
          idx: yup.number().required().integer().min(0),
          source: yup
            .string()
            .trim()
            .required(
              t('Questions/Categorization/Editor/categories-error-required'),
            ),
          destinations: yup
            .array()
            .of(
              yup.object().shape({
                idx: yup.number().required().integer().min(0),
                title: yup.string().trim().required(),
              }),
            )
            .test(
              'destinations length',
              t('Questions/Categorization/Editor/destinations-error-size'),
              (destinations) => destinations.length >= 1,
            ),
        }),
      )
      .compact()
      .transform((categories) =>
        categories
          .filter(
            (category) =>
              !!category.source &&
              category.destinations.length >= 1 &&
              category.destinations.every((destination) => !!destination.title),
          )
          .map((category, categoryIndex) => ({
            ...category,
            idx: categoryIndex,
            destinations: category.destinations.map(
              (destination, destinationIndex) => ({
                ...destination,
                idx:
                  categoryIndex === 0
                    ? destinationIndex
                    : categoryIndex * 100 + destinationIndex,
              }),
            ),
          })),
      )
      .test(
        'length',
        t('Questions/Categorization/Editor/categories-error-size'),
        (categories) => categories.length >= 2,
      ),
    feedback: yup.string().trim(),
  });

export default EditorContainer;
