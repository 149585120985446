import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { useQueryMe } from 'gql/queries/me';

import FinderGAR from './FinderGAR';

const FinderGARContainer = ({ course, ...props }) => {
  const { isLoading } = useQueryMe();
  const groups = useMemo(() => {
    if (isLoading) {
      return [];
    }
    const divs = (course.gar?.div || []).map((div) => {
      const [key, name] = div.split('##');
      return {
        title: `${name} (${key})`,
        students: course.students.filter((student) =>
          (student.metadata.garDIV || []).includes(div),
        ),
      };
    });
    const gros = (course.gar?.gro || []).map((gro) => {
      const [key, name] = gro.split('##');
      return {
        title: `${name} (${key})`,
        students: course.students.filter((student) =>
          (student.metadata.garGRO || []).includes(gro),
        ),
      };
    });
    return [...divs, ...gros];
  }, [isLoading, course]);
  return <FinderGAR course={course} groups={groups} {...props} />;
};

FinderGARContainer.propTypes = {
  course: PropTypes.object.isRequired,
};

export default FinderGARContainer;
