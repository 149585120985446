import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useQueryTestStatus } from 'gql/queries/test';
import useModal from 'hooks/useModal';

import ExamStart from './ExamStart';

const ExamStartContainer = ({ course, ...props }) => {
  const { t } = useTranslation();
  const { isModalOpen, handleModalOpen, handleModalClose } = useModal();
  const { isLoading, data } = useQueryTestStatus({
    variables: {
      courseId: course.id,
    },
    skip: !isModalOpen,
  });
  return (
    <ExamStart
      course={course}
      handleModalOpen={handleModalOpen}
      handleModalClose={handleModalClose}
      isLoading={isLoading}
      isModalOpen={isModalOpen}
      testStatus={data?.testStatus}
      t={t}
      {...props}
    />
  );
};

ExamStartContainer.propTypes = {
  course: PropTypes.object.isRequired,
};

export default ExamStartContainer;
