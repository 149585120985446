import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useMutationCourseDelete } from 'gql/mutations/course';
import useModal from 'hooks/useModal';
import history from 'lib/history';
import { trackEvent, reportError } from 'lib/tracking';
import { notify } from 'uikit/Notifications';

import CourseDelete from './CourseDelete';

const CourseDeleteContainer = ({ course, ...props }) => {
  const { t } = useTranslation();
  const mutationCourseDelete = useMutationCourseDelete();
  const [isLoading, setLoading] = useState(false);
  const [status, setStatus] = useState(undefined);
  const { isModalOpen, handleModalOpen, handleModalClose } = useModal(false);
  useEffect(() => {
    setStatus(undefined);
  }, [isModalOpen]);
  const handleCourseDelete = useCallback(
    async (evt) => {
      try {
        evt.preventDefault();
        evt.stopPropagation();
        setStatus(undefined);
        setLoading(true);
        await mutationCourseDelete(course);
        handleModalClose();
        notify(
          'success',
          t('Course/Teacher/Cover/CourseDelete/course-delete-success'),
        );
        history.push('/');
        trackEvent('course', 'delete');
      } catch (err) {
        setStatus('500');
        reportError('course', 'delete', err);
      } finally {
        setLoading(false);
      }
    },
    [course, handleModalClose, mutationCourseDelete, t],
  );
  return (
    <CourseDelete
      handleCourseDelete={handleCourseDelete}
      handleModalClose={handleModalClose}
      handleModalOpen={handleModalOpen}
      isLoading={isLoading}
      isModalOpen={isModalOpen}
      status={status}
      t={t}
      {...props}
    />
  );
};

CourseDeleteContainer.propTypes = {
  course: PropTypes.object.isRequired,
};

export default CourseDeleteContainer;
