import classNames from 'classnames/bind';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

import styles from './InputTextArray.module.scss';

const cx = classNames.bind(styles);

const InputTextArray = ({
  className,
  customStyles,
  "data-testid": dataTestId,
  disableCreation,
  helper,
  isDisabled,
  items,
  label,
  name,
  options,
  setItems,
  shouldHideError,
  t,
  ...props
}) => {
  const [, meta] = useField(name);
  return (
    <label
      className={cx(
        'container',
        {
          isDisabled,
          hasError: meta.touched && meta.error,
        },
        className,
      )}
      data-testid={dataTestId}
      htmlFor={name}
    >
      {(label || helper) && (
        <div className={cx('title')} id={`inputtextarray-${name}-label`}>
          <div className={cx('label')}>{label}</div>
          <div className={cx('helper')}>{helper}</div>
        </div>
      )}
      {!disableCreation ? (
        <CreatableSelect
          aria-labelledby={`inputtextarray-${name}-label`}
          defaultValue={items.map((item) => ({ value: item, label: item }))}
          formatCreateLabel={(inputValue) =>
            t('uikit/InputTextArray/create', { inputValue })
          }
          id={name}
          isMulti
          name={name}
          onBlur={(event) => {
            if (event.target.value !== '') {
              setItems([...items, event.target.value]);
            }
          }}
          onChange={(value) => {
            setItems(value.map((v) => v.value));
          }}
          onKeyDown={(event) => {
            if (
              event.key === 'Enter' &&
              (event.target.value === '' || items.includes(event.target.value))
            ) {
              event.preventDefault();
            }
          }}
          openMenuOnClick={false}
          options={options.map((option) => ({
            value: option,
            label: option,
          }))}
          placeholder={t('uikit/InputTextArray/create-or-select')}
          styles={customStyles}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: '#f5f1ff',
              primary: '#1d254f',
            },
          })}
          value={items.map((item) => ({ value: item, label: item }))}
          {...props}
        />
      ) : (
        <Select
          aria-labelledby={`inputtextarray-${name}-label`}
          styles={customStyles}
          defaultValue={items.map((item) => ({ value: item, label: item }))}
          id={name}
          isMulti
          name={name}
          onBlur={(event) => {
            if (event.target.value !== '') {
              setItems([...items, event.target.value]);
            }
          }}
          onChange={(value) => {
            setItems(value.map((v) => v.value));
          }}
          onKeyDown={(event) => {
            if (
              event.key === 'Enter' &&
              (event.target.value === '' || items.includes(event.target.value))
            ) {
              event.preventDefault();
            }
          }}
          openMenuOnClick={false}
          options={options.map((option) => ({
            value: option,
            label: option,
          }))}
          placeholder={t('uikit/InputTextArray/select')}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: '#f5f1ff',
              primary: '#1d254f',
            },
          })}
          value={items.map((item) => ({ value: item, label: item }))}
          {...props}
        />
      )}
      {!shouldHideError && meta.touched && meta.error && (
        <p className={cx('error-message')} id={`error-${name}`} role="alert">
          {meta.error}
        </p>
      )}
    </label>
  );
};

InputTextArray.propTypes = {
  className: PropTypes.string,
  customStyles: PropTypes.object.isRequired,
  disableCreation: PropTypes.bool,
  helper: PropTypes.node,
  isDisabled: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  setItems: PropTypes.func.isRequired,
  shouldHideError: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

InputTextArray.defaultProps = {
  className: undefined,
  disableCreation: false,
  helper: undefined,
  isDisabled: false,
  label: undefined,
  shouldHideError: false,
};

export default InputTextArray;
