import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { mutationEditorQuestionTypeChange } from 'gql/mutations/editor';

import styles from './QuestionTypeCard.module.scss';

const cx = classNames.bind(styles);

const QuestionTypeCard = ({
  className = null,
  highlightedQuestion,
  info,
  onQuestionHighlight,
}) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Shared/QuestionEdit/QuestionTypes/QuestionTypeCard',
  });

  const { t: tName } = useTranslation('');

  const handleQuestionCreate = () => {
    mutationEditorQuestionTypeChange(info.type);
  };

  return (
    <button
      className={cx('container', className, {
        highlighted: highlightedQuestion.type === info.type,
      })}
      data-testid={`create-${info.type}-button`}
      onClick={handleQuestionCreate}
      onMouseEnter={() => onQuestionHighlight(info)}
      type="button"
    >
      {info.isNew && <div className={cx('new')}>{t('new')}</div>}
      <div className={cx('picture-container')}>
        <img className={cx('picture')} alt="" src={info.picture} />
      </div>
      <p className={cx('title')}>{tName(info.name)}</p>
    </button>
  );
};

QuestionTypeCard.propTypes = {
  className: PropTypes.string,
  highlightedQuestion: PropTypes.object.isRequired,
  info: PropTypes.object.isRequired,
  onQuestionHighlight: PropTypes.func.isRequired,
};

export default QuestionTypeCard;
