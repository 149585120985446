import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './Theme.module.scss';

const cx = classNames.bind(styles);

const Theme = ({ className, theme, handleThemeUpdate, isActive, t }) => (
  // eslint-disable-next-line jsx-a11y/control-has-associated-label
  <button
    aria-label={t(`Course/Teacher/Cover/CourseTheme/Theme/${theme}`)}
    className={cx(
      'container',
      `background-${theme}`,
      {
        isActive,
      },
      className,
    )}
    onClick={handleThemeUpdate}
    type="button"
  >
    Tt
  </button>
);

Theme.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.string.isRequired,
  handleThemeUpdate: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

Theme.defaultProps = {
  className: undefined,
};

export default Theme;
