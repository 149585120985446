import React, { useMemo } from 'react';
import { useParams } from 'react-router';

import { useQueryCourseProgress } from 'gql/queries/course';
import { useURLToStateQuestionCommentOpenQuestion } from 'hooks/useURLToState';

import Progress from './Progress';

const ProgressContainer = (props) => {
  const { courseId } = useParams();
  const { isLoading, data } = useQueryCourseProgress({
    variables: {
      courseId,
    },
  });
  const course = data?.course;
  const hasStatistics = useMemo(
    () =>
      course &&
      []
        .concat(course.questions, ...course.chapters.map((c) => c.questions))
        .filter((q) => q.studyStats.attempted).length > 0,
    [course],
  );
  useURLToStateQuestionCommentOpenQuestion(isLoading, data);
  return (
    <Progress
      course={course}
      hasStatistics={hasStatistics}
      isLoading={isLoading}
      {...props}
    />
  );
};

export default ProgressContainer;
