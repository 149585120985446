import t from 'helpers/t';

import Aggregate from './Aggregate';
import Editor from './Editor';
import Recto from './Recto';
import Verso from './Verso';
import svg from './icon.svg';
import picture from './picture.svg';

const Categorization = {
  info: {
    type: 'Categorization',
    name: t('Questions/Categorization/name'),
    tooltip: t('Questions/Categorization/tooltip'),
    icon: svg,
    picture,
    color: 'blue',
    isCorrectable: true,
    isOneSided: false,
    isInScore: true,
    isReportable: true,
    study: true,
    play: true,
    test: true,
    isNew: true,
    canFullForceCorrect: false,
    canPartialForceCorrect: false,
  },
  Aggregate,
  Editor,
  Recto,
  Verso,
};

export default Categorization;
