import classNames from 'classnames/bind';
import THEMES from 'constants/themes';
import PropTypes from 'prop-types';
import React from 'react';

import ArtImage from 'images/categories/art.svg';
import ComputerScienceImage from 'images/categories/computer-science.svg';
import HealthImage from 'images/categories/health.svg';
import LanguageImage from 'images/categories/language.svg';
import ManagementImage from 'images/categories/management.svg';
import MathematicsImage from 'images/categories/mathematics.svg';
import OtherImage from 'images/categories/other.svg';
import ScienceImage from 'images/categories/science.svg';
import TutorialIllustration1 from 'images/tutorials/illustrationTutorial1.svg';
import TutorialIllustration2 from 'images/tutorials/illustrationTutorial2.svg';
import Icon from 'uikit/Icon';
import Menu from 'uikit/Menu';
import Tooltip from 'uikit/Tooltip';

import Theme from './Theme';
import styles from './CourseTheme.module.scss';

const cx = classNames.bind(styles);

const IMAGES = {
  art: ArtImage,
  'computer-science': ComputerScienceImage,
  health: HealthImage,
  language: LanguageImage,
  management: ManagementImage,
  mathematics: MathematicsImage,
  other: OtherImage,
  science: ScienceImage,
};

const CourseTheme = ({
  className,
  course,
  handleThemeUpdate,
  needTutorialIllustration1,
  needTutorialIllustration2,
  t,
}) => (
  <>
    <Menu
      placement="bottom-start"
      toggle={
        <button
          aria-label={t('Course/Teacher/Cover/CourseTheme/aria-edit-theme')}
          className={cx('button', `theme-${course.themeBackground}`, className)}
          type="button"
        >
          <Tooltip
            tooltip={t('Course/Teacher/Cover/CourseTheme/aria-edit-theme')}
          >
            <div
              className={cx('image', {
                isTutorial2: needTutorialIllustration2,
              })}
              style={{
                backgroundImage: `url(${
                  needTutorialIllustration1
                    ? TutorialIllustration1
                    : needTutorialIllustration2
                    ? TutorialIllustration2
                    : IMAGES[course.category] || IMAGES.other
                })`,
              }}
            />
          </Tooltip>
          <div className={cx('pencil-container')}>
            <Icon className={cx('pencil')} name="pencil" />
          </div>
        </button>
      }
    >
      <div className={cx('menu')}>
        {THEMES.map((theme) => (
          <Theme
            className={cx('theme')}
            theme={theme}
            handleThemeUpdate={() => handleThemeUpdate(theme)}
            isActive={
              course.themeBackground === theme ||
              (theme === 'purple' && !THEMES.includes(course.themeBackground))
            }
            key={theme}
          />
        ))}
      </div>
    </Menu>
  </>
);

CourseTheme.propTypes = {
  className: PropTypes.string,
  course: PropTypes.object.isRequired,
  handleThemeUpdate: PropTypes.func.isRequired,
  needTutorialIllustration1: PropTypes.bool.isRequired,
  needTutorialIllustration2: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

CourseTheme.defaultProps = {
  className: undefined,
};

export default CourseTheme;
