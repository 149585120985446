import PROPERTIES_TO_SAVE from '../data/propertiesToSave';

const history = [];
const HISTORY_LIMIT = 20;

export const saveHistory = (fabricRef, stepToSave) => {
  const jsonToSave =
    stepToSave && stepToSave !== history[history.length - 1]
      ? stepToSave
      : JSON.stringify(fabricRef.current.toJSON(PROPERTIES_TO_SAVE)) !==
        history[history.length - 1]
      ? JSON.stringify(fabricRef.current.toJSON(PROPERTIES_TO_SAVE))
      : null;
  if (jsonToSave) {
    history.push(jsonToSave);
    if (history.length > HISTORY_LIMIT) {
      history.shift();
    }
  }
};

export const undo = (fabricRef) => {
  const lastStepContent = history[history.length - 1];
  if (lastStepContent) {
    fabricRef.current.loadFromJSON(lastStepContent);
    history.pop();
  }
};

export const clearHistory = () => {
  history.length = 0;
};
