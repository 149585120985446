import t from 'helpers/t';

import {
  TITLE_HEIGHT,
  TITLE_WIDTH,
  SUBTITLE_HEIGHT,
  TEXT_HEIGHT,
  TEXT_WIDTH,
  HEIGHT,
  WIDTH,
} from './constant';

import IMAGE_PLACEHOLDER_LARGE from '../images/imagePlaceholderLarge.svg';
import IMAGE_PLACEHOLDER_SMALL from '../images/imagePlaceholderSmall.svg';
import MASCOTTE_1 from '../images/templates/mascotte1.svg';
import MASCOTTE_2 from '../images/templates/mascotte2.svg';
import MASCOTTE_3 from '../images/templates/mascotte3.svg';
import MASCOTTE_4 from '../images/templates/mascotte4.svg';
import MASCOTTE_5 from '../images/templates/mascotte5.svg';
import MASCOTTE_6 from '../images/templates/mascotte6.svg';
import MASCOTTE_7 from '../images/templates/mascotte7.svg';
import MASCOTTE_8 from '../images/templates/mascotte8.svg';
import MASCOTTE_9 from '../images/templates/mascotte9.svg';
import MASCOTTE_10 from '../images/templates/mascotte10.svg';
import MASCOTTE_11 from '../images/templates/mascotte11.svg';
import MASCOTTE_12 from '../images/templates/mascotte12.svg';
import MASCOTTE_13 from '../images/templates/mascotte13.svg';
import BG_ADVANCED1 from '../images/templates/bg-advanced1.svg';
import BG_ADVANCED2 from '../images/templates/bg-advanced2.svg';
import BG_ADVANCED3 from '../images/templates/bg-advanced3.svg';
import BG_ADVANCED4 from '../images/templates/bg-advanced4.svg';
import BG_ADVANCED5 from '../images/templates/bg-advanced5.svg';
import BG_ADVANCED6 from '../images/templates/bg-advanced6.svg';
import BG_ADVANCED7 from '../images/templates/bg-advanced7.svg';
import BG_ADVANCED8 from '../images/templates/bg-advanced8.svg';
import BG_ADVANCED9 from '../images/templates/bg-advanced9.svg';
import BG_ADVANCED10 from '../images/templates/bg-advanced10.svg';
import BG_ADVANCED11 from '../images/templates/bg-advanced11.svg';
import BG_BASIC1 from '../images/templates/bg-basic1.svg';
import BG_BASIC2 from '../images/templates/bg-basic2.svg';
import BG_BASIC3 from '../images/templates/bg-basic3.svg';
import BG_BASIC4 from '../images/templates/bg-basic4.svg';
import BG_BASIC5 from '../images/templates/bg-basic5.svg';
import BG_BASIC6 from '../images/templates/bg-basic6.svg';
import BG_BASIC7 from '../images/templates/bg-basic7.svg';
import BG_BASIC8 from '../images/templates/bg-basic8.svg';
import BG_BASIC9 from '../images/templates/bg-basic9.svg';
import BG_BASIC10 from '../images/templates/bg-basic10.svg';

import DEFAULT_CANVAS_PROPERTIES from './defaultCanvasProperties';

const TEMPLATES = [
  {
    version: '5.3.0',
    illustration: BG_BASIC1,
    backgroundColor: '#fff',
    textBoxes: [
      {
        ...DEFAULT_CANVAS_PROPERTIES.titleProps,
        text: t('Questions/Shared/SlideBuilder/TextToolbar/title'),
      },
    ],
  },
  {
    version: '5.3.0',
    illustration: BG_BASIC2,
    backgroundColor: '#fff',
    textBoxes: [
      {
        ...DEFAULT_CANVAS_PROPERTIES.titleProps,
        text: t('Questions/Shared/SlideBuilder/TextToolbar/title'),
        top: HEIGHT / 2.5 - TITLE_HEIGHT / 2,
      },
      {
        ...DEFAULT_CANVAS_PROPERTIES.subtitleProps,
        text: t('Questions/Shared/SlideBuilder/TextToolbar/subtitle'),
        top: HEIGHT / 1.5 - SUBTITLE_HEIGHT / 2,
      },
    ],
  },
  {
    version: '5.3.0',
    illustration: BG_BASIC3,
    backgroundColor: '#fff',
    textBoxes: [
      {
        ...DEFAULT_CANVAS_PROPERTIES.titleProps,
        text: t('Questions/Shared/SlideBuilder/TextToolbar/title'),
        top: HEIGHT / 2.5 - TITLE_HEIGHT / 2,
      },
      {
        ...DEFAULT_CANVAS_PROPERTIES.textProps,
        text: 'Lorem ipsum dolor sit amet,\nconsectetur adipiscing elit,\ndo eiusmod tempor',
        top: HEIGHT / 1.5 - TEXT_HEIGHT / 2,
        width: 250,
        textAlign: 'center',
      },
    ],
  },
  {
    version: '5.3.0',
    illustration: BG_BASIC4,
    backgroundColor: '#fff',
    textBoxes: [
      {
        ...DEFAULT_CANVAS_PROPERTIES.titleProps,
        text: t('Questions/Shared/SlideBuilder/TextToolbar/title'),
        top: HEIGHT / 3 - TITLE_HEIGHT / 2,
        left: WIDTH / 4 - TITLE_WIDTH / 2,
      },
      {
        ...DEFAULT_CANVAS_PROPERTIES.textProps,
        text: 'Lorem ipsum dolor sit',
        top: HEIGHT / 4.1 - TEXT_HEIGHT / 2,
        left: WIDTH / 1.4 - TEXT_WIDTH / 2,
        textAlign: 'left',
      },
      {
        ...DEFAULT_CANVAS_PROPERTIES.textProps,
        text: 'Lorem ipsum dolor sit',
        top: HEIGHT / 2.6 - TEXT_HEIGHT / 2,
        left: WIDTH / 1.4 - TEXT_WIDTH / 2,
        textAlign: 'left',
      },
      {
        ...DEFAULT_CANVAS_PROPERTIES.textProps,
        text: 'Lorem ipsum dolor sit',
        top: HEIGHT / 1.9 - TEXT_HEIGHT / 2,
        left: WIDTH / 1.4 - TEXT_WIDTH / 2,
        textAlign: 'left',
      },
      {
        ...DEFAULT_CANVAS_PROPERTIES.textProps,
        text: 'Lorem ipsum dolor sit',
        top: HEIGHT / 1.5 - TEXT_HEIGHT / 2,
        left: WIDTH / 1.4 - TEXT_WIDTH / 2,
        textAlign: 'left',
      },
      {
        ...DEFAULT_CANVAS_PROPERTIES.textProps,
        text: 'Lorem ipsum dolor sit',
        top: HEIGHT / 1.24 - TEXT_HEIGHT / 2,
        left: WIDTH / 1.4 - TEXT_WIDTH / 2,
        textAlign: 'left',
      },
    ],
  },
  {
    version: '5.3.0',
    illustration: BG_BASIC5,
    backgroundColor: '#fff',
    textBoxes: [
      {
        ...DEFAULT_CANVAS_PROPERTIES.titleProps,
        text: t('Questions/Shared/SlideBuilder/TextToolbar/title'),
        top: HEIGHT / 3 - TITLE_HEIGHT / 2,
      },
      {
        ...DEFAULT_CANVAS_PROPERTIES.textProps,
        text: 'Lorem ipsum dolor sit amet',
        top: HEIGHT / 1.5 - TEXT_HEIGHT / 2,
        left: WIDTH / 25,
        width: 180,
        textAlign: 'center',
      },
      {
        ...DEFAULT_CANVAS_PROPERTIES.textProps,
        text: 'Lorem ipsum dolor sit amet',
        top: HEIGHT / 1.5 - TEXT_HEIGHT / 2,
        left: WIDTH / 2.8,
        width: 180,
        textAlign: 'center',
      },
      {
        ...DEFAULT_CANVAS_PROPERTIES.textProps,
        text: 'Lorem ipsum dolor sit amet',
        top: HEIGHT / 1.5 - TEXT_HEIGHT / 2,
        left: WIDTH / 1.47,
        width: 180,
        textAlign: 'center',
      },
    ],
  },
  {
    version: '5.3.0',
    illustration: BG_BASIC6,
    backgroundColor: '#fff',
    textBoxes: [
      {
        ...DEFAULT_CANVAS_PROPERTIES.titleProps,
        text: t('Questions/Shared/SlideBuilder/TextToolbar/title'),
        top: HEIGHT / 3 - TITLE_HEIGHT / 2,
      },
      {
        ...DEFAULT_CANVAS_PROPERTIES.textProps,
        text: 'Lorem ipsum dolor sit amet',
        top: HEIGHT / 1.3 - TEXT_HEIGHT / 2,
        left: WIDTH / 6.2 - 180 / 2,
        width: 180,
        textAlign: 'center',
      },
      {
        ...DEFAULT_CANVAS_PROPERTIES.textProps,
        text: 'Lorem ipsum dolor sit amet',
        top: HEIGHT / 1.3 - TEXT_HEIGHT / 2,
        left: WIDTH / 2 - 180 / 2,
        width: 180,
        textAlign: 'center',
      },
      {
        ...DEFAULT_CANVAS_PROPERTIES.textProps,
        text: 'Lorem ipsum dolor sit amet',
        top: HEIGHT / 1.3 - TEXT_HEIGHT / 2,
        left: WIDTH / 1.2 - 180 / 2,
        width: 180,
        textAlign: 'center',
      },
    ],
    objects: [
      {
        ...DEFAULT_CANVAS_PROPERTIES.bulletGroupProps,
        top: HEIGHT / 1.65,
        left: WIDTH / 7,
        objects: [
          {
            ...DEFAULT_CANVAS_PROPERTIES.bulletCircleProps,
            originX: 'left',
            originY: 'top',
            top: -20,
            left: -20,
          },
          {
            ...DEFAULT_CANVAS_PROPERTIES.bulletNumberProps,
            text: '1',
          },
        ],
      },
      {
        ...DEFAULT_CANVAS_PROPERTIES.bulletGroupProps,
        top: HEIGHT / 1.65,
        left: WIDTH / 2,
        objects: [
          {
            ...DEFAULT_CANVAS_PROPERTIES.bulletCircleProps,
            originX: 'left',
            originY: 'top',
            top: -20,
            left: -20,
          },
          {
            ...DEFAULT_CANVAS_PROPERTIES.bulletNumberProps,
            text: '2',
          },
        ],
      },
      {
        ...DEFAULT_CANVAS_PROPERTIES.bulletGroupProps,
        top: HEIGHT / 1.65,
        left: WIDTH / 1.2,
        objects: [
          {
            ...DEFAULT_CANVAS_PROPERTIES.bulletCircleProps,
            originX: 'left',
            originY: 'top',
            top: -20,
            left: -20,
          },
          {
            ...DEFAULT_CANVAS_PROPERTIES.bulletNumberProps,
            text: '3',
          },
        ],
      },
    ],
  },
  {
    version: '5.3.0',
    illustration: BG_BASIC7,
    backgroundColor: '#fff',
    textBoxes: [
      {
        ...DEFAULT_CANVAS_PROPERTIES.titleProps,
        text: t('Questions/Shared/SlideBuilder/TextToolbar/title'),
        top: HEIGHT / 4.3 - TITLE_HEIGHT / 2,
        left: WIDTH / 6 - TITLE_WIDTH / 2,
      },
      {
        ...DEFAULT_CANVAS_PROPERTIES.textProps,
        text: 'Lorem ipsum dolor sit',
        top: HEIGHT / 4.1 - TEXT_HEIGHT / 2,
        left: WIDTH / 1.4 - TEXT_WIDTH / 2,
        textAlign: 'left',
      },
      {
        ...DEFAULT_CANVAS_PROPERTIES.textProps,
        text: 'Lorem ipsum dolor sit',
        top: HEIGHT / 2.6 - TEXT_HEIGHT / 2,
        left: WIDTH / 1.4 - TEXT_WIDTH / 2,
        textAlign: 'left',
      },
      {
        ...DEFAULT_CANVAS_PROPERTIES.textProps,
        text: 'Lorem ipsum dolor sit',
        top: HEIGHT / 1.9 - TEXT_HEIGHT / 2,
        left: WIDTH / 1.4 - TEXT_WIDTH / 2,
        textAlign: 'left',
      },
      {
        ...DEFAULT_CANVAS_PROPERTIES.textProps,
        text: 'Lorem ipsum dolor sit',
        top: HEIGHT / 1.5 - TEXT_HEIGHT / 2,
        left: WIDTH / 1.4 - TEXT_WIDTH / 2,
        textAlign: 'left',
      },
      {
        ...DEFAULT_CANVAS_PROPERTIES.textProps,
        text: 'Lorem ipsum dolor sit',
        top: HEIGHT / 1.24 - TEXT_HEIGHT / 2,
        left: WIDTH / 1.4 - TEXT_WIDTH / 2,
        textAlign: 'left',
      },
    ],
    objects: [
      {
        ...DEFAULT_CANVAS_PROPERTIES.bulletGroupProps,
        top: HEIGHT / 4.1,
        left: WIDTH / 2.25,
        objects: [
          {
            ...DEFAULT_CANVAS_PROPERTIES.bulletCircleProps,
            originX: 'left',
            originY: 'top',
            top: -20,
            left: -20,
          },
          {
            ...DEFAULT_CANVAS_PROPERTIES.bulletNumberProps,
            text: '1',
          },
        ],
      },

      {
        ...DEFAULT_CANVAS_PROPERTIES.bulletGroupProps,
        top: HEIGHT / 2.6,
        left: WIDTH / 2.25,
        objects: [
          {
            ...DEFAULT_CANVAS_PROPERTIES.bulletCircleProps,
            originX: 'left',
            originY: 'top',
            top: -20,
            left: -20,
          },
          {
            ...DEFAULT_CANVAS_PROPERTIES.bulletNumberProps,
            text: '2',
          },
        ],
      },

      {
        ...DEFAULT_CANVAS_PROPERTIES.bulletGroupProps,
        top: HEIGHT / 1.9,
        left: WIDTH / 2.25,
        objects: [
          {
            ...DEFAULT_CANVAS_PROPERTIES.bulletCircleProps,
            originX: 'left',
            originY: 'top',
            top: -20,
            left: -20,
          },
          {
            ...DEFAULT_CANVAS_PROPERTIES.bulletNumberProps,
            text: '3',
          },
        ],
      },

      {
        ...DEFAULT_CANVAS_PROPERTIES.bulletGroupProps,
        top: HEIGHT / 1.5,
        left: WIDTH / 2.25,
        objects: [
          {
            ...DEFAULT_CANVAS_PROPERTIES.bulletCircleProps,
            originX: 'left',
            originY: 'top',
            top: -20,
            left: -20,
          },
          {
            ...DEFAULT_CANVAS_PROPERTIES.bulletNumberProps,
            text: '4',
          },
        ],
      },

      {
        ...DEFAULT_CANVAS_PROPERTIES.bulletGroupProps,
        top: HEIGHT / 1.24,
        left: WIDTH / 2.25,
        objects: [
          {
            ...DEFAULT_CANVAS_PROPERTIES.bulletCircleProps,
            originX: 'left',
            originY: 'top',
            top: -20,
            left: -20,
          },
          {
            ...DEFAULT_CANVAS_PROPERTIES.bulletNumberProps,
            text: '5',
          },
        ],
      },
    ],
  },
  {
    version: '5.3.0',
    illustration: BG_BASIC8,
    backgroundColor: '#fff',
    textBoxes: [
      {
        ...DEFAULT_CANVAS_PROPERTIES.titleProps,
        text: t('Questions/Shared/SlideBuilder/TextToolbar/title'),
        top: HEIGHT / 3.5 - TITLE_HEIGHT / 2,
        left: WIDTH / 8 - TITLE_WIDTH / 2,
      },
      {
        ...DEFAULT_CANVAS_PROPERTIES.textProps,
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, do eiusmod tempor incididu ut labore et dolore magna aliqua',
        top: HEIGHT / 2.4,
        left: WIDTH / 3.85 - TEXT_WIDTH / 2,
        width: 230,
      },
    ],
    objects: [
      {
        ...DEFAULT_CANVAS_PROPERTIES.imagePlaceholderProps,
        left: WIDTH / 2,
        top: 0,
        width: WIDTH / 2,
        height: HEIGHT / 1,
        src: IMAGE_PLACEHOLDER_SMALL,
      },
    ],
  },
  {
    version: '5.3.0',
    illustration: BG_BASIC9,
    backgroundColor: '#fff',
    textBoxes: [
      {
        ...DEFAULT_CANVAS_PROPERTIES.titleProps,
        text: t('Questions/Shared/SlideBuilder/TextToolbar/title'),
        top: HEIGHT / 3.5 - TITLE_HEIGHT / 2,
        left: WIDTH / 1.58 - TITLE_WIDTH / 2,
      },
      {
        ...DEFAULT_CANVAS_PROPERTIES.textProps,
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, do eiusmod tempor incididu ut labore et dolore magna aliqua',
        top: HEIGHT / 2.4,
        left: WIDTH / 1.31 - TEXT_WIDTH / 2,
        width: 230,
      },
    ],
    objects: [
      {
        ...DEFAULT_CANVAS_PROPERTIES.imagePlaceholderProps,
        left: 0,
        top: 0,
        width: WIDTH / 2,
        height: HEIGHT / 1,
        src: IMAGE_PLACEHOLDER_SMALL,
      },
    ],
  },
  {
    version: '5.3.0',
    illustration: BG_BASIC10,
    backgroundColor: '#fff',
    objects: [
      {
        ...DEFAULT_CANVAS_PROPERTIES.imagePlaceholderProps,
        left: 0,
        top: 0,
        width: WIDTH,
        height: HEIGHT,
        src: IMAGE_PLACEHOLDER_LARGE,
      },
    ],
  },
  {
    version: '5.3.0',
    backgroundImage: MASCOTTE_1,
    backgroundColor: '#906ef0',
    textBoxes: [
      {
        ...DEFAULT_CANVAS_PROPERTIES.titleProps,
        text: t('Questions/Shared/SlideBuilder/TextToolbar/title'),
        top: HEIGHT / 3 - TITLE_HEIGHT / 2,
        left: WIDTH / 3.2 - TITLE_WIDTH / 2,
      },
    ],
  },
  {
    version: '5.3.0',
    backgroundImage: MASCOTTE_2,
    backgroundColor: '#906ef0',
    textBoxes: [
      {
        ...DEFAULT_CANVAS_PROPERTIES.titleProps,
        text: t('Questions/Shared/SlideBuilder/TextToolbar/title'),
        top: HEIGHT / 3 - TITLE_HEIGHT / 2,
        left: WIDTH / 3.2 - TITLE_WIDTH / 2,
      },
    ],
  },
  {
    version: '5.3.0',
    backgroundImage: MASCOTTE_3,
    backgroundColor: '#906ef0',
    textBoxes: [
      {
        ...DEFAULT_CANVAS_PROPERTIES.textProps,
        text: '“ Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua adipiscing elit, sed do eiusmod tempor incididunt eiusmod tempor incididunt ut labore et dolore. “',
        top: HEIGHT / 8,
        left: WIDTH / 2,
        width: 250,
      },
    ],
  },
  {
    version: '5.3.0',
    backgroundImage: MASCOTTE_4,
    backgroundColor: '#906ef0',
    textBoxes: [
      {
        ...DEFAULT_CANVAS_PROPERTIES.textProps,
        text: '“ Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua adipiscing elit, sed do eiusmod tempor incididunt eiusmod tempor incididunt ut labore et dolore. “',
        top: HEIGHT / 8,
        left: WIDTH / 2,
        width: 250,
      },
    ],
  },
  {
    version: '5.3.0',
    backgroundImage: MASCOTTE_5,
    backgroundColor: '#906ef0',
    textBoxes: [
      {
        ...DEFAULT_CANVAS_PROPERTIES.titleProps,
        text: t('Questions/Shared/SlideBuilder/TextToolbar/title'),
        top: HEIGHT / 3.5 - TITLE_HEIGHT / 2,
      },
      {
        ...DEFAULT_CANVAS_PROPERTIES.textProps,
        text: t('Questions/Shared/SlideBuilder/TextToolbar/text'),
        top: HEIGHT / 2.1 - TEXT_HEIGHT / 2,
      },
    ],
  },
  {
    version: '5.3.0',
    backgroundImage: MASCOTTE_6,
    backgroundColor: '#906ef0',
    textBoxes: [
      {
        ...DEFAULT_CANVAS_PROPERTIES.titleProps,
        text: t('Questions/Shared/SlideBuilder/TextToolbar/title'),
        top: HEIGHT / 3.5 - TITLE_HEIGHT / 2,
      },
      {
        ...DEFAULT_CANVAS_PROPERTIES.textProps,
        text: t('Questions/Shared/SlideBuilder/TextToolbar/text'),
        top: HEIGHT / 2.1 - TEXT_HEIGHT / 2,
      },
    ],
  },
  {
    version: '5.3.0',
    backgroundImage: MASCOTTE_7,
    backgroundColor: '#906ef0',
    textBoxes: [
      {
        ...DEFAULT_CANVAS_PROPERTIES.titleProps,
        text: t('Questions/Shared/SlideBuilder/TextToolbar/title'),
        top: HEIGHT / 5 - TITLE_HEIGHT / 2,
        left: WIDTH / 4 - TITLE_WIDTH / 2,
      },
      {
        ...DEFAULT_CANVAS_PROPERTIES.textProps,
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
        top: HEIGHT / 3.5,
        left: WIDTH / 16,
        width: 230,
      },
      {
        ...DEFAULT_CANVAS_PROPERTIES.titleProps,
        text: t('Questions/Shared/SlideBuilder/TextToolbar/title'),
        top: HEIGHT / 5 - TITLE_HEIGHT / 2,
        left: WIDTH / 1.35 - TITLE_WIDTH / 2,
      },
      {
        ...DEFAULT_CANVAS_PROPERTIES.textProps,
        text: 'Lorem ipsum dolor sit\namet, consectetur\nadipiscing elit, sed do\neiusmod tempor\nincididunt ut labore et\ndolore magna aliqua\n',
        textAlign: 'right',
        top: HEIGHT / 3.5,
        left: WIDTH / 2,
        width: 230,
      },
    ],
  },
  {
    version: '5.3.0',
    backgroundImage: MASCOTTE_8,
    backgroundColor: '#906ef0',
    textBoxes: [
      {
        ...DEFAULT_CANVAS_PROPERTIES.titleProps,
        text: 'Lorem ipsum dolor sit amet',
        textAlign: 'center',
        top: HEIGHT / 3 - TEXT_HEIGHT / 2,
        left: WIDTH / 2 - 300 / 2,
        width: 300,
      },
    ],
  },
  {
    version: '5.3.0',
    backgroundImage: MASCOTTE_9,
    backgroundColor: '#906ef0',
    textBoxes: [
      {
        ...DEFAULT_CANVAS_PROPERTIES.titleProps,
        text: t('Questions/Shared/SlideBuilder/TextToolbar/title'),
        top: HEIGHT / 3.2 - TITLE_HEIGHT / 2,
      },
    ],
  },
  {
    version: '5.3.0',
    backgroundImage: MASCOTTE_10,
    backgroundColor: '#906ef0',
    textBoxes: [
      {
        ...DEFAULT_CANVAS_PROPERTIES.titleProps,
        text: t('Questions/Shared/SlideBuilder/TextToolbar/title'),
        top: HEIGHT / 3.2 - TITLE_HEIGHT / 2,
      },
    ],
  },
  {
    version: '5.3.0',
    backgroundImage: MASCOTTE_11,
    backgroundColor: '#906ef0',
    textBoxes: [
      {
        ...DEFAULT_CANVAS_PROPERTIES.titleProps,
        text: t('Questions/Shared/SlideBuilder/TextToolbar/title'),
        top: HEIGHT / 3.2 - TITLE_HEIGHT / 2,
      },
    ],
  },
  {
    version: '5.3.0',
    backgroundImage: MASCOTTE_12,
    backgroundColor: '#906ef0',
    textBoxes: [
      {
        ...DEFAULT_CANVAS_PROPERTIES.titleProps,
        text: t('Questions/Shared/SlideBuilder/TextToolbar/title'),
        top: HEIGHT / 3.2 - TITLE_HEIGHT / 2,
      },
    ],
  },
  {
    version: '5.3.0',
    backgroundImage: MASCOTTE_13,
    backgroundColor: '#906ef0',
    textBoxes: [
      {
        ...DEFAULT_CANVAS_PROPERTIES.titleProps,
        text: t('Questions/Shared/SlideBuilder/TextToolbar/title'),
        top: HEIGHT / 3.7 - TITLE_HEIGHT / 2,
      },
    ],
  },
  {
    version: '5.3.0',
    backgroundImage: BG_ADVANCED1,
    backgroundColor: '#906ef0',
    textBoxes: [
      {
        ...DEFAULT_CANVAS_PROPERTIES.titleProps,
        text: 'Lorem ipsum dolor sit amet',
        textAlign: 'center',
        top: HEIGHT / 3 - TITLE_HEIGHT / 2,
        left: WIDTH / 2 - 350 / 2,
        width: 350,
      },
    ],
  },
  {
    version: '5.3.0',
    backgroundImage: BG_ADVANCED2,
    backgroundColor: '#906ef0',
    textBoxes: [
      {
        ...DEFAULT_CANVAS_PROPERTIES.titleProps,
        text: 'Lorem ipsum dolor sit amet',
        textAlign: 'center',
        top: HEIGHT / 3 - TITLE_HEIGHT / 2,
        left: WIDTH / 2 - 350 / 2,
        width: 350,
      },
    ],
  },
  {
    version: '5.3.0',
    backgroundImage: BG_ADVANCED3,
    backgroundColor: '#906ef0',
    textBoxes: [
      {
        ...DEFAULT_CANVAS_PROPERTIES.titleProps,
        text: 'Lorem ipsum dolor sit amet',
        textAlign: 'center',
        top: HEIGHT / 2.5 - TITLE_HEIGHT / 2,
        left: WIDTH / 2 - 350 / 2,
        width: 350,
      },
    ],
  },
  {
    version: '5.3.0',
    backgroundImage: BG_ADVANCED4,
    backgroundColor: '#906ef0',
    textBoxes: [
      {
        ...DEFAULT_CANVAS_PROPERTIES.titleProps,
        text: 'Lorem ipsum dolor sit amet',
        textAlign: 'center',
        top: HEIGHT / 2.5 - TITLE_HEIGHT / 2,
        left: WIDTH / 2 - 350 / 2,
        width: 350,
      },
    ],
  },
  {
    version: '5.3.0',
    backgroundImage: BG_ADVANCED5,
    backgroundColor: '#906ef0',
    textBoxes: [
      {
        ...DEFAULT_CANVAS_PROPERTIES.titleProps,
        text: 'Lorem ipsum dolor sit amet',
        textAlign: 'center',
        top: HEIGHT / 2.5 - TITLE_HEIGHT / 2,
        left: WIDTH / 2 - 350 / 2,
        width: 350,
      },
    ],
  },
  {
    version: '5.3.0',
    backgroundImage: BG_ADVANCED6,
    backgroundColor: '#906ef0',
    textBoxes: [
      {
        ...DEFAULT_CANVAS_PROPERTIES.titleProps,
        text: 'Lorem ipsum dolor sit amet',
        textAlign: 'center',
        top: HEIGHT / 2 - TITLE_HEIGHT / 2,
        left: WIDTH / 2 - 350 / 2,
        width: 350,
      },
    ],
  },
  {
    version: '5.3.0',
    backgroundImage: BG_ADVANCED7,
    backgroundColor: '#906ef0',
    textBoxes: [
      {
        ...DEFAULT_CANVAS_PROPERTIES.titleProps,
        text: 'Lorem ipsum dolor sit amet',
        textAlign: 'center',
        top: HEIGHT / 2.5 - TITLE_HEIGHT / 2,
        left: WIDTH / 2 - 350 / 2,
        width: 350,
      },
    ],
  },
  {
    version: '5.3.0',
    backgroundImage: BG_ADVANCED8,
    backgroundColor: '#906ef0',
    textBoxes: [
      {
        ...DEFAULT_CANVAS_PROPERTIES.titleProps,
        text: 'Lorem ipsum dolor sit amet',
        textAlign: 'center',
        top: HEIGHT / 2.5 - TITLE_HEIGHT / 2,
        left: WIDTH / 2 - 350 / 2,
        width: 350,
      },
    ],
  },
  {
    version: '5.3.0',
    backgroundImage: BG_ADVANCED9,
    backgroundColor: '#906ef0',
    textBoxes: [
      {
        ...DEFAULT_CANVAS_PROPERTIES.titleProps,
        text: 'Lorem ipsum dolor sit amet',
        textAlign: 'center',
        top: HEIGHT / 2 - TITLE_HEIGHT / 2,
        left: WIDTH / 2 - 350 / 2,
        width: 350,
      },
    ],
  },
  {
    version: '5.3.0',
    backgroundImage: BG_ADVANCED10,
    backgroundColor: '#906ef0',
    textBoxes: [
      {
        ...DEFAULT_CANVAS_PROPERTIES.titleProps,
        text: 'Lorem ipsum dolor sit amet',
        textAlign: 'center',
        top: HEIGHT / 2 - TITLE_HEIGHT / 2,
        left: WIDTH / 2 - 350 / 2,
        width: 350,
      },
    ],
  },
  {
    version: '5.3.0',
    backgroundImage: BG_ADVANCED11,
    backgroundColor: '#906ef0',
    textBoxes: [
      {
        ...DEFAULT_CANVAS_PROPERTIES.titleProps,
        text: 'Lorem ipsum dolor sit amet',
        textAlign: 'center',
        top: HEIGHT / 2 - TITLE_HEIGHT / 2,
        left: WIDTH / 2 - 350 / 2,
        width: 350,
      },
    ],
  },
];

export default TEMPLATES;
