import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Options from 'questions/Shared/Options';
import Tags from 'questions/Shared/Tags';
import Icon from 'uikit/Icon';
import InputRich from 'uikit/InputRich';
import Tooltip from 'uikit/Tooltip';

import styles from './Editor.module.scss';

const cx = classNames.bind(styles);

const Editor = ({ answerType, className, handleAnswerType, t }) => (
  <div className={cx('container', className)}>
    <p className={cx('label')}>
      {t('Questions/Interview/Editor/answer-type-label')}
    </p>
    <div className={cx('answer-type-container')}>
      <button
        className={cx('answer-type-button', {
          isChecked: answerType === 'video',
        })}
        onClick={() => handleAnswerType('video')}
        type="button"
      >
        <span
          className={cx('checkbox', {
            isChecked: answerType === 'video',
          })}
        >
          <Icon name="check" />
        </span>
        <span className={cx('answer-type-icon-container')}>
          <Icon className={cx('answer-type-icon')} name="video" />
        </span>
        <span className={cx('answer-type-text')}>
          {t('Questions/Interview/Editor/video')}
        </span>
      </button>
      <p className={cx('or')}>{t('Questions/Interview/Editor/or')}</p>
      <button
        className={cx('answer-type-button', {
          isChecked: answerType === 'audio',
        })}
        onClick={() => handleAnswerType('audio')}
        type="button"
      >
        <span className={cx('checkbox')}>
          <Icon name="check" />
        </span>
        <span className={cx('answer-type-icon-container')}>
          <Icon className={cx('answer-type-icon')} name="microphone-lines" />
        </span>
        <span className={cx('answer-type-text')}>
          {t('Questions/Interview/Editor/audio')}
        </span>
      </button>
    </div>
    <InputRich
      helper={
        <Tooltip tooltip={t('Questions/Interview/Editor/feedback-helper')}>
          <Icon name="info-circle" />
        </Tooltip>
      }
      label={t('Questions/Interview/Editor/feedback-label')}
      name="feedback"
    />
    <Options className={cx('options')}>
      <Tags />
    </Options>
  </div>
);

Editor.propTypes = {
  answerType: PropTypes.string.isRequired,
  className: PropTypes.string,
  handleAnswerType: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

Editor.defaultProps = {
  className: undefined,
};

export default Editor;
