import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'uikit/Button';
import FormattedText from 'uikit/FormattedText';
import Icon from 'uikit/Icon';
import Modal from 'uikit/Modal';

import styles from './Recto.module.scss';

const cx = classNames.bind(styles);

const Recto = ({
  answer,
  className,
  destinations,
  handleDestinationRemove,
  handleDestinationSelect,
  handleSourceSelect,
  isDisabled,
  question,
  selectedSource,
  selectedSourceIndex,
  sources,
  t,
  theme,
}) => (
  <>
    <div className={cx('container', className, `theme-${theme}`)}>
      <FormattedText as="h1" className={cx('title')}>
        {question.title}
      </FormattedText>
      {!isDisabled && (
        <div className={cx('instructions')}>
          {t('Questions/Categorization/Recto/instructions')}
        </div>
      )}
      {sources.map((source, sourceIndex) => {
        const categorized = destinations.filter((destination) =>
          answer[sourceIndex].includes(destination.idx),
        );
        return (
          <div
            className={cx('category', {
              isDisabled,
            })}
            key={source.idx}
          >
            {/* eslint-disable-next-line */}
            <div
              className={cx('inner')}
              onClick={() => handleSourceSelect(sourceIndex)}
              role="button"
            >
              <FormattedText className={cx('category-title')}>
                {source.title}
              </FormattedText>
              {categorized.length > 0 ? (
                <div className={cx('category-destinations')}>
                  {categorized.map((destination) => (
                    <div className={cx('category-destination-container')}>
                      <FormattedText className={cx('category-destination')}>
                        {destination.title}
                      </FormattedText>
                      <button
                        aria-label={t(
                          'Questions/Categorization/Recto/aria-remove-categorization',
                        )}
                        className={cx('remove')}
                        onClick={() =>
                          handleDestinationRemove(sourceIndex, destination.idx)
                        }
                        type="button"
                      >
                        <Icon name="times" />
                      </button>
                    </div>
                  ))}
                </div>
              ) : (
                <p>{t('Questions/Categorization/Recto/category')}</p>
              )}
            </div>
          </div>
        );
      })}
    </div>
    <Modal isOpen={!!selectedSource} onExit={() => handleSourceSelect(-1)}>
      <div className={cx('modal', `theme-${theme}`)}>
        <Button
          aria-label={t('Questions/Categorization/Recto/aria-close')}
          className={cx('close')}
          icon="times"
          onClick={() => handleSourceSelect(-1)}
        />
        <FormattedText className={cx('modal-title')}>
          {selectedSource?.title}
        </FormattedText>
        {destinations.map((destination) => (
          // eslint-disable-next-line
          <button
            className={cx('category', 'inner', {
              isSelected: answer[selectedSourceIndex]?.includes(
                destination.idx,
              ),
              isSelectedForOtherCourse: answer
                .filter((a, index) => selectedSourceIndex !== index)
                .some((a) => a.includes(destination.idx)),
            })}
            key={destination.idx}
            onClick={() => handleDestinationSelect(destination.idx)}
          >
            <FormattedText tabIndex={-1}>{destination.title}</FormattedText>
          </button>
        ))}
        <Button
          className={cx('validate-button')}
          intent="primary"
          onClick={() => handleSourceSelect(-1)}
          size="large"
        >
          {t('Questions/Categorization/Recto/validate')}
        </Button>
      </div>
    </Modal>
  </>
);

Recto.propTypes = {
  answer: PropTypes.arrayOf(PropTypes.number).isRequired,
  className: PropTypes.string,
  destinations: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleDestinationRemove: PropTypes.func.isRequired,
  handleDestinationSelect: PropTypes.func.isRequired,
  handleSourceSelect: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  question: PropTypes.object.isRequired,
  selectedSource: PropTypes.object,
  selectedSourceIndex: PropTypes.number.isRequired,
  sources: PropTypes.arrayOf(PropTypes.object).isRequired,
  t: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
};

Recto.defaultProps = {
  className: undefined,
  isDisabled: false,
  selectedSource: undefined,
};

export default Recto;
