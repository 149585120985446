import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'uikit/Button';
import Icon from 'uikit/Icon';

import styles from './DirectLink.module.scss';

const cx = classNames.bind(styles);

const DirectLink = ({ className, handleCopy, link, t }) => (
  <div className={cx('container', className)}>
    <div className={cx('icon')}>
      <Icon name="link" />
    </div>
    <div className={cx('grow')}>
      <p className={cx('title')}>
        {t('Course/Teacher/Shared/DirectLink/link')}
      </p>
      <div className={cx('content')}>
        <p className={cx('link')}>{link}</p>
        <Button
          aria-label={t('Course/Teacher/Shared/DirectLink/aria-copy-link')}
          onClick={handleCopy}
        >
          {t('Course/Teacher/Shared/DirectLink/copy-link')}
        </Button>
        <input
          aria-hidden="true"
          className={cx('hidden')}
          id="share-link"
          readOnly
          type="text"
          value={link}
          tabIndex={-1}
        />
      </div>
    </div>
  </div>
);

DirectLink.propTypes = {
  className: PropTypes.string,
  handleCopy: PropTypes.func.isRequired,
  link: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

DirectLink.defaultProps = {
  className: undefined,
};

export default DirectLink;
