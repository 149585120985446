import classNames from 'classnames/bind';
import React from 'react';
import PropTypes from 'prop-types';

import ProfilePicture from './images/Profil_picture.svg';

import styles from './BestPlayer.module.scss';

const cx = classNames.bind(styles);

const BestPlayer = ({ className, isLeaderBoardFull, player, rank, t }) => (
  <div
    className={cx(className, 'container', {
      first: isLeaderBoardFull && rank === 1,
      second: isLeaderBoardFull && rank === 2,
      third: isLeaderBoardFull && rank === 3,
    })}
  >
    <div
      className={cx('profil', {
        'profile-first': rank === 1,
        'profile-without-picture': !player.account.picture,
      })}
    >
      <img
        alt=""
        aria-hidden="true"
        className={cx(player.account.picture ? 'picture' : 'image')}
        src={player.account.picture ? player.account.picture : ProfilePicture}
      />
    </div>
    {rank === 1 && (
      <div className={cx('circles')}>
        <div className={cx('circle-1')} />
        <div className={cx('circle-2')} />
        <div className={cx('confetti')} />
      </div>
    )}
    <div
      className={cx('rank', {
        'rank-first': rank === 1,
        'rank-second': rank === 2,
        'rank-third': rank === 3,
      })}
    >
      {rank}
    </div>
    <p className={cx('name')}>
      {`${player.account.firstName} ${player.account.lastName}`}
    </p>
    <p className={cx('score')}>
      {t('Play/Leaderboard/BestPlayer/score', { count: player.score })}
    </p>
  </div>
);

BestPlayer.propTypes = {
  className: PropTypes.string,
  isLeaderBoardFull: PropTypes.bool.isRequired,
  player: PropTypes.object.isRequired,
  rank: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

BestPlayer.defaultProps = {
  className: undefined,
};

export default BestPlayer;
