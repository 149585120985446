import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import PERMISSIONS from 'constants/permissions';
import Button from 'uikit/Button';

import styles from './CollaboratorCard.module.scss';

const cx = classNames.bind(styles);

const CollaboratorCard = ({
  className,
  collaborator,
  handleCollaboratorDelete,
  level,
  t,
}) => (
  <div className={cx('container', className)}>
    <div
      aria-hidden="true"
      className={cx('logo')}
      style={{
        backgroundImage: `url("${collaborator.picture}")`,
      }}
    >
      {!collaborator.picture &&
        `${collaborator.lastName && collaborator.lastName[0]}${
          collaborator.firstName && collaborator.firstName[0]
        }`.toUpperCase()}
      <div
        className={cx('status', {
          isActive: collaborator.isActive,
        })}
      />
    </div>
    <div className={cx('info')}>
      <h3 className={cx('title')}>
        {`${collaborator.lastName.toUpperCase()}, ${collaborator.firstName}`}
      </h3>
      <p className={cx('email')}>{`${
        collaborator.email || collaborator.username
      }`}</p>
    </div>

    {level >= PERMISSIONS.OWNER && collaborator.level < PERMISSIONS.OWNER && (
      <Button
        className={cx('action')}
        icon="trash-alt"
        onClick={handleCollaboratorDelete}
        tooltip={t(
          'Course/Teacher/Collaborators/CollaboratorCard/delete-collaborator',
        )}
        variant="regular"
      />
    )}
  </div>
);

CollaboratorCard.propTypes = {
  className: PropTypes.string,
  collaborator: PropTypes.object.isRequired,
  handleCollaboratorDelete: PropTypes.func.isRequired,
  level: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

CollaboratorCard.defaultProps = {
  className: undefined,
};

export default CollaboratorCard;
