import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import extract from 'helpers/extract';
import { useMutationMeTag, useMutationMeUpdate } from 'gql/mutations/me';
import { trackEvent } from 'lib/tracking';
import { notify } from 'uikit/Notifications';

import InstitutionPopUp from './InstitutionPopUp';

const InstitutionPopUpContainer = () => {
  const { t } = useTranslation();
  const mutationMeTag = useMutationMeTag();
  const mutationMeUpdate = useMutationMeUpdate();
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        institution: yup.string().trim().max(256),
      }),
    [],
  );
  const handlePopUpClose = useCallback(async () => {
    try {
      await mutationMeTag(['institution-asked']);
      trackEvent('tag', 'institution-asked');
    } catch (err) {
      reportError('tag', 'institution-asked', err);
    }
  }, [mutationMeTag]);
  const handleSubmit = useCallback(
    async (values) => {
      try {
        const variables = extract(values, validationSchema);
        await Promise.all([
          mutationMeUpdate(variables),
          mutationMeTag(['institution-asked']),
        ]);
        notify(
          'success',
          t('Shared/MenuContainer/InstitutionPopUp/notification-success'),
        );

        trackEvent('tag', `institution-asked`);
      } catch (err) {
        reportError('tag', `institution-asked`, err);
      }
    },
    [mutationMeTag, mutationMeUpdate, t, validationSchema],
  );
  return (
    <InstitutionPopUp
      handlePopUpClose={handlePopUpClose}
      handleSubmit={handleSubmit}
      t={t}
      validationSchema={validationSchema}
    />
  );
};

export default InstitutionPopUpContainer;
