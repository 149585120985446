import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import storeJS from 'store';

import LanguageSelector from 'components/Shared/LanguageSelector';
import { useMutationMeOnboard, useMutationMeUpdate } from 'gql/mutations/me';
import { trackEvent, reportError } from 'lib/tracking';
import Button from 'uikit/Button';
import FormattedText from 'uikit/FormattedText';

import ILLUSTRATION from './images/illustration.svg';
import styles from './Marketing.module.scss';

const cx = classNames.bind(styles);

const Marketing = ({ me, onBack }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'App/Onboarding/Marketing',
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const mutationMeUpdate = useMutationMeUpdate();

  const mutationMeOnboard = useMutationMeOnboard();

  const getUserCountry = async () => {
    try {
      const response = await fetch('https://ipapi.co/json/');
      const data = await response.json();
      return data.country;
    } catch (err) {
      return '';
    }
  };

  const handleOnboardingTerminate = async (wantsMarketing) => {
    try {
      setIsSubmitting(true);
      const country = await getUserCountry();
      await mutationMeUpdate({ wantsMarketing, country });
      const referralCode = storeJS.get('referralCode');
      const referralDate = storeJS.get('referralDate');
      await mutationMeOnboard({
        referralCode,
        referralDate,
      });
      if (window.$crisp && window.$crisp.push) {
        window.$crisp.push(['safe', true]);
        window.$crisp.push([
          'set',
          'user:nickname',
          [`${me.firstName} ${me.lastName}`],
        ]);
        window.$crisp.push(['set', 'user:email', [me.email]]);
        window.$crisp.push(['set', 'user:avatar', [me.picture]]);
        window.$crisp.push(['set', 'user:company', [me.institution]]);
        window.$crisp.push([
          'set',
          'session:segments',
          [
            [
              'onboarded',
              `language-${me.language}`,
              `sector-${me.sector}`,
              `role-${me.role}`,
              `marketing-${wantsMarketing ? 'on' : 'off'}`,
              ...me.tags,
            ],
          ],
        ]);
        window.$crisp.push([
          'set',
          'session:data',
          [
            [
              ['id', me.id],
              ['firstName', me.firstName],
              ['lastName', me.lastName],
              ['username', me.username],
              ['email', me.email],
              ['language', me.language],
              ['sector', me.sector],
              ['role', me.role],
              ['institution', me.institution],
              ['branch', me.branch],
            ],
          ],
        ]);
        if (me.role !== 'teacher') {
          window.$crisp.push(['do', 'chat:hide']);
        }
        window.$crisp.push(['set', 'session:event', ['user:onboarded']]);
      }
      setIsSubmitting(false);
      trackEvent('onboarding', 'info');
    } catch (err) {
      setIsSubmitting(false);
      reportError('onboarding', 'info', err);
    }
  };

  return (
    <div className={cx('container')}>
      <div
        className={cx('form-container')}
        // Specific style applied to the form container to handle height issue on mobile
        // src: https://chanind.github.io/javascript/2019/09/28/avoid-100vh-on-mobile-web.html
        style={{ height: window.innerHeight }}
      >
        <div className={cx('header')}>
          <Button
            aria-label={t('back')}
            className={cx('back')}
            icon="arrow-left"
            intent="gray"
            onClick={onBack}
          />
          <div className={cx('points')}>
            {[1, 2, 3, 4, 5, 6].map((element) => (
              <div className={cx('point')} key={element} />
            ))}
          </div>
          <LanguageSelector className={cx('language')} intent="square" />
        </div>
        <div className={cx('form-container')}>
          <div className={cx('form')}>
            <img
              alt=""
              className={cx('illustration')}
              aria-hidden="true"
              src={ILLUSTRATION}
            />
            <h1 className={cx('title')}>{t(`title-${me.role}`)}</h1>
            <Button
              className={cx('action')}
              data-testid="accept-marketing-button"
              intent="primary"
              isLoading={isSubmitting}
              onClick={() => handleOnboardingTerminate(true)}
              size="large"
            >
              <FormattedText className={cx('action-text')}>
                {t('accept-marketing')}
              </FormattedText>
            </Button>
            <Button
              className={cx('reject')}
              data-testid="reject-marketing-button"
              intent="white"
              isLoading={isSubmitting}
              onClick={() => handleOnboardingTerminate(false)}
            >
              {t('reject-marketing')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

Marketing.propTypes = {
  me: PropTypes.object.isRequired,
  onBack: PropTypes.func.isRequired,
};

export default Marketing;
