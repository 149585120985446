import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useMutationChapterDelete } from 'gql/mutations/chapter';
import { mutationEditorReset } from 'gql/mutations/editor';
import useModal from 'hooks/useModal';
import { trackEvent, reportError } from 'lib/tracking';
import { notify } from 'uikit/Notifications';
import Button from 'uikit/Button';
import { MenuItem } from 'uikit/Menu';
import Modal from 'uikit/Modal';

import styles from './ChapterDelete.module.scss';

const cx = classNames.bind(styles);

const ChapterDelete = ({ chapter }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/Editor/Finder/ChapterCard/ChapterDelete',
  });

  const mutationChapterDelete = useMutationChapterDelete();

  const [isLoading, setLoading] = useState(false);

  const [status, setStatus] = useState(undefined);

  const { isModalOpen, handleModalOpen, handleModalClose } = useModal(false);

  useEffect(() => {
    setStatus(undefined);
  }, [isModalOpen]);

  const handleChapterDelete = async () => {
    try {
      mutationEditorReset();
      setLoading(true);
      await mutationChapterDelete(chapter);
      handleModalClose();
      setLoading(false);
      trackEvent('chapter', 'delete');
    } catch (err) {
      notify('error', t('chapter-delete-error'));
      reportError('chapter', 'delete', err);
    }
  };

  return (
    <>
      <MenuItem
        icon="trash-alt"
        onClick={handleModalOpen}
        variant="regular"
      >
        {t('delete-chapter')}
      </MenuItem>
      <Modal isOpen={isModalOpen} onExit={handleModalClose}>
        <div className={cx('modal')}>
          <Button
            aria-label={t('aria-close')}
            className={cx('close')}
            icon="times"
            onClick={handleModalClose}
          />
          <h1 className={cx('title')}>{t('title')}</h1>
          <p className={cx('subtitle')}>{t('subtitle')}</p>
          {status && (
            <p className={cx('error')} role="alert">
              {t('chapter-delete-error')}
            </p>
          )}
          <Button
            className={cx('action')}
            intent="danger"
            isLoading={isLoading}
            onClick={handleChapterDelete}
            size="large"
          >
            {t('confirm-delete-chapter')}
          </Button>
        </div>
      </Modal>
    </>
  );
};

ChapterDelete.propTypes = {
  chapter: PropTypes.object.isRequired,
};

export default ChapterDelete;
