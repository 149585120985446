import classNames from 'classnames/bind';
import { Formik, Form, Field } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'uikit/Button';
import Tooltip from 'uikit/Tooltip';

import styles from './CommentContent.module.scss';

const cx = classNames.bind(styles);

const CommentContent = ({
  className,
  content,
  error,
  handleCancel,
  handleCommentReplyGenerate,
  handleSave,
  hasAIAccess,
  isAnswering,
  isEditing,
  isLoading,
  validationSchema,
  t,
}) => (
  <div className={className}>
    {isEditing ? (
      <Formik
        enableReinitialize
        initialValues={{
          content: content,
        }}
        onSubmit={handleSave}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form>
            {isAnswering && hasAIAccess && (
              <Tooltip tooltip={t('Shared/Comment/CommentContent/ai-tooltip')}>
                <Button
                  className={cx('ai-button')}
                  icon="wand-sparkles"
                  intent="ia"
                  isLoading={isLoading}
                  onClick={() => handleCommentReplyGenerate(setFieldValue)}
                  size="small"
                />
              </Tooltip>
            )}
            {error && (
              <p className={cx('error-message')}>
                {t('Shared/Comment/CommentContent/error-message')}
              </p>
            )}
            <Field
              aria-label={t('Shared/Comment/CommentContent/aria-content')}
              autoFocus
              placeholder={t(
                'Shared/Comment/CommentContent/content-placeholder',
              )}
              className={cx('field')}
              name="content"
              as="textarea"
            />
            <div className={cx('actions')}>
              {handleCancel && (
                <Button onClick={handleCancel} size="small">
                  {t('Shared/Comment/CommentContent/cancel')}
                </Button>
              )}
              <Button
                intent="primary"
                size="small"
                type="submit"
                isLoading={isSubmitting}
              >
                {t('Shared/Comment/CommentContent/send')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    ) : (
      <div className={cx('content')}>{content}</div>
    )}
  </div>
);

CommentContent.propTypes = {
  className: PropTypes.string,
  content: PropTypes.string.isRequired,
  error: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func,
  handleCommentReplyGenerate: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  hasAIAccess: PropTypes.bool.isRequired,
  isAnswering: PropTypes.bool,
  isEditing: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  validationSchema: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

CommentContent.defaultProps = {
  className: undefined,
  handleCancel: undefined,
  isAnswering: false,
  isEditing: false,
};

export default CommentContent;
