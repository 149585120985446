import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import { useQueryCourseContent } from 'gql/queries/course';
import useSiteContext from 'hooks/useSiteContext';

import Cover from './Cover';

const CoverContainer = (props) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const activeTab = pathname.split('/')[3];
  const { courseId } = useParams();
  const { isLoading, data } = useQueryCourseContent({
    variables: {
      courseId,
    },
  });
  const { isGAR, isTNECanope, isInTeams, isRoleTeacher } = useSiteContext();
  return (
    <Cover
      activeTab={activeTab}
      course={data?.course}
      isGAR={isGAR}
      isInTeams={isInTeams}
      isLoading={isLoading}
      isRoleTeacher={isRoleTeacher}
      isTNECanope={isTNECanope}
      t={t}
      {...props}
    />
  );
};

export default CoverContainer;
