const THEMES = [
  {
    backgroundColor: '#FFF',
    titleProps: {
      fill: '#1d254f',
      fontName: 'Nunito Black',
      fontFamily: 'Nunito',
      fontSize: 60,
    },
    subtitleProps: {
      fill: '#1d254f',
      fontName: 'Nunito Bold',
      fontFamily: 'Nunito',
      fontSize: 35,
    },
    textProps: {
      fill: '#1d254f',
      fontName: 'Nunito Regular',
      fontFamily: 'Nunito',
      fontSize: 20,
    },
    bulletProps: {
      background: '#F4EFFE',
      fill: '#906ef0',
      fontFamily: 'Nunito',
      fontSize: 20,
      fontWeight: 900,
    },
  },
  {
    backgroundColor: '#F4F8FF',
    titleProps: {
      fill: '#00338c',
      fontName: 'Nunito Black',
      fontFamily: 'Nunito',
      fontSize: 48,
    },
    subtitleProps: {
      fill: '#518ef7',
      fontName: 'Nunito Regular',
      fontFamily: 'Nunito',
      fontSize: 35,
    },
    textProps: {
      fill: '#FFB500',
      fontName: 'Nunito Bold',
      fontFamily: 'Nunito',
      fontSize: 20,
    },
    bulletProps: {
      background: '#00338c',
      fill: '#FFB500',
      fontFamily: 'Nunito',
      fontSize: 20,
      fontWeight: 900,
    },
  },
  {
    backgroundColor: '#EDF2FE',
    titleProps: {
      fill: '#655EB5',
      fontName: 'Archivo',
      fontFamily: 'Archivo',
      fontSize: 48,
    },
    subtitleProps: {
      fill: '#BCADDE',
      fontName: 'Open Sans',
      fontFamily: 'Open sans',
      fontSize: 35,
    },
    textProps: {
      fill: '#4454A6',
      fontName: 'Open Sans',
      fontFamily: 'Open sans',
      fontSize: 20,
    },
    bulletProps: {
      background: '#fff',
      fill: '#655EB5',
      fontFamily: 'Archivo',
      fontSize: 20,
      fontWeight: 900,
    },
  },
  {
    backgroundColor: '#F2E2C6',
    titleProps: {
      fill: '#734264',
      fontName: 'Anton Regular',
      fontFamily: 'Anton',
      fontSize: 48,
    },
    subtitleProps: {
      fill: '#9C4B4C',
      fontName: 'Prompt Medium',
      fontFamily: 'Prompt',
      fontSize: 35,
    },
    textProps: {
      fill: '#DEA480',
      fontName: 'Prompt Light',
      fontFamily: 'Prompt',
      fontSize: 20,
    },
    bulletProps: {
      background: '#DEA480',
      fill: '#734264',
      fontFamily: 'Anton',
      fontSize: 20,
      fontWeight: 900,
    },
  },
  {
    backgroundColor: '#f9f8f8',
    titleProps: {
      fill: '#3A98B9',
      fontName: 'Lobster',
      fontFamily: 'Lobster',
      fontSize: 48,
    },
    subtitleProps: {
      fill: '#b9ad9c',
      fontName: 'Roboto Regular',
      fontFamily: 'Roboto',
      fontSize: 35,
    },
    textProps: {
      fill: '#d5c4b4',
      fontName: 'Roboto Bold',
      fontFamily: 'Roboto',
      fontSize: 20,
    },
    bulletProps: {
      background: '#3A98B9',
      fill: '#fff',
      fontFamily: 'Lobster',
      fontSize: 20,
      fontWeight: 900,
    },
  },
  {
    backgroundColor: '#F6F1E6',
    titleProps: {
      fill: '#4D4C20',
      fontName: 'Lato Black',
      fontFamily: 'Lato',
      fontSize: 48,
    },
    subtitleProps: {
      fill: '#a79b1b',
      fontName: 'Lato Regular',
      fontFamily: 'lato',
      fontSize: 35,
    },
    textProps: {
      fill: '#DFB33F',
      fontName: 'Roboto Bold',
      fontFamily: 'Roboto',
      fontSize: 20,
    },
    bulletProps: {
      background: '#4D4C20',
      fill: '#DFB33F',
      fontFamily: 'Lato',
      fontSize: 20,
      fontWeight: 900,
    },
  },
  {
    backgroundColor: '#f8f2f2',
    titleProps: {
      fill: '#867070',
      fontName: 'Nunito Black',
      fontFamily: 'Nunito',
      fontSize: 48,
    },
    subtitleProps: {
      fill: '#b69999',
      fontName: 'Raleway Regular',
      fontFamily: 'Raleway',
      fontSize: 35,
    },
    textProps: {
      fill: '#ceabab',
      fontName: 'Raleway Bold',
      fontFamily: 'Raleway',
      fontSize: 20,
    },
    bulletProps: {
      background: '#b69999',
      fill: '#f8f2f2',
      fontFamily: 'Raleway',
      fontSize: 20,
      fontWeight: 900,
    },
  },
  {
    backgroundColor: '#FF5F9E',
    titleProps: {
      fill: '#060047',
      fontName: 'Pacifico Bold',
      fontFamily: 'Pacifico',
      fontSize: 48,
    },
    subtitleProps: {
      fill: '#B3005E',
      fontName: 'PT Sans Regular',
      fontFamily: 'PT-sans',
      fontSize: 35,
    },
    textProps: {
      fill: '#E90064',
      fontName: 'PT Sans Bold',
      fontFamily: 'PT-sans',
      fontSize: 20,
    },
    bulletProps: {
      background: '#060047',
      fill: '#E90064',
      fontFamily: 'Pacifico',
      fontSize: 20,
      fontWeight: 900,
    },
  },
  {
    backgroundColor: '#E5E5CB',
    titleProps: {
      fill: '#1A120B',
      fontName: 'Roboto Bold',
      fontFamily: 'Roboto',
      fontSize: 48,
    },
    subtitleProps: {
      fill: '#3C2A21',
      fontName: 'PT Sans Regular',
      fontFamily: 'PT-sans',
      fontSize: 35,
    },
    textProps: {
      fill: '#aea885',
      fontName: 'PT Sans Bold',
      fontFamily: 'PT-sans',
      fontSize: 20,
    },
    bulletProps: {
      background: '#1A120B',
      fill: '#E5E5CB',
      fontFamily: 'PT-sans',
      fontSize: 20,
      fontWeight: 900,
    },
  },
  {
    backgroundColor: '#FFF6C3',
    titleProps: {
      fill: '#DC0000',
      fontName: 'Sofia Regular',
      fontFamily: 'Sofia',
      fontSize: 48,
    },
    subtitleProps: {
      fill: '#850000',
      fontName: 'Poppins Regular',
      fontFamily: 'Poppins',
      fontSize: 35,
    },
    textProps: {
      fill: '#d8ba74',
      fontName: 'Poppins Bold',
      fontFamily: 'Poppins',
      fontSize: 18,
    },
    bulletProps: {
      background: '#850000',
      fill: '#FFF6C3',
      fontFamily: 'Sofia',
      fontSize: 20,
      fontWeight: 900,
    },
  },
  {
    backgroundColor: '#F1F6F5',
    titleProps: {
      fill: '#472183',
      fontName: 'Arvo Bold',
      fontFamily: 'Arvo',
      fontSize: 48,
    },
    subtitleProps: {
      fill: '#4B56D2',
      fontName: 'Poppins Regular',
      fontFamily: 'Poppins',
      fontSize: 35,
    },
    textProps: {
      fill: '#82C3EC',
      fontName: 'Poppins Bold',
      fontFamily: 'Poppins',
      fontSize: 18,
    },
    bulletProps: {
      background: '#472183',
      fill: '#F1F6F5',
      fontFamily: 'Poppins',
      fontSize: 20,
      fontWeight: 900,
    },
  },
];

export default THEMES;
