import classNames from 'classnames/bind';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useMutationCourseGARShare } from 'gql/mutations/course';
import { useQueryMe } from 'gql/queries/me';
import Button from 'uikit/Button';
import CheckBox from 'uikit/CheckBox';

import styles from './GAR.module.scss';

const cx = classNames.bind(styles);

const GAR = ({ course, handleModalClose }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/Cover/CourseShare/GAR',
  });

  const mutationCourseGARShare = useMutationCourseGARShare();

  const { isLoading, data } = useQueryMe();

  // Extract from the user data:
  // garDIV: the existing divisions (classes) that the user can share the course to.
  // garGRO: the existing groups that the user can share the course to.
  // initialValues: the divisions and the groups that the user has selected to share the course with.
  const { garDIV, garGRO, initialValues } = useMemo(() => {
    const meDIV = isLoading ? [] : data.me.metadata.garDIV || [];
    const meGRO = isLoading ? [] : data.me.metadata.garGRO || [];
    const courseDIV = course.gar?.div || [];
    const courseGRO = course.gar?.gro || [];
    const divisions = Object.fromEntries([
      ...meDIV.map((div) => [div, courseDIV.includes(div)]),
      ...courseDIV.map((div) => [div, true]),
    ]);
    const groups = Object.fromEntries([
      ...meGRO.map((gro) => [gro, courseGRO.includes(gro)]),
      ...courseGRO.map((gro) => [gro, true]),
    ]);
    return {
      garDIV: Object.keys(divisions).sort((a, b) => a.localeCompare(b)),
      garGRO: Object.keys(groups).sort((a, b) => a.localeCompare(b)),
      initialValues: {
        div: divisions,
        gro: groups,
      },
    };
  }, [isLoading, data, course]);

  const handleSave = async ({ div, gro }) => {
    try {
      const selectedDivisions = Object.entries(div)
        .filter(([, v]) => v)
        .map(([key]) => key);
      const selectedGroups = Object.entries(gro)
        .filter(([, v]) => v)
        .map(([key]) => key);
      await mutationCourseGARShare({
        courseId: course.id,
        div: selectedDivisions,
        gro: selectedGroups,
      });
      handleModalClose();
    } catch (err) {
      //
    }
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSave}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className={cx('checkboxes')}>
              {garDIV.length > 0 && (
                <>
                  <h2 className={cx('subtitle')}>{t('divisions')}</h2>

                  {garDIV.map((div) => {
                    const [key, name] = div.split('##');
                    if (key === undefined || name === undefined) return null;
                    return (
                      <CheckBox
                        className={cx('checkbox')}
                        key={div}
                        name={`div.${div}`}
                        label={`${name} (${key})`}
                      />
                    );
                  })}
                </>
              )}
              {garGRO.length > 0 && (
                <>
                  <h2 className={cx('subtitle')}>{t('groups')}</h2>
                  {garGRO.map((gro) => {
                    const [key, name] = gro.split('##');
                    if (key === undefined || name === undefined) return null;
                    return (
                      <CheckBox
                        className={cx('checkbox')}
                        key={gro}
                        name={`gro.${gro}`}
                        label={`${name} (${key})`}
                      />
                    );
                  })}
                </>
              )}
            </div>
            <Button
              className={cx('action')}
              intent="primary"
              isLoading={isSubmitting}
              size="large"
              type="submit"
            >
              {t('share')}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

GAR.propTypes = {
  course: PropTypes.object.isRequired,
  handleModalClose: PropTypes.func.isRequired,
};

export default GAR;
