import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import Button from 'uikit/Button';
import Icon from 'uikit/Icon';
import Menu from 'uikit/Menu';

import CourseLeave from 'components/Shared/CourseLeave';
import GamePlay from 'components/Course/Student/Cover/GamePlay';
import LoadingScreen from 'components/Shared/LoadingScreen';
import CourseStudy from 'components/Course/Shared/CourseStudy/CourseStudy';
import ExamStart from 'components/Course/Shared/ExamStart';

import BACKGROUND_IMAGE from './images/background.svg';
import TUTORIAL1_IMAGE from './images/background_tutorial1.png';
import TUTORIAL2_IMAGE from './images/background_tutorial2.png';
import MOODLE_IMAGE from './images/moodle.png';
import styles from './Cover.module.scss';

const cx = classNames.bind(styles);

const Cover = ({
  canSeeExamReview,
  canTakeExam,
  className,
  course,
  handleQuestionCreate,
  isEditionDisabled,
  isLoading,
  needTutorialIllustration1,
  needTutorialIllustration2,
  t,
}) =>
  isLoading ? (
    <LoadingScreen />
  ) : (
    <div
      className={cx('container', `theme-${course.themeBackground}`, className)}
      style={{
        backgroundImage: `url(${
          needTutorialIllustration1
            ? TUTORIAL1_IMAGE
            : needTutorialIllustration2
            ? TUTORIAL2_IMAGE
            : BACKGROUND_IMAGE
        })`,
      }}
    >
      <h1
        className={cx('title', {
          'title-with-introduction': !!course.introduction,
        })}
      >
        {course.title}
      </h1>
      {course.introduction && (
        <p className={cx('introduction')}>{course.introduction}</p>
      )}
      <div className={cx('actions')}>
        {course.isExam ? (
          <>
            {canTakeExam && (
              <ExamStart
                className={cx('action')}
                course={course}
                intent="primary"
                size="large"
              />
            )}
            {canSeeExamReview && (
              <Link
                className={cx('action', 'review')}
                to={`/test-review/${course.id}`}
              >
                {t('Course/Student/Cover/test-review')}
                <Icon className={cx('chevron')} name="chevron-right" />
              </Link>
            )}
          </>
        ) : (
          <>
            <CourseStudy className={cx('action')} course={course} />
            {course.games.length > 0 && (
              <GamePlay className={cx('action')} course={course} />
            )}
          </>
        )}
        {course.moodle.isMoodle && (
          <Button
            as="a"
            className={cx('action', 'moodle-button')}
            href={course.moodle.courseUrl}
            icon={
              <img
                alt=""
                aria-hidden="true"
                className={cx('moodle-image')}
                src={MOODLE_IMAGE}
              />
            }
            intent="white"
            size="large"
            target="_top"
          >
            {t('Course/Student/Cover/moodle')}
          </Button>
        )}
      </div>
      {!isEditionDisabled && (
        <Menu
          className={cx('menu')}
          placement="bottom-end"
          toggle={
            <Button
              icon="sign-out"
              intent="white"
              size="small"
              tooltip={t('Course/Student/Cover/leave')}
            />
          }
        >
          <CourseLeave course={course} />
        </Menu>
      )}
      {course.isStudentCollaborationEnabled && !course.isExam && (
        <Button
          className={cx('propose-question')}
          icon="plus"
          intent="white"
          onClick={handleQuestionCreate}
        >
          {t('Course/Student/Cover/suggest-question')}
        </Button>
      )}
    </div>
  );

Cover.propTypes = {
  canSeeExamReview: PropTypes.bool,
  canTakeExam: PropTypes.bool,
  className: PropTypes.string,
  course: PropTypes.object,
  handleQuestionCreate: PropTypes.func.isRequired,
  isEditionDisabled: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  needTutorialIllustration1: PropTypes.bool.isRequired,
  needTutorialIllustration2: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

Cover.defaultProps = {
  canSeeExamReview: undefined,
  canTakeExam: undefined,
  className: undefined,
  course: undefined,
  isEditionDisabled: undefined,
};

export default Cover;
