import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import Avatar from 'uikit/Avatar';
import Icon from 'uikit/Icon';
import Menu, { MenuItem } from 'uikit/Menu';

import MenuTooltip from '../MenuTooltip';
import styles from './AccountMenu.module.scss';

const cx = classNames.bind(styles);

const AccountMenu = ({
  className,
  isGAR,
  isInLateralPanel,
  isInTeams,
  isMenuExpanded,
  me,
  t,
}) => (
  <div className={cx('container', className)}>
    <Menu
      placement={'right'}
      toggle={
        isInLateralPanel ? (
          <button
            aria-label={t('Shared/MenuContainer/AccountMenu/my-account')}
            className={cx('lateral-panel-button', { small: !isMenuExpanded })}
            type="button"
          >
            <MenuTooltip
              isInMenu={true}
              tooltip={`${me.firstName} ${me.lastName}`}
            >
              <Icon className={cx('lateral-panel-icon')} name="user" />
            </MenuTooltip>
            <span
              className={cx('lateral-panel-text', { hidden: !isMenuExpanded })}
            >
              {`${me.firstName} ${me.lastName}`}
            </span>
          </button>
        ) : (
          <button
            aria-label={t('Shared/MenuContainer/AccountMenu/aria-my-account')}
            className={cx('toggle-button')}
            type="button"
          >
            <Avatar profile={me} />
          </button>
        )
      }
    >
      <MenuItem as={Link} icon="cog" to="/settings">
        {t('Shared/MenuContainer/AccountMenu/settings')}
      </MenuItem>
      {!isInTeams && !isGAR && (
        <MenuItem as={Link} icon="rocket-launch" to="/subscription">
          {t('Shared/MenuContainer/AccountMenu/subscription')}
        </MenuItem>
      )}
    </Menu>
  </div>
);

AccountMenu.propTypes = {
  className: PropTypes.string,
  isGAR: PropTypes.bool.isRequired,
  isInLateralPanel: PropTypes.bool,
  isInTeams: PropTypes.bool.isRequired,
  isMenuExpanded: PropTypes.bool,
  me: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

AccountMenu.defaultProps = {
  className: undefined,
  isInLateralPanel: false,
  isMenuExpanded: false,
};

export default AccountMenu;
