import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import CourseJoin from 'components/Shared/CourseJoin';
import Button from 'uikit/Button';

import imageCelebration from './images/celebration.svg';
import styles from './Empty.module.scss';

const cx = classNames.bind(styles);

const Empty = ({
  className,
  handleCourseCreate,
  handleCourseJoinClose,
  handleCourseJoinOpen,
  isCourseCreating,
  isCourseJoinDisplayed,
  role,
  t,
}) => (
  <>
    <div className={cx('container', className)}>
      <div className={cx('section')}>
        <img
          alt=""
          aria-hidden="true"
          className={cx('image')}
          src={imageCelebration}
        />
        <h3 className={cx('title')}>{t('Dashboard/Empty/title')}</h3>
        <h3 className={cx('subtitle')}>{t('Dashboard/Empty/subtitle')}</h3>
        {role === 'teacher' ? (
          <>
            <Button
              className={cx('action')}
              intent="primary"
              isLoading={isCourseCreating}
              onClick={handleCourseCreate}
              size="large"
            >
              {t('Dashboard/Empty/create-course')}
            </Button>
            <Button
              className={cx('action')}
              onClick={handleCourseJoinOpen}
              size="large"
            >
              {t('Dashboard/Empty/join-course')}
            </Button>
          </>
        ) : (
          <>
            <Button
              className={cx('action')}
              intent="primary"
              onClick={handleCourseJoinOpen}
              size="large"
            >
              {t('Dashboard/Empty/join-course')}
            </Button>
            <Button
              className={cx('action')}
              isLoading={isCourseCreating}
              onClick={handleCourseCreate}
              size="large"
            >
              {t('Dashboard/Empty/create-course')}
            </Button>
          </>
        )}
      </div>
      <p className={cx('or')}>
        <span>{t('Dashboard/Empty/or')}</span>
      </p>
      <div className={cx('section')}>
        <h3 className={cx('title')}>{t('Dashboard/Empty/title2')}</h3>
        <h3 className={cx('subtitle')}>{t('Dashboard/Empty/subtitle2')}</h3>
        <Button
          as={Link}
          className={cx('action')}
          to="/public-courses"
          size="large"
        >
          {t('Dashboard/Empty/public-courses')}
        </Button>
      </div>
    </div>
    <CourseJoin
      isModalOpen={isCourseJoinDisplayed}
      handleModalClose={handleCourseJoinClose}
    />
  </>
);

Empty.propTypes = {
  className: PropTypes.string,
  handleCourseCreate: PropTypes.func.isRequired,
  handleCourseJoinClose: PropTypes.func.isRequired,
  handleCourseJoinOpen: PropTypes.func.isRequired,
  isCourseCreating: PropTypes.bool.isRequired,
  isCourseJoinDisplayed: PropTypes.bool.isRequired,
  role: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

Empty.defaultProps = {
  className: undefined,
};

export default Empty;
