import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import InputTolerance from './InputTolerance';

const InputToleranceContainer = ({ name, ...props }) => {
  const { t } = useTranslation();
  const [{ value: toleranceValue }, , { setValue: setToleranceValue }] =
    useField(name);
  // We need to differentiate the tolerance value and the value displayed in the input.
  // Otherwise, we would run into some problems when typing
  // and removing decimal digits (e.g. 5.5 --> 5. could be transformed to
  // 5 if we don't do this).
  const [, , { setValue: setInputValue }] = useField(`${name}Input`);
  const [{ value: toleranceType }, , { setValue: setToleranceType }] =
    useField('toleranceType');
  useEffect(() => {
    let processedValue = '';
    if (typeof toleranceValue === 'number') {
      processedValue = `${toleranceValue}`;
      if (toleranceType === 'RELATIVE') {
        processedValue += '%';
      }
    }
    setToleranceValue(toleranceValue);
    setInputValue(processedValue);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const formatValue = useCallback((rawValue) => {
    let firstMatch = true;
    return (
      rawValue
        // Remove chars not 0-9, ',', '.' or '%'
        .replace(/[^0-9.,%]/g, '')
        // Remove all occurrences of ','/'.' except first one
        .replace(/\.|,/g, (match) => {
          if (firstMatch) {
            firstMatch = false;
            return match;
          }
          return '';
        })
        // Replace all occurrences of the '%' except the last one
        .replace(
          /(.*)%/,
          (matchedString) => `${matchedString.replace(/%/gi, '')}%`,
        )
        // Replace all the characters after the '%' sign
        .replace(/%.*/gi, '%')
        // If the input doesn't contain any digits (e.g. only a % sign or a dot),
        // clear the value
        .replace(/^\D$/, '')
    );
  }, []);
  const parseStringToNumber = useCallback((formattedValueToParse) => {
    const parsedValue = parseFloat(formattedValueToParse.replace(',', '.'));
    return !Number.isNaN(parsedValue) ? parsedValue : null;
  }, []);
  const handleChange = useCallback(
    ({ target: { value: eventValue } }) => {
      const formattedInputValue = formatValue(eventValue);
      let newToleranceType;
      if (formattedInputValue.includes('%')) {
        newToleranceType = 'RELATIVE';
      } else if (formattedInputValue) {
        newToleranceType = 'ABSOLUTE';
      } else {
        newToleranceType = 'NONE';
      }
      const newValue = parseStringToNumber(formattedInputValue);
      setInputValue(formattedInputValue);
      setToleranceValue(newValue);
      setToleranceType(newToleranceType);
    },
    [
      formatValue,
      parseStringToNumber,
      setInputValue,
      setToleranceType,
      setToleranceValue,
    ],
  );
  return (
    <InputTolerance handleChange={handleChange} name={name} t={t} {...props} />
  );
};

InputToleranceContainer.propTypes = {
  name: PropTypes.string.isRequired,
};

export default InputToleranceContainer;
