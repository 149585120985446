import { useEffect, useRef } from 'react';

const useInterval = (callback, lapse) => {
  const refedCallback = useRef(callback);
  useEffect(() => {
    refedCallback.current = callback;
  }, [callback]);
  useEffect(() => {
    function tick() {
      refedCallback.current();
    }
    const interval = setInterval(tick, lapse);
    return () => {
      clearInterval(interval);
    };
  }, [lapse]);
};

export default useInterval;
