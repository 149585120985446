import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'uikit/Button';
import CommentContent from 'components/Shared/Comment/CommentContent';
import CommentHeader from 'components/Shared/Comment/CommentHeader';
import Icon from 'uikit/Icon';

import CommentReply from './CommentReply';
import styles from './Comment.module.scss';

const cx = classNames.bind(styles);

const Comment = ({
  canSolve,
  className,
  comment,
  handleCommentReply,
  handleCommentSolve,
  handleDelete,
  handleEdit,
  handleEditToggle,
  handleThreadClose,
  handleThreadOpen,
  isEditing,
  isThreadOpen,
  validationSchema,
  t,
}) => (
  <div className={cx('container', className)}>
    <div className={cx('header')}>
      <CommentHeader
        account={comment.account}
        className={cx('header-primary')}
        comment={comment}
        handleEdit={handleEditToggle}
        handleDelete={handleDelete}
      />
      {canSolve && (
        <Button
          icon="check"
          className={cx('solve-button')}
          onClick={handleCommentSolve}
          size="small"
          tooltip={t('Course/Shared/QuestionComments/Comment/solve')}
        />
      )}
    </div>
    <CommentContent
      className={cx('content')}
      comment={null}
      content={comment.content}
      handleCancel={handleEditToggle}
      handleSave={handleEdit}
      isEditing={isEditing}
      validationSchema={validationSchema}
    />
    {isThreadOpen ? (
      <>
        {comment.commentReplies.map((commentReply) => (
          <CommentReply
            className={cx('comment-reply')}
            commentReply={commentReply}
            key={commentReply.id}
          />
        ))}
        <div className={cx('line')} />
        <p className={cx('reply')}>
          {t('Course/Shared/QuestionComments/Comment/reply')}
        </p>
        <CommentContent
          comment={comment}
          content=''
          handleCancel={handleThreadClose}
          handleSave={handleCommentReply}
          isAnswering={true}
          isEditing={true}
          validationSchema={validationSchema}
        />
      </>
    ) : (
      <button
        className={cx('toggle-replies')}
        onClick={handleThreadOpen}
        type="button"
      >
        {t(
          'Course/Shared/QuestionComments/Comment/see-thread',
          comment.commentReplies.length,
        )}
        <Icon name="chevron-right" className={cx('chevron-right')} />
      </button>
    )}
  </div>
);

Comment.propTypes = {
  canSolve: PropTypes.bool.isRequired,
  className: PropTypes.string,
  comment: PropTypes.object.isRequired,
  handleCommentReply: PropTypes.func.isRequired,
  handleCommentSolve: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleEditToggle: PropTypes.func.isRequired,
  handleThreadClose: PropTypes.func.isRequired,
  handleThreadOpen: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isThreadOpen: PropTypes.bool.isRequired,
  validationSchema: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

Comment.defaultProps = {
  className: undefined,
};

export default Comment;
