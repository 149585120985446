import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';

import EDITOR_STATUSES from 'constants/editorStatuses';
import {
  mutationEditorQuestionPreview,
  mutationEditorQuestionUpdate,
} from 'gql/mutations/editor';
import { useQueryLocalApp, useQueryLocalEditor } from 'gql/queries/local';
import useHotkeys from 'hooks/useHotkeys';
import { getQuestionRecto, getQuestionVerso } from 'questions';
import Button from 'uikit/Button';

import Navigation from '../Navigation/Navigation';
import PreviewHeader from './PreviewHeader/PreviewHeader';
import styles from './QuestionPreview.module.scss';

const cx = classNames.bind(styles);

const QuestionPreview = ({
  isQuestionSaved,
  nextQuestion = null,
  onCancel,
  previousQuestion = null,
}) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Shared/QuestionEdit/QuestionPreview',
  });

  const {
    data: {
      app: { isMenuExpanded },
    },
  } = useQueryLocalApp();

  const {
    data: {
      editor: { questionEditing },
    },
  } = useQueryLocalEditor();

  const [seeRecto, setSeeRecto] = useState(true);

  useHotkeys('up', () => {
    if (previousQuestion) mutationEditorQuestionPreview(previousQuestion);
  });

  useHotkeys('down', () => {
    if (nextQuestion) mutationEditorQuestionPreview(nextQuestion);
  });

  useEffect(() => setSeeRecto(true), [questionEditing]);

  const RectoComponent = getQuestionRecto(questionEditing?.type);

  const VersoComponent = getQuestionVerso(questionEditing?.type);

  const initialValues = useMemo(
    () => ({
      answer: RectoComponent?.defaultAnswer(questionEditing),
    }),
    [questionEditing, RectoComponent],
  );

  const question = useMemo(
    () => ({
      ...questionEditing,
      solution: VersoComponent?.defaultSolution(questionEditing),
    }),
    [questionEditing, VersoComponent],
  );

  const handleQuestionUpdate = () => {
    mutationEditorQuestionUpdate(
      questionEditing,
      EDITOR_STATUSES.QUESTION_PREVIEW,
    );
  };

  const handleToggleCard = () => setSeeRecto((seeRectoValue) => !seeRectoValue);

  return (
    <div className={cx('subpage')}>
      <div className={cx('main', { 'menu-expanded': isMenuExpanded })}>
        <Navigation
          nextQuestion={nextQuestion}
          onExit={onCancel}
          previousQuestion={previousQuestion}
        />
        <div className={cx('preview')}>
          <PreviewHeader
            isQuestionSaved={isQuestionSaved}
            onQuestionUpdate={handleQuestionUpdate}
          />
          <Formik
            initialValues={initialValues}
            key={question.id}
            validateOnBlur={false}
            validateOnChange={false}
          >
            {() => (
              <Form className={cx('question')}>
                {seeRecto
                  ? RectoComponent && <RectoComponent question={question} />
                  : VersoComponent && <VersoComponent question={question} />}
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div className={cx('footer')}>
        <Button className={cx('action')} onClick={handleToggleCard}>
          {seeRecto ? t('see-verso') : t('see-recto')}
        </Button>
      </div>
    </div>
  );
};

QuestionPreview.propTypes = {
  isQuestionSaved: PropTypes.bool.isRequired,
  nextQuestion: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  previousQuestion: PropTypes.object,
  question: PropTypes.object,
  versoComponent: PropTypes.elementType,
};

export default QuestionPreview;
