import { useField } from 'formik';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import Editor from './Editor';

const EditorContainer = (props) => {
  const { t } = useTranslation();
  const [{ value: image }, , { setValue: setImage }] = useField('image');
  const handleImageDelete = useCallback(() => {
    setImage(null);
  }, [setImage]);
  const [{ value: zones }, , { setValue: setZones }] = useField('zones');
  const handleZoneAdd = useCallback(
    (type) => {
      const newZone = {
        h: type === 'rectangle' ? 0.1 : null,
        r: type === 'circle' ? 0.05 : null,
        w: type === 'rectangle' ? 0.1 : null,
        x: type === 'rectangle' ? 0.01 : 0.06,
        y: type === 'rectangle' ? 0.01 : 0.06,
        shapeType: type,
      };
      setZones([...zones, newZone]);
    },
    [setZones, zones],
  );
  return (
    <Editor
      handleImageDelete={handleImageDelete}
      handleZoneAdd={handleZoneAdd}
      image={image}
      t={t}
      {...props}
    />
  );
};

EditorContainer.validationSchema = (t) =>
  yup.object().shape({
    tags: yup.array().of(yup.string().trim().lowercase().max(256)).compact(),
    title: yup
      .string()
      .trim()
      .required(t('Questions/FindOnImage/Editor/title-error-required')),
    image: yup
      .string()
      .trim()
      .required(t('Questions/FindOnImage/Editor/image-error-required'))
      .url(t('Questions/FindOnImage/Editor/image-error-required')),
    zones: yup
      .array()
      .of(
        yup.object().shape({
          shapeType: yup
            .string()
            .trim()
            .required()
            .oneOf(['rectangle', 'circle']),
          x: yup.number().required(),
          y: yup.number().required(),
          w: yup.number().nullable(),
          h: yup.number().nullable(),
          r: yup.number().nullable(),
        }),
      )
      .test(
        'zones',
        t('Questions/FindOnImage/Editor/zones-error-required'),
        (zones) => zones.length >= 1,
      ),
    feedback: yup.string().trim(),
  });

export default EditorContainer;
