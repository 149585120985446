import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import Options from 'questions/Shared/Options';
import Tags from 'questions/Shared/Tags';
import Button from 'uikit/Button';
import Icon from 'uikit/Icon';
import InputError from 'uikit/InputError';
import InputRich from 'uikit/InputRich';
import Tooltip from 'uikit/Tooltip';

import styles from './Editor.module.scss';

const cx = classNames.bind(styles);

const Editor = ({
  className,
  dndRef,
  handleItemAdd,
  handleItemComplete,
  handleItemMove,
  handleItemRemove,
  handleMoveDown,
  handleMoveUp,
  items,
  t,
}) => (
  <div className={cx('container', className)}>
    <p className={cx('label')}>{t('Questions/Sorting/Editor/items')}</p>
    <div className={cx('field')}>
      <DragDropContext
        onDragEnd={handleItemMove}
        sensors={[
          (api) => {
            // eslint-disable-next-line no-param-reassign
            dndRef.current = api;
          },
        ]}
      >
        <InputError name="items" />
        <Droppable droppableId="items">
          {(providedItems, snapshotItems) => (
            <div
              className={cx('zone', {
                isDraggingOver: snapshotItems.isDraggingOver,
              })}
              ref={providedItems.innerRef}
              {...providedItems.droppableProps}
            >
              {[...items, ''].map((_, index) => (
                <Draggable
                  draggableId={index.toString()}
                  index={index}
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                >
                  {(providedItem, snapshotItem) => (
                    <div
                      className={cx('list-element', {
                        isDragging: snapshotItem.isDragging,
                      })}
                      ref={providedItem.innerRef}
                      {...providedItem.draggableProps}
                    >
                      {index === items.length ? (
                        <Button
                          className={cx('item-add')}
                          icon="plus"
                          intent="outline"
                          onClick={handleItemAdd}
                          size="small"
                          tooltip={t('Questions/Sorting/Editor/item-add')}
                        />
                      ) : (
                        <div
                          aria-label={t('Questions/Sorting/Editor/aria-move')}
                          className={cx('drag')}
                          role="button"
                          {...providedItem.dragHandleProps}
                        >
                          <Icon name="bars" />
                        </div>
                      )}
                      {index === items.length ? (
                        <InputRich
                          className={cx('item-complete')}
                          name="tmp"
                          onChange={handleItemComplete}
                        />
                      ) : (
                        <InputRich
                          className={cx('fill')}
                          name={`items.${index}.title`}
                          placeholder={t(
                            `Questions/Sorting/Editor/item${
                              index + 1
                            }-placeholder`,
                          )}
                          shouldHideError
                        />
                      )}
                      {index !== items.length && (
                        <>
                          <Button
                            className={cx('hidden')}
                            icon="chevron-down"
                            isDisabled={index === items.length - 1}
                            onClick={() => handleMoveDown(index)}
                            title={t('Questions/Sorting/Editor/move-down')}
                            tabIndex={-1}
                          />
                          <Button
                            className={cx('hidden')}
                            icon="chevron-up"
                            isDisabled={index === 0}
                            onClick={() => handleMoveUp(index)}
                            title={t('Questions/Sorting/Editor/move-up')}
                            tabIndex={-1}
                          />
                          <Button
                            icon="trash-alt"
                            intent="outline"
                            onClick={() => handleItemRemove(index)}
                            tooltip={t('Questions/Sorting/Editor/delete-item')}
                            variant="regular"
                          />
                        </>
                      )}
                    </div>
                  )}
                </Draggable>
              ))}
              {providedItems.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
    <InputRich
      helper={
        <Tooltip tooltip={t('Questions/Sorting/Editor/feedback-helper')}>
          <Icon name="info-circle" />
        </Tooltip>
      }
      label={t('Questions/Sorting/feedback-label')}
      name="feedback"
    />
    <Options className={cx('options')}>
      <Tags />
    </Options>
  </div>
);

Editor.propTypes = {
  className: PropTypes.string,
  dndRef: PropTypes.object.isRequired,
  handleItemAdd: PropTypes.func.isRequired,
  handleItemComplete: PropTypes.func.isRequired,
  handleItemMove: PropTypes.func.isRequired,
  handleItemRemove: PropTypes.func.isRequired,
  handleMoveDown: PropTypes.func.isRequired,
  handleMoveUp: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  t: PropTypes.func.isRequired,
};

Editor.defaultProps = {
  className: undefined,
};

export default Editor;
