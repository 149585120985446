import { useField } from 'formik';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import Editor from './Editor';

const EditorContainer = (props) => {
  const { t } = useTranslation();
  const [{ value: matches }, , { setValue: setMatches }] = useField('matches');
  const handleMatchAdd = useCallback(() => {
    setMatches([
      ...matches,
      {
        idx: matches.length,
        source: '',
        destination: '',
      },
    ]);
  }, [matches, setMatches]);
  const handleMatchCompleteSource = useCallback(
    (model) => {
      if (model) {
        setMatches([
          ...matches,
          {
            idx: matches.length,
            source: model,
            destination: '',
          },
        ]);
      }
    },
    [matches, setMatches],
  );
  const handleMatchCompleteDestination = useCallback(
    (model) => {
      if (model) {
        setMatches([
          ...matches,
          {
            idx: matches.length,
            source: '',
            destination: model,
          },
        ]);
      }
    },
    [matches, setMatches],
  );
  const handleMatchRemove = useCallback(
    (index) => {
      const newMatches = matches.filter((a, i) => i !== index);
      if (newMatches.length < 1) {
        newMatches.push({
          idx: newMatches.length,
          source: '',
          destination: '',
        });
      }
      setMatches(newMatches);
    },
    [matches, setMatches],
  );
  return (
    <Editor
      handleMatchAdd={handleMatchAdd}
      handleMatchCompleteDestination={handleMatchCompleteDestination}
      handleMatchCompleteSource={handleMatchCompleteSource}
      handleMatchRemove={handleMatchRemove}
      matches={matches}
      t={t}
      {...props}
    />
  );
};

EditorContainer.validationSchema = (t) =>
  yup.object().shape({
    tags: yup.array().of(yup.string().trim().lowercase().max(256)).compact(),
    title: yup
      .string()
      .trim()
      .transform((v) => v || t('Questions/Matching/Editor/default-title')),
    matches: yup
      .array()
      .of(
        yup.object().shape({
          idx: yup.number().required().integer().min(0),
          source: yup
            .string()
            .trim()
            .required(t('Questions/Matching/Editor/matches-error-required')),
          destination: yup
            .string()
            .trim()
            .required(t('Questions/Matching/Editor/matches-error-required')),
        }),
      )
      .compact()
      .transform((matches) =>
        matches
          .filter((match) => !!match.source && !!match.destination)
          .map((match, index) => ({
            ...match,
            idx: index,
          })),
      )
      .test(
        'length',
        t('Questions/Matching/Editor/matches-error-size'),
        (matches) => matches.length >= 2,
      ),
    feedback: yup.string().trim(),
  });

export default EditorContainer;
