import classNames from 'classnames/bind';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import DateTimePicker from 'react-datetime-picker';
import { useTranslation } from 'react-i18next';

import styles from './InputDateTime.module.scss';

const cx = classNames.bind(styles);

const InputDateTime = React.forwardRef(
  (
    {
      className,
      helper,
      isDisabled,
      intent,
      label,
      name,
      shouldHideError,
      ...props
    },
    ref,
  ) => {
    const { i18n } = useTranslation();
    const [{ value }, meta, { setValue }] = useField(name);
    return (
      <label
        className={cx(
          'container',
          `intent-${intent}`,
          { isDisabled },
          className,
        )}
        htmlFor={name}
      >
        {(label || helper) && (
          <div className={cx('title')} id={`inputdatetime-${name}-label`}>
            <div className={cx('label')}>{label}</div>
            <div className={cx('helper')}>{helper}</div>
          </div>
        )}
        <DateTimePicker
          aria-labelledby={`inputdatetime-${name}-label`}
          className={cx('field', { hasError: meta.touched && meta.error })}
          disabled={isDisabled}
          value={value && new Date(value)}
          locale={i18n.language}
          onChange={(m) => setValue(m)}
          ref={ref}
          {...props}
        />
        {!shouldHideError && meta.touched && meta.error && (
          <p className={cx('error-message')} id={`error-${name}`} role="alert">
            {meta.error}
          </p>
        )}
      </label>
    );
  },
);

InputDateTime.propTypes = {
  className: PropTypes.string,
  helper: PropTypes.node,
  isDisabled: PropTypes.bool,
  intent: PropTypes.string,
  label: PropTypes.node,
  name: PropTypes.string.isRequired,
  shouldHideError: PropTypes.bool,
};

InputDateTime.defaultProps = {
  className: undefined,
  helper: undefined,
  isDisabled: false,
  intent: 'default',
  label: undefined,
  shouldHideError: false,
};

export default InputDateTime;
