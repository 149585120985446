import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import EDITOR_STATUSES from 'constants/editorStatuses';
import { mutationEditorClear } from 'gql/mutations/editor';
import { useMutationGroupUpdate } from 'gql/mutations/group';
import { useQueryLocalEditor } from 'gql/queries/local';
import extract from 'helpers/extract';
import { trackEvent, reportError } from 'lib/tracking';

import GroupEdit from './GroupEdit';

const GroupEditContainer = (props) => {
  const { t } = useTranslation();
  const {
    data: {
      editor: { status: editorStatus, groupEditing },
    },
  } = useQueryLocalEditor();
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        title: yup
          .string()
          .trim()
          .required(t('Course/Teacher/Students/GroupEdit/title-error-required'))
          .max(
            256,
            t('Course/Teacher/Students/GroupEdit/title-error-required'),
          ),
      }),
    [t],
  );
  const mutationGroupUpdate = useMutationGroupUpdate();
  const handleSave = useCallback(
    async (values, form) => {
      try {
        form.setStatus(null);
        const variables = extract(values, validationSchema);
        await mutationGroupUpdate(groupEditing, variables);
        mutationEditorClear();
        trackEvent('group', 'update');
      } catch (err) {
        form.setStatus('500');
        reportError('group', 'update', err);
      }
    },
    [groupEditing, mutationGroupUpdate, validationSchema],
  );
  const initialValues = useMemo(
    () =>
      groupEditing
        ? {
            title: '',
            ...groupEditing,
          }
        : {},
    [groupEditing],
  );
  const isModalOpen = editorStatus === EDITOR_STATUSES.GROUP_UPDATE;
  return (
    <GroupEdit
      handleClose={mutationEditorClear}
      handleSave={handleSave}
      initialValues={initialValues}
      isModalOpen={isModalOpen}
      t={t}
      validationSchema={validationSchema}
      {...props}
    />
  );
};

export default GroupEditContainer;
