import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Cover from './Cover';
import Exam from './Exam';
import Progress from './Progress';
import styles from './Student.module.scss';

const cx = classNames.bind(styles);

const Student = ({ className, course }) => (
  <div className={className}>
    <Cover className={cx('cover')} />
    {course.isExam ? <Exam /> : <Progress />}
  </div>
);

Student.propTypes = {
  className: PropTypes.string,
  course: PropTypes.object.isRequired,
};

Student.defaultProps = {
  className: undefined,
};

export default Student;
