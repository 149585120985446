import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { Droppable } from 'react-beautiful-dnd';

import Button from 'uikit/Button';
import Icon from 'uikit/Icon';
import Menu, { MenuItem } from 'uikit/Menu';

import CourseItem from '../CourseItem';
import styles from './FolderCard.module.scss';

const cx = classNames.bind(styles);

const FolderCard = ({
  className,
  courses,
  folder,
  handleFolderDelete,
  handleFolderExpandedToggle,
  handleFolderUpdate,
  isExpanded,
  t,
}) => (
  <Droppable droppableId={folder.id} type="course">
    {(providedCourses, snapshotCourses) => (
      // eslint-disable-next-line jsx-a11y/interactive-supports-focus, jsx-a11y/click-events-have-key-events
      <div
        className={cx(
          'container',
          {
            isDraggingOver: snapshotCourses.isDraggingOver,
          },
          className,
        )}
        ref={providedCourses.innerRef}
        {...providedCourses.droppableProps}
      >
        <button
          aria-label={
            isExpanded
              ? t('Dashboard/Finder/FolderCard/aria-close-folder', {
                  name: folder.title,
                })
              : t('Dashboard/Finder/FolderCard/aria-open-folder', {
                  name: folder.title,
                })
          }
          className={cx('folder', {
            'margin-bottom': isExpanded,
          })}
          onClick={handleFolderExpandedToggle}
          type="button"
        >
          <div className={cx('toggle')}>
            <Icon name={isExpanded ? 'chevron-down' : 'chevron-right'} />
          </div>
          <div className={cx('folder-icon')}>
            <Icon name="folder" />
          </div>
          <p className={cx('title')}>{folder.title}</p>
          <p className={cx('course-number')}>{courses.length}</p>
          <div className={cx('fill')} />
          <Menu
            placement="bottom-end"
            toggle={
              <Button
                aria-label={t('Dashboard/Finder/FolderCard/aria-options', {
                  name: folder.title,
                })}
                className={cx('action')}
                icon="ellipsis-v"
                size="small"
                tooltip={t('Dashboard/Finder/FolderCard/options')}
              />
            }
          >
            <MenuItem icon="edit" onClick={handleFolderUpdate}>
              {t('Dashboard/Finder/FolderCard/rename-folder')}
            </MenuItem>
            <MenuItem
              icon="trash-alt"
              onClick={handleFolderDelete}
              variant="regular"
            >
              {t('Dashboard/Finder/FolderCard/delete-folder')}
            </MenuItem>
          </Menu>
        </button>
        {isExpanded && (
          <div
            className={cx('courses', {
              isDraggingOver: snapshotCourses.isDraggingOver,
            })}
          >
            {courses.map((course, index) => (
              <CourseItem
                className={cx('course')}
                course={course}
                folderId={folder.id}
                index={index}
                key={course.id}
              />
            ))}
            {providedCourses.placeholder}
          </div>
        )}
      </div>
    )}
  </Droppable>
);

FolderCard.propTypes = {
  className: PropTypes.string,
  courses: PropTypes.arrayOf(PropTypes.object).isRequired,
  folder: PropTypes.object.isRequired,
  handleFolderDelete: PropTypes.func.isRequired,
  handleFolderExpandedToggle: PropTypes.func.isRequired,
  handleFolderUpdate: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

FolderCard.defaultProps = {
  className: undefined,
};

export default FolderCard;
