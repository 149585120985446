import * as dayjs from 'dayjs';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'uikit/Button';
import Menu, { MenuItem } from 'uikit/Menu';

import styles from './CommentHeader.module.scss';

const cx = classNames.bind(styles);

const CommentHeader = ({
  account,
  canModify,
  className,
  comment,
  handleDelete,
  handleEdit,
  t,
}) => (
  <div className={cx('container', className)}>
    <div
      aria-hidden="true"
      className={cx('logo')}
      style={{
        backgroundImage: `url("${account.picture}")`,
      }}
    >
      {!account.picture &&
        `${account.lastName && account.lastName[0]}${
          account.firstName && account.firstName[0]
        }`.toUpperCase()}
    </div>
    <div className={cx('information')}>
      <div className={cx('main-information')}>
        <p className={cx('name')}>
          {account.firstName} {account.lastName}
        </p>
        {comment && !!comment.commentReplies && !comment.isRead && (
          <div className={cx('comment-notification')} />
        )}
      </div>
      {comment && (
        <p className={cx('date')}>
          {dayjs().to(comment.updatedAt || comment.createdAt)}
        </p>
      )}
    </div>
    {canModify && (
      <Menu
        placement="bottom-end"
        toggle={
          <Button
            className={cx('action')}
            icon="ellipsis-v"
            size="small"
            tooltip={t('Shared/Comment/CommentHeader/options')}
          />
        }
      >
        <MenuItem icon="edit" onClick={handleEdit}>
          {t('Shared/Comment/CommentHeader/update-comment')}
        </MenuItem>
        <MenuItem icon="trash-alt" onClick={handleDelete} variant="regular">
          {t('Shared/Comment/CommentHeader/delete-comment')}
        </MenuItem>
      </Menu>
    )}
  </div>
);

CommentHeader.propTypes = {
  account: PropTypes.object.isRequired,
  className: PropTypes.string,
  canModify: PropTypes.bool.isRequired,
  comment: PropTypes.object,
  handleDelete: PropTypes.func,
  handleEdit: PropTypes.func,
  t: PropTypes.func.isRequired,
};

CommentHeader.defaultProps = {
  className: undefined,
  comment: undefined,
  handleDelete: undefined,
  handleEdit: undefined,
};

export default CommentHeader;
