import classNames from 'classnames/bind';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useMutationMeUpdate } from 'gql/mutations/me';
import { trackEvent, reportError } from 'lib/tracking';

import imageBusiness from './images/business.svg';
import imageLower from './images/lower.svg';
import imageHigher from './images/higher.svg';
import styles from './Sector.module.scss';

const cx = classNames.bind(styles);

const Sector = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'App/Onboarding/Sector',
  });

  const mutationMeUpdate = useMutationMeUpdate();

  const handleSectorUpdate = async (sector) => {
    try {
      await mutationMeUpdate({
        sector,
      });
      trackEvent('migration', 'sector');
    } catch (err) {
      reportError('migration', 'sector', err);
    }
  };

  return (
    <div className={cx('container')}>
      <h1 className={cx('title')}>{t('select-sector')}</h1>
      <div className={cx('sectors')}>
        <button
          className={cx('sector')}
          onClick={() => handleSectorUpdate('lower')}
          type="button"
        >
          <img
            alt=""
            aria-hidden="true"
            className={cx('sector-image')}
            src={imageLower}
          />
          <span className={cx('sector-text')}>{t('lower')}</span>
        </button>
        <button
          className={cx('sector')}
          onClick={() => handleSectorUpdate('higher')}
          type="button"
        >
          <img
            alt=""
            aria-hidden="true"
            className={cx('sector-image')}
            src={imageHigher}
          />
          <span className={cx('sector-text')}>{t('higher')}</span>
        </button>
        <button
          className={cx('sector')}
          onClick={() => handleSectorUpdate('business')}
          type="button"
        >
          <img
            alt=""
            aria-hidden="true"
            className={cx('sector-image')}
            src={imageBusiness}
          />
          <span className={cx('sector-text')}>{t('business')}</span>
        </button>
      </div>
    </div>
  );
};

export default Sector;
