import classNames from 'classnames/bind';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import LoadingScreen from 'components/Shared/LoadingScreen';
import { useQueryCourseGames } from 'gql/queries/course';
import {
  mutationEditorReset,
  mutationEditorGameCreate,
} from 'gql/mutations/editor';
import Button from 'uikit/Button';

import Empty from './Empty/Empty';
import GameCard from './GameCard/GameCard';
import GameCreate from './GameCreate/GameCreate';
import GameDelete from './GameDelete/GameDelete';
import GameUpdate from './GameUpdate/GameUpdate';
import styles from './Games.module.scss';

const cx = classNames.bind(styles);

const Games = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/Games',
  });

  const { courseId } = useParams();

  const { isLoading, data } = useQueryCourseGames({
    variables: {
      courseId,
    },
  });

  useEffect(() => {
    mutationEditorReset();
    return () => {
      mutationEditorReset();
    };
  }, []);

  const isEmpty = isLoading ? false : data.course.games.length === 0;

  const course = data?.course;

  return isLoading ? (
    <LoadingScreen />
  ) : (
    <>
      <div className={cx('container')}>
        <div className={cx('header')}>
          <h3 className={cx('title')}>
            {t('title')}
            <span className={cx('count')}>{course.games.length}</span>
          </h3>
          <Button icon="plus" intent="white" onClick={mutationEditorGameCreate}>
            {t('create-game')}
          </Button>
        </div>
        {isEmpty ? (
          <Empty />
        ) : (
          course.games.map((game) => (
            <GameCard className={cx('game')} game={game} key={game.id} />
          ))
        )}
      </div>
      <GameCreate course={course} />
      <GameDelete />
      <GameUpdate course={course} />
    </>
  );
};

export default Games;
