import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import {
  getDenormalizedPinPosition,
  getDenormalizedZonePosition,
} from 'questions/Shared/utils/images';
import Pin from './Pin';
import Zone from './Zone';
import styles from './ImageContainer.module.scss';

const cx = classNames.bind(styles);

const ImageContainer = ({
  className,
  image,
  handleImageLoaded,
  imageRef,
  imageSrc,
  isReport,
  result,
  solution,
  userPins,
  wrapperRef,
}) => (
  <div className={cx('container', className)} ref={wrapperRef}>
    <img
      alt="img"
      className={cx('image')}
      draggable="false"
      onLoad={handleImageLoaded}
      ref={imageRef}
      src={imageSrc}
      style={{
        width: image.width,
        height: image.height,
      }}
    />
    {image.diagonal && isReport
      ? userPins.map((pin, index) => (
          <Pin
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            result={pin.result}
            sizeData={getDenormalizedPinPosition(
              {
                x: pin.coordination.x,
                y: pin.coordination.y,
              },
              image.diagonal,
            )}
          />
        ))
      : image.diagonal &&
        solution.userX &&
        solution.userY && (
          <Pin
            result={result}
            sizeData={getDenormalizedPinPosition(
              {
                x: solution.userX,
                y: solution.userY,
              },
              image.diagonal,
            )}
          />
        )}
    {image.diagonal &&
      solution.zones.map((zone, index) => (
        <Zone
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          shapeType={zone.shapeType}
          sizeData={getDenormalizedZonePosition(zone, image.diagonal)}
        />
      ))}
  </div>
);

ImageContainer.propTypes = {
  className: PropTypes.string,
  handleImageLoaded: PropTypes.func.isRequired,
  imageSrc: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  imageRef: PropTypes.object.isRequired,
  isReport: PropTypes.bool,
  result: PropTypes.string,
  solution: PropTypes.object.isRequired,
  userPins: PropTypes.arrayOf(PropTypes.object),
  wrapperRef: PropTypes.object.isRequired,
};

ImageContainer.defaultProps = {
  className: undefined,
  isReport: undefined,
  result: undefined,
  userPins: undefined,
};

export default ImageContainer;
