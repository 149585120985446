import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import Standalone from './Standalone';

const StandaloneContainer = ({ courses, ...props }) => {
  const filteredCourses = useMemo(
    () => courses.filter((course) => course.folderId === null),
    [courses],
  );
  return <Standalone courses={filteredCourses} {...props} />;
};

StandaloneContainer.propTypes = {
  courses: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default React.memo(StandaloneContainer);
