import PropTypes from 'prop-types';
import React from 'react';

import { getQuestionAggregate, getQuestionInfo } from 'questions';

import ReportEmpty from '../../../../Shared/ReportEmpty';

const Report = ({ aggregatedStudyAnswers }) => {
  const aggregateComponents = aggregatedStudyAnswers.map((question) =>
    getQuestionAggregate(question.question.type),
  );

  const questionInfos = aggregatedStudyAnswers.map((aggregatedStudyAnswer) =>
    getQuestionInfo(aggregatedStudyAnswer.question.type),
  );

  return (
    <>
      {aggregatedStudyAnswers.map((aggregatedStudyAnswer, index) => {
        const AggregateQuestion = aggregateComponents[index];
        return (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index}>
            {!questionInfos[index]?.isReportable ||
            aggregatedStudyAnswer?.aggregation?.isEmpty ? (
              <ReportEmpty
                isUnsupported={!questionInfos[index]?.isReportable}
                question={aggregatedStudyAnswer.question}
                questionInfo={questionInfos[index]}
              />
            ) : (
              aggregatedStudyAnswer &&
              questionInfos[index] && (
                <AggregateQuestion
                  aggregation={aggregatedStudyAnswer.aggregation}
                  question={aggregatedStudyAnswer.question}
                  questionInfo={questionInfos[index]}
                />
              )
            )}
          </div>
        );
      })}
    </>
  );
};

Report.propTypes = {
  aggregatedStudyAnswers: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Report;
