import PropTypes from 'prop-types';
import React, { useCallback, useContext, useRef, useState } from 'react';
import { useLocation } from 'react-router';

import formatMediaDuration from 'helpers/formatMediaDuration';
import Context from 'lib/context';

import VideoPreview from './VideoPreview';

const VideoPreviewContainer = ({ setVideoDuration, ...props }) => {
  const { theme } = useContext(Context);
  const { pathname } = useLocation();
  const isStudying = pathname.includes('/study');
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef();
  const handlePlay = useCallback(() => {
    setIsPlaying(true);
  }, []);
  const handleVideoDurationSet = useCallback(() => {
    if (videoRef.current) {
      formatMediaDuration(videoRef.current.duration, setVideoDuration);
    }
    setIsPlaying(false);
  }, [setVideoDuration, videoRef]);
  const handleVideoEnd = useCallback(() => {
    setIsPlaying(false);
  }, []);
  return (
    <VideoPreview
      handleVideoDurationSet={handleVideoDurationSet}
      handlePlay={handlePlay}
      handleVideoEnd={handleVideoEnd}
      isPlaying={isPlaying}
      theme={isStudying ? theme : {}}
      videoRef={videoRef}
      {...props}
    />
  );
};

VideoPreviewContainer.propTypes = {
  setVideoDuration: PropTypes.func.isRequired,
};

export default VideoPreviewContainer;
