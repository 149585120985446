import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import Context from 'lib/context';

import { formatMatches } from '../utils';
import Recto from './Recto';

const RectoContainer = ({ isDisabled, question, ...props }) => {
  const { t } = useTranslation();
  const { theme } = useContext(Context);
  const [selectedSourceId, setSelectedSourceId] = useState(-1);
  const [{ value: answer }, , { setValue: setAnswer }] = useField('answer');
  const handleSourceRemove = useCallback(
    (idx) => {
      if (!isDisabled) {
        setAnswer(
          answer.map((destinationIdx, sourceId) =>
            sourceId === idx ? -1 : destinationIdx,
          ),
        );
      }
    },
    [answer, isDisabled, setAnswer],
  );
  const handleSourceSelect = useCallback(
    (idx) => {
      if (!isDisabled) {
        setSelectedSourceId(idx);
      }
    },
    [isDisabled],
  );
  const handleDestinationSelect = useCallback(
    (idx) => {
      setAnswer(
        answer.map((destinationIdx, sourceId) =>
          sourceId === selectedSourceId
            ? idx
            : destinationIdx === idx
            ? -1
            : destinationIdx,
        ),
      );
      setSelectedSourceId(-1);
    },
    [answer, selectedSourceId, setAnswer],
  );
  const matches = useMemo(
    () => formatMatches(question.content.matches),
    [question],
  );
  const selectedSource =
    selectedSourceId === -1 ? undefined : matches[selectedSourceId];
  return (
    <Recto
      answer={answer}
      handleDestinationSelect={handleDestinationSelect}
      handleSourceRemove={handleSourceRemove}
      handleSourceSelect={handleSourceSelect}
      isDisabled={isDisabled}
      matches={matches}
      question={question}
      selectedSource={selectedSource}
      t={t}
      theme={theme}
      {...props}
    />
  );
};

RectoContainer.propTypes = {
  isDisabled: PropTypes.bool,
  question: PropTypes.object.isRequired,
};

RectoContainer.defaultProps = {
  isDisabled: false,
};

RectoContainer.defaultAnswer = (question) =>
  question.content.matches.map(() => -1);

RectoContainer.validationSchema = () =>
  yup.object().shape({
    answer: yup
      .array()
      .of(yup.number().required().integer())
      .test('atLeastOne', (arr) => arr.some((v) => v !== -1)),
  });

export default RectoContainer;
