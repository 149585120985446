import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import Context from 'lib/context';

import Verso from './Verso';

const VersoContainer = (props) => {
  const { t } = useTranslation();
  const { theme } = useContext(Context);
  return <Verso {...props} t={t} theme={theme} />;
};

VersoContainer.propTypes = {
  question: PropTypes.object.isRequired,
};

VersoContainer.defaultAnswer = (question) =>
  (question.content.text.match(/\[{2}.*?\]*\]{2}|\{{2}.*?\}*\}{2}/g) || []).map(
    () => '',
  );

VersoContainer.defaultSolution = (question) => {
  const blanks = Array.from(
    question.content.text.matchAll(/(\[{2}.*?\]*\]{2})|(\{{2}.*?\}*\}{2})/g),
    (m) => m[1] || m[2],
  );
  const solution = blanks.map((blank) => {
    const isSelect = blank[0] === '{';
    const inner = blank.substring(2, blank.length - 2);
    const decomposedInner = inner
      .replace(/\\,/g, '#_FAKE_COMMA_#')
      .split(',')
      .map((ele) => ele.replace(/#_FAKE_COMMA_#/g, ','));
    if (isSelect) {
      const [acceptedAnswer] = decomposedInner;
      return {
        user: null,
        exact: null,
        correct: [acceptedAnswer],
        isCorrect: false,
      };
    }
    return {
      user: null,
      exact: null,
      correct: decomposedInner,
      isCorrect: false,
    };
  });
  return solution;
};

export default VersoContainer;
