import froalaeditor from 'froala-editor';

froalaeditor.POPUP_TEMPLATES['physicsPlugin.popup'] = '[_CUSTOM_LAYER_]';

froalaeditor.PLUGINS.physicsPlugin = (editor) => {
  function initPopup() {
    const render = (str) =>
      window.katex
        ? window.katex.renderToString(str, {
            throwOnError: false,
          })
        : str;
    const template = {
      custom_layer: `
        <div class="fr-popup fr-active">
          <div class="fr-icon-container fr-sc-container">
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addPhysics" data-param1="ε_0">${render(
              '\\epsilon_0',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addPhysics" data-param1="μ_0">${render(
              '\\mu_0',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addPhysics" data-param1="ℏ">${render(
              'ℏ',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addPhysics" data-param1="vec(1_x)">${render(
              '\\vec{1_x}',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addPhysics" data-param1="vec(1_y)">${render(
              '\\vec{1_y}',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addPhysics" data-param1="vec(1_z)">${render(
              '\\vec{1_z}',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addPhysics" data-param1="vec(1_r)">${render(
              '\\vec{1_r}',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addPhysics" data-param1="vec(1_θ)">${render(
              '\\vec{1_\\theta}',
            )}</span>
          </div>
        </div>`,
    };
    return editor.popups.create('physicsPlugin.popup', template);
  }
  function showPopup() {
    let $popup = editor.popups.get('physicsPlugin.popup');
    if (!$popup) {
      $popup = initPopup();
    }
    editor.popups.setContainer('physicsPlugin.popup', editor.$tb);
    const $btn = editor.$tb.find('.fr-command[data-cmd="insertPhysics"]');
    const { left } = $btn.offset();
    const top =
      $btn.offset().top +
      (editor.opts.toolbarBottom ? 10 : $btn.outerHeight() - 10);
    editor.popups.show('physicsPlugin.popup', left, top, $btn.outerHeight());
  }
  function hidePopup() {
    editor.popups.hide('physicsPlugin.popup');
  }
  return {
    showPopup,
    hidePopup,
  };
};

froalaeditor.DefineIcon('physicsIcon', {
  FA5NAME: 'atom',
  template: 'font_awesome_5',
});

froalaeditor.RegisterCommand('insertPhysics', {
  title: 'Physique',
  icon: 'physicsIcon',
  showOnMobile: true,
  undo: false,
  focus: false,
  callback: function callback() {
    this.physicsPlugin.showPopup();
  },
});

froalaeditor.RegisterCommand('addPhysics', {
  undo: true,
  focus: true,
  callback: function callback(cmd, val) {
    this.html.insert(` ${val} `);
    this.physicsPlugin.hidePopup();
  },
});
