import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useMutationCourseDuplicate } from 'gql/mutations/course';
import useModal from 'hooks/useModal';
import history from 'lib/history';
import { trackEvent, reportError } from 'lib/tracking';
import { notify } from 'uikit/Notifications';

import CourseDuplicate from './CourseDuplicate';

const CourseDuplicateContainer = ({ course, ...props }) => {
  const { t } = useTranslation();
  const mutationCourseDuplicate = useMutationCourseDuplicate();
  const [status, setStatus] = useState(undefined);
  const [isLoading, setLoading] = useState(false);
  const { isModalOpen, handleModalOpen, handleModalClose } = useModal(false);
  useEffect(() => {
    setStatus(undefined);
  }, [isModalOpen]);
  const handleCourseDuplicate = useCallback(async () => {
    try {
      setStatus(undefined);
      setLoading(true);
      const {
        data: { courseDuplicate },
      } = await mutationCourseDuplicate({
        course,
        prefix: t('Course/Teacher/Cover/CourseDuplicate/copy-of'),
      });
      handleModalClose();
      notify(
        'success',
        t('Course/Teacher/Cover/CourseDuplicate/course-duplicate-success'),
      );
      history.push(`/course/${courseDuplicate.id}/`);
      trackEvent('course', 'duplicate');
    } catch (err) {
      setStatus('500');
      reportError('course', 'duplicate', err);
    } finally {
      setLoading(false);
    }
  }, [course, handleModalClose, mutationCourseDuplicate, t]);
  return (
    <CourseDuplicate
      handleCourseDuplicate={handleCourseDuplicate}
      handleModalClose={handleModalClose}
      handleModalOpen={handleModalOpen}
      isLoading={isLoading}
      isModalOpen={isModalOpen}
      status={status}
      t={t}
      {...props}
    />
  );
};

CourseDuplicateContainer.propTypes = {
  course: PropTypes.object.isRequired,
};

export default CourseDuplicateContainer;
