import froalaeditor from 'froala-editor';

froalaeditor.POPUP_TEMPLATES['code.insert'] = '[_CUSTOM_LAYER_]';

froalaeditor.PLUGINS.code = (editor) => {
  function initPopup() {
    const template = {
      custom_layer: `
        <div class="fr-layer fr-active">
          <div class="fr-input-line">
            <textarea id="fr-code-code" rows="5"></textarea>
            <label for="fr-code-code">Code</label>
          </div>
          <div class="fr-input-line">
            <label for="fr-code-lang">Lang : </label>
            <select id="fr-code-lang" type="text">
              <option value="bash">Bash</option>
              <option value="c">C</option>
              <option value="cs">C#</option>
              <option value="c++">C++</option>
              <option value="css">CSS</option>
              <option value="diff">Diff</option>
              <option value="html">HTML</option>
              <option value="http">HTTP</option>
              <option value="json">JSON</option>
              <option value="java">Java</option>
              <option value="makefile">Makefile</option>
              <option value="markdown">Markdown</option>
              <option value="objectivec">ObjectiveC</option>
              <option value="php">PHP</option>
              <option value="perl">Perl</option>
              <option value="python">Python</option>
              <option value="ruby">Ruby</option>
              <option value="sql">SQL</option>
              <option value="shell">Shell</option>
              <option value="xml">XML</option>
              <option value="">Other</option>
            </select>
          </div>
          <div class="fr-action-buttons">
            <button class="fr-command fr-submit" data-cmd="codeInsert" type="button">
              ${editor.language.translate('Insert')}
            </button>
          </div>
        </div>`,
    };
    return editor.popups.create('code.insert', template);
  }

  function showPopup() {
    let $popup = editor.popups.get('code.insert');
    if (!$popup) {
      $popup = editor.code.initPopup();
    }
    editor.popups.setContainer('code.insert', editor.$tb);
    const $btn = editor.$tb.find('.fr-command[data-cmd="insertCode"]');
    const { left } = $btn.offset();
    const top =
      $btn.offset().top +
      (editor.opts.toolbarBottom ? 10 : $btn.outerHeight() - 10);
    editor.popups.show('code.insert', left, top, $btn.outerHeight());
  }

  function hidePopup() {
    editor.popups.hide('code.insert');
  }

  function insert() {
    const codeInput = document.getElementById('fr-code-code');
    const langInput = document.getElementById('fr-code-lang');
    if (codeInput && langInput) {
      const value = codeInput.value.trim();
      if (value) {
        const shell = document.createElement('div');
        const pre = document.createElement('pre');
        const code = document.createElement('code');
        code.setAttribute('class', langInput.value);
        code.innerText = value;
        pre.appendChild(code);
        shell.appendChild(pre);
        editor.html.insert(shell.innerHTML);
        editor.undo.saveStep();
        codeInput.value = '';
        editor.code.hidePopup();
      }
    }
  }

  return {
    initPopup,
    showPopup,
    hidePopup,
    insert,
  };
};

froalaeditor.DefineIcon('insertCode', {
  FA5NAME: 'brackets-curly',
  template: 'font_awesome_5',
});

froalaeditor.RegisterCommand('insertCode', {
  title: 'Insérer du code',
  callback() {
    this.code.showPopup();
  },
});

froalaeditor.RegisterCommand('codeInsert', {
  callback() {
    this.code.insert();
  },
});
