/* eslint-disable no-param-reassign */
import { gql, useMutation } from '@apollo/client';
import produce from 'immer';
import { useCallback } from 'react';

import STUDY_DISPLAY_STATUSES from 'constants/studyDisplayStatuses';
import { STUDY_ANSWER_DATA, STUDY_RESPONSE_DATA } from 'gql/fragments';
import { studyVar } from 'gql/local';
import { getQuestionInfo, shouldBeInScore } from 'questions';

const initialState = {
  studySession: null,
  chapters: null,
  chaptersIndex: 0,
  questions: [],
  questionsIndex: 0,
  performanceIndex: null,
  display: STUDY_DISPLAY_STATUSES.LOADING,
  transition: 'next',
  stats: {
    nTotal: 0,
    nCorrect: 0,
    nWrong: 0,
    nUnknown: 0,
    nSkip: 0,
  },
  hasMascotFlag: false,
  isPartialForceCorrect: false,
};

const shouldSeeRepetitionScreen = (draft) => {
  const question =
    draft.questions[
      draft.display === STUDY_DISPLAY_STATUSES.MASCOT.CORRECT ||
      draft.display === STUDY_DISPLAY_STATUSES.MASCOT.WRONG
        ? draft.questionsIndex - 1
        : draft.questionsIndex
    ];
  const nextQuestion =
    draft.questions[
      draft.display === STUDY_DISPLAY_STATUSES.MASCOT.CORRECT ||
      draft.display === STUDY_DISPLAY_STATUSES.MASCOT.WRONG
        ? draft.questionsIndex
        : draft.questionsIndex + 1
    ];
  return question.result === 'skip' ||
    question.result === 'wrong' ||
    question.result === 'unknown'
    ? !question.isRepeated && (!nextQuestion || nextQuestion.isRepeated)
    : !question.isRepeated &&
        nextQuestion?.isRepeated &&
        draft.display !== STUDY_DISPLAY_STATUSES.REPETITION;
};

const shouldSeeMascotCorrect = (draft) => {
  const N_QUESTIONS_IN_A_ROW_MULTIPLE = 5;
  const showMascot = Math.random() < 0.5;
  if (!draft.hasMascotFlag || !showMascot) {
    return false;
  }
  const answeredQuestions = draft.questions.filter(
    (question) => question.isAnswered,
  );
  const correctAnswerIndexes = answeredQuestions
    .map((question, index) => ({
      initialIndex: index,
      result: question.result,
    }))
    .filter((question) => question.result === 'correct');
  const lastCorrectAnswerIndexes = correctAnswerIndexes.filter(
    (question, index) =>
      question.initialIndex + (correctAnswerIndexes.length - index) ===
      answeredQuestions.length,
  );
  return (
    lastCorrectAnswerIndexes.length &&
    lastCorrectAnswerIndexes.length % N_QUESTIONS_IN_A_ROW_MULTIPLE === 0
  );
};

const shouldSeeMascotWrong = (draft) => {
  const N_QUESTIONS_IN_A_ROW_MULTIPLE = 5;
  const showMascot = Math.random() < 0.5;
  if (!draft.hasMascotFlag || !showMascot) {
    return false;
  }
  const answeredQuestions = draft.questions.filter(
    (question) => question.isAnswered,
  );
  const wrongAnswerIndexes = answeredQuestions
    .map((question, index) => ({
      initialIndex: index,
      result: question.result,
    }))
    .filter(
      (question) =>
        question.result === 'skip' ||
        question.result === 'wrong' ||
        question.result === 'unknown',
    );
  const lastWrongAnswerIndexes = wrongAnswerIndexes.filter(
    (question, index) =>
      question.initialIndex + (wrongAnswerIndexes.length - index) ===
      answeredQuestions.length,
  );
  return (
    lastWrongAnswerIndexes.length &&
    lastWrongAnswerIndexes.length % N_QUESTIONS_IN_A_ROW_MULTIPLE === 0
  );
};

const shouldSeeMascotPercentageComplete = (draft) => {
  if (!draft.hasMascotFlag) {
    return false;
  }
  const PERCENTAGE_COMPLETED = 0.75;
  const initialQuestionsLength = draft.questions.filter(
    (question) => !question.isRepeated,
  ).length;
  return (
    initialQuestionsLength >= 20 &&
    draft.questionsIndex ===
      Math.floor(initialQuestionsLength * PERCENTAGE_COMPLETED) &&
    draft.display !== STUDY_DISPLAY_STATUSES.MASCOT.COMPLETE
  );
};

const prepareSolution = (question, isRepeated) => {
  const solution = question.solution;
  const isQuestionPartiallyAnswerable = [
    'LabelOnImage',
    'FillInTheBlanks',
  ].includes(question.type);
  const isAnswered = question.isAnswered;
  const isResultWrong = question.result === 'wrong';
  const shouldKeepSolution =
    isQuestionPartiallyAnswerable && isAnswered && isResultWrong && isRepeated;
  if (shouldKeepSolution) {
    const preparedSolution = solution.map((item) => ({
      user: item.user,
      correct: item.correct,
      isCorrect: item.isCorrect,
      isForced: item.isForced,
    }));
    return preparedSolution;
  }
  return undefined;
};

const prepareQuestion = (question, isRepeated = false) => ({
  ...question,
  studyAnswerId: null,
  answer: undefined,
  result: undefined,
  solution: prepareSolution(question, isRepeated),
  isAnswered: false,
  isFlipped: false,
  isRepeated,
});

export async function mutationStudyReset() {
  let hasMascotFlag;
  studyVar(
    produce(studyVar(), (draft) => {
      hasMascotFlag = draft.hasMascotFlag;
    }),
  );
  studyVar({ ...initialState, hasMascotFlag });
}

export const STUDY_RESUME = gql`
  mutation StudyResume($courseId: ID!, $chapterId: ID, $method: String) {
    studyResume(courseId: $courseId, chapterId: $chapterId, method: $method)
  }
`;

export const useMutationStudyResume = () => {
  const [mutation] = useMutation(STUDY_RESUME, {
    onCompleted: async ({ studyResume }) => {
      studyVar(
        produce(studyVar(), (draft) => {
          if (studyResume) {
            draft.studySession = studyResume.studySession;
            draft.chapters = studyResume.chapters;
            draft.chaptersIndex = 0;
            draft.questions = studyResume.chapters[
              draft.chaptersIndex
            ].questions.map((question) => prepareQuestion(question));
            draft.questionsIndex = 0;
            draft.performanceIndex = null;
            draft.display = STUDY_DISPLAY_STATUSES.QUESTIONS;
            draft.transition = 'next';
            draft.stats = {
              nTotal: 0,
              nCorrect: 0,
              nWrong: 0,
              nUnknown: 0,
              nSkip: 0,
            };
          } else {
            draft.display = STUDY_DISPLAY_STATUSES.START;
          }
        }),
      );
    },
  });
  return useCallback(
    (variables) =>
      mutation({
        variables,
      }),
    [mutation],
  );
};

export const mutationStudyInit = () => {
  studyVar(
    produce(studyVar(), (draft) => {
      draft.display = STUDY_DISPLAY_STATUSES.START;
    }),
  );
};

export const STUDY_START = gql`
  mutation StudyStart($courseId: ID!, $chapterId: ID, $method: String) {
    studyStart(courseId: $courseId, chapterId: $chapterId, method: $method)
  }
`;

export const useMutationStudyStart = () => {
  const [mutation] = useMutation(STUDY_START, {
    onCompleted: async ({ studyStart }) => {
      studyVar(
        produce(studyVar(), (draft) => {
          if (studyStart.isNothingToStudy) {
            draft.display = STUDY_DISPLAY_STATUSES.NOTHING;
          } else if (studyStart.isEmpty) {
            draft.display = STUDY_DISPLAY_STATUSES.EMPTY;
          } else {
            draft.studySession = studyStart.studySession;
            draft.chapters = studyStart.chapters;
            draft.chaptersIndex = 0;
            draft.questions = studyStart.chapters[
              draft.chaptersIndex
            ].questions.map((question) => prepareQuestion(question));
            draft.questionsIndex = 0;
            draft.performanceIndex = null;
            draft.display = STUDY_DISPLAY_STATUSES.QUESTIONS;
            draft.transition = 'next';
            draft.stats = {
              nTotal: 0,
              nCorrect: 0,
              nWrong: 0,
              nUnknown: 0,
              nSkip: 0,
            };
          }
        }),
      );
    },
  });
  return useCallback(
    (variables) =>
      mutation({
        variables,
      }),
    [mutation],
  );
};

export const mutationStudyStop = () => {
  studyVar(
    produce(studyVar(), (draft) => {
      draft.display = STUDY_DISPLAY_STATUSES.SUMMARY;
    }),
  );
};

export const mutationStudyPerformancePreview = (question) => {
  studyVar(
    produce(studyVar(), (draft) => {
      draft.performanceIndex = question;
    }),
  );
};

export const mutationStudyContinue = () => {
  studyVar(
    produce(studyVar(), (draft) => {
      draft.display = STUDY_DISPLAY_STATUSES.QUESTIONS;
      draft.transition = 'next';
      if (draft.questionsIndex === draft.questions.length - 1) {
        draft.questions = draft.chapters[draft.chaptersIndex + 1].questions.map(
          (question) => prepareQuestion(question),
        );
        draft.chaptersIndex += 1;
        draft.questionsIndex = 0;
      }
    }),
  );
};

export const mutationStudyQuestionNext = () => {
  studyVar(
    produce(studyVar(), (draft) => {
      const question = draft.questions[draft.questionsIndex];
      if (question.isAnswered) {
        draft.display =
          draft.questionsIndex < draft.questions.length - 1
            ? shouldSeeMascotCorrect(draft)
              ? STUDY_DISPLAY_STATUSES.MASCOT.CORRECT
              : shouldSeeMascotWrong(draft)
              ? STUDY_DISPLAY_STATUSES.MASCOT.WRONG
              : shouldSeeMascotPercentageComplete(draft)
              ? STUDY_DISPLAY_STATUSES.MASCOT.COMPLETE
              : shouldSeeRepetitionScreen(draft)
              ? STUDY_DISPLAY_STATUSES.REPETITION
              : STUDY_DISPLAY_STATUSES.QUESTIONS
            : STUDY_DISPLAY_STATUSES.SUMMARY;
        draft.transition = 'next';
        draft.questionsIndex = Math.max(
          0,
          Math.min(draft.questions.length - 1, draft.questionsIndex + 1),
        );
      }
    }),
  );
};

export const mutationStudyQuestionFlip = () => {
  studyVar(
    produce(studyVar(), (draft) => {
      const question = draft.questions[draft.questionsIndex];
      const questionInfo = getQuestionInfo(question.type);
      if (!questionInfo.isCorrectable) {
        question.isFlipped = !draft.questions[draft.questionsIndex].isFlipped;
        draft.transition = 'flip';
      }
    }),
  );
};

export const STUDY_ANSWER_CREATE = gql`
  mutation StudyAnswerCreate(
    $questionId: ID!
    $studySessionId: ID!
    $answer: JSON!
    $isRepeated: Boolean
  ) {
    studyAnswerCreate(
      questionId: $questionId
      studySessionId: $studySessionId
      answer: $answer
      isRepeated: $isRepeated
    ) {
      ...StudyResponseData
    }
  }
  ${STUDY_RESPONSE_DATA}
`;

export const useMutationStudyQuestionCorrect = () => {
  const [mutation] = useMutation(STUDY_ANSWER_CREATE, {
    onCompleted: async () => {
      studyVar(
        produce(studyVar(), (draft) => {
          const question = draft.questions[draft.questionsIndex];
          question.studyAnswerId = null;
          question.answer = true;
          question.result = 'correct';
          question.solution = null;
          question.isAnswered = true;
          if (shouldBeInScore(question.type)) {
            draft.stats.nTotal += 1;
            draft.stats.nCorrect += 1;
          }
          if (draft.questionsIndex === draft.questions.length - 1) {
            draft.display = STUDY_DISPLAY_STATUSES.SUMMARY;
          } else {
            draft.display = shouldSeeMascotCorrect(draft)
              ? STUDY_DISPLAY_STATUSES.MASCOT.CORRECT
              : shouldSeeMascotPercentageComplete(draft)
              ? STUDY_DISPLAY_STATUSES.MASCOT.COMPLETE
              : shouldSeeRepetitionScreen(draft)
              ? STUDY_DISPLAY_STATUSES.REPETITION
              : draft.display;
            draft.questionsMaxIndex += 1;
            draft.questionsIndex += 1;
          }
          draft.transition = 'next';
        }),
      );
    },
  });
  return useCallback(() => {
    const study = studyVar();
    const question = study.questions[study.questionsIndex];
    const questionInfo = getQuestionInfo(question.type);
    if (question.isAnswered || questionInfo.isCorrectable) {
      return null;
    }
    return mutation({
      variables: {
        questionId: question.id,
        studySessionId: study.studySession.id,
        answer: true,
        isRepeated: question.isRepeated,
      },
    });
  }, [mutation]);
};

export const useMutationStudyQuestionWrong = () => {
  const [mutation] = useMutation(STUDY_ANSWER_CREATE, {
    onCompleted: async () => {
      studyVar(
        produce(studyVar(), (draft) => {
          const question = draft.questions[draft.questionsIndex];
          question.studyAnswerId = null;
          question.answer = false;
          question.result = 'wrong';
          question.solution = null;
          question.isAnswered = true;
          if (shouldBeInScore(question.type)) {
            draft.stats.nTotal += 1;
            draft.stats.nWrong += 1;
          }
          if (!question.isRepeated) {
            draft.questions = [
              ...draft.questions,
              prepareQuestion(question, true),
            ];
          }
          if (draft.questionsIndex === draft.questions.length - 1) {
            draft.display = STUDY_DISPLAY_STATUSES.SUMMARY;
          } else {
            draft.display = shouldSeeMascotWrong(draft)
              ? STUDY_DISPLAY_STATUSES.MASCOT.WRONG
              : shouldSeeMascotPercentageComplete(draft)
              ? STUDY_DISPLAY_STATUSES.MASCOT.COMPLETE
              : shouldSeeRepetitionScreen(draft)
              ? STUDY_DISPLAY_STATUSES.REPETITION
              : draft.display;
            draft.questionsMaxIndex += 1;
            draft.questionsIndex += 1;
          }
          draft.transition = 'next';
        }),
      );
    },
  });
  return useCallback(() => {
    const study = studyVar();
    const question = study.questions[study.questionsIndex];
    const questionInfo = getQuestionInfo(question.type);
    if (question.isAnswered || questionInfo.isCorrectable) {
      return null;
    }
    return mutation({
      variables: {
        questionId: question.id,
        studySessionId: study.studySession.id,
        answer: false,
        isRepeated: question.isRepeated,
      },
    });
  }, [mutation]);
};

export const useMutationStudyQuestionAnswer = () => {
  const [mutation] = useMutation(STUDY_ANSWER_CREATE, {
    onCompleted: async ({ studyAnswerCreate }) => {
      studyVar(
        produce(studyVar(), (draft) => {
          const {
            studyAnswerId,
            correction: { content, answer, result, solution },
          } = studyAnswerCreate;
          const question = draft.questions[draft.questionsIndex];
          question.content = {
            ...question.content,
            ...content,
          };
          question.studyAnswerId = studyAnswerId;
          question.answer = answer;
          question.result = result;
          question.solution = solution;
          question.isAnswered = true;
          question.isFlipped = true;
          if (shouldBeInScore(question.type)) {
            draft.stats.nTotal += 1;
          }
          if (result === 'correct' && shouldBeInScore(question.type)) {
            draft.stats.nCorrect += 1;
          } else if (result === 'wrong' && shouldBeInScore(question.type)) {
            draft.stats.nWrong += 1;
          }
          if (result === 'wrong' && !question.isRepeated) {
            draft.questions = [
              ...draft.questions,
              prepareQuestion(question, true),
            ];
          }
          draft.questionsMaxIndex += 1;
          draft.transition = 'flip';
        }),
      );
    },
  });
  return useCallback(
    (answer) => {
      const study = studyVar();
      const question = study.questions[study.questionsIndex];
      const questionInfo = getQuestionInfo(question.type);
      if (question.isAnswered || !questionInfo.isCorrectable) {
        return null;
      }
      return mutation({
        variables: {
          questionId: question.id,
          studySessionId: study.studySession.id,
          answer,
          isRepeated: question.isRepeated,
        },
      });
    },
    [mutation],
  );
};

export const STUDY_ANSWER_UNKNOWN = gql`
  mutation StudyAnswerUnknown(
    $questionId: ID!
    $studySessionId: ID!
    $isRepeated: Boolean
  ) {
    studyAnswerUnknown(
      questionId: $questionId
      studySessionId: $studySessionId
      isRepeated: $isRepeated
    ) {
      ...StudyResponseData
    }
  }
  ${STUDY_RESPONSE_DATA}
`;

export const useMutationStudyQuestionUnknown = () => {
  const [mutation] = useMutation(STUDY_ANSWER_UNKNOWN, {
    onCompleted: async ({ studyAnswerUnknown }) => {
      studyVar(
        produce(studyVar(), (draft) => {
          const {
            correction: { content, answer, result, solution },
          } = studyAnswerUnknown;
          const question = draft.questions[draft.questionsIndex];
          question.content = {
            ...question.content,
            ...content,
          };
          question.answer = answer;
          question.result = result;
          question.solution = solution;
          question.isAnswered = true;
          question.isFlipped = true;
          if (shouldBeInScore(question.type)) {
            draft.stats.nTotal += 1;
            draft.stats.nUnknown += 1;
          }
          if (!question.isRepeated) {
            draft.questions = [
              ...draft.questions,
              prepareQuestion(question, true),
            ];
          }
          draft.questionsMaxIndex += 1;
          draft.transition = 'flip';
        }),
      );
    },
  });
  return useCallback(() => {
    const study = studyVar();
    const question = study.questions[study.questionsIndex];
    const questionInfo = getQuestionInfo(question.type);
    if (question.isAnswered || !questionInfo.isCorrectable) {
      return null;
    }
    return mutation({
      variables: {
        questionId: question.id,
        studySessionId: study.studySession.id,
        isRepeated: question.isRepeated,
      },
    });
  }, [mutation]);
};

export const STUDY_ANSWER_SKIP = gql`
  mutation StudyAnswerSkip(
    $questionId: ID!
    $studySessionId: ID!
    $isRepeated: Boolean
  ) {
    studyAnswerSkip(
      questionId: $questionId
      studySessionId: $studySessionId
      isRepeated: $isRepeated
    )
  }
`;
export const useMutationStudyQuestionSkip = () => {
  const [mutation] = useMutation(STUDY_ANSWER_SKIP, {
    onCompleted: async () => {
      studyVar(
        produce(studyVar(), (draft) => {
          const question = draft.questions[draft.questionsIndex];
          question.result = 'skip';
          question.isAnswered = true;
          if (shouldBeInScore(question.type)) {
            draft.stats.nTotal += 1;
            draft.stats.nSkip += 1;
          }
          if (draft.questionsIndex === draft.questions.length - 1) {
            draft.display = STUDY_DISPLAY_STATUSES.SUMMARY;
          } else {
            draft.display = shouldSeeMascotWrong(draft)
              ? STUDY_DISPLAY_STATUSES.MASCOT.WRONG
              : shouldSeeMascotPercentageComplete(draft)
              ? STUDY_DISPLAY_STATUSES.MASCOT.COMPLETE
              : shouldSeeRepetitionScreen(draft)
              ? STUDY_DISPLAY_STATUSES.REPETITION
              : draft.display;
            draft.questionsMaxIndex += 1;
            draft.questionsIndex += 1;
            draft.transition = 'next';
          }
        }),
      );
    },
  });
  return useCallback(() => {
    const study = studyVar();
    const question = study.questions[study.questionsIndex];
    return mutation({
      variables: {
        questionId: question.id,
        studySessionId: study.studySession.id,
        isRepeated: question.isRepeated,
      },
    });
  }, [mutation]);
};

export const mutationStudyTransitionNext = () => {
  studyVar(
    produce(studyVar(), (draft) => {
      draft.display = shouldSeeMascotPercentageComplete(draft)
        ? STUDY_DISPLAY_STATUSES.MASCOT.COMPLETE
        : shouldSeeRepetitionScreen(draft)
        ? STUDY_DISPLAY_STATUSES.REPETITION
        : STUDY_DISPLAY_STATUSES.QUESTIONS;
    }),
  );
};

export const mutationStudyMascotFlag = () => {
  studyVar(
    produce(studyVar(), (draft) => {
      draft.hasMascotFlag = true;
    }),
  );
};

export const STUDY_ANSWER_FULL_FORCE_CORRECT = gql`
  mutation StudyAnswerFullForceCorrect($studyAnswerId: ID!) {
    studyAnswerFullForceCorrect(studyAnswerId: $studyAnswerId)
  }
`;

export const useMutationStudyQuestionFullForceCorrect = () => {
  const [mutation] = useMutation(STUDY_ANSWER_FULL_FORCE_CORRECT, {
    onCompleted: async ({ studyAnswerFullForceCorrect }) => {
      studyVar(
        produce(studyVar(), (draft) => {
          const question = draft.questions[draft.questionsIndex];
          question.result = 'correct';
          if (shouldBeInScore(question.type)) {
            draft.stats.nCorrect += 1;
            draft.stats.nWrong -= 1;
          }
          if (!question.isRepeated) {
            draft.questions.pop();
          }
        }),
      );
    },
  });
  return useCallback(() => {
    const study = studyVar();
    const question = study.questions[study.questionsIndex];
    return mutation({
      variables: {
        studyAnswerId: question.studyAnswerId,
      },
    });
  }, [mutation]);
};

export const STUDY_ANSWER_PARTIAL_FORCE_CORRECT = gql`
  mutation StudyAnswerPartialForceCorrect(
    $studyAnswerId: ID!
    $forcedAnswers: [JSON!]!
  ) {
    studyAnswerPartialForceCorrect(
      studyAnswerId: $studyAnswerId
      forcedAnswers: $forcedAnswers
    ) {
      ...StudyAnswerData
    }
  }
  ${STUDY_ANSWER_DATA}
`;

export const useMutationStudyQuestionPartialForceCorrect = () => {
  const [mutation] = useMutation(STUDY_ANSWER_PARTIAL_FORCE_CORRECT, {
    onCompleted: async ({ studyAnswerPartialForceCorrect: studyAnswer }) => {
      studyVar(
        produce(studyVar(), (draft) => {
          const question = draft.questions[draft.questionsIndex];
          const isCorrect = studyAnswer.result === 'correct';
          question.result = studyAnswer.result;
          question.solution = studyAnswer.solution;
          if (shouldBeInScore(question.type) && isCorrect) {
            draft.stats.nCorrect += 1;
            draft.stats.nWrong -= 1;
          }
          if (!question.isRepeated) {
            draft.questions.pop();
          }
          if (!question.isRepeated && !isCorrect) {
            draft.questions = [
              ...draft.questions,
              prepareQuestion(question, true),
            ];
          }
        }),
      );
    },
  });
  return useCallback(
    (forcedAnswers) => {
      const study = studyVar();
      const question = study.questions[study.questionsIndex];
      return mutation({
        variables: {
          studyAnswerId: question.studyAnswerId,
          forcedAnswers,
        },
      });
    },
    [mutation],
  );
};

export const mutationStudyTogglePartialForceCorrect = () => {
  studyVar(
    produce(studyVar(), (draft) => {
      draft.isPartialForceCorrect = !draft.isPartialForceCorrect;
    }),
  );
};
