import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Icon from 'uikit/Icon';

import QuestionCard from '../QuestionCard';
import styles from './ChapterCard.module.scss';

const cx = classNames.bind(styles);

const ChapterCard = ({
  allowPreview,
  chapter,
  className,
  handleChapterExpandedToggle,
  handleChapterSelect,
  isExpanded,
  isSelected,
  questions,
  t,
}) => (
  <div className={cx('container', className)}>
    <div className={cx('chapter')}>
      <label htmlFor={chapter.id}>
        <input
          aria-label={chapter.title}
          checked={isSelected}
          className={cx('check')}
          id={chapter.id}
          type="checkbox"
          onChange={handleChapterSelect}
        />
        <span
          className={cx('checkbox', {
            isChecked: isSelected,
          })}
        >
          <Icon name="check" />
        </span>
      </label>
      <button
        aria-label={t(
          `uikit/InputQuestionTree/ChapterCard/aria-chapter-${isExpanded ? 'close' : 'open'}`
        )}
        className={cx('icon')}
        onClick={handleChapterExpandedToggle}
        type="button"
      >
        {isExpanded ? (
          <Icon name="chevron-down" />
        ) : (
          <Icon name="chevron-right" />
        )}
      </button>
      <p className={cx('title')}>{chapter.title}</p>
    </div>
    {isExpanded && (
      <div className={cx('questions')}>
        {questions.map((question) => (
          <QuestionCard
            allowPreview={allowPreview}
            className={cx('question')}
            key={question.id}
            question={question}
          />
        ))}
      </div>
    )}
  </div>
);

ChapterCard.propTypes = {
  allowPreview: PropTypes.bool,
  chapter: PropTypes.object.isRequired,
  className: PropTypes.string,
  handleChapterExpandedToggle: PropTypes.func.isRequired,
  handleChapterSelect: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  questions: PropTypes.arrayOf(PropTypes.object).isRequired,
  t: PropTypes.func.isRequired,
};

ChapterCard.defaultProps = {
  allowPreview: undefined,
  className: undefined,
};

export default ChapterCard;
