import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import imageWooflashLogo from 'images/logos/logo.svg';
import Button from 'uikit/Button';
import FormattedText from 'uikit/FormattedText';
import Icon from 'uikit/Icon';

import AccountMenu from './AccountMenu';
import LanguageSelector from '../LanguageSelector';
import MenuElement from './MenuElement';
import MSTeamsNavigation from './MSTeamsNavigation';
import InstitutionPopUp from './InstitutionPopUp';
import TutorialsPopUp from './TutorialsPopUp';
// import Upgrade from './Upgrade';
// import UpgradeNow from './UpgradeNow';
import styles from './MenuContainer.module.scss';
import MenuTooltip from './MenuTooltip';

const cx = classNames.bind(styles);

const MenuContainer = ({
  children,
  closeButtonRef,
  handleMenuVisibleChange,
  headerAction = null,
  header = null,
  isEditionDisabled = null,
  isGAR,
  isMenuExpanded,
  isMenuVisible,
  isInstitutionPopUpVisible,
  isInTeams,
  isTNECanope,
  isTutorialsPopUpVisible,
  isOrganizationWarningDisplayed,
  isRoleTeacher,
  me = null,
  nUnreadNotifications,
  onMenuToggle,
  onWarningClose,
  t,
  title = null,
}) => (
  <>
    <a className={cx('skip')} href="#main-start">
      {t('Shared/MenuContainer/skip')}
    </a>
    <div
      className={cx({
        collapsed: !isMenuExpanded,
      })}
    >
      {!isInTeams && (
        <nav
          className={cx('menu', {
            isMenuVisible,
          })}
        >
          <div className={cx('menu-header')}>
            <Button
              aria-label={t('Shared/MenuContainer/aria-close-menu')}
              className={cx('back')}
              icon="arrow-left"
              intent="white"
              onClick={handleMenuVisibleChange}
              ref={closeButtonRef}
            />
            <Link
              aria-label={t('Shared/MenuContainer/aria-home')}
              className={cx('logo')}
              to="/"
            >
              <img
                alt=""
                aria-hidden="true"
                className={cx('logo-image')}
                src={imageWooflashLogo}
              />
              <span className={cx('logo-text')}>wooflash</span>
            </Link>
            <MenuTooltip
              isMenuExpanded={isMenuExpanded}
              tooltip={t('Shared/MenuContainer/aria-expand-menu')}
            >
              <Button
                aria-label={t(
                  `Shared/MenuContainer/aria-${
                    isMenuExpanded ? 'reduce' : 'expand'
                  }-menu`,
                )}
                className={cx('collapse-button')}
                icon={isMenuExpanded ? 'chevrons-left' : 'chevrons-right'}
                intent="white"
                onClick={onMenuToggle}
              />
            </MenuTooltip>
          </div>
          {me ? (
            <MenuElement
              icon="book-reader"
              target="/"
              title={t('Shared/MenuContainer/my-courses')}
            />
          ) : (
            <>
              <MenuTooltip
                isMenuExpanded={isMenuExpanded}
                tooltip={t('Shared/MenuContainer/log-in')}
              >
                <Button
                  as={Link}
                  className={cx('action-login')}
                  icon="right-to-bracket"
                  intent="primary"
                  size={isMenuExpanded ? 'large' : undefined}
                  to="/auth/login"
                >
                  {t('Shared/MenuContainer/log-in')}
                </Button>
              </MenuTooltip>
              <MenuTooltip
                isMenuExpanded={isMenuExpanded}
                tooltip={t('Shared/MenuContainer/register')}
              >
                <Button
                  as={Link}
                  className={cx('action-register')}
                  icon="user-plus"
                  intent="white"
                  size={isMenuExpanded ? 'large' : undefined}
                  to="/auth/register"
                >
                  {t('Shared/MenuContainer/register')}
                </Button>
              </MenuTooltip>
              <div className={cx('separator')} />
            </>
          )}
          {!isTNECanope &&
            (!isGAR || (isGAR && isRoleTeacher)) &&
            !isEditionDisabled && (
              <MenuElement
                icon="earth-americas"
                target="/public-courses"
                title={t('Shared/MenuContainer/public-courses')}
              />
            )}
          {me && (
            <div className={cx('agenda')}>
              <MenuElement
                icon="calendar-alt"
                isBeta={true}
                target="/agenda"
                title={t('Shared/MenuContainer/agenda')}
              />
            </div>
          )}
          {me && !isInTeams && (
            <div className={cx('notifications', { isMenuVisible })}>
              <MenuElement
                icon="bell"
                target="/notifications"
                title={t('Shared/MenuContainer/notifications')}
              />
              {nUnreadNotifications > 0 && (
                <p className={cx('count', { isMenuVisible })}>
                  {nUnreadNotifications}
                </p>
              )}
            </div>
          )}
          <div className={cx('filler')} />
          {isGAR && (
            <>
              <MenuElement
                asATag
                icon="info-square"
                target={`${process.env.REACT_APP_LANDING_URL}/fr/conditions-d-utilisation-gar`}
                title={t('Support/terms-and-conditions')}
              />
              <MenuElement
                asATag
                icon="shield"
                target={`${process.env.REACT_APP_LANDING_URL}/fr/politique-de-confidentialite-gar`}
                title={t('Support/privacy-policy')}
              />
            </>
          )}
          {/* {me && !isGAR && <Upgrade me={me} />} */}
          <LanguageSelector />
          {me && (
            <AccountMenu
              className={cx('me-menu')}
              isInLateralPanel={true}
              me={me}
            />
          )}
          {!isGAR && (
            <MenuElement
              icon="question"
              target="/support"
              title={t('Shared/MenuContainer/AccountMenu/support')}
            />
          )}
          <MenuElement
            dataTestId="logout-button"
            icon="sign-out"
            target="/auth/logout"
            title={t('Shared/MenuContainer/AccountMenu/sign-out')}
          />
          <div className={cx('menu-footer')}>
            <Link
              aria-label={t('Shared/MenuContainer/aria-home')}
              className={cx('logo-small')}
              to="/"
            >
              <img
                alt=""
                aria-hidden="true"
                className={cx('logo-image')}
                src={imageWooflashLogo}
              />
              <span className={cx('logo-text')}>wooflash</span>
            </Link>
          </div>
        </nav>
      )}
      <div
        className={cx('main', {
          isInTeams,
        })}
      >
        {isOrganizationWarningDisplayed && (
          <div className={cx('warning')}>
            <Icon className={cx('warning-icon')} name="triangle-exclamation" />
            <FormattedText className={cx('warning-text')}>
              {t('Shared/MenuContainer/warning-text')}
            </FormattedText>
            <Button
              icon="times"
              intent="white"
              onClick={onWarningClose}
              size="small"
            />
          </div>
        )}
        <header className={cx('header-padding', { header })}>
          {!isInTeams ? (
            <div className={cx('header-top')}>
              <Link
                aria-label={t('Shared/MenuContainer/aria-home')}
                className={cx('header-link')}
                to="/"
              >
                <img
                  alt=""
                  aria-hidden="true"
                  className={cx('logo-image')}
                  src={imageWooflashLogo}
                />
              </Link>
              <div className={cx('header-left')}>
                <div className={cx('header-toggle-container')}>
                  <Button
                    aria-label={t('Shared/MenuContainer/aria-open-menu')}
                    className={cx('header-toggle')}
                    icon="bars"
                    intent="white"
                    onClick={handleMenuVisibleChange}
                  />
                  {nUnreadNotifications > 0 && (
                    <p className={cx('count', 'count-toggle')}>
                      {nUnreadNotifications}
                    </p>
                  )}
                </div>
                {headerAction}
              </div>
              <div className={cx('header-top-title')}>{title}</div>
              {me && <AccountMenu className={cx('me-header')} me={me} />}
            </div>
          ) : (
            <MSTeamsNavigation />
          )}
          {header && <div className={cx('header-bottom')}>{header}</div>}
        </header>
        {/* {me && <UpgradeNow me={me} />} */}
        {isInstitutionPopUpVisible && <InstitutionPopUp me={me} />}
        {isTutorialsPopUpVisible && !isEditionDisabled && <TutorialsPopUp />}
        <main className={cx('content')} id="main-start">
          {children}
        </main>
      </div>
    </div>
  </>
);

MenuContainer.propTypes = {
  children: PropTypes.node.isRequired,
  closeButtonRef: PropTypes.object.isRequired,
  handleMenuVisibleChange: PropTypes.func.isRequired,
  header: PropTypes.node,
  isEditionDisabled: PropTypes.bool,
  isGAR: PropTypes.bool.isRequired,
  isMenuExpanded: PropTypes.bool.isRequired,
  isMenuVisible: PropTypes.bool.isRequired,
  isInstitutionPopUpVisible: PropTypes.bool.isRequired,
  isInTeams: PropTypes.bool.isRequired,
  isTNECanope: PropTypes.bool.isRequired,
  isTutorialsPopUpVisible: PropTypes.bool.isRequired,
  isOrganizationWarningDisplayed: PropTypes.bool,
  isRoleTeacher: PropTypes.bool.isRequired,
  me: PropTypes.object,
  nUnreadNotifications: PropTypes.number.isRequired,
  onMenuToggle: PropTypes.func.isRequired,
  onWarningClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  title: PropTypes.node,
};

MenuContainer.defaultProps = {
  isOrganizationWarningDisplayed: false,
};

export default MenuContainer;
