import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { apiUploadsS3 } from 'api/upload';
import formatMediaDuration from 'helpers/formatMediaDuration';
import useHotkeys from 'hooks/useHotkeys';
import useInterval from 'hooks/useInterval';
import useModal from 'hooks/useModal';
import Context from 'lib/context';

import VideoRecorder from './VideoRecorder';

const VideoRecorderContainer = ({
  name,
  onRecordStart,
  onRecordStop,
  ...props
}) => {
  const { t } = useTranslation();
  const { theme } = useContext(Context);
  const { pathname } = useLocation();
  const isStudying = pathname.includes('/study');
  const { isModalOpen, handleModalClose, handleModalOpen } = useModal(false);
  const [, , { setValue: setVideo }] = useField(name);
  const [{ value: videoToImport }, , { setValue: setVideoToImport }] =
    useField('videoToImport');
  const [{ value: videoPreview }, , { setValue: setVideoPreview }] =
    useField('videoPreview');
  const [isLoading, setIsLoading] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [isRestarting, setIsRestarting] = useState(false);
  const [timer, setTimer] = useState(3);
  const [videoDuration, setVideoDuration] = useState(' ');
  const videoRef = useRef();
  const btnRecordStop = useRef();
  const handleCountDown = useCallback(() => {
    if (isLoading && timer > 0) {
      setTimer(timer - 1);
    }
  }, [isLoading, timer]);
  useInterval(handleCountDown, 1 * 1000);
  const handleRecordVideo = useCallback(() => {
    setIsRestarting(false);
    handleModalOpen();
    navigator.mediaDevices
      .getUserMedia({
        audio: true,
        video: true,
      })
      .then((stream) => {
        setTimer(3);
        setIsLoading(true);
        // The delay is needed to sync the audio with the video
        setTimeout(() => {
          setIsLoading(false);
          setIsRecording(true);
          if (onRecordStart) {
            onRecordStart();
          }
          const mediaRecorder = new MediaRecorder(stream);
          mediaRecorder.start();
          const videoStream = document.getElementById('videoStream');
          videoStream.srcObject = stream;
          if (btnRecordStop.current) {
            btnRecordStop.current.addEventListener('click', () => {
              mediaRecorder.stop();
              stream.getTracks().forEach((track) => {
                track.stop();
              });
            });
          }
          const chuck = [];
          mediaRecorder.addEventListener('dataavailable', (e) => {
            chuck.push(e.data);
          });
          mediaRecorder.addEventListener('stop', () => {
            const blob = new Blob(chuck, { type: 'video/mp4' });
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onload = (event) => {
              setVideoToImport(blob);
              setVideoPreview(event.target.result);
            };
            setIsRecording(false);
            if (onRecordStop) {
              onRecordStop();
            }
          });
        }, 3000);
      });
  }, [
    handleModalOpen,
    onRecordStart,
    onRecordStop,
    setVideoPreview,
    setVideoToImport,
  ]);
  const handleCurrentTimeUpdate = useCallback(() => {
    formatMediaDuration(videoRef.current.currentTime, setVideoDuration);
  }, [videoRef, setVideoDuration]);
  const handleRecordedVideoReset = useCallback(() => {
    setTimer(3);
    setVideoToImport(null);
    setVideoPreview(null);
    handleRecordVideo();
  }, [handleRecordVideo, setVideoPreview, setVideoToImport]);
  const handleModalCloseAndReset = useCallback(() => {
    setTimer(3);
    setIsRecording(false);
    setVideoToImport(null);
    setVideoPreview(null);
    formatMediaDuration(0, setVideoDuration);
    if (onRecordStop) {
      onRecordStop();
    }
    if (btnRecordStop.current) {
      btnRecordStop.current.click();
    }
    setTimeout(() => {
      setVideoToImport(null);
      setVideoPreview(null);
    }, 1000);
    handleModalClose();
  }, [handleModalClose, onRecordStop, setVideoPreview, setVideoToImport]);
  const handleRecordingRestart = useCallback(() => {
    setIsRestarting(true);
    handleModalCloseAndReset();
    setTimeout(handleRecordVideo, 1000);
  }, [handleModalCloseAndReset, handleRecordVideo]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSelect = useCallback(async () => {
    try {
      setIsSubmitting(true);
      const filePath = await apiUploadsS3(videoToImport);
      setVideo(filePath);
      handleModalClose();
      setIsSubmitting(false);
    } catch (err) {
      reportError('video', 'upload', err);
    }
  }, [handleModalClose, setVideo, videoToImport]);
  useHotkeys('r', isRecording ? handleRecordingRestart : handleRecordVideo);
  useHotkeys('s', () => btnRecordStop.current?.click());
  return (
    <VideoRecorder
      btnRecordStop={btnRecordStop}
      handleCurrentTimeUpdate={handleCurrentTimeUpdate}
      handleModalCloseAndReset={handleModalCloseAndReset}
      handleRecordedVideoReset={handleRecordedVideoReset}
      handleRecordingRestart={handleRecordingRestart}
      handleRecordVideo={handleRecordVideo}
      handleSelect={handleSelect}
      isLoading={isLoading}
      isModalOpen={isModalOpen}
      isRecording={isRecording}
      isRestarting={isRestarting}
      isSubmitting={isSubmitting}
      t={t}
      theme={isStudying ? theme : {}}
      timer={timer}
      videoDuration={videoDuration}
      videoPreview={videoPreview}
      videoRef={videoRef}
      {...props}
    />
  );
};

VideoRecorderContainer.propTypes = {
  name: PropTypes.string.isRequired,
  onRecordStart: PropTypes.func,
  onRecordStop: PropTypes.func,
};

VideoRecorderContainer.defaultProps = {
  onRecordStart: undefined,
  onRecordStop: undefined,
};

export default VideoRecorderContainer;
