import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'uikit/Button';
import FormattedText from 'uikit/FormattedText';

import ILLUSTRATION from './images/illustration.svg';
import styles from './TutorialsPopUp.module.scss';

const cx = classNames.bind(styles);

const TutorialsPopUp = ({ handlePopUpClose, t }) => (
  <div className={cx('container')}>
    <img alt="" className={cx('illustration')} src={ILLUSTRATION} />
    <FormattedText className={cx('title')}>
      {t('Shared/MenuContainer/TutorialsPopUp/title')}
    </FormattedText>
    <Button
      className={cx('button')}
      intent="dark"
      onClick={handlePopUpClose}
    >
      {t('Shared/MenuContainer/TutorialsPopUp/ok')}
    </Button>
  </div>
);

TutorialsPopUp.propTypes = {
  handlePopUpClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default TutorialsPopUp;
