import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'uikit/Button';
import Icon from 'uikit/Icon';

import styles from './CourseItem.module.scss';

const cx = classNames.bind(styles);

const CourseItem = ({ className, course, handleCourseJoin, t }) => (
  <div
    className={cx('container', `theme-${course.themeBackground}`, className)}
  >
    <div className={cx('icon')}>
      <Icon name="book" />
    </div>
    <h2 className={cx('title')}>{course.title}</h2>
    <Button intent="primary" onClick={handleCourseJoin}>
      {t('Dashboard/CoursesGAR/CourseItem/join')}
    </Button>
  </div>
);

CourseItem.propTypes = {
  className: PropTypes.string,
  course: PropTypes.object.isRequired,
  handleCourseJoin: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

CourseItem.defaultProps = {
  className: undefined,
};

export default CourseItem;
