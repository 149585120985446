import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useQueryMe } from 'gql/queries/me';
import { useMutationMeTag } from 'gql/mutations/me';
import { trackEvent } from 'lib/tracking';

import TutorialsPopUp from './TutorialsPopUp';

const TutorialsPopUpContainer = () => {
  const { t } = useTranslation();
  const mutationMeTag = useMutationMeTag();
  const {
    data: {
      me: { language },
    },
  } = useQueryMe();
  const handlePopUpClose = useCallback(async () => {
    try {
      await mutationMeTag(['tutorials']);
      trackEvent('tag', 'tutorials');
    } catch (err) {
      reportError('tag', 'tutorials', err);
    }
  }, [mutationMeTag]);
  useEffect(() => {
    if (language !== 'fr') {
      handlePopUpClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <TutorialsPopUp handlePopUpClose={handlePopUpClose} t={t} />;
};

export default TutorialsPopUpContainer;
