import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import React from 'react';

import Button from 'uikit/Button';
import InputText from 'uikit/InputText';

import styles from './AudioLink.module.scss';

const cx = classNames.bind(styles);

const AudioLink = ({ name, t }) => {
  const [{ value: audio }, , { setValue: setAudio }] = useField(name);
  return (
    <>
      <div className={cx('link-input-container')}>
        <InputText
          aria-label={t('uikit/AudioPicker/AudioLink/aria-link')}
          className={cx('link')}
          name={name}
          placeholder={t('uikit/AudioPicker/AudioLink/link-placeholder')}
        />
        {audio && (
          <Button
            aria-label={t('uikit/AudioPicker/AudioLink/delete')}
            className={cx('link-reset-button')}
            icon="times"
            intent="white"
            onClick={() => setAudio('')}
          />
        )}
      </div>
    </>
  );
};

AudioLink.propTypes = {
  name: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default AudioLink;
