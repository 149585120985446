import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useQueryCourseGames } from 'gql/queries/course';
import useModal from 'hooks/useModal';

import GamePlay from './GamePlay';

const GamePlayContainer = ({ course, ...props }) => {
  const { t } = useTranslation();
  const { isModalOpen, handleModalOpen, handleModalClose } = useModal();
  const { data } = useQueryCourseGames({
    variables: {
      courseId: course.id,
    },
    skip: !isModalOpen,
  });
  return (
    <GamePlay
      course={course}
      games={data?.course.games}
      handleModalOpen={handleModalOpen}
      handleModalClose={handleModalClose}
      isModalOpen={isModalOpen}
      t={t}
      {...props}
    />
  );
};

GamePlayContainer.propTypes = {
  course: PropTypes.object.isRequired,
};

export default GamePlayContainer;
