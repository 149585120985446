import classNames from 'classnames/bind';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'uikit/Button';
import InputText from 'uikit/InputText';
import Modal from 'uikit/Modal';

import styles from './CourseJoin.module.scss';

const cx = classNames.bind(styles);

const CourseJoin = ({
  handleCourseJoin,
  handleModalClose,
  isModalOpen,
  t,
  validationSchema,
}) => (
  <Modal isOpen={isModalOpen} onExit={handleModalClose}>
    <Formik
      initialValues={{
        accessCode: '',
      }}
      onSubmit={handleCourseJoin}
      validationSchema={validationSchema}
    >
      {({ isSubmitting, status }) => (
        <Form className={cx('modal')}>
          <Button
            aria-label={t('Shared/CourseJoin/aria-close')}
            className={cx('close')}
            data-testid="close-modal-course-join-button"
            icon="times"
            onClick={handleModalClose}
          />
          <h1 className={cx('title')}>{t('Shared/CourseJoin/title')}</h1>
          <p className={cx('subtitle')}>{t('Shared/CourseJoin/subtitle')}</p>
          {status && (
            <p className={cx('error')} role="alert">
              {t('Shared/CourseJoin/course-join-error')}
            </p>
          )}
          <div className={cx('input-container')}>
            <InputText
              autoFocus
              data-testid="access-code-input"
              maxLength={256}
              name="accessCode"
              placeholder={t('Shared/CourseJoin/access-code-label')}
              shouldHideError
            />
            <Button
              data-testid="submit-access-code-button"
              intent="dark"
              isLoading={isSubmitting}
              type="submit"
            >
              {t('Shared/CourseJoin/join-course')}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  </Modal>
);

CourseJoin.propTypes = {
  handleCourseJoin: PropTypes.func.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  validationSchema: PropTypes.object.isRequired,
};

export default CourseJoin;
