import { gql, useQuery } from '@apollo/client';

import { WOOGEN_DATA } from 'gql/fragments';

export const QUERY_WOOGEN = gql`
  {
    woogen {
      ...WoogenData
    }
  }
  ${WOOGEN_DATA}
`;

export function useQueryWoogen(options) {
  const { loading, data, ...props } = useQuery(QUERY_WOOGEN, options);
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}
