import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from 'recharts';

import CleanedText from 'uikit/CleanedText';
import FormattedText from 'uikit/FormattedText';

import styles from './Aggregate.module.scss';

const cx = classNames.bind(styles);

const Aggregate = ({ aggregation, className, question, questionInfo, t }) => (
  <div className={cx('container', className)}>
    <div className={cx('background')}>
      <div className={cx('header')}>
        <div className={cx('title-container')}>
          <p className={cx('icon', `background-${questionInfo.color}`)}>
            <img alt="" src={questionInfo.icon} />
          </p>
          <CleanedText as="p" className={cx('title')}>
            {question?.title}
          </CleanedText>
        </div>
        <p
          className={cx('answers-numbers', {
            'answers-numbers-wrong': aggregation.nCorrectAnswers === 0,
          })}
        >
          <span
            className={cx('correct-answers', {
              isEmpty: aggregation.nCorrectAnswers === 0,
            })}
          >
            {t('Questions/JCT/Aggregate/total-answers', {
              count: aggregation.nTotalAnswers,
            })}
          </span>
        </p>
      </div>
      <div className={cx('subtitle')}>
        <p className={cx('subtitle-header')}>
          {t('Questions/JCT/additional-information')}
        </p>
        <FormattedText>{question.content.subtitle}</FormattedText>
      </div>
      <div className={cx('chart')}>
        <ResponsiveContainer>
          <BarChart
            data={[0, 1, 2, 3].map((i) => ({
              name:
                question.content.labels[i] ||
                t(`Questions/JCT/experts-${i}-label`),
              experts: question.content.experts[i],
              students: aggregation.aggregatedAnswers[i] || 0,
            }))}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            fontSize="1vw"
            fontWeight={700}
          >
            <CartesianGrid opacity=".2" strokeDasharray="0" vertical={false} />
            <Tooltip />
            <Legend height={36} verticalAlign="bottom" />
            <XAxis
              dataKey="name"
              tick={({ payload, ...props }) => (
                <text className={cx('chart-axis')} dy="15" {...props}>
                  {payload.value}
                </text>
              )}
            />
            <Bar
              dataKey="experts"
              fill="#1bc489"
              name={t(`Questions/JCT/experts-label`)}
            >
              <LabelList
                className={cx('chart-label')}
                dataKey="experts"
                position="top"
              />
              {[0, 1, 2, 3].map((i) => (
                <Cell key={i} fill="#1bc489" />
              ))}
            </Bar>
            <Bar
              dataKey="students"
              fill="#8258f3"
              name={t(`Questions/JCT/students-label`)}
            >
              <LabelList
                className={cx('chart-label')}
                dataKey="students"
                position="top"
              />
              {[0, 1, 2, 3, 4].map((i) => (
                <Cell key={i} fill="#8258f3" />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  </div>
);

Aggregate.propTypes = {
  aggregation: PropTypes.object.isRequired,
  className: PropTypes.string,
  question: PropTypes.object.isRequired,
  questionInfo: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

Aggregate.defaultProps = {
  className: undefined,
};

export default Aggregate;
