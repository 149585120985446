import classNames from 'classnames/bind';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'uikit/Button';

import VideoImporter from './VideoImporter';
import VideoLink from './VideoLink';
import VideoPreview from './VideoPreview';
import VideoRecorder from './VideoRecorder';
import styles from './VideoPicker.module.scss';

const cx = classNames.bind(styles);

const VideoPicker = ({
  className,
  'data-testid': dataTestId,
  disableImporter,
  disableLink,
  disableRecorder,
  handleDeselect,
  name,
  onRecordStart,
  onRecordStop,
  setVideoDuration,
  t,
  videoDuration,
}) => {
  const [{ value: video }, meta] = useField(name);
  const [{ value: videoLink }] = useField('videoLink');
  return (
    <div className={className}>
      {video && !videoLink ? (
        <VideoPreview
          footer={
            <>
              <div className={cx('duration')}>{videoDuration}</div>
              <Button
                className={cx('delete')}
                icon="trash-alt"
                onClick={handleDeselect}
                size="small"
                tooltip={t('uikit/VideoPicker/delete-video')}
                variant="regular"
              />
            </>
          }
          setVideoDuration={setVideoDuration}
          video={video}
        />
      ) : (
        <>
          {!disableLink && (
            <>
              <p className={cx('label')}>{t('uikit/VideoPicker/link')}</p>
              <p className={cx('description')}>
                {t('uikit/VideoPicker/link-description')}
              </p>
              <VideoLink data-testid={dataTestId} name={name} />
            </>
          )}

          {!videoLink && (
            <>
              {!disableLink && (
                <p className={cx('or')}>
                  <span>{t('uikit/VideoPicker/or')}</span>
                </p>
              )}
              {!disableImporter && <VideoImporter name={name} />}
              {disableLink && !disableImporter && !disableRecorder && (
                <p className={cx('or', 'small')}>
                  <span>{t('uikit/VideoPicker/or')}</span>
                </p>
              )}
              {!disableRecorder && (
                <VideoRecorder
                  name={name}
                  onRecordStart={onRecordStart}
                  onRecordStop={onRecordStop}
                />
              )}
            </>
          )}
        </>
      )}
      {meta.touched && meta.error && (
        <p className={cx('error-message')} id={`error-${name}`} role="alert">
          {meta.error}
        </p>
      )}
    </div>
  );
};

VideoPicker.propTypes = {
  className: PropTypes.string,
  'data-testid': PropTypes.string,
  disableImporter: PropTypes.bool,
  disableLink: PropTypes.bool,
  disableRecorder: PropTypes.bool,
  handleDeselect: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onRecordStart: PropTypes.func,
  onRecordStop: PropTypes.func,
  setVideoDuration: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  videoDuration: PropTypes.string.isRequired,
};

VideoPicker.defaultProps = {
  className: undefined,
  'data-testid': undefined,
  disableImporter: false,
  disableLink: false,
  disableRecorder: false,
  onRecordStart: undefined,
  onRecordStop: undefined,
};

export default VideoPicker;
