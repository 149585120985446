import classNames from 'classnames/bind';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './InputTolerance.module.scss';

const cx = classNames.bind(styles);

const InputTolerance = React.forwardRef(
  (
    {
      className,
      handleChange,
      helper,
      isDisabled,
      label,
      name,
      shouldHideError,
      t,
      type,
      ...props
    },
    ref,
  ) => {
    const [{ onChange, ...field }] = useField(`${name}Input`);
    const [, meta] = useField(name);
    return (
      <label
        className={cx(
          'container',
          {
            isDisabled,
            hasError: meta.touched && meta.error,
          },
          className,
        )}
        htmlFor={name}
      >
        {(label || helper) && (
          <div className={cx('title')} id={`inputtolerance-${name}-label`}>
            <div className={cx('label')}>{label}</div>
            <div className={cx('helper')}>{helper}</div>
          </div>
        )}
        <input
          aria-labelledby={`inputtolerance-${name}-label`}
          className={cx('input')}
          disabled={isDisabled}
          id={name}
          name={name}
          onChange={handleChange}
          ref={ref}
          type={type}
          {...field}
          {...props}
        />
        {!shouldHideError && meta.touched && meta.error && (
          <p className={cx('error-message')} id={`error-${name}`} role="alert">
            {meta.error}
          </p>
        )}
      </label>
    );
  },
);

InputTolerance.propTypes = {
  className: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  helper: PropTypes.node,
  isDisabled: PropTypes.bool,
  label: PropTypes.node,
  name: PropTypes.string.isRequired,
  shouldHideError: PropTypes.bool,
  t: PropTypes.func.isRequired,
  type: PropTypes.string,
};

InputTolerance.defaultProps = {
  className: undefined,
  helper: undefined,
  isDisabled: false,
  label: undefined,
  shouldHideError: false,
  type: 'text',
};

export default InputTolerance;
