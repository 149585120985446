import classNames from 'classnames/bind';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Container from 'components/Auth/Shared/Container/Container';
import { mutationAppLogOut } from 'gql/mutations/app';
import { trackEvent, reportError } from 'lib/tracking';
import Button from 'uikit/Button';

import styles from './Logout.module.scss';

const cx = classNames.bind(styles);

const Logout = () => {
  const { t } = useTranslation('', { keyPrefix: 'Auth/Logout' });

  useEffect(() => {
    (async function asyncEffect() {
      try {
        await mutationAppLogOut();
        trackEvent('auth', 'logout');
      } catch (err) {
        reportError('auth', 'logout', err);
      }
    })();
  }, []);

  return (
    <Container>
      <div className={cx('container')}>
        <div className={cx('content')}>
          <h1 className={cx('title')}>{t('title')}</h1>
          <Button as={Link} intent="primary" size="large" to="/auth/login">
            {t('continue')}
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default Logout;
