import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'uikit/Button';
import Icon from 'uikit/Icon';
import { notify } from 'uikit/Notifications';

import styles from './ExpertLink.module.scss';
import HelpTooltip from 'uikit/HelpTooltip';

const cx = classNames.bind(styles);

const ExpertLink = ({ className = null, course }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/Cover/CourseShare/ExpertLink',
  });

  const link = `${process.env.REACT_APP_WEB_URL}/expert/${course.id}`;

  const handleCopy = () => {
    document.getElementById('share-link-expert').select();
    document.execCommand('copy');
    notify('success', t('link-copied'));
  };

  return (
    <div className={cx('container', className)}>
      <div className={cx('icon')}>
        <Icon name="user-tie" />
      </div>
      <div className={cx('grow')}>
        <div className={cx('header')}>
          <p className={cx('title')}>{t('link')}</p>
          <HelpTooltip tooltip={t('tooltip')} />
        </div>
        <div className={cx('content')}>
          <p className={cx('link')}>{link}</p>
          <Button aria-label={t('aria-copy-link')} onClick={handleCopy}>
            {t('copy-link')}
          </Button>
          <input
            aria-hidden="true"
            className={cx('hidden')}
            id="share-link-expert"
            readOnly
            type="text"
            value={link}
            tabIndex={-1}
          />
        </div>
      </div>
    </div>
  );
};

ExpertLink.propTypes = {
  className: PropTypes.string,
  course: PropTypes.object.isRequired,
};

export default ExpertLink;
