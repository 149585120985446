import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import { useQueryLocalApp, useQueryLocalEditor } from 'gql/queries/local';
import { getQuestionEditor } from 'questions';
import ErrorFocus from 'uikit/ErrorFocus';

import Navigation from '../Navigation/Navigation';
import QuestionTitleEditor from './QuestionTitleEditor/QuestionTitleEditor';
import QuestionEditorHeader from './QuestionEditorHeader/QuestionEditorHeader';
import QuestionEditorFooter from './QuestionEditorFooter/QuestionEditorFooter';
import styles from './QuestionEditor.module.scss';

const cx = classNames.bind(styles);

const QuestionEditor = ({
  course,
  isQuestionSaved,
  nextQuestion = null,
  onCancel,
  onDelete,
  onDeleteModalOpen,
  onExit,
  onGradeModalOpen,
  previousQuestion = null,
}) => {
  const {
    data: {
      editor: { questionType },
    },
  } = useQueryLocalEditor();

  const {
    data: {
      app: { isMenuExpanded },
    },
  } = useQueryLocalApp();

  const Editor = getQuestionEditor(questionType);

  return (
    <div className={cx('subpage')}>
      <div className={cx('main', { 'menu-expanded': isMenuExpanded })}>
        <Navigation
          nextQuestion={nextQuestion}
          onExit={onExit}
          previousQuestion={previousQuestion}
        />
        <div className={cx('editor')}>
          <QuestionEditorHeader isQuestionSaved={isQuestionSaved} />
          <div className={cx('editor-container')}>
            <QuestionTitleEditor />
            <Editor course={course} />
          </div>
        </div>
      </div>
      <QuestionEditorFooter
        course={course}
        onCancel={onCancel}
        onDelete={onDelete}
        onDeleteModalOpen={onDeleteModalOpen}
        onGradeModalOpen={onGradeModalOpen}
      />
      <ErrorFocus />
    </div>
  );
};

QuestionEditor.propTypes = {
  course: PropTypes.object.isRequired,
  isQuestionSaved: PropTypes.bool.isRequired,
  nextQuestion: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onDeleteModalOpen: PropTypes.func.isRequired,
  onExit: PropTypes.func.isRequired,
  onGradeModalOpen: PropTypes.func.isRequired,
  previousQuestion: PropTypes.object,
};

export default QuestionEditor;
