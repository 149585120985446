import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { PARTICIPANT_DATA } from 'gql/fragments';

export const STUDENT_CREATE = gql`
  mutation StudentCreate($courseId: ID!, $email: String!) {
    studentCreate(courseId: $courseId, email: $email) {
      ...ParticipantData
    }
  }
  ${PARTICIPANT_DATA}
`;

export function useMutationStudentCreate() {
  const [mutation] = useMutation(STUDENT_CREATE);
  return useCallback(
    (variables) =>
      mutation({
        variables,
        update(cache, { data: { studentCreate } }) {
          cache.modify({
            id: `Course:${studentCreate.courseId}`,
            fields: {
              students: (existingStudentsRef = [], { readField }) => {
                if (
                  existingStudentsRef.some(
                    (c) => readField('id', c) === studentCreate.id,
                  )
                ) {
                  return existingStudentsRef;
                }
                cache.modify({
                  id: `CourseStats:${studentCreate.courseId}`,
                  fields: {
                    students: (students) => students + 1,
                  },
                });
                const newStudentRef = cache.writeFragment({
                  data: studentCreate,
                  fragment: PARTICIPANT_DATA,
                });
                return [...existingStudentsRef, newStudentRef].sort((a, b) =>
                  `${readField('lastName', a)}, ${readField(
                    'firstName',
                    a,
                  )}`.localeCompare(
                    `${readField('lastName', b)}, ${readField('firstName', b)}`,
                    undefined,
                    {
                      numeric: true,
                      sensitivity: 'base',
                    },
                  ),
                );
              },
            },
          });
        },
      }),
    [mutation],
  );
}

export const STUDENT_DELETE = gql`
  mutation StudentDelete($participantId: ID!) {
    studentDelete(participantId: $participantId)
  }
`;

export function useMutationStudentDelete() {
  const [mutation] = useMutation(STUDENT_DELETE);
  return useCallback(
    (student) =>
      mutation({
        variables: {
          participantId: student.id,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          studentDelete: true,
        },
        update(cache) {
          cache.modify({
            id: student.groupId
              ? `Group:${student.groupId}`
              : `Course:${student.courseId}`,
            fields: {
              students: (existingStudentsRef = [], { readField }) =>
                existingStudentsRef.filter(
                  (c) => readField('id', c) !== student.id,
                ),
            },
          });
          cache.modify({
            id: `CourseStats:${student.courseId}`,
            fields: {
              students: (students) => students - 1,
            },
          });
        },
      }),
    [mutation],
  );
}

export const STUDENT_SORT = gql`
  mutation StudentSort($participantId: ID!, $groupId: ID) {
    studentSort(participantId: $participantId, groupId: $groupId) {
      ...ParticipantData
    }
  }
  ${PARTICIPANT_DATA}
`;

export function useMutationStudentSort() {
  const [mutation] = useMutation(STUDENT_SORT);
  return useCallback(
    (student, variables) =>
      mutation({
        variables: {
          ...variables,
          participantId: student.id,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          studentSort: {
            ...student,
            ...variables,
            __typename: 'Participant',
          },
        },
        update(cache, { data: { studentSort } }) {
          if (student.groupId === studentSort.groupId) {
            return;
          }
          let studentRef = null;
          cache.modify({
            id: student.groupId
              ? `Group:${student.groupId}`
              : `Course:${student.courseId}`,
            fields: {
              students: (existingStudentsRef = [], { readField }) => {
                studentRef = existingStudentsRef.find(
                  (s) => readField('id', s) === student.id,
                );
                return existingStudentsRef.filter(
                  (s) => readField('id', s) !== student.id,
                );
              },
            },
          });
          cache.modify({
            id: studentSort.groupId
              ? `Group:${studentSort.groupId}`
              : `Course:${studentSort.courseId}`,
            fields: {
              students: (existingStudentsRef = [], { readField }) =>
                [...existingStudentsRef, studentRef].sort((a, b) =>
                  `${readField('lastName', a)}, ${readField(
                    'firstName',
                    a,
                  )}`.localeCompare(
                    `${readField('lastName', b)}, ${readField('firstName', b)}`,
                    undefined,
                    {
                      numeric: true,
                      sensitivity: 'base',
                    },
                  ),
                ),
            },
          });
        },
      }),
    [mutation],
  );
}
