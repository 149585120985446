import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { Link } from 'react-router-dom';

import Button from 'uikit/Button';
import Icon from 'uikit/Icon';
import Menu, { MenuItem } from 'uikit/Menu';

import Students from '../Students';
import styles from './GroupCard.module.scss';

const cx = classNames.bind(styles);

const GroupCard = ({
  className,
  course,
  group,
  handleGroupDelete,
  handleGroupExpandedToggle,
  handleGroupShare,
  handleGroupUpdate,
  isExpanded,
  t,
}) => (
  <Droppable droppableId={group.id} type="student">
    {(providedStudents, snapshotStudents) => (
      // eslint-disable-next-line jsx-a11y/interactive-supports-focus, jsx-a11y/click-events-have-key-events
      <div
        className={cx(
          'container',
          {
            isDraggingOver: snapshotStudents.isDraggingOver,
          },
          className,
        )}
      >
        <div
          ref={providedStudents.innerRef}
          {...providedStudents.droppableProps}
        >
          <div className={cx('group')}>
            <button
              aria-label={
                isExpanded
                  ? t(
                      'Course/Teacher/Students/Finder/GroupCard/aria-close-group',
                    )
                  : t(
                      'Course/Teacher/Students/Finder/GroupCard/aria-open-group',
                    )
              }
              className={cx('icon')}
              onClick={handleGroupExpandedToggle}
              type="button"
            >
              <Icon
                name={isExpanded ? 'chevron-down' : 'chevron-right'}
                variant="solid"
              />
            </button>
            <div className={cx('group-icon')}>
              <Icon name="screen-users" />
            </div>
            <p className={cx('title')}>{group.title}</p>
            <Button
              className={cx('action')}
              onClick={handleGroupShare}
              icon="plus"
              size="small"
            >
              {t('Course/Teacher/Students/Finder/GroupCard/invite-students')}
            </Button>
            {!course.isExam && (
              <Button
                as={Link}
                className={cx('action')}
                icon="chart-bar"
                size="small"
                to={{
                  pathname: `/course/${course.id}/statistics`,
                  state: {
                    type: 'group',
                    groupId: group.id,
                  },
                }}
                tooltip={t(
                  'Course/Teacher/Students/Finder/GroupCard/statistics',
                )}
              />
            )}
            <Menu
              placement="bottom-end"
              toggle={
                <Button
                  className={cx('action')}
                  icon="ellipsis-v"
                  size="small"
                  tooltip={t(
                    'Course/Teacher/Students/Finder/GroupCard/options',
                  )}
                />
              }
            >
              <MenuItem icon="edit" onClick={handleGroupUpdate}>
                {t('Course/Teacher/Students/Finder/GroupCard/rename-group')}
              </MenuItem>
              <MenuItem
                icon="trash-alt"
                onClick={handleGroupDelete}
                variant="regular"
              >
                {t('Course/Teacher/Students/Finder/GroupCard/delete-group')}
              </MenuItem>
            </Menu>
          </div>
          {isExpanded && (
            <div
              className={cx('students', {
                isDraggingOver: snapshotStudents.isDraggingOver,
              })}
            >
              <Students course={course} groupId={group.id} />
              {providedStudents.placeholder}
            </div>
          )}
        </div>
      </div>
    )}
  </Droppable>
);

GroupCard.propTypes = {
  className: PropTypes.string,
  course: PropTypes.object.isRequired,
  group: PropTypes.object.isRequired,
  handleGroupExpandedToggle: PropTypes.func.isRequired,
  handleGroupDelete: PropTypes.func.isRequired,
  handleGroupShare: PropTypes.func.isRequired,
  handleGroupUpdate: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

GroupCard.defaultProps = {
  className: undefined,
};

export default GroupCard;
