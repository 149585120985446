import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'uikit/Button';
import Modal from 'uikit/Modal';

import styles from './CollaboratorDelete.module.scss';

const cx = classNames.bind(styles);

const CollaboratorDelete = ({
  handleClose,
  handleCollaboratorDelete,
  isLoading,
  isModalOpen,
  status,
  t,
}) => (
  <Modal isOpen={isModalOpen} onExit={handleClose}>
    <div className={cx('container')}>
      <Button
        aria-label={t(
          'Course/Teacher/Collaborators/CollaboratorDelete/aria-close',
        )}
        className={cx('close')}
        icon="times"
        onClick={handleClose}
      />
      <h1 className={cx('title')}>
        {t('Course/Teacher/Collaborators/CollaboratorDelete/title')}
      </h1>
      <p className={cx('subtitle')}>
        {t('Course/Teacher/Collaborators/CollaboratorDelete/subtitle')}
      </p>
      {status && (
        <p className={cx('error')} role="alert">
          {t(
            'Course/Teacher/Collaborators/CollaboratorDelete/collaborator-delete-error',
          )}
        </p>
      )}
      <Button
        className={cx('action')}
        intent="danger"
        isLoading={isLoading}
        onClick={handleCollaboratorDelete}
        size="large"
      >
        {t(
          'Course/Teacher/Collaborators/CollaboratorDelete/confirm-delete-collaborator',
        )}
      </Button>
    </div>
  </Modal>
);

CollaboratorDelete.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleCollaboratorDelete: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  status: PropTypes.string,
  t: PropTypes.func.isRequired,
};

CollaboratorDelete.defaultProps = {
  status: undefined,
};

export default CollaboratorDelete;
