import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './TourTooltip.module.scss';

const cx = classNames.bind(styles);

const TourTooltip = ({ step, tooltipProps }) => (
  <div className={cx('container')} {...tooltipProps}>
    {step.content}
  </div>
);

TourTooltip.propTypes = {
  step: PropTypes.object.isRequired,
  tooltipProps: PropTypes.object.isRequired,
};

export default TourTooltip;
