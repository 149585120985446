import t from 'helpers/t';

export const LOWER_CLASSES = [
  { key: 'cp', translation: 'Global/classes/lower-education/CP' },
  { key: 'ce1', translation: 'Global/classes/lower-education/CE1' },
  { key: 'ce2', translation: 'Global/classes/lower-education/CE2' },
  { key: 'cm1', translation: 'Global/classes/lower-education/CM1' },
  { key: 'cm2', translation: 'Global/classes/lower-education/CM2' },
  { key: '6e', translation: 'Global/classes/lower-education/6e' },
  { key: '5e', translation: 'Global/classes/lower-education/5e' },
  { key: '4e', translation: 'Global/classes/lower-education/4e' },
  { key: '3e', translation: 'Global/classes/lower-education/3e' },
  { key: '2nd', translation: 'Global/classes/lower-education/2nd' },
  { key: '1er', translation: 'Global/classes/lower-education/1er' },
  {
    key: 'terminal',
    translation: t('Global/classes/lower-education/terminal'),
  },
];

export const HIGHER_CLASSES = [
  { key: 'l1', translation: 'Global/classes/higher-education/L1' },
  { key: 'l2', translation: 'Global/classes/higher-education/L2' },
  { key: 'l3', translation: 'Global/classes/higher-education/L3' },
  { key: 'm1', translation: 'Global/classes/higher-education/M1' },
  { key: 'm2', translation: 'Global/classes/higher-education/M2' },
];
