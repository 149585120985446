import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Feedback from 'questions/Shared/Feedback';
import FormattedText from 'uikit/FormattedText';

import styles from './Verso.module.scss';

const cx = classNames.bind(styles);

const Verso = ({ className, question, t, theme }) => (
  <div
    className={cx(
      'container',
      className,
      `theme-${theme}`,
    )}
  >
    <FormattedText className={cx('title')}>{question.title}</FormattedText>
    <div className={cx('answer-container')}>
      {question.result !== 'correct' && (
        <p className={cx('answer', 'wrong')}>
          {question.solution.user || '___'}
        </p>
      )}
      {question.solution.exact ? (
        <>
          {question.result !== 'correct' && (
            <p className={cx('label')}>
              {t('Questions/ShortAnswer/Verso/exact-answer')}
            </p>
          )}
          <p
            className={cx('answer', {
              correct: question.result === 'correct',
            })}
          >
            {question.solution.exact}
          </p>
        </>
      ) : (
        <>
          {question.result !== 'correct' && (
            <p className={cx('label')}>
              {t('Questions/ShortAnswer/Verso/correct-answer')}
            </p>
          )}
          {question.solution.correct.map((text, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <p
              className={cx('answer', {
                correct: question.result === 'correct',
              })}
              key={index}
            >
              {text}
            </p>
          ))}
        </>
      )}
    </div>
    {question.content.feedback && (
     <Feedback feedback={question.content.feedback} />
    )}
  </div>
);

Verso.propTypes = {
  className: PropTypes.string,
  question: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
};

Verso.defaultProps = {
  className: undefined,
};

export default Verso;
