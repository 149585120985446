import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import Context from 'lib/context';

import Verso from './Verso';

const VersoContainer = (props) => {
  const { t } = useTranslation();
  const { theme } = useContext(Context);
  return <Verso t={t} theme={theme} {...props} />;
};

VersoContainer.defaultAnswer = () => '';

VersoContainer.defaultSolution = (question) => ({
  user: null,
  correct: question.content.answer,
});

export default VersoContainer;
