import classNames from 'classnames/bind';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import EDITOR_STATUSES from 'constants/editorStatuses';
import { useQueryLocalApp, useQueryLocalEditor } from 'gql/queries/local';
import { getQuestionInfo } from 'questions';
import Button from 'uikit/Button';

import styles from './QuestionEditorFooter.module.scss';
import SplittedButton from './SplittedButton/SplittedButton';

const cx = classNames.bind(styles);

const QuestionEditorFooter = ({
  course,
  onCancel,
  onDelete,
  onDeleteModalOpen,
  onGradeModalOpen,
}) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Shared/QuestionEdit/QuestionEditor/QuestionEditorFooter',
  });

  const {
    data: {
      app: { isMenuExpanded },
    },
  } = useQueryLocalApp();

  const {
    data: {
      editor: { status: editorStatus, questionType },
    },
  } = useQueryLocalEditor();

  const { handleSubmit, setFieldValue } = useFormikContext();

  const questionInfo = getQuestionInfo(questionType);

  const isNotImportingFromPDForPPT = questionInfo?.type !== 'PDFOrPPT';

  const isApprovingQuestion = editorStatus === EDITOR_STATUSES.QUESTION_APPROVE;

  const isProposingQuestion = editorStatus === EDITOR_STATUSES.QUESTION_PROPOSE;

  const canRecreateQuestion =
    !isApprovingQuestion && isNotImportingFromPDForPPT;

  const recreateButtonLabel = t(
    isProposingQuestion ? 'repropose-question' : 'recreate-question',
  );

  const handleQuestionDelete = () => {
    if (isApprovingQuestion) {
      onDeleteModalOpen();
    } else {
      onDelete();
    }
  };

  const handleQuestionRecreate = () => {
    setFieldValue('wantRecreate', true);
    handleSubmit();
  };

  return (
    <div className={cx('footer', { 'menu-expanded': isMenuExpanded })}>
      <Button
        className={cx('button', 'cancel')}
        onClick={onCancel}
        size="large"
      >
        {t('cancel')}
      </Button>
      <div className={cx('actions-right')}>
        {canRecreateQuestion ? (
          <Button
            className={cx('button')}
            onClick={handleQuestionRecreate}
            size="large"
          >
            {recreateButtonLabel}
          </Button>
        ) : isNotImportingFromPDForPPT ? (
          <Button
            className={cx('button')}
            icon="trash-alt"
            onClick={handleQuestionDelete}
            size="large"
          >
            {t('delete-question')}
          </Button>
        ) : null}
        <SplittedButton course={course} onGradeModalOpen={onGradeModalOpen} />
      </div>
    </div>
  );
};

QuestionEditorFooter.propTypes = {
  course: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onDeleteModalOpen: PropTypes.func.isRequired,
  onGradeModalOpen: PropTypes.func.isRequired,
};

export default QuestionEditorFooter;
