import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { COMMENT_REPLY_DATA } from 'gql/fragments';

export const COMMENT_REPLY_CREATE = gql`
  mutation CommentReplyCreate($commentId: ID!, $content: String!) {
    commentReplyCreate(commentId: $commentId, content: $content) {
      ...CommentReplyData
    }
  }
  ${COMMENT_REPLY_DATA}
`;

export function useMutationCommentReplyCreate() {
  const [mutation] = useMutation(COMMENT_REPLY_CREATE);
  return useCallback(
    (variables) =>
      mutation({
        variables,
        update(cache, { data: { commentReplyCreate } }) {
          const newCommentReplyRef = cache.writeFragment({
            data: commentReplyCreate,
            fragment: COMMENT_REPLY_DATA,
          });
          cache.modify({
            id: `Comment:${commentReplyCreate.commentId}`,
            fields: {
              isClosed: () => false,
              commentReplies: (existingCommentRepliesRef = []) => [
                ...existingCommentRepliesRef,
                newCommentReplyRef,
              ],
            },
          });
        },
      }),
    [mutation],
  );
}

export const COMMENT_REPLY_DELETE = gql`
  mutation CommentReplyDelete($commentReplyId: ID!) {
    commentReplyDelete(commentReplyId: $commentReplyId)
  }
`;

export function useMutationCommentReplyDelete() {
  const [mutation] = useMutation(COMMENT_REPLY_DELETE);
  return useCallback(
    (commentReply) =>
      mutation({
        variables: {
          commentReplyId: commentReply.id,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          commentReplyDelete: true,
        },
        update(cache) {
          cache.modify({
            id: `Comment:${commentReply.commentId}`,
            fields: {
              commentReplies: (
                existingCommentRepliesRef = [],
                { readField },
              ) => {
                const updatedCommentRepliesRef =
                  existingCommentRepliesRef.filter(
                    (c) => readField('id', c) !== commentReply.id,
                  );
                return updatedCommentRepliesRef;
              },
            },
          });
        },
      }),
    [mutation],
  );
}

export const COMMENT_REPLY_UPDATE = gql`
  mutation CommentReplyUpdate($commentReplyId: ID!, $content: String) {
    commentReplyUpdate(commentReplyId: $commentReplyId, content: $content) {
      ...CommentReplyData
    }
  }
  ${COMMENT_REPLY_DATA}
`;

export function useMutationCommentReplyUpdate() {
  const [mutation] = useMutation(COMMENT_REPLY_UPDATE);
  return useCallback(
    (commentReply, variables) =>
      mutation({
        variables: {
          ...variables,
          commentReplyId: commentReply.id,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          commentReplyUpdate: {
            ...commentReply,
            ...variables,
            __typename: 'CommentReply',
          },
        },
      }),
    [mutation],
  );
}

export const COMMENT_REPLY_GENERATE = gql`
  mutation CommentReplyGenerate($commentId: ID!) {
    commentReplyGenerate(commentId: $commentId)
  }
`;

export function useMutationCommentReplyGenerate() {
  const [mutation] = useMutation(COMMENT_REPLY_GENERATE);
  return useCallback(
    (variables) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}
