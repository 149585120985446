import classNames from 'classnames/bind';
import { useField } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Icon from 'uikit/Icon';

import imageGoogleLogo from './images/google.png';
import imageMicrosoftLogo from './images/microsoft.png';
import styles from './Social.module.scss';

const cx = classNames.bind(styles);

const Social = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'Auth/Shared/Social',
  });

  const [{ value: organization }] = useField('organization');

  const canSeeSocial =
    !organization ||
    !organization.forceSSO ||
    organization.allowGoogleSSO ||
    organization.allowMicrosoftSSO;

  return (
    canSeeSocial && (
      <div className={cx('container')}>
        <p className={cx('or')}>
          <span>{t('or')}</span>
        </p>
        <ul className={cx('buttons')}>
          {(!organization ||
            !organization.forceSSO ||
            (organization.forceSSO && organization.allowGoogleSSO)) && (
            <li>
              <a
                aria-label={t('google')}
                className={cx('button', 'google')}
                href={`${process.env.REACT_APP_API_URL}/api/auth/google/login`}
              >
                <img
                  alt=""
                  aria-hidden="true"
                  className={cx('logo')}
                  src={imageGoogleLogo}
                />
              </a>
            </li>
          )}
          {(!organization ||
            !organization.forceSSO ||
            (organization.forceSSO && organization.allowMicrosoftSSO)) && (
            <li>
              <a
                aria-label={t('microsoft')}
                className={cx('button', 'microsoft')}
                href={`${process.env.REACT_APP_API_URL}/api/auth/azure-ad/login`}
              >
                <img
                  alt=""
                  aria-hidden="true"
                  className={cx('logo')}
                  src={imageMicrosoftLogo}
                />
              </a>
            </li>
          )}
          {(!organization || (organization && !organization.forceSSO)) && (
            <li>
              <a
                aria-label={t('apple')}
                className={cx('button', 'apple')}
                href={`${process.env.REACT_APP_API_URL}/api/auth/apple/login`}
              >
                <Icon className={cx('logo')} name="apple" variant="brand" />
              </a>
            </li>
          )}
          {(!organization || (organization && !organization.forceSSO)) && (
            <li>
              <a
                aria-label={t('facebook')}
                className={cx('button', 'facebook')}
                href={`${process.env.REACT_APP_API_URL}/api/auth/facebook/login`}
              >
                <Icon className={cx('logo')} name="facebook" variant="brand" />
              </a>
            </li>
          )}
        </ul>
      </div>
    )
  );
};

export default Social;
