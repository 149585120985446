import PropTypes from 'prop-types';
import React from 'react';

import { useMutationMeTag } from 'gql/mutations/me';
import { trackEvent, reportError } from 'lib/tracking';

import Welcome from './Welcome/Welcome';
import Steps from './Steps/Steps';

const Tour = ({ me }) => {
  const mutationMeTag = useMutationMeTag();

  const currentStep = !me.tags.includes('onboarded')
    ? null
    : Math.max(
        0,
        ...me.tags
          .filter((tag) => tag.startsWith('tour:'))
          .map((tag) => Number(tag.split(':')[1]) + 1),
      );

  const isActive = currentStep !== null && currentStep <= 3;

  const handleCloseStep = async () => {
    try {
      if (currentStep !== null) {
        await mutationMeTag([`tour:${currentStep}`]);
        trackEvent('tag', `tour:${currentStep}`);
      }
    } catch (err) {
      reportError('tag', `tour:${currentStep}`, err);
    }
  };

  return isActive ? (
    currentStep === 0 ? (
      <Welcome onNextStep={handleCloseStep} />
    ) : (
      <Steps currentStep={currentStep} me={me} onNextStep={handleCloseStep} />
    )
  ) : null;
};

Tour.propTypes = {
  me: PropTypes.object.isRequired,
};

export default Tour;
