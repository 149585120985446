import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Feedback from 'questions/Shared/Feedback';
import FormattedText from 'uikit/FormattedText';

import ImageContainer from '../Shared/ImageContainer';
import styles from './Verso.module.scss';

const cx = classNames.bind(styles);

const Verso = ({ className, question, theme }) => (
  <div
    className={cx(
      'container',
      className,
      `theme-${theme}`,
    )}
  >
    <FormattedText className={cx('title')}>{question.title}</FormattedText>
    <ImageContainer
      className={cx('image')}
      imageSrc={question.content.image}
      solution={question.solution}
      result={question.result}
    />
    {question.content.feedback && (
     <Feedback feedback={question.content.feedback} />
    )}
  </div>
);

Verso.propTypes = {
  className: PropTypes.string,
  question: PropTypes.object.isRequired,
  theme: PropTypes.string.isRequired,
};

Verso.defaultProps = {
  className: undefined,
};

export default Verso;
