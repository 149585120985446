const orderAlphabetically = (list) =>
  list.sort(function (a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

export default orderAlphabetically;
