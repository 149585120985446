import classNames from 'classnames/bind';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router';

import Agenda from 'components/Shared/Agenda/Agenda';
import LoadingScreen from 'components/Shared/LoadingScreen';
import StudyDeadline from 'components/Shared/StudyDeadline';
import { mutationEditorReset } from 'gql/mutations/editor';
import { useMutationLMSGradeSynchronize } from 'gql/mutations/lms';
import { useQueryCourseStudents } from 'gql/queries/course';
import {
  useQueryResultsAggregated,
  useQueryResultsAggregatedByGroup,
  useQueryResultsAggregatedByMe,
  useQueryResultsAggregatedByStudent,
  useQueryResultsStudyStatistics,
  useQueryResultsStudyStatisticsByGroup,
  useQueryResultsStudyStatisticsByMe,
  useQueryResultsStudyStatisticsByStudent,
} from 'gql/queries/results';
import history from 'lib/history';
import { trackEvent, reportError } from 'lib/tracking';
import Button from 'uikit/Button';
import Icon from 'uikit/Icon/Icon';
import Menu, { MenuItem } from 'uikit/Menu';
import { notify } from 'uikit/Notifications';

import ClassificationMatrix from './ClassificationMatrix/ClassificationMatrix';
import CourseProgress from '../../Shared/Progress/CourseProgress';
import Chapters from '../../Shared/Progress/Chapters';
import Tags from './Tags/Tags';
import Questions from './Questions/Questions';
import Report from './Report/Report';
import Students from './Students/Students';
import styles from './Statistics.module.scss';

const cx = classNames.bind(styles);

const Statistics = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/Statistics',
  });

  const { courseId } = useParams();

  const { state } = useLocation();

  const mutationLMSGradeSynchronize = useMutationLMSGradeSynchronize();

  const { isLoading: isLoadingCourse, data: dataCourse } =
    useQueryCourseStudents({
      variables: {
        courseId,
      },
    });

  const {
    getResultsStudyStatistics,
    isLoading: isLoadingFull,
    data: dataFull,
  } = useQueryResultsStudyStatistics();

  const {
    getResultsStudyStatisticsByGroup,
    isLoading: isLoadingGroup,
    data: dataGroup,
  } = useQueryResultsStudyStatisticsByGroup();

  const {
    getResultsStudyStatisticsByMe,
    isLoading: isLoadingMe,
    data: dataMe,
  } = useQueryResultsStudyStatisticsByMe();

  const {
    getResultsStudyStatisticsByStudent,
    isLoading: isLoadingStudent,
    data: dataStudent,
  } = useQueryResultsStudyStatisticsByStudent();

  const {
    getResultsAggregated,
    isLoading: isLoadingReportFull,
    data: dataReportFull,
  } = useQueryResultsAggregated();

  const {
    getResultsAggregatedByGroup,
    isLoading: isLoadingReportGroup,
    data: dataReportGroup,
  } = useQueryResultsAggregatedByGroup();

  const {
    getResultsAggregatedByMe,
    isLoading: isLoadingReportMe,
    data: dataReportMe,
  } = useQueryResultsAggregatedByMe();

  const {
    getResultsAggregatedByStudent,
    isLoading: isLoadingReportStudent,
    data: dataReportStudent,
  } = useQueryResultsAggregatedByStudent();

  const [studentInputValue, setStudentInputValue] = useState('');

  const [groupInputValue, setGroupInputValue] = useState('');

  const students = useMemo(
    () =>
      isLoadingCourse
        ? []
        : [].concat(
            dataCourse.course.students,
            ...dataCourse.course.groups.map((g) => g.students),
          ),
    [dataCourse, isLoadingCourse],
  );

  const hasNoStudents = students.length === 0;

  const type = state?.type || (hasNoStudents ? 'me' : 'full');

  const display = state?.display || 'statistics';

  const isLoading =
    isLoadingCourse ||
    (display === 'statistics' && type === 'student'
      ? isLoadingStudent
      : display === 'statistics' && type === 'group'
      ? isLoadingGroup
      : display === 'statistics' && type === 'me'
      ? isLoadingMe
      : display === 'statistics' && type === 'full'
      ? isLoadingFull
      : display === 'report' && type === 'student'
      ? isLoadingReportStudent
      : display === 'report' && type === 'group'
      ? isLoadingReportGroup
      : display === 'report' && type === 'me'
      ? isLoadingReportMe
      : isLoadingReportFull);

  const groups = isLoadingCourse ? [] : dataCourse.course.groups;

  const groupId = state?.groupId || null;

  const studentId = state?.studentId || null;

  const selectedStudent = useMemo(
    () =>
      isLoading || type !== 'student'
        ? undefined
        : students.find((student) => student.id === state?.studentId) || [
            students[0],
          ],
    [isLoading, state, students, type],
  );

  const selectedGroup = useMemo(
    () =>
      isLoading || type !== 'group'
        ? undefined
        : dataCourse.course.groups.find((group) => group.id === state?.groupId),
    [isLoading, dataCourse, type, state],
  );

  useEffect(() => {
    if (display === 'statistics' && type === 'student') {
      getResultsStudyStatisticsByStudent({
        variables: {
          studentId,
        },
      });
    } else if (display === 'statistics' && type === 'group') {
      getResultsStudyStatisticsByGroup({
        variables: {
          groupId,
        },
      });
    } else if (display === 'statistics' && type === 'me') {
      getResultsStudyStatisticsByMe({
        variables: {
          courseId,
        },
      });
    } else if (display === 'statistics' && type === 'full') {
      getResultsStudyStatistics({
        variables: {
          courseId,
        },
      });
    } else if (display === 'report' && type === 'student') {
      getResultsAggregatedByStudent({
        variables: {
          studentId,
        },
      });
    } else if (display === 'report' && type === 'group') {
      getResultsAggregatedByGroup({
        variables: {
          groupId,
        },
      });
    } else if (display === 'report' && type === 'me') {
      getResultsAggregatedByMe({
        variables: {
          courseId,
        },
      });
    } else if (display === 'report' && type === 'full') {
      getResultsAggregated({
        variables: {
          courseId,
        },
      });
    }
  }, [
    courseId,
    display,
    getResultsAggregated,
    getResultsAggregatedByGroup,
    getResultsAggregatedByMe,
    getResultsAggregatedByStudent,
    getResultsStudyStatistics,
    getResultsStudyStatisticsByGroup,
    getResultsStudyStatisticsByMe,
    getResultsStudyStatisticsByStudent,
    groupId,
    studentId,
    type,
  ]);

  useEffect(() => {
    if (selectedStudent) {
      setStudentInputValue(
        `${selectedStudent.lastName.toUpperCase()}, ${
          selectedStudent.firstName
        }`,
      );
    }
  }, [selectedStudent]);

  useEffect(() => {
    if (selectedGroup) {
      setGroupInputValue(selectedGroup.title);
    }
  }, [selectedGroup]);

  useEffect(() => {
    mutationEditorReset();
    return () => {
      mutationEditorReset();
    };
  }, []);

  const handleChangeDisplay = () => {
    if (type === 'student') {
      history.push({
        pathname: `/course/${courseId}/statistics`,
        state: {
          display: display === 'statistics' ? 'report' : 'statistics',
          type: 'student',
          studentId: students && students[0] && students[0].id,
        },
      });
    } else if (type === 'group') {
      history.push({
        pathname: `/course/${courseId}/statistics`,
        state: {
          display: display === 'statistics' ? 'report' : 'statistics',
          type: 'group',
          groupId: groups && groups[0] && groups[0].id,
        },
      });
    } else if (type === 'me') {
      history.push({
        pathname: `/course/${courseId}/statistics`,
        state: {
          display: display === 'statistics' ? 'report' : 'statistics',
          type: 'me',
        },
      });
    } else {
      history.push({
        pathname: `/course/${courseId}/statistics`,
        state: {
          display: display === 'statistics' ? 'report' : 'statistics',
          type: 'full',
        },
      });
    }
  };

  const handleChangeType = (ty) => {
    if (ty === 'student') {
      history.push({
        pathname: `/course/${courseId}/statistics`,
        state: {
          display,
          type: 'student',
          studentId: students && students[0] && students[0].id,
        },
      });
    } else if (ty === 'group') {
      history.push({
        pathname: `/course/${courseId}/statistics`,
        state: {
          display,
          type: 'group',
          groupId: groups && groups[0] && groups[0].id,
        },
      });
    } else if (ty === 'me') {
      history.push({
        pathname: `/course/${courseId}/statistics`,
        state: {
          display,
          type: 'me',
        },
      });
    } else {
      history.push({
        pathname: `/course/${courseId}/statistics`,
        state: {
          display,
          type: 'full',
        },
      });
    }
  };

  const handleGradeSynchronize = async () => {
    try {
      await mutationLMSGradeSynchronize({ courseId });
      trackEvent('LMS', 'grade synchronize');
      notify('success', t('grade-synchronized'));
    } catch (err) {
      reportError('LMS', 'grade synchronize', err);
    }
  };

  const handleGroupInputBlur = () => {
    if (selectedGroup) {
      setGroupInputValue(selectedGroup.title);
    }
  };

  const handleGroupInputChange = (event) =>
    setGroupInputValue(event.target.value);

  const handleGroupSelect = (gId) => {
    history.push({
      pathname: `/course/${courseId}/statistics`,
      state: {
        display,
        type: 'group',
        groupId: gId,
      },
    });
  };

  const handleStudentInputBlur = () => {
    if (selectedStudent) {
      setStudentInputValue(
        `${selectedStudent.lastName.toUpperCase()}, ${
          selectedStudent.firstName
        }`,
      );
    }
  };

  const handleStudentInputChange = (event) =>
    setStudentInputValue(event.target.value);

  const handleStudentSelect = (sId) => {
    history.push({
      pathname: `/course/${courseId}/statistics`,
      state: {
        display,
        type: 'student',
        studentId: sId,
      },
    });
  };

  const course = dataCourse?.course;

  const data =
    display === 'statistics'
      ? type === 'student'
        ? dataStudent?.resultsStudyStatisticsByStudent
        : type === 'group'
        ? dataGroup?.resultsStudyStatisticsByGroup
        : type === 'me'
        ? dataMe?.resultsStudyStatisticsByMe
        : dataFull?.resultsStudyStatistics
      : type === 'student'
      ? dataReportStudent?.resultsAggregatedByStudent
      : type === 'group'
      ? dataReportGroup?.resultsAggregatedByGroup
      : type === 'me'
      ? dataReportMe?.resultsAggregatedByMe
      : dataReportFull?.resultsAggregated;

  const filteredGroups = groups.filter(
    (group) =>
      group.title.toLowerCase().includes(groupInputValue.toLowerCase()) ||
      groupInputValue === '' ||
      groups.some(
        (g) => g.title.toLowerCase() === groupInputValue.toLowerCase(),
      ),
  );

  const filteredStudents = students
    .filter(
      (student) =>
        `${student.lastName.toUpperCase()}, ${student.firstName}`
          .toLowerCase()
          .includes(studentInputValue.toLowerCase()) ||
        studentInputValue === '' ||
        students.some(
          (s) =>
            `${s.lastName.toUpperCase()}, ${s.firstName}`.toLowerCase() ===
            studentInputValue.toLowerCase(),
        ),
    )
    .sort((a, b) => a.lastName.localeCompare(b.lastName));

  const hasNoGroups = groups.length === 0;

  return isLoading ? (
    <LoadingScreen />
  ) : (
    <div className={cx('container')}>
      {course.moodle.isMoodle && (
        <Button
          className={cx('lms')}
          intent="primary"
          onClick={handleGradeSynchronize}
        >
          {t('moodle-grade-sync')}
        </Button>
      )}
      <div className={cx('choices')}>
        <button
          className={cx('choice', {
            isActive: display === 'statistics',
          })}
          onClick={() => handleChangeDisplay('statistics')}
          type="button"
        >
          {t('statistics')}
        </button>
        <button
          className={cx('choice', {
            isActive: display === 'report',
          })}
          onClick={() => handleChangeDisplay('report')}
          type="button"
        >
          {t('report')}
        </button>
      </div>
      <div className={cx('header')}>
        <div className={cx('select-container')}>
          <select
            className={cx('select')}
            onChange={(evt) => handleChangeType(evt.target.value)}
            value={type}
          >
            <option value="full" disabled={hasNoStudents}>
              {t(
                display === 'statistics' ? 'stats-see-full' : 'report-see-full',
              )}
            </option>
            <option value="me">
              {t(display === 'statistics' ? 'stats-see-me' : 'report-see-me')}
            </option>
            <option value="group" disabled={hasNoGroups}>
              {t(
                display === 'statistics'
                  ? 'stats-see-groups'
                  : 'report-see-groups',
              )}
            </option>
            <option value="student" disabled={hasNoStudents}>
              {t(
                display === 'statistics'
                  ? 'stats-see-students'
                  : 'report-see-students',
              )}
            </option>
          </select>
        </div>
        <div className={cx('filler')} />
        {type === 'group' && (
          <Menu
            className={cx('menu-group')}
            isHideOnClickDeactivated
            placement="bottom"
            toggle={
              <div className={cx('input-group-container')}>
                <input
                  className={cx('input-group')}
                  onBlur={handleGroupInputBlur}
                  onChange={handleGroupInputChange}
                  type="text"
                  value={groupInputValue}
                />
                <Icon className={cx('icon-input-group')} name="chevron-down" />
              </div>
            }
          >
            {filteredGroups.map((group) => (
              <MenuItem
                key={group.id}
                onClick={() => handleGroupSelect(group.id)}
                variant="regular"
              >
                {group.title}
              </MenuItem>
            ))}
          </Menu>
        )}
        {type === 'student' && (
          <Menu
            className={cx('menu-student')}
            isHideOnClickDeactivated
            placement="bottom"
            toggle={
              <div className={cx('input-student-container')}>
                <input
                  className={cx('input-student')}
                  onBlur={handleStudentInputBlur}
                  onChange={handleStudentInputChange}
                  type="text"
                  value={studentInputValue}
                />
                <Icon
                  className={cx('icon-input-student')}
                  name="chevron-down"
                />
              </div>
            }
          >
            {filteredStudents.map((student) => (
              <MenuItem
                key={student.id}
                onClick={() => handleStudentSelect(student.id)}
                variant="regular"
              >
                {`${student.lastName.toUpperCase()}, ${student.firstName}`}
              </MenuItem>
            ))}
          </Menu>
        )}
      </div>
      {display === 'statistics' ? (
        <>
          <CourseProgress className={cx('course')} course={data.course} />
          {type === 'me' &&
            (course.deadline ? (
              <Agenda course={data.course} isSingle />
            ) : (
              <StudyDeadline
                className={cx('study-deadline')}
                course={data.course}
                from="teacher-statistics"
              />
            ))}
          <ClassificationMatrix
            className={cx('matrix')}
            course={course}
            groupId={groupId}
            matrix={data.classificationMatrix}
            studentId={studentId}
            totalStudents={Number(data.counts.students)}
            type={type}
          />
          {data.chapters.length !== 0 && (
            <Chapters
              className={cx('chapters')}
              chapters={data.chapters}
              course={data.course}
            />
          )}
          {data.tags.length !== 0 && (
            <Tags className={cx('tags')} tags={data.tags} />
          )}
          {data.questions.length !== 0 && (
            <Questions
              className={cx('questions')}
              course={course}
              groupId={groupId}
              nQuestions={Number(data.counts.questions)}
              questions={data.questions}
              studentId={studentId}
            />
          )}
          {data.students.length !== 0 && (
            <Students
              className={cx('students')}
              course={course}
              groupId={groupId}
              studentId={studentId}
              students={data.students}
              totalStudents={Number(data.counts.students)}
              type={type}
            />
          )}
        </>
      ) : (
        <Report aggregatedStudyAnswers={data} />
      )}
    </div>
  );
};

export default Statistics;
