import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import LanguageSelector from 'components/Shared/LanguageSelector';
import { useMutationMeUpdate } from 'gql/mutations/me';
import { useQueryMe } from 'gql/queries/me';
import { trackEvent, reportError } from 'lib/tracking';

import LOWER_IMAGE from './images/lower.svg';
import HIGHER_IMAGE from './images/higher.svg';
import BUSINESS_IMAGE from './images/business.svg';
import styles from './Sector.module.scss';

const cx = classNames.bind(styles);

const Sector = ({ handleStepNext }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'App/Onboarding/Sector',
  });

  const mutationMeUpdate = useMutationMeUpdate();

  const { data: meData } = useQueryMe();

  const isNewOnboarding = meData.me.flags?.includes('new-onboarding');

  const onboardingSteps = new Array(isNewOnboarding ? 6 : 3).fill(0);

  const handleSectorUpdate = async (sector) => {
    try {
      await mutationMeUpdate({
        sector,
      });
      handleStepNext();
      trackEvent('onboarding', 'sector');
    } catch (err) {
      reportError('onboarding', 'sector', err);
    }
  };

  return (
    <div className={cx('container')}>
      <div className={cx('form-container')}>
        <div className={cx('header')}>
          <div className={cx('points')}>
            {onboardingSteps.map((_, index) => (
              <div className={cx('point')} key={`point-${index}`} />
            ))}
          </div>
          <LanguageSelector className={cx('language')} intent="square" />
        </div>
        <div className={cx('form')}>
          <h1 className={cx('title')}>{t('select-sector')}</h1>
          <div className={cx('select-buttons')}>
            <button
              className={cx('select-button')}
              onClick={() => handleSectorUpdate('lower')}
              type="button"
            >
              <img
                alt=""
                aria-hidden="true"
                className={cx('button-illustration')}
                src={LOWER_IMAGE}
              />
              {t('lower')}
            </button>
            <button
              className={cx('select-button')}
              data-testid={
                isNewOnboarding
                  ? 'higher-education-button-new-tour'
                  : 'higher-education-button'
              }
              onClick={() => handleSectorUpdate('higher')}
              type="button"
            >
              <img
                alt=""
                aria-hidden="true"
                className={cx('button-illustration')}
                src={HIGHER_IMAGE}
              />
              {t('higher')}
            </button>
            <button
              className={cx('select-button')}
              onClick={() => handleSectorUpdate('business')}
              type="button"
            >
              <img
                alt=""
                aria-hidden="true"
                className={cx('button-illustration')}
                src={BUSINESS_IMAGE}
              />
              {t('business')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

Sector.propTypes = {
  handleStepNext: PropTypes.func.isRequired,
};

export default Sector;
