import React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import Editor from './Editor';

const EditorContainer = (props) => {
  const { t } = useTranslation();
  return <Editor t={t} {...props} />;
};

EditorContainer.validationSchema = (t) =>
  yup.object().shape({
    tags: yup.array().of(yup.string().trim().lowercase().max(256)).compact(),
    title: yup
      .string()
      .trim()
      .required(t('Questions/SCT/Editor/title-error-required')),
    subtitle: yup
      .string()
      .trim()
      .required(t('Questions/SCT/Editor/hypothesis-error-required')),
    additionalInformation: yup
      .string()
      .trim()
      .required(
        t('Questions/SCT/Editor/additional-information-error-required'),
      ),
    labels: yup.array().of(yup.string().nullable().trim()),
    experts: yup.array().of(yup.number().integer().min(0)),
    expertFeedbacks: yup
      .array()
      .of(
        yup.array().of(
          yup.object().shape({
            id: yup.string().trim().nullable().uuid(),
            firstName: yup.string().trim().nullable(),
            lastName: yup.string().trim().nullable(),
            feedback: yup.string().trim(),
          }),
        ),
      )
      .required(),
    feedback: yup.string().trim(),
  });

export default EditorContainer;
