import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useMutationFileDelete } from 'gql/mutations/file';
import Button from 'uikit/Button';
import Icon from 'uikit/Icon';
import Menu, { MenuItem } from 'uikit/Menu';

import styles from './FileCard.module.scss';

const cx = classNames.bind(styles);

const FileCard = ({
  className = null,
  file,
  isQuestionBank = false,
  isTeacher = false,
}) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Shared/Files/FileCard',
  });

  const mutationFileDelete = useMutationFileDelete();

  const handleFileDelete = () => {
    mutationFileDelete(file);
  };

  const handleFileOpen = () => {
    window.open(file.link, '_blank').focus();
  };

  return (
    <div className={cx('container', className, { public: isQuestionBank })}>
      {!isQuestionBank && (
        <Button
          className={cx('action')}
          icon="download"
          onClick={handleFileOpen}
          size="small"
          tooltip={t('Download-file')}
        />
      )}
      <div className={cx('icon')}>
        <Icon name="file" />
      </div>
      <p className={cx('title')}>{file.name}</p>
      {isTeacher && (
        <Menu
          placement="bottom-end"
          toggle={
            <Button
              className={cx('action')}
              icon="ellipsis-v"
              size="small"
              tooltip={t('options')}
            />
          }
        >
          <MenuItem
            icon="trash-alt"
            onClick={handleFileDelete}
            variant="regular"
          >
            {t('delete-file')}
          </MenuItem>
        </Menu>
      )}
    </div>
  );
};

FileCard.propTypes = {
  className: PropTypes.string,
  file: PropTypes.object.isRequired,
  isQuestionBank: PropTypes.bool,
  isTeacher: PropTypes.bool,
};

export default FileCard;
