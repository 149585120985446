import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { Droppable } from 'react-beautiful-dnd';

import CourseItem from '../CourseItem';
import styles from './Standalone.module.scss';

const cx = classNames.bind(styles);

const Standalone = ({ className, courses }) => (
  <div className={className}>
    <Droppable droppableId="dashboard" type="course">
      {(providedCourses, snapshotCourses) => (
        <div
          className={cx('droppable', {
            isDraggingOver: snapshotCourses.isDraggingOver,
          })}
          ref={providedCourses.innerRef}
          {...providedCourses.droppableProps}
        >
          {courses.map((course, index) => (
            <CourseItem
              className={cx('course')}
              course={course}
              index={index}
              key={course.id}
              folderId=""
            />
          ))}
          {providedCourses.placeholder}
        </div>
      )}
    </Droppable>
  </div>
);

Standalone.propTypes = {
  className: PropTypes.string,
  courses: PropTypes.arrayOf(PropTypes.object).isRequired,
};

Standalone.defaultProps = {
  className: undefined,
};

export default Standalone;
