import classNames from 'classnames/bind';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'uikit/Button';
import InputText from 'uikit/InputText';

import styles from './CourseTitle.module.scss';

const cx = classNames.bind(styles);

const CourseTitle = ({
  className,
  course,
  handleTitleEdit,
  handleEditDone,
  handleTitleMount,
  isEditing,
  t,
}) => (
  <div className={cx('container', className)}>
    {isEditing ? (
      <Formik
        initialValues={{
          title: course.title,
        }}
        onSubmit={handleEditDone}
      >
        {({ isSubmitting, submitForm }) => (
          <Form className={cx('form')}>
            <InputText
              className={cx('field')}
              name="title"
              onBlur={submitForm}
              ref={handleTitleMount}
            />
            <Button icon="check" isLoading={isSubmitting} type="submit" />
          </Form>
        )}
      </Formik>
    ) : (
      <div className={cx('title-container')}>
        <h1 className={cx('title')}>{course.title}</h1>
        <Button
          aria-label={t('Course/Teacher/Cover/CourseTitle/edit')}
          className={cx('edit-button')}
          icon="pencil"
          onClick={handleTitleEdit}
          size="small"
        />
      </div>
    )}
  </div>
);

CourseTitle.propTypes = {
  className: PropTypes.string,
  course: PropTypes.object.isRequired,
  handleTitleEdit: PropTypes.func.isRequired,
  handleEditDone: PropTypes.func.isRequired,
  handleTitleMount: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

CourseTitle.defaultProps = {
  className: undefined,
};

export default CourseTitle;
