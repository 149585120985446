import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { GROUP_DATA, GROUP_FULL_DATA } from 'gql/fragments';

export const GROUP_CREATE = gql`
  mutation GroupCreate($courseId: ID!, $title: String!) {
    groupCreate(courseId: $courseId, title: $title) {
      ...GroupFullData
    }
  }
  ${GROUP_FULL_DATA}
`;

export function useMutationGroupCreate() {
  const [mutation] = useMutation(GROUP_CREATE);
  return useCallback(
    (variables) =>
      mutation({
        variables,
        update(cache, { data: { groupCreate } }) {
          const newGroupRef = cache.writeFragment({
            data: groupCreate,
            fragment: GROUP_FULL_DATA,
            fragmentName: 'GroupFullData',
          });
          cache.modify({
            id: `Course:${groupCreate.courseId}`,
            fields: {
              groups: (existingGroupsRef = [], { readField }) =>
                [...existingGroupsRef, newGroupRef].sort((a, b) =>
                  readField('title', a).localeCompare(
                    readField('title', b),
                    undefined,
                    {
                      numeric: true,
                      sensitivity: 'base',
                    },
                  ),
                ),
            },
          });
        },
      }),
    [mutation],
  );
}

export const GROUP_DELETE = gql`
  mutation GroupDelete($groupId: ID!) {
    groupDelete(groupId: $groupId)
  }
`;

export function useMutationGroupDelete() {
  const [mutation] = useMutation(GROUP_DELETE);
  return useCallback(
    (group) =>
      mutation({
        variables: {
          groupId: group.id,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          groupDelete: true,
        },
        update(cache) {
          cache.modify({
            id: `Course:${group.courseId}`,
            fields: {
              groups: (existingGroupsRef = [], { readField }) =>
                existingGroupsRef.filter(
                  (c) => readField('id', c) !== group.id,
                ),
            },
          });
        },
      }),
    [mutation],
  );
}

export const GROUP_UPDATE = gql`
  mutation GroupUpdate($groupId: ID!, $title: String) {
    groupUpdate(groupId: $groupId, title: $title) {
      ...GroupData
    }
  }
  ${GROUP_DATA}
`;

export function useMutationGroupUpdate() {
  const [mutation] = useMutation(GROUP_UPDATE);
  return useCallback(
    (group, variables) =>
      mutation({
        variables: {
          ...variables,
          groupId: group.id,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          groupUpdate: {
            ...group,
            ...variables,
            __typename: 'Group',
          },
        },
        update(cache) {
          cache.modify({
            id: `Course:${group.courseId}`,
            fields: {
              groups: (existingGroupsRef = [], { readField }) =>
                [...existingGroupsRef].sort((a, b) =>
                  readField('title', a).localeCompare(
                    readField('title', b),
                    undefined,
                    {
                      numeric: true,
                      sensitivity: 'base',
                    },
                  ),
                ),
            },
          });
        },
      }),
    [mutation],
  );
}
