import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

export const COLLABORATOR_DELETE = gql`
  mutation CollaboratorDelete($participantId: ID!) {
    collaboratorDelete(participantId: $participantId)
  }
`;

export function useMutationCollaboratorDelete() {
  const [mutation] = useMutation(COLLABORATOR_DELETE);
  return useCallback(
    (collaborator) =>
      mutation({
        variables: {
          participantId: collaborator.id,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          collaboratorDelete: true,
        },
        update(cache) {
          cache.modify({
            id: `Course:${collaborator.courseId}`,
            fields: {
              collaborators: (existingCollaboratorsRef = [], { readField }) =>
                existingCollaboratorsRef.filter(
                  (c) => readField('id', c) !== collaborator.id,
                ),
            },
          });
        },
      }),
    [mutation],
  );
}
