import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import CourseContent from './CourseContent';

const CourseContentContainer = ({ course, ...props }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const isQuestionBank = pathname.includes('/public-course');
  const hasComments =
    course.chapters?.some((chapter) =>
      chapter.questions.some((question) => !!question.comments),
    ) || course.questions?.some((question) => !!question.comments);
  return (
    <CourseContent
      {...props}
      course={course}
      hasComments={hasComments}
      isQuestionBank={isQuestionBank}
      t={t}
    />
  );
};

CourseContent.propTypes = {
  course: PropTypes.object.isRequired,
};

export default CourseContentContainer;
