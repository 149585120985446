import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useMutationMeUpdate } from 'gql/mutations/me';
import { trackEvent, reportError } from 'lib/tracking';
import imageStudent from './images/student.svg';
import imageTeacher from './images/teacher.svg';
import styles from './Role.module.scss';

const cx = classNames.bind(styles);

const Role = ({ me }) => {
  const { t } = useTranslation('', {
    KeyPrefix: 'App/Onboarding/Role',
  });

  const mutationMeUpdate = useMutationMeUpdate();

  const handleRoleUpdate = async (role) => {
    try {
      await mutationMeUpdate({
        role,
      });
      trackEvent('migration', 'role');
    } catch (err) {
      reportError('migration', 'role', err);
    }
  };

  return (
    <div className={cx('container')}>
      <h1 className={cx('title')}>{t('select-role')}</h1>
      <div className={cx('roles')}>
        <button
          className={cx('role')}
          onClick={() => handleRoleUpdate('student')}
          type="button"
        >
          <img
            alt=""
            aria-hidden="true"
            className={cx('role-image')}
            src={imageStudent}
          />
          <span className={cx('role-text')}>
            {me.sector === 'lower'
              ? t('student-lower')
              : me.sector === 'higher'
              ? t('student-higher')
              : t('student-business')}
          </span>
        </button>
        <button
          className={cx('role')}
          onClick={() => handleRoleUpdate('teacher')}
          type="button"
        >
          <img
            alt=""
            aria-hidden="true"
            className={cx('role-image')}
            src={imageTeacher}
          />
          <span className={cx('role-text')}>
            {me.sector === 'lower'
              ? t('teacher-lower')
              : me.sector === 'higher'
              ? t('teacher-higher')
              : t('teacher-business')}
          </span>
        </button>
      </div>
    </div>
  );
};

Role.propTypes = {
  me: PropTypes.object.isRequired,
};

export default Role;
