import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import PERMISSIONS from 'constants/permissions';

import CourseDelete from 'components/Shared/CourseDelete';
import CourseLeave from 'components/Shared/CourseLeave';
import LoadingScreen from 'components/Shared/LoadingScreen';
import CourseStudy from 'components/Course/Shared/CourseStudy/CourseStudy';
import ExamStart from 'components/Course/Shared/ExamStart';

import Button from 'uikit/Button';
import Menu, { MenuItem } from 'uikit/Menu';

import CourseDuplicate from './CourseDuplicate';
import CourseExport from './CourseExport';
import CourseIntroduction from './CourseIntroduction';
import CourseShare from './CourseShare/CourseShare';
import CourseTheme from './CourseTheme';
import CourseTitle from './CourseTitle';
import imageMoodle from './images/moodle.png';
import Participants from './Participants';
import styles from './Cover.module.scss';

const cx = classNames.bind(styles);

const Cover = ({
  activeTab,
  className,
  course,
  isGAR,
  isInTeams,
  isLoading,
  isRoleTeacher,
  isTNECanope,
  t,
}) =>
  isLoading ? (
    <LoadingScreen />
  ) : (
    <div className={cx('container', className)}>
      <CourseTheme course={course} />
      <div className={cx('main')}>
        <div className={cx('top')}>
          <div className={cx('header')}>
            <CourseTitle className={cx('title')} course={course} />
            <CourseIntroduction course={course} />
          </div>
          {course.isExam ? (
            <>
              <ExamStart
                className={cx('top-action')}
                course={course}
                isTeacher
              />
              {course.isExamReviewEnabled && (
                <Button
                  as={Link}
                  className={cx('top-action')}
                  size="large"
                  to={`/test-review/${course.id}`}
                >
                  {t('Course/Teacher/Cover/test-review')}
                </Button>
              )}
            </>
          ) : (
            <CourseStudy
              className={cx('top-action')}
              course={course}
              intent="white"
              isEditionView={true}
              size="large"
            />
          )}
          {course.moodle.isMoodle && (
            <Button
              as="a"
              className={cx('top-action')}
              href={course.moodle.courseUrl}
              icon={
                <img
                  alt=""
                  aria-hidden="true"
                  className={cx('moodle')}
                  src={imageMoodle}
                />
              }
              intent="white"
              size="large"
              target="_top"
            >
              {t('Course/Teacher/Cover/moodle')}
            </Button>
          )}
          {!isTNECanope && (
            <CourseShare
              className={cx('top-action', 'share')}
              course={course}
            />
          )}
          <Menu
            className={cx('menu')}
            placement="bottom-end"
            toggle={
              <Button
                aria-label={t('Course/Teacher/Cover/options')}
                className={cx('options')}
                icon="ellipsis-v"
              />
            }
          >
            {!course.isExam && !isInTeams && !isTNECanope && (
              <MenuItem
                as={Link}
                icon="gamepad"
                to={`/course/${course.id}/games`}
              >
                {t('Course/Teacher/Cover/game')}
              </MenuItem>
            )}
            <CourseExport course={course} />
            {!isInTeams && <CourseDuplicate course={course} />}
            {course.level >= PERMISSIONS.OWNER ? (
              <>{!isInTeams && <CourseDelete course={course} />}</>
            ) : (
              <>{!isInTeams && <CourseLeave course={course} />}</>
            )}
          </Menu>
        </div>
        <div className={cx('scroll')}>
          <div className={cx('tabs')} role="tablist">
            <Link
              aria-selected={activeTab === ''}
              className={cx('tab', {
                isActive: activeTab === '',
              })}
              data-testid="content-tab"
              role="tab"
              to={`/course/${course.id}/`}
            >
              {t('Course/Teacher/Cover/content')}
            </Link>
            {course.isExam ? (
              <Link
                aria-selected={activeTab === 'test-results'}
                className={cx('tab', {
                  isActive: activeTab === 'test-results',
                })}
                role="tab"
                to={`/course/${course.id}/test-results`}
              >
                {t('Course/Teacher/Cover/test-results')}
              </Link>
            ) : (
              <Link
                aria-selected={activeTab === 'statistics'}
                className={cx('tab', {
                  isActive: activeTab === 'statistics',
                })}
                role="tab"
                to={`/course/${course.id}/statistics`}
              >
                {t('Course/Teacher/Cover/statistics')}
              </Link>
            )}
            <Link
              aria-selected={[
                'participants',
                'collaborators',
                'students',
              ].includes(activeTab)}
              className={cx('tab', {
                isActive: [
                  'participants',
                  'collaborators',
                  'students',
                ].includes(activeTab),
              })}
              role="tab"
              to={`/course/${course.id}/participants`}
            >
              {t(
                isGAR && !isRoleTeacher
                  ? 'Course/Teacher/Cover/collaborators'
                  : 'Course/Teacher/Cover/participants',
              )}
            </Link>
            <Link
              aria-selected={activeTab === 'settings'}
              className={cx('tab', {
                isActive: activeTab === 'settings',
              })}
              data-testid="settings-tab"
              role="tab"
              to={`/course/${course.id}/settings`}
            >
              {t('Course/Teacher/Cover/settings')}
            </Link>
          </div>
          <div className={cx('filler')} />
          <Participants courseId={course?.id} />
        </div>
      </div>
    </div>
  );

Cover.propTypes = {
  activeTab: PropTypes.string,
  className: PropTypes.string,
  course: PropTypes.object,
  isGAR: PropTypes.bool.isRequired,
  isInTeams: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isRoleTeacher: PropTypes.bool.isRequired,
  isTNECanope: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

Cover.defaultProps = {
  activeTab: '',
  className: undefined,
  course: undefined,
};

export default Cover;
