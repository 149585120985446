import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { CHAPTER_DATA, CHAPTER_FULL_DATA } from 'gql/fragments';
import { QUERY_COURSE_CONTENT } from 'gql/queries/course';

export const CHAPTER_CREATE = gql`
  mutation ChapterCreate(
    $courseId: ID!
    $title: String!
    $onlineTimestamp: Date
    $offlineTimestamp: Date
    $isLinked: Boolean
  ) {
    chapterCreate(
      courseId: $courseId
      title: $title
      onlineTimestamp: $onlineTimestamp
      offlineTimestamp: $offlineTimestamp
      isLinked: $isLinked
    ) {
      ...ChapterFullData
    }
  }
  ${CHAPTER_FULL_DATA}
`;

export function useMutationChapterCreate() {
  const [mutation] = useMutation(CHAPTER_CREATE);
  return useCallback(
    (variables) =>
      mutation({
        variables: {
          ...variables,
        },
        update(cache, { data: { chapterCreate } }) {
          const newChapterRef = cache.writeFragment({
            data: chapterCreate,
            fragment: CHAPTER_FULL_DATA,
            fragmentName: 'ChapterFullData',
          });
          cache.modify({
            id: `Course:${chapterCreate.courseId}`,
            fields: {
              chapters: (existingChaptersRef = []) => [
                ...existingChaptersRef,
                newChapterRef,
              ],
            },
          });
        },
      }),
    [mutation],
  );
}

export const CHAPTER_DELETE = gql`
  mutation ChapterDelete($chapterId: ID!) {
    chapterDelete(chapterId: $chapterId)
  }
`;

export function useMutationChapterDelete() {
  const [mutation] = useMutation(CHAPTER_DELETE, {
    refetchQueries: [QUERY_COURSE_CONTENT],
  });
  return useCallback(
    (chapter) =>
      mutation({
        variables: {
          chapterId: chapter.id,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          chapterDelete: true,
        },
        update(cache) {
          cache.modify({
            id: `Course:${chapter.courseId}`,
            fields: {
              chapters: (existingChaptersRef = [], { readField }) => {
                const updatedChaptersRef = existingChaptersRef.filter(
                  (c) => readField('id', c) !== chapter.id,
                );
                updatedChaptersRef.forEach((c) => {
                  const rOrder = readField('order', c);
                  if (rOrder >= chapter.order) {
                    cache.writeFragment({
                      id: `Chapter:${readField('id', c)}`,
                      fragment: gql`
                        fragment ChapterUpdateOrder on Chapter {
                          order
                        }
                      `,
                      data: {
                        order: rOrder - 1,
                      },
                    });
                  }
                });
                return updatedChaptersRef;
              },
            },
          });
        },
      }),
    [mutation],
  );
}

export const CHAPTER_DUPLICATE = gql`
  mutation ChapterDuplicate($chapterId: ID!) {
    chapterDuplicate(chapterId: $chapterId) {
      ...ChapterFullData
    }
  }
  ${CHAPTER_FULL_DATA}
`;

export function useMutationChapterDuplicate() {
  const [mutation] = useMutation(CHAPTER_DUPLICATE, {
    refetchQueries: [QUERY_COURSE_CONTENT],
  });
  return useCallback(
    (chapter) =>
      mutation({
        variables: {
          chapterId: chapter.id,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          chapterDuplicate: {
            ...chapter,
            id: Math.random().toString(10),
            order: chapter.order + 1,
            questions: (chapter.questions || []).map((q) => ({
              ...q,
              id: Math.random().toString(10),
            })),
            __typename: 'Chapter',
          },
        },
        update(cache, { data: { chapterDuplicate } }) {
          const newChapterRef = cache.writeFragment({
            data: chapterDuplicate,
            fragment: CHAPTER_FULL_DATA,
            fragmentName: 'ChapterFullData',
          });
          cache.modify({
            id: `Course:${chapterDuplicate.courseId}`,
            fields: {
              chapters: (existingChaptersRef = [], { readField }) => {
                existingChaptersRef.forEach((c) => {
                  const rOrder = readField('order', c);
                  if (rOrder >= chapterDuplicate.order) {
                    cache.writeFragment({
                      id: `Chapter:${readField('id', c)}`,
                      fragment: gql`
                        fragment ChapterUpdateOrder on Chapter {
                          order
                        }
                      `,
                      data: {
                        order: rOrder + 1,
                      },
                    });
                  }
                });
                const updatedChaptersRef = [...existingChaptersRef];
                updatedChaptersRef.splice(
                  chapterDuplicate.order,
                  0,
                  newChapterRef,
                );
                return updatedChaptersRef;
              },
            },
          });
        },
      }),
    [mutation],
  );
}

export const CHAPTER_MOVE = gql`
  mutation ChapterMove($chapterId: ID!, $courseId: ID!) {
    chapterMove(chapterId: $chapterId, courseId: $courseId) {
      ...ChapterData
    }
  }
  ${CHAPTER_DATA}
`;

export function useMutationChapterMove() {
  const [mutation] = useMutation(CHAPTER_MOVE, {
    refetchQueries: [QUERY_COURSE_CONTENT],
  });
  return useCallback(
    (chapter, variables) =>
      mutation({
        variables: {
          ...variables,
          chapterId: chapter.id,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          chapterMove: {
            ...chapter,
            courseId: variables.courseId,
            order: 999999,
            __typename: 'Chapter',
          },
        },
        update(cache, { data: { chapterMove } }) {
          let chapterRef = null;
          let questionIds = [];
          cache.modify({
            id: `Course:${chapter.courseId}`,
            fields: {
              chapters: (existingChaptersRef = [], { readField }) => {
                existingChaptersRef.forEach((c) => {
                  const rId = readField('id', c);
                  const rOrder = readField('order', c);
                  if (rId === chapter.id) {
                    chapterRef = c;
                    questionIds = readField('questions', c).map((q) =>
                      readField('id', q),
                    );
                  } else if (rOrder >= chapter.order) {
                    cache.writeFragment({
                      id: `Chapter:${rId}`,
                      fragment: gql`
                        fragment ChapterUpdateOrder on Chapter {
                          order
                        }
                      `,
                      data: {
                        order: rOrder - 1,
                      },
                    });
                  }
                });
                const updatedChaptersRef = existingChaptersRef.filter(
                  (c) => readField('id', c) !== chapter.id,
                );
                return updatedChaptersRef;
              },
            },
          });
          cache.modify({
            id: `Course:${chapterMove.courseId}`,
            fields: {
              chapters: (existingChaptersRef = []) => [
                ...existingChaptersRef,
                chapterRef,
              ],
            },
          });
          questionIds.forEach((questionId) => {
            cache.writeFragment({
              id: `Question:${questionId}`,
              fragment: gql`
                fragment QuestionUpdateCourse on Question {
                  courseId
                }
              `,
              data: {
                courseId: chapterMove.courseId,
              },
            });
          });
        },
      }),
    [mutation],
  );
}

export const CHAPTER_SORT = gql`
  mutation ChapterSort($chapterId: ID!, $to: Int!) {
    chapterSort(chapterId: $chapterId, to: $to) {
      ...ChapterData
    }
  }
  ${CHAPTER_DATA}
`;

export function useMutationChapterSort() {
  const [mutation] = useMutation(CHAPTER_SORT, {
    refetchQueries: [QUERY_COURSE_CONTENT],
  });
  return useCallback(
    (chapter, variables) =>
      mutation({
        variables: {
          ...variables,
          chapterId: chapter.id,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          chapterSort: {
            ...chapter,
            order: variables.to,
            __typename: 'Chapter',
          },
        },
        update(cache, { data: { chapterSort } }) {
          cache.modify({
            id: `Course:${chapterSort.courseId}`,
            fields: {
              chapters: (existingChaptersRef = [], { readField }) => {
                existingChaptersRef.forEach((c) => {
                  const rId = readField('id', c);
                  const rOrder = readField('order', c);
                  if (
                    rId !== chapterSort.id &&
                    chapterSort.order <= rOrder &&
                    rOrder < chapter.order
                  ) {
                    cache.writeFragment({
                      id: `Chapter:${rId}`,
                      fragment: gql`
                        fragment ChapterUpdateOrder on Chapter {
                          order
                        }
                      `,
                      data: {
                        order: rOrder + 1,
                      },
                    });
                  } else if (
                    rId !== chapterSort.id &&
                    chapter.order < rOrder &&
                    rOrder <= chapterSort.order
                  ) {
                    cache.writeFragment({
                      id: `Chapter:${rId}`,
                      fragment: gql`
                        fragment ChapterUpdateOrder on Chapter {
                          order
                        }
                      `,
                      data: {
                        order: rOrder - 1,
                      },
                    });
                  }
                });
                const updatedChaptersRef = [...existingChaptersRef];
                updatedChaptersRef.splice(
                  chapterSort.order,
                  0,
                  updatedChaptersRef.splice(chapter.order, 1)[0],
                );
                return updatedChaptersRef;
              },
            },
          });
        },
      }),
    [mutation],
  );
}

export const CHAPTER_UPDATE = gql`
  mutation ChapterUpdate(
    $chapterId: ID!
    $title: String
    $onlineTimestamp: Date
    $offlineTimestamp: Date
    $isLinked: Boolean
  ) {
    chapterUpdate(
      chapterId: $chapterId
      title: $title
      onlineTimestamp: $onlineTimestamp
      offlineTimestamp: $offlineTimestamp
      isLinked: $isLinked
    ) {
      ...ChapterData
    }
  }
  ${CHAPTER_DATA}
`;

export function useMutationChapterUpdate() {
  const [mutation] = useMutation(CHAPTER_UPDATE);
  return useCallback(
    (chapter, variables) =>
      mutation({
        variables: {
          ...variables,
          chapterId: chapter.id,
          onlineTimestamp: variables.isOffline
            ? new Date('2001-01-01 00:00:00+00')
            : variables.chooseDate
            ? variables.onlineTimestamp
            : null,
          offlineTimestamp: variables.isOffline
            ? new Date('2001-01-02 00:00:00+00')
            : variables.chooseDate
            ? variables.offlineTimestamp
            : null,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          chapterUpdate: {
            ...chapter,
            ...variables,
            __typename: 'Chapter',
          },
        },
      }),
    [mutation],
  );
}
