import hotkeys from 'hotkeys-js';
import { useCallback, useEffect } from 'react';

const useHotkeys = (keys, callback) => {
  const memoisedCallback = useCallback(
    (evt) => {
      evt.preventDefault();
      evt.stopPropagation();
      if (callback) {
        callback(evt);
      }
      return false;
    },
    [callback],
  );
  useEffect(() => {
    hotkeys(keys, memoisedCallback);
    return () => {
      hotkeys.unbind(keys);
    };
  }, [keys, memoisedCallback]);
};

export default useHotkeys;
