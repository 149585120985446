import React, { useContext } from 'react';

import Context from 'lib/context';

import Recto from './Recto';

const RectoContainer = (props) => {
  const { theme } = useContext(Context);
  return <Recto theme={theme} {...props} />;
};

RectoContainer.defaultAnswer = () => undefined;

RectoContainer.validationSchema = () => undefined;

export default RectoContainer;
