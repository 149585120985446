import classNames from 'classnames/bind';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import EDITOR_STATUSES from 'constants/editorStatuses';
import {
  mutationEditorQuestionPreview,
  mutationEditorQuestionUpdate,
  mutationEditorQuestionVerify,
} from 'gql/mutations/editor';
import { useQueryLocalApp, useQueryLocalEditor } from 'gql/queries/local';
import useHotkeys from 'hooks/useHotkeys';
import Button from 'uikit/Button';

import styles from './Navigation.module.scss';

const cx = classNames.bind(styles);

const Navigation = ({
  nextQuestion = null,
  onExit,
  previousQuestion = null,
}) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Shared/QuestionEdit/Navigation',
  });

  const {
    data: {
      app: { isMenuExpanded },
    },
  } = useQueryLocalApp();

  const {
    data: {
      editor: { status: editorStatus, questionType },
    },
  } = useQueryLocalEditor();

  const { dirty, submitForm, validateForm } = useFormikContext();

  useHotkeys('up', () => {
    if (previousQuestion) handleQuestionChange(previousQuestion);
  });

  useHotkeys('down', () => {
    if (nextQuestion) handleQuestionChange(nextQuestion);
  });

  const isApprovingQuestion = editorStatus === EDITOR_STATUSES.QUESTION_APPROVE;

  const isPreviewingQuestion =
    editorStatus === EDITOR_STATUSES.QUESTION_PREVIEW;

  const canNavigate =
    (editorStatus !== EDITOR_STATUSES.QUESTION_PROPOSE && !!questionType) ||
    isPreviewingQuestion;

  function handleQuestionChange(question) {
    if (isPreviewingQuestion) {
      mutationEditorQuestionPreview(question);
    } else {
      handleQuestionChangeInEdition(question);
    }
  }

  async function handleQuestionChangeInEdition(question) {
    const errors = await validateForm();
    const isNoError = Object.keys(errors).length === 0;
    if (isApprovingQuestion) {
      mutationEditorQuestionVerify(question);
    } else {
      await submitForm();
      if (isNoError || !dirty) {
        mutationEditorQuestionUpdate(question);
      }
    }
  }

  return (
    <div className={cx('container', { 'menu-expanded': isMenuExpanded })}>
      <Button
        className={cx('back-button', { 'menu-expanded': isMenuExpanded })}
        icon="arrow-left"
        onClick={onExit}
        tooltip={t('back')}
      />
      {canNavigate && (
        <>
          <Button
            icon="chevron-up"
            isDisabled={!previousQuestion}
            onClick={() => handleQuestionChange(previousQuestion)}
            tooltip={t('previous-question')}
          />
          <Button
            icon="chevron-down"
            isDisabled={!nextQuestion}
            onClick={() => handleQuestionChange(nextQuestion)}
            tooltip={t('next-question')}
          />
        </>
      )}
    </div>
  );
};

Navigation.propTypes = {
  nextQuestion: PropTypes.object,
  onExit: PropTypes.func.isRequired,
  previousQuestion: PropTypes.object,
};

export default Navigation;
