import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Feedback from 'questions/Shared/Feedback';
import FormattedText from 'uikit/FormattedText';

import styles from './Verso.module.scss';

const cx = classNames.bind(styles);

const Verso = ({ className, question, t, theme }) => (
  <div
    className={cx(
      'container',
      className,
      `theme-${theme}`,
    )}
  >
    <FormattedText className={cx('title')}>{question.title}</FormattedText>
    <div className={cx('answer')}>
      {question.result !== 'correct' && (
        <>
          <p className={cx('label')}>
            {t('Questions/GuessNumber/Verso/wrong-answer')}
          </p>
          <div className={cx('wrong')}>{question.solution.user || '...'}</div>
        </>
      )}
      <p className={cx('label')}>
        {t('Questions/GuessNumber/Verso/correct-answer')}
      </p>
      <div className={cx('correct')}>{question.solution.correct}</div>
      {question.solution.maxCorrect && question.solution.minCorrect && (
        <p className={cx('range')}>
          {t('Questions/GuessNumber/Verso/range-answer', {
            min: question.solution.minCorrect,
            max: question.solution.maxCorrect,
          })}
        </p>
      )}
    </div>
    {question.content.feedback && (
     <Feedback feedback={question.content.feedback} />
    )}
  </div>
);

Verso.propTypes = {
  className: PropTypes.string,
  question: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
};

Verso.defaultProps = {
  className: undefined,
};

export default Verso;
