import classNames from 'classnames/bind';
import * as dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { useTable, useSortBy, useFilters } from 'react-table';

import { TextFilter, RangeFilter } from 'components/Shared/Filters';
import { useQueryResultsStudyStudentStatistics } from 'gql/queries/results';
import HelpTooltip from 'uikit/HelpTooltip';
import Icon from 'uikit/Icon';

import styles from './Students.module.scss';

const cx = classNames.bind(styles);

const LIMIT = 20;

const Students = ({
  className = null,
  course,
  groupId = null,
  studentId = null,
  students,
  totalStudents,
  type,
}) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/Statistics/Students',
  });

  const count = groupId
    ? course.groups.find((group) => group.id === groupId).students.length
    : studentId || type === 'me'
    ? 1
    : totalStudents;

  const [isSectionOpen, SetIsSectionOpen] = useState(false);

  const [studentsData, setStudentsData] = useState(students);

  const [offset, setOffset] = useState(0);

  const [hasMore, setHasMore] = useState(count > LIMIT);

  const columns = useMemo(
    () => [
      {
        Header: t('name'),
        accessor: 'name',
        Filter: TextFilter,
        filter: 'text',
      },
      {
        Header: t('success'),
        accessor: 'success',
        Cell: ({ value }) => `${value}%`,
        Filter: RangeFilter,
        filter: 'between',
      },
      {
        Header: t('progression'),
        accessor: 'progression',
        Cell: ({ value }) => `${value}%`,
        Filter: RangeFilter,
        filter: 'between',
      },
      {
        Header: t('study-sessions'),
        accessor: 'nStudySessions',
        disableFilters: true,
      },
      {
        Header: t('average-study-session'),
        accessor: 'averageStudySessionMinutes',
        Cell: ({ value }) => `${value} min.`,
        disableFilters: true,
      },
      {
        Header: t('last-study-sessions'),
        accessor: 'lastStudySession',
        Cell: ({ value }) => (value ? dayjs(value).format('L') : ''),
        disableFilters: true,
      },
    ],
    [t],
  );

  const filterTypes = {
    text: (rows, id, filterValue) =>
      rows.filter((row) => {
        const rowValue = row.values[id];
        return rowValue !== undefined
          ? String(rowValue)
              .toLowerCase()
              .includes(String(filterValue).toLowerCase())
          : true;
      }),
  };

  const table = useTable(
    {
      columns,
      data: studentsData,
      filterTypes,
    },
    useFilters,
    useSortBy,
  );

  const { fetchMore } = useQueryResultsStudyStudentStatistics({
    variables: {
      offset,
      limit: LIMIT,
      courseId: course.id,
      groupId,
      studentId,
    },
  });

  useEffect(() => {
    setStudentsData(students);
  }, [students]);

  const handleLoadMore = async () => {
    try {
      setHasMore(false);
      const {
        data: { resultsStudyStudentStatistics },
      } = await fetchMore({
        variables: {
          offset: offset + LIMIT,
          limit: LIMIT,
        },
      });
      setStudentsData([
        ...studentsData,
        ...resultsStudyStudentStatistics.students,
      ]);
      setOffset((o) => o + LIMIT);
      setHasMore(count > offset + LIMIT);
    } catch (err) {
      reportError('statistics students', 'load more', err);
      setHasMore(false);
    }
  };

  const handleSectionToggle = () => {
    SetIsSectionOpen(!isSectionOpen);
  };

  return (
    <div className={cx('container', className, { isSectionOpen })}>
      <button
        className={cx('header')}
        onClick={handleSectionToggle}
        type="button"
      >
        <div className={cx('title-container')}>
          <Icon className={cx('title-icon')} name="users" />
          <h2 className={cx('title')}>
            {t('title')}
            <span className={cx('count')}>{count}</span>
          </h2>
        </div>
        <Icon
          className={cx('toggle-button')}
          name={isSectionOpen ? 'chevron-up' : 'chevron-down'}
        />
      </button>
      {isSectionOpen && (
        <div className={cx('content')}>
          <InfiniteScroll
            pageStart={0}
            loadMore={handleLoadMore}
            hasMore={hasMore}
            useWindow={false}
          >
            <table className={cx('table')} {...table.getTableProps()}>
              <thead>
                {table.headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        <div
                          className={cx('header')}
                          {...column.getSortByToggleProps()}
                        >
                          {column.render('Header')}
                          {column.id === 'success' && (
                            <HelpTooltip
                              className={cx('tooltip')}
                              tooltip={t('success-tooltip')}
                            />
                          )}
                          {column.id === 'progression' && (
                            <HelpTooltip
                              className={cx('tooltip')}
                              tooltip={t('progression-tooltip')}
                            />
                          )}
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...table.getTableBodyProps()}>
                {table.rows.map((row) => {
                  table.prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td className={cx('data')} {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </InfiniteScroll>
        </div>
      )}
    </div>
  );
};

Students.propTypes = {
  className: PropTypes.string,
  course: PropTypes.object.isRequired,
  groupId: PropTypes.string,
  studentId: PropTypes.string,
  students: PropTypes.arrayOf(PropTypes.object).isRequired,
  totalStudents: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
};

export default Students;
