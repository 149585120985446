import { useField } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  getNormalizedPinPosition,
  getEventCursorPosition,
  getDimensions,
} from 'questions/Shared/utils/images';
import ImageContainer from './ImageContainer';

const ImageContainerContainer = (props) => {
  const wrapperRef = useRef(null);
  const imageRef = useRef(null);
  const [image, setImage] = useState({
    width: null,
    height: null,
    diagonal: null,
  });
  const handleImageLoaded = useCallback(() => {
    const [width, height, diagonal] = getDimensions(wrapperRef, imageRef);
    setImage({
      width,
      height,
      diagonal,
    });
  }, [imageRef, wrapperRef]);
  const handleScreenResize = useCallback(() => {
    if (wrapperRef && imageRef) {
      handleImageLoaded();
    }
  }, [handleImageLoaded]);
  useEffect(() => {
    window.addEventListener('resize', handleScreenResize);
    return () => {
      window.removeEventListener('resize', handleScreenResize);
    };
  }, [handleScreenResize]);
  const [{ value: answer }, , { setValue: setAnswer }] = useField('answer');
  const handlePinAdd = useCallback(
    (event) => {
      const [clientX, clientY] = getEventCursorPosition(event);
      const { left: offsetLeft, top: offsetTop } =
        wrapperRef.current.getBoundingClientRect();
      const normalizedPinPosition = getNormalizedPinPosition(
        {
          top: clientY - offsetTop,
          left: clientX - offsetLeft,
        },
        image.diagonal,
      );
      setAnswer({
        x: normalizedPinPosition.x,
        y: normalizedPinPosition.y,
      });
    },
    [image, setAnswer, wrapperRef],
  );
  const handlePinDelete = useCallback(() => {
    setAnswer({
      x: -1,
      y: -1,
    });
  }, [setAnswer]);
  return (
    <ImageContainer
      answer={answer}
      handleImageLoaded={handleImageLoaded}
      handlePinAdd={handlePinAdd}
      handlePinDelete={handlePinDelete}
      image={image}
      imageRef={imageRef}
      wrapperRef={wrapperRef}
      {...props}
    />
  );
};

export default ImageContainerContainer;
