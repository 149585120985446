import { useField } from 'formik';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import storeJS from 'store';
import * as yup from 'yup';

import Editor from './Editor';

const EditorContainer = (props) => {
  const { t } = useTranslation();
  const [, , { setValue: setTitleLang }] = useField('titleLang');
  const [, , { setValue: setFeedbackLang }] = useField('feedbackLang');
  const [{ value: useSpeech }, , { setValue: setUseSpeech }] =
    useField('useSpeech');
  const handleTitleLangChange = useCallback(
    ({ target: { value: newLang } }) => {
      setTitleLang(newLang);
      storeJS.set('titleLang', newLang);
    },
    [setTitleLang],
  );
  const handleFeedbackLangChange = useCallback(
    ({ target: { value: newLang } }) => {
      setFeedbackLang(newLang);
      storeJS.set('feedbackLang', newLang);
    },
    [setFeedbackLang],
  );
  const handleUseSpeechChange = useCallback(() => {
    setUseSpeech(!useSpeech);
    storeJS.set('useSpeech', !useSpeech);
  }, [setUseSpeech, useSpeech]);
  return (
    <Editor
      t={t}
      handleFeedbackLangChange={handleFeedbackLangChange}
      handleTitleLangChange={handleTitleLangChange}
      handleUseSpeechChange={handleUseSpeechChange}
      useSpeech={useSpeech}
      {...props}
    />
  );
};
EditorContainer.validationSchema = (t) =>
  yup.object().shape({
    tags: yup.array().of(yup.string().trim().lowercase().max(256)).compact(),
    title: yup
      .string()
      .trim()
      .required(t('Questions/Flashcard/Editor/recto-error-required')),
    feedback: yup
      .string()
      .trim()
      .required(t('Questions/Flashcard/Editor/verso-error-required')),
    titleLang: yup.string().trim().default('fr'),
    feedbackLang: yup.string().trim().default('fr'),
    useSpeech: yup.bool().default(true),
    randomizeFlashcard: yup.boolean().default(false),
  });

export default EditorContainer;
