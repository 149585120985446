import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import EDITOR_STATUSES from 'constants/editorStatuses';
import { mutationEditorClear } from 'gql/mutations/editor';
import { useMutationFolderUpdate } from 'gql/mutations/folder';
import { useQueryLocalEditor } from 'gql/queries/local';
import extract from 'helpers/extract';
import { trackEvent, reportError } from 'lib/tracking';

import FolderEdit from './FolderEdit';

const FolderEditContainer = (props) => {
  const { t } = useTranslation();
  const {
    data: {
      editor: { status: editorStatus, folderEditing },
    },
  } = useQueryLocalEditor();
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        title: yup
          .string()
          .trim()
          .required(t('Dashboard/FolderEdit/title-error-required'))
          .max(256, t('Dashboard/FolderEdit/title-error-required')),
      }),
    [t],
  );
  const mutationFolderUpdate = useMutationFolderUpdate();
  const handleSave = useCallback(
    async (values, form) => {
      try {
        form.setStatus(null);
        const variables = extract(values, validationSchema);
        await mutationFolderUpdate(folderEditing, variables);
        mutationEditorClear();
        trackEvent('folder', 'update');
      } catch (err) {
        form.setStatus('500');
        reportError('folder', 'update', err);
      }
    },
    [folderEditing, mutationFolderUpdate, validationSchema],
  );
  const initialValues = useMemo(
    () =>
      folderEditing
        ? {
            title: '',
            ...folderEditing,
          }
        : {},
    [folderEditing],
  );
  const isModalOpen = editorStatus === EDITOR_STATUSES.FOLDER_UPDATE;
  return (
    <FolderEdit
      handleClose={mutationEditorClear}
      handleSave={handleSave}
      initialValues={initialValues}
      isModalOpen={isModalOpen}
      t={t}
      validationSchema={validationSchema}
      {...props}
    />
  );
};

export default FolderEditContainer;
