import t from 'helpers/t';

const DESTINATIONS = [
  {
    key: 'lower-education',
    translation: t('Global/destination/lower-education'),
  },
  {
    key: 'higher-education',
    translation: t('Global/destination/higher-education'),
  },
  {
    key: 'professional-training',
    translation: t('Global/destination/professional-training'),
  },
  {
    key: 'other',
    translation: t('Global/destination/other'),
  },
];

export default DESTINATIONS;
