import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './Zone.module.scss';

const cx = classNames.bind(styles);

const Zone = ({ image, imageSrc, sizeData }) => (
  <div
    className={cx('zone')}
    style={{
      width: `${sizeData.width}px`,
      height: `${sizeData.height}px`,
      left: `${sizeData.left}px`,
      top: `${sizeData.top}px`,
    }}
  >
    <div
      style={{
        width: `${sizeData.width}px`,
        height: `${sizeData.height}px`,
        backgroundImage: `url(${imageSrc})`,
        backgroundSize: `${image.width}px ${image.height}px`,
        backgroundPosition: `-${sizeData.left}px -${sizeData.top}px`,
        filter: `blur(20px)`,
      }}
    />
  </div>
);

Zone.propTypes = {
  image: PropTypes.object.isRequired,
  imageSrc: PropTypes.string.isRequired,
  sizeData: PropTypes.object.isRequired,
};

export default Zone;
