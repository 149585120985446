import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { useQueryLocalStudy } from 'gql/queries/local';
import {
  mutationStudyTogglePartialForceCorrect,
  useMutationStudyQuestionPartialForceCorrect,
} from 'gql/mutations/study';
import extract from 'helpers/extract';
import { trackEvent, reportError } from 'lib/tracking';

import PartialForceCorrect from './PartialForceCorrect';

const PartialForceCorrectContainer = ({ question, ...props }) => {
  const { t } = useTranslation();

  const {
    data: {
      study: { isPartialForceCorrect },
    },
  } = useQueryLocalStudy();

  const answers = question.solution.filter((item) => !item.isCorrect);

  const mutationStudyQuestionPartialForceCorrect =
    useMutationStudyQuestionPartialForceCorrect();

  const validationSchema = yup.object().shape({
    forcedAnswers: yup
      .array()
      .of(
        yup.object().shape({
          correct: yup.array(),
          exact: yup.string().nullable(),
          isCorrect: yup.boolean(),
          user: yup.string().nullable(),
        }),
      )
      .required()
      .test(
        'length',
        t('Questions/LabelOnImage/Verso/PartialForceCorrect/error-no-answer'),
        (forcedAnswers) => forcedAnswers.length >= 1,
      ),
  });

  const handlePartialForceCorrect = async (values) => {
    try {
      const { forcedAnswers } = extract(values, validationSchema);
      await mutationStudyQuestionPartialForceCorrect(forcedAnswers);
      mutationStudyTogglePartialForceCorrect();
      trackEvent('study', 'answer-partial-force-correct');
    } catch (err) {
      reportError('study', 'answer-partial-force-correct', err);
    }
  };

  const handleAnswerSelect = ({
    answer,
    values,
    setFieldValue,
    isSelected,
  }) => {
    if (isSelected) {
      const newAnswers = values.forcedAnswers.filter(
        (forcedAnswer) =>
          JSON.stringify(forcedAnswer) !== JSON.stringify(answer),
      );
      setFieldValue('forcedAnswers', newAnswers);
    } else {
      setFieldValue('forcedAnswers', [...values.forcedAnswers, answer]);
    }
  };

  return (
    <PartialForceCorrect
      {...props}
      answers={answers}
      handleAnswerSelect={handleAnswerSelect}
      handleModalClose={mutationStudyTogglePartialForceCorrect}
      handlePartialForceCorrect={handlePartialForceCorrect}
      isModalOpen={isPartialForceCorrect}
      t={t}
      validationSchema={validationSchema}
    />
  );
};

PartialForceCorrectContainer.propTypes = {
  question: PropTypes.object.isRequired,
};

export default PartialForceCorrectContainer;
