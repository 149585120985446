import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import CleanedText from 'uikit/CleanedText';
import Icon from 'uikit/Icon';

import styles from './PastCourse.module.scss';

const cx = classNames.bind(styles);

const PastCourse = ({ course, isSingle = false }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Shared/Agenda/PastCourse',
  });

  return (
    <div
      className={cx('container', `theme-${course.themeBackground}`, {
        tooltip: !isSingle,
      })}
    >
      {!isSingle && (
        <div className={cx('header')}>
          <div className={cx('course-icon')}>
            <Icon name="book" />
          </div>
          <div>
            <CleanedText className={cx('title')}>{course.title}</CleanedText>
          </div>
        </div>
      )}
      <div className={cx('duration-container', { tooltip: !isSingle })}>
        {isSingle && <Icon className={cx('duration-icon')} name="clock" />}
        <p className={cx('study-duration', { tooltip: !isSingle })}>
          <span>{` ${course.sessionDuration} min.`}</span>
          {t('duration-study')}
        </p>
      </div>
      <p className={cx('stat', 'seen')}>
        <span>{course.seen}</span>
        {t('stats-seen', {
          count: course.seen,
        })}
      </p>
      <p className={cx('stat', 'correct')}>
        <span>{course.correct}</span>
        {t('stats-correct', {
          count: course.correct,
        })}
      </p>
      <p className={cx('stat', 'wrong')}>
        <span>{course.wrong}</span>
        {t('stats-wrong', {
          count: course.wrong,
        })}
      </p>
      <p className={cx('stat', 'unknown')}>
        <span>{course.unknown}</span>
        {t('stats-unknown')}
      </p>
    </div>
  );
};

PastCourse.propTypes = {
  course: PropTypes.object.isRequired,
  isSingle: PropTypes.bool,
};

export default PastCourse;
