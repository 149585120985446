import classNames from 'classnames/bind';
import { ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './InputError.module.scss';

const cx = classNames.bind(styles);

const InputError = ({ className, name }) => (
  <ErrorMessage name={name}>
    {(msg) =>
      typeof msg === 'string' ? (
        <div
          className={cx('container', className)}
          id={`error-${name}`}
          role="alert"
        >
          {msg}
        </div>
      ) : null
    }
  </ErrorMessage>
);

InputError.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
};

InputError.defaultProps = {
  className: undefined,
};

export default InputError;
