import Tippy from '@tippyjs/react';
import classNames from 'classnames/bind';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import Context from 'lib/context';

import getPlugins from './plugins';
import styles from './Select.module.scss';

const cx = classNames.bind(styles);

const Select = ({ choices, isDisabled, selectIndex }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Questions/FillInTheBlanks/Recto',
  });

  const { theme } = useContext(Context);

  const [{ value: answer }, , { setValue: setAnswer }] = useField('answer');

  const plugins = getPlugins(selectIndex);

  const handleChoiceSelect = (choice, selectIndex) => {
    const blanks = document.getElementsByClassName('field');
    const currentBlank = blanks[selectIndex];
    const nextBlank = blanks[selectIndex + 1];
    const isNextBlankInput = nextBlank?.children[0];
    const updatedAnswer = answer.map((blankAnswer, blankIndex) =>
      selectIndex === blankIndex ? choice.trim() || ' ' : blankAnswer,
    );
    setAnswer(updatedAnswer);
    // focus if the next blank is an input
    if (isNextBlankInput) {
      nextBlank.children[0].focus();
    }
    // focus if the next blank is a select
    if (nextBlank) {
      nextBlank?.focus();
      // blur if no next blank
    } else {
      currentBlank?.blur();
    }
  };

  return (
    <Tippy
      content={
        <ul className={cx('choices', `theme-${theme}`)}>
          {choices?.map((choice, choiceIndex) => (
            <li>
              <button
                className={cx('choice', { disabled: isDisabled })}
                disabled={isDisabled}
                id={`choice-${selectIndex + 1}-${choiceIndex}`}
                key={`choice-key-${choiceIndex}`}
                onClick={() => handleChoiceSelect(choice, selectIndex)}
                type="button"
              >
                {choice.trim() || ' '}
              </button>
            </li>
          ))}
        </ul>
      }
      duration={0}
      interactive
      maxWidth="none"
      placement="bottom"
      plugins={plugins}
      trigger="click focus"
    >
      <button
        aria-label={t('input', {
          number: selectIndex + 1,
        })}
        autoFocus={selectIndex === 0}
        className={cx('select', 'field')}
        id={`blank-${selectIndex + 1}`}
        type="button"
      >
        {answer[selectIndex].trim() || ''}
      </button>
    </Tippy>
  );
};

Select.propTypes = {
  choices: PropTypes.arrayOf(PropTypes.object).isRequired,
  isDisabled: PropTypes.bool.isRequired,
  selectIndex: PropTypes.number.isRequired,
};

export default Select;
