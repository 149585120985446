import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import FormattedText from 'uikit/FormattedText';
import InputText from 'uikit/InputText';

import styles from './Expert.module.scss';

const cx = classNames.bind(styles);

const Expert = ({ answer, className, handleChoiceSelect, question, t }) => (
  <div className={cx('container', className)}>
    <FormattedText className={cx('title')}>{question.title}</FormattedText>
    <div className={cx('subtitle')}>
      <FormattedText>{question.content.subtitle}</FormattedText>
    </div>
    <div className={cx('instructions')}>
      {t('Questions/JCT/Expert/instructions')}
    </div>
    <div className={cx('experts')}>
      {[0, 1, 2, 3].map((index) => (
        <button
          aria-pressed={answer === index}
          className={cx('expert', {
            isActive: answer === index,
          })}
          key={index}
          onClick={() => handleChoiceSelect(index)}
          type="button"
        >
          {question.content.labels && question.content.labels[index]
            ? question.content.labels[index]
            : t(`Questions/JCT/experts-${index}-label`)}
        </button>
      ))}
    </div>
    <InputText
      className={cx('expert-feedback')}
      label={t('Questions/JCT/Expert/feedback-label')}
      name="feedback"
    />
  </div>
);

Expert.propTypes = {
  answer: PropTypes.number,
  className: PropTypes.string,
  handleChoiceSelect: PropTypes.func.isRequired,
  question: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

Expert.defaultProps = {
  answer: undefined,
  className: undefined,
};

export default Expert;
