import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import Button from 'uikit/Button';

import styles from './MSTeamsNavigation.module.scss';

const cx = classNames.bind(styles);

const MSTeamsNavigation = ({ className, courseId, shouldDisplayBack }) => (
  <>
    {shouldDisplayBack && (
      <Button
        as={Link}
        className={cx('button', className)}
        icon="arrow-left"
        intent="white"
        to={`/course/${courseId}`}
      />
    )}
  </>
);

MSTeamsNavigation.propTypes = {
  className: PropTypes.string,
  courseId: PropTypes.func.isRequired,
  shouldDisplayBack: PropTypes.bool.isRequired,
};

MSTeamsNavigation.defaultProps = {
  className: undefined,
};

export default MSTeamsNavigation;
