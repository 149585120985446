import { gql, useQuery, useLazyQuery } from '@apollo/client';

import { STUDY_ANSWER_DATA } from 'gql/fragments';

export const QUERY_RESULTS_STUDY_STATISTICS = gql`
  query ($courseId: ID!) {
    resultsStudyStatistics(courseId: $courseId)
  }
`;

export function useQueryResultsStudyStatistics(options) {
  const [getResultsStudyStatistics, { loading, data, ...props }] = useLazyQuery(
    QUERY_RESULTS_STUDY_STATISTICS,
    options,
  );
  return {
    getResultsStudyStatistics,
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

export const QUERY_RESULTS_STUDY_STATISTICS_BY_GROUP = gql`
  query ($groupId: ID!) {
    resultsStudyStatisticsByGroup(groupId: $groupId)
  }
`;

export function useQueryResultsStudyStatisticsByGroup(options) {
  const [getResultsStudyStatisticsByGroup, { loading, data, ...props }] =
    useLazyQuery(QUERY_RESULTS_STUDY_STATISTICS_BY_GROUP, options);
  return {
    getResultsStudyStatisticsByGroup,
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

export const QUERY_RESULTS_STUDY_STATISTICS_BY_ME = gql`
  query ($courseId: ID!) {
    resultsStudyStatisticsByMe(courseId: $courseId)
  }
`;

export function useQueryResultsStudyStatisticsByMe(options) {
  const [getResultsStudyStatisticsByMe, { loading, data, ...props }] =
    useLazyQuery(QUERY_RESULTS_STUDY_STATISTICS_BY_ME, options);
  return {
    getResultsStudyStatisticsByMe,
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

export const QUERY_RESULTS_STUDY_STATISTICS_BY_STUDENT = gql`
  query ($studentId: ID!) {
    resultsStudyStatisticsByStudent(studentId: $studentId)
  }
`;

export function useQueryResultsStudyStatisticsByStudent(options) {
  const [getResultsStudyStatisticsByStudent, { loading, data, ...props }] =
    useLazyQuery(QUERY_RESULTS_STUDY_STATISTICS_BY_STUDENT, options);
  return {
    getResultsStudyStatisticsByStudent,
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

export const QUERY_RESULTS_STUDY_STATISTICS_FOR_AGENDA = gql`
  query ($courseId: ID, $sunday: Date!) {
    resultsStudyStatisticsForAgenda(courseId: $courseId, sunday: $sunday)
  }
`;

export function useQueryResultsStudyStatisticsForAgenda(options) {
  const [getResultsStudyStatisticsForAgenda, { loading, data, ...props }] =
    useLazyQuery(QUERY_RESULTS_STUDY_STATISTICS_FOR_AGENDA, options);
  return {
    getResultsStudyStatisticsForAgenda,
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

export const QUERY_RESULTS_AGGREGATED = gql`
  query ($courseId: ID!) {
    resultsAggregated(courseId: $courseId)
  }
`;

export function useQueryResultsAggregated(options) {
  const [getResultsAggregated, { loading, data, ...props }] = useLazyQuery(
    QUERY_RESULTS_AGGREGATED,
    options,
  );
  return {
    getResultsAggregated,
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

export const QUERY_RESULTS_AGGREGATED_BY_STUDENT = gql`
  query ($studentId: ID!) {
    resultsAggregatedByStudent(studentId: $studentId)
  }
`;

export function useQueryResultsAggregatedByStudent(options) {
  const [getResultsAggregatedByStudent, { loading, data, ...props }] =
    useLazyQuery(QUERY_RESULTS_AGGREGATED_BY_STUDENT, options);
  return {
    getResultsAggregatedByStudent,
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

export const QUERY_RESULTS_AGGREGATED_BY_GROUP = gql`
  query ($groupId: ID!) {
    resultsAggregatedByGroup(groupId: $groupId)
  }
`;

export function useQueryResultsAggregatedByGroup(options) {
  const [getResultsAggregatedByGroup, { loading, data, ...props }] =
    useLazyQuery(QUERY_RESULTS_AGGREGATED_BY_GROUP, options);
  return {
    getResultsAggregatedByGroup,
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

export const QUERY_RESULTS_AGGREGATED_BY_ME = gql`
  query ($courseId: ID!) {
    resultsAggregatedByMe(courseId: $courseId)
  }
`;

export function useQueryResultsAggregatedByMe(options) {
  const [getResultsAggregatedByMe, { loading, data, ...props }] = useLazyQuery(
    QUERY_RESULTS_AGGREGATED_BY_ME,
    options,
  );
  return {
    getResultsAggregatedByMe,
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

export const QUERY_RESULTS_AGGREGATED_BY_QUESTION = gql`
  query ($questionId: ID!) {
    resultsAggregatedByQuestion(questionId: $questionId)
  }
`;

export function useQueryResultsAggregatedByQuestion(options) {
  const { loading, data, ...props } = useQuery(
    QUERY_RESULTS_AGGREGATED_BY_QUESTION,
    options,
  );
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

export const QUERY_RESULTS_STUDY_WRONG_ANSWERS = gql`
  query ($limit: Int, $offset: Int, $questionId: ID!) {
    resultsStudyWrongAnswers(
      limit: $limit
      offset: $offset
      questionId: $questionId
    ) {
      ...StudyAnswerData
    }
  }
  ${STUDY_ANSWER_DATA}
`;

export function useQueryResultsStudyWrongAnswers(options) {
  const { loading, data, ...props } = useQuery(
    QUERY_RESULTS_STUDY_WRONG_ANSWERS,
    options,
  );
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

export const QUERY_RESULTS_STUDY_CLASSIFICATION_MATRIX_STATISTICS = gql`
  query (
    $limit: Int
    $offset: Int
    $courseId: ID!
    $groupId: ID
    $studentId: ID
  ) {
    resultsStudyClassificationMatrixStatistics(
      limit: $limit
      offset: $offset
      courseId: $courseId
      groupId: $groupId
      studentId: $studentId
    )
  }
`;

export function useQueryResultsStudyClassificationMatrixStatistics(options) {
  const [
    getResultsStudyClassificationMatrixStatistics,
    { loading, data, ...props },
  ] = useLazyQuery(QUERY_RESULTS_STUDY_CLASSIFICATION_MATRIX_STATISTICS, options);
  return {
    getResultsStudyClassificationMatrixStatistics,
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

export const QUERY_RESULTS_STUDY_QUESTION_STATISTICS = gql`
  query ($limit: Int, $offset: Int, $courseId: ID!, $groupId: ID, $studentId: ID) {
    resultsStudyQuestionStatistics(
      limit: $limit
      offset: $offset
      courseId: $courseId
      groupId: $groupId
      studentId: $studentId
    )
  }
`;

export function useQueryResultsStudyQuestionStatistics(options) {
  const { loading, data, ...props } = useQuery(
    QUERY_RESULTS_STUDY_QUESTION_STATISTICS,
    options,
  );
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}


export const QUERY_RESULTS_STUDY_STUDENT_STATISTICS = gql`
  query ($limit: Int, $offset: Int, $courseId: ID!, $groupId: ID, $studentId: ID) {
    resultsStudyStudentStatistics(
      limit: $limit
      offset: $offset
      courseId: $courseId
      groupId: $groupId
      studentId: $studentId
    )
  }
`;

export function useQueryResultsStudyStudentStatistics(options) {
  const { loading, data, ...props } = useQuery(
    QUERY_RESULTS_STUDY_STUDENT_STATISTICS,
    options,
  );
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}
