import { HEIGHT, WIDTH } from '../data/constant';

const snapToGrid = (options) => {
  const gridWidth = WIDTH / 4;
  const gridHeight = HEIGHT / 4;
  if (
    Math.round(
      ((options.target.left + options.target.width / 2) / gridWidth) * 4,
    ) %
      4 ===
      0 &&
    Math.round(
      ((options.target.top + options.target.height / 2) / gridHeight) * 4,
    ) %
      4 ===
      0
  ) {
    options.target
      .set({
        left:
          Math.round(
            (options.target.left + options.target.width / 2) / gridWidth,
          ) *
            gridWidth -
          options.target.width / 2,
        top:
          Math.round(
            (options.target.top + options.target.height / 2) / gridHeight,
          ) *
            gridHeight -
          options.target.height / 2,
      })
      .setCoords();
  }
};

export default snapToGrid;
