/* eslint-disable no-underscore-dangle */
import { fabric } from 'fabric';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import storeJS from 'store';

import useModal from 'hooks/useModal';

import COLORS from '../data/colors';
import DEFAULT_CANVAS_PROPERTIES from '../data/defaultCanvasProperties';
import resizeImage from '../helpers/resizeImage';
import SecondaryToolbar from './SecondaryToolbar';
import { saveHistory } from '../config/history';

const SecondaryToolbarContainer = ({ fabricRef, selectedObject, ...props }) => {
  const { t } = useTranslation();
  const { isModalOpen, handleModalClose, handleModalOpen } = useModal(false);
  const getColorName = useCallback(
    (hex) => COLORS.find((color) => color.hex === hex)?.name,
    [],
  );
  const getColorHex = useCallback(
    (name) => COLORS.find((color) => color.name === name)?.hex,
    [],
  );
  const [textColor, setTextColor] = useState('black');
  useEffect(() => {
    if (
      selectedObject &&
      (selectedObject.type === 'i-text' || selectedObject.type === 'group')
    ) {
      const color =
        getColorName(
          // if group or svg, get color of first character
          selectedObject.type === 'group'
            ? selectedObject._objects.filter(
                (object) => object.type !== 'circle',
              )[0].fill
            : // if selected text, get color of first selected character.
            selectedObject.getSelectionStyles().length &&
              selectedObject.getSelectionStyles()[0].fill
            ? selectedObject.getSelectionStyles()[0].fill
            : // if  color applied on characters,get color of first character.
            selectedObject.styles.length && selectedObject.styles[0][0]?.fill
            ? selectedObject.styles[0][0].fill
            : // else get global color of the object
              selectedObject.fill,
        ) || 'black';
      setTextColor(color);
    } else {
      setTextColor('black');
    }
  }, [getColorName, selectedObject]);
  const saveGlobalProperties = useCallback(
    (property, value) => {
      const hasSelectionLastCharacter =
        selectedObject.selectionEnd === selectedObject.text.length;
      if (hasSelectionLastCharacter) {
        selectedObject.set(property, value);
      }
    },
    [selectedObject],
  );
  const handleTextColorChange = useCallback(
    (selectedColor) => {
      if (selectedObject.selectionStart !== selectedObject.selectionEnd) {
        saveHistory(fabricRef);
        selectedObject.setSelectionStyles({ fill: getColorHex(selectedColor) });
        saveGlobalProperties('fill', getColorHex(selectedColor));
        setTextColor(selectedColor);
        fabricRef.current.requestRenderAll();
      } else {
        selectedObject.selectAll();
        handleTextColorChange(selectedColor);
      }
    },
    [getColorHex, saveGlobalProperties, selectedObject, fabricRef],
  );
  const handleTextWeight = useCallback(() => {
    if (selectedObject.selectionStart !== selectedObject.selectionEnd) {
      saveHistory(fabricRef);
      const fontWeight =
        selectedObject
          .getSelectionStyles()
          .every((letter) => letter.fontWeight && letter.fontWeight === 900) ||
        (selectedObject
          .getSelectionStyles()
          .every((letter) => !letter.fontWeight) &&
          selectedObject.fontWeight === 900)
          ? 400
          : 900;
      selectedObject.setSelectionStyles({ fontWeight });
      saveGlobalProperties('fontWeight', fontWeight);
      fabricRef.current.requestRenderAll();
    } else {
      selectedObject.selectAll();
      handleTextWeight();
    }
  }, [saveGlobalProperties, selectedObject, fabricRef]);
  const handleTextFontFamily = useCallback(
    (fontFamily) => {
      if (selectedObject.selectionStart !== selectedObject.selectionEnd) {
        saveHistory(fabricRef);
        selectedObject.setSelectionStyles({ fontFamily });
        saveGlobalProperties('fontFamily', fontFamily);
        fabricRef.current.requestRenderAll();
      } else {
        selectedObject.selectAll();
        handleTextFontFamily(fontFamily);
      }
    },
    [saveGlobalProperties, selectedObject, fabricRef],
  );
  const handleTextFontSize = useCallback(
    (fontSize) => {
      if (selectedObject.selectionStart !== selectedObject.selectionEnd) {
        saveHistory(fabricRef);
        selectedObject.setSelectionStyles({ fontSize });
        saveGlobalProperties('fontSize', fontSize);
        fabricRef.current.requestRenderAll();
      } else {
        selectedObject.selectAll();
        handleTextFontSize(fontSize);
      }
    },
    [saveGlobalProperties, selectedObject, fabricRef],
  );
  const handleTextStyle = useCallback(() => {
    if (selectedObject.selectionStart !== selectedObject.selectionEnd) {
      saveHistory(fabricRef);
      const fontStyle =
        selectedObject
          .getSelectionStyles()
          .every(
            (letter) => letter.fontStyle && letter.fontStyle === 'italic',
          ) ||
        (selectedObject
          .getSelectionStyles()
          .every((letter) => !letter.fontStyle) &&
          selectedObject.fontStyle === 'italic')
          ? 'normal'
          : 'italic';
      selectedObject.setSelectionStyles({ fontStyle });
      saveGlobalProperties('fontStyle', fontStyle);
      fabricRef.current.requestRenderAll();
    } else {
      selectedObject.selectAll();
      handleTextStyle();
    }
  }, [saveGlobalProperties, selectedObject, fabricRef]);
  const handleTextAlign = useCallback(
    (textAlign) => {
      saveHistory(fabricRef);
      selectedObject.set('textAlign', textAlign);
      fabricRef.current.requestRenderAll();
    },
    [selectedObject, fabricRef],
  );
  const currentBackground = getColorName(fabricRef?.current?.backgroundColor);
  const handleBackgroundChange = useCallback(
    (imageUrl) => {
      saveHistory(fabricRef);
      fabric.Image.fromURL(imageUrl, (img) => {
        resizeImage(
          img,
          DEFAULT_CANVAS_PROPERTIES.width,
          DEFAULT_CANVAS_PROPERTIES.height,
        );
        fabricRef.current.setBackgroundImage(
          img,
          fabricRef.current.renderAll.bind(fabricRef.current),
        );
        handleModalClose();
      });
    },
    [fabricRef, handleModalClose],
  );
  const handleBackgroundDelete = useCallback(() => {
    saveHistory(fabricRef);
    handleBackgroundChange('');
  }, [fabricRef, handleBackgroundChange]);
  const handleImageChange = useCallback(
    (imageUrl) => {
      const selectedObjectWidth = selectedObject.width;
      const selectedObjectHeight = selectedObject.height;
      selectedObject.setSrc(imageUrl, (img) => {
        resizeImage(img, selectedObjectWidth, selectedObjectHeight);
        fabricRef.current.renderAll();
      });
      handleModalClose();
    },
    [fabricRef, handleModalClose, selectedObject],
  );
  const handleBackgroundColorChange = useCallback(
    (selectedColorName) => {
      saveHistory(fabricRef);
      fabricRef.current.setBackgroundColor(
        getColorHex(selectedColorName),
        () => {
          fabricRef.current.renderAll();
          storeJS.set('slide', {
            ...storeJS.get('slide'),
            backgroundColor: getColorHex(selectedColorName),
          });
        },
      );
      handleModalClose();
    },
    [getColorHex, fabricRef, handleModalClose],
  );
  const handleSVGColorChange = useCallback(
    (selectedColorName) => {
      saveHistory(fabricRef);
      selectedObject._objects.forEach((character) => {
        if (character.type !== 'circle') {
          character.set({
            fill: getColorHex(selectedColorName),
          });
        }
      });
      fabricRef.current.renderAll();
      setTextColor(selectedColorName);
    },
    [fabricRef, getColorHex, selectedObject],
  );
  return (
    <SecondaryToolbar
      currentBackground={currentBackground}
      fabricRef={fabricRef}
      handleImageChange={handleImageChange}
      handleBackgroundChange={handleBackgroundChange}
      handleBackgroundColorChange={handleBackgroundColorChange}
      handleBackgroundDelete={handleBackgroundDelete}
      handleModalClose={handleModalClose}
      handleModalOpen={handleModalOpen}
      handleSVGColorChange={handleSVGColorChange}
      handleTextAlign={handleTextAlign}
      handleTextColorChange={handleTextColorChange}
      handleTextFontFamily={handleTextFontFamily}
      handleTextFontSize={handleTextFontSize}
      handleTextStyle={handleTextStyle}
      handleTextWeight={handleTextWeight}
      isModalOpen={isModalOpen}
      selectedObject={selectedObject}
      t={t}
      textColor={textColor}
      {...props}
    />
  );
};

SecondaryToolbarContainer.propTypes = {
  fabricRef: PropTypes.object,
  selectedObject: PropTypes.object,
};

SecondaryToolbarContainer.defaultProps = {
  fabricRef: undefined,
  selectedObject: undefined,
};

export default SecondaryToolbarContainer;
