import classNames from 'classnames/bind';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import EDITOR_STATUSES from 'constants/editorStatuses';
import { useQueryLocalEditor } from 'gql/queries/local';
import { getQuestionInfo } from 'questions';
import Button from 'uikit/Button';
import Icon from 'uikit/Icon';

import TypeSelector from './TypeSelector/TypeSelector';
import styles from './QuestionEditorHeader.module.scss';

const cx = classNames.bind(styles);

const QuestionEditorHeader = ({ isQuestionSaved }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Shared/QuestionEdit/QuestionEditor/QuestionEditorHeader',
  });

  const {
    data: {
      editor: { status: editorStatus, questionType },
    },
  } = useQueryLocalEditor();

  const { handleSubmit, setFieldValue } = useFormikContext();

  const questionInfo = getQuestionInfo(questionType);

  const isApprovingQuestion = editorStatus === EDITOR_STATUSES.QUESTION_APPROVE;

  const isProposingQuestion = editorStatus === EDITOR_STATUSES.QUESTION_PROPOSE;

  const isNotImportingFromPDForPPT = questionInfo?.type !== 'PDFOrPPT';

  const canPreview =
    isNotImportingFromPDForPPT && !isApprovingQuestion && !isProposingQuestion;

  const handleQuestionPreview = () => {
    setFieldValue('wantPreview', true);
    handleSubmit();
  };

  return (
    <div className={cx('header')}>
      <TypeSelector />
      <div className={cx('header-right')}>
        <div className={cx('saved-container')}>
          <div
            className={cx('save-information', {
              isVisible: isQuestionSaved,
            })}
          >
            <Icon name="check" />
            <p className={cx('save-text')}>{t('saved')}</p>
          </div>
        </div>
        {canPreview && (
          <Button icon="eye" onClick={handleQuestionPreview} size="large">
            {t('preview-question')}
          </Button>
        )}
      </div>
    </div>
  );
};

QuestionEditorHeader.propTypes = {
  isQuestionSaved: PropTypes.bool.isRequired,
};

export default QuestionEditorHeader;
