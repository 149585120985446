const focusModalTitle = () => {
  const modal = document.querySelector('[aria-modal="true"]');
  const modalTitle = modal?.querySelector('h1');
  if (modalTitle) {
    modalTitle.tabIndex = -1;
    modalTitle.focus();
  }
};

export default focusModalTitle;
