import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { useMutationCourseJoinByCode } from 'gql/mutations/course';
import extract from 'helpers/extract';
import history from 'lib/history';
import { trackEvent, reportError } from 'lib/tracking';

import CourseJoin from './CourseJoin';

const CourseJoinContainer = ({ isModalOpen, handleModalClose }) => {
  const { t } = useTranslation();
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        accessCode: yup
          .string()
          .trim()
          .uppercase()
          .required(t('Shared/CourseJoin/access-code-error-required'))
          .max(256, t('Shared/CourseJoin/access-code-error-required')),
      }),
    [t],
  );
  const mutationCourseJoinByCode = useMutationCourseJoinByCode();
  const handleCourseJoin = useCallback(
    async (values, form) => {
      try {
        form.setStatus(null);
        const variables = extract(values, validationSchema);
        const {
          data: { courseJoinByCode },
        } = await mutationCourseJoinByCode(variables);
        handleModalClose();
        history.push(`/course/${courseJoinByCode.id}/`);
        trackEvent('course', 'join');
      } catch (err) {
        form.setStatus('500');
        reportError('course', 'join', err);
      }
    },
    [handleModalClose, mutationCourseJoinByCode, validationSchema],
  );
  return (
    <CourseJoin
      handleCourseJoin={handleCourseJoin}
      handleModalClose={handleModalClose}
      isModalOpen={isModalOpen}
      t={t}
      validationSchema={validationSchema}
    />
  );
};

CourseJoinContainer.propTypes = {
  handleModalClose: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
};

export default CourseJoinContainer;
