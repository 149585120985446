const FONTS = [
  'Anton',
  'Archivo',
  'Arial',
  'Arvo',
  'Brush Script MT',
  'Calibri',
  'Courier New',
  'Georgia',
  'Garamond',
  'Lato',
  'Lobster',
  'Nunito',
  'Open-sans',
  'Pacifico',
  'Poppins',
  'Prompt',
  'PT-Sans',
  'Raleway',
  'Roboto',
  'Sofia',
  'Tahoma',
  'Times New Roman',
  'Trebuchet MS',
  'Verdana',
];

export default FONTS;
