import Categorization from './Categorization';
import Dictation from './Dictation';
import Essay from './Essay';
import FillInTheBlanks from './FillInTheBlanks';
import FindOnImage from './FindOnImage';
import Flashcard from './Flashcard';
import GuessNumber from './GuessNumber';
import HomeScreen from './HomeScreen';
import Interview from './Interview';
import JCT from './JCT';
import LabelOnImage from './LabelOnImage';
import Matching from './Matching';
import Math_ from './Math';
import MCQ from './MCQ';
import PDFOrPPT from './PDFOrPPT';
import SCT from './SCT';
import ShortAnswer from './ShortAnswer';
import Slide from './Slide';
import Slide2 from './Slide2';
import Sorting from './Sorting';
import TrueOrFalse from './TrueOrFalse';
import Video from './Video';

const questions = {
  Flashcard,
  MCQ,
  ShortAnswer,
  FillInTheBlanks,
  Matching,
  Categorization,
  Sorting,
  FindOnImage,
  LabelOnImage,
  Math: Math_,
  Slide,
  Slide2,
  Essay,
  SCT,
  JCT,
  Dictation,
  Video,
  HomeScreen,
  GuessNumber,
  TrueOrFalse,
  PDFOrPPT,
  Interview,
};

export const getQuestionsInfo = () => ({
  all: Object.values(questions).map((question) => question.info),
  questions: [
    questions.MCQ.info,
    questions.Flashcard.info,
    questions.ShortAnswer.info,
    questions.LabelOnImage.info,
    questions.FillInTheBlanks.info,
    questions.Sorting.info,
    questions.Matching.info,
    questions.Categorization.info,
    questions.Essay.info,
    questions.FindOnImage.info,
    questions.Dictation.info,
    questions.GuessNumber.info,
    questions.TrueOrFalse.info,
    questions.Math.info,
    questions.SCT.info,
    questions.JCT.info,
    questions.Interview.info,
  ],
  informativeQuestions: [
    questions.HomeScreen.info,
    questions.Slide.info,
    questions.Slide2.info,
    questions.Video.info,
    questions.PDFOrPPT.info,
  ],
});

export const getQuestionInfo = (type) => questions[type]?.info;

/**
 * Get the question types that are accessible for play
 * @returns {Array} the question types
 */
export function getQuestionTypesForPlay() {
  return Object.values(questions)
    .filter((type) => type.info.play)
    .map((type) => type.info.type);
}

export const shouldBeInScore = (type) => questions[type]?.info.isInScore;

export const getQuestionEditor = (type) => questions[type]?.Editor;

export const getQuestionRecto = (type) => questions[type]?.Recto;

export const getQuestionVerso = (type) => questions[type]?.Verso;

export const getQuestionAggregate = (type) => questions[type]?.Aggregate;

export const getQuestionExpert = (type) => questions[type]?.Expert;
