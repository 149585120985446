import PropTypes from 'prop-types';
import React from 'react';

const CleanedText = ({ as, html, ...props }) =>
  React.createElement(as, {
    ...props,
    dangerouslySetInnerHTML: {
      __html: html,
    },
  });

CleanedText.propTypes = {
  as: PropTypes.string,
  html: PropTypes.string.isRequired,
};

CleanedText.defaultProps = {
  as: 'span',
};

export default CleanedText;
