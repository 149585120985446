import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Feedback from 'questions/Shared/Feedback';
import FormattedText from 'uikit/FormattedText';

import ImageContainer from '../Shared/ImageContainer';
import Answer from './Answer';
import PartialForceCorrect from './PartialForceCorrect';
import styles from './Verso.module.scss';

const cx = classNames.bind(styles);

const Verso = ({
  answers,
  className,
  isScrollable,
  question,
  theme,
}) => (
  <div className={cx('container', className, `theme-${theme}`)}>
    <FormattedText className={cx('title')}>{question.title}</FormattedText>
    <div className={cx('image-legends-container')}>
      <ImageContainer
        className={cx('image')}
        imageSrc={question.content.image}
        legends={question.content.legends}
        solution={answers}
        zones={question.content.zones}
      />
      <div className={cx('answers', { scroll: isScrollable })}>
        {answers.map((answer, index) => (
          <Answer answer={answer} index={index} isForcingCorrect={false} />
        ))}
      </div>
    </div>
    {question.content.feedback && (
      <Feedback feedback={question.content.feedback} />
    )}
    <PartialForceCorrect question={question} />
  </div>
);

Verso.propTypes = {
  answers: PropTypes.arrayOf(PropTypes.object).isRequired,
  className: PropTypes.string,
  isScrollable: PropTypes.bool.isRequired,
  question: PropTypes.object.isRequired,
  theme: PropTypes.string.isRequired,
};

Verso.defaultProps = {
  className: undefined,
};

export default Verso;
