import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import StudentCard from '../StudentCard';
import styles from './Students.module.scss';

const cx = classNames.bind(styles);

const Students = ({ className, course, students }) => (
  <div className={className}>
    {students.map((student, index) => (
      <StudentCard
        className={cx('student')}
        course={course}
        index={index}
        key={student.id}
        student={student}
      />
    ))}
  </div>
);

Students.propTypes = {
  className: PropTypes.string,
  course: PropTypes.object.isRequired,
  students: PropTypes.arrayOf(PropTypes.object).isRequired,
};

Students.defaultProps = {
  className: undefined,
};

export default Students;
