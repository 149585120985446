import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PERMISSIONS from 'constants/permissions';
import { mutationAppDashboardDisplayStyle } from 'gql/mutations/app';
import { useMutationCourseCreate } from 'gql/mutations/course';
import { useQueryCoursesMe } from 'gql/queries/course';
import { useQueryFoldersMe } from 'gql/queries/folder';
import { useQueryLocalApp } from 'gql/queries/local';
import { useQueryMe } from 'gql/queries/me';
import useModal from 'hooks/useModal';
import useOnError from 'hooks/useOnError';
import useSiteContext from 'hooks/useSiteContext';
import history from 'lib/history';
import { useMutationFolderCreate } from 'gql/mutations/folder';
import { mutationEditorFolderUpdate } from 'gql/mutations/editor';
import { useMutationMeTag } from 'gql/mutations/me';
import { trackEvent, reportError } from 'lib/tracking';

import Dashboard from './Dashboard';

const DashboardContainer = () => {
  const { t } = useTranslation();
  const { data: dataMe } = useQueryMe();
  const { isEditionDisabled, isGAR, isRoleStudent } = useSiteContext();
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState('all');
  const mutationMeTag = useMutationMeTag();
  const handleSearchChange = useCallback(
    async (evt) => {
      try {
        setSearch(evt.target.value);
        const isTutorialPopupDisplayed = !dataMe.me.tags.includes('tutorials');
        if (isTutorialPopupDisplayed) {
          await mutationMeTag(['tutorials']);
          trackEvent('tag', 'tutorials');
        }
      } catch (err) {
        reportError('tag', 'tutorials', err);
      }
    },
    [dataMe, mutationMeTag],
  );
  const {
    data: {
      app: { dashboardDisplayStyle },
    },
  } = useQueryLocalApp();
  const handleDisplayStyleChange = useCallback(() => {
    mutationAppDashboardDisplayStyle(
      dashboardDisplayStyle === 'list' ? 'grid' : 'list',
    );
  }, [dashboardDisplayStyle]);
  const { error, isLoading, data } = useQueryCoursesMe();
  const {
    error: folderError,
    isLoading: isLoadingFolders,
    data: dataFolders,
  } = useQueryFoldersMe();
  useOnError(error, () => {
    history.push('/error');
  });
  useOnError(folderError, () => {
    history.push('/error');
  });
  const courses = useMemo(
    () => (isLoading ? [] : data.coursesMe),
    [data, isLoading],
  );
  const folders = isLoadingFolders ? [] : dataFolders.foldersMe;
  const isEmpty = courses.length === 0 && !isGAR;
  const filteredCourses = useMemo(
    () =>
      courses
        .filter((course) =>
          filter === 'teacher'
            ? course.level >= PERMISSIONS.COLLABORATOR
            : filter === 'student'
            ? course.level === PERMISSIONS.STUDENT
            : true,
        )
        .filter(
          (course) =>
            !search ||
            course.title.toLowerCase().includes(search.trim().toLowerCase()) ||
            `${course.owner.firstName.toLowerCase()} ${course.owner.lastName.toLowerCase()}`.includes(
              search.trim().toLowerCase(),
            ),
        ),
    [courses, filter, search],
  );
  const pinnedCourses = useMemo(
    () => courses.filter((course) => course.isPinned),
    [courses],
  );
  const mutationFolderCreate = useMutationFolderCreate();
  const handleFolderCreate = useCallback(async () => {
    try {
      const {
        data: { folderCreate },
      } = await mutationFolderCreate({
        title: t('Dashboard/new-folder-title'),
      });
      mutationEditorFolderUpdate(folderCreate);
      trackEvent('folder', 'create');
    } catch (err) {
      reportError('folder', 'create', err);
    }
  }, [mutationFolderCreate, t]);
  const [isCourseCreating, setCourseCreating] = useState(false);
  const mutationCourseCreate = useMutationCourseCreate();
  const handleCourseCreate = useCallback(async () => {
    try {
      setCourseCreating(true);
      const {
        data: { courseCreate },
      } = await mutationCourseCreate({
        title: t('Dashboard/new-course-title'),
      });
      history.push(`/course/${courseCreate.id}/`);
      trackEvent('course', 'create');
    } catch (err) {
      reportError('course', 'create', err);
    } finally {
      setCourseCreating(false);
    }
  }, [mutationCourseCreate, t]);
  const {
    isModalOpen: isCourseJoinDisplayed,
    handleModalOpen: handleCourseJoinOpen,
    handleModalClose: handleCourseJoinClose,
  } = useModal();
  return (
    <Dashboard
      courses={filteredCourses}
      dashboardDisplayStyle={dashboardDisplayStyle}
      filter={filter}
      folders={folders}
      handleCourseCreate={handleCourseCreate}
      handleCourseJoinOpen={handleCourseJoinOpen}
      handleCourseJoinClose={handleCourseJoinClose}
      handleFolderCreate={handleFolderCreate}
      handleDisplayStyleChange={handleDisplayStyleChange}
      handleFilterChange={setFilter}
      handleSearchChange={handleSearchChange}
      isCourseCreating={isCourseCreating}
      isCourseJoinDisplayed={isCourseJoinDisplayed}
      isEditionDisabled={isEditionDisabled}
      isEmpty={isEmpty}
      isGAR={isGAR}
      isLoading={isLoading}
      isRoleStudent={isRoleStudent}
      me={dataMe?.me}
      pinnedCourses={pinnedCourses}
      search={search}
      t={t}
    />
  );
};

export default DashboardContainer;
