import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import Context from 'lib/context';

import Recto from './Recto';

const RectoContainer = ({ isDisabled, question, ...props }) => {
  const { t } = useTranslation();
  const { theme } = useContext(Context);
  const [{ value: answer }, , { setValue: setAnswer }] = useField('answer');
  const dndRef = useRef(null);
  const handleSortEnd = useCallback(
    async ({ source, destination }) => {
      if (!destination || source.index === destination.index) {
        return;
      }
      const fromIndex = source.index;
      const toIndex = destination.index;
      const newAnswer = [...answer];
      newAnswer.splice(toIndex, 0, newAnswer.splice(fromIndex, 1)[0]);
      setAnswer(newAnswer);
    },
    [answer, setAnswer],
  );
  const handleMoveUp = useCallback(
    async (index) => {
      if (!isDisabled && dndRef.current) {
        const drag = dndRef.current.tryGetLock(index.toString(), () => null);
        const ele = drag.snapLift();
        ele.moveUp();
        await new Promise((resolve) => setTimeout(resolve, 500));
        ele.drop();
      }
    },
    [isDisabled],
  );
  const handleMoveDown = useCallback(
    async (index) => {
      if (!isDisabled && dndRef.current) {
        const drag = dndRef.current.tryGetLock(index.toString(), () => null);
        const ele = drag.snapLift();
        ele.moveDown();
        await new Promise((resolve) => setTimeout(resolve, 500));
        ele.drop();
      }
    },
    [isDisabled],
  );
  return (
    <Recto
      answer={answer}
      dndRef={dndRef}
      handleMoveDown={handleMoveDown}
      handleMoveUp={handleMoveUp}
      handleSortEnd={handleSortEnd}
      isDisabled={isDisabled}
      question={question}
      t={t}
      theme={theme}
      {...props}
    />
  );
};

RectoContainer.propTypes = {
  isDisabled: PropTypes.bool,
  question: PropTypes.object.isRequired,
};

RectoContainer.defaultProps = {
  isDisabled: false,
};

RectoContainer.defaultAnswer = (question) =>
  question.content.items.map((item) => item.idx);

RectoContainer.validationSchema = () =>
  yup.object().shape({
    answer: yup.array().of(yup.number().required().integer().min(0)),
  });

export default RectoContainer;
