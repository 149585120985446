import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import CourseProgress from './CourseProgress';

const CourseProgressContainer = ({ course, isStudent, ...props }) => {
  const { t } = useTranslation();
  const courseWithStatistics = useMemo(() => {
    if (isStudent) {
      const questions = [].concat(
        course.questions,
        ...course.chapters.map((c) => c.questions),
      );
      const mastery =
        Math.round(
          (questions.reduce((m, q) => m + q.studyStats.mastery, 0) /
            (course.masteryThreshold * questions.length)) *
            100,
        ) || 0;
      const progression =
        Math.round(
          (questions.filter((q) => q.studyStats.attempted).length /
            questions.length) *
            100,
        ) || 0;
      const success =
        Math.round(
          (questions.filter((q) => q.studyStats.succeeded).length /
            questions.filter((q) => q.studyStats.attempted).length) *
            100,
        ) || 0;
      return { ...course, mastery, progression, success };
    }
    return course;
  }, [course, isStudent]);
  return (
    <CourseProgress
      course={courseWithStatistics}
      isStudent={isStudent}
      t={t}
      {...props}
    />
  );
};

CourseProgressContainer.propTypes = {
  course: PropTypes.object.isRequired,
  isStudent: PropTypes.bool,
};

CourseProgressContainer.defaultProps = {
  isStudent: false,
};

export default CourseProgressContainer;
