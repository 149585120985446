import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './Zone.module.scss';

const cx = classNames.bind(styles);

const Zone = ({ shapeType, sizeData }) => (
  <div
    className={cx('zone', `shape-${shapeType}`)}
    style={{
      width: `${sizeData.width}px`,
      height: `${sizeData.height}px`,
      left: `${sizeData.left}px`,
      top: `${sizeData.top}px`,
    }}
  />
);

Zone.propTypes = {
  shapeType: PropTypes.string.isRequired,
  sizeData: PropTypes.object.isRequired,
};

export default Zone;
