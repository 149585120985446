import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import * as yup from 'yup';

import COURSE_CONFIG from 'constants/courseConfig';
import { useMutationCourseUpdate } from 'gql/mutations/course';
import { useQueryCourse } from 'gql/queries/course';
import extract from 'helpers/extract';
import { trackEvent, reportError } from 'lib/tracking';

import Settings from './Settings';

const SettingsContainer = (props) => {
  const { t } = useTranslation();
  const { courseId } = useParams();
  const { isLoading, data } = useQueryCourse({
    variables: {
      courseId,
    },
  });
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        isLinear: yup.boolean(),
        masteryThreshold: yup
          .number()
          .integer()
          .default(1)
          .min(1, t('Course/Teacher/Settings/mastery-threshold-error-min')),
        isExam: yup.boolean().nullable(),
        examStartTime: yup
          .date()
          .when('isExam', {
            is: true,
            then: yup
              .date()
              .nullable()
              .required(t('Course/Teacher/Settings/start-time-error-required')),
            otherwise: yup.date().nullable(),
          })
          .min(
            COURSE_CONFIG.minDateLimit,
            t('Course/Teacher/Settings/limit-min-date'),
          )
          .max(
            COURSE_CONFIG.maxDateLimit,
            t('Course/Teacher/Settings/limit-max-date'),
          ),
        examStopTime: yup
          .date()
          .when('isExam', {
            is: true,
            then: yup
              .date()
              .nullable()
              .required(t('Course/Teacher/Settings/stop-time-error-required'))
              .min(
                yup.ref('examStartTime'),
                t('Course/Teacher/Settings/stop-time-error-after-start'),
              ),
            otherwise: yup.date().nullable(),
          })
          .max(
            COURSE_CONFIG.maxDateLimit,
            t('Course/Teacher/Settings/limit-max-date'),
          ),
        examDurationMinutes: yup
          .number()
          .integer(t('Course/Teacher/Settings/duration-error-required'))
          .min(0, t('Course/Teacher/Settings/duration-error-required'))
          .transform((v) => v || 0),
        isLinearExam: yup.boolean().nullable(),
        examInRandomOrder: yup.boolean().nullable(),
        nQuestionsByExam: yup.number().nullable().min(0),
        examInstructions: yup.string().nullable().trim(),
        isExamReviewEnabled: yup.boolean().nullable(),
        deadline: yup
          .date()
          .nullable()
          .min(
            COURSE_CONFIG.minDateLimit,
            t('Course/Teacher/Settings/limit-min-date'),
          )
          .max(
            COURSE_CONFIG.maxDateLimit,
            t('Course/Teacher/Settings/limit-max-date'),
          ),
      }),
    [t],
  );
  const handleTypeUpdate = useCallback(
    (setFieldValue) => (type) => {
      setFieldValue('isLinear', type === 'linear');
      setFieldValue('isExam', type === 'exam');
    },
    [],
  );
  const mutationCourseUpdate = useMutationCourseUpdate();
  const handleCourseUpdate = useCallback(
    async (values, form) => {
      try {
        form.setStatus(null);
        const formattedValues =
          values.nQuestionsByExam === ''
            ? { ...values, nQuestionsByExam: 0 }
            : values;
        const variables = extract(formattedValues, validationSchema);
        await mutationCourseUpdate(data.course, variables);
        trackEvent('course', 'update');
      } catch (err) {
        form.setStatus('500');
        reportError('course', 'update', err);
      }
    },
    [data, mutationCourseUpdate, validationSchema],
  );
  return (
    <Settings
      course={data?.course}
      handleCourseUpdate={handleCourseUpdate}
      handleTypeUpdate={handleTypeUpdate}
      isLoading={isLoading}
      t={t}
      validationSchema={validationSchema}
      {...props}
    />
  );
};

export default SettingsContainer;
