import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './FormattedText.module.scss';

const cx = classNames.bind(styles);

const FormattedText = ({
  as,
  children,
  className,
  handleRefMount,
  html,
  isAlreadyFormatted,
  ...props
}) =>
  isAlreadyFormatted
    ? React.createElement(
        as,
        {
          ...props,
          className: cx('container', 'fr-view', className),
          ref: handleRefMount,
          tabIndex: as === 'h1' ? -1 : 0,
        },
        children,
      )
    : React.createElement(as, {
        ...props,
        className: cx('container', 'fr-view', className),
        dangerouslySetInnerHTML: {
          __html: html,
        },
        ref: handleRefMount,
        tabIndex: as === 'h1' ? -1 : 0,
      });

FormattedText.propTypes = {
  as: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  handleRefMount: PropTypes.func.isRequired,
  html: PropTypes.string,
  isAlreadyFormatted: PropTypes.bool,
};

FormattedText.defaultProps = {
  as: 'div',
  children: undefined,
  className: undefined,
  html: undefined,
  isAlreadyFormatted: false,
};

export default FormattedText;
