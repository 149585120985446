import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Icon from '../Icon';
import Tooltip from '../Tooltip';

import styles from './HelpTooltip.module.scss';

const cx = classNames.bind(styles);

const HelpTooltip = ({ className, intent, tooltip }) => (
  <Tooltip tooltip={tooltip}>
    <Icon className={cx(className, `intent-${intent}`)} name="info-circle" />
  </Tooltip>
);

HelpTooltip.propTypes = {
  className: PropTypes.string,
  intent: PropTypes.oneOf(['ia']),
  tooltip: PropTypes.string.isRequired,
};

HelpTooltip.defaultProps = {
  className: undefined,
  intent: undefined,
};

export default HelpTooltip;
