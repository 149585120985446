import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Options from 'questions/Shared/Options';
import Tags from 'questions/Shared/Tags';
import Button from 'uikit/Button';
import Icon from 'uikit/Icon';
import InputError from 'uikit/InputError';
import InputImage from 'uikit/InputImage';
import InputRich from 'uikit/InputRich';
import InputTextArray from 'uikit/InputTextArray';
import Switch from 'uikit/Switch';
import Tooltip from 'uikit/Tooltip';

import ImageContainer from './ImageContainer';
import styles from './Editor.module.scss';

const cx = classNames.bind(styles);

const Editor = ({
  className,
  handleImageDelete,
  handleLegendAdd,
  handleLegendDelete,
  handleLegendsSet,
  handleZoneAdd,
  handleZonesSet,
  image,
  legends,
  t,
  zones,
}) => (
  <div className={cx('container', className)}>
    <p className={cx('label')}>{t('Questions/LabelOnImage/Editor/image')}</p>
    {image ? (
      <>
        <InputError name="legends" />
        <div className={cx('toolbar')}>
          <Button
            className={cx('action')}
            icon="plus"
            onClick={handleLegendAdd}
          >
            {t('Questions/LabelOnImage/Editor/add-legend')}
          </Button>
          <Button className={cx('action')} icon="plus" onClick={handleZoneAdd}>
            {t('Questions/LabelOnImage/Editor/add-zone')}
          </Button>
          <Button
            className={cx('action')}
            icon="trash-alt"
            onClick={handleImageDelete}
            tooltip={t('Questions/LabelOnImage/Editor/delete-image')}
            variant="regular"
          />
        </div>
        <div className={cx('image-legends-container')}>
          <ImageContainer
            className={cx('image')}
            handleLegendsSet={handleLegendsSet}
            handleZonesSet={handleZonesSet}
            imageSrc={image}
            legends={legends}
            zones={zones}
          />
          <div className={cx('legends')}>
            {legends.map((legend, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div className={cx('legend')} key={index}>
                <p className={cx('legend-index')}>{index + 1}</p>
                <InputTextArray
                  autoFocus
                  className={cx('legend-input')}
                  name={`legends.${index}.content`}
                  options={[]}
                />
                <Button
                  icon="trash-alt"
                  onClick={() => handleLegendDelete(index)}
                  tooltip={t('Questions/LabelOnImage/Editor/delete-label')}
                  variant="regular"
                />
              </div>
            ))}
          </div>
        </div>
      </>
    ) : (
      <InputImage className={cx('field', 'image-input')} name="image" />
    )}
    <InputRich
      helper={
        <Tooltip tooltip={t('Questions/LabelOnImage/Editor/feedback-helper')}>
          <Icon name="info-circle" />
        </Tooltip>
      }
      label={t('Questions/LabelOnImage/feedback-label')}
      name="feedback"
    />
    <Options className={cx('options')}>
      <Switch
        className={cx('field')}
        label={t('Questions/LabelOnImage/Editor/ignore-case-label')}
        name="ignoreCase"
      />
      <Switch
        className={cx('field')}
        label={t('Questions/LabelOnImage/Editor/ignore-accents-label')}
        name="ignoreAccents"
      />
      <Switch
        className={cx('field')}
        label={t('Questions/LabelOnImage/Editor/ignore-punctuation-label')}
        name="ignorePunctuation"
      />
      <Tags />
    </Options>
  </div>
);

Editor.propTypes = {
  className: PropTypes.string,
  handleImageDelete: PropTypes.func.isRequired,
  handleLegendAdd: PropTypes.func.isRequired,
  handleLegendDelete: PropTypes.func.isRequired,
  handleLegendsSet: PropTypes.func.isRequired,
  handleZoneAdd: PropTypes.func.isRequired,
  handleZonesSet: PropTypes.func.isRequired,
  image: PropTypes.string,
  legends: PropTypes.arrayOf(PropTypes.object).isRequired,
  t: PropTypes.func.isRequired,
  zones: PropTypes.arrayOf(PropTypes.object).isRequired,
};

Editor.defaultProps = {
  className: undefined,
  image: undefined,
};

export default Editor;
