import froalaeditor from 'froala-editor';
import BlankIcon from '../images/blank.svg';

froalaeditor.POPUP_TEMPLATES['blank.insert'] = '[_CUSTOM_LAYER_]';

froalaeditor.PLUGINS.blank = (editor) => {
  function insert() {
    editor.html.insert(
      editor.selection.text() ? `[[${editor.selection.text()}]]` : '[[blank]]',
    );
    editor.undo.saveStep();
  }
  return {
    insert,
  };
};

froalaeditor.DefineIcon('insertBlank', {
  SRC: BlankIcon,
  ALT: 'Insérer un trou',
  template: 'image',
});

froalaeditor.RegisterCommand('insertBlank', {
  title: 'Insérer un trou',
  callback() {
    this.blank.insert();
  },
});
