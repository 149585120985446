import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Options from 'questions/Shared/Options';
import SlideBuilder from 'questions/Shared/SlideBuilder';
import Tags from 'questions/Shared/Tags';

import styles from './Editor.module.scss';

const cx = classNames.bind(styles);

const Editor = ({ className }) => (
  <div className={cx('container', className)}>
    <SlideBuilder />
    <Options className={cx('options')}>
      <Tags />
    </Options>
  </div>
);

Editor.propTypes = {
  className: PropTypes.string,
};

Editor.defaultProps = {
  className: undefined,
};

export default Editor;
