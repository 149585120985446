import * as yup from 'yup';

import Editor from './Editor';

const EditorContainer = Editor;

EditorContainer.validationSchema = (t) =>
  yup.object().shape({
    tags: yup.array().of(yup.string().trim().lowercase().max(256)).compact(),
    title: yup
      .string()
      .trim()
      .required(t('Questions/Slide2/Editor/title-error-required')),
    slideContent: yup
      .string()
      .trim()
      .required(t('Questions/Slide2/Editor/slide-content-error-required')),
  });

export default EditorContainer;
