export const getDimensions = (wrapperRef, imageRef) => {
  if (!wrapperRef || !imageRef) {
    return [0, 0, 0];
  }
  const { clientWidth: maxWidth } = wrapperRef.current;
  const width = imageRef.current.clientWidth || imageRef.current.naturalWidth;
  const height =
    imageRef.current.clientHeight || imageRef.current.naturalHeight;
  if (maxWidth) {
    const scaleWidth = maxWidth / width;
    return [
      width * scaleWidth,
      height * scaleWidth,
      scaleWidth * Math.sqrt(width ** 2 + height ** 2),
    ];
  }
  return [width, height, Math.sqrt(width ** 2 + height ** 2)];
};

export const getEventCursorPosition = (event, currentFingerId = null) => {
  if (event.touches && event.touches.length) {
    const fingerId = currentFingerId ?? event.touches.length - 1;
    return [
      event.touches[fingerId].clientX,
      event.touches[fingerId].clientY,
      fingerId,
    ];
  }
  return [event.clientX, event.clientY, 0];
};

export const getDenormalizedPinPosition = (pin, diagonal) => ({
  top: pin.y * diagonal,
  left: pin.x * diagonal,
});

export const getNormalizedPinPosition = ({ top, left }, diagonal) => ({
  y: top / diagonal,
  x: left / diagonal,
});

export const getDenormalizedZonePosition = (zone, diagonal) => {
  if (zone.shapeType === 'rectangle') {
    return {
      top: zone.y * diagonal,
      left: zone.x * diagonal,
      width: zone.w * diagonal,
      height: zone.h * diagonal,
    };
  }
  return {
    top: (zone.y - zone.r) * diagonal,
    left: (zone.x - zone.r) * diagonal,
    width: zone.r * diagonal * 2,
    height: zone.r * diagonal * 2,
  };
};

export const getNormalizedZonePosition = (
  { width, height, top, left },
  diagonal,
  shapeType,
) => {
  if (shapeType === 'rectangle') {
    return {
      w: width / diagonal,
      h: height / diagonal,
      y: top / diagonal,
      x: left / diagonal,
    };
  }
  const denormalizedRadius = width / 2;
  const r = denormalizedRadius / diagonal;
  return {
    r,
    y: (top + denormalizedRadius) / diagonal,
    x: (left + denormalizedRadius) / diagonal,
  };
};
