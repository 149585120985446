import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import Tex2SVG from 'react-hook-mathjax';

import Button from 'uikit/Button';

import styles from './TextToolbar.module.scss';

const cx = classNames.bind(styles);

const TextToolbar = ({
  className,
  handleFormulaAdd,
  handleFormulaChange,
  handleFormulaSave,
  handleTextAdd,
  isDisplayedInColumn,
  t,
  textFormula,
}) => (
  <div>
    <div
      className={cx('container', className, { column: isDisplayedInColumn })}
    >
      <p className={cx('title')}>
        {t('Questions/Shared/SlideBuilder/TextToolbar/text-title')}
      </p>
      {['title', 'subtitle', 'text'].map((textElement) => (
        <Button
          className={cx('action')}
          key={textElement}
          onClick={() => handleTextAdd(textElement)}
        >
          {t(`Questions/Shared/SlideBuilder/TextToolbar/${textElement}-add`)}
        </Button>
      ))}
    </div>
    <div className={cx('container', className)}>
      <p className={cx('title')}>
        {t('Questions/Shared/SlideBuilder/TextToolbar/formula-title')}
      </p>
      <div className={cx('formula-input-container')}>
        <input
          className={cx('input', { margin: !textFormula })}
          onChange={handleFormulaChange}
          type="text"
          placeholder={t(
            'Questions/Shared/SlideBuilder/TextToolbar/formula-placeholder',
          )}
          value={textFormula}
        />
        <Button
          className={cx('formula-button')}
          intent={textFormula ? 'primary' : 'default'}
          onClick={handleFormulaAdd}
        >
          Ok
        </Button>
      </div>
      <div className={cx('formula-preview', { visible: !!textFormula })}>
        <Tex2SVG
          latex={textFormula}
          onSuccess={(html) => handleFormulaSave(html)}
        />
      </div>
    </div>
  </div>
);

TextToolbar.propTypes = {
  className: PropTypes.string,
  handleFormulaAdd: PropTypes.func.isRequired,
  handleFormulaChange: PropTypes.func.isRequired,
  handleFormulaSave: PropTypes.func.isRequired,
  handleTextAdd: PropTypes.func.isRequired,
  isDisplayedInColumn: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  textFormula: PropTypes.string,
};

TextToolbar.defaultProps = {
  className: undefined,
  textFormula: '',
};

export default TextToolbar;
