import { useField } from 'formik';
import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import useModal from 'hooks/useModal';
import Context from 'lib/context';

import Editor from './Editor';

const EditorContainer = (props) => {
  const { t } = useTranslation();
  const { theme } = useContext(Context);
  const [{ value: background }, , { setValue: setBackground }] =
    useField('background');
  const [{ value: textColor }, , { setValue: setTextColor }] =
    useField('textColor');
  const { isModalOpen, handleModalClose, handleModalOpen } = useModal(false);
  const handleImageSelect = useCallback(
    (url) => {
      setBackground(url);
      handleModalClose();
    },
    [handleModalClose, setBackground],
  );
  return (
    <Editor
      background={background}
      handleBackgroundSelect={setBackground}
      handleImageSelect={handleImageSelect}
      handleModalClose={handleModalClose}
      handleModalOpen={handleModalOpen}
      handleTextColorSelect={setTextColor}
      isModalOpen={isModalOpen}
      t={t}
      theme={theme}
      textColor={textColor}
      {...props}
    />
  );
};

EditorContainer.validationSchema = (t) =>
  yup.object().shape({
    tags: yup.array().of(yup.string().trim().lowercase().max(256)).compact(),
    title: yup
      .string()
      .trim()
      .required(t('Questions/Slide/Editor/title-error-required')),
    background: yup
      .string()
      .trim()
      .required(t('Questions/Slide/Editor/background-error-required')),
    textColor: yup
      .string()
      .trim()
      .required(t('Questions/Slide/Editor/textColor-error-required')),
  });

export default EditorContainer;
