import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { useMutationCourseSort } from 'gql/mutations/course';
import { trackEvent, reportError } from 'lib/tracking';

import Finder from './Finder';

const FinderContainer = ({ courses, folders, ...props }) => {
  const mutationCourseSort = useMutationCourseSort();
  const handleCourseSort = useCallback(
    async ({ draggableId, source, destination }) => {
      try {
        if (
          !destination ||
          (source.index === destination.index &&
            source.droppableId === destination.droppableId)
        ) {
          return;
        }
        const course = courses.find((c) => c.id === draggableId);
        if (course) {
          mutationCourseSort(course, {
            folderId:
              destination.droppableId === 'dashboard'
                ? null
                : destination.droppableId,
          });
        }
        trackEvent('course', 'sort');
      } catch (err) {
        reportError('course', 'sort', err);
      }
    },
    [courses, mutationCourseSort],
  );
  return (
    <Finder
      courses={courses}
      folders={folders}
      handleCourseSort={handleCourseSort}
      {...props}
    />
  );
};

FinderContainer.propTypes = {
  courses: PropTypes.arrayOf(PropTypes.object).isRequired,
  folders: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default FinderContainer;
