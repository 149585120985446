import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { mutationEditorQuestionCreateFromIA } from 'gql/mutations/editor';
import { useMutationMeTag } from 'gql/mutations/me';
import { useQueryMe } from 'gql/queries/me';
import useModal from 'hooks/useModal';
import { trackEvent } from 'lib/tracking';
import Button from 'uikit/Button';
import Icon from 'uikit/Icon';
import Modal from 'uikit/Modal';
import Tooltip from 'uikit/Tooltip';

import styles from './ContentCreationMethod.module.scss';

const cx = classNames.bind(styles);

const ContentCreationMethod = ({ course }) => {
  const { t } = useTranslation();

  const prefix = 'Course/Teacher/ContentCreationMethod';

  const location = useLocation();

  const { handleModalClose, handleModalOpen, isModalOpen } = useModal();

  const { data: dataMe } = useQueryMe();

  const mutationMeTag = useMutationMeTag();

  const wantsAI = !!dataMe?.me.wantsAI;

  const meTags = dataMe?.me.tags || [];

  useEffect(() => {
    const isFromOnboardingWithIA = location.search.includes('useIA=true');
    const isFromOnboardingWithoutIA = location.search.includes('useIA=false');
    const isCourseEmpty =
      !course.questions.length &&
      !course.chapters.length &&
      !course.files.length;
    if (isFromOnboardingWithIA) {
      mutationEditorQuestionCreateFromIA();
    } else if (wantsAI && !isFromOnboardingWithoutIA && isCourseEmpty) {
      handleModalOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTriggerFirstTourSteps = async () => {
    try {
      if (!meTags.includes('first-course-created')) {
        await mutationMeTag(['first-course-created']);
        trackEvent('tag', 'first-course-created');
      }
    } catch (err) {
      reportError('tag', 'first-course-created', err);
    }
  };

  const handleAvoidFirstTourStep = async () => {
    try {
      if (
        !meTags.includes('first-course-created') ||
        !meTags.includes('tour:2')
      ) {
        await mutationMeTag(['first-course-created', 'tour:2']);
        trackEvent('tag', 'first-course-created, tour:2');
      }
    } catch (err) {
      reportError('tag', 'first-course-created, tour:2', err);
    }
  };

  const handleMethodFromIA = () => {
    handleAvoidFirstTourStep();
    mutationEditorQuestionCreateFromIA();
    handleModalClose();
  };

  const handleMethodFromScratch = () => {
    handleTriggerFirstTourSteps();
    handleModalClose();
  };

  return (
    <Modal isOpen={isModalOpen} onExit={handleMethodFromScratch}>
      <div className={cx('modal')}>
        <Button
          aria-label={t(`${prefix}/aria-close`)}
          className={cx('close')}
          icon="times"
          onClick={handleMethodFromScratch}
        />
        <h1 className={cx('title')}>{t(`${prefix}/title`)}</h1>
        <div className={cx('choices')}>
          <button
            className={cx('choice', 'from-scratch')}
            data-testid="from-scratch-course-button"
            onClick={handleMethodFromScratch}
            type="button"
          >
            <Icon className={cx('icon')} name="plus" />
            <h2 className={cx('choice-title')}>
              {t(`${prefix}/from-scratch-title`)}
            </h2>
          </button>
          <button
            className={cx('choice', 'from-ia')}
            onClick={handleMethodFromIA}
            type="button"
          >
            <Tooltip tooltip={t(`${prefix}/from-ia-tooltip`)}>
              <Icon className={cx('tooltip-icon')} name="info-circle" />
            </Tooltip>
            <Icon className={cx('icon')} name="wand-sparkles" />
            <h2 className={cx('choice-title')}>
              {t(`${prefix}/from-ia-title`)}
            </h2>
          </button>
        </div>
      </div>
    </Modal>
  );
};

ContentCreationMethod.propTypes = {
  course: PropTypes.object.isRequired,
};

export default ContentCreationMethod;
