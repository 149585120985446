import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { QUESTION_DATA } from 'gql/fragments';

export const QUESTION_CREATE = gql`
  mutation QuestionCreate(
    $courseId: ID!
    $chapterId: ID
    $type: String!
    $tags: [String]
    $title: String!
    $content: JSON!
  ) {
    questionCreate(
      courseId: $courseId
      chapterId: $chapterId
      type: $type
      tags: $tags
      title: $title
      content: $content
    ) {
      ...QuestionData
    }
  }
  ${QUESTION_DATA}
`;

export function useMutationQuestionCreate() {
  const [mutation] = useMutation(QUESTION_CREATE);
  return useCallback(
    (variables) =>
      mutation({
        variables,
        update(cache, { data: { questionCreate } }) {
          const newQuestionRef = cache.writeFragment({
            data: questionCreate,
            fragment: QUESTION_DATA,
          });
          cache.modify({
            id: questionCreate.chapterId
              ? `Chapter:${questionCreate.chapterId}`
              : `Course:${questionCreate.courseId}`,
            fields: {
              questions: (existingQuestionsRef = []) => [
                ...existingQuestionsRef,
                newQuestionRef,
              ],
            },
          });
        },
      }),
    [mutation],
  );
}

export const QUESTION_PROPOSE = gql`
  mutation QuestionPropose(
    $courseId: ID!
    $type: String!
    $tags: [String]
    $title: String!
    $content: JSON!
  ) {
    questionPropose(
      courseId: $courseId
      type: $type
      tags: $tags
      title: $title
      content: $content
    ) {
      ...QuestionData
    }
  }
  ${QUESTION_DATA}
`;

export function useMutationQuestionPropose() {
  const [mutation] = useMutation(QUESTION_PROPOSE);
  return useCallback(
    (variables) =>
      mutation({
        variables,
        update(cache, { data: { questionPropose } }) {
          const newQuestionToApproveRef = cache.writeFragment({
            data: questionPropose,
            fragment: QUESTION_DATA,
          });
          cache.modify({
            id: `Course:${questionPropose.courseId}`,
            fields: {
              questionsToApprove: (existingQuestionsToApproveRef = []) => [
                ...existingQuestionsToApproveRef,
                newQuestionToApproveRef,
              ],
            },
          });
        },
      }),
    [mutation],
  );
}

export const QUESTION_DELETE = gql`
  mutation QuestionDelete(
    $questionId: ID!
    $grade: Int
    $notifyStudent: Boolean
    $studentFeedback: String
  ) {
    questionDelete(
      questionId: $questionId
      grade: $grade
      notifyStudent: $notifyStudent
      studentFeedback: $studentFeedback
    )
  }
`;

export function useMutationQuestionDelete() {
  const [mutation] = useMutation(QUESTION_DELETE);
  return useCallback(
    (question, variables) =>
      mutation({
        variables: {
          ...variables,
          questionId: question.id,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          questionDelete: true,
        },
        update(cache) {
          if (question.isAwaitingApproval) {
            cache.modify({
              id: `Course:${question.courseId}`,
              fields: {
                questionsToApprove: (
                  existingQuestionsToApproveRef = [],
                  { readField },
                ) =>
                  existingQuestionsToApproveRef.filter(
                    (q) => readField('id', q) !== question.id,
                  ),
              },
            });
          } else {
            cache.modify({
              id: question.chapterId
                ? `Chapter:${question.chapterId}`
                : `Course:${question.courseId}`,
              fields: {
                questions: (existingQuestionsRef = [], { readField }) => {
                  const updatedQuestionsRef = existingQuestionsRef.filter(
                    (q) => readField('id', q) !== question.id,
                  );
                  updatedQuestionsRef.forEach((q) => {
                    const rOrder = readField('order', q);
                    if (rOrder >= question.order) {
                      cache.writeFragment({
                        id: `Question:${readField('id', q)}`,
                        fragment: gql`
                          fragment QuestionUpdateOrder on Question {
                            order
                          }
                        `,
                        data: {
                          order: rOrder - 1,
                        },
                      });
                    }
                  });
                  return updatedQuestionsRef;
                },
              },
            });
          }
        },
      }),
    [mutation],
  );
}

export const QUESTION_DELETE_MULTIPLE = gql`
  mutation QuestionDeleteMultiple($courseId: ID!, $questionIds: [ID]!) {
    questionDeleteMultiple(courseId: $courseId, questionIds: $questionIds)
  }
`;

export function useMutationQuestionDeleteMultiple() {
  const [mutation] = useMutation(QUESTION_DELETE_MULTIPLE);
  return useCallback(
    (course, questions) =>
      mutation({
        variables: {
          courseId: course.id,
          questionIds: questions.map((question) => question.id),
        },
        optimisticResponse: {
          __typename: 'Mutation',
          questionDeleteMultiple: true,
        },
        update(cache) {
          const questionsPerChapter = questions.reduce((rv, question) => {
            const chapterId = question.chapterId || 'course';
            // eslint-disable-next-line no-param-reassign
            (rv[chapterId] = rv[chapterId] || []).push(question);
            return rv;
          }, {});
          Object.entries(questionsPerChapter).forEach(([chapterId, qs]) => {
            const ids = qs.map((q) => q.id);
            const orders = qs.map((q) => q.order);
            cache.modify({
              id:
                chapterId === 'course'
                  ? `Course:${course.id}`
                  : `Chapter:${chapterId}`,
              fields: {
                questions: (existingQuestionsRef = [], { readField }) => {
                  const updatedQuestionsRef = existingQuestionsRef.filter(
                    (q) => !ids.includes(readField('id', q)),
                  );
                  updatedQuestionsRef.forEach((q) => {
                    const rOrder = readField('order', q);
                    const decrement = orders.filter((o) => o <= rOrder).length;
                    if (decrement > 0) {
                      cache.writeFragment({
                        id: `Question:${readField('id', q)}`,
                        fragment: gql`
                          fragment QuestionUpdateOrder on Question {
                            order
                          }
                        `,
                        data: {
                          order: rOrder - decrement,
                        },
                      });
                    }
                  });
                  return updatedQuestionsRef;
                },
                questionsToApprove: (
                  existingQuestionsToApproveRef = [],
                  { readField },
                ) =>
                  existingQuestionsToApproveRef.filter(
                    (q) => !ids.includes(readField('id', q)),
                  ),
              },
            });
          });
        },
      }),
    [mutation],
  );
}

export const QUESTION_DUPLICATE = gql`
  mutation QuestionDuplicate($questionId: ID!) {
    questionDuplicate(questionId: $questionId) {
      ...QuestionData
    }
  }
  ${QUESTION_DATA}
`;

export function useMutationQuestionDuplicate() {
  const [mutation] = useMutation(QUESTION_DUPLICATE);
  return useCallback(
    (question) =>
      mutation({
        variables: {
          questionId: question.id,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          questionDuplicate: {
            ...question,
            id: Math.random().toString(10),
            order: question.order + 1,
            __typename: 'Question',
          },
        },
        update(cache, { data: { questionDuplicate } }) {
          const newQuestionRef = cache.writeFragment({
            data: questionDuplicate,
            fragment: QUESTION_DATA,
          });
          cache.modify({
            id: questionDuplicate.chapterId
              ? `Chapter:${questionDuplicate.chapterId}`
              : `Course:${questionDuplicate.courseId}`,
            fields: {
              questions: (existingQuestionsRef = [], { readField }) => {
                existingQuestionsRef.forEach((q) => {
                  const rOrder = readField('order', q);
                  if (rOrder >= questionDuplicate.order) {
                    cache.writeFragment({
                      id: `Question:${readField('id', q)}`,
                      fragment: gql`
                        fragment QuestionUpdateOrder on Question {
                          order
                        }
                      `,
                      data: {
                        order: rOrder + 1,
                      },
                    });
                  }
                });
                const updatedQuestionsRef = [...existingQuestionsRef];
                updatedQuestionsRef.splice(
                  questionDuplicate.order,
                  0,
                  newQuestionRef,
                );
                return updatedQuestionsRef;
              },
            },
          });
        },
      }),
    [mutation],
  );
}

export const QUESTION_DUPLICATE_MULTIPLE = gql`
  mutation QuestionDuplicateMultiple($courseId: ID!, $questionIds: [ID]!) {
    questionDuplicateMultiple(courseId: $courseId, questionIds: $questionIds) {
      ...QuestionData
    }
  }
  ${QUESTION_DATA}
`;

export function useMutationQuestionDuplicateMultiple() {
  const [mutation] = useMutation(QUESTION_DUPLICATE_MULTIPLE);
  return useCallback(
    (course, questions) => {
      const ordersPerChapter = questions.reduce((rv, question) => {
        const chapterId = question.chapterId || 'course';
        // eslint-disable-next-line no-param-reassign
        (rv[chapterId] = rv[chapterId] || []).push(question.order);
        return rv;
      }, {});
      return mutation({
        variables: {
          courseId: course.id,
          questionIds: questions.map((question) => question.id),
        },
        optimisticResponse: {
          __typename: 'Mutation',
          questionDuplicateMultiple: questions.map((question) => {
            const chapterId = question.chapterId || 'course';
            const increment = ordersPerChapter[chapterId].filter(
              (o) => o <= question.order,
            ).length;
            return {
              ...question,
              id: Math.random().toString(10),
              order: question.order + increment,
              __typename: 'Question',
            };
          }),
        },
        update(cache, { data: { questionDuplicateMultiple } }) {
          const questionsPerChapter = questionDuplicateMultiple.reduce(
            (rv, question) => {
              const chapterId = question.chapterId || 'course';
              // eslint-disable-next-line no-param-reassign
              (rv[chapterId] = rv[chapterId] || []).push(question);
              return rv;
            },
            {},
          );
          Object.entries(questionsPerChapter).forEach(([chapterId, qs]) => {
            const orders = qs.map((q) => q.order);
            cache.modify({
              id:
                chapterId === 'course'
                  ? `Course:${course.id}`
                  : `Chapter:${chapterId}`,
              fields: {
                questions: (existingQuestionsRef = [], { readField }) => {
                  existingQuestionsRef.forEach((q) => {
                    const rOrder = readField('order', q);
                    const increment = orders.filter((o) => o <= rOrder).length;
                    if (increment > 0) {
                      cache.writeFragment({
                        id: `Question:${readField('id', q)}`,
                        fragment: gql`
                          fragment QuestionUpdateOrder on Question {
                            order
                          }
                        `,
                        data: {
                          order: rOrder + increment,
                        },
                      });
                    }
                  });
                  const newQuestionRefs = qs.map((q) =>
                    cache.writeFragment({
                      data: q,
                      fragment: QUESTION_DATA,
                    }),
                  );
                  const updatedQuestionsRef = [
                    ...existingQuestionsRef,
                    ...newQuestionRefs,
                  ].sort(
                    (a, b) => readField('order', a) - readField('order', b),
                  );
                  return updatedQuestionsRef;
                },
              },
            });
          });
        },
      });
    },
    [mutation],
  );
}

export const QUESTION_IMPORT_PUBLIC_COURSE = gql`
  mutation QuestionImportPublicCourse(
    $courseId: ID!
    $selectedCourseId: ID!
    $chapterId: ID
    $questionIds: [ID]!
  ) {
    questionImportPublicCourse(
      courseId: $courseId
      selectedCourseId: $selectedCourseId
      chapterId: $chapterId
      questionIds: $questionIds
    ) {
      ...QuestionData
    }
  }
  ${QUESTION_DATA}
`;

export function useMutationQuestionImportPublicCourse() {
  const [mutation] = useMutation(QUESTION_IMPORT_PUBLIC_COURSE);
  return useCallback(
    (variables) =>
      mutation({
        variables,
        update(cache, { data: { questionImportPublicCourse } }) {
          cache.modify({
            id: variables.chapterId
              ? `Chapter:${variables.chapterId}`
              : `Course:${variables.courseId}`,
            fields: {
              questions: (existingQuestionsRef = []) => [
                ...existingQuestionsRef,
                ...questionImportPublicCourse.map((question) =>
                  cache.writeFragment({
                    data: question,
                    fragment: QUESTION_DATA,
                  }),
                ),
              ],
            },
          });
        },
      }),
    [mutation],
  );
}

export const QUESTION_MOVE = gql`
  mutation QuestionMove($questionId: ID!, $courseId: ID!, $chapterId: ID) {
    questionMove(
      questionId: $questionId
      courseId: $courseId
      chapterId: $chapterId
    ) {
      ...QuestionData
    }
  }
  ${QUESTION_DATA}
`;

export function useMutationQuestionMove() {
  const [mutation] = useMutation(QUESTION_MOVE);
  return useCallback(
    (question, variables) =>
      mutation({
        variables: {
          ...variables,
          questionId: question.id,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          questionMove: {
            ...question,
            courseId: variables.courseId,
            chapterId: variables.chapterId,
            order: 999999,
            __typename: 'Question',
          },
        },
        update(cache, { data: { questionMove } }) {
          let questionRef = null;
          cache.modify({
            id: question.chapterId
              ? `Chapter:${question.chapterId}`
              : `Course:${question.courseId}`,
            fields: {
              questions: (existingQuestionsRef = [], { readField }) => {
                existingQuestionsRef.forEach((q) => {
                  const rId = readField('id', q);
                  const rOrder = readField('order', q);
                  if (rId === question.id) {
                    questionRef = q;
                  } else if (rOrder >= question.order) {
                    cache.writeFragment({
                      id: `Question:${rId}`,
                      fragment: gql`
                        fragment QuestionUpdateOrder on Question {
                          order
                        }
                      `,
                      data: {
                        order: rOrder - 1,
                      },
                    });
                  }
                });
                const updatedQuestionsRef = existingQuestionsRef.filter(
                  (c) => readField('id', c) !== question.id,
                );
                return updatedQuestionsRef;
              },
            },
          });
          cache.modify({
            id: questionMove.chapterId
              ? `Chapter:${questionMove.chapterId}`
              : `Course:${questionMove.courseId}`,
            fields: {
              questions: (existingQuestionsRef = []) => [
                ...existingQuestionsRef,
                questionRef,
              ],
            },
          });
        },
      }),
    [mutation],
  );
}

export const QUESTION_MOVE_MULTIPLE = gql`
  mutation QuestionMoveMultiple(
    $courseId: ID!
    $questionIds: [ID]!
    $selectedCourseId: ID!
    $selectedChapterId: ID
  ) {
    questionMoveMultiple(
      courseId: $courseId
      questionIds: $questionIds
      selectedCourseId: $selectedCourseId
      selectedChapterId: $selectedChapterId
    ) {
      ...QuestionData
    }
  }
  ${QUESTION_DATA}
`;

export function useMutationQuestionMoveMultiple() {
  const [mutation] = useMutation(QUESTION_MOVE_MULTIPLE);
  return useCallback(
    (course, questions, variables) =>
      mutation({
        variables: {
          ...variables,
          courseId: course.id,
          questionIds: questions.map((question) => question.id),
        },
        optimisticResponse: {
          __typename: 'Mutation',
          questionMoveMultiple: questions.map((question, i) => ({
            ...question,
            id: Math.random().toString(10),
            courseId: variables.selectedCourseId,
            chapterId: variables.selectedChapterId || null,
            order: 9999 + i,
            __typename: 'Question',
          })),
        },
        update(cache, { data: { questionMoveMultiple } }) {
          const questionsRef = [];
          const questionsPerChapter = questions.reduce((rv, question) => {
            const chapterId = question.chapterId || 'course';
            // eslint-disable-next-line no-param-reassign
            (rv[chapterId] = rv[chapterId] || []).push(question);
            return rv;
          }, {});
          Object.entries(questionsPerChapter).forEach(([chapterId, qs]) => {
            const ids = qs.map((q) => q.id);
            const orders = qs.map((q) => q.order);
            cache.modify({
              id:
                chapterId === 'course'
                  ? `Course:${course.id}`
                  : `Chapter:${chapterId}`,
              fields: {
                questions: (existingQuestionsRef = [], { readField }) => {
                  const updatedQuestionsRef = existingQuestionsRef.filter(
                    (q) => {
                      if (ids.includes(readField('id', q))) {
                        questionsRef.push(q);
                        return false;
                      }
                      return true;
                    },
                  );
                  updatedQuestionsRef.forEach((q) => {
                    const rOrder = readField('order', q);
                    const decrement = orders.filter((o) => o <= rOrder).length;
                    if (decrement > 0) {
                      cache.writeFragment({
                        id: `Question:${readField('id', q)}`,
                        fragment: gql`
                          fragment QuestionUpdateOrder on Question {
                            order
                          }
                        `,
                        data: {
                          order: rOrder - decrement,
                        },
                      });
                    }
                  });
                  return updatedQuestionsRef;
                },
              },
            });
          });
          cache.modify({
            id: questionMoveMultiple[0]?.chapterId
              ? `Chapter:${questionMoveMultiple[0]?.chapterId}`
              : `Course:${questionMoveMultiple[0]?.courseId}`,
            fields: {
              questions: (existingQuestionsRef = []) => [
                ...existingQuestionsRef,
                ...questionsRef,
              ],
            },
          });
        },
      }),
    [mutation],
  );
}

export const QUESTION_SORT = gql`
  mutation QuestionSort($questionId: ID!, $chapterId: ID, $to: Int!) {
    questionSort(questionId: $questionId, chapterId: $chapterId, to: $to) {
      ...QuestionData
    }
  }
  ${QUESTION_DATA}
`;

export function useMutationQuestionSort() {
  const [mutation] = useMutation(QUESTION_SORT);
  return useCallback(
    (question, variables) =>
      mutation({
        variables: {
          ...variables,
          questionId: question.id,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          questionSort: {
            ...question,
            chapterId: variables.chapterId,
            order: variables.to,
            __typename: 'Question',
          },
        },
        update(cache, { data: { questionSort } }) {
          if (question.chapterId === questionSort.chapterId) {
            cache.modify({
              id: question.chapterId
                ? `Chapter:${question.chapterId}`
                : `Course:${question.courseId}`,
              fields: {
                questions: (existingQuestionsRef = [], { readField }) => {
                  existingQuestionsRef.forEach((q) => {
                    const rId = readField('id', q);
                    const rOrder = readField('order', q);
                    if (
                      rId !== questionSort.id &&
                      questionSort.order <= rOrder &&
                      rOrder < question.order
                    ) {
                      cache.writeFragment({
                        id: `Question:${rId}`,
                        fragment: gql`
                          fragment QuestionUpdateOrder on Question {
                            order
                          }
                        `,
                        data: {
                          order: rOrder + 1,
                        },
                      });
                    } else if (
                      rId !== questionSort.id &&
                      question.order < rOrder &&
                      rOrder <= questionSort.order
                    ) {
                      cache.writeFragment({
                        id: `Question:${rId}`,
                        fragment: gql`
                          fragment QuestionUpdateOrder on Question {
                            order
                          }
                        `,
                        data: {
                          order: rOrder - 1,
                        },
                      });
                    }
                  });
                  const updatedQuestionsRef = [...existingQuestionsRef];
                  updatedQuestionsRef.splice(
                    questionSort.order,
                    0,
                    updatedQuestionsRef.splice(question.order, 1)[0],
                  );
                  return updatedQuestionsRef;
                },
              },
            });
          } else {
            let questionRef = null;
            cache.modify({
              id: question.chapterId
                ? `Chapter:${question.chapterId}`
                : `Course:${question.courseId}`,
              fields: {
                questions: (existingQuestionsRef = [], { readField }) => {
                  existingQuestionsRef.forEach((q) => {
                    const rId = readField('id', q);
                    const rOrder = readField('order', q);
                    if (rId === question.id) {
                      questionRef = q;
                    } else if (rOrder >= question.order) {
                      cache.writeFragment({
                        id: `Question:${rId}`,
                        fragment: gql`
                          fragment QuestionUpdateOrder on Question {
                            order
                          }
                        `,
                        data: {
                          order: rOrder - 1,
                        },
                      });
                    }
                  });
                  const updatedQuestionsRef = existingQuestionsRef.filter(
                    (c) => readField('id', c) !== question.id,
                  );
                  return updatedQuestionsRef;
                },
              },
            });
            cache.modify({
              id: questionSort.chapterId
                ? `Chapter:${questionSort.chapterId}`
                : `Course:${questionSort.courseId}`,
              fields: {
                questions: (existingQuestionsRef = [], { readField }) => {
                  existingQuestionsRef.forEach((q) => {
                    const rOrder = readField('order', q);
                    if (rOrder >= questionSort.order) {
                      cache.writeFragment({
                        id: `Question:${readField('id', q)}`,
                        fragment: gql`
                          fragment QuestionUpdateOrder on Question {
                            order
                          }
                        `,
                        data: {
                          order: rOrder + 1,
                        },
                      });
                    }
                  });
                  const updatedQuestionsRef = [...existingQuestionsRef];
                  updatedQuestionsRef.splice(
                    questionSort.order,
                    0,
                    questionRef,
                  );
                  return updatedQuestionsRef;
                },
              },
            });
          }
        },
      }),
    [mutation],
  );
}

export const QUESTION_SORT_MULTIPLE = gql`
  mutation QuestionSortMultiple(
    $questionIds: [ID]!
    $courseId: ID!
    $chapterId: ID
    $to: Int!
  ) {
    questionSortMultiple(
      questionIds: $questionIds
      courseId: $courseId
      chapterId: $chapterId
      to: $to
    ) {
      ...QuestionData
    }
  }
  ${QUESTION_DATA}
`;

export function useMutationQuestionSortMultiple() {
  const [mutation] = useMutation(QUESTION_SORT_MULTIPLE);
  return useCallback(
    (questions, variables) =>
      mutation({
        variables: {
          ...variables,
          questionIds: questions.map((question) => question.id),
        },
        // OptimisticResponse stop the cache updating during the multiple sorting
        // optimisticResponse: {
        //   __typename: 'Mutation',
        //   questionSortMultiple: questions.map((question, index) => ({
        //     ...question,
        //     chapterId: variables.chapterId,
        //     order: variables.to + index,
        //     __typename: 'Question',
        //   })),
        // },
        update(cache, { data: { questionSortMultiple } }) {
          questions.forEach((question) => {
            if (questionSortMultiple && questionSortMultiple.length) {
              const questionSort = questionSortMultiple.find(
                (q) => q.id === question.id,
              );
              const questionIds = questions.map((q) => q.id);
              if (question.chapterId === questionSort.chapterId) {
                cache.modify({
                  id: question.chapterId
                    ? `Chapter:${question.chapterId}`
                    : `Course:${question.courseId}`,
                  fields: {
                    questions: (existingQuestionsRef = [], { readField }) => {
                      existingQuestionsRef.forEach((q) => {
                        const rId = readField('id', q);
                        const rOrder = readField('order', q);
                        if (
                          !questionIds.includes(rId) &&
                          questionSort.order <= rOrder &&
                          rOrder < question.order
                        ) {
                          cache.writeFragment({
                            id: `Question:${rId}`,
                            fragment: gql`
                              fragment QuestionUpdateOrder on Question {
                                order
                              }
                            `,
                            data: {
                              order: rOrder + 1,
                            },
                          });
                        } else if (
                          !questionIds.includes(rId) &&
                          question.order < rOrder &&
                          rOrder <= questionSort.order
                        ) {
                          cache.writeFragment({
                            id: `Question:${rId}`,
                            fragment: gql`
                              fragment QuestionUpdateOrder on Question {
                                order
                              }
                            `,
                            data: {
                              order: rOrder - 1,
                            },
                          });
                        }
                      });
                      const updatedQuestionsRef = [...existingQuestionsRef];
                      updatedQuestionsRef.splice(question.order, 1);
                      updatedQuestionsRef.splice(
                        questionSort.order,
                        0,
                        questionSort,
                      );
                      return updatedQuestionsRef;
                    },
                  },
                });
              } else {
                cache.modify({
                  id: question.chapterId
                    ? `Chapter:${question.chapterId}`
                    : `Course:${question.courseId}`,
                  fields: {
                    questions: (existingQuestionsRef = [], { readField }) => {
                      existingQuestionsRef.forEach((q) => {
                        const rId = readField('id', q);
                        const rOrder = readField('order', q);
                        if (
                          !questionIds.includes(rId) &&
                          rOrder >= question.order
                        ) {
                          cache.writeFragment({
                            id: `Question:${rId}`,
                            fragment: gql`
                              fragment QuestionUpdateOrder on Question {
                                order
                              }
                            `,
                            data: {
                              order: rOrder - 1,
                            },
                          });
                        }
                      });
                      const updatedQuestionsRef = existingQuestionsRef.filter(
                        (c) => readField('id', c) !== question.id,
                      );
                      return updatedQuestionsRef;
                    },
                  },
                });
                cache.modify({
                  id: questionSort.chapterId
                    ? `Chapter:${questionSort.chapterId}`
                    : `Course:${questionSort.courseId}`,
                  fields: {
                    questions: (existingQuestionsRef = [], { readField }) => {
                      existingQuestionsRef.forEach((q) => {
                        const rId = readField('id', q);
                        const rOrder = readField('order', q);
                        if (
                          !questionIds.includes(rId) &&
                          rOrder >= questionSort.order
                        ) {
                          cache.writeFragment({
                            id: `Question:${readField('id', q)}`,
                            fragment: gql`
                              fragment QuestionUpdateOrder on Question {
                                order
                              }
                            `,
                            data: {
                              order: rOrder + 1,
                            },
                          });
                        }
                      });
                      const updatedQuestionsRef = [...existingQuestionsRef];
                      updatedQuestionsRef.splice(
                        questionSort.order,
                        0,
                        questionSort,
                      );
                      return updatedQuestionsRef;
                    },
                  },
                });
              }
            }
            return null;
          });
        },
      }),
    [mutation],
  );
}

export const QUESTION_UPDATE = gql`
  mutation QuestionUpdate(
    $questionId: ID!
    $tags: [String]
    $title: String
    $content: JSON
  ) {
    questionUpdate(
      questionId: $questionId
      tags: $tags
      title: $title
      content: $content
    ) {
      ...QuestionData
    }
  }
  ${QUESTION_DATA}
`;

export function useMutationQuestionUpdate() {
  const [mutation] = useMutation(QUESTION_UPDATE);
  return useCallback(
    (question, variables) =>
      mutation({
        variables: {
          ...variables,
          questionId: question.id,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          questionUpdate: {
            ...question,
            ...variables,
            __typename: 'Question',
          },
        },
      }),
    [mutation],
  );
}

export const QUESTION_APPROVE = gql`
  mutation QuestionApprove(
    $questionId: ID!
    $chapterId: ID
    $tags: [String]
    $title: String
    $content: JSON
    $grade: Int
    $studentFeedback: String
    $notifyStudent: Boolean
  ) {
    questionApprove(
      questionId: $questionId
      chapterId: $chapterId
      tags: $tags
      title: $title
      content: $content
      grade: $grade
      studentFeedback: $studentFeedback
      notifyStudent: $notifyStudent
    ) {
      ...QuestionData
    }
  }
  ${QUESTION_DATA}
`;

export function useMutationQuestionApprove() {
  const [mutation] = useMutation(QUESTION_APPROVE);
  return useCallback(
    (question, variables) =>
      mutation({
        variables: {
          ...variables,
          questionId: question.id,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          questionApprove: {
            ...question,
            ...variables,
            order: 99999,
            chapterId: null,
            isAwaitingApproval: false,
            __typename: 'Question',
          },
        },
        update(cache, { data: { questionApprove } }) {
          const newApprovedQuestionRef = cache.writeFragment({
            data: questionApprove,
            fragment: QUESTION_DATA,
          });
          cache.modify({
            id: `Course:${variables.courseId}`,
            fields: {
              questions: (existingQuestionsRef = []) => [
                ...existingQuestionsRef,
                newApprovedQuestionRef,
              ],
              questionsToApprove: (
                existingQuestionsToApproveRef = [],
                { readField },
              ) => {
                const updatedQuestionsToApproveRef =
                  existingQuestionsToApproveRef.filter(
                    (q) => readField('id', q) !== question.id,
                  );
                return updatedQuestionsToApproveRef;
              },
            },
          });
        },
      }),
    [mutation],
  );
}

export const QUESTION_IMPORT_COURSE = gql`
  mutation QuestionImportCourse(
    $courseId: ID!
    $chapterId: ID
    $selectedCourseId: ID!
    $questionIds: [ID]!
  ) {
    questionImportCourse(
      courseId: $courseId
      chapterId: $chapterId
      selectedCourseId: $selectedCourseId
      questionIds: $questionIds
    ) {
      ...QuestionData
    }
  }
  ${QUESTION_DATA}
`;

export function useMutationQuestionImportCourse() {
  const [mutation] = useMutation(QUESTION_IMPORT_COURSE);
  return useCallback(
    (variables) =>
      mutation({
        variables,
        update(cache, { data: { questionImportCourse } }) {
          cache.modify({
            id: variables.chapterId
              ? `Chapter:${variables.chapterId}`
              : `Course:${variables.courseId}`,
            fields: {
              questions: (existingQuestionsRef = []) => [
                ...existingQuestionsRef,
                ...questionImportCourse.map((question) =>
                  cache.writeFragment({
                    data: question,
                    fragment: QUESTION_DATA,
                  }),
                ),
              ],
            },
          });
        },
      }),
    [mutation],
  );
}

export const QUESTION_IMPORT_FILE = gql`
  mutation QuestionImportFile(
    $courseId: ID!
    $chapterId: ID
    $extension: String!
    $fileName: String!
    $file: Upload
    $filePath: String
    $recto: String
    $verso: String
  ) {
    questionImportFile(
      courseId: $courseId
      chapterId: $chapterId
      extension: $extension
      fileName: $fileName
      file: $file
      filePath: $filePath
      recto: $recto
      verso: $verso
    ) {
      ...QuestionData
    }
  }
  ${QUESTION_DATA}
`;

export function useMutationQuestionImportFile() {
  const [mutation] = useMutation(QUESTION_IMPORT_FILE);
  return useCallback(
    (variables) =>
      mutation({
        variables,
        update(cache, { data: { questionImportFile } }) {
          cache.modify({
            id: variables.chapterId
              ? `Chapter:${variables.chapterId}`
              : `Course:${variables.courseId}`,
            fields: {
              questions: (existingQuestionsRef = []) => [
                ...existingQuestionsRef,
                ...questionImportFile.map((question) =>
                  cache.writeFragment({
                    data: question,
                    fragment: QUESTION_DATA,
                  }),
                ),
              ],
            },
          });
        },
      }),
    [mutation],
  );
}

export const QUESTION_IMPORT_ANKI = gql`
  mutation QuestionImportAnki($fileName: String!, $filePath: String!) {
    questionImportAnki(fileName: $fileName, filePath: $filePath)
  }
`;

export function useMutationQuestionImportAnki() {
  const [mutation] = useMutation(QUESTION_IMPORT_ANKI);
  return useCallback(
    (variables) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}

export const QUESTION_IMPORT_LIST = gql`
  mutation QuestionImportList(
    $courseId: ID!
    $chapterId: ID
    $type: String!
    $content: String!
    $columnSeparator: String!
    $rowSeparator: String!
    $switchOrder: Boolean!
  ) {
    questionImportList(
      courseId: $courseId
      chapterId: $chapterId
      type: $type
      content: $content
      columnSeparator: $columnSeparator
      rowSeparator: $rowSeparator
      switchOrder: $switchOrder
    ) {
      ...QuestionData
    }
  }
  ${QUESTION_DATA}
`;

export function useMutationQuestionImportList() {
  const [mutation] = useMutation(QUESTION_IMPORT_LIST);
  return useCallback(
    (variables) =>
      mutation({
        variables,
        update(cache, { data: { questionImportList } }) {
          cache.modify({
            id: variables.chapterId
              ? `Chapter:${variables.chapterId}`
              : `Course:${variables.courseId}`,
            fields: {
              questions: (existingQuestionsRef = []) => [
                ...existingQuestionsRef,
                ...questionImportList.map((question) =>
                  cache.writeFragment({
                    data: question,
                    fragment: QUESTION_DATA,
                  }),
                ),
              ],
            },
          });
        },
      }),
    [mutation],
  );
}

export const QUESTION_IMPORT_WOOCLAP = gql`
  mutation QuestionImportWooclap(
    $courseId: ID!
    $chapterId: ID
    $payload: JSON!
  ) {
    questionImportWooclap(
      courseId: $courseId
      chapterId: $chapterId
      payload: $payload
    ) {
      ...QuestionData
    }
  }
  ${QUESTION_DATA}
`;

export function useMutationQuestionImportWooclap() {
  const [mutation] = useMutation(QUESTION_IMPORT_WOOCLAP);
  return useCallback(
    (variables) =>
      mutation({
        variables,
        update(cache, { data: { questionImportWooclap } }) {
          cache.modify({
            id: variables.chapterId
              ? `Chapter:${variables.chapterId}`
              : `Course:${variables.courseId}`,
            fields: {
              questions: (existingQuestionsRef = []) => [
                ...existingQuestionsRef,
                ...questionImportWooclap.map((question) =>
                  cache.writeFragment({
                    data: question,
                    fragment: QUESTION_DATA,
                  }),
                ),
              ],
            },
          });
        },
      }),
    [mutation],
  );
}

export const QUESTION_IMPORT_QUIZWIZARD = gql`
  mutation QuestionImportQuizWizard(
    $courseId: ID!
    $chapterId: ID
    $payload: JSON!
  ) {
    questionImportQuizWizard(
      courseId: $courseId
      chapterId: $chapterId
      payload: $payload
    ) {
      ...QuestionData
    }
  }
  ${QUESTION_DATA}
`;

export function useMutationQuestionImportQuizWizard() {
  const [mutation] = useMutation(QUESTION_IMPORT_QUIZWIZARD);
  return useCallback(
    (variables) =>
      mutation({
        variables,
        update(cache, { data: { questionImportQuizWizard } }) {
          cache.modify({
            id: variables.chapterId
              ? `Chapter:${variables.chapterId}`
              : `Course:${variables.courseId}`,
            fields: {
              questions: (existingQuestionsRef = []) => [
                ...existingQuestionsRef,
                ...questionImportQuizWizard.map((question) =>
                  cache.writeFragment({
                    data: question,
                    fragment: QUESTION_DATA,
                  }),
                ),
              ],
            },
          });
        },
      }),
    [mutation],
  );
}

export const QUESTION_GENERATE_FEEDBACK = gql`
  mutation QuestionGenerateFeedback($type: String!, $content: JSON!) {
    questionGenerateFeedback(type: $type, content: $content)
  }
`;

export function useMutationQuestionGenerateFeedback() {
  const [mutation] = useMutation(QUESTION_GENERATE_FEEDBACK);
  return useCallback(
    (variables) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}
