import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import CommentContent from 'components/Shared/Comment/CommentContent';
import CommentHeader from 'components/Shared/Comment/CommentHeader';

import styles from './CommentReply.module.scss';

const cx = classNames.bind(styles);

const CommentReply = ({
  className,
  commentReply,
  handleDelete,
  handleEdit,
  handleEditToggle,
  isEditing,
  validationSchema,
}) => (
  <div className={className}>
    <CommentHeader
      account={commentReply.account}
      className={cx('header')}
      comment={commentReply}
      handleEdit={handleEditToggle}
      handleDelete={handleDelete}
    />
    <CommentContent
      comment={null}
      content={commentReply.content}
      handleCancel={handleEditToggle}
      handleSave={handleEdit}
      isEditing={isEditing}
      validationSchema={validationSchema}
    />
  </div>
);

CommentReply.propTypes = {
  className: PropTypes.string,
  commentReply: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleEditToggle: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  validationSchema: PropTypes.object.isRequired,
};

CommentReply.defaultProps = {
  className: null,
};

export default CommentReply;
