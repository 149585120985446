import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Icon from 'uikit/Icon';

import styles from './Answer.module.scss';

const cx = classNames.bind(styles);

const Answer = ({ answer, index, isForcingCorrect, isSelected = false }) => (
  <div
    className={cx('answer', {
      aligned: isForcingCorrect,
      selected: isSelected,
    })}
    key={index}
  >
    <div
      className={cx('answer-index', {
        isCorrect: answer?.isCorrect,
      })}
    >
      {index + 1}
    </div>
    <div className={cx('corrections')}>
      {!answer?.isCorrect && (
        <div className={cx('correction-wrong-container')}>
          <div className={cx('correction', 'wrong')}>
            {answer.user || '___'}
          </div>
          <Icon
            className={cx('icon-large')}
            name={isForcingCorrect ? 'chevron-right' : 'chevron-down'}
          />
          <Icon className={cx('icon-small')} name={'chevron-down'} />
        </div>
      )}
      <div className={cx('correction', 'correct')}>
        {answer.isForced
          ? answer.correct.join(',') || '___'
          : answer?.exact || answer?.correct.join(',') || '___'}
      </div>
    </div>
  </div>
);

Answer.propTypes = {
  answer: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  isForcingCorrect: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool,
};

export default Answer;
