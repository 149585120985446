import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Icon from '../Icon';
import Tooltip from '../Tooltip';
import styles from './Button.module.scss';

const cx = classNames.bind(styles);

const Button = React.forwardRef(
  (
    {
      as: Component,
      children,
      className,
      icon,
      intent,
      isDisabled,
      isLoading,
      size,
      tooltip,
      variant,
      ...props
    },
    ref,
  ) => {
    const additionalProps = {};
    if (Component === 'button') {
      additionalProps.disabled = isDisabled;
      additionalProps.type = 'button';
    }
    if (tooltip) {
      additionalProps['aria-label'] = tooltip;
    }
    const btn = (
      <Component
        className={cx(
          'container',
          `intent-${intent}`,
          `size-${size}`,
          {
            hasIcon: !!icon,
            isDisabled,
            isEmpty: icon && !children,
            isLoading,
          },
          intent !== 'ia-light' && className,
        )}
        ref={ref}
        {...additionalProps}
        {...props}
      >
        {isLoading && (
          <span className={cx('spinner')}>
            <Icon name="spinner-third" spin />
          </span>
        )}
        {icon && (
          <p className={cx('icon')}>
            {typeof icon === 'string' ? (
              <Icon name={icon} variant={variant} />
            ) : (
              icon
            )}
          </p>
        )}
        {children && <span className={cx('content')}>{children}</span>}
      </Component>
    );
    if (tooltip) {
      return <Tooltip tooltip={tooltip}>{btn}</Tooltip>;
    }
    if (intent === 'ia-light') {
      return <div className={cx('ia-button-container', className)}>{btn}</div>;
    }
    return btn;
  },
);

Button.propTypes = {
  as: PropTypes.oneOfType([PropTypes.elementType, PropTypes.string]),
  children: PropTypes.node,
  className: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  intent: PropTypes.oneOf([
    'default',
    'primary',
    'white',
    'outline',
    'danger',
    'wrong',
    'correct',
    'dark',
    'ia',
    'ia-light',
    'gray',
  ]),
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  size: PropTypes.oneOf(['default', 'small', 'large']),
  tooltip: PropTypes.string,
  variant: PropTypes.oneOf(['solid', 'regular', 'light', 'brand', 'duotone']),
};

Button.defaultProps = {
  as: 'button',
  children: undefined,
  className: undefined,
  icon: undefined,
  intent: 'default',
  isDisabled: false,
  isLoading: false,
  size: 'default',
  tooltip: undefined,
  variant: undefined,
};

export default Button;
