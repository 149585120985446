import froalaeditor from 'froala-editor';

froalaeditor.POPUP_TEMPLATES['opsPlugin.popup'] = '[_CUSTOM_LAYER_]';

froalaeditor.PLUGINS.opsPlugin = (editor) => {
  function initPopup() {
    const render = (str) =>
      window.katex
        ? window.katex.renderToString(str, {
            throwOnError: false,
          })
        : str;
    const template = {
      custom_layer: `
        <div class="fr-popup fr-active">
          <div class="fr-icon-container fr-sc-container">
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addOps" data-param1="+">${render(
              '+',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addOps" data-param1="-">${render(
              '-',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addOps" data-param1="⋅">${render(
              '⋅',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addOps" data-param1="×">${render(
              '×',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addOps" data-param1="/">${render(
              '/',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addOps" data-param1="÷">${render(
              '÷',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addOps" data-param1="⊕">${render(
              '⊕',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addOps" data-param1="⊗">${render(
              '⊗',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addOps" data-param1="⊙">${render(
              '⊙',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addOps" data-param1="∀">${render(
              '∀',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addOps" data-param1="∃">${render(
              '∃',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addOps" data-param1="±">${render(
              '±',
            )}</span>
          </div>
        </div>`,
    };
    return editor.popups.create('opsPlugin.popup', template);
  }
  function showPopup() {
    let $popup = editor.popups.get('opsPlugin.popup');
    if (!$popup) {
      $popup = initPopup();
    }
    editor.popups.setContainer('opsPlugin.popup', editor.$tb);
    const $btn = editor.$tb.find('.fr-command[data-cmd="insertOps"]');
    const { left } = $btn.offset();
    const top =
      $btn.offset().top +
      (editor.opts.toolbarBottom ? 10 : $btn.outerHeight() - 10);
    editor.popups.show('opsPlugin.popup', left, top, $btn.outerHeight());
  }
  function hidePopup() {
    editor.popups.hide('opsPlugin.popup');
  }
  return {
    showPopup,
    hidePopup,
  };
};

froalaeditor.DefineIcon('opsIcon', {
  FA5NAME: 'calculator-alt',
  template: 'font_awesome_5',
});

froalaeditor.RegisterCommand('insertOps', {
  title: 'Opérateurs',
  icon: 'opsIcon',
  showOnMobile: true,
  undo: false,
  focus: false,
  callback: function callback() {
    this.opsPlugin.showPopup();
  },
});

froalaeditor.RegisterCommand('addOps', {
  undo: true,
  focus: true,
  callback: function callback(cmd, val) {
    this.html.insert(` ${val} `);
    this.opsPlugin.hidePopup();
  },
});
