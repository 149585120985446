import classNames from 'classnames/bind';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import Icon from 'uikit/Icon';

import styles from './CheckBoxTrueFalse.module.scss';

const cx = classNames.bind(styles);

const CheckBoxTrueFalse = React.forwardRef(
  (
    {
      children,
      className,
      isDisabled,
      label,
      name,
      shouldHideError,
      theme,
      ...props
    },
    ref,
  ) => {
    const [{ value, ...field }, meta, { setValue }] = useField(name);
    return (
      <div className={cx('container', `theme-${theme}`)}>
        <div
          className={cx(
            'buttons',
            {
              isDisabled,
              hasError: !shouldHideError && meta.touched && meta.error,
            },
            className,
          )}
          role="group"
          aria-labelledby={`group-${name}-label`}
          ref={ref}
        >
          <button
            className={cx('button', 'left', {
              isChecked: value === true,
            })}
            disabled={isDisabled}
            onClick={() => setValue(true)}
            type="button"
            {...field}
            {...props}
          >
            <Icon name="check" />
          </button>
          <button
            className={cx('button', 'right', {
              isChecked: value === false,
            })}
            disabled={isDisabled}
            onClick={() => setValue(false)}
            type="button"
            {...field}
            {...props}
          >
            <Icon name="times" />
          </button>
        </div>
        <div className={cx('content')} id={`radio-${name}-label`}>
          {label && <p className={cx('label')}>{label}</p>}
          {!shouldHideError && meta.touched && meta.error && (
            <p
              className={cx('error-message')}
              id={`error-${name}`}
              role="alert"
            >
              {meta.error}
            </p>
          )}
        </div>
      </div>
    );
  },
);

CheckBoxTrueFalse.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.node,
  shouldHideError: PropTypes.bool,
  theme: PropTypes.string.isRequired,
};

CheckBoxTrueFalse.defaultProps = {
  children: undefined,
  className: undefined,
  isDisabled: false,
  label: undefined,
  shouldHideError: false,
};

export default CheckBoxTrueFalse;
