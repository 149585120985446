import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'uikit/Button';
import FormattedText from 'uikit/FormattedText';
import Icon from 'uikit/Icon';
import Modal from 'uikit/Modal';

import styles from './CourseResetParticipants.module.scss';

const cx = classNames.bind(styles);

const CourseResetParticipants = ({
  onCourseDuplicate,
  onCourseResetParticipants,
  onModalClose,
  onModalOpen,
  isResetLoading,
  isDuplicationLoading,
  isModalOpen,
  status = undefined,
  t,
}) => (
  <>
    <Button
      icon="trash-alt"
      intent="white"
      onClick={onModalOpen}
      tooltip={t(
        'Course/Teacher/Participants/Students/CourseResetParticipants/tooltip',
      )}
    />
    <Modal isOpen={isModalOpen} onExit={onModalClose}>
      <div className={cx('container')}>
        <Button
          aria-label={t(
            'Course/Teacher/Participants/Students/CourseResetParticipants/aria-close',
          )}
          className={cx('close')}
          icon="times"
          onClick={onModalClose}
        />
        <h1 className={cx('title', 'centered-title')}>
          {t(
            'Course/Teacher/Participants/Students/CourseResetParticipants/title',
          )}
        </h1>
        <FormattedText className={cx('explanation')}>
          {t(
            'Course/Teacher/Participants/Students/CourseResetParticipants/subtitle',
          )}
        </FormattedText>
        <div className={cx('information-container')}>
          <Icon
            className={cx('text-gradient-blue', 'information-icon')}
            name="info-circle"
          />
          <FormattedText className={cx('information')}>
            {t(
              'Course/Teacher/Participants/Students/CourseResetParticipants/information',
            )}
          </FormattedText>
        </div>
        {status && (
          <p className={cx('error')} role="alert">
            {t(
              'Course/Teacher/Participants/Students/CourseResetParticipants/student-delete-error',
            )}
          </p>
        )}
        <div className={cx('actions')}>
          <Button className={cx('cancel-button')} onClick={onModalClose}>
            {t(
              'Course/Teacher/Participants/Students/CourseResetParticipants/cancel',
            )}
          </Button>
          <div className={cx('actions-right')}>
            <Button
              isLoading={isDuplicationLoading}
              onClick={onCourseDuplicate}
            >
              {t(
                'Course/Teacher/Participants/Students/CourseResetParticipants/course-duplicate',
              )}
            </Button>
            <Button
              intent="danger"
              isLoading={isResetLoading}
              onClick={onCourseResetParticipants}
            >
              {t(
                'Course/Teacher/Participants/Students/CourseResetParticipants/confirm-course-reset',
              )}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  </>
);

CourseResetParticipants.propTypes = {
  onCourseDuplicate: PropTypes.func.isRequired,
  onCourseResetParticipants: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
  onModalOpen: PropTypes.func.isRequired,
  isResetLoading: PropTypes.bool.isRequired,
  isDuplicationLoading: PropTypes.bool.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  status: PropTypes.string,
  t: PropTypes.func.isRequired,
};

export default CourseResetParticipants;
