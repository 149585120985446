import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import CleanedText from 'uikit/CleanedText';

import ImageContainer from '../Shared/ImageContainer';
import styles from './Aggregate.module.scss';

const cx = classNames.bind(styles);

const Aggregate = ({ aggregation, className, question, questionInfo, t }) => (
  <div className={cx('container', className)}>
    <div className={cx('background')}>
      <div className={cx('header')}>
        <div className={cx('title-container')}>
          <p className={cx('icon', `background-${questionInfo.color}`)}>
            <img alt="" src={questionInfo.icon} />
          </p>
          <CleanedText as="p" className={cx('title')}>
            {question?.title}
          </CleanedText>
        </div>
        <p
          className={cx('answers-numbers', {
            'answers-numbers-wrong': aggregation.nCorrectAnswers === 0,
          })}
        >
          <span
            className={cx('correct-answers', {
              isEmpty: aggregation.nCorrectAnswers === 0,
            })}
          >
            {t('Questions/FindOnImage/Aggregate/correct-answers', {
              count: aggregation.nCorrectAnswers,
            })}
          </span>
          <span
            className={cx('total-answers', {
              'total-answers-wrong': aggregation.nCorrectAnswers === 0,
            })}
          >
            {` / ${aggregation.nTotalAnswers}`}
          </span>
        </p>
      </div>
      <ImageContainer
        imageSrc={question.content.image}
        isReport
        solution={question.content}
        userPins={aggregation.aggregatedAnswers}
      />
    </div>
  </div>
);

Aggregate.propTypes = {
  aggregation: PropTypes.object.isRequired,
  className: PropTypes.string,
  question: PropTypes.object.isRequired,
  questionInfo: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

Aggregate.defaultProps = {
  className: undefined,
};

export default Aggregate;
