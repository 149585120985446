import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Tabs from 'uikit/Tabs';
import TEMPLATES from '../data/templates';

import THEMES from '../data/themes';
import styles from './DesignToolbar.module.scss';

const cx = classNames.bind(styles);

const DesignToolbar = ({
  className,
  handleTemplateChange,
  handleThemeChange,
  isDisplayedInColumn,
  selectedThemeIndex,
  t,
}) => (
  <div className={cx('container', className, { column: isDisplayedInColumn })}>
    <Tabs className={cx('tabs')}>
      <Tabs.Tab
        label={t('Questions/Shared/SlideBuilder/DesignToolbar/template-title')}
      >
        <div className={cx('templates', { column: isDisplayedInColumn })}>
          {TEMPLATES.map((template, index) => (
            <button
              aria-label={t(
                'Questions/Shared/SlideBuilder/DesignToolbar/template',
                { index },
              )}
              className={cx('button-container')}
              // eslint-disable-next-line react/no-array-index-key
              key={`template${index}`}
              onClick={() => handleTemplateChange(index)}
              type="button"
            >
              <img
                alt=""
                className={cx('template-illustration', {
                  'illustration-background': !template.illustration,
                })}
                src={template.backgroundImage || template.illustration}
              />
            </button>
          ))}
        </div>
      </Tabs.Tab>
      <Tabs.Tab
        label={t('Questions/Shared/SlideBuilder/DesignToolbar/theme-title')}
      >
        <div className={cx('themes', { column: isDisplayedInColumn })}>
          {THEMES.map((theme, index) => (
            <button
              className={cx('theme', {
                selected: selectedThemeIndex === index,
              })}
              // eslint-disable-next-line react/no-array-index-key
              key={`theme${index}`}
              onClick={() => handleThemeChange(index)}
              type="button"
            >
              <div>
                <div className={cx('theme-colors')}>
                  <div
                    className={cx('theme-color')}
                    style={{ background: theme.titleProps.fill, zIndex: 4 }}
                  />
                  <div
                    className={cx('theme-color')}
                    style={{ background: theme.subtitleProps.fill, zIndex: 3 }}
                  />
                  <div
                    className={cx('theme-color')}
                    style={{ background: theme.textProps.fill, zIndex: 2 }}
                  />
                  <div
                    className={cx('theme-color')}
                    style={{ background: theme.backgroundColor, zIndex: 1 }}
                  />
                </div>
                <p
                  className={cx('theme-text')}
                  style={{
                    fontFamily: theme.titleProps.fontFamily,
                    fontWeight: theme.titleProps.fontWeight,
                    fontSize: theme.titleProps.fontSize / 2,
                  }}
                >
                  {theme.titleProps.fontName}
                </p>
                <p
                  className={cx('theme-text')}
                  style={{
                    fontFamily: theme.subtitleProps.fontFamily,
                    fontWeight: theme.subtitleProps.fontWeight,
                    fontSize: theme.subtitleProps.fontSize / 2,
                  }}
                >
                  {theme.subtitleProps.fontName}
                </p>
                <p
                  className={cx('theme-text')}
                  style={{
                    fontFamily: theme.textProps.fontFamily,
                    fontWeight: theme.textProps.fontWeight,
                    fontSize: theme.textProps.fontSize / 2,
                  }}
                >
                  {theme.textProps.fontName}
                </p>
              </div>
            </button>
          ))}
        </div>
      </Tabs.Tab>
    </Tabs>
  </div>
);

DesignToolbar.propTypes = {
  className: PropTypes.string,
  handleTemplateChange: PropTypes.func.isRequired,
  handleThemeChange: PropTypes.func.isRequired,
  isDisplayedInColumn: PropTypes.bool.isRequired,
  selectedThemeIndex: PropTypes.number,
  t: PropTypes.func.isRequired,
};

DesignToolbar.defaultProps = {
  className: undefined,
  selectedThemeIndex: undefined,
};

export default DesignToolbar;
