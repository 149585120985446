import froalaeditor from 'froala-editor';
import BlankWithChoicesIcon from '../images/blank-with-choices.svg';

froalaeditor.POPUP_TEMPLATES['blankWithChoices.insert'] = '[_CUSTOM_LAYER_]';

froalaeditor.PLUGINS.blankWithChoices = (editor) => {
  function insert() {
    editor.html.insert(
      editor.selection.text()
        ? `{{${editor.selection.text()}}}`
        : '{{correct,wrong,wrong}}',
    );
    editor.undo.saveStep();
  }
  return {
    insert,
  };
};

froalaeditor.DefineIcon('insertBlankWithChoices', {
  SRC: BlankWithChoicesIcon,
  ALT: 'Insérer un trou avec propositions',
  template: 'image',
});

froalaeditor.RegisterCommand('insertBlankWithChoices', {
  title: 'Insérer un trou avec propositions',
  callback() {
    this.blankWithChoices.insert();
  },
});
