import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Options from 'questions/Shared/Options';
import Tags from 'questions/Shared/Tags';
import Button from 'uikit/Button';
import CheckBox from 'uikit/CheckBox';
import FeedbackWithIA from 'questions/Shared/FeedbackWithIA';
import Icon from 'uikit/Icon';
import InputError from 'uikit/InputError';
import InputRich from 'uikit/InputRich';
import Switch from 'uikit/Switch';
import Tooltip from 'uikit/Tooltip';

import styles from './Editor.module.scss';

const cx = classNames.bind(styles);

const Editor = ({
  addFeedbacks,
  choices,
  className,
  handleChoiceAdd,
  handleChoiceComplete,
  handleChoiceRemove,
  handleFeedbacksToggle,
  hasAIFeedbackAccess,
  t,
  validationSchema,
}) => (
  <div className={cx('container', className)}>
    <p className={cx('label')}>{t('Questions/MCQ/Editor/choices')}</p>
    <div className={cx('field')}>
      <InputError name="choices" />
      {[...choices, ''].map((_, index) => (
        //  eslint-disable-next-line react/no-array-index-key
        <div key={index}>
          <div className={cx('list-element')}>
            {index === choices.length ? (
              <Button
                className={cx('choice-add')}
                icon="plus"
                intent="outline"
                onClick={handleChoiceAdd}
                size="small"
                tooltip={t('Questions/MCQ/Editor/choice-add')}
              />
            ) : (
              <CheckBox
                data-testid={`choices.${index}.answer`}
                name={`choices.${index}.answer`}
              />
            )}
            {index === choices.length ? (
              <InputRich
                className={cx('choice-complete')}
                name="tmp"
                onChange={handleChoiceComplete}
              />
            ) : (
              <InputRich
                className={cx('fill')}
                data-testid={`choices.${index}.title`}
                name={`choices.${index}.title`}
                placeholder={t(
                  `Questions/MCQ/Editor/choice${index + 1}-placeholder`,
                )}
                shouldHideError
              />
            )}
            {index !== choices.length && (
              <Button
                icon="trash-alt"
                intent="outline"
                onClick={() => handleChoiceRemove(index)}
                tooltip={t('Questions/MCQ/Editor/delete-choice')}
              />
            )}
          </div>
          {addFeedbacks && (
            <InputRich
              className={cx('choice-feedback')}
              name={`choices.${index}.feedback`}
              placeholder={t('Questions/MCQ/choice-feedback-label')}
            />
          )}
        </div>
      ))}
    </div>
    {hasAIFeedbackAccess ? (
      <FeedbackWithIA type="MCQ" validationSchema={validationSchema} />
    ) : (
      <InputRich
        helper={
          <Tooltip tooltip={t('Questions/MCQ/Editor/feedback-helper')}>
            <Icon name="info-circle" />
          </Tooltip>
        }
        label={t('Questions/MCQ/feedback-label')}
        name="feedback"
      />
    )}
    <Options className={cx('options')}>
      <Switch
        className={cx('field')}
        label={t('Questions/MCQ/Editor/randomize-label')}
        name="randomize"
      />
      <Switch
        className={cx('field')}
        label={t('Questions/MCQ/Editor/multiple-choices-label')}
        name="multipleChoices"
      />
      <Switch
        className={cx('field')}
        label={t('Questions/MCQ/Editor/feedbacks')}
        name="feedbacks"
        onClick={handleFeedbacksToggle}
      />
      <Tags />
    </Options>
  </div>
);

Editor.propTypes = {
  addFeedbacks: PropTypes.bool,
  choices: PropTypes.arrayOf(PropTypes.object).isRequired,
  className: PropTypes.string,
  handleChoiceAdd: PropTypes.func.isRequired,
  handleChoiceComplete: PropTypes.func.isRequired,
  handleChoiceRemove: PropTypes.func.isRequired,
  handleFeedbacksToggle: PropTypes.func.isRequired,
  hasAIFeedbackAccess: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  validationSchema: PropTypes.object.isRequired,
};

Editor.defaultProps = {
  className: undefined,
  addFeedbacks: undefined,
};

export default Editor;
