import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import Button from 'uikit/Button';
import Icon from 'uikit/Icon';
import Modal from 'uikit/Modal';

import imagePlay from './images/play.svg';
import styles from './GamePlay.module.scss';

const cx = classNames.bind(styles);

const GamePlay = ({
  className,
  course,
  games,
  handleModalClose,
  handleModalOpen,
  isModalOpen,
  t,
  ...props
}) => (
  <>
    <button
      className={cx(className, 'play-button')}
      onClick={handleModalOpen}
      type="button"
      {...props}
    >
      {t('Shared/GamePlay/play-game')}
      <Icon className={cx('chevron')} name="chevron-right" />
    </button>
    <Modal isOpen={isModalOpen} onExit={handleModalClose}>
      <div className={cx('modal')}>
        <Button
          aria-label={t('Shared/GamePlay/aria-close')}
          className={cx('close')}
          icon="times"
          onClick={handleModalClose}
        />
        <img
          alt=""
          aria-hidden="true"
          className={cx('image')}
          src={imagePlay}
        />
        <h1 className={cx('title')}>{t('Shared/GamePlay/title')}</h1>
        {games.map((game) => (
          <Button
            as={Link}
            className={cx('action')}
            icon="gamepad"
            intent="primary"
            key={game.id}
            size="large"
            to={`/play/${game.id}`}
          >
            {game.title}
          </Button>
        ))}
      </div>
    </Modal>
  </>
);

GamePlay.propTypes = {
  className: PropTypes.string,
  course: PropTypes.object.isRequired,
  games: PropTypes.arrayOf(PropTypes.object),
  handleModalClose: PropTypes.func.isRequired,
  handleModalOpen: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

GamePlay.defaultProps = {
  className: undefined,
  games: [],
};

export default GamePlay;
