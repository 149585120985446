import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import {
  getDenormalizedPinPosition,
  getDenormalizedZonePosition,
} from 'questions/Shared/utils/images';
import LegendPin from './LegendPin';
import Zone from './Zone';
import styles from './ImageContainer.module.scss';

const cx = classNames.bind(styles);

const ImageContainer = ({
  className,
  handleImageLoaded,
  image,
  imageRef,
  imageSrc,
  legends,
  wrapperRef,
  zones,
}) => (
  <>
    <div className={cx('container', className)} ref={wrapperRef}>
      <img
        alt="img"
        className={cx('image')}
        draggable="false"
        onLoad={handleImageLoaded}
        ref={imageRef}
        src={imageSrc}
        style={{
          width: image.width,
          height: image.height,
        }}
      />
      {image.diagonal &&
        zones &&
        zones.map((zone, index) => (
          <Zone
            image={image}
            imageSrc={imageSrc}
            key={`zone-${index}`}
            sizeData={getDenormalizedZonePosition(zone, image.diagonal)}
          />
        ))}
      {image.diagonal &&
        legends &&
        legends.map((legend, index) => (
          <LegendPin
            index={index}
            isCorrect={legend.isCorrect}
            key={`legend-${index}`}
            positionData={getDenormalizedPinPosition(legend, image.diagonal)}
          />
        ))}
    </div>
  </>
);

ImageContainer.propTypes = {
  className: PropTypes.string,
  handleImageLoaded: PropTypes.func.isRequired,
  image: PropTypes.object.isRequired,
  imageRef: PropTypes.object.isRequired,
  imageSrc: PropTypes.string.isRequired,
  legends: PropTypes.arrayOf(PropTypes.object).isRequired,
  wrapperRef: PropTypes.object.isRequired,
  zones: PropTypes.arrayOf(PropTypes.object).isRequired,
};

ImageContainer.defaultProps = {
  className: undefined,
};

export default ImageContainer;
