import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

export const LMS_GRADE_SYNCHRONIZE = gql`
  mutation LMSGradeSynchronize($courseId: ID!) {
    LMSGradeSynchronize(courseId: $courseId)
  }
`;

export function useMutationLMSGradeSynchronize() {
  const [mutation] = useMutation(LMS_GRADE_SYNCHRONIZE);
  return useCallback(
    (variables) =>
      mutation({
        variables,
        optimisticResponse: {
          __typename: 'Mutation',
          LMSGradeSynchronize: true,
        },
      }),
    [mutation],
  );
}
