import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import LANGUAGES from 'constants/languages';
import Icon from 'uikit/Icon';
import Menu, { MenuItem } from 'uikit/Menu';

import MenuTooltip from '../MenuContainer/MenuTooltip';
import styles from './LanguageSelector.module.scss';

const cx = classNames.bind(styles);

const LanguageSelector = ({
  className = null,
  handleLanguageChange,
  intent = 'default',
  isMenuExpanded,
  language,
  t,
  userLanguage = null,
}) => (
  <div className={cx('container', { large: intent !== 'square' }, className)}>
    <Menu
      placement={intent === 'square' ? 'bottom' : 'right'}
      toggle={
        intent === 'square' ? (
          <button
            aria-label={t('Shared/LanguageSelector/aria-change-language')}
            className={cx('toggle-square')}
            type="button"
          >
            <span className={cx('toggle-text')}>{language.toUpperCase()}</span>
            <Icon className={cx('toggle-icon')} name="chevron-down" />
          </button>
        ) : (
          <button
            aria-label={t('Shared/LanguageSelector/aria-change-language')}
            className={cx('toggle-default', { small: !isMenuExpanded })}
            type="button"
          >
            <MenuTooltip isInMenu={true} tooltip={t(userLanguage)}>
              <Icon className={cx('toggle-default-icon')} name="language" />
            </MenuTooltip>
            <span
              className={cx('toggle-default-text', {
                hidden: !isMenuExpanded,
              })}
            >
              {t(userLanguage)}
            </span>
          </button>
        )
      }
    >
      {LANGUAGES.map(({ key, translation }) => (
        <MenuItem
          isActive={language === key}
          key={`${key}-translation`}
          onClick={() => handleLanguageChange(key)}
        >
          {t(translation)}
        </MenuItem>
      ))}
    </Menu>
  </div>
);

LanguageSelector.propTypes = {
  className: PropTypes.string,
  handleLanguageChange: PropTypes.func.isRequired,
  intent: PropTypes.oneOf(['default', 'square']),
  isMenuExpanded: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  userLanguage: PropTypes.string,
};

export default LanguageSelector;
 