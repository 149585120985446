/**
 * get the number of decimal of a number
 * @param {number} a the number
 * @returns {number} the number of decimals
 */
export function getNbDecimals(a) {
  const match = a.toString().match(/(?:\.|,)(\d*)$/);
  return (match && match[1].length) || 0;
}

/**
 * Because we all know 1.6 + 0.1 === 1.7000000000000002 in js
 * To avoid problems with floats, do the following
 * Convert the float to an int: to do this, we just cast it to string then remove the decimal points, and parseInt.
 * This is better than multiplying by powers of 10 otherwise, we might have an error during multiplication
 * Divide by the corresponding power of ten (depending on the number of decimals)
 * @param {number} float the float number
 * @param {number} int the integer to add
 * @returns {number} the addition
 */
export function addIntToFloat(float, int) {
  const nbDecimalsFloat = getNbDecimals(float);
  const q = 10 ** nbDecimalsFloat;
  return Math.round(float * q + int) / q;
}
