import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import GroupCard from './GroupCard';
import styles from './FinderGAR.module.scss';

const cx = classNames.bind(styles);

const FinderGAR = ({ className, course, groups }) => (
  <div className={cx(className)}>
    {groups.map((group) => (
      <GroupCard className={cx('group')} course={course} group={group} />
    ))}
  </div>
);

FinderGAR.propTypes = {
  className: PropTypes.string,
  course: PropTypes.object.isRequired,
  groups: PropTypes.arrayOf(PropTypes.object).isRequired,
};

FinderGAR.defaultProps = {
  className: undefined,
};

export default FinderGAR;
