import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Icon from 'uikit/Icon';

import styles from './Zone.module.scss';

const cx = classNames.bind(styles);

const Zone = ({
  handleDelete,
  handleMouseMove,
  handleStartGrab,
  handleStartResize,
  image,
  imageSrc,
  isActive,
  sizeData,
}) => (
  <button
    className={cx('zone', 'shape-rectangle', {
      isActive,
    })}
    onMouseDown={handleStartGrab}
    onTouchStart={handleStartGrab}
    onMouseMove={handleMouseMove}
    onTouchMove={handleMouseMove}
    style={{
      width: `${sizeData.width}px`,
      height: `${sizeData.height}px`,
      left: `${sizeData.left}px`,
      top: `${sizeData.top}px`,
    }}
    type="button"
  >
    <div
      style={{
        width: `${sizeData.width}px`,
        height: `${sizeData.height}px`,
        backgroundImage: `url(${imageSrc})`,
        backgroundSize: `${image.width}px ${image.height}px`,
        backgroundPosition: `-${sizeData.left}px -${sizeData.top}px`,
        filter: `blur(20px)`,
      }}
    />
    {isActive && (
      <>
        <button
          className={cx('resize')}
          onMouseDown={handleStartResize}
          onTouchStart={handleStartResize}
          onMouseMove={handleMouseMove}
          onTouchMove={handleMouseMove}
          type="button"
        >
          <Icon name="arrows-alt" />
        </button>
        <button className={cx('delete')} onClick={handleDelete} type="button">
          <Icon name="trash-alt" variant="regular" />
        </button>
      </>
    )}
  </button>
);

Zone.propTypes = {
  handleDelete: PropTypes.func.isRequired,
  handleMouseMove: PropTypes.func.isRequired,
  handleStartGrab: PropTypes.func.isRequired,
  handleStartResize: PropTypes.func.isRequired,
  image: PropTypes.object.isRequired,
  imageSrc: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  sizeData: PropTypes.object.isRequired,
};

export default Zone;
