import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Joyride, { STATUS } from 'react-joyride';
import { useLocation } from 'react-router';

import { SCREEN_WIDTH } from 'constants/screenWidth';
import {
  mutationEditorClear,
  mutationEditorQuestionCreate,
} from 'gql/mutations/editor';
import { useMutationMeTag } from 'gql/mutations/me';
import { useQueryMe } from 'gql/queries/me';
import history from 'lib/history';
import { trackEvent, reportError } from 'lib/tracking';

import IMAGE_QUESTION from './images/question.svg';
import IMAGE_SHARE from './images/share.svg';
import IMAGE_STUDY from './images/study.svg';
import TourTooltip from './TourTooltip/TourTooltip';

const REACT_JOYRIDE_SPOTLIGHT = 'react-joyride__spotlight';

const TourSteps = ({ course }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'App/Onboarding/NewTour/TourSteps',
  });

  const location = useLocation();

  const { data } = useQueryMe();

  const mutationMeTag = useMutationMeTag();

  // This variable is linked to the "run" prop of joyride.
  // It it used to trigger the product tour
  // It is set to false when the user clicks on the spotlight of the first step
  // Then we can manage the appearance of the second step of the tour
  // regarding if we want it directly after the creation of the first question,
  // or after the toaster saying that the AI questions have been created successfully
  const [run, setRun] = useState(true);

  const role = data?.me.role ?? 'student';

  const screenWidth = window.innerWidth;

  const firstStepTarget =
    screenWidth < SCREEN_WIDTH.small
      ? '#create-button-small'
      : screenWidth > SCREEN_WIDTH.medium
      ? '#create-question-button-large'
      : '#create-button-medium';

  const secondStepTarget =
    role === 'student'
      ? '#start-study-session-button'
      : '#open-share-modal-button';

  const isCourseEmpty = !course.questions.length && !course.chapters.length;

  const isFirstStepOpen =
    data?.me.tags.includes('first-course-created') &&
    !data?.me.tags.includes('tour:2');

  const isSecondStepOpen =
    data?.me.tags.includes('tour:2') &&
    !data?.me.tags.includes('tour:3') &&
    !isCourseEmpty;

  const currentStep = isFirstStepOpen ? 1 : isSecondStepOpen ? 2 : null;

  const steps = [
    {
      content: (
        <>
          <img alt="" aria-hidden="true" src={IMAGE_QUESTION} />
          <p id="tour-step-1-description">{t(`create-question-${role}`)}</p>
        </>
      ),
      placement: 'left',
      target: firstStepTarget,
    },
    {
      content: (
        <>
          <img
            alt=""
            aria-hidden="true"
            src={role === 'student' ? IMAGE_STUDY : IMAGE_SHARE}
          />
          <p id="tour-step-2-description">
            {t(`${role === 'student' ? 'study' : 'share'}`)}
          </p>
        </>
      ),
      placement: 'bottom',
      target: secondStepTarget,
    },
  ];

  // Trigger actions when the user click on the spotlight of the product tour
  useEffect(() => {
    const handleSpotlightClick = (event) => {
      const isSpotlight = event.target.className === REACT_JOYRIDE_SPOTLIGHT;
      if (isSpotlight) {
        if (currentStep === 1) {
          mutationEditorQuestionCreate();
        } else if (role === 'teacher') {
          const shareButton = document.querySelector(secondStepTarget);
          shareButton.click();
          mutationEditorClear();
        } else {
          history.push(`/study/${course?.id}`);
        }
      }
    };
    window.addEventListener('click', handleSpotlightClick);
    return () => {
      window.removeEventListener('resize', handleSpotlightClick);
    };
  }, [course, currentStep, role, secondStepTarget]);

  // Delay apparition of the second step if the user created questions with AI
  // This is to avoid conflict with the toaster
  // saying that the questions hae been created successfully
  useEffect(() => {
    async function delayStepRun() {
      const isFromOnboardingWithIA = location.search.includes('useIA=true');
      if (isSecondStepOpen && isFromOnboardingWithIA) {
        await setTimeout(() => setRun(true), 5000);
      } else {
        setRun(true);
      }
    }
    delayStepRun();
  }, [isSecondStepOpen, location]);

  // Add tags to the user profile
  const handleCallback = async (callbackProps) => {
    const { status } = callbackProps;
    const tag = data.me.tags.includes('tour:2') ? ['tour:3'] : ['tour:2'];
    try {
      if (status === STATUS.FINISHED) {
        await mutationMeTag(tag);
        trackEvent('tag', tag);
        setRun(false);
      }
    } catch (err) {
      reportError('tag', tag, err);
    }
  };

  return currentStep ? (
    <Joyride
      callback={handleCallback}
      disableScrolling={true}
      tooltipComponent={TourTooltip}
      hideBackButton
      run={run}
      steps={[
        {
          content: steps[currentStep - 1].content,
          disableBeacon: true,
          placement: steps[currentStep - 1].placement,
          target: steps[currentStep - 1].target,
        },
      ]}
    />
  ) : null;
};

TourSteps.propTypes = {
  course: PropTypes.object.isRequired,
};

export default TourSteps;
