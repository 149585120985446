import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';

import InputFile from './InputFile';

const InputFileContainer = ({ name, ...props }) => {
  const [, , { setValue }] = useField(name);
  const [fileName, setFileName] = useState(null);
  const [isDroppable, setDroppable] = useState(false);
  const handleDragEnter = useCallback((evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    setDroppable(true);
  }, []);
  const handleDragLeave = useCallback((evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    setDroppable(false);
  }, []);
  const handleImport = useCallback(
    async (evt) => {
      evt.preventDefault();
      evt.stopPropagation();
      const files = evt.dataTransfer
        ? evt.dataTransfer.files
        : evt.target.files;
      setDroppable(false);
      if (!files || !files.length || !files[0]) {
        return;
      }
      setValue(files[0]);
      setFileName(files[0].name);
    },
    [setValue],
  );
  return (
    <InputFile
      fileName={fileName}
      handleDragEnter={handleDragEnter}
      handleDragLeave={handleDragLeave}
      handleImport={handleImport}
      isDroppable={isDroppable}
      name={name}
      {...props}
    />
  );
};

InputFileContainer.propTypes = {
  name: PropTypes.string.isRequired,
};

export default InputFileContainer;
