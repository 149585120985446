import classNames from 'classnames/bind';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import LoadingScreen from 'components/Shared/LoadingScreen';
import CourseTypes from 'components/Course/Shared/CourseTypes';
import Button from 'uikit/Button';
import Icon from 'uikit/Icon';
import InputDateTime from 'uikit/InputDateTime';
import InputNumber from 'uikit/InputNumber';
import InputRich from 'uikit/InputRich';
import InputText from 'uikit/InputText';
import Switch from 'uikit/Switch';
import Tooltip from 'uikit/Tooltip';

import styles from './Settings.module.scss';

const cx = classNames.bind(styles);

const Settings = ({
  className,
  course,
  handleCourseUpdate,
  handleTypeUpdate,
  isLoading,
  t,
  validationSchema,
}) =>
  isLoading ? (
    <LoadingScreen />
  ) : (
    <Formik
      enableReinitialize
      initialValues={{
        ...course,
        masteryThresholdInput: course.masteryThreshold,
        deadline: course.deadline,
      }}
      onSubmit={handleCourseUpdate}
      validateOnBlur={false}
      validationSchema={validationSchema}
    >
      {({ dirty, isSubmitting, setFieldValue, status, values }) => (
        <Form className={cx('container', className)}>
          <div className={cx('header')}>
            <h3 className={cx('title')}>
              {t('Course/Teacher/Settings/title')}
              {dirty && (
                <span className={cx('badge')}>
                  {t('Course/Teacher/Settings/not-save')}
                </span>
              )}
            </h3>
            <div>
              <Button
                data-testid="save-settings-button"
                intent={dirty ? 'primary' : 'default'}
                isDisabled={!dirty}
                isLoading={isSubmitting}
                type="submit"
              >
                {t('Course/Teacher/Settings/save')}
              </Button>
            </div>
          </div>
          {status === '500' && (
            <p className={cx('error')} role="alert">
              {t('Course/Teacher/Settings/update-error')}
            </p>
          )}
          <CourseTypes
            className={cx('choices')}
            current={
              values.isExam ? 'exam' : values.isLinear ? 'linear' : 'adaptive'
            }
            handleTypeUpdate={handleTypeUpdate(setFieldValue)}
            isInSettings
          />
          {!values.isExam && (
            <div className={cx('content')}>
              <p className={cx('section-title')}>
                {t('Course/Teacher/Settings/section-study')}
              </p>
              <InputNumber
                className={cx('field')}
                helper={
                  <Tooltip
                    tooltip={t('Course/Teacher/Settings/mastery-helper')}
                  >
                    <Icon name="info-circle" />
                  </Tooltip>
                }
                label={t('Course/Teacher/Settings/mastery-label')}
                name="masteryThreshold"
              />
            </div>
          )}
          {!values.isExam && (
            <div className={cx('content')}>
              <p className={cx('section-title')}>
                {t('Course/Teacher/Settings/section-deadline')}
              </p>
              <InputDateTime name="deadline" />
            </div>
          )}
          {values.isExam && (
            <>
              <div className={cx('content')}>
                <p className={cx('section-title')}>
                  {t('Course/Teacher/Settings/section-time')}
                </p>
                <InputDateTime
                  className={cx('field')}
                  label={t('Course/Teacher/Settings/start-time-label')}
                  name="examStartTime"
                />
                <InputDateTime
                  className={cx('field')}
                  label={t('Course/Teacher/Settings/stop-time-label')}
                  name="examStopTime"
                />
                <InputText
                  className={cx('field')}
                  label={t('Course/Teacher/Settings/duration-label')}
                  name="examDurationMinutes"
                  type="number"
                />
              </div>
              <div className={cx('content')}>
                <p className={cx('section-title')}>
                  {t('Course/Teacher/Settings/section-question')}
                </p>
                <Switch
                  className={cx('field')}
                  label={t('Course/Teacher/Settings/is-linear-exam')}
                  name="isLinearExam"
                />
                <Switch
                  className={cx({ field: !values.examInRandomOrder })}
                  label={t('Course/Teacher/Settings/random-label')}
                  name="examInRandomOrder"
                />
                {values.examInRandomOrder && (
                  <div className={cx('field', 'questions-by-exam')}>
                    <InputNumber name="nQuestionsByExam" />
                    <p className={cx('questions-by-exam-label')}>
                      {t('Course/Teacher/Settings/questions-by-exam-label')}
                    </p>
                  </div>
                )}
                <Switch
                  className={cx('field')}
                  label={t('Course/Teacher/Settings/review-label')}
                  name="isExamReviewEnabled"
                />
              </div>
              <div className={cx('content')}>
                <p className={cx('section-title')}>
                  {t('Course/Teacher/Settings/section-instructions')}
                </p>
                <InputRich
                  className={cx('field')}
                  label={t('Course/Teacher/Settings/instructions-label')}
                  name="examInstructions"
                />
              </div>
            </>
          )}
        </Form>
      )}
    </Formik>
  );

Settings.propTypes = {
  className: PropTypes.string,
  course: PropTypes.object,
  handleCourseUpdate: PropTypes.func.isRequired,
  handleTypeUpdate: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  validationSchema: PropTypes.object.isRequired,
};

Settings.defaultProps = {
  className: undefined,
  course: undefined,
};

export default Settings;
