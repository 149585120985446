import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Collaborators from './Collaborators';
import Students from './Students';
import styles from './Participants.module.scss';

const cx = classNames.bind(styles);

const Participants = ({ className, isGAR, isRoleTeacher, isTNECanope }) => (
  <div className={className}>
    {!isTNECanope && (
      <Collaborators className={cx('collaborators')} />
    )}
    {(!isGAR || (isGAR && isRoleTeacher)) && <Students />}
  </div>
);

Participants.propTypes = {
  className: PropTypes.string,
  isGAR: PropTypes.bool.isRequired,
  isRoleTeacher: PropTypes.bool.isRequired,
  isTNECanope: PropTypes.bool.isRequired,
};

Participants.defaultProps = {
  className: undefined,
};

export default Participants;
