import PropTypes from 'prop-types';
import React from 'react';

import InputTextArray from 'uikit/InputTextArray';

const Tags = ({ t, ...props }) => (
  <InputTextArray
    label={t('Questions/Shared/Tags/tags-label')}
    name="tags"
    options={[]}
    {...props}
  />
);

Tags.propTypes = {
  t: PropTypes.func.isRequired,
};

export default Tags;
