import React, { useCallback, useContext, useState, useRef } from 'react';
import { useLocation } from 'react-router';

import formatMediaDuration from 'helpers/formatMediaDuration';
import useHotkeys from 'hooks/useHotkeys';
import Context from 'lib/context';

import AudioPreview from './AudioPreview';

const AudioPreviewContainer = (props) => {
  const { theme } = useContext(Context);
  const { pathname } = useLocation();
  const isStudying = pathname.includes('/study');
  const audioRef = useRef();
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioDuration, setAudioDuration] = useState(' ');
  const handleCurrentTimeUpdate = useCallback(() => {
    formatMediaDuration(audioRef.current.currentTime, setAudioDuration);
  }, [audioRef, setAudioDuration]);
  const handleAudioDurationSet = useCallback(() => {
    formatMediaDuration(audioRef.current.duration, setAudioDuration);
  }, [audioRef, setAudioDuration]);
  const handlePlayToggle = useCallback(() => {
    setIsPlaying((value) => {
      if (audioRef.current && value) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
        if (audioRef.current.currentTime === audioRef.current.duration) {
          audioRef.current.currentTime = 0;
          formatMediaDuration(0, setAudioDuration);
        }
      }
      return !value;
    });
  }, [audioRef, setAudioDuration]);
  const handleAudioEnd = useCallback(() => {
    setIsPlaying(false);
  }, []);
  useHotkeys('space', handlePlayToggle);
  return (
    <AudioPreview
      audioRef={audioRef}
      audioDuration={audioDuration}
      handlePlayToggle={handlePlayToggle}
      handleAudioEnd={handleAudioEnd}
      handleCurrentTimeUpdate={handleCurrentTimeUpdate}
      handleAudioDurationSet={handleAudioDurationSet}
      isPlaying={isPlaying}
      theme={isStudying ? theme : {}}
      {...props}
    />
  );
};

export default AudioPreviewContainer;
