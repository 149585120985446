import { PropTypes } from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useQueryLocalEditor } from 'gql/queries/local';
import { useQueryMe } from 'gql/queries/me';
import { useMutationCommentReplyGenerate } from 'gql/mutations/commentReply';
import { notify } from 'uikit/Notifications';

import CommentContent from './CommentContent';

const CommentContentContainer = ({ comment, ...props }) => {
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const {
    data: {
      editor: { questionEditing },
    },
  } = useQueryLocalEditor();
  const mutationCommentReplyGenerate = useMutationCommentReplyGenerate();
  const handleCommentReplyGenerate = async (setFieldValue) => {
    if (comment) {
      try {
        setError(false);
        setLoading(true);
        const {
          data: { commentReplyGenerate: commentReply },
        } = await mutationCommentReplyGenerate({ commentId: comment.id });
        setFieldValue('content', commentReply);
        notify('ia', t('Shared/Comment/CommentContent/ai-notification'));
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }
  };
  const { data: meData } = useQueryMe();
  const hasAcceptedAI = meData && meData.me.wantsAI;
  const hasAIFeatureFlag =
    meData && meData.me.flags?.includes('ai-comment-reply');
  const isQuestionMCQ = questionEditing && questionEditing.type === 'MCQ';
  const hasAIAccess = hasAcceptedAI && hasAIFeatureFlag && isQuestionMCQ;
  return (
    <CommentContent
      error={error}
      handleCommentReplyGenerate={handleCommentReplyGenerate}
      hasAIAccess={hasAIAccess}
      isLoading={isLoading}
      t={t}
      {...props}
    />
  );
};

CommentContentContainer.propTypes = {
  comment: PropTypes.object,
};

CommentContentContainer.defaultProps = {
  comment: null,
};

export default CommentContentContainer;
