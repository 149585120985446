import { useEffect } from 'react';

const useOnError = (error, callback) => {
  useEffect(() => {
    if (error) {
      callback(error);
    }
  }, [callback, error]);
};

export default useOnError;
