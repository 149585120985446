import PropTypes from 'prop-types';

import Verso from './Verso';

const VersoContainer = Verso;

VersoContainer.propTypes = {
  className: PropTypes.string,
  question: PropTypes.object.isRequired,
};

VersoContainer.defaultProps = {
  className: undefined,
};

VersoContainer.defaultAnswer = () => undefined;

VersoContainer.defaultSolution = () => null;

export default VersoContainer;
