import classNames from 'classnames/bind';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import Icon from 'uikit/Icon';

import styles from './InputNumber.module.scss';

const cx = classNames.bind(styles);

const InputNumber = React.forwardRef(
  (
    {
      className,
      'data-testid': dataTestId,
      handleBlur,
      handleChange,
      handleIncrement,
      handleIncrementStart,
      handleIncrementStop,
      handleKeyDown,
      helper,
      isDisabled,
      label,
      name,
      shouldHideError,
      t,
      type,
      ...props
    },
    ref,
  ) => {
    const [{ onBlur, onChange, ...field }] = useField(`${name}Input`);
    const [{ value }, meta] = useField(name);
    return (
      <label
        className={cx(
          'container',
          {
            isDisabled,
            hasError: meta.touched && meta.error,
          },
          className,
        )}
        htmlFor={name}
      >
        {(label || helper) && (
          <div className={cx('title')} id={`inputnumber-${name}-label`}>
            <div className={cx('label')}>{label}</div>
            <div className={cx('helper')}>{helper}</div>
          </div>
        )}
        <div className={cx('input-container')}>
          <input
            aria-labelledby={`inputnumber-${name}-label`}
            className={cx('input')}
            disabled={isDisabled}
            id={name}
            name={name}
            onBlur={handleBlur}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            ref={ref}
            type={type}
            {...field}
            {...props}
          />
          <div className={cx('spin')}>
            <button
              aria-label={t('uikit/inputNumber/increment', { number: value })}
              className={cx('button')}
              data-testid={dataTestId}
              onClick={() => handleIncrement(1)}
              onMouseDown={(event) => handleIncrementStart(event, 1)}
              onMouseLeave={handleIncrementStop}
              onMouseUp={handleIncrementStop}
              onTouchEnd={handleIncrementStop}
              onTouchStart={(event) => handleIncrementStart(event, 1)}
              type="button"
            >
              <Icon name="plus" />
            </button>
            <button
              aria-label={t('uikit/inputNumber/decrement', { number: value })}
              className={cx('button')}
              onClick={() => handleIncrement(-1)}
              onMouseDown={(event) => handleIncrementStart(event, -1)}
              onMouseLeave={handleIncrementStop}
              onMouseUp={handleIncrementStop}
              onTouchEnd={handleIncrementStop}
              onTouchStart={(event) => handleIncrementStart(event, -1)}
              type="button"
            >
              <Icon name="minus" />
            </button>
          </div>
        </div>
        {!shouldHideError && meta.touched && meta.error && (
          <p className={cx('error-message')} id={`error-${name}`} role="alert">
            {meta.error}
          </p>
        )}
      </label>
    );
  },
);

InputNumber.propTypes = {
  className: PropTypes.string,
  'data-testid': PropTypes.string,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleIncrement: PropTypes.func.isRequired,
  handleIncrementStart: PropTypes.func.isRequired,
  handleIncrementStop: PropTypes.func.isRequired,
  handleKeyDown: PropTypes.func.isRequired,
  helper: PropTypes.node,
  isDisabled: PropTypes.bool,
  label: PropTypes.node,
  name: PropTypes.string.isRequired,
  shouldHideError: PropTypes.bool,
  t: PropTypes.func.isRequired,
  type: PropTypes.string,
};

InputNumber.defaultProps = {
  className: undefined,
  'data-testid': undefined,
  helper: undefined,
  isDisabled: false,
  label: undefined,
  shouldHideError: false,
  type: 'text',
};

export default InputNumber;
