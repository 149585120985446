import { GET } from 'lib/request';

// eslint-disable-next-line import/prefer-default-export
export const apiUploadsS3 = async (file) => {
  const upload = await GET(
    `${process.env.REACT_APP_API_URL}/rest/uploads/token/`,
    {
      name: file.name,
      size: file.size,
      type: file.type,
    },
  );
  const formData = new FormData();
  formData.append('Content-Type', file.type);
  Object.entries(upload.fields).forEach(([k, v]) => {
    formData.append(k, v);
  });
  formData.append('file', file);
  const response = await fetch(upload.url, {
    method: 'POST',
    body: formData,
  });
  if (!response || !response.ok) {
    throw new Error(response.status);
  }
  return upload.key;
};
