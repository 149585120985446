export function LaTeX2HTML(full, value) {
  try {
    const raw = value
      .replace(/<\/?(.+?)>/g, '')
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&amp;/g, '&')
      .replace(/&nbsp;/g, ' ');
    const html = window.katex
      ? window.katex.renderToString(raw, { throwOnError: false })
      : raw;
    return `<span class="math">${html}</span>`;
  } catch (err) {
    return full;
  }
}

export function ASCIIMath2HTML(full, value) {
  try {
    const raw = value
      .replace(/<\/?(.+?)>/g, '')
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&amp;/g, '&')
      .replace(/&nbsp;/g, ' ');
    const latex = window.asciimath2tex.parse(raw);
    const html = window.katex
      ? window.katex.renderToString(latex, {
          throwOnError: false,
        })
      : latex;
    return `<span class="math">${html}</span>`;
  } catch (err) {
    return full;
  }
}

export function highlight(full, props, content) {
  try {
    const lang = (props.match(/class="([^"]+)/) || [])[1];
    const code = content
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&amp;/g, '&')
      .replace(/&nbsp;/g, ' ');
    const highlighted = !window.hljs
      ? code
      : lang && window.hljs.getLanguage(lang)
      ? window.hljs.highlight(lang, code).value
      : window.hljs.highlightAuto(code).value;
    return `<pre><code>${highlighted}</code></pre>`;
  } catch (err) {
    return full;
  }
}
