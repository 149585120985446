import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import EDITOR_STATUSES from 'constants/editorStatuses';
import { mutationEditorClear } from 'gql/mutations/editor';
import { useMutationCollaboratorDelete } from 'gql/mutations/collaborator';
import { useQueryLocalEditor } from 'gql/queries/local';
import { trackEvent, reportError } from 'lib/tracking';

import CollaboratorDelete from './CollaboratorDelete';

const CollaboratorDeleteContainer = () => {
  const { t } = useTranslation();
  const mutationCollaboratorDelete = useMutationCollaboratorDelete();
  const {
    data: {
      editor: { status: editorStatus, collaboratorEditing },
    },
  } = useQueryLocalEditor();
  const isModalOpen = editorStatus === EDITOR_STATUSES.COLLABORATOR_DELETE;
  const [status, setStatus] = useState(undefined);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    setStatus(undefined);
  }, [isModalOpen]);
  const handleCollaboratorDelete = useCallback(async () => {
    try {
      setStatus(undefined);
      setLoading(true);
      await mutationCollaboratorDelete(collaboratorEditing);
      mutationEditorClear();
      trackEvent('collaborator', 'delete');
    } catch (err) {
      setStatus(err.message);
      reportError('collaborator', 'delete', err);
    } finally {
      setLoading(false);
    }
  }, [collaboratorEditing, mutationCollaboratorDelete]);
  return (
    <CollaboratorDelete
      handleCollaboratorDelete={handleCollaboratorDelete}
      handleClose={mutationEditorClear}
      isLoading={isLoading}
      isModalOpen={isModalOpen}
      status={status}
      t={t}
    />
  );
};

export default CollaboratorDeleteContainer;
