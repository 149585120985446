import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'uikit/Button';
import CleanedText from 'uikit/CleanedText';

import styles from './QuestionCard.module.scss';

const cx = classNames.bind(styles);

const QuestionCard = ({
  className,
  handleQuestionComments,
  isQuestionBank,
  position,
  question,
  questionInfo,
  t,
}) => (
  <div className={cx('container', className)}>
    <p className={cx('icon', `background-${questionInfo.color}`)}>
      <img alt="" src={questionInfo.icon} />
    </p>
    <p className={cx('position')}>{position}.</p>
    <CleanedText as="p" className={cx('title')}>
      {question.title}
    </CleanedText>
    {question.comments?.length > 0 && (
      <>
        <Button
          aria-label={t('Shared/CourseContent/QuestionCard/aria-comments')}
          className={cx('action')}
          icon="comment"
          size="small"
          onClick={handleQuestionComments}
        />
        {/* {question.comments.some((comment) => !comment.isRead) && ( */}
        <div className={cx('comment-notification')} />
        {/* )} */}
      </>
    )}
    {!isQuestionBank && (
      <p className={cx('stats')}>{`${question.studyStats.rate}%`}</p>
    )}
  </div>
);

QuestionCard.propTypes = {
  className: PropTypes.string,
  handleQuestionComments: PropTypes.func.isRequired,
  isQuestionBank: PropTypes.bool.isRequired,
  position: PropTypes.number.isRequired,
  question: PropTypes.object.isRequired,
  questionInfo: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

QuestionCard.defaultProps = {
  className: undefined,
};

export default QuestionCard;
