import classNames from 'classnames/bind';
import React from 'react';

import styles from './LoadingScreen.module.scss';

const cx = classNames.bind(styles);

const LoadingScreen = () => (
  <div className={cx('container')}>
    <div className={cx('chase')}>
      <div className={cx('dot')} />
      <div className={cx('dot')} />
      <div className={cx('dot')} />
      <div className={cx('dot')} />
      <div className={cx('dot')} />
      <div className={cx('dot')} />
    </div>
  </div>
);

export default LoadingScreen;
