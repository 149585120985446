import classNames from 'classnames/bind';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'uikit/Button';
import SubPage from 'uikit/SubPage/SubPage';

import Comment from './Comment';
import styles from './QuestionComments.module.scss';

const cx = classNames.bind(styles);

const QuestionComments = ({
  comments,
  course,
  handleClose,
  handleThreadClose,
  initialValues,
  isCommentSelected,
  isVisible,
  question,
  rectoComponent: RectoQuestion,
  versoComponent: VersoQuestion,
  t,
}) => (
  <SubPage
    isOpen={isVisible}
    onExit={handleClose}
    subtitle={course.title}
    title={t('Course/Shared/QuestionComments/comments')}
  >
    <div className={cx('container')}>
      <aside className={cx('preview')}>
        <p className={cx('explanation-preview')}>
          {t('Course/Shared/QuestionComments/explanation-preview')}
        </p>
        <div className={cx('question')}>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={() => null}
            validateOnBlur={false}
            validateOnChange={false}
          >
            {() => (
              <Form>
                {VersoQuestion && question.result && (
                  <VersoQuestion isDisabled question={question} />
                )}
                {RectoQuestion && !question.result && (
                  <RectoQuestion isDisabled question={question} />
                )}
              </Form>
            )}
          </Formik>
        </div>
      </aside>
      <main className={cx('comments')}>
        {comments && comments.length !== 0 ? (
          <>
            {isCommentSelected && (
              <div className={cx('thread')}>
                <Button
                  aria-label={t(
                    'Course/Shared/QuestionComments/aria-close-thread',
                  )}
                  onClick={handleThreadClose}
                  icon="times"
                  intent="white"
                />
                <p className={cx('thread-title')}>
                  {t('Course/Shared/QuestionComments/thread')}
                </p>
              </div>
            )}
            {comments.map((comment) => (
              <Comment
                className={cx('comment')}
                course={course}
                comment={comment}
                key={comment.id}
              />
            ))}
          </>
        ) : (
          <p>{t('Course/Shared/QuestionComments/no-comments')}</p>
        )}
      </main>
    </div>
  </SubPage>
);

QuestionComments.propTypes = {
  comments: PropTypes.arrayOf(PropTypes.object),
  course: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleThreadClose: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  isCommentSelected: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  question: PropTypes.object,
  rectoComponent: PropTypes.elementType,
  versoComponent: PropTypes.elementType,
  t: PropTypes.func.isRequired,
};

QuestionComments.defaultProps = {
  comments: [],
  question: undefined,
  rectoComponent: undefined,
  versoComponent: undefined,
};

export default QuestionComments;
