import Tippy from '@tippyjs/react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './Tooltip.module.scss';

const cx = classNames.bind(styles);

const Tooltip = ({ children, isInMenu = false, tooltip }) => (
  <Tippy
    className={cx('container', { 'menu-tooltip': isInMenu })}
    content={tooltip}
    duration={0}
    placement={isInMenu ? 'right' : 'top'}
  >
    {children}
  </Tippy>
);

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  isInMenu: PropTypes.bool,
  tooltip: PropTypes.string.isRequired,
};

export default Tooltip;
