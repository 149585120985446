import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';

import FolderCard from './FolderCard';
import Standalone from './Standalone';
import styles from './Finder.module.scss';

const cx = classNames.bind(styles);

const Finder = ({ className, courses, folders, handleCourseSort }) => (
  <div className={className}>
    <DragDropContext onDragEnd={handleCourseSort}>
      {folders.map((folder) => (
        <FolderCard
          className={cx('margin-bottom')}
          courses={courses}
          folder={folder}
          key={folder.id}
        />
      ))}
      <Standalone className={cx('margin-bottom')} courses={courses} />
    </DragDropContext>
  </div>
);

Finder.propTypes = {
  className: PropTypes.string,
  courses: PropTypes.arrayOf(PropTypes.object).isRequired,
  folders: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleCourseSort: PropTypes.func.isRequired,
};

Finder.defaultProps = {
  className: undefined,
};

export default Finder;
