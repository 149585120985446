import t from 'helpers/t';

import Aggregate from './Aggregate';
import Editor from './Editor';
import Recto from './Recto/Recto';
import Verso from './Verso';
import svg from './icon.svg';
import picture from './picture.svg';

const FillInTheBlanks = {
  info: {
    type: 'FillInTheBlanks',
    name: t('Questions/FillInTheBlanks/name'),
    tooltip: t('Questions/FillInTheBlanks/tooltip'),
    icon: svg,
    picture,
    color: 'purple',
    isCorrectable: true,
    isOneSided: false,
    isInScore: true,
    isReportable: true,
    study: true,
    play: true,
    test: true,
    isNew: false,
    canFullForceCorrect: false,
    canPartialForceCorrect: false,
  },
  Aggregate,
  Editor,
  Recto,
  Verso,
};

export default FillInTheBlanks;
