import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import EDITOR_STATUSES from 'constants/editorStatuses';
import { mutationEditorClear } from 'gql/mutations/editor';
import { useMutationStudentDelete } from 'gql/mutations/student';
import { useQueryLocalEditor } from 'gql/queries/local';
import { trackEvent, reportError } from 'lib/tracking';

import StudentDelete from './StudentDelete';

const StudentDeleteContainer = () => {
  const { t } = useTranslation();
  const {
    data: {
      editor: { status: editorStatus, studentEditing },
    },
  } = useQueryLocalEditor();
  const isModalOpen = editorStatus === EDITOR_STATUSES.STUDENT_DELETE;
  const [status, setStatus] = useState(undefined);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    setStatus(undefined);
  }, [isModalOpen]);
  const mutationStudentDelete = useMutationStudentDelete();
  const handleStudentDelete = useCallback(async () => {
    try {
      setStatus(undefined);
      setLoading(true);
      await mutationStudentDelete(studentEditing);
      mutationEditorClear();
      trackEvent('student', 'delete');
    } catch (err) {
      setStatus(err.message);
      reportError('student', 'delete', err);
    } finally {
      setLoading(false);
    }
  }, [studentEditing, mutationStudentDelete]);
  return (
    <StudentDelete
      handleClose={mutationEditorClear}
      handleStudentDelete={handleStudentDelete}
      isLoading={isLoading}
      isModalOpen={isModalOpen}
      status={status}
      t={t}
    />
  );
};

export default StudentDeleteContainer;
