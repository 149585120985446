import React from 'react';
import { useTranslation } from 'react-i18next';

import useModal from 'hooks/useModal';

import ExpertFeedbacksEditor from './ExpertInstructions';

const ExpertInstructionsContainer = (props) => {
  const { t } = useTranslation();
  const { isModalOpen, handleModalOpen, handleModalClose } = useModal(false);
  return (
    <ExpertFeedbacksEditor
      handleModalClose={handleModalClose}
      handleModalOpen={handleModalOpen}
      isModalOpen={isModalOpen}
      t={t}
      {...props}
    />
  );
};

export default ExpertInstructionsContainer;
