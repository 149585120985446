import classNames from 'classnames/bind';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'uikit/Button';
import InputText from 'uikit/InputText';
import Modal from 'uikit/Modal';

import UnInvitedWarning from 'components/Course/Shared/UnInvitedWarning';
import styles from './CollaboratorsInvite.module.scss';

const cx = classNames.bind(styles);

const CollaboratorsInvite = ({
  handleClose,
  handleCollaboratorsInvite,
  isCourseInvitationRestricted = false,
  isModalOpen,
  t,
  unInvited,
  validationSchema,
}) => (
  <Modal isOpen={isModalOpen} onExit={handleClose}>
    <Formik
      initialValues={{
        usernames: '',
      }}
      onSubmit={handleCollaboratorsInvite}
      validationSchema={validationSchema}
    >
      {({ isSubmitting, status }) => (
        <Form className={cx('container')}>
          <Button
            aria-label={t(
              'Course/Teacher/Collaborators/CollaboratorsInvite/aria-close',
            )}
            className={cx('close')}
            icon="times"
            onClick={handleClose}
          />
          <h1 className={cx('title')}>
            {t('Course/Teacher/Collaborators/CollaboratorsInvite/title')}
          </h1>
          <p className={cx('subtitle')}>
            {t('Course/Teacher/Collaborators/CollaboratorsInvite/subtitle')}
          </p>
          {status && (
            <p className={cx('error')} role="alert">
              {t(
                'Course/Teacher/Collaborators/CollaboratorsInvite/collaborators-invite-error',
              )}
            </p>
          )}
          {isCourseInvitationRestricted && (
            <p className={cx('warning')}>
              {t(
                'Course/Teacher/Cover/CourseShare/warning-invitations-restricted',
              )}
            </p>
          )}
          <InputText
            className={cx('field')}
            icon="at"
            label={t(
              'Course/Teacher/Collaborators/CollaboratorsInvite/usernames-label',
            )}
            name="usernames"
          />
          <UnInvitedWarning unInvited={unInvited} />
          <Button
            className={cx('action')}
            intent="primary"
            isLoading={isSubmitting}
            size="large"
            type="submit"
          >
            {t(
              'Course/Teacher/Collaborators/CollaboratorsInvite/confirm-invite-collaborators',
            )}
          </Button>
        </Form>
      )}
    </Formik>
  </Modal>
);

CollaboratorsInvite.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleCollaboratorsInvite: PropTypes.func.isRequired,
  isCourseInvitationRestricted: PropTypes.bool,
  isModalOpen: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  unInvited: PropTypes.arrayOf(PropTypes.string).isRequired,
  validationSchema: PropTypes.object.isRequired,
};

export default CollaboratorsInvite;
