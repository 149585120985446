import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';

import Context from 'lib/context';

import { formatMatches } from '../utils';
import Verso from './Verso';

const VersoContainer = ({ question, ...props }) => {
  const { theme } = useContext(Context);
  const matches = useMemo(
    () => formatMatches(question.content.matches),
    [question],
  );
  return (
    <Verso matches={matches} question={question} theme={theme} {...props} />
  );
};

VersoContainer.propTypes = {
  question: PropTypes.object.isRequired,
};

VersoContainer.defaultAnswer = (question) =>
  question.content.matches.map(() => -1);

VersoContainer.defaultSolution = (question) =>
  question.content.matches.map((match) => ({
    idx: match.idx,
    user: null,
    correct: match.idx,
    isCorrect: false,
  }));

export default VersoContainer;
