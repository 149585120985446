import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { mutationEditorStudentDelete } from 'gql/mutations/editor';

import StudentCard from './StudentCard';

const StudentCardContainer = ({ student, ...props }) => {
  const { t } = useTranslation();
  const handleStudentDelete = useCallback(() => {
    mutationEditorStudentDelete(student);
  }, [student]);
  return (
    <StudentCard
      handleStudentDelete={handleStudentDelete}
      student={student}
      t={t}
      {...props}
    />
  );
};

StudentCardContainer.propTypes = {
  student: PropTypes.object.isRequired,
};

export default StudentCardContainer;
