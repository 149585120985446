import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { apiUploadsS3 } from 'api/upload';
import useModal from 'hooks/useModal';
import Context from 'lib/context';
import { reportError } from 'lib/tracking';

import AudioImporter from './AudioImporter';

const AudioImporterContainer = ({ name, ...props }) => {
  const { t } = useTranslation();
  const { theme } = useContext(Context);
  const { pathname } = useLocation();
  const isStudying = pathname.includes('/study');
  const { isModalOpen, handleModalClose, handleModalOpen } = useModal(false);
  const [, , { setValue: setAudio }] = useField(name);
  const [{ value: audioToImport }, , { setValue: setAudioToImport }] =
    useField('audioToImport');
  const [{ value: audioPreview }, , { setValue: setAudioPreview }] =
    useField('audioPreview');
  const [isDroppable, setDroppable] = useState(false);
  const handleDragEnter = useCallback((evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    setDroppable(true);
  }, []);
  const handleDragLeave = useCallback((evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    setDroppable(false);
  }, []);
  const handleAudioReset = useCallback(() => {
    setAudioToImport(null);
    setAudioPreview(null);
  }, [setAudioToImport, setAudioPreview]);
  const handleImport = useCallback(
    async (evt) => {
      evt.preventDefault();
      evt.stopPropagation();
      const files = evt.dataTransfer
        ? evt.dataTransfer.files
        : evt.target.files;
      setDroppable(false);
      if (!files || !files.length || !files[0]) {
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = (e) => {
        setAudioToImport(files[0]);
        setAudioPreview(e.target.result);
      };
    },
    [setAudioPreview, setAudioToImport],
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSelect = useCallback(async () => {
    try {
      setIsSubmitting(true);
      const filePath = await apiUploadsS3(audioToImport);
      setAudio(filePath);
      handleModalClose();
      setIsSubmitting(false);
    } catch (err) {
      reportError('audio', 'upload', err);
    }
  }, [audioToImport, handleModalClose, setAudio]);
  const handleModalCloseAndReset = useCallback(() => {
    setAudioPreview('');
    setAudioToImport('');
    handleModalClose();
  }, [handleModalClose, setAudioPreview, setAudioToImport]);
  return (
    <AudioImporter
      audioPreview={audioPreview}
      audioToImport={audioToImport}
      handleDragEnter={handleDragEnter}
      handleDragLeave={handleDragLeave}
      handleImport={handleImport}
      handleModalCloseAndReset={handleModalCloseAndReset}
      handleModalOpen={handleModalOpen}
      handleSelect={handleSelect}
      handleAudioReset={handleAudioReset}
      isDroppable={isDroppable}
      isModalOpen={isModalOpen}
      isSubmitting={isSubmitting}
      name={name}
      t={t}
      theme={isStudying ? theme : {}}
      {...props}
    />
  );
};

AudioImporterContainer.propTypes = {
  name: PropTypes.string.isRequired,
};

export default AudioImporterContainer;
