import { withTranslation } from 'react-i18next';
import * as yup from 'yup';

import Editor from './Editor';

const EditorContainer = withTranslation()(Editor);

EditorContainer.validationSchema = (t) =>
  yup.object().shape({
    tags: yup.array().of(yup.string().trim().lowercase().max(256)).compact(),
    title: yup
      .string()
      .trim()
      .transform((v) => v || t('Questions/Dictation/Editor/default-title')),
    audio: yup
      .string()
      .trim()
      .required(t('Questions/Dictation/Editor/audio-error-required'))
      .url(t('Questions/Dictation/Editor/audio-error-required')),
    answer: yup
      .string()
      .trim()
      .required(t('Questions/Dictation/Editor/answer-error-required')),
    feedback: yup.string().trim(),
    ignoreCase: yup.boolean().default(true),
  });

export default EditorContainer;
