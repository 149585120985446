import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import EDITOR_STATUSES from 'constants/editorStatuses';
import { useQueryLocalEditor } from 'gql/queries/local';

import Navigation from '../Navigation/Navigation';
import QuestionTypeCard from './QuestionTypeCard/QuestionTypeCard';
import styles from './QuestionTypes.module.scss';

const cx = classNames.bind(styles);

const QuestionTypes = ({ className = null, onExit, questionsInfo }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Shared/QuestionEdit/QuestionTypes',
  });

  const { t: tQuestionInfos } = useTranslation('');

  const {
    data: {
      editor: { status: editorStatus },
    },
  } = useQueryLocalEditor();

  const [highlightedQuestion, setHighlightedQuestion] = useState(
    questionsInfo.informativeQuestions[0],
  );

  const handleQuestionHighlight = (info) => {
    setHighlightedQuestion(info);
  };

  const isProposingQuestion = editorStatus === EDITOR_STATUSES.QUESTION_PROPOSE;

  return (
    <div className={cx(className, 'container')}>
      <Navigation onExit={onExit} />
      <div className={cx('subpage')}>
        <div className={cx('question-info')}>
          <div className={cx('question-picture-container')}>
            <img
              className={cx('question-picture')}
              alt=""
              src={highlightedQuestion.picture}
            />
          </div>
          <p className={cx('category-title')}>
            {tQuestionInfos(highlightedQuestion.name)}
          </p>
          <p className={cx('question-tooltip')}>
            {tQuestionInfos(highlightedQuestion.tooltip)}
          </p>
        </div>
        <div className={cx('types')}>
          <div>
            <h5 className={cx('category-title')}>
              {t('informative-questions')}
            </h5>
            <div className={cx('cards', 'informative-cards')}>
              {questionsInfo.informativeQuestions.map((info) => (
                <QuestionTypeCard
                  className={cx('type')}
                  highlightedQuestion={highlightedQuestion}
                  info={info}
                  key={info.type}
                  onQuestionHighlight={handleQuestionHighlight}
                />
              ))}
            </div>
            <h5 className={cx('category-title')}>{t('questions')}</h5>
            <div className={cx('cards')}>
              {questionsInfo.questions.map((info) => {
                const isTypeVisibleToStudents = info.type !== 'PDFOrPPT';
                const isQuestionTypeVisible =
                  !isProposingQuestion || isTypeVisibleToStudents;
                return (
                  <div key={info.type}>
                    {isQuestionTypeVisible && (
                      <QuestionTypeCard
                        className={cx('type')}
                        highlightedQuestion={highlightedQuestion}
                        info={info}
                        onQuestionHighlight={handleQuestionHighlight}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

QuestionTypes.propTypes = {
  className: PropTypes.string,
  onExit: PropTypes.func.isRequired,
  questionsInfo: PropTypes.object.isRequired,
};

export default QuestionTypes;
