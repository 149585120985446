import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { useQueryCourseCollaborators } from 'gql/queries/course';
import {
  mutationEditorReset,
  mutationEditorCollaboratorsInvite,
} from 'gql/mutations/editor';

import Collaborators from './Collaborators';

const CollaboratorsContainer = (props) => {
  const { t } = useTranslation();
  const { courseId } = useParams();
  const { isLoading, data } = useQueryCourseCollaborators({
    variables: {
      courseId,
    },
  });
  useEffect(() => {
    mutationEditorReset();
    return () => {
      mutationEditorReset();
    };
  }, []);
  return (
    <Collaborators
      course={data?.course}
      handleCollaboratorsInvite={mutationEditorCollaboratorsInvite}
      isLoading={isLoading}
      t={t}
      {...props}
    />
  );
};

export default CollaboratorsContainer;
