import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import Context from 'lib/context';

import Recto from './Recto';

const RectoContainer = (props) => {
  const { t } = useTranslation();
  const { theme } = useContext(Context);
  return <Recto t={t} theme={theme} {...props} />;
};

RectoContainer.defaultAnswer = (question) =>
  question.content.choices.map((choice) => ({
    idx: choice.idx,
    answer: undefined,
  }));

RectoContainer.validationSchema = () =>
  yup.object().shape({
    answer: yup
      .array()
      .of(
        yup.object().shape({
          idx: yup.number().required().integer().min(0),
          answer: yup.boolean().required(),
        }),
      )
      .compact(),
  });

export default RectoContainer;
