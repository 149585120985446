import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { apiUploadsS3 } from 'api/upload';
import formatMediaDuration from 'helpers/formatMediaDuration';
import useHotkeys from 'hooks/useHotkeys';
import useInterval from 'hooks/useInterval';
import useModal from 'hooks/useModal';
import Context from 'lib/context';

import AudioRecorder from './AudioRecorder';

const AudioRecorderContainer = ({
  name,
  onRecordStart,
  onRecordStop,
  ...props
}) => {
  const { t } = useTranslation();
  const { theme } = useContext(Context);
  const { pathname } = useLocation();
  const isStudying = pathname.includes('/study');
  const { isModalOpen, handleModalClose, handleModalOpen } = useModal(false);
  const [, , { setValue: setAudio }] = useField(name);
  const [{ value: audioToImport }, , { setValue: setAudioToImport }] =
    useField('audioToImport');
  const [{ value: audioPreview }, , { setValue: setAudioPreview }] =
    useField('audioPreview');
  const [timer, setTimer] = useState(3);
  const [isLoading, setIsLoading] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [isRestarting, setIsRestarting] = useState(false);
  const audioRef = useRef();
  const [audioDuration, setAudioDuration] = useState(' ');
  const handleCountDown = useCallback(() => {
    if (isLoading && timer > 0) {
      setTimer(timer - 1);
    }
  }, [isLoading, timer]);
  useInterval(handleCountDown, 1 * 1000);
  const btnRecordStop = useRef();
  const handleRecordAudio = useCallback(() => {
    setIsRestarting(false);
    handleModalOpen();
    navigator.mediaDevices
      .getUserMedia({
        audio: true,
      })
      .then((stream) => {
        setIsLoading(true);
        // The delay is needed to sync the audio with the audio
        setTimeout(() => {
          setIsLoading(false);
          setIsRecording(true);
          if (onRecordStart) {
            onRecordStart();
          }
          const mediaRecorder = new MediaRecorder(stream);
          mediaRecorder.start();
          const audioStream = document.getElementById('audioStream');
          audioStream.srcObject = stream;
          if (btnRecordStop.current) {
            btnRecordStop.current.addEventListener('click', () => {
              mediaRecorder.stop();
              stream.getTracks().forEach((track) => {
                track.stop();
              });
            });
          }
          const chuck = [];
          mediaRecorder.addEventListener('dataavailable', (e) => {
            chuck.push(e.data);
          });
          mediaRecorder.addEventListener('stop', () => {
            const blob = new Blob(chuck, { type: 'audio/ogg;codecs=vorbis' });
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onload = (event) => {
              setAudioToImport(blob);
              setAudioPreview(event.target.result);
            };
            setIsRecording(false);
            if (onRecordStop) {
              onRecordStop();
            }
          });
        }, 3000);
      });
  }, [handleModalOpen, onRecordStart, onRecordStop, setAudioPreview, setAudioToImport]);
  const handleCurrentTimeUpdate = useCallback(() => {
    formatMediaDuration(audioRef.current.currentTime, setAudioDuration);
  }, [audioRef, setAudioDuration]);
  const handleRecordedAudioReset = useCallback(() => {
    setTimer(3);
    setAudioDuration(' ');
    setAudioToImport(null);
    setAudioPreview(null);
    handleRecordAudio();
    if (btnRecordStop.current) {
      btnRecordStop.current.click();
    }
  }, [handleRecordAudio, setAudioPreview, setAudioToImport]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSelect = useCallback(async () => {
    try {
      setIsSubmitting(true);
      const filePath = await apiUploadsS3(audioToImport);
      setAudio(filePath);
      handleModalClose();
      setIsSubmitting(false);
    } catch (err) {
      reportError('audio', 'upload', err);
    }
  }, [audioToImport, handleModalClose, setAudio]);
  const handleModalCloseAndReset = useCallback(() => {
    setIsRecording(false);
    setAudioToImport(null);
    setAudioPreview(null);
    setAudioDuration(' ');
    setTimer(3);
    if (onRecordStop) {
      onRecordStop();
    }
    if (btnRecordStop.current) {
      btnRecordStop.current.click();
    }
    setTimeout(() => {
      setAudioToImport(null);
      setAudioPreview(null);
    }, 1000);
    handleModalClose();
  }, [handleModalClose, onRecordStop, setAudioPreview, setAudioToImport]);
  const handleRecordingRestart = useCallback(() => {
    setIsRestarting(true);
    setAudioDuration(' ');
    handleModalCloseAndReset();
    setTimeout(handleRecordAudio, 1000);
  }, [handleModalCloseAndReset, handleRecordAudio]);
  useHotkeys('r', isRecording ? handleRecordingRestart : handleRecordAudio);
  useHotkeys('s', () => btnRecordStop.current?.click());
  return (
    <AudioRecorder
      audioDuration={audioDuration}
      audioPreview={audioPreview}
      audioRef={audioRef}
      btnRecordStop={btnRecordStop}
      handleCurrentTimeUpdate={handleCurrentTimeUpdate}
      handleModalCloseAndReset={handleModalCloseAndReset}
      handleRecordAudio={handleRecordAudio}
      handleRecordedAudioReset={handleRecordedAudioReset}
      handleRecordingRestart={handleRecordingRestart}
      handleSelect={handleSelect}
      isLoading={isLoading}
      isModalOpen={isModalOpen}
      isRecording={isRecording}
      isRestarting={isRestarting}
      isSubmitting={isSubmitting}
      t={t}
      theme={isStudying ? theme : {}}
      timer={timer}
      {...props}
    />
  );
};

AudioRecorderContainer.propTypes = {
  name: PropTypes.string.isRequired,
  onRecordStart: PropTypes.func.isRequired,
  onRecordStop: PropTypes.func.isRequired,
};

export default AudioRecorderContainer;
