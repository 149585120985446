import PropTypes from 'prop-types';
import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import Context from 'lib/context';
import sanitize from 'lib/sanitize';

import Recto from './Recto';

const RectoContainer = ({ question, ...props }) => {
  const { t } = useTranslation();
  const { theme } = useContext(Context);
  const handleSpeech = useCallback(() => {
    const msg = new SpeechSynthesisUtterance();
    const { speechSynthesis } = window;
    msg.text = sanitize.string(question.title);
    msg.lang = question.content.titleLang || 'fr';
    msg.voice = speechSynthesis
      .getVoices()
      .find((voice) => voice.lang.includes(msg.lang.slice(0, 2)));
    window.speechSynthesis.speak(msg);
  }, [question]);
  return (
    <Recto
      handleSpeech={handleSpeech}
      question={question}
      t={t}
      theme={theme}
      {...props}
    />
  );
};

RectoContainer.propTypes = {
  question: PropTypes.object.isRequired,
};

RectoContainer.defaultAnswer = () => undefined;

RectoContainer.validationSchema = () => undefined;

export default RectoContainer;
