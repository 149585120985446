import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'uikit/Button';

import styles from './VideoPreview.module.scss';

const cx = classNames.bind(styles);

const VideoPreview = ({
  className,
  footer,
  handleVideoDurationSet,
  handlePlay,
  handleVideoEnd,
  isPlaying,
  theme,
  video,
  videoRef,
}) => (
  <div
    className={cx(
      'preview',
      className,
      { 'preview-container': footer },
      `theme-${theme}`,
    )}
  >
    <button
      className={cx('video-container')}
      onClick={!isPlaying ? handlePlay : null}
      type="button"
    >
      {video.includes('youtube.com') ? (
        <iframe
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className={cx('iframe')}
          frameBorder="0"
          height="315"
          src={video}
          title="YouTube video player"
          width="560"
        />
      ) : (
        <>
          {!isPlaying && (
            <Button
              className={cx('play')}
              icon="play"
              intent="primary"
              onClick={handlePlay}
              size="large"
            />
          )}
          <video
            className={cx('video')}
            controls={isPlaying ? 'controls' : false}
            controlsList="nodownload"
            id="video"
            key={video}
            onDurationChange={handleVideoDurationSet}
            onEnded={handleVideoEnd}
            ref={videoRef}
          >
            <source type="video/webm" src={video} />
            <source type="video/mp4" src={video} />
            <source type="video/mov" src={video} />
            <source type="video/wmv" src={video} />
            <source type="video/avi" src={video} />
            <source type="video/mkv" src={video} />
            <source src={video} />
            <track default kind="captions" />
          </video>
        </>
      )}
    </button>
    {footer && <div className={cx('footer')}>{footer}</div>}
  </div>
);

VideoPreview.propTypes = {
  className: PropTypes.string,
  footer: PropTypes.node,
  handleVideoDurationSet: PropTypes.func.isRequired,
  handlePlay: PropTypes.func.isRequired,
  handleVideoEnd: PropTypes.func.isRequired,
  isPlaying: PropTypes.bool.isRequired,
  theme: PropTypes.string.isRequired,
  video: PropTypes.string.isRequired,
  videoRef: PropTypes.object,
};

VideoPreview.defaultProps = {
  className: undefined,
  footer: undefined,
  videoRef: undefined,
};

export default VideoPreview;
