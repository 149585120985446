import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'uikit/Button';
import ImagePicker from 'uikit/ImagePicker';
import Menu from 'uikit/Menu';

import COLORS from '../data/colors';
import FONTS from '../data/fonts';
import styles from './SecondaryToolbar.module.scss';

const cx = classNames.bind(styles);

const SecondaryToolbar = ({
  className,
  currentBackground,
  fabricRef,
  handleBackgroundChange,
  handleBackgroundColorChange,
  handleBackgroundDelete,
  handleImageChange,
  handleModalClose,
  handleModalOpen,
  handleSVGColorChange,
  handleTextAlign,
  handleTextColorChange,
  handleTextFontFamily,
  handleTextFontSize,
  handleTextStyle,
  handleTextWeight,
  isModalOpen,
  selectedObject,
  t,
  textColor,
}) => (
  <div className={cx('container', className)}>
    {(!selectedObject ||
      selectedObject.type === 'i-text' ||
      selectedObject.type === 'group') && (
      <Menu
        isHideOnClickDeactivated
        placement="right"
        toggle={
          <Button
            className={cx(
              'action',
              'background-button',
              `current-background-${
                selectedObject ? textColor : currentBackground
              }`,
            )}
            icon={
              // eslint-disable-next-line no-underscore-dangle
              !selectedObject && fabricRef.current?.backgroundImage?._element
                ? 'image'
                : 'circle'
            }
            intent="white"
          />
        }
      >
        <p className={cx('color-title')}>
          {t(
            selectedObject
              ? 'Questions/Shared/SlideBuilder/SecondaryToolbar/text-color'
              : 'Questions/Shared/SlideBuilder/SecondaryToolbar/background',
          )}
        </p>
        <div className={cx('colors')}>
          {COLORS.map((bg) =>
            selectedObject && bg.name === 'image-picker' ? null : (
              <Button
                className={cx('color', bg.name, `background-${bg.name}`)}
                icon={bg.name === 'image-picker' ? 'image' : '.'}
                key={bg.name}
                onClick={
                  selectedObject?.type === 'i-text'
                    ? () => handleTextColorChange(bg.name)
                    : // eslint-disable-next-line no-underscore-dangle
                    selectedObject?._objects
                    ? () => handleSVGColorChange(bg.name)
                    : bg.name === 'image-picker'
                    ? handleModalOpen
                    : () => handleBackgroundColorChange(bg.name)
                }
              />
            ),
          )}
        </div>
        {/* eslint-disable-next-line no-underscore-dangle */}
        {!selectedObject && fabricRef.current?.backgroundImage?._element && (
          <Button
            className={cx('delete-button')}
            onClick={handleBackgroundDelete}
          >
            {t(
              'Questions/Shared/SlideBuilder/SecondaryToolbar/delete-background',
            )}
          </Button>
        )}
      </Menu>
    )}

    {selectedObject && selectedObject.type === 'i-text' && (
      <>
        <Menu
          isHideOnClickDeactivated
          placement="right"
          toggle={
            <Button
              className={cx('action', 'font-family-action')}
              icon="font"
              intent="white"
            />
          }
        >
          <div className={cx('font-family')}>
            {FONTS.map((fontFamily) => (
              <Button
                className={cx('font-family-button')}
                key={fontFamily}
                onClick={() => handleTextFontFamily(fontFamily)}
                style={{ fontFamily }}
              >
                {fontFamily}
              </Button>
            ))}
          </div>
        </Menu>
        <Button
          className={cx('action')}
          icon="bold"
          intent="white"
          onClick={handleTextWeight}
        />
        <Button
          className={cx('action')}
          icon="italic"
          intent="white"
          onClick={handleTextStyle}
        />
        <Menu
          isHideOnClickDeactivated
          placement="right"
          toggle={
            <Button className={cx('action')} icon="align-left" intent="white" />
          }
        >
          <div className={cx('text-align')}>
            {['left', 'right', 'center', 'justify'].map((textAlign) => (
              <Button
                className={cx('text-align-button')}
                icon={`align-${textAlign}`}
                key={textAlign}
                onClick={() => handleTextAlign(textAlign)}
              />
            ))}
          </div>
        </Menu>
        <Menu
          isHideOnClickDeactivated
          placement="right"
          toggle={
            <button className={cx('action', 'font-size-action')} type="button">
              {`${selectedObject.fontSize}pt`}
            </button>
          }
        >
          <div className={cx('font-size')}>
            {[
              '10',
              '15',
              '20',
              '25',
              '30',
              '35',
              '40',
              '45',
              '50',
              '55',
              '60',
              '65',
              '70',
              '75',
              '80',
            ].map((fontSize) => (
              <Button
                className={cx('font-size-button')}
                key={fontSize}
                onClick={() => handleTextFontSize(fontSize)}
              >
                {`${fontSize}pt`}
              </Button>
            ))}
          </div>
        </Menu>
      </>
    )}
    {selectedObject && selectedObject.type === 'image' && (
      <Button icon="image" intent="white" onClick={handleModalOpen} />
    )}
    <ImagePicker
      current={
        selectedObject
          ? //  eslint-disable-next-line no-underscore-dangle
            selectedObject?._element?.currentSrc
          : fabricRef.background
      }
      handleModalClose={handleModalClose}
      isModalOpen={isModalOpen}
      onSelect={selectedObject ? handleImageChange : handleBackgroundChange}
    />
  </div>
);

SecondaryToolbar.propTypes = {
  className: PropTypes.string,
  currentBackground: PropTypes.string,
  fabricRef: PropTypes.object,
  handleBackgroundChange: PropTypes.func.isRequired,
  handleBackgroundColorChange: PropTypes.func.isRequired,
  handleBackgroundDelete: PropTypes.func.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  handleModalOpen: PropTypes.func.isRequired,
  handleImageChange: PropTypes.func.isRequired,
  handleSVGColorChange: PropTypes.func.isRequired,
  handleTextAlign: PropTypes.func.isRequired,
  handleTextColorChange: PropTypes.func.isRequired,
  handleTextFontFamily: PropTypes.func.isRequired,
  handleTextFontSize: PropTypes.func.isRequired,
  handleTextStyle: PropTypes.func.isRequired,
  handleTextWeight: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  selectedObject: PropTypes.object,
  t: PropTypes.func.isRequired,
  textColor: PropTypes.string.isRequired,
};

SecondaryToolbar.defaultProps = {
  className: undefined,
  currentBackground: undefined,
  fabricRef: undefined,
  selectedObject: null,
};

export default SecondaryToolbar;
