import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import Standalone from './Standalone';

const StandaloneContainer = ({ course, questionTypes, ...props }) => {
  const questions = useMemo(
    () =>
      course.questions.filter(
        (question) => !questionTypes || questionTypes.includes(question.type),
      ),
    [course, questionTypes],
  );
  return <Standalone questions={questions} {...props} />;
};

StandaloneContainer.propTypes = {
  course: PropTypes.object.isRequired,
  questionTypes: PropTypes.arrayOf(PropTypes.string),
};

StandaloneContainer.defaultProps = {
  questionTypes: undefined,
};

export default StandaloneContainer;
