import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Options from 'questions/Shared/Options';
import Tags from 'questions/Shared/Tags';
import Button from 'uikit/Button';
import Icon from 'uikit/Icon';
import InputError from 'uikit/InputError';
import InputRich from 'uikit/InputRich';
import Tooltip from 'uikit/Tooltip';

import styles from './Editor.module.scss';

const cx = classNames.bind(styles);

const Editor = ({
  className,
  handleMatchAdd,
  handleMatchCompleteDestination,
  handleMatchCompleteSource,
  handleMatchRemove,
  matches,
  t,
}) => (
  <div className={cx('container', className)}>
    <p className={cx('label')}>{t('Questions/Matching/Editor/matches')}</p>
    <div className={cx('field')}>
      <InputError name="matches" />
      {[...matches, ''].map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className={cx('list-element')} key={index}>
          {index === matches.length && (
            <Button
              className={cx('match-add')}
              icon="plus"
              intent="outline"
              onClick={handleMatchAdd}
              size="small"
              tooltip={t('Questions/Matching/Editor/match-add')}
            />
          )}
          {index === matches.length ? (
            <InputRich
              className={cx('match-complete-left')}
              name="tmp"
              onChange={handleMatchCompleteSource}
            />
          ) : (
            <InputRich
              className={cx('left')}
              name={`matches.${index}.source`}
              placeholder={t(
                `Questions/Matching/Editor/match${index + 1}-placeholder`,
              )}
            />
          )}
          <Icon className={cx('arrow')} name="arrows-h" />
          {index === matches.length ? (
            <InputRich
              className={cx('match-complete-right')}
              name="tmp"
              onChange={handleMatchCompleteDestination}
            />
          ) : (
            <InputRich
              className={cx('right')}
              name={`matches.${index}.destination`}
              placeholder={t(
                `Questions/Matching/Editor/destination${index + 1}-placeholder`,
              )}
            />
          )}
          {index !== matches.length && (
            <Button
              icon="trash-alt"
              intent="outline"
              onClick={() => handleMatchRemove(index)}
              tooltip={t('Questions/Matching/Editor/delete-matching')}
              variant="regular"
            />
          )}
        </div>
      ))}
    </div>
    <InputRich
      helper={
        <Tooltip tooltip={t('Questions/Matching/Editor/feedback-helper')}>
          <Icon name="info-circle" />
        </Tooltip>
      }
      label={t('Questions/Matching/feedback-label')}
      name="feedback"
    />
    <Options className={cx('options')}>
      <Tags />
    </Options>
  </div>
);

Editor.propTypes = {
  className: PropTypes.string,
  handleMatchAdd: PropTypes.func.isRequired,
  handleMatchCompleteDestination: PropTypes.func.isRequired,
  handleMatchCompleteSource: PropTypes.func.isRequired,
  handleMatchRemove: PropTypes.func.isRequired,
  matches: PropTypes.arrayOf(PropTypes.object).isRequired,
  t: PropTypes.func.isRequired,
};

Editor.defaultProps = {
  className: undefined,
};

export default Editor;
