import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { useMutationStudentSort } from 'gql/mutations/student';
import { trackEvent, reportError } from 'lib/tracking';

import Finder from './Finder';

const FinderContainer = ({ course, ...props }) => {
  const mutationStudentSort = useMutationStudentSort();
  const handleSort = useCallback(
    async ({ draggableId, source, destination }) => {
      try {
        if (
          !destination ||
          (source.index === destination.index &&
            source.droppableId === destination.droppableId)
        ) {
          return;
        }
        const container =
          source.droppableId === 'course'
            ? course
            : course.groups.find((c) => c.id === source.droppableId);
        const student = container?.students.find((q) => q.id === draggableId);
        if (student) {
          mutationStudentSort(student, {
            groupId:
              destination.droppableId === 'course'
                ? null
                : destination.droppableId,
          });
        }
        trackEvent('student', 'sort');
      } catch (err) {
        reportError('student', 'sort', err);
      }
    },
    [course, mutationStudentSort],
  );
  return <Finder course={course} handleSort={handleSort} {...props} />;
};

FinderContainer.propTypes = {
  course: PropTypes.object.isRequired,
};

export default FinderContainer;
