const GENERIC_EMAILS = [
  'gmail',
  'yahoo',
  'hotmail',
  'aol',
  'msn',
  'wanadoo',
  'orange',
  'live',
  'rediffmail',
  'free',
  'gmx',
  'web',
  'yandex',
  'ymail',
  'libero',
  'outlook',
  'uol',
  'bol',
  'mail',
  'cox',
  'sbcglobal',
  'sfr',
  'verizon',
  'googlemail',
  'bigpond',
  'terra',
  'neuf',
  'alice',
  'rocketmail',
  'laposte',
  'facebook',
  'bellsouth',
  'charter',
  'rambler',
  'tiscali',
  'shaw',
  'sky',
  'earthlink',
  'optonline',
  'freenet',
  't-online',
  'aliceadsl',
  'virgilio',
  'home',
  'telenet',
  'voila',
  'planet',
  'ntlworld',
  'arcor',
  'frontiernet',
  'hetnet',
  'zonnet',
  'club-internet',
  'juno',
  'optusnet',
  'blueyonder',
  'bluewin',
  'skynet',
  'sympatico',
  'windstream',
  'centurytel',
  'chello',
];
export default GENERIC_EMAILS;
