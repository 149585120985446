import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import EDITOR_STATUSES from 'constants/editorStatuses';
import {
  mutationEditorClear,
  mutationEditorQuestionCommentsChange,
} from 'gql/mutations/editor';
import { useQueryLocalEditor } from 'gql/queries/local';
import { useQueryQuestionComments } from 'gql/queries/question';
import { useURLToStateQuestionCommentOpenComment } from 'hooks/useURLToState';
import { getQuestionRecto, getQuestionVerso } from 'questions';

import QuestionComments from './QuestionComments';

const QuestionCommentsContainer = (props) => {
  const { t } = useTranslation();
  const {
    data: {
      editor: { status: editorStatus, questionEditing, commentSelected },
    },
  } = useQueryLocalEditor();
  const isVisible = editorStatus === EDITOR_STATUSES.QUESTION_COMMENTS;
  const rectoComponent = getQuestionRecto(questionEditing?.type);
  const versoComponent = getQuestionVerso(questionEditing?.type);
  const initialValues = useMemo(
    () => ({
      answer: rectoComponent?.defaultAnswer(questionEditing),
    }),
    [questionEditing, rectoComponent],
  );
  const { isLoading, data } = useQueryQuestionComments({
    variables: {
      questionId: questionEditing?.id,
    },
    skip: !isVisible,
  });
  const question = useMemo(
    () =>
      commentSelected?.studyAnswer
        ? {
            ...questionEditing,
            ...commentSelected.studyAnswer,
          }
        : questionEditing,
    [commentSelected, questionEditing],
  );
  const handleThreadClose = useCallback(() => {
    mutationEditorQuestionCommentsChange(null);
  }, []);
  useURLToStateQuestionCommentOpenComment(isLoading, data);
  const comments = data?.question.comments ? [...data?.question.comments] : [];
  const sortedComments = comments.sort((a, b) =>
    a.isRead === b.isRead ? 0 : b.isRead ? -1 : 1,
  );
  return (
    <QuestionComments
      comments={sortedComments}
      handleClose={mutationEditorClear}
      handleThreadClose={handleThreadClose}
      initialValues={initialValues}
      isCommentSelected={!!commentSelected}
      isVisible={isVisible}
      question={question}
      rectoComponent={rectoComponent}
      versoComponent={versoComponent}
      t={t}
      {...props}
    />
  );
};

export default QuestionCommentsContainer;
