import classNames from 'classnames/bind';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { useMutationQuestionImportList } from 'gql/mutations/question';
import extract from 'helpers/extract';
import { trackEvent, reportError } from 'lib/tracking';
import Button from 'uikit/Button';
import InputText from 'uikit/InputText';
import InputError from 'uikit/InputError';
import Select from 'uikit/Select';
import Switch from 'uikit/Switch';

import styles from './QuestionImportList.module.scss';

const cx = classNames.bind(styles);

const QuestionImportList = ({ course, handleModalClose }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/Editor/QuestionImport/QuestionImportList',
  });

  const { t: tQuestionType } = useTranslation();

  const mutationQuestionImportList = useMutationQuestionImportList();

  async function handleQuestionsImport(values, form) {
    try {
      form.setStatus(null);
      const {
        rowSeparator,
        rowSeparatorOther,
        columnSeparator,
        columnSeparatorOther,
        ...variables
      } = extract(values, validationSchema);
      const {
        data: { questionImportList },
      } = await mutationQuestionImportList({
        ...variables,
        courseId: course.id,
        columnSeparator:
          columnSeparator === 'other' ? columnSeparatorOther : columnSeparator,
        rowSeparator:
          rowSeparator === 'other' ? rowSeparatorOther : rowSeparator,
      });
      handleModalClose(questionImportList);
      trackEvent('question', 'import-list');
    } catch (err) {
      form.setStatus('500');
      reportError('question', 'import-list', err);
    }
  }

  const validationSchema = yup.object().shape({
    chapterId: yup
      .string()
      .trim()
      .matches(
        /^|([0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})$/i,
      ),
    type: yup
      .string()
      .required(t('type-error-required'))
      .trim()
      .oneOf(
        ['Flashcard', 'MCQ', 'ShortAnswer', 'Matching', 'Categorization'],
        t('type-error-required'),
      ),
    content: yup.string().required(t('content-error-required')).trim(),
    rowSeparator: yup.string().required(),
    rowSeparatorOther: yup.string().when('rowSeparator', {
      is: 'other',
      then: yup.string().required(t('row-separator-error-required')),
      otherwise: yup.string(),
    }),
    columnSeparator: yup.string().required(),
    columnSeparatorOther: yup.string().when('columnSeparator', {
      is: 'other',
      then: yup.string().required(t('column-separator-error-required')),
      otherwise: yup.string(),
    }),
    switchOrder: yup.boolean().required(),
  });

  const chapters = course.chapters;

  return (
    <>
      <Formik
        initialValues={{
          chapterId: '',
          type: '',
          content: '',
          rowSeparator: 'newline',
          rowSeparatorOther: '-',
          columnSeparator: 'tab',
          columnSeparatorOther: '_',
          switchOrder: false,
        }}
        onSubmit={handleQuestionsImport}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, status, values }) => (
          <Form className={cx('subpage')}>
            {status === '500' && (
              <p className={cx('error')} role="alert">
                {t('question-import-error')}
              </p>
            )}
            <h3 className={cx('sub-title')}>
              <span className={cx('count')}>1</span>
              {t('my-question')}
            </h3>
            <Select
              className={cx('field')}
              icon="question"
              label={t('type-label')}
              name="type"
            >
              <option value="">{t('no-type')}</option>
              <option value="Flashcard">
                {tQuestionType('Questions/Flashcard/name')}
              </option>
              <option value="MCQ">{tQuestionType('Questions/MCQ/name')}</option>
              <option value="ShortAnswer">
                {tQuestionType('Questions/ShortAnswer/name')}
              </option>
              <option value="Matching">
                {tQuestionType('Questions/Matching/name')}
              </option>
              <option value="Categorization">
                {tQuestionType('Questions/Categorization/name')}
              </option>
            </Select>
            <InputText
              className={cx('field')}
              label={t('content-label')}
              maxRows={20}
              minRows={4}
              name="content"
              type="textarea"
            />
            <h3 className={cx('sub-title', 'margin')}>
              <span className={cx('count')}>2</span>
              {t('options')}
            </h3>
            <div className={cx('separators')}>
              <div className={cx('separator', 'field')}>
                <div className={cx('multi')}>
                  <Select
                    label={t('column-separator-label')}
                    name="columnSeparator"
                  >
                    <option value="tab">{t('tab')}</option>
                    <option value=" ">{t('space')}</option>
                    <option value=",">{t('comma')}</option>
                    <option value="other">{t('other')}</option>
                  </Select>
                  {values.columnSeparator === 'other' && (
                    <InputText
                      className={cx('extra')}
                      name="columnSeparatorOther"
                      shouldHideError
                    />
                  )}
                </div>
                <InputError
                  className={cx('field-error')}
                  name="columnSeparatorOther"
                />
              </div>
              <div className={cx('separator', 'field')}>
                <div className={cx('multi')}>
                  <Select label={t('row-separator-label')} name="rowSeparator">
                    <option value="newline">{t('newline')}</option>
                    <option value=";">{t('semicolon')}</option>
                    <option value="other">{t('other')}</option>
                  </Select>
                  {values.rowSeparator === 'other' && (
                    <InputText
                      className={cx('extra')}
                      name="rowSeparatorOther"
                      shouldHideError
                    />
                  )}
                </div>
                <InputError
                  className={cx('field-error')}
                  name="rowSeparatorOther"
                />
              </div>
              <div className={cx('separator', 'field', 'switch-button')}>
                <Switch label={t('switch-order')} name="switchOrder" />
              </div>
            </div>
            <h3 className={cx('sub-title', 'margin')}>
              <span className={cx('count')}>3</span>
              {t('import-in')}
            </h3>
            <Select
              className={cx('field')}
              icon="book-open"
              label={t('chapter-label')}
              name="chapterId"
            >
              <option value="">{t('no-chapter')}</option>
              {chapters.map((chapter) => (
                <option key={chapter.id} value={chapter.id}>
                  {chapter.title}
                </option>
              ))}
            </Select>
            <div className={cx('actions')}>
              <div />
              <Button
                className={cx('action')}
                intent="primary"
                isLoading={isSubmitting}
                size="large"
                type="submit"
              >
                {t('confirm-import-questions')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

QuestionImportList.propTypes = {
  course: PropTypes.object.isRequired,
  handleModalClose: PropTypes.func.isRequired,
};

export default QuestionImportList;
