import classNames from 'classnames/bind';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import EDITOR_STATUSES from 'constants/editorStatuses';
import { useQueryLocalEditor } from 'gql/queries/local';
import { getQuestionInfo } from 'questions';
import Button from 'uikit/Button';
import Menu, { MenuItem } from 'uikit/Menu';

import styles from './SplittedButton.module.scss';

const cx = classNames.bind(styles);

const SplittedButton = ({
  course,
  onGradeModalOpen,
}) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Shared/QuestionEdit/QuestionEditor/QuestionEditorFooter/SplittedButton',
  });

  const {
    data: {
      editor: { status: editorStatus, chapterSelected, questionType },
    },
  } = useQueryLocalEditor();

  const { handleSubmit, isSubmitting, setFieldValue } = useFormikContext();

  const questionInfo = getQuestionInfo(questionType);

  const isNotImportingFromPDForPPT = questionInfo?.type !== 'PDFOrPPT';

  const chapterSelectedId = chapterSelected?.id || null;

  const isApprovingQuestion = editorStatus === EDITOR_STATUSES.QUESTION_APPROVE;

  const isProposingQuestion = editorStatus === EDITOR_STATUSES.QUESTION_PROPOSE;

  const isCreatingQuestion = editorStatus === EDITOR_STATUSES.QUESTION_CREATE;

  const hasChapters = course.chapters.length > 0;

  const hasNotSelectedChapterYet = !chapterSelectedId;

  const canSelectChapter =
    (isCreatingQuestion || isApprovingQuestion) &&
    isNotImportingFromPDForPPT &&
    hasChapters &&
    hasNotSelectedChapterYet;

  const getSubmitButtonLabel = () => {
    if (!isNotImportingFromPDForPPT) {
      return 'confirm';
    } else if (isApprovingQuestion) {
      return 'approve-question';
    } else if (isProposingQuestion) {
      return 'propose-question';
    }
    return 'finish-question';
  };

  const handleQuestionSave = () => {
    if (isApprovingQuestion) {
      onGradeModalOpen();
    } else {
      setFieldValue('wantPreview', false);
      handleSubmit();
    }
  };

  const handleQuestionSaveInChapter = (chapterId) => {
    setFieldValue('chapterId', chapterId);
    if (isApprovingQuestion) {
      onGradeModalOpen();
    } else {
      handleSubmit();
    }
  };

  return (
    <div className={cx('split-button-container')}>
      <Button
        className={cx('button', {
          'split-button': canSelectChapter,
        })}
        data-testid="save-question-button"
        intent="primary"
        isLoading={isSubmitting}
        onClick={handleQuestionSave}
        size="large"
      >
        {t(getSubmitButtonLabel())}
      </Button>
      {canSelectChapter && (
        <Menu
          placement="bottom"
          toggle={
            <Button
              className={cx('save-in-chapter-button')}
              icon="chevron-down"
              size="large"
            />
          }
          trigger="mouseenter focus"
        >
          <div className={cx('save-in-chapter-title')}>
            {t('save-question-in')}
          </div>
          {course.chapters.map((chapter) => (
            <MenuItem
              key={chapter.id}
              onClick={() => handleQuestionSaveInChapter(chapter.id)}
            >
              {chapter.title}
            </MenuItem>
          ))}
        </Menu>
      )}
    </div>
  );
};

SplittedButton.propTypes = {
  course: PropTypes.object.isRequired,
  onGradeModalOpen: PropTypes.func.isRequired,
};

export default SplittedButton;
