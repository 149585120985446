import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { useMutationCourseUpdate } from 'gql/mutations/course';
import extract from 'helpers/extract';
import { trackEvent, reportError } from 'lib/tracking';

import CourseIntroduction from './CourseIntroduction';

const CourseIntroductionContainer = ({ course, ...props }) => {
  const { t } = useTranslation();
  const mutationCourseUpdate = useMutationCourseUpdate();
  const [isEditing, setEditing] = useState(false);
  const handleIntroductionMount = useCallback((ref) => {
    if (ref) {
      ref.select();
    }
  }, []);
  const handleIntroductionEdit = useCallback(() => {
    setEditing(true);
  }, []);
  const handleEditDone = useCallback(
    async (values) => {
      try {
        const introduction = extract(
          values.introduction,
          yup.string().trim().max(256),
        );
        if (introduction === course.introduction) {
          setEditing(false);
          return;
        }
        await mutationCourseUpdate(course, {
          introduction,
        });
        trackEvent('course', 'update');
      } catch (err) {
        reportError('course', 'update', err);
      } finally {
        setEditing(false);
      }
    },
    [course, mutationCourseUpdate],
  );
  return (
    <CourseIntroduction
      course={course}
      handleEditDone={handleEditDone}
      handleIntroductionEdit={handleIntroductionEdit}
      handleIntroductionMount={handleIntroductionMount}
      isEditing={isEditing}
      t={t}
      {...props}
    />
  );
};

CourseIntroductionContainer.propTypes = {
  course: PropTypes.object.isRequired,
};

export default CourseIntroductionContainer;
