import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'uikit/Button';

import styles from './Welcome.module.scss';

const cx = classNames.bind(styles);

const Welcome = ({ onNextStep }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'App/Onboarding/Tour/Welcome',
  });

  return (
    <div className={cx('container')}>
      <div className={cx('content')}>
        <h2 className={cx('title')}>{t('title')}</h2>
        <p className={cx('subtitle')}>{t('subtitle')}</p>
        <Button
          className={cx('action')}
          data-testid="tour-welcome-next-button"
          intent="primary"
          onClick={onNextStep}
          size="large"
        >
          {t('continue')}
        </Button>
      </div>
    </div>
  );
};

Welcome.propTypes = {
  onNextStep: PropTypes.func.isRequired,
};

export default Welcome;
