import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Icon from 'uikit/Icon';

import styles from './CollapseItem.module.scss';

const cx = classNames.bind(styles);

const CollapseItem = ({
  children,
  className,
  handleToggle,
  icon,
  isOpen,
  title,
}) => (
  <div className={className}>
    <button
      aria-selected={isOpen}
      className={cx('header')}
      onClick={handleToggle}
      role="tab"
      type="button"
    >
      <p className={cx('toggle')}>
        <Icon name={isOpen ? 'caret-down' : 'caret-right'} />
      </p>
      {icon && (
        <p className={cx('icon')}>
          <Icon name={icon} />
        </p>
      )}
      <p className={cx('title')}>{title}</p>
    </button>
    {isOpen && <div role="tabpanel">{children}</div>}
  </div>
);

CollapseItem.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  handleToggle: PropTypes.func,
  icon: PropTypes.string,
  isOpen: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

CollapseItem.defaultProps = {
  className: undefined,
  handleToggle: undefined,
  icon: undefined,
  isOpen: false,
};

export default CollapseItem;
