import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import CleanedText from 'uikit/CleanedText';
import Icon from 'uikit/Icon';

import IMAGE from './images/study.svg';

import styles from './FutureCourse.module.scss';
import { useTranslation } from 'react-i18next';

const cx = classNames.bind(styles);

const FutureCourse = ({ course, isSingle = false }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Shared/Agenda/FutureCourse',
  });

  return (
    <div
      className={cx('container', `theme-${course.themeBackground}`, {
        future: !course.isPast && isSingle,
        tooltip: !isSingle,
      })}
    >
      {isSingle ? (
        <img className={cx('picture')} alt="" src={IMAGE} />
      ) : (
        <div className={cx('header')}>
          <div className={cx('course-icon')}>
            <Icon name="book" />
          </div>
          <div>
            <CleanedText className={cx('title')}>{course.title}</CleanedText>
          </div>
        </div>
      )}
      {course.nQuestionsToStudy > 0 ? (
        <p
          className={cx('stat-to-study', {
            future: !course.isPast && !!course.deadline && isSingle,
          })}
        >
          <span>{course.nQuestionsToStudy}</span>
          {t('stats-to-study', {
            count: course.nQuestionsToStudy,
          })}
        </p>
      ) : (
        <div className={cx('subtitle-container')}>
          <div className={cx('icon-check')}>
            <Icon name="check" />
          </div>
          <p
            className={cx('subtitle', {
              future: !course.isPast && !!course.deadline && isSingle,
            })}
          >
            {t('study-done')}
          </p>
        </div>
      )}
    </div>
  );
};

FutureCourse.propTypes = {
  course: PropTypes.object.isRequired,
  isSingle: PropTypes.bool,
};

export default FutureCourse;
