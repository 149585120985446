import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import Context from 'lib/context';

import Verso from './Verso';

const VersoContainer = ({ question, ...props }) => {
  const { theme } = useContext(Context);
  const sources =
    question.content.categories.sources ||
    question.content.categories.map((category) => ({
      idx: category.idx,
      title: category.source,
    }));
  const destinations =
    question.content.categories.destinations ||
    question.content.categories.flatMap((category) => category.destinations);
  return (
    <Verso
      destinations={destinations}
      question={question}
      sources={sources}
      theme={theme}
      {...props}
    />
  );
};

VersoContainer.propTypes = {
  question: PropTypes.object.isRequired,
};

VersoContainer.defaultAnswer = (question) =>
  question.content.categories.map(() => []);

VersoContainer.defaultSolution = (question) =>
  question.content.categories.map((category) => ({
    idx: category.idx,
    user: [],
    correct: category.destinations.map((destination) => destination.idx),
    isCorrect: false,
  }));

export default VersoContainer;
