import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import CleanedText from 'uikit/CleanedText';
import FormattedText from 'uikit/FormattedText/FormattedText';

import text2React from '../../text2React';
import Input from './Input/Input';
import Select from './Select/Select';
import styles from './QuestionText.module.scss';

const cx = classNames.bind(styles);

const QuestionText = ({ isDisabled, question }) => {
  const displayCorrectAnswer = (solution) => (
    <strong className={cx('correct-answer')}>
      <CleanedText>{solution.user}</CleanedText>
    </strong>
  );

  const displaySelect = (attribs, selectIndex) => {
    const choices = decodeURIComponent(attribs.data)
      .replace(/\\,/g, '#_FAKE_COMMA_#')
      .split(',')
      .map((ele) => ele.replace(/#_FAKE_COMMA_#/g, ','));
    return (
      <Select
        choices={choices}
        isDisabled={isDisabled}
        selectIndex={selectIndex}
      />
    );
  };

  const displayInput = (blankIndex) => (
    <Input inputIndex={blankIndex} isDisabled={isDisabled} />
  );

  const questionText = () => {
    let blankIndex = 0;
    return text2React(question.content.text, {
      replace: ({ name, attribs }) => {
        const solution = question.solution?.[blankIndex];
        const isBlank = name === 'input' || name === 'select';
        if (isBlank && solution?.isCorrect) {
          blankIndex += 1;
          return displayCorrectAnswer(solution);
        }
        if (name === 'select') {
          const selectIndex = blankIndex;
          blankIndex += 1;
          return displaySelect(attribs, selectIndex);
        }
        if (name === 'input') {
          blankIndex += 1;
          return displayInput(blankIndex);
        }
      },
    });
  };

  return (
    <FormattedText className={cx('text')} isAlreadyFormatted>
      {questionText()}
    </FormattedText>
  );
};

QuestionText.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  question: PropTypes.object.isRequired,
};

export default QuestionText;
