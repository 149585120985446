import classNames from 'classnames/bind';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'uikit/Button';
import Modal from 'uikit/Modal';
import sanitize from 'lib/sanitize';

import styles from './PartialForceCorrect.module.scss';
import Answer from '../Answer';
import Icon from 'uikit/Icon';

const cx = classNames.bind(styles);

const PartialForceCorrect = ({
  answers,
  handleAnswerSelect,
  handlePartialForceCorrect,
  handleModalClose,
  isModalOpen,
  t,
  validationSchema,
}) => (
  <Modal isOpen={isModalOpen} onExit={handleModalClose}>
    <div className={cx('modal')}>
      <Button
        aria-label={t(
          'Questions/LabelOnImage/Verso/PartialForceCorrect/aria-close',
        )}
        className={cx('close')}
        icon="times"
        onClick={handleModalClose}
      />
      <h1 className={cx('title')}>
        {t('Questions/LabelOnImage/Verso/PartialForceCorrect/title')}
      </h1>
      <Formik
        initialValues={{
          forcedAnswers: [],
        }}
        onSubmit={handlePartialForceCorrect}
        validationSchema={validationSchema}
      >
        {({
          errors,
          isSubmitting,
          status,
          setFieldValue,
          values,
          ...other
        }) => (
          <Form className={cx('answers')}>
            {answers.map((answer, index) => {
              const isSelected = values.forcedAnswers.find(
                (forcedAnswer) =>
                  JSON.stringify(forcedAnswer) === JSON.stringify(answer),
              );
              return (
                <div className={cx('answer')}>
                  <label htmlFor={`${answer.user}-${index}`}>
                    <input
                      aria-label={sanitize.string(answer.user)}
                      checked={isSelected}
                      className={cx('check')}
                      id={`${answer.user}-${index}`}
                      type="checkbox"
                      onChange={() =>
                        handleAnswerSelect({
                          answer,
                          values,
                          setFieldValue,
                          isSelected,
                        })
                      }
                    />
                    <span
                      className={cx('checkbox', {
                        isChecked: isSelected,
                      })}
                    >
                      <Icon name="check" />
                    </span>
                  </label>
                  <Answer
                    answer={answer}
                    index={index}
                    isForcingCorrect={true}
                    isSelected={isSelected}
                  />
                </div>
              );
            })}
            {status && (
              <p className={cx('error')} role="alert">
                {t('Questions/LabelOnImage/Verso/PartialForceCorrect/error')}
              </p>
            )}
            {errors && (
              <p className={cx('error')} role="alert">
                {errors.forcedAnswers}
              </p>
            )}
            <Button
              className={cx('action')}
              intent="primary"
              isLoading={isSubmitting}
              size="large"
              type="submit"
            >
              {t('Questions/LabelOnImage/Verso/PartialForceCorrect/confirm')}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  </Modal>
);

PartialForceCorrect.propTypes = {
  answers: PropTypes.object.isRequired,
  handleAnswerSelect: PropTypes.func.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  handlePartialForceCorrect: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  validationSchema: PropTypes.object.isRequired,
};

export default PartialForceCorrect;
