import React from 'react';

import useSiteContext from 'hooks/useSiteContext';

import InputRich from './InputRich';

const InputRichContainer = (props) => {
  const { isExternal } = useSiteContext();
  return <InputRich isExternal={isExternal} {...props} />;
};

export default InputRichContainer;
