import React, { useContext } from 'react';

import Context from 'lib/context';

import Verso from './Verso';

const VersoContainer = (props) => {
  const { theme } = useContext(Context);
  return <Verso theme={theme} {...props} />;
};

VersoContainer.defaultAnswer = (question) =>
  question.content.choices.map((choice) => ({
    idx: choice.idx,
    answer: false,
  }));

VersoContainer.defaultSolution = (question) =>
  question.content.choices.map((choice) => ({
    idx: choice.idx,
    user: null,
    correct: choice.answer,
    isCorrect: false,
    feedback: '',
  }));

export default VersoContainer;
