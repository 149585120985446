import { withTranslation } from 'react-i18next';
import * as yup from 'yup';

import Editor from './Editor';

const EditorContainer = withTranslation()(Editor);

EditorContainer.validationSchema = (t) =>
  yup.object().shape({
    tags: yup.array().of(yup.string().trim().lowercase().max(256)).compact(),
    title: yup
      .string()
      .trim()
      .required(t('Questions/ShortAnswer/Editor/title-error-required')),
    answers: yup
      .array()
      .of(yup.string().trim())
      .compact()
      .test(
        'length',
        t('Questions/ShortAnswer/Editor/answers-error-length'),
        (answers) => answers.length >= 1,
      ),
    feedback: yup.string().trim(),
    ignoreCase: yup.boolean().default(true),
    ignoreAccents: yup.boolean().default(true),
    ignorePunctuation: yup.boolean().default(true),
  });

export default EditorContainer;
