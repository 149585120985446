import { saveHistory } from './history';

let clipboard;

export const copy = (fabricRef) => {
  const activeObject = fabricRef.current.getActiveObject();
  if (activeObject) {
    activeObject.clone((cloned) => {
      clipboard = cloned;
    });
  }
};

export const paste = (fabricRef) => {
  saveHistory(fabricRef);
  clipboard.clone((clonedObj) => {
    fabricRef.current.discardActiveObject();
    clonedObj.set({
      left: clonedObj.left + 10,
      top: clonedObj.top + 10,
      evented: true,
    });
    if (clonedObj.type === 'activeSelection') {
      clonedObj.set({ canvas: fabricRef.current });
      clonedObj.forEachObject((obj) => {
        fabricRef.current.add(obj);
      });
      clonedObj.setCoords();
    } else {
      fabricRef.current.add(clonedObj);
    }
    clipboard.top += 10;
    clipboard.left += 10;
    fabricRef.current.setActiveObject(clonedObj);
    fabricRef.current.requestRenderAll();
  });
};

export const moveUp = (fabricRef) => {
  const activeObject = fabricRef.current.getActiveObject();
  if (activeObject) {
    saveHistory(fabricRef);
    activeObject.set({ top: activeObject.top - 10 });
    fabricRef.current.requestRenderAll();
  }
};

export const moveDown = (fabricRef) => {
  const activeObject = fabricRef.current.getActiveObject();
  if (activeObject) {
    saveHistory(fabricRef);
    activeObject.set({ top: activeObject.top + 10 });
    fabricRef.current.requestRenderAll();
  }
};

export const moveLeft = (fabricRef) => {
  const activeObject = fabricRef.current.getActiveObject();
  if (activeObject) {
    saveHistory(fabricRef);
    activeObject.set({ left: activeObject.left - 10 });
    fabricRef.current.requestRenderAll();
  }
};

export const moveRight = (fabricRef) => {
  const activeObject = fabricRef.current.getActiveObject();
  if (activeObject) {
    saveHistory(fabricRef);
    activeObject.set({ left: activeObject.left + 10 });
    fabricRef.current.requestRenderAll();
  }
};
