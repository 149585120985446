import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import PERMISSIONS from 'constants/permissions';
import LoadingScreen from 'components/Shared/LoadingScreen';
import Button from 'uikit/Button';

import CollaboratorCard from './CollaboratorCard';
import CollaboratorsInvite from './CollaboratorsInvite';
import CollaboratorDelete from './CollaboratorDelete';
import styles from './Collaborators.module.scss';

const cx = classNames.bind(styles);

const Collaborators = ({
  className,
  course,
  handleCollaboratorsInvite,
  isLoading,
  t,
}) =>
  isLoading ? (
    <LoadingScreen />
  ) : (
    <>
      <div className={cx('container', className)}>
        <div className={cx('header')}>
          <h3 className={cx('title')}>
            {t('Course/Teacher/Collaborators/title')}
            <span className={cx('count')}>
              {course.collaborators.length + 1}
            </span>
          </h3>
          <Button
            icon="plus"
            intent="white"
            onClick={handleCollaboratorsInvite}
          >
            {t('Course/Teacher/Collaborators/invite-collaborators')}
          </Button>
        </div>
        <CollaboratorCard
          className={cx('collaborator')}
          collaborator={course.owner}
          level={course.level}
        />
        {course.collaborators.map((collaborator) => (
          <CollaboratorCard
            className={cx('collaborator')}
            collaborator={collaborator}
            level={course.level}
            key={collaborator.id}
          />
        ))}
      </div>
      <CollaboratorsInvite course={course} />
      {course.level >= PERMISSIONS.OWNER && <CollaboratorDelete />}
    </>
  );

Collaborators.propTypes = {
  className: PropTypes.string,
  course: PropTypes.object,
  handleCollaboratorsInvite: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

Collaborators.defaultProps = {
  className: undefined,
  course: undefined,
};

export default Collaborators;
