import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import EDITOR_STATUSES from 'constants/editorStatuses';
import {
  mutationEditorAggregatedAnswers,
  mutationEditorClear,
} from 'gql/mutations/editor';
import { useQueryLocalEditor } from 'gql/queries/local';
import { useQueryResultsAggregatedByQuestion } from 'gql/queries/results';
import { getQuestionAggregate, getQuestionInfo } from 'questions';
import Button from 'uikit/Button';
import SubPage from 'uikit/SubPage/SubPage';

import ReportEmpty from '../../../../Shared/ReportEmpty';
import styles from './AggregatedAnswers.module.scss';

const cx = classNames.bind(styles);

const AggregatedAnswers = ({ course }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/Editor/AggregatedAnswers',
  });

  const {
    data: {
      editor: { status: editorStatus, questionEditing },
    },
  } = useQueryLocalEditor();

  const isVisible = editorStatus === EDITOR_STATUSES.AGGREGATED_ANSWERS;

  const { data } = useQueryResultsAggregatedByQuestion({
    variables: {
      questionId: questionEditing?.id,
    },
    skip: !isVisible,
  });

  const allQuestions =
      [].concat(
        ...course.chapters.map((chapter) => chapter.questions),
        course.questions,
      );

  const previousQuestion =
      allQuestions.find(
        (question, index) =>
          allQuestions[index + 1]?.id === questionEditing?.id,
      );

  const nextQuestion =
      allQuestions.find(
        (question, index) =>
          allQuestions[index - 1]?.id === questionEditing?.id,
      );

  const aggregatedStudyAnswers = data?.resultsAggregatedByQuestion;

  const AggregateQuestion = getQuestionAggregate(questionEditing?.type);

  const questionInfo = getQuestionInfo(questionEditing?.type);

  return (
    <SubPage
      isOpen={isVisible}
      onExit={mutationEditorClear}
      subtitle={course.title}
      title={t('title')}
    >
      <div className={cx('subpage')}>
        <div className={cx('buttons')}>
          <Button
            icon="chevron-left"
            intent="white"
            isDisabled={!previousQuestion}
            onClick={() => mutationEditorAggregatedAnswers(previousQuestion)}
            size="large"
            tooltip={t('previous-question')}
          />
          <Button
            icon="chevron-right"
            intent="white"
            isDisabled={!nextQuestion}
            onClick={() => mutationEditorAggregatedAnswers(nextQuestion)}
            size="large"
            tooltip={t('next-question')}
          />
        </div>
        {!questionInfo?.isReportable ||
        aggregatedStudyAnswers?.aggregation?.isEmpty ? (
          <ReportEmpty
            isUnsupported={!questionInfo?.isReportable}
            question={questionEditing}
            questionInfo={questionInfo}
          />
        ) : (
          aggregatedStudyAnswers?.aggregation?.type === questionInfo?.type && (
            <AggregateQuestion
              aggregation={aggregatedStudyAnswers.aggregation}
              question={questionEditing}
              questionInfo={questionInfo}
            />
          )
        )}
      </div>
    </SubPage>
  );
};

AggregatedAnswers.propTypes = {
  course: PropTypes.object.isRequired,
};

export default AggregatedAnswers;
