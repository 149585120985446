import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Collapse from 'uikit/Collapse';

import styles from './Options.module.scss';

const cx = classNames.bind(styles);

const Options = ({ children, className, t }) => (
  <Collapse className={className}>
    <Collapse.Item
      className={cx('options')}
      title={t('Questions/Shared/Options/more')}
    >
      {children}
    </Collapse.Item>
  </Collapse>
);

Options.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  t: PropTypes.func.isRequired,
};

Options.defaultProps = {
  className: undefined,
};

export default Options;
