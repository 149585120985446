import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useMutationCourseLeave } from 'gql/mutations/course';
import useModal from 'hooks/useModal';
import history from 'lib/history';
import { trackEvent, reportError } from 'lib/tracking';

import CourseLeave from './CourseLeave';

const CourseLeaveContainer = ({ course, ...props }) => {
  const { t } = useTranslation();
  const { isModalOpen, handleModalOpen, handleModalClose } = useModal(false);
  const [status, setStatus] = useState(undefined);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    setStatus(undefined);
    setLoading(false);
  }, [isModalOpen]);
  const mutationCourseLeave = useMutationCourseLeave();
  const handleCourseLeave = useCallback(
    async (evt) => {
      try {
        evt.preventDefault();
        evt.stopPropagation();
        setStatus(undefined);
        setLoading(true);
        await mutationCourseLeave(course);
        handleModalClose();
        history.push('/');
        trackEvent('course', 'leave');
      } catch (err) {
        setStatus('500');
        reportError('course', 'leave', err);
      } finally {
        setLoading(false);
      }
    },
    [course, handleModalClose, mutationCourseLeave],
  );
  return (
    <CourseLeave
      handleCourseLeave={handleCourseLeave}
      handleModalClose={handleModalClose}
      handleModalOpen={handleModalOpen}
      isLoading={isLoading}
      isModalOpen={isModalOpen}
      status={status}
      t={t}
      {...props}
    />
  );
};

CourseLeaveContainer.propTypes = {
  course: PropTypes.object.isRequired,
};

export default CourseLeaveContainer;
