import classNames from 'classnames/bind';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useMutationMeUpdate } from 'gql/mutations/me';
import { useQueryMe } from 'gql/queries/me';
import useSiteContext from 'hooks/useSiteContext';
import LOGO from 'images/logos/logo.svg';
import { trackEvent, reportError } from 'lib/tracking';
import Button from 'uikit/Button';
import FormattedTextContainer from 'uikit/FormattedText';
import Icon from 'uikit/Icon';

import styles from './DownloadApp.module.scss';

const cx = classNames.bind(styles);

const AddToHomeScreen = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'App/AddToHomeScreen',
  });

  const mutationMeUpdate = useMutationMeUpdate();

  const { browser } = useSiteContext();

  const { isLoading, data } = useQueryMe();

  async function handleClose() {
    try {
      await mutationMeUpdate({
        metadata: {
          ...metadataMe,
          ignoreApp: true,
        },
      });
      trackEvent('me:update', 'metadata:ignoreApp');
    } catch (err) {
      reportError('me:update', 'metadata:ignoreApp', err);
    }
  }

  const metadataMe = isLoading ? {} : data.me.metadata;

  const tagsMe = isLoading ? [] : data.me.tags;

  const registeredAt = isLoading ? new Date() : new Date(data.me.registeredAt);

  const today = new Date();

  const isRegisteredToday =
    registeredAt.getDate() === today.getDate() &&
    registeredAt.getMonth() === today.getMonth() &&
    registeredAt.getFullYear() === today.getFullYear();

  const isOpen = isLoading
    ? false
    : !metadataMe?.ignoreApp &&
      tagsMe.includes('onboarded') &&
      !!browser &&
      !isRegisteredToday;

  return isOpen ? (
    <aside className={cx('container')}>
      <button
        aria-label={t('aria-close')}
        className={cx('close')}
        onClick={handleClose}
        type="button"
      >
        <Icon name="times" />
      </button>
      <div className={cx('content')}>
        <img alt="wooflash-logo" className={cx('logo')} src={LOGO} />
        <FormattedTextContainer className={cx('title')}>
          {t('title')}
        </FormattedTextContainer>
        <Button
          as="a"
          className={cx('button')}
          href={
            browser === 'iOS'
              ? 'https://apps.apple.com/us/app/wooflash/6443394551'
              : 'https://play.google.com/store/apps/details?id=com.wooclap.wooflash'
          }
          intent="primary"
          rel="nofollow noreferrer noopener"
          size="small"
          target="blank"
        >
          {t('download').toUpperCase()}
        </Button>
      </div>
    </aside>
  ) : null;
};

export default AddToHomeScreen;
