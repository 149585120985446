import classNames from 'classnames/bind';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'uikit/Button';
import InputText from 'uikit/InputText';
import Modal from 'uikit/Modal';

import styles from './GroupEdit.module.scss';

const cx = classNames.bind(styles);

const GroupEdit = ({
  handleClose,
  handleSave,
  initialValues,
  isModalOpen,
  t,
  validationSchema,
}) => (
  <Modal isOpen={isModalOpen} onExit={handleClose}>
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSave}
      validationSchema={validationSchema}
    >
      {({ isSubmitting, status }) => (
        <Form className={cx('modal', 'large')}>
          <Button
            aria-label={t('Course/Teacher/Students/GroupEdit/aria-close')}
            className={cx('close')}
            icon="times"
            onClick={handleClose}
          />
          <h1 className={cx('title')}>
            {t('Course/Teacher/Students/GroupEdit/title')}
          </h1>
          {status === '500' && (
            <p className={cx('error')} role="alert">
              {t('Course/Teacher/Students/GroupEdit/group-edit-error')}
            </p>
          )}
          <InputText
            autoFocus
            className={cx('field')}
            label={t('Course/Teacher/Students/GroupEdit/title-label')}
            name="title"
            maxLength={256}
          />
          <Button
            className={cx('action')}
            intent="primary"
            isLoading={isSubmitting}
            size="large"
            type="submit"
          >
            {t('Course/Teacher/Students/GroupEdit/save-group')}
          </Button>
        </Form>
      )}
    </Formik>
  </Modal>
);

GroupEdit.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  validationSchema: PropTypes.object.isRequired,
};

export default GroupEdit;
