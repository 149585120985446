/**
 * Check if a date is today
 * @param {String} date the date to check
 * @returns {Boolean} true if the date is today
 */
export default function isToday(date) {
  if (!date) return false;
  const today = new Date();
  const dateToCheck = new Date(date);
  return (
    dateToCheck.getDate() === today.getDate() &&
    dateToCheck.getMonth() === today.getMonth() &&
    dateToCheck.getFullYear() === today.getFullYear()
  );
}
