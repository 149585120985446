import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import useSiteContext from 'hooks/useSiteContext';
import Button from 'uikit/Button';

import STEP1_STUDENT_IMAGE from './images/step1_student.svg';
import STEP2_STUDENT_IMAGE from './images/step2_student.svg';
import STEP3_STUDENT_IMAGE from './images/step3_student.svg';
import STEP1_TEACHER_IMAGE from './images/step1_teacher.svg';
import STEP2_TEACHER_IMAGE from './images/step2_teacher.svg';
import STEP3_TEACHER_IMAGE from './images/step3_teacher.svg';
import styles from './Steps.module.scss';

const cx = classNames.bind(styles);

const IMAGES = {
  1: { student: STEP1_STUDENT_IMAGE, teacher: STEP1_TEACHER_IMAGE },
  2: { student: STEP2_STUDENT_IMAGE, teacher: STEP2_TEACHER_IMAGE },
  3: { student: STEP3_STUDENT_IMAGE, teacher: STEP3_TEACHER_IMAGE },
};

const Steps = ({ currentStep, me, onNextStep }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'App/Onboarding/Tour/Steps',
  });

  const location = useLocation();

  const { isEditionDisabled } = useSiteContext();

  const isJoiningCourse = location.pathname.includes('/course/');

  const role = me.role || 'teacher';

  const handleCourseJoin = async () => {
    onNextStep();
    document.getElementById('course-join-button')?.click();
  };

  return (
    <div className={cx('container', `step${currentStep}`)}>
      <div className={cx('points')}>
        {[1, 2, 3].map((element) => (
          <div className={cx('point')} key={element} />
        ))}
      </div>
      <div className={cx('content')}>
        <img
          alt=""
          aria-hidden="true"
          className={cx('picture')}
          src={IMAGES[currentStep][role]}
        />
        <div className={cx('text')}>
          <h2 className={cx('title')}>
            {t(`${role}-step${currentStep}-title`)}
          </h2>
          {(currentStep !== 'step2' || !isEditionDisabled) && (
            <p className={cx('subtitle')}>
              {t(`${role}-step${currentStep}-subtitle`)}
            </p>
          )}
          <div className={cx('actions')}>
            {currentStep === 3 ? (
              <>
                {role === 'student' && !isJoiningCourse && !isEditionDisabled && (
                  <Button
                    className={cx('action')}
                    intent="dark"
                    onClick={handleCourseJoin}
                  >
                    {t('join')}
                  </Button>
                )}
                <Button
                  className={cx('action')}
                  data-testid="tour-steps-close-button"
                  intent={
                    isJoiningCourse || role === 'teacher' || isEditionDisabled
                      ? 'dark'
                      : 'white'
                  }
                  onClick={onNextStep}
                >
                  {t(
                    isJoiningCourse
                      ? 'access-course'
                      : isEditionDisabled
                      ? 'dashboard'
                      : 'create',
                  )}
                </Button>
              </>
            ) : (
              <Button
                className={cx('action')}
                data-testid={`tour-step-${currentStep}-next-button`}
                intent="dark"
                onClick={onNextStep}
              >
                {t('next')}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

Steps.propTypes = {
  currentStep: PropTypes.number.isRequired,
  me: PropTypes.object.isRequired,
  onNextStep: PropTypes.func.isRequired,
};

export default Steps;
