import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'uikit/Button';
import Modal from 'uikit/Modal';

import styles from './ExpertFeedbacksVerso.module.scss';

const cx = classNames.bind(styles);

const ExpertFeedbacksVerso = ({
  className,
  feedbacks,
  handleModalClose,
  handleModalOpen,
  isModalOpen,
  label,
  t,
}) => (
  <>
    {feedbacks.length > 0 ? (
      <div className={cx('button-container')}>
        <Button
          className={cx(className, 'button')}
          icon="comment-quote"
          onClick={handleModalOpen}
          tooltip={t('Questions/Shared/ExpertFeedbacksVerso/see-feedbacks')}
        />
        <div className={cx('count')}>{feedbacks.length}</div>
      </div>
    ) : (
      <p> </p>
    )}
    <Modal isOpen={isModalOpen} onExit={handleModalClose}>
      <div className={cx('modal')}>
        <Button
          aria-label={t('Questions/Shared/ExpertFeedbacksVerso/aria-close')}
          className={cx('close')}
          icon="times"
          onClick={handleModalClose}
        />
        <h1 className={cx('title')}>
          {t('Questions/Shared/ExpertFeedbacksVerso/title', { label })}
        </h1>
        {feedbacks.map((feedback) => (
          <div className={cx('feedback')}>{feedback.feedback}</div>
        ))}
      </div>
    </Modal>
  </>
);

ExpertFeedbacksVerso.propTypes = {
  className: PropTypes.string,
  feedbacks: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleModalClose: PropTypes.func.isRequired,
  handleModalOpen: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

ExpertFeedbacksVerso.defaultProps = {
  className: undefined,
};

export default ExpertFeedbacksVerso;
