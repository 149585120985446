import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'uikit/Button';
import FormattedText from 'uikit/FormattedText';

import styles from './Recto.module.scss';

const cx = classNames.bind(styles);

const Recto = ({ className, handleSpeech, isDisabled, question, t, theme }) => (
  <div className={cx('container', className, `theme-${theme}`)}>
    <div className={cx('front-container')}>
      <div className={cx('actions-container')}>
        {'speechSynthesis' in window &&
          (question.content.useSpeech !== undefined
            ? question.content.useSpeech
            : true) && (
            <Button
              aria-label={t('Questions/Flashcard/Recto/speech')}
              className={cx('speech')}
              icon="volume"
              onClick={() => handleSpeech('feedback')}
            />
          )}
      </div>
      <div
        className={cx('front-content', {
          isBigContent: question.content.inverted
            ? question.content.feedback.length < 150
            : question.title.length < 150,
        })}
      >
        <FormattedText
          as="h1"
          className={cx('front-text', {
            isBigText: question.content.inverted
              ? question.content.feedback.length < 150
              : question.title.length < 150,
          })}
        >
          {question.content.inverted
            ? question.content.feedback
            : question.title}
        </FormattedText>
      </div>
    </div>
    {!isDisabled && (
      <div className={cx('instructions')}>
        {t('Questions/Flashcard/Recto/instructions')}
      </div>
    )}
  </div>
);

Recto.propTypes = {
  className: PropTypes.string,
  handleSpeech: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  question: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
};

Recto.defaultProps = {
  className: undefined,
};

export default Recto;
