import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import PERMISSIONS from 'constants/permissions';
import {
  COURSE_DATA,
  COURSE_FULL_DATA,
  PARTICIPANT_DATA,
  PUBLIC_COURSE_DATA,
} from 'gql/fragments';

export const COURSE_CREATE = gql`
  mutation CourseCreate(
    $title: String!
    $introduction: String
    $isLinear: Boolean
    $masteryThreshold: Int
    $themeBackground: String
    $themeText: String
    $isExam: Boolean
    $isLinearExam: Boolean
    $examStartTime: Date
    $examStopTime: Date
    $examDurationMinutes: Int
    $examInRandomOrder: Boolean
    $examInstructions: String
    $isExamReviewEnabled: Boolean
    $isPublic: Boolean
    $category: String
    $language: String
    $destination: String
    $classes: [String]
    $institution: String
  ) {
    courseCreate(
      title: $title
      introduction: $introduction
      isLinear: $isLinear
      masteryThreshold: $masteryThreshold
      themeBackground: $themeBackground
      themeText: $themeText
      isExam: $isExam
      isLinearExam: $isLinearExam
      examStartTime: $examStartTime
      examStopTime: $examStopTime
      examDurationMinutes: $examDurationMinutes
      examInRandomOrder: $examInRandomOrder
      examInstructions: $examInstructions
      isExamReviewEnabled: $isExamReviewEnabled
      isPublic: $isPublic
      category: $category
      language: $language
      destination: $destination
      institution: $institution
      classes: $classes
    ) {
      ...CourseFullData
    }
  }
  ${COURSE_FULL_DATA}
`;

export function useMutationCourseCreate() {
  const [mutation] = useMutation(COURSE_CREATE);
  return useCallback(
    (variables) =>
      mutation({
        variables,
        update(cache, { data: { courseCreate } }) {
          const newCourseRef = cache.writeFragment({
            data: courseCreate,
            fragment: COURSE_FULL_DATA,
            fragmentName: 'CourseFullData',
          });
          cache.modify({
            fields: {
              coursesMe: (existingCoursesRef = [], { readField }) =>
                [...existingCoursesRef, newCourseRef].sort((a, b) =>
                  readField('title', a).localeCompare(
                    readField('title', b),
                    undefined,
                    {
                      numeric: true,
                      sensitivity: 'base',
                    },
                  ),
                ),
            },
          });
        },
      }),
    [mutation],
  );
}

export const COURSE_INVITE = gql`
  mutation CourseInvite(
    $courseId: ID!
    $groupId: ID
    $level: Int!
    $usernames: String!
  ) {
    courseInvite(
      courseId: $courseId
      groupId: $groupId
      level: $level
      usernames: $usernames
    ) {
      notSentInvitations
      participants {
        ...ParticipantData
      }
    }
  }
  ${PARTICIPANT_DATA}
`;

export function useMutationCourseInvite() {
  const [mutation] = useMutation(COURSE_INVITE);
  return useCallback(
    ({ courseId, groupId, level, ...variables }) =>
      mutation({
        variables: {
          courseId,
          groupId,
          level,
          ...variables,
        },
        update(cache, { data: { courseInvite } }) {
          const newParticipantsRefs = courseInvite.participants.map(
            (participant) =>
              cache.writeFragment({
                data: participant,
                fragment: PARTICIPANT_DATA,
              }),
          );
          if (level === PERMISSIONS.STUDENT) {
            cache.modify({
              id: groupId ? `Group:${groupId}` : `Course:${courseId}`,
              fields: {
                students: (existingStudentsRef = [], { readField }) =>
                  [...existingStudentsRef, ...newParticipantsRefs].sort(
                    (a, b) =>
                      `${readField('lastName', a)}, ${readField(
                        'firstName',
                        a,
                      )}`.localeCompare(
                        `${readField('lastName', b)}, ${readField(
                          'firstName',
                          b,
                        )}`,
                        undefined,
                        {
                          numeric: true,
                          sensitivity: 'base',
                        },
                      ),
                  ),
              },
            });
          } else {
            cache.modify({
              id: `Course:${courseId}`,
              fields: {
                collaborators: (existingCollaboratorsRef = [], { readField }) =>
                  [...existingCollaboratorsRef, ...newParticipantsRefs].sort(
                    (a, b) =>
                      `${readField('lastName', a)}, ${readField(
                        'firstName',
                        a,
                      )}`.localeCompare(
                        `${readField('lastName', b)}, ${readField(
                          'firstName',
                          b,
                        )}`,
                        undefined,
                        {
                          numeric: true,
                          sensitivity: 'base',
                        },
                      ),
                  ),
              },
            });
          }
        },
      }),
    [mutation],
  );
}

export const COURSE_JOIN_BY_CODE = gql`
  mutation CourseJoinByCode(
    $accessCode: String!
    $groupId: String
    $from: Int
  ) {
    courseJoinByCode(accessCode: $accessCode, groupId: $groupId, from: $from) {
      ...CourseFullData
    }
  }
  ${COURSE_FULL_DATA}
`;

export function useMutationCourseJoinByCode() {
  const [mutation] = useMutation(COURSE_JOIN_BY_CODE);
  return useCallback(
    (variables) =>
      mutation({
        variables,
        update(cache, { data: { courseJoinByCode } }) {
          cache.modify({
            fields: {
              coursesMe: (existingCoursesRef = [], { readField }) => {
                if (
                  existingCoursesRef.some(
                    (c) => readField('id', c) === courseJoinByCode.id,
                  )
                ) {
                  return existingCoursesRef;
                }
                const newCourseRef = cache.writeFragment({
                  data: courseJoinByCode,
                  fragment: COURSE_FULL_DATA,
                  fragmentName: 'CourseFullData',
                });
                return [...existingCoursesRef, newCourseRef].sort((a, b) =>
                  readField('title', a).localeCompare(
                    readField('title', b),
                    undefined,
                    {
                      numeric: true,
                      sensitivity: 'base',
                    },
                  ),
                );
              },
              coursesGAR: (existingCoursesRef = [], { readField }) =>
                existingCoursesRef.filter(
                  (c) => readField('id', c) !== courseJoinByCode.id,
                ),
            },
          });
        },
      }),
    [mutation],
  );
}

export const COURSE_JOIN_BY_INVITATION = gql`
  mutation CourseJoinByInvitation($invitationId: String!) {
    courseJoinByInvitation(invitationId: $invitationId) {
      ...CourseFullData
    }
  }
  ${COURSE_FULL_DATA}
`;

export function useMutationCourseJoinByInvitation() {
  const [mutation] = useMutation(COURSE_JOIN_BY_INVITATION);
  return useCallback(
    (variables) =>
      mutation({
        variables,
        update(cache, { data: { courseJoinByInvitation } }) {
          cache.modify({
            fields: {
              coursesMe: (existingCoursesRef = [], { readField }) => {
                if (
                  existingCoursesRef.some(
                    (c) => readField('id', c) === courseJoinByInvitation.id,
                  )
                ) {
                  return existingCoursesRef;
                }
                const newCourseRef = cache.writeFragment({
                  data: courseJoinByInvitation,
                  fragment: COURSE_FULL_DATA,
                  fragmentName: 'CourseFullData',
                });
                return [...existingCoursesRef, newCourseRef].sort((a, b) =>
                  readField('title', a).localeCompare(
                    readField('title', b),
                    undefined,
                    {
                      numeric: true,
                      sensitivity: 'base',
                    },
                  ),
                );
              },
            },
          });
        },
      }),
    [mutation],
  );
}

export const COURSE_DELETE = gql`
  mutation CourseDelete($courseId: ID!) {
    courseDelete(courseId: $courseId)
  }
`;

export function useMutationCourseDelete() {
  const [mutation] = useMutation(COURSE_DELETE);
  return useCallback(
    (course) =>
      mutation({
        variables: {
          courseId: course.id,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          courseDelete: true,
        },
        update(cache) {
          cache.modify({
            fields: {
              coursesMe: (existingCoursesRef = [], { readField }) =>
                existingCoursesRef.filter(
                  (c) => readField('id', c) !== course.id,
                ),
            },
          });
          cache.modify({
            fields: {
              publicCourses: (existingPublicCoursesRef = [], { readField }) =>
                course.isPublic
                  ? existingPublicCoursesRef.filter(
                      (publicCourse) =>
                        readField('id', publicCourse) !== course.id,
                    )
                  : existingPublicCoursesRef,
            },
          });
        },
      }),
    [mutation],
  );
}

export const COURSE_LEAVE = gql`
  mutation CourseLeave($courseId: ID!) {
    courseLeave(courseId: $courseId)
  }
`;

export function useMutationCourseLeave() {
  const [mutation] = useMutation(COURSE_LEAVE);
  return useCallback(
    (course) =>
      mutation({
        variables: {
          courseId: course.id,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          courseLeave: true,
        },
        update(cache) {
          cache.modify({
            fields: {
              coursesMe: (existingCoursesRef = [], { readField }) =>
                existingCoursesRef.filter(
                  (c) => readField('id', c) !== course.id,
                ),
            },
          });
        },
      }),
    [mutation],
  );
}

export const COURSE_DUPLICATE = gql`
  mutation CourseDuplicate($courseId: ID!, $prefix: String!) {
    courseDuplicate(courseId: $courseId, prefix: $prefix) {
      ...CourseFullData
    }
  }
  ${COURSE_FULL_DATA}
`;

export function useMutationCourseDuplicate() {
  const [mutation] = useMutation(COURSE_DUPLICATE);
  return useCallback(
    ({ course, prefix }) =>
      mutation({
        variables: {
          courseId: course.id,
          prefix,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          courseDuplicate: {
            ...course,
            title: `${prefix} ${course.title}`,
            id: Math.random().toString(10),
            __typename: 'Course',
          },
        },
        update(cache, { data: { courseDuplicate } }) {
          const newCourseRef = cache.writeFragment({
            data: courseDuplicate,
            fragment: COURSE_FULL_DATA,
            fragmentName: 'CourseFullData',
          });
          cache.modify({
            fields: {
              coursesMe: (existingCoursesRef = [], { readField }) =>
                [...existingCoursesRef, newCourseRef].sort((a, b) =>
                  readField('title', a).localeCompare(
                    readField('title', b),
                    undefined,
                    {
                      numeric: true,
                      sensitivity: 'base',
                    },
                  ),
                ),
            },
          });
        },
      }),
    [mutation],
  );
}

export const COURSE_UPDATE = gql`
  mutation CourseUpdate(
    $courseId: ID!
    $accessCode: String
    $title: String
    $introduction: String
    $isLinear: Boolean
    $masteryThreshold: Int
    $themeBackground: String
    $themeText: String
    $isExam: Boolean
    $isLinearExam: Boolean
    $examStartTime: Date
    $examStopTime: Date
    $examDurationMinutes: Int
    $examInRandomOrder: Boolean
    $nQuestionsByExam: Int
    $examInstructions: String
    $isExamReviewEnabled: Boolean
    $isStudentCollaborationEnabled: Boolean
    $deadline: Date
    $isPublic: Boolean
    $category: String
    $language: String
    $destination: String
    $tagIds: [ID]
    $institution: String
    $classes: [String]
  ) {
    courseUpdate(
      courseId: $courseId
      accessCode: $accessCode
      title: $title
      introduction: $introduction
      isLinear: $isLinear
      masteryThreshold: $masteryThreshold
      themeBackground: $themeBackground
      themeText: $themeText
      isExam: $isExam
      isLinearExam: $isLinearExam
      examStartTime: $examStartTime
      examStopTime: $examStopTime
      examDurationMinutes: $examDurationMinutes
      examInRandomOrder: $examInRandomOrder
      nQuestionsByExam: $nQuestionsByExam
      examInstructions: $examInstructions
      isExamReviewEnabled: $isExamReviewEnabled
      isStudentCollaborationEnabled: $isStudentCollaborationEnabled
      deadline: $deadline
      isPublic: $isPublic
      category: $category
      language: $language
      destination: $destination
      tagIds: $tagIds
      institution: $institution
      classes: $classes
    ) {
      ...CourseData
    }
  }
  ${COURSE_DATA}
`;

export function useMutationCourseUpdate() {
  const [mutation] = useMutation(COURSE_UPDATE);
  return useCallback(
    (course, variables) =>
      mutation({
        variables: {
          ...variables,
          courseId: course.id,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          courseUpdate: {
            ...course,
            ...variables,
            __typename: 'Course',
          },
        },
        update(cache, { data: { courseUpdate } }) {
          cache.modify({
            fields: {
              coursesMe: (existingCoursesRef = [], { readField }) =>
                [...existingCoursesRef].sort((a, b) =>
                  readField('title', a).localeCompare(
                    readField('title', b),
                    undefined,
                    {
                      numeric: true,
                      sensitivity: 'base',
                    },
                  ),
                ),
            },
          });
          if (
            'isPublic' in variables &&
            course.isPublic !== variables.isPublic
          ) {
            cache.modify({
              fields: {
                publicCourses: (existingPublicCoursesRef = [], { readField }) =>
                  variables.isPublic
                    ? [...existingPublicCoursesRef, courseUpdate].sort((a, b) =>
                        readField('title', a).localeCompare(
                          readField('title', b),
                          undefined,
                          {
                            numeric: true,
                            sensitivity: 'base',
                          },
                        ),
                      )
                    : [...existingPublicCoursesRef].filter(
                        (publicCourse) =>
                          readField('id', publicCourse) !== courseUpdate.id,
                      ),
              },
            });
          }
        },
      }),
    [mutation],
  );
}

export const COURSE_PIN = gql`
  mutation CoursePin($courseId: ID!) {
    coursePin(courseId: $courseId) {
      ...CourseData
    }
  }
  ${COURSE_DATA}
`;

export function useMutationCoursePin() {
  const [mutation] = useMutation(COURSE_PIN);
  return useCallback(
    (course) =>
      mutation({
        variables: {
          courseId: course.id,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          coursePin: {
            ...course,
            isPinned: !course.isPinned,
            __typename: 'Course',
          },
        },
      }),
    [mutation],
  );
}

export const COURSE_OPEN = gql`
  mutation CourseOpen($courseId: ID!) {
    courseOpen(courseId: $courseId) {
      ...CourseData
    }
  }
  ${COURSE_DATA}
`;

export function useMutationCourseOpen() {
  const [mutation] = useMutation(COURSE_OPEN);
  return useCallback(
    (course) =>
      mutation({
        variables: {
          courseId: course.id,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          courseOpen: {
            ...course,
            lastConnection: new Date(),
            __typename: 'Course',
          },
        },
      }),
    [mutation],
  );
}

export const COURSE_SORT = gql`
  mutation CourseSort($courseId: ID!, $folderId: ID) {
    courseSort(courseId: $courseId, folderId: $folderId) {
      ...CourseData
    }
  }
  ${COURSE_DATA}
`;

export function useMutationCourseSort() {
  const [mutation] = useMutation(COURSE_SORT);
  return useCallback(
    (course, variables) =>
      mutation({
        variables: {
          ...variables,
          courseId: course.id,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          courseSort: {
            ...course,
            folderId: variables.folderId,
            __typename: 'Course',
          },
        },
      }),
    [mutation],
  );
}

export const COURSE_GAR_SHARE = gql`
  mutation CourseGARShare($courseId: ID!, $div: [String], $gro: [String]) {
    courseGARShare(courseId: $courseId, div: $div, gro: $gro) {
      ...CourseData
    }
  }
  ${COURSE_DATA}
`;

export function useMutationCourseGARShare() {
  const [mutation] = useMutation(COURSE_GAR_SHARE);
  return useCallback(
    (variables) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}

export const PUBLIC_COURSE_IMPORT = gql`
  mutation PublicCourseImport($courseId: ID!, $title: String!) {
    publicCourseImport(courseId: $courseId, title: $title) {
      ...CourseData
    }
  }
  ${COURSE_DATA}
`;

export function useMutationPublicCourseImport() {
  const [mutation] = useMutation(PUBLIC_COURSE_IMPORT);
  return useCallback(
    (course, title) =>
      mutation({
        variables: {
          courseId: course.id,
          title,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          publicCourseImport: {
            ...course,
            title,
            id: Math.random().toString(10),
            __typename: 'Course',
          },
        },
        update(cache, { data: { publicCourseImport } }) {
          const newCourseRef = cache.writeFragment({
            data: publicCourseImport,
            fragment: COURSE_FULL_DATA,
            fragmentName: 'CourseFullData',
          });
          cache.modify({
            fields: {
              coursesMe: (existingCoursesRef = [], { readField }) =>
                [...existingCoursesRef, newCourseRef].sort((a, b) =>
                  readField('title', a).localeCompare(
                    readField('title', b),
                    undefined,
                    {
                      numeric: true,
                      sensitivity: 'base',
                    },
                  ),
                ),
            },
          });
        },
      }),
    [mutation],
  );
}

export const PUBLIC_COURSE_LIKE = gql`
  mutation PublicCourseLike($courseId: ID!) {
    publicCourseLike(courseId: $courseId) {
      ...PublicCourseData
    }
  }
  ${PUBLIC_COURSE_DATA}
`;

export function useMutationPublicCourseLike() {
  const [mutation] = useMutation(PUBLIC_COURSE_LIKE);
  return useCallback(
    (course) =>
      mutation({
        variables: {
          courseId: course.id,
        },
      }),
    [mutation],
  );
}

export const COURSE_SET_DEADLINE = gql`
  mutation CourseSetDeadline($courseId: ID!, $deadline: Date) {
    courseSetDeadline(courseId: $courseId, deadline: $deadline) {
      ...CourseData
    }
  }
  ${COURSE_DATA}
`;

export function useMutationCourseSetDeadline() {
  const [mutation] = useMutation(COURSE_SET_DEADLINE);
  return useCallback(
    (course, variables) =>
      mutation({
        variables: {
          ...variables,
          courseId: course.id,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          courseSetDeadline: {
            ...course,
            deadline: variables.deadline,
            __typename: 'Course',
          },
        },
      }),
    [mutation],
  );
}

export const PUBLIC_COURSE_REPORT = gql`
  mutation PublicCourseReport(
    $courseId: ID!
    $explanation: String!
    $accountId: ID
  ) {
    publicCourseReport(
      courseId: $courseId
      explanation: $explanation
      accountId: $accountId
    )
  }
`;

export function useMutationPublicCourseReport() {
  const [mutation] = useMutation(PUBLIC_COURSE_REPORT);
  return useCallback(
    (variables) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}

export const COURSE_RESET_PARTICIPANTS = gql`
  mutation CourseResetParticipants($courseId: ID!) {
    courseResetParticipants(courseId: $courseId)
  }
`;

export function useMutationCourseResetParticipants() {
  const [mutation] = useMutation(COURSE_RESET_PARTICIPANTS);
  return useCallback(
    (courseId) =>
      mutation({
        variables: {
          courseId,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          courseResetParticipants: true,
        },
        update(cache) {
          cache.modify({
            id: `Course:${courseId}`,
            fields: {
              students: () => [],
              groups: () => [],
            },
          });
          cache.modify({
            id: `CourseStats:${courseId}`,
            fields: {
              students: () => 0,
            },
          });
        },
      }),
    [mutation],
  );
}
