import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Icon from 'uikit/Icon';

import StudentCard from '../StudentCard';
import styles from './GroupCard.module.scss';

const cx = classNames.bind(styles);

const GroupCard = ({
  className,
  course,
  group,
  handleGroupExpandedToggle,
  isExpanded,
  t,
}) => (
  <div className={cx('container', className)}>
    <div className={cx('group')}>
      <button
        aria-label={
          isExpanded
            ? t('Course/Teacher/Students/Finder/GroupCard/aria-close-group')
            : t('Course/Teacher/Students/Finder/GroupCard/aria-open-group')
        }
        className={cx('icon')}
        onClick={handleGroupExpandedToggle}
        type="button"
      >
        {isExpanded ? (
          <Icon name="chevron-down" />
        ) : (
          <Icon name="chevron-right" />
        )}
      </button>
      <p className={cx('title')}>{group.title}</p>
    </div>
    {isExpanded && (
      <div className={cx('students')}>
        {group.students.map((student) => (
          <StudentCard
            className={cx('student')}
            course={course}
            student={student}
          />
        ))}
      </div>
    )}
  </div>
);

GroupCard.propTypes = {
  className: PropTypes.string,
  course: PropTypes.object.isRequired,
  group: PropTypes.object.isRequired,
  handleGroupExpandedToggle: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

GroupCard.defaultProps = {
  className: undefined,
};

export default GroupCard;
