import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'uikit/Button';

import styles from './AudioPreview.module.scss';

const cx = classNames.bind(styles);

const AudioPreview = ({
  audio,
  audioDuration,
  audioRef,
  className,
  footer,
  handleAudioEnd,
  handleAudioDurationSet,
  handleCurrentTimeUpdate,
  handlePlayToggle,
  isPlaying,
  theme,
}) => (
  <div
    className={cx(
      'container',
      className,
      `theme-${theme}`,
    )}
  >
    <div className={cx('preview')}>
      <Button
        className={cx('play')}
        icon={isPlaying ? 'pause' : 'play'}
        intent="primary"
        onClick={handlePlayToggle}
        size="large"
      />
      <audio
        className={cx('audio')}
        controlsList="nodownload"
        id="audio-player"
        key={audio}
        onEnded={handleAudioEnd}
        onDurationChange={handleAudioDurationSet}
        onTimeUpdate={handleCurrentTimeUpdate}
        ref={audioRef}
      >
        <source src={audio} />
        <track default kind="captions" />
      </audio>
      <div className={cx('duration')}>{audioDuration}</div>
    </div>
    {footer && <div className={cx('footer')}>{footer}</div>}
  </div>
);

AudioPreview.propTypes = {
  audio: PropTypes.string.isRequired,
  audioDuration: PropTypes.string.isRequired,
  audioRef: PropTypes.object,
  className: PropTypes.string,
  footer: PropTypes.node,
  handlePlayToggle: PropTypes.func.isRequired,
  handleAudioEnd: PropTypes.func.isRequired,
  handleCurrentTimeUpdate: PropTypes.func.isRequired,
  handleAudioDurationSet: PropTypes.func.isRequired,
  isPlaying: PropTypes.bool.isRequired,
  theme: PropTypes.string.isRequired,
};

AudioPreview.defaultProps = {
  audioRef: undefined,
  className: undefined,
  footer: undefined,
};

export default AudioPreview;
