import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Icon from 'uikit/Icon';

import styles from './MenuItem.module.scss';

const cx = classNames.bind(styles);

const MenuItem = React.forwardRef(
  (
    {
      as: Component,
      children,
      className,
      htmlType,
      icon,
      isActive,
      isDisabled,
      variant,
      ...props
    },
    ref,
  ) => {
    const additionalProps = {};
    if (Component === 'button') {
      additionalProps.disabled = isDisabled;
      additionalProps.type = htmlType;
    }
    return (
      <Component
        className={cx(
          'container',
          {
            isActive,
          },
          className,
        )}
        ref={ref}
        role="menuitem"
        {...additionalProps}
        {...props}
      >
        {icon &&
          (typeof icon === 'string' ? (
            <Icon
              className={cx('icon')}
              fixedWidth
              name={icon}
              variant={variant}
            />
          ) : (
            icon
          ))}
        <span>{children}</span>
      </Component>
    );
  },
);

MenuItem.propTypes = {
  as: PropTypes.oneOfType([PropTypes.elementType, PropTypes.string]),
  children: PropTypes.node,
  className: PropTypes.string,
  htmlType: PropTypes.oneOf(['button', 'submit', 'reset']),
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
  variant: PropTypes.oneOf(['solid', 'regular', 'light', 'brand', 'duotone']),
};

MenuItem.defaultProps = {
  as: 'button',
  children: undefined,
  className: undefined,
  htmlType: 'button',
  icon: undefined,
  isActive: false,
  isDisabled: false,
  variant: undefined,
};

export default MenuItem;
