import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Icon from 'uikit/Icon';

import QuestionsImporter from './QuestionImporter/QuestionImporter';
import styles from './CourseCard.module.scss';

const cx = classNames.bind(styles);

const CourseCard = ({ course = null, selectedCourse }) => (
  <div className={cx('container')}>
    <div className={cx('icon')}>
      <Icon name="book" />
    </div>
    <h3 className={cx('title')}>{selectedCourse.title}</h3>
    <QuestionsImporter course={course} selectedCourse={selectedCourse} />
  </div>
);

CourseCard.propTypes = {
  course: PropTypes.object,
  selectedCourse: PropTypes.object.isRequired,
};

export default CourseCard;
