import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Icon from 'uikit/Icon';

import QuestionToApprove from './QuestionToApprove/QuestionToApprove';
import styles from './ChapterToApprove.module.scss';

const cx = classNames.bind(styles);

const ChapterToApprove = ({ questions }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/Editor/Finder/ChapterToApprove',
  });

  const [isExpanded, setIsExpanded] = useState(false);

  const handleChapterExpandedToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={cx('container')}>
      <div className={cx('card')}>
        <div className={cx('chapter')}>
          <button
            aria-label={t(`aria-${isExpanded ? 'close' : 'open'}-chapter`)}
            className={cx('icon')}
            onClick={handleChapterExpandedToggle}
            type="button"
          >
            <Icon
              name={`chevron-${isExpanded ? 'down' : 'right'}`}
              variant="solid"
            />
          </button>
          <button
            className={cx('title')}
            onClick={handleChapterExpandedToggle}
            type="button"
          >
            {t('title')}
            <span className={cx('count')}>{questions.length}</span>
          </button>
        </div>
        {isExpanded && (
          <div className={cx('questions')}>
            {questions.map((question, index) => (
              <QuestionToApprove
                position={index + 1}
                key={question.id}
                question={question}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

ChapterToApprove.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ChapterToApprove;
