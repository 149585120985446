import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Icon from 'uikit/Icon';

import styles from './Avatar.module.scss';

const cx = classNames.bind(styles);

const Avatar = ({ isTeacher, profile, size, useInitial }) => (
  <div
    className={cx('avatar')}
    style={{
      backgroundImage: `url(${profile.picture})`,
      height: `${size || 34}px`,
      width: `${size || 34}px`,
    }}
  >
    {!profile.picture &&
      (useInitial ? (
        <div className={cx('initial')}>
          {profile && profile.firstName && profile.firstName[0]
            ? profile.firstName[0].toUpperCase()
            : ''}
        </div>
      ) : (
        <Icon className={cx('icon')} name="user" />
      ))}
    {isTeacher && (
      <div className={cx('teacher-icon-container')}>
        <Icon className={cx('icon', 'teacher')} name="user-shield" />
      </div>
    )}
  </div>
);

Avatar.propTypes = {
  isTeacher: PropTypes.bool,
  profile: PropTypes.object.isRequired,
  size: PropTypes.number,
  useInitial: PropTypes.bool,
};

Avatar.defaultProps = {
  isTeacher: undefined,
  size: undefined,
  useInitial: undefined,
};

export default Avatar;
