import froalaeditor from 'froala-editor';

froalaeditor.POPUP_TEMPLATES['greekPlugin.popup'] = '[_CUSTOM_LAYER_]';

froalaeditor.PLUGINS.greekPlugin = (editor) => {
  function initPopup() {
    const template = {
      custom_layer: `
        <div class="fr-popup fr-active">
          <div class="fr-icon-container fr-sc-container">
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addGreek" data-param1="α">α</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addGreek" data-param1="β">β</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addGreek" data-param1="γ">γ</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addGreek" data-param1="Γ">Γ</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addGreek" data-param1="δ">δ</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addGreek" data-param1="Δ">Δ</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addGreek" data-param1="ε">ε</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addGreek" data-param1="ζ">ζ</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addGreek" data-param1="η">η</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addGreek" data-param1="θ">θ</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addGreek" data-param1="Θ">Θ</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addGreek" data-param1="ϑ">ϑ</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addGreek" data-param1="ι">ι</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addGreek" data-param1="κ">κ</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addGreek" data-param1="λ">λ</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addGreek" data-param1="Λ">Λ</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addGreek" data-param1="μ">μ</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addGreek" data-param1="ν">ν</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addGreek" data-param1="ξ">ξ</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addGreek" data-param1="Ξ">Ξ</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addGreek" data-param1="π">π</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addGreek" data-param1="Π">Π</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addGreek" data-param1="ρ">ρ</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addGreek" data-param1="σ">σ</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addGreek" data-param1="Σ">Σ</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addGreek" data-param1="τ">τ</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addGreek" data-param1="υ">υ</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addGreek" data-param1="ϕ">ϕ</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addGreek" data-param1="Φ">Φ</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addGreek" data-param1="φ">φ</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addGreek" data-param1="χ">χ</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addGreek" data-param1="ψ">ψ</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addGreek" data-param1="Ψ">Ψ</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addGreek" data-param1="ω">ω</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addGreek" data-param1="Ω">Ω</span>
          </div>
        </div>`,
    };
    return editor.popups.create('greekPlugin.popup', template);
  }
  function showPopup() {
    let $popup = editor.popups.get('greekPlugin.popup');
    if (!$popup) {
      $popup = initPopup();
    }
    editor.popups.setContainer('greekPlugin.popup', editor.$tb);
    const $btn = editor.$tb.find('.fr-command[data-cmd="insertGreek"]');
    const { left } = $btn.offset();
    const top =
      $btn.offset().top +
      (editor.opts.toolbarBottom ? 10 : $btn.outerHeight() - 10);
    editor.popups.show('greekPlugin.popup', left, top, $btn.outerHeight());
  }
  function hidePopup() {
    editor.popups.hide('greekPlugin.popup');
  }
  return {
    showPopup,
    hidePopup,
  };
};

froalaeditor.DefineIcon('greekIcon', {
  FA5NAME: 'omega',
  template: 'font_awesome_5',
});

froalaeditor.RegisterCommand('insertGreek', {
  title: 'Lettres grècques',
  icon: 'greekIcon',
  showOnMobile: true,
  undo: false,
  focus: false,
  callback: function callback() {
    this.greekPlugin.showPopup();
  },
});

froalaeditor.RegisterCommand('addGreek', {
  undo: true,
  focus: true,
  callback: function callback(cmd, val) {
    this.html.insert(` ${val} `);
    this.greekPlugin.hidePopup();
  },
});
