import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import ChapterProgress from './ChapterProgress';

const ChapterProgressContainer = ({ chapter, course, isStudent, ...props }) => {
  const { t } = useTranslation();
  const chapterWithStats = useMemo(() => {
    if (isStudent) {
      const { questions } = chapter;
      const mastery =
        Math.round(
          (questions.reduce((m, q) => m + q.studyStats.mastery, 0) /
            (course.masteryThreshold * questions.length)) *
            100,
        ) || 0;
      const progression =
        Math.round(
          (questions.filter((q) => q.studyStats.attempted).length /
            questions.length) *
            100,
        ) || 0;
      const success =
        Math.round(
          (questions.filter((q) => q.studyStats.succeeded).length /
            questions.filter((q) => q.studyStats.attempted).length) *
            100,
        ) || 0;
      return { ...chapter, mastery, progression, success };
    }
    return chapter;
  }, [chapter, course, isStudent]);
  return <ChapterProgress chapter={chapterWithStats} t={t} {...props} />;
};

ChapterProgressContainer.propTypes = {
  chapter: PropTypes.object.isRequired,
  course: PropTypes.object.isRequired,
  isStudent: PropTypes.bool,
};

ChapterProgressContainer.defaultProps = {
  isStudent: false,
};

export default ChapterProgressContainer;
