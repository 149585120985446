import React from 'react';
import { useTranslation } from 'react-i18next';

import { useQueryLocalApp } from 'gql/queries/local';
import useSiteContext from 'hooks/useSiteContext';

import AccountMenu from './AccountMenu';

const AccountMenuContainer = (props) => {
  const { t } = useTranslation();
  const { isGAR, isInTeams } = useSiteContext();
  const {
    data: {
      app: { isMenuExpanded },
    },
  } = useQueryLocalApp();
  return (
    <AccountMenu
      isGAR={isGAR}
      isInTeams={isInTeams}
      isMenuExpanded={isMenuExpanded}
      t={t}
      {...props}
    />
  );
};

export default AccountMenuContainer;
