import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import Students from './Students';

const StudentsContainer = ({ course, groupId, ...props }) => {
  const students = useMemo(
    () =>
      (groupId === null
        ? course.students
        : course.groups.find((c) => c.id === groupId)?.students) || [],
    [course, groupId],
  );
  return <Students course={course} students={students} {...props} />;
};

StudentsContainer.propTypes = {
  course: PropTypes.object.isRequired,
  groupId: PropTypes.string,
};

StudentsContainer.defaultProps = {
  groupId: null,
};

export default React.memo(StudentsContainer);
