import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './Tabs.module.scss';

const cx = classNames.bind(styles);

const Tabs = ({ activeTab, children, className, handleTabChange }) => (
  <>
    <div className={cx('tab-list', className)} role="tablist">
      {React.Children.toArray(children)
        .filter((child) => !!child)
        .map(
          (child, index) =>
            child && (
              <button
                aria-selected={activeTab === index}
                className={cx('tab-list-item', {
                  isActive: activeTab === index,
                })}
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                onClick={() => handleTabChange(index)}
                type="button"
                role="tab"
              >
                {child.props.label}
              </button>
            ),
        )}
    </div>
    <div className={cx('tab-content')}>
      {React.Children.toArray(children)
        .filter((child) => !!child)
        .map(
          (child, index) =>
            child && index === activeTab && child.props.children,
        )}
    </div>
  </>
);

Tabs.propTypes = {
  activeTab: PropTypes.number.isRequired,
  children: PropTypes.instanceOf(Array).isRequired,
  className: PropTypes.string,
  handleTabChange: PropTypes.func.isRequired,
};

Tabs.defaultProps = {
  className: undefined,
};

export default Tabs;
