import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'uikit/Button/Button';
import Icon from 'uikit/Icon/Icon';

import TagProgress from './TagProgress/TagProgress';
import styles from './Tags.module.scss';

const cx = classNames.bind(styles);

const Tags = ({ className = null, tags }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/Statistics/Tags',
  });

  const [isSectionOpen, SetIsSectionOpen] = useState(false);

  const [showMore, setShowMore] = useState(false);

  const handleSectionToggle = () => {
    SetIsSectionOpen(!isSectionOpen);
  };

  const handleShowMoreToggle = () => setShowMore(!showMore);

  const displayedTags = tags.filter((_, i) => i <= 5 || showMore);

  return (
    <div className={cx('container', className, { isSectionOpen })}>
      <button
        className={cx('header')}
        onClick={handleSectionToggle}
        type="button"
      >
        <div className={cx('title-container')}>
          <Icon className={cx('title-icon')} name="tag" />
          <h2 className={cx('title')}>
            {t('title')}
            <span className={cx('count')}>{tags.length}</span>
          </h2>
        </div>
        <Icon
          className={cx('toggle-button')}
          name={isSectionOpen ? 'chevron-up' : 'chevron-down'}
        />
      </button>
      {isSectionOpen && (
        <div className={cx('tags-container')}>
          <div className={cx('tags')}>
            {displayedTags.map((tag) => (
              <TagProgress tag={tag} key={tag.id} />
            ))}
          </div>
          {tags.length > 5 && (
            <Button
              className={cx('tags-toggle')}
              onClick={handleShowMoreToggle}
            >
              {t(showMore ? 'show-less' : 'show-more')}
              <Icon
                className={cx('tags-toggle-icon')}
                name={showMore ? 'chevron-up' : 'chevron-down'}
              />
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

Tags.propTypes = {
  className: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Tags;
