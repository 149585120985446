import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import Files from 'components/Shared/Files/Files';
import EDITOR_STATUSES from 'constants/editorStatuses';
import {
  mutationEditorReset,
  mutationEditorChapterSelect,
  mutationEditorChapterUpdate,
  mutationEditorChapterMove,
} from 'gql/mutations/editor';
import { useMutationChapterDuplicate } from 'gql/mutations/chapter';
import { useQueryLocalEditor } from 'gql/queries/local';
import { trackEvent, reportError } from 'lib/tracking';
import Button from 'uikit/Button';
import Icon from 'uikit/Icon';
import Menu, { MenuItem } from 'uikit/Menu';
import { notify } from 'uikit/Notifications';
import Tooltip from 'uikit/Tooltip';

import ChapterDelete from './ChapterDelete/ChapterDelete';
import Questions from '../../Questions/Questions';
import styles from './ChapterCard.module.scss';

const cx = classNames.bind(styles);

const ChapterCard = ({ chapter, course, className = null }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/Editor/Finder/ChapterCard',
  });

  const mutationChapterDuplicate = useMutationChapterDuplicate();

  const {
    data: {
      editor: { status: editorStatus, chapterSelected, questionEditing },
    },
  } = useQueryLocalEditor();

  const [isExpandedLocal, setExpanded] = useState(false);

  const handleChapterDuplicate = async () => {
    try {
      mutationEditorReset();
      await mutationChapterDuplicate(chapter);
      trackEvent('chapter', 'duplicate');
    } catch (err) {
      notify('error', t('chapter-duplicate-error'));
      reportError('chapter', 'duplicate', err);
    }
  };

  const handleChapterExpandedToggle = (evt) => {
    evt.stopPropagation();
    setExpanded((state) => !state);
  };

  const handleChapterMove = () => {
    mutationEditorChapterMove(chapter);
  };

  const handleChapterSelect = () => {
    mutationEditorChapterSelect(chapter);
    setExpanded((state) => !state);
  };

  const handleChapterUpdate = () => {
    mutationEditorChapterUpdate(chapter);
  };

  const isSelected = chapterSelected?.id === chapter.id;

  const isExpanded =
    isExpandedLocal ||
    (editorStatus === EDITOR_STATUSES.QUESTION_CREATE &&
      chapterSelected?.id === chapter.id) ||
    (editorStatus === EDITOR_STATUSES.QUESTION_UPDATE &&
      questionEditing?.chapterId === chapter.id);

  return (
    <Draggable draggableId={chapter.id} index={chapter.order}>
      {(providedChapter, snapshotChapter) => (
        <Droppable droppableId={chapter.id} type="question">
          {(providedQuestions, snapshotQuestions) => (
            <div
              {...providedChapter.draggableProps}
              aria-label={chapter.title}
              className={cx(
                'container',
                {
                  isDragging: snapshotChapter.isDragging,
                  isDraggingOver: snapshotQuestions.isDraggingOver,
                  isSelected,
                },
                className,
              )}
              ref={providedChapter.innerRef}
              role="button"
            >
              <p
                className={cx('aria-drag-description')}
                id="chapter-aria-drag-description"
              >
                {t('aria-drag-description')}
              </p>
              <div
                ref={providedQuestions.innerRef}
                {...providedQuestions.droppableProps}
              >
                <div className={cx('chapter')}>
                  <div
                    {...providedChapter.dragHandleProps}
                    aria-describedby="chapter-aria-drag-description"
                    aria-label={t('aria-sort', { name: chapter.title })}
                    className={cx('sort')}
                    role="button"
                  >
                    <Icon name="bars" />
                  </div>
                  <button
                    aria-label={t(
                      `aria-${isExpanded ? 'close' : 'open'}-chapter`,
                      { name: chapter.title },
                    )}
                    className={cx('icon')}
                    onClick={handleChapterExpandedToggle}
                    type="button"
                  >
                    <Icon
                      name={isExpanded ? 'chevron-down' : 'chevron-right'}
                      variant="solid"
                    />
                  </button>
                  <div className={cx('chapter-icon')}>
                    {snapshotChapter.isDragging ? (
                      <Icon name="sort" />
                    ) : chapter.title === t('ia-chapter-title') ? (
                      <Icon className={cx('ia-icon')} name="book-sparkles" />
                    ) : (
                      <Icon name="folder" />
                    )}
                  </div>
                  {chapter.questions.some((question) =>
                    question.comments.some((comment) => !comment.isRead),
                  ) && <div className={cx('comment-notification')} />}
                  <p
                    className={cx('title')}
                    onClick={handleChapterSelect}
                    role="button"
                  >
                    {chapter.title}
                  </p>
                  {chapter.isHidden && (
                    <Tooltip tooltip={t('hidden')}>
                      <Icon className={cx('hidden')} name="eye-slash" />
                    </Tooltip>
                  )}
                  {chapter.isLinked && (
                    <Tooltip tooltip={t('linked')}>
                      <Icon className={cx('linked')} name="link" />
                    </Tooltip>
                  )}
                  <Menu
                    placement="bottom-end"
                    toggle={
                      <Button
                        aria-label={t('aria-options', { name: chapter.title })}
                        className={cx('action')}
                        icon="ellipsis-v"
                        size="small"
                        tooltip={t('options', { name: chapter.title })}
                      />
                    }
                  >
                    <MenuItem icon="edit" onClick={handleChapterUpdate}>
                      {t('update-chapter')}
                    </MenuItem>
                    <MenuItem
                      icon="arrow-square-right"
                      onClick={handleChapterMove}
                    >
                      {t('move-chapter')}
                    </MenuItem>
                    <MenuItem icon="copy" onClick={handleChapterDuplicate}>
                      {t('duplicate-chapter')}
                    </MenuItem>
                    <ChapterDelete chapter={chapter} />
                  </Menu>
                </div>
                {isExpanded && (
                  <>
                    <Files chapterId={chapter.id} course={course} isTeacher />
                    <div
                      className={cx('questions', {
                        isDraggingOver: snapshotQuestions.isDraggingOver,
                      })}
                    >
                      <Questions chapterId={chapter.id} course={course} />
                      {providedQuestions.placeholder}
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </Droppable>
      )}
    </Draggable>
  );
};

ChapterCard.propTypes = {
  chapter: PropTypes.object.isRequired,
  className: PropTypes.string,
  course: PropTypes.object.isRequired,
};

export default ChapterCard;
