import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import Context from 'lib/context';

import Recto from './Recto';

const RectoContainer = (props) => {
  const { t } = useTranslation();
  const { theme } = useContext(Context);
  const [isRecording, setIsRecording] = useState(false);
  const handleMessageDisplay = useCallback(() => {
    setIsRecording(true);
  }, []);
  const handleMessageHide = useCallback(() => {
    setIsRecording(false);
  }, []);
  return (
    <Recto
      handleMessageDisplay={handleMessageDisplay}
      handleMessageHide={handleMessageHide}
      isRecording={isRecording}
      t={t}
      theme={theme}
      {...props}
    />
  );
};

RectoContainer.defaultAnswer = () => '';

RectoContainer.validationSchema = () =>
  yup.object().shape({
    answer: yup.string().trim().required().url(),
  });

export default RectoContainer;
