import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import useModal from 'hooks/useModal';

import CourseExport from './CourseExport';

const CourseExportContainer = ({ course, ...props }) => {
  const { t } = useTranslation();
  const { isModalOpen, handleModalOpen, handleModalClose } = useModal(false);
  return (
    <CourseExport
      course={course}
      handleModalClose={handleModalClose}
      handleModalOpen={handleModalOpen}
      isModalOpen={isModalOpen}
      t={t}
      {...props}
    />
  );
};

CourseExportContainer.propTypes = {
  course: PropTypes.object.isRequired,
};

export default CourseExportContainer;
