import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import Context from 'lib/context';

import Recto from './Recto';

const RectoContainer = (props) => {
  const { t } = useTranslation();
  const { theme } = useContext(Context);
  return <Recto t={t} theme={theme} {...props} />;
};

RectoContainer.defaultAnswer = () => '';

RectoContainer.validationSchema = () =>
  yup.object().shape({
    answer: yup.string().trim().required(),
  });

export default RectoContainer;
