/**
 * Extract data from a source using a schema
 * @param {Object} source the data source from which to extract
 * @param {Object} schema the schema
 * @returns {Object} the extracted data
 */
export default function extract(source, schema) {
  if (!schema.isValidSync(source)) {
    throw new Error(400);
  }
  return schema.cast(source, {
    stripUnknown: true,
  });
}
