import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import PERMISSIONS from 'constants/permissions';
import TutorialIllustration1 from 'images/tutorials/illustrationTutorial1.svg';
import TutorialIllustration2 from 'images/tutorials/illustrationTutorial2.svg';
import Icon from 'uikit/Icon';
import Tooltip from 'uikit/Tooltip';

import Illustration1 from './images/illustration1.svg';
import Illustration2 from './images/illustration2.svg';
import Illustration3 from './images/illustration3.svg';
import Illustration4 from './images/illustration4.svg';
import styles from './CourseCard.module.scss';

const cx = classNames.bind(styles);

const IMAGES = [Illustration1, Illustration2, Illustration3, Illustration4];

const CourseCard = ({
  className,
  course,
  handleCoursePin,
  illustrationIndex,
  needTutorialIllustration1,
  needTutorialIllustration2,
  t,
}) => (
  <>
    <Link
      className={cx(
        'card-container',
        `theme-${course.themeBackground}`,
        className,
      )}
      to={`/course/${course.id}/`}
    >
      <img
        className={cx('picture')}
        alt=""
        src={
          needTutorialIllustration1
            ? TutorialIllustration1
            : needTutorialIllustration2
            ? TutorialIllustration2
            : IMAGES[illustrationIndex]
        }
      />
      <p className={cx('title')}>{course.title}</p>
      <div className={cx('footer')}>
        <p className={cx('owner')}>
          {t('Dashboard/CourseCard/author', {
            firstName: course.owner.firstName,
            lastName: course.owner.lastName,
          })}
        </p>
        {course.level >= PERMISSIONS.COLLABORATOR && (
          <p className={cx('participants')}>
            <Icon className={cx('participants-icon')} name="chalkboard-user" />
            {/* {course.courseStats.students} */}
          </p>
        )}
      </div>
    </Link>
    <Tooltip
      tooltip={
        course.isPinned
          ? t('Dashboard/CourseCard/remove-favorite')
          : t('Dashboard/CourseCard/add-favorite')
      }
    >
      <button
        aria-label={
          course.isPinned
            ? t('Dashboard/CourseCard/aria-remove-favorite', {
                name: course.title,
              })
            : t('Dashboard/CourseCard/aria-add-favorite', {
                name: course.title,
              })
        }
        aria-checked={course.isPinned}
        className={cx('pin')}
        onClick={handleCoursePin}
        tooltip={
          course.isPinned
            ? t('Dashboard/CourseCard/remove-favorite')
            : t('Dashboard/CourseCard/add-favorite')
        }
        type="button"
        role="checkbox"
      >
        <Icon name="star" variant={course.isPinned ? 'solid' : 'regular'} />
      </button>
    </Tooltip>
  </>
);

CourseCard.propTypes = {
  className: PropTypes.string,
  course: PropTypes.object.isRequired,
  handleCoursePin: PropTypes.func.isRequired,
  illustrationIndex: PropTypes.number.isRequired,
  needTutorialIllustration1: PropTypes.bool.isRequired,
  needTutorialIllustration2: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

CourseCard.defaultProps = {
  className: undefined,
};

export default CourseCard;
