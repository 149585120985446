import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import Button from 'uikit/Button';
import Icon from 'uikit/Icon';
import Tooltip from 'uikit/Tooltip';

import Files from '../../Files/Files';
import Questions from '../Questions';
import styles from './ChapterCard.module.scss';

const cx = classNames.bind(styles);

const ChapterCard = ({
  chapter,
  className,
  course,
  handleChapterExpandedToggle,
  isExpanded,
  isQuestionBank,
  t,
}) => (
  <div className={cx(className, 'container')}>
    <div className={cx('chapter')}>
      <button
        aria-label={
          isExpanded
            ? t('Shared/CourseContent/ChapterCard/aria-close-chapter')
            : t('Shared/CourseContent/ChapterCard/aria-open-chapter')
        }
        className={cx('chapter-button')}
        onClick={handleChapterExpandedToggle}
        type="button"
      >
        <div className={cx('icon')}>
          {isExpanded ? (
            <Icon name="chevron-down" variant="solid" />
          ) : (
            <Icon name="chevron-right" variant="solid" />
          )}
        </div>
        {chapter.questions.some((question) =>
          question.comments?.some((comment) => !comment.isRead),
        ) && <div className={cx('comment-notification')} />}
        <p className={cx('title')}>{chapter.title}</p>
        {chapter.isLinked && (
          <Tooltip tooltip={t('Shared/CourseContent/ChapterCard/linked')}>
            <Icon className={cx('linked')} name="link" />
          </Tooltip>
        )}
      </button>
      {!isQuestionBank && (
        <Button
          aria-label={t('Shared/CourseContent/ChapterCard/aria-study', {
            name: chapter.title,
          })}
          as={Link}
          className={cx('action')}
          to={`/study/${course.id}?chapterId=${chapter.id}&method=wooflash`}
        >
          {t('Shared/CourseContent/ChapterCard/study')}
        </Button>
      )}
    </div>
    {isExpanded && (
      <>
        <Files
          chapterId={chapter.id}
          course={course}
          isQuestionBank={isQuestionBank}
        />
        <Questions
          className={cx('questions')}
          chapterId={chapter.id}
          course={course}
        />
      </>
    )}
  </div>
);

ChapterCard.propTypes = {
  chapter: PropTypes.object.isRequired,
  className: PropTypes.string,
  course: PropTypes.object.isRequired,
  handleChapterExpandedToggle: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  isQuestionBank: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

ChapterCard.defaultProps = {
  className: undefined,
};

export default ChapterCard;
