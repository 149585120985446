import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import {
  getDenormalizedPinPosition,
  getDenormalizedZonePosition,
} from 'questions/Shared/utils/images';
import LegendPin from './LegendPin';
import Zone from './Zone';
import styles from './ImageContainer.module.scss';

const cx = classNames.bind(styles);

const ImageContainer = ({
  activeElementRef,
  className,
  handleImageLoaded,
  handleMouseDown,
  handleMouseMove,
  handleZoneDelete,
  image,
  imageRef,
  imageSrc,
  legends,
  wrapperRef,
  zones,
}) => (
  <div className={cx('container', className)} ref={wrapperRef}>
    <button
      className={cx('wrapper')}
      onMouseDown={(event) => handleMouseDown(event, -1, 'create', 'new')}
      onTouchStart={(event) => handleMouseDown(event, -1, 'create', 'new')}
      onMouseMove={handleMouseMove}
      onTouchMove={handleMouseMove}
      type="button"
    >
      <img
        alt="img"
        className={cx('image')}
        draggable="false"
        onLoad={handleImageLoaded}
        ref={imageRef}
        src={imageSrc}
        style={{
          width: image.width,
          height: image.height,
        }}
      />
    </button>
    {zones.map((zone, index) => (
      <Zone
        handleDelete={() => handleZoneDelete(index)}
        handleMouseMove={handleMouseMove}
        handleStartGrab={(event) =>
          handleMouseDown(event, index, 'position', 'zone')
        }
        handleStartResize={(event) =>
          handleMouseDown(event, index, 'resize', 'zone')
        }
        image={image}
        imageSrc={imageSrc}
        isActive={
          activeElementRef &&
          activeElementRef.type === 'zone' &&
          activeElementRef.index === index
        }
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        sizeData={getDenormalizedZonePosition(zone, image.diagonal)}
      />
    ))}
    {legends.map((legend, index) => (
      <LegendPin
        handleMouseMove={handleMouseMove}
        handleStartGrab={(event) =>
          handleMouseDown(event, index, 'position', 'legend')
        }
        index={index}
        isActive={
          activeElementRef &&
          activeElementRef.type === 'legend' &&
          activeElementRef.index === index
        }
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        positionData={getDenormalizedPinPosition(legend, image.diagonal)}
      />
    ))}
  </div>
);

ImageContainer.propTypes = {
  activeElementRef: PropTypes.object,
  className: PropTypes.string,
  handleImageLoaded: PropTypes.func.isRequired,
  handleMouseDown: PropTypes.func.isRequired,
  handleMouseMove: PropTypes.func.isRequired,
  handleZoneDelete: PropTypes.func.isRequired,
  image: PropTypes.object.isRequired,
  imageRef: PropTypes.object.isRequired,
  imageSrc: PropTypes.string.isRequired,
  legends: PropTypes.arrayOf(PropTypes.object).isRequired,
  wrapperRef: PropTypes.object.isRequired,
  zones: PropTypes.arrayOf(PropTypes.object).isRequired,
};

ImageContainer.defaultProps = {
  activeElementRef: undefined,
  className: undefined,
};

export default ImageContainer;
