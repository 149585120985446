const SUPPORTED_LANGUAGES = [
  { name: 'Français', code: 'fr-FR' },
  { name: 'English', code: 'en-GB' },
  { name: 'Nederlands', code: 'nl-NL' },
  { name: 'Italiano', code: 'it-IT' },
  { name: 'Español', code: 'es-ES' },
  { name: '--------------------', code: '' },
  { name: 'English (United States)', code: 'en-US' },
  { name: 'Español (México)', code: 'es-MX' },
  { name: 'Deutsch', code: 'de-DE' },
  { name: 'Afrikaans', code: 'af-ZA' },
  { name: 'አማርኛ', code: 'am-ET' },
  { name: 'Azərbaycanca', code: 'az-AZ' },
  { name: 'বাংলা', code: 'bn-BD' },
  { name: 'Bahasa Indonesia', code: 'id-ID' },
  { name: 'Bahasa Melayu', code: 'ms-MY' },
  { name: 'Català', code: 'ca-ES' },
  { name: 'Čeština', code: 'cs-CZ' },
  { name: 'Dansk', code: 'da-DK' },
  { name: 'Euskara', code: 'eu-ES' },
  { name: 'Filipino', code: 'fil-PH' },
  { name: 'Basa Jawa', code: 'jv-ID' },
  { name: 'Galego', code: 'gl-ES' },
  { name: 'ગુજરાતી', code: 'gu-IN' },
  { name: 'Hrvatski', code: 'hr-HR' },
  { name: 'IsiZulu', code: 'zu-ZA' },
  { name: 'Íslenska', code: 'is-IS' },
  { name: 'ಕನ್ನಡ', code: 'kn-IN' },
  { name: 'ភាសាខ្មែរ', code: 'km-KH' },
  { name: 'Latviešu', code: 'lv-LV' },
  { name: 'Lietuvių', code: 'lt-LT' },
  { name: 'മലയാളം', code: 'ml-IN' },
  { name: 'मराठी', code: 'mr-IN' },
  { name: 'Magyar', code: 'hu-HU' },
  { name: 'ລາວ', code: 'lo-LA' },
  { name: 'नेपाली भाषा', code: 'ne-NP' },
  { name: 'Norsk bokmål', code: 'nb-NO' },
  { name: 'Polski', code: 'pl-PL' },
  { name: 'Português (Brasil)', code: 'pt-BR' },
  { name: 'Português (Portugal)', code: 'pt-PT' },
  { name: 'Română', code: 'ro-RO' },
  { name: 'සිංහල', code: 'si-LK' },
  { name: 'Slovenščina', code: 'sl-SI' },
  { name: 'Basa Sunda', code: 'su-ID' },
  { name: 'Slovenčina', code: 'sk-SK' },
  { name: 'Suomi', code: 'fi-FI' },
  { name: 'Svenska', code: 'sv-SE' },
  { name: 'Kiswahili', code: 'sw-TZ' },
  { name: 'ქართული', code: 'ka-GE' },
  { name: 'Հայերեն', code: 'hy-AM' },
  { name: 'தமிழ்', code: 'ta-IN' },
  { name: 'తెలుగు', code: 'te-IN' },
  { name: 'Tiếng Việt', code: 'vi-VN' },
  { name: 'Türkçe', code: 'tr-TR' },
  { name: 'اُردُو', code: 'ur-PK' },
  { name: 'Ελληνικά', code: 'el-GR' },
  { name: 'български', code: 'bg-BG' },
  { name: 'Pусский', code: 'ru-RU' },
  { name: 'Српски', code: 'sr-RS' },
  { name: 'Українська', code: 'uk-UA' },
  { name: '한국어', code: 'ko-KR' },
  { name: '中文 普通话', code: 'cmn-Hans-CN' },
  { name: '日本語', code: 'ja-JP' },
  { name: 'हिन्दी', code: 'hi-IN' },
  { name: 'ภาษาไทย', code: 'th-TH' },
];

export default SUPPORTED_LANGUAGES;
