import classNames from 'classnames/bind';
import * as dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import LoadingScreen from 'components/Shared/LoadingScreen';
import { useQueryResultsStudyStatisticsForAgenda } from 'gql/queries/results';
import Button from 'uikit/Button';

import FutureCourse from './FutureCourse/FutureCourse';
import PastCourse from './PastCourse/PastCourse';
import styles from './Agenda.module.scss';
import CourseItem from './CourseItem/CourseItem';

const cx = classNames.bind(styles);

const Agenda = ({
  course = null,
  isSingle = false,
  refetchAgendaOccurrences = null,
}) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Agenda',
  });

  const [selectedDay, setSelectedDay] = useState();

  const sunday = dayjs(new Date()).isoWeekday(7).$d;

  const [currentSunday, setCurrentSunday] = useState(sunday);

  const {
    getResultsStudyStatisticsForAgenda,
    isLoading: isLoadingAgenda,
    data: dataAgenda,
  } = useQueryResultsStudyStatisticsForAgenda();

  // Refresh agenda
  useEffect(() => {
    getResultsStudyStatisticsForAgenda({
      variables: {
        courseId: isSingle ? course?.id : null,
        sunday: currentSunday,
      },
    });
  }, [
    course,
    currentSunday,
    getResultsStudyStatisticsForAgenda,
    isSingle,
    refetchAgendaOccurrences,
  ]);

  const agenda = isLoadingAgenda
    ? null
    : dataAgenda.resultsStudyStatisticsForAgenda;

  useEffect(() => {
    const dayToSelect = agenda?.statistics.find(
      (day) =>
        dayjs(new Date()).format('L').toString() ===
        dayjs(day.date).format('L').toString(),
    );
    const firstDay = agenda?.statistics[0];
    setSelectedDay(dayToSelect || firstDay);
  }, [agenda]);

  const handleWeekCurrent = () => {
    setCurrentSunday(sunday);
  };

  const handleWeekNext = () => {
    setCurrentSunday(dayjs(currentSunday).add(7, 'day').$d);
  };

  const handleWeekPrevious = () => {
    setCurrentSunday(dayjs(currentSunday).subtract(7, 'day').$d);
  };

  const isLookingCurrentWeek =
    dayjs(sunday).format('L') === dayjs(currentSunday).format('L');

  const period = `${dayjs(agenda?.statistics[0].date).format('MMMM')} ${dayjs(
    agenda?.statistics[0].date,
  ).format('DD')}-${dayjs(agenda?.statistics[6].date).format('DD')}, ${dayjs(
    agenda?.statistics[0].date,
  ).format('YYYY')}`;

  return (
    <>
      {!agenda ? (
        <LoadingScreen />
      ) : (
        <div className={cx('container')}>
          <div className={cx('header')}>
            <p className={cx('period')}>{period}</p>
            <div className={cx('actions')}>
              {!isLookingCurrentWeek && (
                <Button
                  className={cx('action', 'action-today')}
                  onClick={handleWeekCurrent}
                >
                  {t('current-week')}
                </Button>
              )}
              <Button
                aria-label={t('aria-previous-week')}
                className={cx('action', 'before')}
                icon="chevron-left"
                onClick={handleWeekPrevious}
              />
              <Button
                aria-label={t('aria-next-week')}
                className={cx('next')}
                icon="chevron-right"
                onClick={handleWeekNext}
              />
            </div>
          </div>
          <div className={cx('week-large')}>
            {agenda?.statistics.map((day, dayIndex) => (
              <div
                className={cx('day', {
                  first: dayIndex === 0,
                  last: dayIndex === 6,
                })}
                key={day.date}
              >
                <div
                  className={cx('day-header', {
                    'future-day': !day.isDayPast && !day.isToday,
                    first: dayIndex === 0,
                    last: dayIndex === 6,
                  })}
                >
                  <div
                    className={cx('date-number', {
                      today: day.isToday,
                    })}
                  >
                    {dayjs(day.date).format('DD')}
                  </div>
                  <div
                    className={cx('date-day', {
                      today: day.isToday,
                    })}
                  >
                    {dayjs(day.date).format('ddd')}
                  </div>
                </div>
                <div
                  className={cx('day-courses', {
                    first: dayIndex === 0,
                    last: dayIndex === 6,
                  })}
                >
                  {day.coursesOfTheDay.map((course) => (
                    <div key={`${course.id}_${day.date}`}>
                      {isSingle ? (
                        !course.isPast ? (
                          <FutureCourse course={course} isSingle={isSingle} />
                        ) : (
                          <PastCourse course={course} isSingle={isSingle} />
                        )
                      ) : (
                        <CourseItem
                          course={course}
                          day={day}
                          isSingle={isSingle}
                        />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <div className={cx('week-small')}>
            <div className={cx('week-small-header')}>
              {agenda?.statistics.map((day) => (
                <button
                  className={cx('day-header')}
                  key={day.date}
                  onClick={() => setSelectedDay(day)}
                  type="button"
                >
                  <div
                    className={cx('date-number', {
                      today:
                        dayjs(selectedDay?.date).format('DD') ===
                        dayjs(day.date).format('DD'),
                    })}
                  >
                    {dayjs(day.date).format('DD')}
                  </div>
                  <div
                    className={cx('date-day', {
                      today:
                        dayjs(selectedDay?.date).format('DD') ===
                        dayjs(day.date).format('DD'),
                    })}
                  >
                    {dayjs(day.date).format('ddd')}
                  </div>
                </button>
              ))}
            </div>
            <div className={cx('day-courses')}>
              {selectedDay && (
                <>
                  {selectedDay.coursesOfTheDay.map((course) => (
                    <div key={`${course.id}_${selectedDay.date}`}>
                      {isSingle ? (
                        !course.isPast ? (
                          <FutureCourse course={course} isSingle={isSingle} />
                        ) : (
                          <PastCourse course={course} isSingle={isSingle} />
                        )
                      ) : (
                        <CourseItem
                          course={course}
                          day={selectedDay}
                          isSingle={isSingle}
                        />
                      )}
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

Agenda.propTypes = {
  course: PropTypes.object,
  isSingle: PropTypes.bool,
  refetchAgendaOccurrences: PropTypes.number,
};

export default Agenda;
