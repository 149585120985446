import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import useModal from 'hooks/useModal';

import ExpertFeedbacksVerso from './ExpertFeedbacksVerso';

const ExpertFeedbacksVersoContainer = ({ index, question, ...props }) => {
  const { t } = useTranslation();
  const { isModalOpen, handleModalOpen, handleModalClose } = useModal(false);
  const feedbacks = question.content.expertFeedbacks[index].filter(
    (feedback) => !!feedback.feedback,
  );
  return (
    <ExpertFeedbacksVerso
      feedbacks={feedbacks}
      handleModalClose={handleModalClose}
      handleModalOpen={handleModalOpen}
      isModalOpen={isModalOpen}
      t={t}
      {...props}
    />
  );
};

ExpertFeedbacksVersoContainer.propTypes = {
  index: PropTypes.number.isRequired,
  question: PropTypes.object.isRequired,
};

export default ExpertFeedbacksVersoContainer;
