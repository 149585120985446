import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import EDITOR_STATUSES from 'constants/editorStatuses';
import PERMISSIONS from 'constants/permissions';
import { useQueryMe } from 'gql/queries/me';
import { mutationEditorClear } from 'gql/mutations/editor';
import { useMutationCourseInvite } from 'gql/mutations/course';
import { useQueryLocalEditor } from 'gql/queries/local';
import extract from 'helpers/extract';
import { trackEvent, reportError } from 'lib/tracking';
import { notify } from 'uikit/Notifications';

import CollaboratorsInvite from './CollaboratorsInvite';

const CollaboratorsInviteContainer = ({ course }) => {
  const { t } = useTranslation();
  const [unInvited, setUnInvited] = useState([]);
  const mutationCourseInvite = useMutationCourseInvite();
  const {
    data: {
      editor: { status: editorStatus },
    },
  } = useQueryLocalEditor();
  const {
    data: { me },
  } = useQueryMe();
  const isCourseInvitationRestricted =
    me?.organization?.settings?.isCourseInvitationRestricted;
  const isModalOpen = editorStatus === EDITOR_STATUSES.COLLABORATORS_INVITE;
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        usernames: yup
          .string()
          .trim()
          .lowercase()
          .required(
            t(
              'Course/Teacher/Collaborators/CollaboratorsInvite/usernames-error-required',
            ),
          ),
      }),
    [t],
  );
  const handleCollaboratorsInvite = useCallback(
    async (values, form) => {
      try {
        form.setStatus(null);
        const variables = extract(values, validationSchema);
        const {
          data: {
            courseInvite: { notSentInvitations },
          },
        } = await mutationCourseInvite({
          ...variables,
          courseId: course.id,
          level: PERMISSIONS.COLLABORATOR,
        });
        setUnInvited(notSentInvitations);
        if (notSentInvitations.length === 0) {
          mutationEditorClear();
          notify(
            'success',
            t(
              'Course/Teacher/Collaborators/CollaboratorsInvite/collaborators-invite-success',
            ),
          );
        }
        trackEvent('collaborators', 'invite');
      } catch (err) {
        form.setStatus(err.message);
        reportError('collaborators', 'invite', err);
      }
    },
    [course, mutationCourseInvite, validationSchema, t],
  );
  return (
    <CollaboratorsInvite
      handleClose={mutationEditorClear}
      handleCollaboratorsInvite={handleCollaboratorsInvite}
      isCourseInvitationRestricted={isCourseInvitationRestricted}
      isModalOpen={isModalOpen}
      t={t}
      unInvited={unInvited}
      validationSchema={validationSchema}
    />
  );
};

CollaboratorsInviteContainer.propTypes = {
  course: PropTypes.object.isRequired,
};

export default CollaboratorsInviteContainer;
