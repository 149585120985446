import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import CleanedText from 'uikit/CleanedText';

import styles from './ReportEmpty.module.scss';

const cx = classNames.bind(styles);

const ReportEmpty = ({ isUnsupported, question, questionInfo, t }) => (
  <div className={cx('container')}>
    <div className={cx('background')}>
      <div className={cx('header')}>
        <div className={cx('title-container')}>
          <p className={cx('icon', `background-${questionInfo?.color}`)}>
            <img alt="" src={questionInfo?.icon} />
          </p>
          <CleanedText as="p" className={cx('title')}>
            {question?.title}
          </CleanedText>
        </div>
      </div>
      <p className={cx('empty', { unsupported: isUnsupported })}>
        {t(
          isUnsupported
            ? 'Shared/ReportEmpty/not-supported'
            : 'Shared/ReportEmpty/empty',
        )}
      </p>
    </div>
  </div>
);

ReportEmpty.propTypes = {
  isUnsupported: PropTypes.bool,
  question: PropTypes.object,
  questionInfo: PropTypes.object,
  t: PropTypes.func.isRequired,
};

ReportEmpty.defaultProps = {
  isUnsupported: false,
  question: undefined,
  questionInfo: undefined,
};

export default ReportEmpty;
