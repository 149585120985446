import classNames from 'classnames/bind';
import * as dayjs from 'dayjs';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import Button from 'uikit/Button';

import Icon from 'uikit/Icon/Icon';
import InputDateTime from 'uikit/InputDateTime';

import styles from './StudyDeadline.module.scss';

const cx = classNames.bind(styles);

const StudyDeadline = ({
  className,
  course,
  from,
  handleDeadlineRemove,
  handleDeadlineSet,
  t,
  validationSchema,
}) => (
  <div
    className={cx('container', className, {
      'gray-border': from === 'missing-deadline',
    })}
  >
    <div className={cx('title-container')}>
      <Icon className={cx('title-icon')} name="calendar-alt" />
      <p className={cx('title')}>
        {t(
          course.canEditDeadline
            ? 'Components/Shared/StudyDeadline/title-editable'
            : 'Components/Shared/StudyDeadline/title-uneditable',
        )}
      </p>
    </div>
    <Formik
      initialValues={{
        deadline: null,
      }}
      onSubmit={handleDeadlineSet}
      validationSchema={validationSchema}
    >
      {({ values, setFieldValue }) => (
        <Form>
          {course.deadline ? (
            <div className={cx('deadline-container')}>
              <p className={cx('deadline')}>
                {dayjs(course.deadline).format('LLLL')}
              </p>
              {course.canEditDeadline && (
                <Button
                  aria-label={t(
                    'Components/Shared/StudyDeadline/remove-deadline',
                  )}
                  icon="trash-alt"
                  onClick={() => handleDeadlineRemove(setFieldValue)}
                  size="small"
                />
              )}
            </div>
          ) : (
            <>
              <InputDateTime name="deadline" />
              <Button
                className={cx('submit-button', { visible: values.deadline })}
                isDisabled={!values.deadline}
                intent="primary"
                type="submit"
              >
                {t('Components/Shared/StudyDeadline/save')}
              </Button>
            </>
          )}
        </Form>
      )}
    </Formik>
  </div>
);

StudyDeadline.propTypes = {
  className: PropTypes.string,
  course: PropTypes.object.isRequired,
  from: PropTypes.string.isRequired,
  handleDeadlineRemove: PropTypes.func.isRequired,
  handleDeadlineSet: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  validationSchema: PropTypes.object.isRequired,
};

StudyDeadline.defaultProps = {
  className: undefined,
};

export default StudyDeadline;
