import classNames from 'classnames/bind';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import InputFile from 'uikit/InputFile';
import Select from 'uikit/Select';

import styles from './Editor.module.scss';

const cx = classNames.bind(styles);

const Editor = ({ className = null, course }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'questions/PDFOrPPT',
  });

  const { isSubmitting } = useFormikContext();

  return (
    <div className={cx('container', className)}>
      <div className={cx('explanation')}>
        <p>{t('explanation')}</p>
      </div>
      <Select
        className={cx('field')}
        disabled={isSubmitting}
        icon="book-open"
        label={t('chapter-label')}
        name="chapterId"
      >
        <option value="">{t('no-chapter')}</option>
        {course.chapters.map((chapter) => (
          <option key={chapter.id} value={chapter.id}>
            {chapter.title}
          </option>
        ))}
      </Select>
      <InputFile
        accept=".ppt,.pptx,.pps,.pot,.ppsx,.potx,.odp,.key,.pdf,.apkg"
        isDisabled={isSubmitting}
        label={t('file-label')}
        name="file"
      />
    </div>
  );
};

Editor.propTypes = {
  className: PropTypes.string,
  course: PropTypes.object.isRequired,
};

Editor.validationSchema = (t) =>
  yup.object().shape({
    chapterId: yup
      .string()
      .trim()
      .matches(
        /^|([0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})$/i,
      )
      .nullable(),
    file: yup
      .mixed()
      .required(t('questions/PDFOrPPT/file-error-required')),
  });

export default Editor;
