import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useInterval from 'hooks/useInterval';

import { addIntToFloat } from '../../helpers/numberExtensions';
import InputNumber from './InputNumber';

const START_DELAY = 300;
const INCREMENT_INTERVAL = 100;

const InputNumberContainer = ({ name, ...props }) => {
  const { t } = useTranslation();
  const [{ value }, , { setValue }] = useField(name);
  // We need to differentiate the value and the value displayed in the input.
  // Otherwise, we would run into some problems when typing
  // and removing decimal digits (e.g. 5.5 --> 5. could be transformed to
  // 5 if we don't do this).
  const [{ value: inputValue }, , { setValue: setInputValue }] = useField(
    `${name}Input`,
  );
  const timerRef = useRef(null);
  const [isIncrementing, setIsIncrementing] = useState(null);
  useEffect(() => {
    setInputValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const parseStringToNumber = useCallback((formattedValueToParse) => {
    const parsedValue = parseFloat(formattedValueToParse);
    return !Number.isNaN(parsedValue) ? parsedValue : '';
  }, []);
  const handleIncrement = useCallback(
    (increment) => {
      if (inputValue === '' || Number.isNaN(inputValue)) {
        setValue(increment);
        setInputValue(increment);
      } else {
        const newValue = addIntToFloat(inputValue, increment);
        const processedValue = parseStringToNumber(newValue);
        setValue(processedValue);
        setInputValue(newValue);
      }
    },
    [inputValue, parseStringToNumber, setInputValue, setValue],
  );
  useInterval(
    () => handleIncrement(isIncrementing),
    isIncrementing ? INCREMENT_INTERVAL : 1e10,
  );
  const clearTimes = useCallback(() => {
    clearTimeout(timerRef.current);
    setIsIncrementing(null);
  }, [setIsIncrementing, timerRef]);
  const delayedInterval = useCallback(
    (increment) => {
      clearTimes();
      timerRef.current = setTimeout(
        () => setIsIncrementing(increment),
        START_DELAY,
      );
    },
    [clearTimes, setIsIncrementing, timerRef],
  );
  const handleIncrementStop = useCallback(
    (event) => {
      event.preventDefault();
      clearTimes();
    },
    [clearTimes],
  );
  const handleIncrementStart = useCallback(
    (event, increment) => {
      handleIncrementStop(event);
      delayedInterval(increment);
    },
    [delayedInterval, handleIncrementStop],
  );
  const handleKeyDown = useCallback(
    (event) => {
      switch (event.key) {
        case 'ArrowDown':
          event.preventDefault();
          handleIncrement(-1);
          break;
        case 'ArrowUp':
          event.preventDefault();
          handleIncrement(1);
          break;
        default:
          break;
      }
    },
    [handleIncrement],
  );
  const formatValue = useCallback((rawValue) => {
    let firstMatch = true;
    const res = rawValue
      // Remove chars not 0-9, ',', '.' or '-'
      .replace(/[^0-9.,-]/g, '')
      // Remove all occurrences of '-' except at beginning
      .replace(/(?!^)-/g, '')
      // Remove all occurrences of ','/'.' except first one
      .replace(/\.|,/g, (match) => {
        if (firstMatch) {
          firstMatch = false;
          return match;
        }
        return '';
      })
      .replace(',', '.');
    return res;
  }, []);
  const handleChange = useCallback(
    ({ target: { value: eventValue } }) => {
      const formattedValue = formatValue(eventValue);
      const parsedValue = parseStringToNumber(formattedValue);
      setInputValue(formattedValue);
      setValue(parsedValue);
    },
    [formatValue, parseStringToNumber, setInputValue, setValue],
  );
  const handleBlur = useCallback(() => {
    // Removing trailing '.'/','
    const stringValue = inputValue
      ? inputValue.toString().replace(/(\.|,)$/, '')
      : '';
    const parsedValue = parseStringToNumber(stringValue);
    setInputValue(stringValue);
    setValue(parsedValue);
  }, [inputValue, parseStringToNumber, setInputValue, setValue]);
  return (
    <InputNumber
      {...props}
      handleBlur={handleBlur}
      handleChange={handleChange}
      handleIncrement={handleIncrement}
      handleIncrementStart={handleIncrementStart}
      handleIncrementStop={handleIncrementStop}
      handleKeyDown={handleKeyDown}
      name={name}
      t={t}
    />
  );
};

InputNumberContainer.propTypes = {
  name: PropTypes.string.isRequired,
};

export default InputNumberContainer;
