import classNames from 'classnames/bind';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { useMutationQuestionImportWooclap } from 'gql/mutations/question';
import extract from 'helpers/extract';
import { trackEvent, reportError } from 'lib/tracking';
import Button from 'uikit/Button';
import Select from 'uikit/Select';

import styles from './QuestionImportWooclap.module.scss';

const cx = classNames.bind(styles);

const QuestionImportWooclap = ({ course, handleModalClose }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/Editor/QuestionImport/QuestionImportWooclap',
  });

  const mutationQuestionImportWooclap = useMutationQuestionImportWooclap();

  const [status, setStatus] = useState(undefined);

  const [chapterId, setChapterId] = useState(null);

  useEffect(() => {
    async function handleMessage(message) {
      try {
        const {
          origin,
          data: { event, payload },
        } = message;
        if (
          origin !== 'https://app.wooclap.com' ||
          event !== 'WooflashMessage.IMPORT'
        ) {
          return;
        }

        const { questions } = payload;

        const slices = [];

        const sliceSize = 50;
        for (let i = 0; i < questions.length; i += sliceSize) {
          slices.push(questions.slice(i, i + sliceSize));
        }

        const questionsSent = [];

        await Promise.all(
          slices.map(async (slice) => {
            const {
              data: { questionImportWooclap },
            } = await mutationQuestionImportWooclap({
              courseId: course.id,
              chapterId,
              payload: { questions: slice },
            });

            questionsSent.push(questionImportWooclap);
          }),
        );

        handleModalClose(questionsSent);
        trackEvent('question', 'import-wooclap');
      } catch (err) {
        setStatus('500');
        reportError('question', 'import-wooclap', err);
      }
    }

    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [chapterId, course.id, handleModalClose, mutationQuestionImportWooclap]);

  async function handleWooclapOpen(values) {
    try {
      setStatus(undefined);
      const { chapterId: cId } = extract(values, validationSchema);
      setChapterId(cId);
      const popup = window.open('/', 'Wooclap', 'height=700,width=800');
      if (popup) {
        popup.location.href = 'about:blank';
        popup.location.href =
          'https://app.wooclap.com/user/questions/export-to-wooflash';
      }
    } catch (err) {
      setStatus('500');
    }
  }

  const validationSchema = yup.object().shape({
    chapterId: yup
      .string()
      .nullable()
      .transform((v) => v || null)
      .trim()
      .uuid(),
  });

  const chapters = course.chapters;

  return (
    <>
      <Formik
        initialValues={{
          chapterId: '',
        }}
        onSubmit={handleWooclapOpen}
        validationSchema={validationSchema}
      >
        {({ isSubmitting }) => (
          <Form className={cx('subpage')}>
            <p className={cx('subtitle')}>{t('subtitle')}</p>
            {status === '500' && (
              <p className={cx('error')} role="alert">
                {t('question-import-error')}
              </p>
            )}
            <Select
              className={cx('field')}
              icon="book-open"
              label={t('chapter-label')}
              name="chapterId"
            >
              <option value="">{t('no-chapter')}</option>
              {chapters.map((chapter) => (
                <option key={chapter.id} value={chapter.id}>
                  {chapter.title}
                </option>
              ))}
            </Select>
            <div className={cx('actions')}>
              <div />
              <Button
                className={cx('action')}
                intent="primary"
                isLoading={isSubmitting}
                size="large"
                type="submit"
              >
                {t('continue-wooclap')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

QuestionImportWooclap.propTypes = {
  course: PropTypes.object.isRequired,
  handleModalClose: PropTypes.func.isRequired,
};

export default QuestionImportWooclap;
