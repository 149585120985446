import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import LanguageSelector from 'components/Shared/LanguageSelector';
import FormattedText from 'uikit/FormattedText';

import BACKGROUND_SMALL from './images/bg-small.svg';
import ELEPHANT_IMAGE_SMALL from './images/elephant-small.svg';
import styles from './Container.module.scss';

const cx = classNames.bind(styles);

const Container = ({ action = null, children }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Auth/Shared/Container',
  });

  return (
    <div className={cx('container')}>
      <aside className={cx('side')}>
        <Link aria-label={t('aria-home')} className={cx('logo')} to="/">
          wooflash
        </Link>
        <img
          alt=""
          aria-hidden="true"
          className={cx('elephant-small')}
          src={ELEPHANT_IMAGE_SMALL}
        />
        <img
          alt=""
          aria-hidden="true"
          className={cx('bg-small')}
          src={BACKGROUND_SMALL}
        />
        <FormattedText className={cx('side-text')}>
          {t('side-text')}
        </FormattedText>
      </aside>
      <header className={cx('header')}>
        {action && <div className={cx('header-action')}>{action}</div>}
        <LanguageSelector intent="square" />
      </header>
      <main className={cx('main')}>
        <div className={cx('content')}>{children}</div>
      </main>
    </div>
  );
};

Container.propTypes = {
  action: PropTypes.node,
  children: PropTypes.node.isRequired,
};

export default Container;
