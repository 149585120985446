import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import COURSE_CONFIG from 'constants/courseConfig';
import { useMutationCourseSetDeadline } from 'gql/mutations/course';
import extract from 'helpers/extract';
import { reportError, trackEvent } from 'lib/tracking';

import StudyDeadline from './StudyDeadline';

const StudyDeadlineContainer = ({
  course,
  handleModalClose,
  refreshAgenda,
  ...props
}) => {
  const { t } = useTranslation();
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        deadline: yup
          .date()
          .min(
            COURSE_CONFIG.minDateLimit,
            t('Components/Shared/StudyDeadline/limit-min-date'),
          )
          .max(
            COURSE_CONFIG.maxDateLimit,
            t('Components/Shared/StudyDeadline/limit-max-date'),
          )
          .required(),
      }),
    [t],
  );
  const mutationCourseSetDeadline = useMutationCourseSetDeadline();
  const handleDeadlineSet = useCallback(
    async (values, form) => {
      try {
        form.setStatus(null);
        const variables = extract(values, validationSchema);
        await mutationCourseSetDeadline(course, variables);
        if (refreshAgenda) {
          refreshAgenda();
        }
        if (handleModalClose) {
          handleModalClose();
        }
        trackEvent('course', 'set deadline');
      } catch (err) {
        form.setStatus(err.message);
        reportError('course', 'set deadline', err);
      }
    },
    [
      course,
      handleModalClose,
      mutationCourseSetDeadline,
      refreshAgenda,
      validationSchema,
    ],
  );
  const handleDeadlineRemove = useCallback(
    async (setFieldValue) => {
      try {
        setFieldValue('deadline', null);
        await mutationCourseSetDeadline(course, { deadline: null });
        trackEvent('course', 'set deadline');
      } catch (err) {
        reportError('course', 'set deadline', err);
      }
    },
    [course, mutationCourseSetDeadline],
  );
  return (
    <StudyDeadline
      {...props}
      course={course}
      handleDeadlineRemove={handleDeadlineRemove}
      handleDeadlineSet={handleDeadlineSet}
      t={t}
      validationSchema={validationSchema}
    />
  );
};

StudyDeadlineContainer.propTypes = {
  course: PropTypes.object.isRequired,
  handleModalClose: PropTypes.func,
  refreshAgenda: PropTypes.func,
};

StudyDeadlineContainer.defaultProps = {
  handleModalClose: undefined,
  refreshAgenda: undefined,
};

export default StudyDeadlineContainer;
