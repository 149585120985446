import propTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import EDITOR_STATUSES from 'constants/editorStatuses';
import { useMutationChapterCreate } from 'gql/mutations/chapter';
import { mutationEditorClear } from 'gql/mutations/editor';
import { useMutationQuestionImportQuizWizard } from 'gql/mutations/question';
import { useQueryLocalApp, useQueryLocalEditor } from 'gql/queries/local';
import { useQueryMe } from 'gql/queries/me';
import { reportError, trackEvent } from 'lib/tracking';
import { notify } from 'uikit/Notifications';

import QuestionCreateFromIA from './QuestionCreateFromIA';
import { useQueryWoogen } from '../../../../gql/queries/woogen';

const QuestionCreateFromIAContainer = ({ course, ...props }) => {
  const { isLoading, data } = useQueryWoogen();
  const { data: dataMe } = useQueryMe();
  const me = dataMe?.me;
  const { t } = useTranslation();
  const {
    data: {
      editor: { status: editorStatus },
    },
  } = useQueryLocalEditor();
  const {
    data: {
      app: { language },
    },
  } = useQueryLocalApp();
  const quizWizardLang = ['en', 'fr'].includes(language) ? language : 'en';
  const isModalOpen = editorStatus === EDITOR_STATUSES.QUESTION_CREATE_FROM_IA;
  const mutationChapterCreate = useMutationChapterCreate();
  const mutationQuestionImportQuizWizard =
    useMutationQuestionImportQuizWizard();
  const handleMessage = useCallback(
    async (message) => {
      try {
        const {
          data: { type, questions },
        } = message;
        if (type !== 'quizwizard' || !questions.length) {
          return;
        }
        const {
          data: { chapterCreate: newChapter },
        } = await mutationChapterCreate({
          courseId: course.id,
          title: t('Course/Teacher/QuestionCreateFromIA/ia-chapter-title'),
        });

        const slices = [];

        const sliceSize = 50;
        for (let i = 0; i < questions.length; i += sliceSize) {
          slices.push(questions.slice(i, i + sliceSize));
        }

        await Promise.all(
          slices.map(async (slice) => {
            await mutationQuestionImportQuizWizard({
              courseId: course.id,
              chapterId: newChapter.id,
              payload: {
                questions: slice,
              },
            });
          }),
        );

        notify(
          'ia',
          t(`Course/Teacher/QuestionCreateFromIA/questions-created-${me.role}`),
        );
        trackEvent('question', 'import-quizwizard');
        mutationEditorClear();
      } catch (err) {
        notify(
          'error',
          t('Course/Teacher/QuestionCreateFromIA/questions-not-created'),
        );
        reportError('question', 'import-quizwizard', err);
      }
    },
    [course, me, mutationChapterCreate, mutationQuestionImportQuizWizard, t],
  );
  useEffect(() => {
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [handleMessage]);
  return (
    <QuestionCreateFromIA
      data={data}
      isLoading={isLoading}
      handleClose={mutationEditorClear}
      isModalOpen={isModalOpen}
      language={quizWizardLang}
      {...props}
    />
  );
};

QuestionCreateFromIAContainer.propTypes = {
  course: propTypes.object.isRequired,
};

export default QuestionCreateFromIAContainer;
