import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'uikit/Button';

import styles from './Pagination.module.scss';

const cx = classNames.bind(styles);

const Pagination = ({
  canNextPage,
  canPreviousPage,
  handlePageLoad,
  handlePageSizeUpdate,
  nPages,
  nStudents,
  offset,
  pageSize,
}) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/Statistics/ClassificationMatrix/Pagination',
  });

  return (
    <div className={cx('pagination')}>
      <div>
        <Button
          className={cx('navigation')}
          icon="chevron-double-left"
          isDisabled={!canPreviousPage}
          onClick={() => handlePageLoad(0, pageSize)}
          tooltip={t('first-page')}
        />
        <Button
          className={cx('navigation')}
          icon="chevron-left"
          isDisabled={!canPreviousPage}
          onClick={() => handlePageLoad(offset - pageSize, pageSize)}
          tooltip={t('previous-page')}
        />
        <Button
          className={cx('navigation')}
          icon="chevron-right"
          isDisabled={!canNextPage}
          onClick={() => handlePageLoad(offset + pageSize, pageSize)}
          tooltip={t('next-page')}
        />
        <Button
          className={cx('navigation')}
          icon="chevron-double-right"
          isDisabled={!canNextPage}
          onClick={() =>
            handlePageLoad(
              Math.floor(nStudents / pageSize) * pageSize,
              pageSize,
            )
          }
          tooltip={t('last-page')}
        />
      </div>
      <div>
        <span className={cx('current-page')}>
          {t('page-count', {
            index: Math.floor(offset / pageSize) + 1,
            of: nPages,
          })}
        </span>
        <select
          aria-label={t('aria-questions-per-page')}
          className={cx('select')}
          value={pageSize}
          onChange={handlePageSizeUpdate}
        >
          {[10, 20, 30, 40, 50].map((ps) => (
            <option key={ps} value={ps}>
              {t('page-size', {
                size: ps,
              })}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

Pagination.propTypes = {
  canNextPage: PropTypes.bool.isRequired,
  canPreviousPage: PropTypes.bool.isRequired,
  handlePageLoad: PropTypes.func.isRequired,
  handlePageSizeUpdate: PropTypes.func.isRequired,
  nPages: PropTypes.number.isRequired,
  nStudents: PropTypes.number.isRequired,
  offset: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
};

export default Pagination;
