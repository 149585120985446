import PropTypes from 'prop-types';
import React from 'react';

import Tooltip from 'uikit/Tooltip';

const MenuTooltip = ({ children, isMenuExpanded = null, tooltip }) =>
  isMenuExpanded ? (
    children
  ) : (
    <Tooltip isInMenu={true} tooltip={tooltip}>
      {children}
    </Tooltip>
  );

MenuTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  isMenuExpanded: PropTypes.bool,
  tooltip: PropTypes.string.isRequired,
};

export default MenuTooltip;
