import classNames from 'classnames/bind';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import Container from 'components/Auth/Shared/Container/Container';
import LoadingScreen from 'components/Shared/LoadingScreen';
import { useMutationAuthChangeEmailConfirm } from 'gql/mutations/auth';
import history from 'lib/history';
import { reportError } from 'lib/tracking';
import Button from 'uikit/Button';

import styles from './ChangeEmailConfirm.module.scss';

const cx = classNames.bind(styles);

const ChangeEmailConfirm = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'Auth/ChangeEmailConfirm',
  });

  const mutationAuthChangeEmailConfirm = useMutationAuthChangeEmailConfirm('/');

  const { pathname, search } = useLocation();

  const [hasError, setError] = useState(false);

  const query = queryString.parse(search);

  useEffect(() => {
    (async function asyncEffect() {
      try {
        if (!query.wantedEmailToken) {
          history.replace('/');
        } else {
          history.replace(pathname);
          await mutationAuthChangeEmailConfirm({
            wantedEmailToken: query.wantedEmailToken,
          });
        }
      } catch (err) {
        setError(true);
        reportError('auth', 'change-email-confirm', err);
      }
    })();
  }, [mutationAuthChangeEmailConfirm, pathname, query]);

  return (
    <Container
      action={
        <Button as={Link} size="large" to="/">
          {t('back')}
        </Button>
      }
    >
      {hasError === false ? (
        <LoadingScreen />
      ) : (
        <div className={cx('container', 'section')}>
          <h1 className={cx('title')}>{t('error')}</h1>
          <Button as={Link} intent="primary" size="large" to="/">
            {t('back')}
          </Button>
        </div>
      )}
    </Container>
  );
};

export default ChangeEmailConfirm;
