import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import Files from 'components/Shared/Files/Files';
import { useMutationChapterSort } from 'gql/mutations/chapter';
import {
  useMutationQuestionSort,
  useMutationQuestionSortMultiple,
} from 'gql/mutations/question';
import { useQueryLocalEditor } from 'gql/queries/local';
import { trackEvent, reportError } from 'lib/tracking';

import Chapters from './Chapters/Chapters';
import ChapterToApprove from './ChapterToApprove/ChapterToApprove';
import Standalone from './Standalone/Standalone';
import styles from './Finder.module.scss';

const cx = classNames.bind(styles);

const Finder = ({ className = null, course }) => {
  const mutationChapterSort = useMutationChapterSort();

  const mutationQuestionSort = useMutationQuestionSort();

  const mutationQuestionSortMultiple = useMutationQuestionSortMultiple();

  const {
    data: {
      editor: { questionsSelected },
    },
  } = useQueryLocalEditor();

  const handleSort = async ({ type, draggableId, source, destination }) => {
    try {
      if (
        !destination ||
        (source.index === destination.index &&
          source.droppableId === destination.droppableId)
      ) {
        return;
      }
      if (type === 'chapter') {
        const chapter = course.chapters.find((c) => c.id === draggableId);
        if (chapter) {
          mutationChapterSort(chapter, {
            to: destination.index,
          });
          trackEvent('chapter', 'sort');
        }
      } else {
        const container =
          source.droppableId === 'course'
            ? course
            : course.chapters.find((c) => c.id === source.droppableId);
        const question = container?.questions.find((q) => q.id === draggableId);
        if (questionsSelected && questionsSelected.length > 1) {
          mutationQuestionSortMultiple(questionsSelected, {
            courseId: course.id,
            chapterId:
              destination.droppableId === 'course'
                ? null
                : destination.droppableId,
            to: destination.index,
          });
          trackEvent('question', 'sortMultiple');
        } else if (question) {
          mutationQuestionSort(question, {
            chapterId:
              destination.droppableId === 'course'
                ? null
                : destination.droppableId,
            to: destination.index,
          });
          trackEvent('question', 'sort');
        }
      }
    } catch (err) {
      reportError(type, 'sort', err);
    }
  };

  return (
    <div className={className}>
      {course.questionsToApprove.length > 0 && (
        <ChapterToApprove questions={course.questionsToApprove} />
      )}
      <Files course={course} isTeacher />
      <DragDropContext onDragEnd={handleSort}>
        <Droppable droppableId="chapters" type="chapter">
          {(providedChapters, snapshotChapters) => (
            <div
              className={cx('chapters', {
                isDraggingOver: snapshotChapters.isDraggingOver,
              })}
              ref={providedChapters.innerRef}
              {...providedChapters.droppableProps}
            >
              <Chapters course={course} />
              {providedChapters.placeholder}
            </div>
          )}
        </Droppable>
        <Standalone className={cx('chapter')} course={course} />
      </DragDropContext>
    </div>
  );
};

Finder.propTypes = {
  className: PropTypes.string,
  course: PropTypes.object.isRequired,
};

export default Finder;
