import classNames from 'classnames/bind';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import Icon from 'uikit/Icon';

import styles from './Select.module.scss';

const cx = classNames.bind(styles);

const Select = React.forwardRef(
  ({ children, className, helper, isDisabled, intent, label, name, ...props }, ref) => {
    const [field, meta] = useField(name);
    return (
      <label
        className={cx(
          'container',
          {
            isDisabled,
            hasError: meta.touched && meta.error,
          },
          className,
          `intent-${intent}`
        )}
        htmlFor={name}
      >
        {(label || helper) && (
          <div className={cx('title')} id={`select-${name}-label`}>
            <div className={cx('label')}>{label}</div>
            <div className={cx('helper')}>{helper}</div>
          </div>
        )}
        <div className={cx('field-box')}>
          <select
            aria-labelledby={`select-${name}-label`}
            className={cx('field')}
            disabled={isDisabled}
            name={name}
            id={name}
            ref={ref}
            {...field}
            {...props}
          >
            {children}
          </select>
          <div className={cx('arrow')}>
            <Icon name="chevron-down" />
          </div>
        </div>
        {meta.touched && meta.error && (
          <p className={cx('error-message')} id={`error-${name}`} role="alert">
            {meta.error}
          </p>
        )}
      </label>
    );
  },
);

Select.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  helper: PropTypes.node,
  intent: PropTypes.oneOf(['gray']),
  isDisabled: PropTypes.bool,
  label: PropTypes.node,
  name: PropTypes.string.isRequired,
};

Select.defaultProps = {
  children: undefined,
  className: undefined,
  isDisabled: false,
  helper: undefined,
  label: undefined,
};

export default Select;
