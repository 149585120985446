import classNames from 'classnames/bind';
import { Formik, Form } from 'formik';
import React, { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import * as yup from 'yup';

import LanguageSelector from 'components/Shared/LanguageSelector';
import { useMutationAuthRecoverPassword } from 'gql/mutations/auth';
import extract from 'helpers/extract';
import { trackEvent, reportError } from 'lib/tracking';
import Button from 'uikit/Button';
import InputText from 'uikit/InputText';

import SENT_IMAGE from './images/sent-image.svg';
import styles from './RecoverPassword.module.scss';

const cx = classNames.bind(styles);

const RecoverPassword = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'Auth/RecoverPassword',
  });

  const mutationAuthRecoverPassword = useMutationAuthRecoverPassword();

  const { state } = useLocation();

  const defaultEmail = state?.email || '';

  const [isDone, setDone] = useState(false);

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup
          .string()
          .trim()
          .lowercase()
          .required(t('email-error-required'))
          .email(t('email-error-required'))
          .max(256, t('email-error-required')),
      }),
    [t],
  );

  const handleRecoverPassword = async (values, form) => {
    try {
      form.setStatus(null);
      const variables = extract(values, validationSchema);
      await mutationAuthRecoverPassword(variables);
      setDone(true);
      trackEvent('auth', 'recover-password');
    } catch (err) {
      form.setStatus(err.message);
      reportError('auth', 'recover-password', err);
    }
  };

  return (
    <div className={cx('container')}>
      <nav className={cx('header')}>
        <Button as={Link} icon="arrow-left" to="/auth/login" />
        <LanguageSelector intent="square" />
      </nav>
      <main className={cx('main')}>
        {isDone ? (
          <div>
            <img alt="" src={SENT_IMAGE} />
            <h1 className={cx('title')}>{t('success')}</h1>
            <h2 className={cx('subtitle')}>{t('subtitle-success')}</h2>
            <Button as={Link} intent="primary" size="large" to="/auth/login">
              {t('back')}
            </Button>
          </div>
        ) : (
          <Formik
            initialValues={{
              email: defaultEmail,
            }}
            onSubmit={handleRecoverPassword}
            validateOnBlur={false}
            validationSchema={validationSchema}
          >
            {({ isSubmitting, status, values }) => (
              <>
                <Form>
                  <h1 className={cx('title')}>{t('title')}</h1>
                  <h2 className={cx('subtitle')}>{t('subtitle')}</h2>
                  {status === 'Not Found' ? (
                    <p className={cx('error')} role="alert">
                      <Trans
                        i18nKey="recover-password-error-email-not-found"
                        t={t}
                      >
                        recover-password-error-email-not-found
                        <Link
                          to={{
                            pathname: '/auth/register',
                            state: {
                              username: values.email,
                            },
                          }}
                        >
                          recover-password-error-email-not-found
                        </Link>
                        recover-password-error-email-not-found
                      </Trans>
                    </p>
                  ) : (
                    status && (
                      <p className={cx('error')} role="alert">
                        {t('login-error')}
                      </p>
                    )
                  )}
                  <InputText
                    className={cx('field')}
                    icon="at"
                    intent="gray"
                    name="email"
                    placeholder={t('placeholder')}
                  />
                  <Button
                    className={cx('recover-button')}
                    intent="primary"
                    isLoading={isSubmitting}
                    size="large"
                    type="submit"
                  >
                    {t('recover-password')}
                  </Button>
                </Form>
              </>
            )}
          </Formik>
        )}
      </main>
    </div>
  );
};

export default RecoverPassword;
