import classNames from 'classnames/bind';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { apiUploadsS3 } from 'api/upload';
import {
  useMutationQuestionImportFile,
  useMutationQuestionImportAnki,
} from 'gql/mutations/question';
import extract from 'helpers/extract';
import { trackEvent, reportError } from 'lib/tracking';
import Button from 'uikit/Button';
import InputFile from 'uikit/InputFile';
import InputRich2 from 'uikit/InputRich2';
import Select from 'uikit/Select';

import styles from './QuestionImportFile.module.scss';

const cx = classNames.bind(styles);

const QuestionImportFile = ({ course, handleModalClose }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/Editor/QuestionImport/QuestionImportFile',
  });

  const mutationQuestionImportFile = useMutationQuestionImportFile();

  const mutationQuestionImportAnki = useMutationQuestionImportAnki();

  const [ankiSettings, setAnkiSettings] = useState(null);

  async function handleQuestionsImport(values, form) {
    try {
      form.setStatus(null);
      const { file, ...variables } = extract(values, validationSchema);
      const extension = file.name.split('.').slice(-1)[0];
      if (extension === 'apkg') {
        const filePath = await apiUploadsS3(file);
        const {
          data: { questionImportAnki },
        } = await mutationQuestionImportAnki({
          fileName: file.name,
          filePath,
        });
        setAnkiSettings({
          file,
          variables,
          ...questionImportAnki,
        });
      } else if (
        [
          'ppt',
          'pps',
          'pot',
          'pptx',
          'ppsx',
          'potx',
          'odp',
          'key',
          'pdf',
        ].includes(extension)
      ) {
        const filePath = await apiUploadsS3(file);
        const {
          data: { questionImportFile },
        } = await mutationQuestionImportFile({
          ...variables,
          courseId: course.id,
          extension,
          fileName: file.name,
          filePath,
        });
        handleModalClose(questionImportFile);
      } else {
        const {
          data: { questionImportFile },
        } = await mutationQuestionImportFile({
          ...variables,
          courseId: course.id,
          extension,
          fileName: file.name,
          file,
        });
        handleModalClose(questionImportFile);
      }
      trackEvent('question', 'import-file');
    } catch (err) {
      form.setStatus('500');
      reportError('question', 'import-file', err);
    }
  }

  async function handleQuestionsImportAnki(values, form) {
    try {
      form.setStatus(null);
      const extension = ankiSettings.file.name.split('.').slice(-1)[0];
      const filePath = await apiUploadsS3(ankiSettings.file);
      const {
        data: { questionImportFile },
      } = await mutationQuestionImportFile({
        ...ankiSettings.variables,
        courseId: course.id,
        extension,
        fileName: ankiSettings.file.name,
        filePath,
        ...values,
      });
      handleModalClose(questionImportFile);
      trackEvent('question', 'import-file');
    } catch (err) {
      form.setStatus('500');
      reportError('question', 'import-file', err);
    }
  }

  const validationSchema = yup.object().shape({
    chapterId: yup
      .string()
      .trim()
      .matches(
        /^|([0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})$/i,
      ),
    file: yup.mixed().required(t('file-error-required')),
  });

  const chapters = course.chapters;

  return ankiSettings ? (
    <>
      <Formik
        key="anki"
        initialValues={{
          recto:
            '<p class="fr-class-title fr-class-horizontal-center">[[Front]]</p>',
          verso:
            '<p class="fr-class-title fr-class-horizontal-center">[[Back]]</p>',
        }}
        onSubmit={handleQuestionsImportAnki}
      >
        {({ isSubmitting }) => (
          <Form className={cx('subpage')}>
            <div className={cx('subtitle')}>
              <p>{t('anki-fields', { column: ankiSettings.columns[0] })}</p>
              <p>
                <strong>{ankiSettings.columns.join(', ')}</strong>
              </p>
            </div>
            <InputRich2
              className={cx('field')}
              name="recto"
              label={t('front')}
            />
            <InputRich2
              className={cx('field')}
              name="verso"
              label={t('back')}
            />
            <div className={cx('actions')}>
              <div />
              <Button
                className={cx('action')}
                intent="primary"
                isLoading={isSubmitting}
                size="large"
                type="submit"
              >
                {t('confirm-import-anki')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  ) : (
    <>
      <Formik
        key="file"
        initialValues={{
          chapterId: '',
          file: null,
        }}
        onSubmit={handleQuestionsImport}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, status }) => (
          <Form className={cx('subpage')}>
            <div className={cx('subtitle')}>
              <p>{t('subtitle')}</p>
              <p>
                <a href={t('excel-link')} target="blank">
                  {t('excel-download')}
                </a>
              </p>
              <p>
                <a href={t('csv-link')} target="blank">
                  {t('csv-download')}
                </a>
              </p>
            </div>
            {status === '500' && (
              <p className={cx('error')} role="alert">
                {t('question-import-error')}
              </p>
            )}
            <Select
              className={cx('field')}
              icon="book-open"
              label={t('chapter-label')}
              name="chapterId"
            >
              <option value="">{t('no-chapter')}</option>
              {chapters.map((chapter) => (
                <option key={chapter.id} value={chapter.id}>
                  {chapter.title}
                </option>
              ))}
            </Select>
            <InputFile
              accept=".xml,.xls,.xlsx,.ods,.csv,.ppt,.pptx,.pps,.pot,.ppsx,.potx,.odp, .key, .pdf, .apkg"
              className={cx('field')}
              icon="file-code"
              label={t('file-label')}
              name="file"
            />
            <div className={cx('actions')}>
              <div />
              <Button
                className={cx('action')}
                intent="primary"
                isLoading={isSubmitting}
                size="large"
                type="submit"
              >
                {t('confirm-import-questions')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

QuestionImportFile.propTypes = {
  course: PropTypes.object.isRequired,
  handleModalClose: PropTypes.func.isRequired,
};

export default QuestionImportFile;
