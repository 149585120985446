import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import FormattedText from 'uikit/FormattedText';
import InputText from 'uikit/InputText';

import styles from './Recto.module.scss';

const cx = classNames.bind(styles);

const Recto = ({ className, isDisabled, question, t, theme }) => (
  <div className={cx('container', className, `theme-${theme}`)}>
    <FormattedText as="h1" className={cx('title')}>
      {question.title}
    </FormattedText>
    {!isDisabled && (
      <div className={cx('instructions')}>
        {t('Questions/ShortAnswer/Recto/instructions')}
      </div>
    )}
    <InputText
      autoCapitalize="off"
      autoComplete="off"
      autoFocus
      autoCorrect="off"
      data-testid="short-answer-answer"
      isDisabled={isDisabled}
      label={t('Questions/ShortAnswer/Recto/answer')}
      name="answer"
      spellCheck="false"
      shouldHideError
    />
  </div>
);

Recto.propTypes = {
  className: PropTypes.string,
  isDisabled: PropTypes.bool.isRequired,
  question: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
};

Recto.defaultProps = {
  className: undefined,
};

export default Recto;
