import froalaeditor from 'froala-editor';

froalaeditor.POPUP_TEMPLATES['relationPlugin.popup'] = '[_CUSTOM_LAYER_]';

froalaeditor.PLUGINS.relationPlugin = (editor) => {
  function initPopup() {
    const render = (str) =>
      window.katex
        ? window.katex.renderToString(str, {
            throwOnError: false,
          })
        : str;
    const template = {
      custom_layer: `
        <div class="fr-popup fr-active">
          <div class="fr-icon-container fr-sc-container">
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addRelation" data-param1="=">${render(
              '=',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addRelation" data-param1="≠">${render(
              '≠',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addRelation" data-param1="<">${render(
              '<',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addRelation" data-param1=">">${render(
              '>',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addRelation" data-param1="≤">${render(
              '≤',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addRelation" data-param1="≥">${render(
              '≥',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addRelation" data-param1="∈">${render(
              '∈',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addRelation" data-param1="∉">${render(
              '∉',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addRelation" data-param1="⊂">${render(
              '⊂',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addRelation" data-param1="⊃">${render(
              '⊃',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addRelation" data-param1="⊆">${render(
              '⊆',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addRelation" data-param1="⊇">${render(
              '⊇',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addRelation" data-param1="≺">${render(
              '≺',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addRelation" data-param1="≻">${render(
              '≻',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addRelation" data-param1="⪯">${render(
              '⪯',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addRelation" data-param1="⪰">${render(
              '⪰',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addRelation" data-param1="≡">${render(
              '≡',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addRelation" data-param1="≅">${render(
              '≅',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addRelation" data-param1="≈">${render(
              '≈',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addRelation" data-param1="∝">${render(
              '∝',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addRelation" data-param1="↑">${render(
              '↑',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addRelation" data-param1="↓">${render(
              '↓',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addRelation" data-param1="→">${render(
              '→',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addRelation" data-param1="←">${render(
              '←',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addRelation" data-param1="↔">${render(
              '↔',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addRelation" data-param1="⇒">${render(
              '⇒',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addRelation" data-param1="⇐">${render(
              '⇐',
            )}</span>
            <span class="fr-command fr-special-character fr-icon" role="button" data-cmd="addRelation" data-param1="⇔">${render(
              '⇔',
            )}</span>
          </div>
        </div>`,
    };
    return editor.popups.create('relationPlugin.popup', template);
  }
  function showPopup() {
    let $popup = editor.popups.get('relationPlugin.popup');
    if (!$popup) {
      $popup = initPopup();
    }
    editor.popups.setContainer('relationPlugin.popup', editor.$tb);
    const $btn = editor.$tb.find('.fr-command[data-cmd="insertRelation"]');
    const { left } = $btn.offset();
    const top =
      $btn.offset().top +
      (editor.opts.toolbarBottom ? 10 : $btn.outerHeight() - 10);
    editor.popups.show('relationPlugin.popup', left, top, $btn.outerHeight());
  }
  function hidePopup() {
    editor.popups.hide('relationPlugin.popup');
  }
  return {
    showPopup,
    hidePopup,
  };
};

froalaeditor.DefineIcon('relationIcon', {
  FA5NAME: 'equals',
  template: 'font_awesome_5',
});

froalaeditor.RegisterCommand('insertRelation', {
  title: 'Relation',
  icon: 'relationIcon',
  showOnMobile: true,
  undo: false,
  focus: false,
  callback: function callback() {
    this.relationPlugin.showPopup();
  },
});

froalaeditor.RegisterCommand('addRelation', {
  undo: true,
  focus: true,
  callback: function callback(cmd, val) {
    this.html.insert(` ${val} `);
    this.relationPlugin.hidePopup();
  },
});
