import classNames from 'classnames/bind';
import * as dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React from 'react';

import LoadingScreen from 'components/Shared/LoadingScreen';
import FormattedText from 'uikit/FormattedText';

import imageExam from './images/exam.svg';
import styles from './Exam.module.scss';

const cx = classNames.bind(styles);

const Exam = ({ className, course, isLoading, t }) =>
  isLoading ? (
    <LoadingScreen />
  ) : (
    <div className={cx('container', className)}>
      <img alt="" aria-hidden="true" className={cx('image')} src={imageExam} />
      <p className={cx('title')}>{t('Course/Student/Exam/start-time')}</p>
      <p>{dayjs(course.examStartTime).format('LLLL')}</p>
      <p className={cx('title')}>{t('Course/Student/Exam/stop-time')}</p>
      <p>{dayjs(course.examStopTime).format('LLLL')}</p>
      {course.examDurationMinutes > 0 && (
        <>
          <p className={cx('title')}>{t('Course/Student/Exam/duration')}</p>
          <p>{`${course.examDurationMinutes} minutes`}</p>
        </>
      )}
      {course.examInstructions && (
        <>
          <p className={cx('title')}>{t('Course/Student/Exam/instructions')}</p>
          <FormattedText>{course.examInstructions}</FormattedText>
        </>
      )}
    </div>
  );

Exam.propTypes = {
  className: PropTypes.string,
  course: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

Exam.defaultProps = {
  className: undefined,
  course: undefined,
};

export default Exam;
