import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import QR from 'qrcode';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'uikit/Button';

import styles from './QRCode.module.scss';

const cx = classNames.bind(styles);

const QRCode = ({ className = null, course }) => {
  const { t } = useTranslation();

  const prefix = 'Course/Teacher/Cover/CourseShare/QRCode';

  const [isDisplayed, setIsDisplayed] = useState(true);

  const link = `${process.env.REACT_APP_WEB_URL}/join/${course.accessCode}?from=2`;

  const canvasRef = (element) => {
    if (element) {
      QR.toCanvas(element, link, (err) => {
        if (err) {
          setIsDisplayed(false);
        }
      });
    }
  };

  const downloadQRCode = () => {
    const canvas = document.querySelector('canvas');
    const image = canvas
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream');
    const a = document.createElement('a');
    a.href = image;
    a.download = 'QRCode.png';
    a.click();
  };

  return (
    isDisplayed && (
      <div className={cx(className, 'container')}>
        <canvas ref={canvasRef} />
        <Button
          aria-label={t(`${prefix}/aria-download-qr`)}
          icon="download"
          onClick={downloadQRCode}
          tooltip={t(`${prefix}/download-qr`)}
        />
      </div>
    )
  );
};

QRCode.propTypes = {
  className: PropTypes.string,
  course: PropTypes.object.isRequired,
};

export default QRCode;
