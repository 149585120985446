import React from 'react';
import { useTranslation } from 'react-i18next';

import { useQueryCoursesGAR } from 'gql/queries/course';

import CoursesGAR from './CoursesGAR';

const CoursesGARContainer = () => {
  const { t } = useTranslation();
  const { isLoading, data } = useQueryCoursesGAR();
  return isLoading ? null : <CoursesGAR courses={data?.coursesGAR} t={t} />;
};

export default CoursesGARContainer;
