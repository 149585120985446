const STUDY_DISPLAY_STATUSES = {
  EMPTY: 'empty',
  LOADING: 'loading',
  MASCOT: {
    COMPLETE: 'mascot-complete',
    CORRECT: 'mascot-correct',
    WRONG: 'mascot-wrong',
  },
  NOTHING: 'nothing',
  QUESTIONS: 'questions',
  REPETITION: 'repetition',
  START: 'start',
  SUMMARY: 'summary',
};

export default STUDY_DISPLAY_STATUSES;
