import classNames from 'classnames/bind';
import * as d3 from 'd3';

import styles from './ClassificationMatrix.module.scss';

const cx = classNames.bind(styles);

const margin = {
  bottom: 100,
  left: 270,
  right: 0,
  top: 200,
};

// initiate the classification matrix
const initiatePlot = ({ ref, matrixData, setSelection, t }) => {
  if (!ref) {
    return;
  }

  const width = matrixData.students.length * 25 + margin.left + margin.right;

  const height = matrixData.questions.length * 25 + margin.top + margin.bottom;

  const tooltip = d3.select(`.${cx('tooltip')}`);

  // SVG
  const svg = d3
    .select(ref)
    .attr('width', width)
    .attr('height', height)
    .attr('viewBox', [0, 0, width, height]);
  svg.selectAll('*').remove();

  // Axis
  const xAxis = d3
    .scaleBand()
    .domain([...Array(matrixData.students.length).keys()])
    .range([margin.left, width - margin.right])
    .paddingInner(0.15);

  const yAxis = d3
    .scaleBand()
    .domain([...Array(matrixData.questions.length).keys()])
    .range([margin.top, height - margin.bottom])
    .paddingInner(0.15);

  // Names
  svg
    .append('g')
    .attr('transform', `translate(0, ${margin.top}) rotate(-90)`)
    .selectAll('text')
    .data(matrixData.students)
    .join('text')
    .attr('class', cx('label'))
    .attr('x', 5)
    .attr('y', (_, i) => xAxis(i) + xAxis.bandwidth() / 2)
    .text((d) => d)
    .attr('dy', '0.35em');

  // Questions
  svg
    .append('g')
    .selectAll('text')
    .data(matrixData.questions)
    .join('text')
    .attr('class', (d) => cx('label', { chapter: d.isChapter }))
    .attr('x', margin.left - 5)
    .attr('y', (_, i) => yAxis(i) + yAxis.bandwidth() / 2)
    .text((d) =>
      d.title.length > 30
        ? `${!d.isChapter ? `Q${d.order + 1} - ` : ''}${d.title.substring(
            0,
            30,
          )}...`
        : `${!d.isChapter ? `Q${d.order + 1} - ` : ''}${
            d.title ? d.title : d.isChapter ? t('no-chapter') : d.title
          }`,
    )
    .attr('dy', '0.35em')
    .attr('text-anchor', 'end');

  // Dots
  svg
    .append('g')
    .selectAll('g')
    .data(matrixData.data)
    .join('g')
    .attr('transform', (_, i) => `translate(0, ${yAxis(i)})`)
    .each(function makeRow(data) {
      d3.select(this)
        .selectAll('rect')
        .data(data)
        .join('rect')
        .attr('class', (d) => cx('cell', { hidden: d.isChapter }))
        .attr('x', (_, i) => xAxis(i))
        .attr('width', xAxis.bandwidth())
        .attr('height', yAxis.bandwidth())
        .attr('rx', 5)
        .attr('fill', (d) =>
          d.score === null || d.trials === 0
            ? '#E7EBF0'
            : d3.interpolateRdYlGn((d.score + 1) / 2),
        )
        .on('mouseover', (evt, d) => {
          setSelection({
            student: matrixData.students[d.col],
            question: matrixData.questions[d.row].title,
            trials: d.trials,
            correct: d.correct,
            wrong: d.wrong,
            unknown: d.unknown,
            history: d.history,
          });
          tooltip
            .style('left', `${xAxis(d.col) + 1.5 * xAxis.bandwidth()}px`)
            .style('top', `${yAxis(d.row) + 0.5 * yAxis.bandwidth()}px`);
        })
        .on('mouseout', () => {
          setSelection(null);
        });
    });
};

export default initiatePlot;
