import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Icon from 'uikit/Icon';

import ChapterCard from './ChapterCard';
import Standalone from './Standalone';
import styles from './InputQuestionTree.module.scss';

const cx = classNames.bind(styles);

const InputQuestionTree = ({
  allowPreview,
  className,
  course,
  handleCourseSelect,
  questionTypes,
  isSelected,
  t,
}) => (
  <div className={className}>
    <p className={cx('label')}>{t('uikit/InputQuestionTree/label')}</p>
    <div className={cx('field')}>
      <div className={cx('course')}>
        <label htmlFor={course.id}>
          <input
            aria-label={course.title}
            checked={isSelected}
            className={cx('check')}
            id={course.id}
            onChange={handleCourseSelect}
            type="checkbox"
          />
          <span
            className={cx('checkbox', {
              isChecked: isSelected,
            })}
          >
            <Icon name="check" />
          </span>
        </label>
        <span className={cx('icon')}>
          <Icon name="book" />
        </span>
        <p className={cx('title')}>{course.title}</p>
      </div>
      <div className={cx('content')}>
        {course.chapters.map((chapter) => (
          <ChapterCard
            allowPreview={allowPreview}
            chapter={chapter}
            className={cx('chapter')}
            questionTypes={questionTypes}
            key={chapter.id}
          />
        ))}
        <Standalone
          allowPreview={allowPreview}
          course={course}
          questionTypes={questionTypes}
        />
      </div>
    </div>
  </div>
);
InputQuestionTree.propTypes = {
  allowPreview: PropTypes.bool,
  className: PropTypes.string,
  course: PropTypes.object.isRequired,
  handleCourseSelect: PropTypes.func.isRequired,
  questionTypes: PropTypes.arrayOf(PropTypes.string),
  isSelected: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

InputQuestionTree.defaultProps = {
  allowPreview: undefined,
  className: undefined,
  questionTypes: undefined,
};

export default InputQuestionTree;
