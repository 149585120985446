import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Icon from 'uikit/Icon';

import ContributeCard from './ContributeCard/ContributeCard';
import CourseCard from './CourseCard/CourseCard';
import styles from './Category.module.scss';

const cx = classNames.bind(styles);

const Category = ({
  category,
  course = null,
  courses: unfilteredCourses,
  destination = null,
  handleCategoryFocus,
  language = null,
  search,
  tags,
}) => {
  const { t } = useTranslation('', {
    keyPrefix:
      'Course/Teacher/Editor/QuestionImport/QuestionImportPublicCourses/Category',
  });

  const [isFocused, setIsFocused] = useState(false);

  const handleShowMore = async () => {
    handleCategoryFocus();
    setIsFocused(true);
  };

  const coursesFiltered = unfilteredCourses.filter(
    (course) =>
      course.category === category.key &&
      (course.language === language || language === '') &&
      (course.destination === destination || destination === '') &&
      (course.tags.filter((tag) => tags.includes(tag.title)).length ||
        !tags.length),
  );

  const courses = coursesFiltered.filter(
    (course) =>
      !search ||
      course.title.toLowerCase().includes(search.trim().toLowerCase()),
  );

  return (
    <div className={cx('container')}>
      <div className={cx('header')}>
        <h2 className={cx('title')}>{category.translation}</h2>
        {!isFocused && courses.length > 5 && (
          <button className={cx('more')} onClick={handleShowMore} type="button">
            <span className={cx('see-more-large')}>
              {t('see-more-large', {
                number: courses.length,
              })}
            </span>
            <span className={cx('see-more-small')}>{t('see-more-small')}</span>
            <Icon className={cx('icon')} name="chevron-right" />
          </button>
        )}
      </div>
      {courses.length ? (
        courses.map((selectedCourse, index) => {
          if ((!isFocused && index < 5) || isFocused) {
            return (
              <CourseCard
                course={course}
                key={selectedCourse.id}
                selectedCourse={selectedCourse}
              />
            );
          }
          return null;
        })
      ) : (
        <ContributeCard />
      )}
    </div>
  );
};

Category.propTypes = {
  category: PropTypes.object.isRequired,
  course: PropTypes.object,
  courses: PropTypes.arrayOf(PropTypes.object).isRequired,
  destination: PropTypes.string,
  handleCategoryFocus: PropTypes.func.isRequired,
  language: PropTypes.string,
  search: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Category;
