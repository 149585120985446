import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import tutorialIllustrationsChecker from 'helpers/tutorialIllustrationsChecker';
import Context from 'lib/context';

import CourseStudyModal from './CourseStudyModal';

const CourseStudyModalContainer = ({ course, ...props }) => {
  const { t } = useTranslation();
  const { theme } = useContext(Context);
  const { needTutorialIllustration1, needTutorialIllustration2 } =
    tutorialIllustrationsChecker(course?.id);
  return (
    <CourseStudyModal
      course={course}
      needTutorialIllustration1={needTutorialIllustration1}
      needTutorialIllustration2={needTutorialIllustration2}
      t={t}
      theme={theme}
      {...props}
    />
  );
};

CourseStudyModalContainer.propTypes = {
  course: PropTypes.object,
};

CourseStudyModalContainer.defaultProps = {
  course: undefined,
};

export default CourseStudyModalContainer;
