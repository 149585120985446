import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { mutationEditorQuestionPropose } from 'gql/mutations/editor';
import { useQueryCourseGames } from 'gql/queries/course';
import { useQueryTestStatus } from 'gql/queries/test';
import tutorialIllustrationsChecker from 'helpers/tutorialIllustrationsChecker';
import useHotkeys from 'hooks/useHotkeys';
import useSiteContext from 'hooks/useSiteContext';

import Cover from './Cover';

const CoverContainer = (props) => {
  const { t } = useTranslation();
  const { isEditionDisabled } = useSiteContext();
  const { courseId } = useParams();
  const { isLoading: isLoadingCourse, data: dataCourse } = useQueryCourseGames({
    variables: {
      courseId,
    },
  });
  const { isLoading: isLoadingTest, data: dataTest } = useQueryTestStatus({
    variables: {
      courseId,
    },
    skip: isLoadingCourse || !dataCourse.course.isExam,
  });
  const { needTutorialIllustration1, needTutorialIllustration2 } =
    tutorialIllustrationsChecker(courseId);
  const isLoading = useMemo(
    () => isLoadingCourse || (dataCourse.course.isExam && isLoadingTest),
    [isLoadingCourse, dataCourse, isLoadingTest],
  );
  const canTakeExam = useMemo(
    () =>
      dataCourse &&
      dataTest &&
      dataCourse.course.isExam &&
      (dataTest.testStatus.status === 203 ||
        dataTest.testStatus.status === 202 ||
        dataTest.testStatus.status === 201),
    [dataCourse, dataTest],
  );
  const canSeeExamReview = useMemo(
    () =>
      dataCourse &&
      dataTest &&
      dataCourse.course.isExam &&
      dataCourse.course.isExamReviewEnabled &&
      (dataTest.testStatus.status === 203 ||
        dataTest.testStatus.when === 'after'),
    [dataCourse, dataTest],
  );
  useHotkeys('shift+n', mutationEditorQuestionPropose);
  return (
    <Cover
      canSeeExamReview={canSeeExamReview}
      canTakeExam={canTakeExam}
      course={dataCourse?.course}
      handleQuestionCreate={mutationEditorQuestionPropose}
      isEditionDisabled={isEditionDisabled}
      isLoading={isLoading}
      needTutorialIllustration1={needTutorialIllustration1}
      needTutorialIllustration2={needTutorialIllustration2}
      t={t}
      {...props}
    />
  );
};

export default CoverContainer;
