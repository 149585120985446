import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Options from 'questions/Shared/Options';
import Tags from 'questions/Shared/Tags';
import Button from 'uikit/Button';
import Icon from 'uikit/Icon';
import InputError from 'uikit/InputError';
import InputImage from 'uikit/InputImage';
import InputRich from 'uikit/InputRich';
import Tooltip from 'uikit/Tooltip';

import ImageContainer from './ImageContainer';
import styles from './Editor.module.scss';

const cx = classNames.bind(styles);

const Editor = ({ className, handleImageDelete, handleZoneAdd, image, t }) => (
  <div className={cx('container', className)}>
    <p className={cx('label')}>{t('Questions/FindOnImage/Editor/image')}</p>
    {image ? (
      <>
        <InputError name="zones" />
        <div className={cx('toolbar')}>
          <Button
            className={cx('action')}
            icon={<Icon name="rectangle-landscape" variant="light" />}
            onClick={() => handleZoneAdd('rectangle')}
          >
            {t('Questions/FindOnImage/Editor/new-rectangle')}
          </Button>
          <Button
            className={cx('action')}
            icon={<Icon name="circle" variant="light" />}
            onClick={() => handleZoneAdd('circle')}
          >
            {t('Questions/FindOnImage/Editor/new-circle')}
          </Button>
          <Button
            className={cx('action')}
            icon="trash-alt"
            onClick={handleImageDelete}
            tooltip={t('Questions/FindOnImage/Editor/delete-image')}
            variant="regular"
          />
        </div>
        <ImageContainer
          className={cx('field', 'image-container')}
          imageSrc={image}
        />
      </>
    ) : (
      <InputImage className={cx('field', 'image')} name="image" />
    )}
    <InputRich
      helper={
        <Tooltip tooltip={t('Questions/FindOnImage/Editor/feedback-helper')}>
          <Icon name="info-circle" />
        </Tooltip>
      }
      label={t('Questions/FindOnImage/feedback-label')}
      name="feedback"
    />
    <Options className={cx('options')}>
      <Tags />
    </Options>
  </div>
);

Editor.propTypes = {
  className: PropTypes.string,
  handleImageDelete: PropTypes.func.isRequired,
  handleZoneAdd: PropTypes.func.isRequired,
  image: PropTypes.string,
  t: PropTypes.func.isRequired,
};

Editor.defaultProps = {
  className: undefined,
  image: undefined,
};

export default Editor;
