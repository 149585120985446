import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import FormattedText from 'uikit/FormattedText';

import VideoPreview from 'uikit/VideoPicker/VideoPreview';
import styles from './Verso.module.scss';

const cx = classNames.bind(styles);

const Verso = ({ className, question }) => (
  <div className={cx('container', className)}>
    <FormattedText className={cx('title')}>{question.title}</FormattedText>
    <VideoPreview video={question.content.video} />
  </div>
);

Verso.propTypes = {
  className: PropTypes.string,
  question: PropTypes.object.isRequired,
};

Verso.defaultProps = {
  className: undefined,
};

export default Verso;
