import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import LanguageSelector from 'components/Shared/LanguageSelector';
import { useMutationMeUpdate } from 'gql/mutations/me';
import { useQueryMe } from 'gql/queries/me';
import { trackEvent, reportError } from 'lib/tracking';
import Button from 'uikit/Button';

import STUDENT_IMAGE from './images/student.svg';
import TEACHER_IMAGE from './images/teacher.svg';
import styles from './Role.module.scss';

const cx = classNames.bind(styles);

const Role = ({ handleStepNext, me, onBack }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'App/Onboarding/Role',
  });

  const mutationMeUpdate = useMutationMeUpdate();

  const { data: meData } = useQueryMe();

  const isNewOnboarding = meData.me.flags?.includes('new-onboarding');

  const onboardingSteps = new Array(isNewOnboarding ? 6 : 3).fill(0);

  const handleRoleUpdate = async (role) => {
    try {
      await mutationMeUpdate({
        role,
      });
      handleStepNext();
      trackEvent('onboarding', 'role');
    } catch (err) {
      reportError('onboarding', 'role', err);
    }
  };

  return (
    <div className={cx('container')}>
      <div className={cx('form-container')}>
        <div className={cx('header')}>
          <Button
            aria-label={t('back')}
            className={cx('back')}
            icon="arrow-left"
            intent="gray"
            onClick={onBack}
          />
          <div className={cx('points')}>
            {onboardingSteps.map((_, index) => (
              <div
                className={cx('point', {
                  'new-onboarding-point': isNewOnboarding,
                })}
                key={`point-${index}`}
              />
            ))}
          </div>
          <LanguageSelector className={cx('language')} intent="square" />
        </div>
        <div className={cx('form')}>
          <h1 className={cx('title')}>{t('select-role')}</h1>
          <div className={cx('select-buttons')}>
            <button
              className={cx('select-button')}
              onClick={() => handleRoleUpdate('student')}
              type="button"
            >
              {t(`student-${me.sector}`)}
              <img
                alt=""
                aria-hidden="true"
                className={cx('button-illustration')}
                src={STUDENT_IMAGE}
              />
            </button>
            <button
              className={cx('select-button')}
              data-testid={
                isNewOnboarding ? 'teacher-button-new-tour' : 'teacher-button'
              }
              onClick={() => handleRoleUpdate('teacher')}
              type="button"
            >
              {t(`teacher-${me.sector}`)}
              <img
                alt=""
                aria-hidden="true"
                className={cx('button-illustration')}
                src={TEACHER_IMAGE}
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

Role.propTypes = {
  handleStepNext: PropTypes.func.isRequired,
  me: PropTypes.object.isRequired,
  onBack: PropTypes.func.isRequired,
};

export default Role;
