import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useMutationCourseCreate } from 'gql/mutations/course';
import { useQueryMe } from 'gql/queries/me';
import useModal from 'hooks/useModal';
import history from 'lib/history';
import { trackEvent, reportError } from 'lib/tracking';

import Empty from './Empty';

const EmptyContainer = (props) => {
  const { t } = useTranslation();
  const [isCourseCreating, setCourseCreating] = useState(false);
  const mutationCourseCreate = useMutationCourseCreate();
  const handleCourseCreate = useCallback(async () => {
    try {
      setCourseCreating(true);
      const {
        data: { courseCreate },
      } = await mutationCourseCreate({
        title: t('Dashboard/Empty/new-course-title'),
      });
      history.push(`/course/${courseCreate.id}/`);
      trackEvent('course', 'create');
    } catch (err) {
      reportError('course', 'create', err);
    } finally {
      setCourseCreating(false);
    }
  }, [mutationCourseCreate, t]);
  const {
    isModalOpen: isCourseJoinDisplayed,
    handleModalOpen: handleCourseJoinOpen,
    handleModalClose: handleCourseJoinClose,
  } = useModal();
  const { isLoading, data } = useQueryMe();
  const role = isLoading ? 'student' : data.me.role || 'student';
  return (
    <Empty
      handleCourseCreate={handleCourseCreate}
      handleCourseJoinOpen={handleCourseJoinOpen}
      handleCourseJoinClose={handleCourseJoinClose}
      isCourseCreating={isCourseCreating}
      isCourseJoinDisplayed={isCourseJoinDisplayed}
      role={role}
      t={t}
      {...props}
    />
  );
};

export default EmptyContainer;
