import React, { useContext } from 'react';

import Context from 'lib/context';

import Feedback from './Feedback';
import { useQueryLocalStudy } from 'gql/queries/local';

const FeedbackContainer = (props) => {
  const { theme } = useContext(Context);
  const {
    data: {
      study: { hasMascotFlag },
    },
  } = useQueryLocalStudy();
  return (
    <Feedback hasMascotFlag={hasMascotFlag} theme={theme} {...props} />
  );
};

export default FeedbackContainer;
