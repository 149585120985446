import * as dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';

import { mutationEditorReset } from 'gql/mutations/editor';
import { useMutationCourseOpen } from 'gql/mutations/course';
import { useQueryFullCourse } from 'gql/queries/course';
import useOnError from 'hooks/useOnError';
import useSiteContext from 'hooks/useSiteContext';
import Context from 'lib/context';
import history from 'lib/history';
import { reportError, trackEvent } from 'lib/tracking';

import Course from './Course';

const CourseContainer = () => {
  const { courseId } = useParams();
  const { error, isLoading, data } = useQueryFullCourse({
    variables: {
      courseId,
    },
  });
  useOnError(error, () => {
    history.push('/');
  });
  useEffect(() => {
    mutationEditorReset();
    return () => {
      mutationEditorReset();
    };
  }, []);
  const mutationCourseOpen = useMutationCourseOpen();
  useEffect(() => {
    async function trackLastConnection() {
      try {
        if (
          data &&
          (dayjs(data.course.lastConnection)
            .add(5, 'minutes')
            .isBefore(new Date()) ||
            !data.course.lastConnection)
        ) {
          await mutationCourseOpen(data.course);
          trackEvent('course', 'open');
        }
      } catch (err) {
        reportError('course', 'open', err);
      }
    }
    trackLastConnection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutationCourseOpen]);
  const { isInTeams } = useSiteContext();
  return (
    <Context.Provider
      value={{
        theme: data?.course.themeBackground,
      }}
    >
      <Course
        course={data?.course}
        isInTeams={isInTeams}
        isLoading={isLoading}
      />
    </Context.Provider>
  );
};

export default CourseContainer;
