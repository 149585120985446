import { useField } from 'formik';
import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import Editor from './Editor';

const EditorContainer = (props) => {
  const { t } = useTranslation();
  const dndRef = useRef(null);
  const handleMoveUp = useCallback(async (index) => {
    if (dndRef.current) {
      const drag = dndRef.current.tryGetLock(index.toString(), () => null);
      const ele = drag.snapLift();
      ele.moveUp();
      await new Promise((resolve) => setTimeout(resolve, 500));
      ele.drop();
    }
  }, []);
  const handleMoveDown = useCallback(async (index) => {
    if (dndRef.current) {
      const drag = dndRef.current.tryGetLock(index.toString(), () => null);
      const ele = drag.snapLift();
      ele.moveDown();
      await new Promise((resolve) => setTimeout(resolve, 500));
      ele.drop();
    }
  }, []);
  const [{ value: items }, , { setValue: setItems }] = useField('items');
  const handleItemAdd = useCallback(() => {
    setItems([...items, '']);
  }, [items, setItems]);
  const handleItemComplete = useCallback(
    (model) => {
      if (model) {
        setItems([...items, model]);
      }
    },
    [items, setItems],
  );
  const handleItemRemove = useCallback(
    (index) => {
      const newItems = items.filter((a, i) => i !== index);
      if (newItems.length < 1) {
        newItems.push('');
      }
      setItems(newItems);
    },
    [items, setItems],
  );
  const handleItemMove = useCallback(
    ({ source, destination }) => {
      if (destination) {
        const newItems = [...items];
        newItems.splice(
          destination.index,
          0,
          newItems.splice(source.index, 1)[0],
        );
        setItems(newItems);
      }
    },
    [items, setItems],
  );
  return (
    <Editor
      dndRef={dndRef}
      handleItemAdd={handleItemAdd}
      handleItemComplete={handleItemComplete}
      handleItemMove={handleItemMove}
      handleItemRemove={handleItemRemove}
      handleMoveDown={handleMoveDown}
      handleMoveUp={handleMoveUp}
      items={items}
      t={t}
      {...props}
    />
  );
};

EditorContainer.validationSchema = (t) =>
  yup.object().shape({
    tags: yup.array().of(yup.string().trim().lowercase().max(256)).compact(),
    title: yup
      .string()
      .trim()
      .required(t('Questions/Sorting/Editor/title-error-required')),
    items: yup
      .array()
      .of(
        yup.object().shape({
          idx: yup.number().required().integer().min(0),
          title: yup.string().trim().required(),
        }),
      )
      .compact()
      .transform((items) =>
        items
          .filter((item) => !!item.title)
          .map((item, index) => ({
            ...item,
            idx: index,
          })),
      )
      .test(
        'length',
        t('Questions/Sorting/Editor/items-error-length'),
        (items) => items.length >= 2,
      ),
    feedback: yup.string().trim(),
  });

export default EditorContainer;
