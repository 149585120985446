import { withTranslation } from 'react-i18next';

import Verso from './Verso';

const VersoContainer = withTranslation()(Verso);

VersoContainer.defaultAnswer = () => '';

VersoContainer.defaultSolution = () => ({
  user: null,
});

export default VersoContainer;
