import classNames from 'classnames/bind';
import { Formik, Form } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { mutationEditorQuestionImportPreview } from 'gql/mutations/editor';
import { useQueryLocalEditor } from 'gql/queries/local';
import { getQuestionRecto, getQuestionVerso } from 'questions';
import Button from 'uikit/Button';

import styles from './QuestionImporterPreview.module.scss';

const cx = classNames.bind(styles);

const QuestionImporterPreview = () => {
  const { t } = useTranslation('', {
    keyPrefix:
      'Course/Teacher/Editor/QuestionImport/QuestionImportPublicCourses/QuestionImporter/QuestionImporterPreview',
  });

  const {
    data: {
      editor: { questionEditing },
    },
  } = useQueryLocalEditor();

  const [seeRecto, setSeeRecto] = useState(true);

  useEffect(() => setSeeRecto(true), []);

  const handleToggleCard = () => setSeeRecto((p) => !p);

  const RectoQuestion = getQuestionRecto(questionEditing?.type);

  const VersoQuestion = getQuestionVerso(questionEditing?.type);

  const initialValues = {
    answer: RectoQuestion?.defaultAnswer(questionEditing),
  };

  const question = {
    ...questionEditing,
    solution: VersoQuestion?.defaultSolution(questionEditing),
  };

  return (
    <div className={cx('container')}>
      <div className={cx('buttons')}>
        <Button
          className={cx('left')}
          icon="arrow-left"
          onClick={() => mutationEditorQuestionImportPreview(null)}
          size="large"
          tooltip={t('back')}
        />
        <Button className={cx('flip')} icon="repeat" onClick={handleToggleCard}>
          {t(seeRecto ? 'see-verso' : 'see-recto')}
        </Button>
      </div>
      <p className={cx('explanation')}>{t('explanation')}</p>
      <Formik
        initialValues={initialValues}
        key={question.id}
        onSubmit={() => null}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {() => (
          <Form>
            {seeRecto
              ? RectoQuestion && <RectoQuestion question={question} />
              : VersoQuestion && <VersoQuestion question={question} />}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default QuestionImporterPreview;
