import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Options from 'questions/Shared/Options';
import Tags from 'questions/Shared/Tags';
import Icon from 'uikit/Icon';
import InputRich from 'uikit/InputRich';
import Tooltip from 'uikit/Tooltip';

import styles from './Editor.module.scss';

const cx = classNames.bind(styles);

const Editor = ({ className, t }) => (
  <div className={cx('container', className)}>
    <InputRich
      data-testid="feedback"
      helper={
        <Tooltip tooltip={t('Questions/Essay/Editor/feedback-helper')}>
          <Icon name="info-circle" />
        </Tooltip>
      }
      label={t('Questions/Essay/Editor/feedback-label')}
      name="feedback"
    />
    <Options className={cx('options')}>
      <Tags />
    </Options>
  </div>
);

Editor.propTypes = {
  className: PropTypes.string,
  t: PropTypes.func.isRequired,
};

Editor.defaultProps = {
  className: undefined,
};

export default Editor;
