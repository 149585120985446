import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'uikit/Button';
import HelpTooltip from 'uikit/HelpTooltip';
import Icon from 'uikit/Icon';

import styles from './PreviewHeader.module.scss';

const cx = classNames.bind(styles);

const PreviewHeader = ({ isQuestionSaved, onQuestionUpdate }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Shared/QuestionEdit/QuestionPreview/PreviewHeader',
  });

  return (
    <div className={cx('header')}>
      <div className={cx('information')}>
        <p className={cx('header-title')}>
          <span className={cx('title-large')}>{t('title')}</span>
          <span className={cx('title-small')}>{t('title-mobile')}</span>
        </p>
        <HelpTooltip
          className={cx('header-description')}
          intent="warning"
          tooltip={t('explanation')}
        />
      </div>
      <div className={cx('header-right')}>
        <div className={cx('saved-container')}>
          <div
            className={cx('save-information', {
              isVisible: isQuestionSaved,
            })}
          >
            <Icon name="check" />
            <p className={cx('save-text')}>{t('saved')}</p>
          </div>
        </div>
        <Button onClick={onQuestionUpdate} size="large">
          {t('modify-question')}
        </Button>
      </div>
    </div>
  );
};

PreviewHeader.propTypes = {
  isQuestionSaved: PropTypes.bool.isRequired,
  onQuestionUpdate: PropTypes.func.isRequired,
};

export default PreviewHeader;
