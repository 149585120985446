import classNames from 'classnames/bind';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import LanguageSelector from 'components/Shared/LanguageSelector';
import { useMutationMeUpdate } from 'gql/mutations/me';
import extract from 'helpers/extract';
import { trackEvent, reportError } from 'lib/tracking';
import Button from 'uikit/Button';
import InputText from 'uikit/InputText';

import ILLUSTRATION from './images/higher.svg';
import styles from './Institution.module.scss';

const cx = classNames.bind(styles);

const Institution = ({ handleStepNext, me, onBack }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'App/Onboarding/Institution',
  });

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        institution: yup.string().trim().max(256),
        branch: yup.string().trim().max(256),
      }),
    [],
  );

  const mutationMeUpdate = useMutationMeUpdate();

  const handleInstitutionInfoUpdate = async (values, form) => {
    try {
      form.setStatus(null);
      const variables = extract(values, validationSchema);
      await mutationMeUpdate(variables);
      handleStepNext();
      trackEvent('onboarding', 'institution-info');
    } catch (err) {
      form.setStatus(err.message);
      reportError('onboarding', 'institution-info', err);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={me}
      onSubmit={handleInstitutionInfoUpdate}
      validationSchema={validationSchema}
    >
      {({ isSubmitting, status }) => (
        <Form className={cx('container')}>
          <div
            className={cx('form-container')}
            // Specific style applied to the form container to handle height issue on mobile
            // src: https://chanind.github.io/javascript/2019/09/28/avoid-100vh-on-mobile-web.html
            style={{ height: window.innerHeight }}
          >
            <div className={cx('header')}>
              <Button
                aria-label={t('back')}
                className={cx('back')}
                icon="arrow-left"
                intent="gray"
                onClick={onBack}
              />
              <div className={cx('points')}>
                {[1, 2, 3, 4, 5, 6].map((element) => (
                  <div className={cx('point')} key={element} />
                ))}
              </div>
              <LanguageSelector className={cx('language')} intent="square" />
            </div>
            <div className={cx('form-container')}>
              <img
                alt=""
                className={cx('illustration')}
                aria-hidden="true"
                src={ILLUSTRATION}
              />
              <div className={cx('form')}>
                <div className={cx('form-content')}>
                  <h1 className={cx('title')}>{t('title')}</h1>
                  {status && (
                    <p className={cx('error')} role="alert">
                      {t('onboarding-error')}
                    </p>
                  )}
                  {me.sector === 'lower' ? (
                    <InputText
                      className={cx('field')}
                      intent="gray"
                      placeholder={t('school-label')}
                      name="institution"
                    />
                  ) : me.sector === 'higher' ? (
                    <>
                      <InputText
                        className={cx('field')}
                        intent="gray"
                        placeholder={t('institution-label')}
                        name="institution"
                      />
                      <InputText
                        className={cx('field')}
                        intent="gray"
                        placeholder={t('branch-label')}
                        name="branch"
                      />
                    </>
                  ) : (
                    <InputText
                      className={cx('field')}
                      intent="gray"
                      placeholder={t('company-label')}
                      name="institution"
                    />
                  )}
                </div>
                <Button
                  className={cx('action', 'submit-button')}
                  data-testid="update-institution-info-button"
                  intent="primary"
                  isLoading={isSubmitting}
                  size="large"
                  type="submit"
                >
                  {t('update-info')}
                </Button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

Institution.propTypes = {
  handleStepNext: PropTypes.func.isRequired,
  me: PropTypes.object.isRequired,
  onBack: PropTypes.func.isRequired,
};

export default Institution;
