import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  mutationEditorClear,
  mutationEditorQuestionsMove,
} from 'gql/mutations/editor';
import {
  useMutationQuestionDuplicateMultiple,
  useMutationQuestionDeleteMultiple,
} from 'gql/mutations/question';
import { useQueryLocalEditor } from 'gql/queries/local';
import { trackEvent, reportError } from 'lib/tracking';
import Button from 'uikit/Button';

import styles from './Multi.module.scss';

const cx = classNames.bind(styles);

const Multi = ({ course }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/Editor/Multi',
  });

  const mutationQuestionDeleteMultiple = useMutationQuestionDeleteMultiple();

  const mutationQuestionDuplicateMultiple =
    useMutationQuestionDuplicateMultiple();

  const {
    data: {
      editor: { questionsSelected },
    },
  } = useQueryLocalEditor();

  const handleQuestionsDelete = async () => {
    try {
      await mutationQuestionDeleteMultiple(course, questionsSelected);
      mutationEditorClear();
      trackEvent('question', 'delete');
    } catch (err) {
      reportError('question', 'delete', err);
    }
  };

  const handleQuestionsDuplicate = async () => {
    try {
      await mutationQuestionDuplicateMultiple(course, questionsSelected);
      trackEvent('question', 'duplicate');
    } catch (err) {
      reportError('question', 'duplicate', err);
    }
  };

  return (
    <div className={cx('container')}>
      <Button
        className={cx('action')}
        icon="file-export"
        intent="white"
        onClick={mutationEditorQuestionsMove}
        tooltip={t('move-questions')}
      />
      <Button
        className={cx('action')}
        icon="copy"
        intent="white"
        onClick={handleQuestionsDuplicate}
        tooltip={t('duplicate-questions')}
      />
      <Button
        className={cx('action')}
        icon="trash-alt"
        intent="white"
        onClick={handleQuestionsDelete}
        tooltip={t('delete-questions')}
        variant="regular"
      />
      <Button
        className={cx('action')}
        icon="times"
        intent="white"
        onClick={mutationEditorClear}
        tooltip={t('clear')}
      />
    </div>
  );
};

Multi.propTypes = {
  course: PropTypes.object.isRequired,
};

export default Multi;
