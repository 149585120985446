import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Icon from 'uikit/Icon';
import HelpTooltip from 'uikit/HelpTooltip';

import styles from './TagProgress.module.scss';

const cx = classNames.bind(styles);

const TagProgress = ({ tag }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/Statistics/Tags/TagProgress',
  });

  return (
    <div className={cx('container')}>
      <h2 className={cx('title')}>{tag.title}</h2>
      <div
        aria-label={t('aria-success', {
          success: tag.success,
        })}
        className={cx('line')}
      >
        <Icon className={cx('indicator', 'success')} name="trophy" />
        <p className={cx('name')}>
          {t('success-name')}
          <HelpTooltip
            className={cx('tooltip')}
            tooltip={t('success-tooltip')}
          />
        </p>
        <div className={cx('gauge', 'success')}>
          <div
            style={{
              width: `${tag.success}%`,
            }}
          />
        </div>
        <p className={cx('percentage')}>{`${tag.success}%`}</p>
      </div>
      <div
        aria-label={t('aria-progression', {
          progression: tag.progression,
        })}
        className={cx('line')}
      >
        <Icon className={cx('indicator', 'progression')} name="rocket" />
        <p className={cx('name')}>
          {t('progression-name')}
          <HelpTooltip
            className={cx('tooltip')}
            tooltip={t('progression-tooltip')}
          />
        </p>
        <div className={cx('gauge', 'progression')}>
          <div
            style={{
              width: `${tag.progression}%`,
            }}
          />
        </div>
        <p className={cx('percentage')}>{`${tag.progression}%`}</p>
      </div>
      <div
        aria-label={t('aria-mastery', {
          mastery: tag.mastery,
        })}
        className={cx('line')}
      >
        <Icon className={cx('indicator', 'mastery')} name="dumbbell" />
        <p className={cx('name')}>
          {t('mastery-name')}
          <HelpTooltip
            className={cx('tooltip')}
            tooltip={t('mastery-tooltip')}
          />
        </p>
        <div className={cx('gauge', 'mastery')}>
          <div
            style={{
              width: `${tag.mastery}%`,
            }}
          />
        </div>
        <p className={cx('percentage')}>{`${tag.mastery}%`}</p>
      </div>
    </div>
  );
};

TagProgress.propTypes = {
  tag: PropTypes.object.isRequired,
};

export default TagProgress;
