import { useEffect } from 'react';
import { useLocation } from 'react-router';

import history from 'lib/history';

import {
  mutationEditorQuestionComments,
  mutationEditorQuestionCommentsChange,
} from 'gql/mutations/editor';

export const useURLToStateQuestionCommentOpenQuestion = (isLoading, data) => {
  const { hash } = useLocation();
  useEffect(() => {
    if (!isLoading) {
      if (/^#comment\/(.+)\/(.+)$/g.test(hash)) {
        const [, questionId] = hash.match(/^#comment\/(.+?)\/(.+?)$/);
        const question = []
          .concat(
            data.course.questions,
            ...data.course.chapters.map((c) => c.questions),
          )
          .find((q) => q.id === questionId);
        if (question) {
          mutationEditorQuestionComments(question);
        }
      }
    }
  }, [data, hash, isLoading]);
};

export const useURLToStateQuestionCommentOpenComment = (isLoading, data) => {
  const { pathname, hash } = useLocation();
  useEffect(() => {
    if (!isLoading) {
      if (/^#comment\/(.+)\/(.+)$/g.test(hash)) {
        const [, , commentId] = hash.match(/^#comment\/(.+?)\/(.+?)$/);
        const comment = data.question.comments.find((c) => c.id === commentId);
        if (comment) {
          mutationEditorQuestionCommentsChange(comment);
        }
        history.push(pathname);
      }
    }
  }, [data, hash, isLoading, pathname]);
};
