import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'uikit/Button';
import Modal from 'uikit/Modal';
import Icon from 'uikit/Icon';

import styles from './ExpertInstructions.module.scss';

const cx = classNames.bind(styles);

const ExpertInstructions = ({
  className,
  handleModalClose,
  handleModalOpen,
  isModalOpen,
  questionType,
  t,
}) => (
  <>
    <Button
      className={cx(className, 'instructions-icon')}
      icon="info-circle"
      onClick={handleModalOpen}
    />
    <Modal isOpen={isModalOpen} onExit={handleModalClose}>
      <div className={cx('modal')}>
        <div className={cx('header')}>
          <h1 className={cx('title')}>{t(`Questions/${questionType}/name`)}</h1>
          <Button
            aria-label={t('Questions/Shared/aria-close')}
            className={cx('close')}
            icon="times"
            onClick={handleModalClose}
          />
        </div>
        <div className={cx('information-container')}>
          <Icon className={cx('information-icon')} name="info-circle" />
          <p className={cx('information')}>
            {t('Questions/Shared/experts-instructions')}
          </p>
        </div>
        <p className={cx('explanation')}>
          {t('Questions/Shared/experts-helper')}
        </p>
      </div>
    </Modal>
  </>
);

ExpertInstructions.propTypes = {
  className: PropTypes.string,
  handleModalClose: PropTypes.func.isRequired,
  handleModalOpen: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  questionType: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

ExpertInstructions.defaultProps = {
  className: undefined,
};

export default ExpertInstructions;
