import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useQueryMe } from 'gql/queries/me';
import CommentHeader from './CommentHeader';

const CommentHeaderContainer = ({ account, comment, ...props }) => {
  const { t } = useTranslation();
  const { isLoading, data } = useQueryMe();
  const canModify = useMemo(
    () => comment && (isLoading ? false : data.me.id === account.id),
    [account, comment, isLoading, data],
  );
  return (
    <CommentHeader
      account={account}
      canModify={canModify}
      comment={comment}
      t={t}
      {...props}
    />
  );
};

CommentHeaderContainer.propTypes = {
  account: PropTypes.object.isRequired,
  comment: PropTypes.object,
};

CommentHeaderContainer.defaultProps = {
  comment: undefined,
};

export default CommentHeaderContainer;
