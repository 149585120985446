import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import FormattedText from 'uikit/FormattedText';

import styles from './Recto.module.scss';

const cx = classNames.bind(styles);

const Recto = ({
  answer,
  className,
  handleChoiceSelect,
  isDisabled,
  question,
  t,
  theme,
}) => (
  <div className={cx('container', className, `theme-${theme}`)}>
    <FormattedText as="h1" className={cx('title')}>
      {question.title}
    </FormattedText>
    <div className={cx('subtitle')}>
      <FormattedText>{question.content.subtitle}</FormattedText>
    </div>
    {!isDisabled && (
      <div className={cx('instructions')}>
        {t('Questions/JCT/Recto/instructions')}
      </div>
    )}
    <div className={cx('experts')}>
      {[0, 1, 2, 3].map((index) => (
        <button
          aria-pressed={answer === index}
          className={cx('expert', {
            isActive: answer === index,
          })}
          data-testid={`JCT.${index}.answer`}
          disabled={isDisabled}
          key={index}
          onClick={() => handleChoiceSelect(index)}
          type="button"
        >
          {question.content.labels && question.content.labels[index]
            ? question.content.labels[index]
            : t(`Questions/JCT/experts-${index}-label`)}
        </button>
      ))}
    </div>
  </div>
);

Recto.propTypes = {
  answer: PropTypes.number,
  className: PropTypes.string,
  handleChoiceSelect: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  question: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
};

Recto.defaultProps = {
  answer: undefined,
  className: undefined,
};

export default Recto;
