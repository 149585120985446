import { useField, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useMutationQuestionGenerateFeedback } from 'gql/mutations/question';
import extract from 'helpers/extract';
import { notify } from 'uikit/Notifications';

import FeedbackWithIA from './FeedbackWithIA';

const FeedbackWithIAContainer = ({ type, validationSchema, ...props }) => {
  const { t } = useTranslation();

  const { values } = useFormikContext();

  const [, , { setValue: setFeedback }] = useField('feedback');

  const [errorType, setErrorType] = useState(null);

  const [isLoading, setLoading] = useState(false);

  const mutationQuestionGenerateFeedback =
    useMutationQuestionGenerateFeedback();

  const handleFeedbackGenerate = async () => {
    try {
      setErrorType(null);
      setLoading(true);
      const content = extract(values, validationSchema);
      const {
        data: { questionGenerateFeedback: feedback },
      } = await mutationQuestionGenerateFeedback({
        type,
        content,
      });
      setFeedback(feedback);
      notify('ia', t('Questions/Shared/FeedbackWithIA/notification'));
    } catch (err) {
      setErrorType(err.message === '400' ? 'user-input' : 'ai-service');
    } finally {
      setLoading(false);
    }
  };

  return (
    <FeedbackWithIA
      {...props}
      errorType={errorType}
      handleFeedbackGenerate={handleFeedbackGenerate}
      isLoading={isLoading}
      t={t}
    />
  );
};

FeedbackWithIAContainer.propTypes = {
  type: PropTypes.string.isRequired,
  validationSchema: PropTypes.object.isRequired,
};

export default FeedbackWithIAContainer;
