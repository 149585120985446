import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import Button from 'uikit/Button';

import styles from './BackButton.module.scss';

const cx = classNames.bind(styles);

const BackButton = ({ course = null, t }) => (
  <Button
    aria-label={t('Course/back')}
    as={Link}
    className={cx('back')}
    icon="arrow-left"
    intent="white"
    to={{
      pathname: '/',
      state: {
        currentDisplayedCourseId: course?.id,
      },
    }}
  />
);

BackButton.propTypes = {
  course: PropTypes.object,
  t: PropTypes.func.isRequired,
};

export default BackButton;
