import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';

import { useQueryLocalApp } from 'gql/queries/local';
import focusModalTitle from 'helpers/focusModalTitle';
import useSiteContext from 'hooks/useSiteContext';
import imageWooflashLogo from 'images/logos/logo.svg';
import Button from 'uikit/Button';

import styles from './SubPage.module.scss';

const cx = classNames.bind(styles);

const SubPage = ({
  className = null,
  children = null,
  header = null,
  headerSize = 'large',
  isFullScreen = false,
  isHeaderHidden = false,
  isOnboarding = false,
  isOpen = false,
  isSidePanel = false,
  onExit = null,
  subtitle = null,
  title = null,
}) => {
  const { t } = useTranslation('', {
    keyPrefix: 'uikit/SubPage',
  });

  const { isInTeams } = useSiteContext();

  const {
    data: {
      app: { isMenuExpanded },
    },
  } = useQueryLocalApp();

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => focusModalTitle(), 1000);
    }
  }, [isOpen]);

  return (
    <ReactModal
      ariaHideApp={false}
      className={cx('modal', {
        'teams-view': isInTeams,
        'full-screen': isFullScreen,
        'collapsed-menu': !isMenuExpanded,
        'side-panel': isSidePanel,
        'large-side-panel': isSidePanel && !isMenuExpanded,
      })}
      closeTimeoutMS={500}
      isOpen={isOpen}
      onRequestClose={onExit}
      overlayClassName={cx('overlay', className, {
        'teams-view': isInTeams,
        'full-screen': isFullScreen,
        'collapsed-menu': !isMenuExpanded,
        'side-panel-overlay': isSidePanel,
      })}
    >
      {!isSidePanel && (
        <div
          className={cx('header', `header-size-${headerSize}`, {
            'header-full-screen': isFullScreen,
            'no-header': isHeaderHidden,
          })}
        >
          {!isOnboarding ? (
            isFullScreen ? (
              <>
                <div className={cx('logo')}>
                  <img
                    alt=""
                    aria-hidden="true"
                    className={cx('logo-image')}
                    src={imageWooflashLogo}
                  />
                  <span className={cx('logo-text')}>wooflash</span>
                </div>
                <Button
                  className={cx('close')}
                  icon="times"
                  intent="white"
                  onClick={onExit}
                  size="large"
                >
                  {t('back')}
                </Button>
              </>
            ) : (
              <>
                <Button
                  className={cx('back')}
                  intent="white"
                  icon="arrow-left"
                  onClick={onExit}
                  size={headerSize}
                  tooltip={t('back')}
                />
                <div>
                  {!title && !subtitle && header}
                  {title && <h3 className={cx('title')}>{title}</h3>}
                  {subtitle && <h4 className={cx('subtitle')}>{subtitle}</h4>}
                </div>
              </>
            )
          ) : null}
        </div>
      )}
      {children}
    </ReactModal>
  );
};

SubPage.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  header: PropTypes.node,
  headerSize: PropTypes.string,
  isFullScreen: PropTypes.bool,
  isHeaderHidden: PropTypes.bool,
  isOnboarding: PropTypes.bool,
  isOpen: PropTypes.bool,
  isSidePanel: PropTypes.bool,
  onExit: PropTypes.func,
  subtitle: PropTypes.string,
  title: PropTypes.string,
};

export default SubPage;
