import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import EDITOR_STATUSES from 'constants/editorStatuses';
import { mutationEditorClear } from 'gql/mutations/editor';
import { useMutationGroupDelete } from 'gql/mutations/group';
import { useQueryLocalEditor } from 'gql/queries/local';
import { trackEvent, reportError } from 'lib/tracking';

import GroupDelete from './GroupDelete';

const GroupDeleteContainer = () => {
  const { t } = useTranslation();
  const {
    data: {
      editor: { status: editorStatus, groupEditing },
    },
  } = useQueryLocalEditor();
  const isModalOpen = editorStatus === EDITOR_STATUSES.GROUP_DELETE;
  const [status, setStatus] = useState(undefined);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    setStatus(undefined);
  }, [isModalOpen]);
  const mutationGroupDelete = useMutationGroupDelete();
  const handleGroupDelete = useCallback(async () => {
    try {
      setStatus(undefined);
      setLoading(true);
      await mutationGroupDelete(groupEditing);
      mutationEditorClear();
      trackEvent('group', 'delete');
    } catch (err) {
      setStatus(err.message);
      reportError('group', 'delete', err);
    } finally {
      setLoading(false);
    }
  }, [groupEditing, mutationGroupDelete]);
  return (
    <GroupDelete
      handleClose={mutationEditorClear}
      handleGroupDelete={handleGroupDelete}
      isLoading={isLoading}
      isModalOpen={isModalOpen}
      status={status}
      t={t}
    />
  );
};

export default GroupDeleteContainer;
