import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { useQueryCourseStudents } from 'gql/queries/course';
import { useMutationCourseUpdate } from 'gql/mutations/course';
import { useMutationGroupCreate } from 'gql/mutations/group';
import {
  mutationEditorReset,
  mutationEditorGroupUpdate,
} from 'gql/mutations/editor';
import useSiteContext from 'hooks/useSiteContext';
import { trackEvent, reportError } from 'lib/tracking';

import Students from './Students';

const StudentsContainer = (props) => {
  const { t } = useTranslation();
  const { courseId } = useParams();
  const { isLoading, data } = useQueryCourseStudents({
    variables: {
      courseId,
    },
  });
  const isEmpty = useMemo(
    () =>
      isLoading
        ? false
        : data.course.students.length === 0 && data.course.groups.length === 0,
    [data, isLoading],
  );
  const nStudents = useMemo(
    () =>
      isLoading
        ? 0
        : data.course.students.length +
          data.course.groups.reduce((p, group) => p + group.students.length, 0),
    [data, isLoading],
  );
  useEffect(() => {
    mutationEditorReset();
    return () => {
      mutationEditorReset();
    };
  }, []);
  const mutationGroupCreate = useMutationGroupCreate();
  const handleGroupCreate = useCallback(async () => {
    try {
      if (data && data.course) {
        const {
          data: { groupCreate },
        } = await mutationGroupCreate({
          courseId,
          title: t('Course/Teacher/Students/new-group-title'),
        });
        mutationEditorGroupUpdate(groupCreate);
        trackEvent('group', 'create');
      }
    } catch (err) {
      reportError('group', 'create', err);
    }
  }, [courseId, data, mutationGroupCreate, t]);
  const { isGAR } = useSiteContext();
  const mutationCourseUpdate = useMutationCourseUpdate();
  const handleStudentCollaborationToggle = useCallback(async () => {
    try {
      if (data && data.course) {
        await mutationCourseUpdate(data.course, {
          isStudentCollaborationEnabled:
            !data.course.isStudentCollaborationEnabled,
        });
        trackEvent('course', 'update');
      }
    } catch (err) {
      reportError('course', 'update', err);
    }
  }, [data, mutationCourseUpdate]);
  return (
    <Students
      course={data?.course}
      handleStudentCollaborationToggle={handleStudentCollaborationToggle}
      handleGroupCreate={handleGroupCreate}
      isEmpty={isEmpty}
      isGAR={isGAR}
      isLoading={isLoading}
      nStudents={nStudents}
      t={t}
      {...props}
    />
  );
};

export default StudentsContainer;
