import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Options from 'questions/Shared/Options';
import Tags from 'questions/Shared/Tags';
import Icon from 'uikit/Icon';
import InputError from 'uikit/InputError';
import InputRich from 'uikit/InputRich';
import Tooltip from 'uikit/Tooltip';

import ExpertInstructions from '../../Shared/ExpertInstructions';
import ExpertOpinionEditor from '../../Shared/ExpertOpinionEditor';
import styles from './Editor.module.scss';

const cx = classNames.bind(styles);

const Editor = ({ className, t }) => (
  <div className={cx('container', className)}>
    <InputRich
      className={cx('field')}
      data-testid="subtitle"
      label={t('Questions/SCT/Editor/hypotheses-label')}
      name="subtitle"
      placeholder={t('Questions/SCT/Editor/subtitle-placeholder')}
    />
    <InputRich
      className={cx('field')}
      data-testid="additionalInformation"
      label={t('Questions/SCT/Editor/additional-information-label')}
      name="additionalInformation"
      placeholder={t('Questions/SCT/Editor/additional-information-placeholder')}
    />
    <div className={cx('label-container')}>
      <p className={cx('subtitle')}>
        {t('Questions/SCT/Editor/experts-label')}
      </p>
      <ExpertInstructions questionType="SCT" />
    </div>
    <InputError name="experts" />
    <div className={cx('field', 'experts')}>
      {[0, 1, 2, 3, 4].map((index) => (
        <ExpertOpinionEditor
          columnIndex={index}
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          questionType="SCT"
        />
      ))}
    </div>
    <InputRich
      helper={
        <Tooltip tooltip={t('Questions/SCT/Editor/feedback-helper')}>
          <Icon name="info-circle" />
        </Tooltip>
      }
      label={t('Questions/SCT/feedback-label')}
      name="feedback"
    />
    <Options className={cx('options')}>
      <Tags />
    </Options>
  </div>
);

Editor.propTypes = {
  className: PropTypes.string,
  t: PropTypes.func.isRequired,
};

Editor.defaultProps = {
  className: undefined,
};

export default Editor;
