import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Feedback from 'questions/Shared/Feedback';
import FormattedText from 'uikit/FormattedText';

import styles from './Verso.module.scss';

const cx = classNames.bind(styles);

const Verso = ({ className, question, t, theme }) => (
  <div
    className={cx(
      'container',
      className,
      `theme-${theme}`,
    )}
  >
    <FormattedText className={cx('title')}>{question.title}</FormattedText>
    <div className={cx('answer')}>
      <p className={cx('label')}>
        {t('Questions/Dictation/Verso/correct-answer')}
      </p>
      <pre className={cx('correct')}>{question.solution.correct}</pre>
      {question.result === 'wrong' && (
        <>
          <p className={cx('label')}>
            {t('Questions/Dictation/Verso/correction')}
          </p>
          <pre>
            {question.solution.correction.map((change, index) => (
              <span
                className={cx({
                  isAdded: change.added,
                  isRemoved: change.removed,
                })}
                // eslint-disable-next-line react/no-array-index-key
                key={index}
              >
                {change.value}
              </span>
            ))}
          </pre>
        </>
      )}
    </div>
    {question.content.feedback && (
     <Feedback feedback={question.content.feedback} />
    )}
  </div>
);

Verso.propTypes = {
  className: PropTypes.string,
  question: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
};

Verso.defaultProps = {
  className: undefined,
};

export default Verso;
