import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import CourseItem from './CourseItem';
import styles from './CoursesGAR.module.scss';

const cx = classNames.bind(styles);

const CoursesGAR = ({ courses, t }) => (
  <div className={cx('container')}>
    <h1 className={cx('title')}>{t('Dashboard/CoursesGAR/title')}</h1>
    {courses.length > 0 ? (
      courses.map((course) => (
        <CourseItem
          className={cx('course-item')}
          course={course}
          key={course.id}
        />
      ))
    ) : (
      <p className={cx('empty')}>{t('Dashboard/CoursesGAR/empty')}</p>
    )}
  </div>
);

CoursesGAR.propTypes = {
  courses: PropTypes.arrayOf(PropTypes.object).isRequired,
  t: PropTypes.func.isRequired,
};

export default CoursesGAR;
