import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import AudioPreview from 'uikit/AudioPicker/AudioPreview/';
import CleanedText from 'uikit/CleanedText';
import VideoPreview from 'uikit/VideoPicker/VideoPreview';

import Footer from './Footer';
import styles from './Aggregate.module.scss';

const cx = classNames.bind(styles);

const Aggregate = ({
  aggregation,
  className,
  currentAnswer,
  handleAnswerNext,
  handleAnswerPrevious,
  question,
  questionInfo,
  setMediaDuration,
  t,
  mediaDuration,
}) => (
  <div className={cx('container', className)}>
    <div className={cx('background')}>
      <div className={cx('header')}>
        <div className={cx('title-container')}>
          <p className={cx('icon', `background-${questionInfo.color}`)}>
            <img alt="" src={questionInfo.icon} />
          </p>
          <CleanedText as="p" className={cx('title')}>
            {question?.title}
          </CleanedText>
        </div>
        <p
          className={cx('answers-numbers', {
            'answers-numbers-wrong': aggregation.nCorrectAnswers === 0,
          })}
        >
          <span
            className={cx('correct-answers', {
              isEmpty: aggregation.nCorrectAnswers === 0,
            })}
          >
            {t('Questions/Interview/Aggregate/total-answers', {
              count: aggregation.nTotalAnswers,
            })}
          </span>
        </p>
      </div>
      <div className={cx('answer-wrapper')}>
        {question.content.answerType === 'video' ? (
          <VideoPreview
            className={cx('preview')}
            footer={
              <Footer
                aggregation={aggregation}
                currentAnswer={currentAnswer}
                handleAnswerNext={handleAnswerNext}
                handleAnswerPrevious={handleAnswerPrevious}
                isVideo
                mediaDuration={mediaDuration}
              />
            }
            setVideoDuration={setMediaDuration}
            video={aggregation.aggregatedAnswers[currentAnswer].answer}
          />
        ) : (
          <AudioPreview
            audio={aggregation.aggregatedAnswers[currentAnswer].answer}
            className={cx('preview')}
            footer={
              <Footer
                aggregation={aggregation}
                currentAnswer={currentAnswer}
                handleAnswerNext={handleAnswerNext}
                handleAnswerPrevious={handleAnswerPrevious}
                mediaDuration={mediaDuration}
              />
            }
            setAudioDuration={setMediaDuration}
          />
        )}
      </div>
    </div>
  </div>
);

Aggregate.propTypes = {
  aggregation: PropTypes.object.isRequired,
  className: PropTypes.string,
  currentAnswer: PropTypes.number.isRequired,
  handleAnswerNext: PropTypes.func.isRequired,
  handleAnswerPrevious: PropTypes.func.isRequired,
  question: PropTypes.object.isRequired,
  questionInfo: PropTypes.object.isRequired,
  setMediaDuration: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  mediaDuration: PropTypes.string.isRequired,
};

Aggregate.defaultProps = {
  className: undefined,
};

export default Aggregate;
