import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ChapterCard from './ChapterCard';

const ChapterCardContainer = ({ chapter, questionTypes, ...props }) => {
  const { t } = useTranslation();
  const [isExpanded, setExpanded] = useState(false);
  const handleChapterExpandedToggle = useCallback(() => {
    setExpanded((open) => !open);
  }, []);
  const questions = useMemo(
    () =>
      chapter.questions.filter(
        (question) => !questionTypes || questionTypes.includes(question.type),
      ),
    [chapter, questionTypes],
  );
  const questionIds = useMemo(
    () => questions.map((question) => question.id),
    [questions],
  );
  const [{ value }, , { setValue }] = useField('questionIds');
  const isSelected = useMemo(
    () => questionIds.every((questionId) => value.includes(questionId)),
    [questionIds, value],
  );
  const handleChapterSelect = useCallback(() => {
    if (isSelected) {
      setValue(value.filter((questionId) => !questionIds.includes(questionId)));
    } else {
      setValue([
        ...value.filter((questionId) => !questionIds.includes(questionId)),
        ...questionIds,
      ]);
    }
  }, [isSelected, questionIds, setValue, value]);
  return (
    <ChapterCard
      {...props}
      chapter={chapter}
      handleChapterExpandedToggle={handleChapterExpandedToggle}
      handleChapterSelect={handleChapterSelect}
      isExpanded={isExpanded}
      isSelected={isSelected}
      questions={questions}
      t={t}
    />
  );
};

ChapterCardContainer.propTypes = {
  chapter: PropTypes.object.isRequired,
  className: PropTypes.string,
  questionTypes: PropTypes.arrayOf(PropTypes.string),
};

ChapterCardContainer.defaultProps = {
  className: undefined,
  questionTypes: undefined,
};

export default ChapterCardContainer;
