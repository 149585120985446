import classNames from 'classnames/bind';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import EDITOR_STATUSES from 'constants/editorStatuses';
import { mutationEditorClear } from 'gql/mutations/editor';
import { useMutationGameDelete } from 'gql/mutations/game';
import { useQueryLocalEditor } from 'gql/queries/local';
import { trackEvent, reportError } from 'lib/tracking';
import Button from 'uikit/Button';
import Modal from 'uikit/Modal';

import styles from './GameDelete.module.scss';

const cx = classNames.bind(styles);

const GameDelete = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/Games/GameDelete',
  });

  const mutationGameDelete = useMutationGameDelete();

  const {
    data: {
      editor: { status: editorStatus, gameEditing },
    },
  } = useQueryLocalEditor();

  const [status, setStatus] = useState(undefined);

  const [isLoading, setLoading] = useState(false);

  const isModalOpen = editorStatus === EDITOR_STATUSES.GAME_DELETE;

  useEffect(() => {
    setStatus(undefined);
  }, [isModalOpen]);

  const handleGameDelete = async () => {
    try {
      setStatus(undefined);
      setLoading(true);
      await mutationGameDelete(gameEditing);
      mutationEditorClear();
      trackEvent('game', 'delete');
    } catch (err) {
      setStatus(err.message);
      reportError('game', 'delete', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isModalOpen} onExit={mutationEditorClear}>
      <div className={cx('container')}>
        <Button
          aria-label={t('aria-close')}
          className={cx('close')}
          icon="times"
          onClick={mutationEditorClear}
        />
        <h1 className={cx('title')}>{t('title')}</h1>
        <p className={cx('subtitle')}>{t('subtitle')}</p>
        {status && (
          <p className={cx('error')} role="alert">
            {t('game-delete-error')}
          </p>
        )}
        <Button
          className={cx('action')}
          intent="danger"
          isLoading={isLoading}
          onClick={handleGameDelete}
          size="large"
        >
          {t('confirm-delete-game')}
        </Button>
      </div>
    </Modal>
  );
};

export default GameDelete;
