import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

import { useQueryLocalEditor } from 'gql/queries/local';
import { mutationEditorQuestionVerify } from 'gql/mutations/editor';
import { getQuestionInfo } from 'questions';
import CleanedText from 'uikit/CleanedText';
import Icon from 'uikit/Icon';

import styles from './QuestionToApprove.module.scss';

const cx = classNames.bind(styles);

const QuestionToApprove = ({ position, question }) => {
  const questionRef = useRef(null);

  const {
    data: {
      editor: { questionEditing },
    },
  } = useQueryLocalEditor();

  const isSelected = questionEditing?.id === question.id;

  useEffect(() => {
    if (isSelected && questionRef.current) {
      questionRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [isSelected, questionRef]);

  const handleQuestionVerify = () => {
    mutationEditorQuestionVerify(question);
  };

  const questionInfo = getQuestionInfo(question.type);

  return (
    <button
      className={cx('container', { isSelected })}
      onClick={handleQuestionVerify}
      type="button"
      ref={questionRef}
    >
      <p className={cx('icon')}>
        <img alt="" src={questionInfo.icon} />
      </p>
      <p className={cx('position')}>{position}.</p>
      <CleanedText as="p" className={cx('title')} role="button">
        {question.title}
      </CleanedText>
      <div className={cx('author')}>
        <Icon className={cx('author-icon')} name="user-graduate" />
        <p>{`${(question.author.lastName || 'Unknown').toUpperCase()}, ${
          question.author.firstName || ''
        }`}</p>
      </div>
    </button>
  );
};

QuestionToApprove.propTypes = {
  position: PropTypes.number.isRequired,
  question: PropTypes.object.isRequired,
};

export default QuestionToApprove;
