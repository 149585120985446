import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { Droppable } from 'react-beautiful-dnd';

import Questions from '../Questions/Questions';
import styles from './Standalone.module.scss';

const cx = classNames.bind(styles);

const Standalone = ({ className = null, course }) => (
  <div className={className}>
    <Droppable droppableId="course" type="question">
      {(providedQuestions, snapshotQuestions) => (
        <div
          className={cx('droppable', {
            isDraggingOver: snapshotQuestions.isDraggingOver,
          })}
          ref={providedQuestions.innerRef}
          {...providedQuestions.droppableProps}
        >
          <Questions chapterId={null} course={course} />
          {providedQuestions.placeholder}
        </div>
      )}
    </Droppable>
  </div>
);

Standalone.propTypes = {
  className: PropTypes.string,
  course: PropTypes.object.isRequired,
};

export default Standalone;
