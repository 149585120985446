import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Chapters from 'components/Course/Shared/Progress/Chapters';
import CourseProgress from 'components/Course/Shared/Progress/CourseProgress';
import QuestionEdit from 'components/Course/Shared/QuestionEdit/QuestionEdit';
import Agenda from 'components/Shared/Agenda/Agenda';
import CourseContent from 'components/Shared/CourseContent';
import LoadingScreen from 'components/Shared/LoadingScreen';

import styles from './Progress.module.scss';

const cx = classNames.bind(styles);

const Progress = ({ className, course, hasStatistics, isLoading }) =>
  isLoading ? (
    <LoadingScreen />
  ) : (
    <>
      <div className={className}>
        {course.deadline && (
          <div className={cx('agenda')}>
            <Agenda course={course} isSingle />
          </div>
        )}
        {hasStatistics && (
          <>
            <CourseProgress
              className={cx('course-progress')}
              course={course}
              isStudent
            />
            {course.chapters.length !== 0 && (
              <Chapters
                className={cx('chapters')}
                chapters={course.chapters}
                course={course}
                isStudent
              />
            )}
          </>
        )}
        <CourseContent course={course} />
        <QuestionEdit course={course} />
      </div>
    </>
  );
Progress.propTypes = {
  className: PropTypes.string,
  course: PropTypes.object,
  hasStatistics: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
};

Progress.defaultProps = {
  className: undefined,
  course: undefined,
  hasStatistics: undefined,
};

export default Progress;
