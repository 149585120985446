import froalaeditor from 'froala-editor';

froalaeditor.POPUP_TEMPLATES['math.insert'] = '[_CUSTOM_LAYER_]';

froalaeditor.PLUGINS.math = (editor) => {
  function initPopup() {
    const template = {
      custom_layer: `
        <div class="fr-layer fr-active">
          <div class="fr-input-line">
            <textarea id="fr-math-equation" rows="5"></textarea>
            <label for="fr-math-equation">LaTeX</label>
          </div>
          <div class="fr-action-buttons">
            <button class="fr-command fr-submit" data-cmd="mathInsert" type="button">
              ${editor.language.translate('Insert')}
            </button>
          </div>
        </div>`,
    };
    return editor.popups.create('math.insert', template);
  }

  function showPopup() {
    let $popup = editor.popups.get('math.insert');
    if (!$popup) {
      $popup = editor.math.initPopup();
    }
    editor.popups.setContainer('math.insert', editor.$tb);
    const $btn = editor.$tb.find('.fr-command[data-cmd="insertMath"]');
    const { left } = $btn.offset();
    const top =
      $btn.offset().top +
      (editor.opts.toolbarBottom ? 10 : $btn.outerHeight() - 10);
    editor.popups.show('math.insert', left, top, $btn.outerHeight());
  }

  function hidePopup() {
    editor.popups.hide('math.insert');
  }

  function insert() {
    const equationInput = document.getElementById('fr-math-equation');
    if (equationInput) {
      const value = equationInput.value.trim();
      if (value) {
        editor.html.insert(`$ ${value} $`);
        editor.undo.saveStep();
        equationInput.value = '';
        editor.math.hidePopup();
      }
    }
  }

  return {
    initPopup,
    showPopup,
    hidePopup,
    insert,
  };
};

froalaeditor.DefineIcon('insertMath', {
  FA5NAME: 'function',
  template: 'font_awesome_5',
});

froalaeditor.RegisterCommand('insertMath', {
  title: 'Insérer une équation',
  callback() {
    this.math.showPopup();
  },
});

froalaeditor.RegisterCommand('mathInsert', {
  callback() {
    this.math.insert();
  },
});
