import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import React from 'react';

import InputTextArray from './InputTextArray';

const InputTextArrayContainer = ({ intent, name, ...props }) => {
  const { t } = useTranslation();
  const [{ value: items }, , { setValue: setItems }] = useField(name);
  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: '2px solid #e7ebf0',
      borderRadius: intent === 'square' ? '5px' : '25px',
      minHeight: '49px',
      ':hover': {
        borderColor: '#e7ebf0',
      },
      ':focus-within': {
        border: '2px solid #1d254f',
      },
      ':focus-visible': {
        boxShadow: '0 0 0 2px #1d254f',
      },
    }),
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: '#fff',
      border: '2px solid #e7ebf0',
      borderRadius: '50px',
      overflow: 'hidden',
      fontWeight: 700,
      color: '#1d254f',
      padding: '0 0 0 5px',
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      fontWeight: 700,
      color: '#1d254f',
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      padding: '5px',
      boxShadow: '0 5px 10px 0 #d3d5f2',
      borderRadius: '50px',
      ':hover': {
        backgroundColor: '#f6f7fa',
      },
    }),
    input: (styles) => ({
      ...styles,
      fontWeight: 700,
      color: '#1d254f',
    }),
    placeholder: (styles) => ({
      ...styles,
      color: '#616161',
    }),
    clearIndicator: (styles) => ({
      ...styles,
      ':hover': {
        color: '#1d254f',
      },
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      ':hover': {
        color: '#1d254f',
      },
    }),
    menu: (styles) => ({
      ...styles,
      border: 'none',
      boxShadow: '0 10px 35px 0 rgba(25, 42, 116, 0.2)',
      fontWeight: '700',
    }),
    option: (styles) => ({
      ...styles,
      backgroundColor: 'transparent',
      color: '#656686',
      ':hover': {
        color: '#1d254f',
      },
      ':focus': {
        backgroundColor: '#1d254f',
      },
    }),
  };
  return (
    <InputTextArray
      customStyles={customStyles}
      name={name}
      items={items}
      setItems={setItems}
      t={t}
      {...props}
    />
  );
};

InputTextArrayContainer.propTypes = {
  intent: PropTypes.string,
  name: PropTypes.string.isRequired,
};

InputTextArrayContainer.defaultProps = {
  intent: undefined,
};

export default InputTextArrayContainer;
