const tutorialIllustrationsChecker = (courseId) => {
  const TutorialIds = {
    illustration1: ['2c1c4574-2d11-48d9-b59d-a4f1d4376901'],
    illustration2: [
      'bd05a538-d247-4e27-81ff-f7be40e4e7c0',
      '1f3cf0e2-2f0b-472a-8859-8bd3878deebd',
    ],
  };

  const needTutorialIllustration1 =
    TutorialIds.illustration1.includes(courseId);

  const needTutorialIllustration2 =
    TutorialIds.illustration2.includes(courseId);

  return { needTutorialIllustration1, needTutorialIllustration2 };
};

export default tutorialIllustrationsChecker;
