import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'uikit/Button';
import Modal from 'uikit/Modal';

import styles from './StudentDelete.module.scss';

const cx = classNames.bind(styles);

const StudentDelete = ({
  handleClose,
  handleStudentDelete,
  isLoading,
  isModalOpen,
  status,
  t,
}) => (
  <Modal isOpen={isModalOpen} onExit={handleClose}>
    <div className={cx('container')}>
      <Button
        aria-label={t('Course/Teacher/Students/StudentDelete/aria-close')}
        className={cx('close')}
        icon="times"
        onClick={handleClose}
      />
      <h1 className={cx('title')}>
        {t('Course/Teacher/Students/StudentDelete/title')}
      </h1>
      <p className={cx('subtitle')}>
        {t('Course/Teacher/Students/StudentDelete/subtitle')}
      </p>
      {status && (
        <p className={cx('error')} role="alert">
          {t('Course/Teacher/Students/StudentDelete/student-delete-error')}
        </p>
      )}
      <Button
        className={cx('action')}
        intent="danger"
        isLoading={isLoading}
        onClick={handleStudentDelete}
        size="large"
      >
        {t('Course/Teacher/Students/StudentDelete/confirm-delete-student')}
      </Button>
    </div>
  </Modal>
);

StudentDelete.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleStudentDelete: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  status: PropTypes.string,
  t: PropTypes.func.isRequired,
};

StudentDelete.defaultProps = {
  status: undefined,
};

export default StudentDelete;
