import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import InputQuestionTree from './InputQuestionTree';

const InputQuestionTreeContainer = ({
  course,
  questionTypes,
  shouldSelectAll,
  ...props
}) => {
  const { t } = useTranslation();
  const questionIds = useMemo(
    () =>
      []
        .concat(
          course.questions,
          ...course.chapters.map((chapter) => chapter.questions),
        )
        .filter(
          (question) => !questionTypes || questionTypes.includes(question.type),
        )
        .map((question) => question.id),
    [course, questionTypes],
  );
  const [{ value }, , { setValue }] = useField('questionIds');
  const isSelected = useMemo(
    () => questionIds.every((questionId) => value.includes(questionId)),
    [questionIds, value],
  );
  useEffect(() => {
    if (shouldSelectAll) {
      setValue(questionIds);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldSelectAll]);
  const handleCourseSelect = useCallback(() => {
    if (isSelected) {
      setValue([]);
    } else {
      setValue(questionIds);
    }
  }, [isSelected, questionIds, setValue]);
  return (
    <InputQuestionTree
      course={course}
      handleCourseSelect={handleCourseSelect}
      isSelected={isSelected}
      questionTypes={questionTypes}
      t={t}
      {...props}
    />
  );
};

InputQuestionTreeContainer.propTypes = {
  course: PropTypes.object.isRequired,
  questionTypes: PropTypes.arrayOf(PropTypes.string),
  shouldSelectAll: PropTypes.bool,
};

InputQuestionTreeContainer.defaultProps = {
  questionTypes: undefined,
  shouldSelectAll: false,
};

export default InputQuestionTreeContainer;
