import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'uikit/Button/Button';
import Icon from 'uikit/Icon/Icon';

import ChapterProgress from './ChapterProgress';
import styles from './Chapters.module.scss';

const cx = classNames.bind(styles);

const Chapters = ({
  className,
  chapters,
  course,
  displayedChapters,
  handleSectionToggle,
  handleShowMoreToggle,
  isSectionOpen,
  isStudent,
  showMore,
  t,
}) => (
  <div className={cx('container', className, { isSectionOpen })}>
    <button
      className={cx('header')}
      onClick={handleSectionToggle}
      type="button"
    >
      <div className={cx('title-container')}>
        <Icon className={cx('title-icon')} name="list-ol" />
        <h2 className={cx('title')}>
          {t(
            isStudent
              ? 'Course/Shared/Progress/Chapters/title-student'
              : 'Course/Shared/Progress/Chapters/title',
          )}
        </h2>
        <div className={cx('count')}>{chapters.length}</div>
      </div>
      <Icon
        className={cx('toggle-button')}
        name={isSectionOpen ? 'chevron-up' : 'chevron-down'}
      />
    </button>
    {isSectionOpen && (
      <div className={cx('chapters-container')}>
        <div className={cx('chapters')}>
          {displayedChapters.map((chapter) => (
            <ChapterProgress
              chapter={chapter}
              course={course}
              isStudent={isStudent}
              key={chapter.id}
            />
          ))}
        </div>
        {chapters.length > 5 && (
          <Button
            className={cx('chapters-toggle')}
            onClick={handleShowMoreToggle}
          >
            {t(
              showMore
                ? 'Course/Shared/Progress/Chapters/show-less'
                : 'Course/Shared/Progress/Chapters/show-more',
            )}
            <Icon
              className={cx('chapters-toggle-icon')}
              name={showMore ? 'chevron-up' : 'chevron-down'}
            />
          </Button>
        )}
      </div>
    )}
  </div>
);

Chapters.propTypes = {
  className: PropTypes.string,
  chapters: PropTypes.arrayOf(PropTypes.object).isRequired,
  course: PropTypes.object.isRequired,
  displayedChapters: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleSectionToggle: PropTypes.func.isRequired,
  handleShowMoreToggle: PropTypes.func.isRequired,
  isSectionOpen: PropTypes.bool.isRequired,
  isStudent: PropTypes.bool,
  showMore: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

Chapters.defaultProps = {
  className: undefined,
  isStudent: false,
};

export default Chapters;
