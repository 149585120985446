const sanitizeHTML = require('sanitize-html');

/**
 * Sanitize a string and remove unwanted HTML
 * @param {String} dirty the string to sanitize
 * @returns {String} the sanitized string
 */
function html(dirty) {
  return sanitizeHTML(dirty || '', {
    allowedTags: [
      'div',
      'p',
      'span',
      'math',
      'pre',
      'code',
      'blockquote',
      'br',
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'strong',
      'b',
      'em',
      'i',
      'u',
      'strike',
      'del',
      's',
      'sub',
      'sup',
      'ol',
      'ul',
      'li',
      'hr',
      'figure',
      'img',
      'caption',
      'a',
      'table',
      'thead',
      'tbody',
      'tfoot',
      'tr',
      'th',
      'td',
      'iframe',
      'video',
      'audio',
      'source',
    ],
    selfClosing: ['img', 'video', 'audio', 'br', 'hr'],
    allowedAttributes: {
      '*': ['class', 'style'],
      a: ['href', 'target', 'rel'],
      img: ['src'],
      video: ['src', 'controls'],
      audio: ['src', 'controls', 'controlsList'],
      iframe: ['src', 'width', 'height', 'allowfullscreen', 'frameborder'],
    },
    allowedSchemes: ['http', 'https'],
    allowedSchemesAppliedToAttributes: ['href', 'src'],
    allowProtocolRelative: false,
    allowIframeRelativeUrls: false,
    allowedStyles: {
      '*': {
        font: [/.+/],
        'font-family': [/.+/],
        'font-size': [/.+/],
        'font-stretch': [/.+/],
        'font-style': [/.+/],
        'font-variant': [/.+/],
        'font-weight': [/.+/],
        'letter-spacing': [/.+/],
        'line-height': [/.+/],
        'list-style': [/.+/],
        'text-align': [/.+/],
        'text-decoration': [/.+/],
        'text-emphasis': [/.+/],
        'text-indent': [/.+/],
        'text-transform': [/.+/],
        'vertical-align': [/.+/],
        'white-space': [/.+/],
        'word-break': [/.+/],
        'word-spacing': [/.+/],
        background: [/.+/],
        'background-color': [/.+/],
        color: [/.+/],
        border: [/.+/],
        width: [/.+/],
        height: [/.+/],
        position: ['relative'],
        padding: [/.+/],
        'padding-top': [/.+/],
        'padding-bottom': [/.+/],
        'padding-left': [/.+/],
        'padding-right': [/.+/],
      },
    },
  }).trim();
}

/**
 * Sanitize a string and remove all HTML
 * @param {String} dirty the string to sanitize
 * @returns {String} the sanitized string
 */
function string(dirty) {
  return sanitizeHTML(dirty || '', {
    allowedTags: [],
    selfClosing: [],
    allowedAttributes: {
      '*': [],
    },
    allowedSchemes: [],
    allowedSchemesAppliedToAttributes: ['*'],
    allowProtocolRelative: false,
    allowedIframeHostnames: [],
    allowIframeRelativeUrls: false,
  }).trim();
}

module.exports.html = html;
module.exports.string = string;
