import classNames from 'classnames/bind';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'uikit/Button';

import AudioImporter from './AudioImporter';
import AudioLink from './AudioLink';
import AudioPreview from './AudioPreview';
import AudioRecorder from './AudioRecorder';
import styles from './AudioPicker.module.scss';

const cx = classNames.bind(styles);

const AudioPicker = ({
  audioToImport,
  className,
  disableImporter,
  disableLink,
  disableRecorder,
  handleDeselect,
  name,
  onRecordStart,
  onRecordStop,
  t,
}) => {
  const [{ value: audio }, meta] = useField(name);
  return (
    <div className={className}>
      {!disableLink && !audioToImport && (
        <>
          <p className={cx('label')}>{t('uikit/AudioPicker/link')}</p>
          <AudioLink name={name} />
        </>
      )}
      {audio ? (
        <div className={cx('preview-container')}>
          <AudioPreview audio={audio} />
          <Button
            className={cx('delete')}
            icon="trash-alt"
            intent="white"
            onClick={handleDeselect}
            size="large"
            tooltip={t('uikit/AudioPicker/delete-audio')}
            variant="regular"
          />
        </div>
      ) : (
        <>
          {!audio && (
            <>
              {!disableLink && (
                <p className={cx('or')}>
                  <span>{t('uikit/AudioPicker/or')}</span>
                </p>
              )}
              {!disableImporter && <AudioImporter name={name} />}
              {disableLink && !disableImporter && !disableRecorder && (
                <p className={cx('or', 'small')}>
                  <span>{t('uikit/VideoPicker/or')}</span>
                </p>
              )}
              {!disableRecorder && (
                <AudioRecorder
                  name={name}
                  onRecordStart={onRecordStart}
                  onRecordStop={onRecordStop}
                />
              )}
            </>
          )}
        </>
      )}
      {meta.touched && meta.error && (
        <p className={cx('error-message')} id={`error-${name}`} role="alert">
          {meta.error}
        </p>
      )}
    </div>
  );
};

AudioPicker.propTypes = {
  audioToImport: PropTypes.string.isRequired,
  className: PropTypes.string,
  disableImporter: PropTypes.bool,
  disableLink: PropTypes.bool,
  disableRecorder: PropTypes.bool,
  handleDeselect: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onRecordStart: PropTypes.func.isRequired,
  onRecordStop: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

AudioPicker.defaultProps = {
  className: undefined,
  disableImporter: false,
  disableLink: false,
  disableRecorder: false,
};

export default AudioPicker;
