import i18next from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import apollo from 'gql';
import { mutationAppLanguageUpdate } from 'gql/mutations/app';
import { useQueryLocalApp } from 'gql/queries/local';
import { useQueryMe, QUERY_ME } from 'gql/queries/me';
import history from 'lib/history';

const I18NContainer = () => {
  useEffect(() => {
    if (window.location.search.includes('lang=')) {
      history.replace(window.location.search.replace(/lang=[a-zA-Z]+/g, ''));
    }
  }, []);
  // Change language on authentication
  const {
    data: {
      app: { isAuthenticated },
    },
  } = useQueryLocalApp();
  useEffect(() => {
    (async function asyncEffect() {
      try {
        if (isAuthenticated) {
          const { data } = await apollo.query({
            query: QUERY_ME,
          });
          await mutationAppLanguageUpdate(data.me.language);
        }
      } catch (err) {
        //
      }
    })();
  }, [isAuthenticated]);
  // Change bundle on sector change
  const { i18n } = useTranslation();
  const [sector, setSector] = useState('higher');
  useQueryMe({
    skip: !isAuthenticated,
    onCompleted: ({ me }) => {
      setSector(me.sector);
    },
  });
  useEffect(() => {
    try {
      i18next.addResources(i18n.language, 'translation', {
        'Global/role/student': `$t(Global/role/student-${sector})`,
        'Global/role/students': `$t(Global/role/students-${sector})`,
        'Global/role/Student': `$t(Global/role/Student-${sector})`,
        'Global/role/Students': `$t(Global/role/Students-${sector})`,
        'Global/role/teacher': `$t(Global/role/teacher-${sector})`,
        'Global/role/teachers': `$t(Global/role/teachers-${sector})`,
        'Global/role/Teacher': `$t(Global/role/Teacher-${sector})`,
        'Global/role/Teachers': `$t(Global/role/Teachers-${sector})`,
      });
    } catch (err) {
      //
    }
  }, [sector, i18n.language]);
  return null;
};

export default I18NContainer;
