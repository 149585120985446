import { gql, useQuery } from '@apollo/client';

import { SSO_DATA } from 'gql/fragments';

export const QUERY_SSOS = gql`
  {
    SSOs {
      ...SSOData
    }
  }
  ${SSO_DATA}
`;

export function useQuerySSOs(options) {
  const { loading, data, ...props } = useQuery(QUERY_SSOS, options);
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}
