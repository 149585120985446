const PROPERTIES_TO_SAVE = [
  'type',
  'version',
  'originX',
  'originY',
  'lockScalingY',
  'left',
  'top',
  'width',
  'height',
  'fill',
  'stroke',
  'strokeWidth',
  'strokeDashArray',
  'strokeLineCap',
  'strokeDashOffset',
  'strokeLineJoin',
  'strokeUniform',
  'strokeMiterLimit',
  'scaleX',
  'scaleY',
  'angle',
  'flipX',
  'flipY',
  'opacity',
  'shadow',
  'visible',
  'backgroundColor',
  'fillRule',
  'paintFirst',
  'globalCompositeOperation',
  'skewX',
  'skewY',
  'cropX',
  'cropY',
  'crossOrigin',
  'filters',
  'fontFamily',
  'fontWeight',
  'fontSize',
  'underline',
  'overline',
  'linethrough',
  'textAlign',
  'fontStyle',
  'lineHeight',
  'textBackgroundColor',
  'charSpacing',
  'styles',
  'direction',
  'path',
  'pathStartOffset',
  'pathSide',
  'pathAlign',
  'snapAngle',
  'ry',
  'rx',
  'radius',
];

export default PROPERTIES_TO_SAVE;
