import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { COMMENT_DATA } from 'gql/fragments';

export const COMMENT_CREATE = gql`
  mutation CommentCreate(
    $questionId: ID!
    $studyAnswerId: ID
    $content: String!
    $isClosed: Boolean
    $isRead: Boolean
  ) {
    commentCreate(
      questionId: $questionId
      studyAnswerId: $studyAnswerId
      content: $content
      isClosed: $isClosed
      isRead: $isRead
    ) {
      ...CommentData
    }
  }
  ${COMMENT_DATA}
`;

export function useMutationCommentCreate() {
  const [mutation] = useMutation(COMMENT_CREATE);
  return useCallback(
    (variables) =>
      mutation({
        variables,
        update(cache, { data: { commentCreate } }) {
          const newCommentRef = cache.writeFragment({
            data: commentCreate,
            fragment: COMMENT_DATA,
          });
          cache.modify({
            id: `Question:${commentCreate.questionId}`,
            fields: {
              comments: (existingCommentsRef = []) => [
                ...existingCommentsRef,
                newCommentRef,
              ],
            },
          });
        },
      }),
    [mutation],
  );
}

export const COMMENT_DELETE = gql`
  mutation CommentDelete($commentId: ID!) {
    commentDelete(commentId: $commentId)
  }
`;

export function useMutationCommentDelete() {
  const [mutation] = useMutation(COMMENT_DELETE);
  return useCallback(
    (comment) =>
      mutation({
        variables: {
          commentId: comment.id,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          commentDelete: true,
        },
        update(cache) {
          cache.modify({
            id: `Question:${comment.questionId}`,
            fields: {
              comments: (existingCommentsRef = [], { readField }) => {
                const updatedCommentsRef = existingCommentsRef.filter(
                  (c) => readField('id', c) !== comment.id,
                );
                return updatedCommentsRef;
              },
            },
          });
        },
      }),
    [mutation],
  );
}

export const COMMENT_UPDATE = gql`
  mutation CommentUpdate(
    $commentId: ID!
    $content: String
    $isClosed: Boolean
  ) {
    commentUpdate(
      commentId: $commentId
      content: $content
      isClosed: $isClosed
    ) {
      ...CommentData
    }
  }
  ${COMMENT_DATA}
`;

export function useMutationCommentUpdate() {
  const [mutation] = useMutation(COMMENT_UPDATE);
  return useCallback(
    (comment, variables) =>
      mutation({
        variables: {
          ...variables,
          commentId: comment.id,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          commentUpdate: {
            ...comment,
            ...variables,
            __typename: 'Comment',
          },
        },
      }),
    [mutation],
  );
}

export const COMMENT_READ = gql`
  mutation CommentRead($commentId: ID!) {
    commentRead(commentId: $commentId) {
      ...CommentData
    }
  }
  ${COMMENT_DATA}
`;

export function useMutationCommentRead() {
  const [mutation] = useMutation(COMMENT_READ);
  return useCallback(
    (comment) =>
      mutation({
        variables: {
          commentId: comment.id,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          commentRead: {
            ...comment,
            isRead: true,
            __typename: 'Comment',
          },
        },
      }),
    [mutation],
  );
}

export function useMutationCommentSolve() {
  const [mutation] = useMutation(COMMENT_UPDATE);
  return useCallback(
    (comment) =>
      mutation({
        variables: {
          commentId: comment.id,
          isClosed: true,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          commentUpdate: {
            ...comment,
            isClosed: true,
            __typename: 'Comment',
          },
        },
        update(cache) {
          cache.modify({
            id: `Question:${comment.questionId}`,
            fields: {
              comments: (existingCommentsRef = [], { readField }) => {
                const updatedCommentsRef = existingCommentsRef.filter(
                  (c) => readField('id', c) !== comment.id,
                );
                return updatedCommentsRef;
              },
            },
          });
        },
      }),
    [mutation],
  );
}
