import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Options from 'questions/Shared/Options';
import Tags from 'questions/Shared/Tags';
import InputNumber from 'uikit/InputNumber';
import InputRich from 'uikit/InputRich';
import InputTolerance from 'uikit/InputTolerance';

import styles from './Editor.module.scss';

const cx = classNames.bind(styles);

const Editor = ({ className, maxCorrect, minCorrect, t }) => (
  <div className={cx('container', className)}>
    <div className={cx('numbers')}>
      <InputNumber
        className={cx('field')}
        data-testid="answer"
        label={t('Questions/GuessNumber/Editor/answer-label')}
        name="answer"
      />
      <InputTolerance
        className={cx('field')}
        label={t('Questions/GuessNumber/Editor/tolerance-label')}
        name="toleranceValue"
      />
    </div>
    {(maxCorrect || minCorrect) && (
      <p className={cx('correct-range')}>
        {t('Questions/GuessNumber/Editor/correct-range', {
          max: maxCorrect,
          min: minCorrect,
        })}
      </p>
    )}
    <InputRich
      label={t('Questions/GuessNumber/Editor/feedback-label')}
      name="feedback"
    />
    <Options className={cx('options')}>
      <Tags />
    </Options>
  </div>
);

Editor.propTypes = {
  className: PropTypes.string,
  maxCorrect: PropTypes.number,
  minCorrect: PropTypes.number,
  t: PropTypes.func.isRequired,
};

Editor.defaultProps = {
  className: undefined,
  maxCorrect: undefined,
  minCorrect: undefined,
};

export default Editor;
