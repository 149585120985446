import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'uikit/Button';
import FormattedText from 'uikit/FormattedText';

import styles from './Verso.module.scss';

const cx = classNames.bind(styles);

const Verso = ({
  className,
  handleSpeech,
  handleQuestionReveal,
  question,
  t,
}) => (
  <div className={cx('container', className)}>
    <div className={cx('front-container')}>
      <div className={cx('actions-container')}>
        <Button
          aria-label={t('Questions/Flashcard/Verso/see-recto')}
          className={cx('un-reveal')}
          icon="rotate-right"
          onClick={handleQuestionReveal}
        />
        {'speechSynthesis' in window &&
          (question.content.useSpeech !== undefined
            ? question.content.useSpeech
            : true) && (
            <Button
              aria-label={t('Questions/Flashcard/Verso/speech')}
              className={cx('speech')}
              icon="volume"
              onClick={() => handleSpeech('feedback')}
            />
          )}
      </div>
      <div
        className={cx('front-content', {
          isBigContent: question.content.inverted
            ? question.title.length < 150
            : question.content.feedback.length < 150,
        })}
      >
        <FormattedText
          as="h1"
          className={cx('front-text', {
            isBigText: question.content.inverted
              ? question.title.length < 150
              : question.content.feedback.length < 150,
          })}
        >
          {question.content.inverted
            ? question.title
            : question.content.feedback}
        </FormattedText>
      </div>
    </div>
  </div>
);

Verso.propTypes = {
  className: PropTypes.string,
  handleSpeech: PropTypes.func.isRequired,
  handleQuestionReveal: PropTypes.func.isRequired,
  question: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

Verso.defaultProps = {
  className: undefined,
};

export default Verso;
