import { pages as msTeamsPages } from '@microsoft/teams-js';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import MSTeamsNavigation from './MSTeamsNavigation';

const MSTeamsNavigationContainer = (props) => {
  const [courseId, setCourseId] = useState(undefined);
  const { pathname } = useLocation();
  useEffect(() => {
    (async function navigate() {
      const config = await msTeamsPages.getConfig();
      setCourseId(config.entityId);
    })();
  }, []);
  return (
    <MSTeamsNavigation
      courseId={courseId}
      shouldDisplayBack={!pathname.startsWith('/course/')}
      {...props}
    />
  );
};

export default MSTeamsNavigationContainer;
