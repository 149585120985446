import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import GroupCard from './GroupCard';

const GroupCardContainer = (props) => {
  const { t } = useTranslation();
  const [isExpanded, setExpanded] = useState(false);
  const handleGroupExpandedToggle = useCallback((evt) => {
    evt.stopPropagation();
    setExpanded((state) => !state);
  }, []);
  return (
    <GroupCard
      handleGroupExpandedToggle={handleGroupExpandedToggle}
      isExpanded={isExpanded}
      t={t}
      {...props}
    />
  );
};

export default GroupCardContainer;
