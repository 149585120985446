import Tippy from '@tippyjs/react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import stylesCSS from './Menu.module.scss';

const cx = classNames.bind(stylesCSS);

const Menu = ({
  children,
  className,
  isHideOnClickDeactivated,
  placement,
  toggle,
  trigger,
}) => (
  <div className={cx('container', className)}>
    <Tippy
      content={<div className={cx('menu')}>{children}</div>}
      duration={0}
      interactive
      maxWidth="none"
      placement={placement}
      onTrigger={(instance, evt) => {
        evt.stopPropagation();
        evt.preventDefault();
      }}
      onUntrigger={(instance, evt) => {
        evt.stopPropagation();
        evt.preventDefault();
      }}
      plugins={[
        {
          name: 'hideOnClick',
          defaultValue: true,
          fn(instance) {
            return {
              onCreate() {
                instance.popper.addEventListener('click', () => {
                  if (!isHideOnClickDeactivated) {
                    instance.hide();
                  }
                });
              },
            };
          },
        },
        {
          name: 'hideOnPopperBlur',
          defaultValue: true,
          fn(instance) {
            return {
              onCreate() {
                instance.popper.addEventListener('focusout', (event) => {
                  if (
                    instance.props.hideOnPopperBlur &&
                    event.relatedTarget &&
                    !instance.popper.contains(event.relatedTarget)
                  ) {
                    instance.hide();
                  }
                });
              },
            };
          },
        },
        {
          name: 'hideOnEsc',
          defaultValue: true,
          fn({ hide }) {
            function onKeyDown(event) {
              if (event.keyCode === 27) {
                hide();
              }
            }
            return {
              onShow() {
                document.addEventListener('keydown', onKeyDown);
              },
              onHide() {
                document.removeEventListener('keydown', onKeyDown);
              },
            };
          },
        },
      ]}
      role="menu"
      trigger={trigger}
    >
      {toggle}
    </Tippy>
  </div>
);

Menu.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  isHideOnClickDeactivated: PropTypes.bool,
  placement: PropTypes.string,
  toggle: PropTypes.node.isRequired,
  trigger: PropTypes.string,
};

Menu.defaultProps = {
  className: undefined,
  isHideOnClickDeactivated: false,
  placement: 'bottom',
  trigger: 'click',
};

export default Menu;
