import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { FILE_DATA } from 'gql/fragments';

export const FILE_CREATE = gql`
  mutation FileCreate(
    $courseId: ID!
    $chapterId: ID
    $name: String!
    $link: String!
  ) {
    fileCreate(
      courseId: $courseId
      chapterId: $chapterId
      name: $name
      link: $link
    ) {
      ...FileData
    }
  }
  ${FILE_DATA}
`;

export function useMutationFileCreate() {
  const [mutation] = useMutation(FILE_CREATE);
  return useCallback(
    (variables) =>
      mutation({
        variables,
        update(cache, { data: { fileCreate } }) {
          const newFileRef = cache.writeFragment({
            data: fileCreate,
            fragment: FILE_DATA,
          });
          cache.modify({
            id: `Course:${fileCreate.courseId}`,
            fields: {
              files: (existingFilesRef = []) => [
                ...existingFilesRef,
                newFileRef,
              ],
            },
          });
        },
      }),
    [mutation],
  );
}

export const FILE_DELETE = gql`
  mutation FileDelete($fileId: ID!) {
    fileDelete(fileId: $fileId)
  }
`;

export function useMutationFileDelete() {
  const [mutation] = useMutation(FILE_DELETE);
  return useCallback(
    (file) =>
      mutation({
        variables: {
          fileId: file.id,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          fileDelete: true,
        },
        update(cache) {
          cache.modify({
            id: `Course:${file.courseId}`,
            fields: {
              files: (existingFilesRef = [], { readField }) => {
                const updatedFilesRef = existingFilesRef.filter(
                  (q) => readField('id', q) !== file.id,
                );
                return updatedFilesRef;
              },
            },
          });
        },
      }),
    [mutation],
  );
}
