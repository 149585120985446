import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import Context from 'lib/context';

import Recto from './Recto';

const RectoContainer = (props) => {
  const { t } = useTranslation();
  const { theme } = useContext(Context);
  return <Recto t={t} theme={theme} {...props} />;
};

RectoContainer.defaultAnswer = () => ({
  x: -1,
  y: -1,
});

RectoContainer.validationSchema = (t) =>
  yup.object().shape({
    answer: yup.object().shape({
      x: yup
        .number()
        .required(t('Questions/FindOnImage/Recto/answer-error'))
        .min(0, t('Questions/FindOnImage/Recto/answer-error')),
      y: yup
        .number()
        .required(t('Questions/FindOnImage/Recto/answer-error'))
        .min(0, t('Questions/FindOnImage/Recto/answer-error')),
    }),
  });

export default RectoContainer;
