import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import sanitize from 'lib/sanitize';
import FormattedText from 'uikit/FormattedText';
import Icon from 'uikit/Icon';

import styles from './Recto.module.scss';

const cx = classNames.bind(styles);

const Recto = ({
  answer,
  className,
  handleChoiceToggle,
  isDisabled,
  question,
  t,
  theme,
}) => (
  <div className={cx('container', className, `theme-${theme}`)}>
    <FormattedText as="h1" className={cx('title')}>
      {question.title}
    </FormattedText>
    {!isDisabled && (
      <div className={cx('instructions')}>
        {t(
          question.content.multipleChoices
            ? 'Questions/MCQ/Recto/mcq-instructions'
            : 'Questions/MCQ/Recto/scq-instructions',
        )}
      </div>
    )}
    {question.content.choices.map((choice) => {
      const selection = answer.find((a) => a.idx === choice.idx);
      const icon = question.content.multipleChoices ? 'square' : 'circle';
      const choiceTitle = sanitize.string(choice.title);
      return (
        // eslint-disable-next-line
        <button
          aria-pressed={selection?.answer}
          className={cx('choice', {
            isDisabled,
            isSelected: selection?.answer,
          })}
          data-testid={`mcq-choice-${choiceTitle}`}
          key={choice.idx}
          onClick={() => handleChoiceToggle(choice.idx)}
          type="button"
        >
          <Icon
            className={cx('icon')}
            name={selection?.answer ? `check-${icon}` : icon}
            variant="solid"
          />
          <FormattedText className={cx('choice-title')}>
            {choice.title}
          </FormattedText>
        </button>
      );
    })}
  </div>
);

Recto.propTypes = {
  answer: PropTypes.arrayOf(PropTypes.object).isRequired,
  className: PropTypes.string,
  handleChoiceToggle: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  question: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
};

Recto.defaultProps = {
  className: undefined,
};

export default Recto;
