import classNames from 'classnames/bind';
import React from 'react';
import { useTranslation } from 'react-i18next';

import imageGames from './images/games.svg';
import styles from './Empty.module.scss';

const cx = classNames.bind(styles);

const Empty = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/Games/Empty',
  });

  return (
    <div className={cx('container')}>
      <img alt="" aria-hidden="true" className={cx('image')} src={imageGames} />
      <p>{t('no-games')}</p>
    </div>
  );
};

export default Empty;
