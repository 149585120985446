import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import Context from 'lib/context';

import Recto from './Recto';

const RectoContainer = ({ isDisabled, question, ...props }) => {
  const { t } = useTranslation();
  const { theme } = useContext(Context);
  const [{ value: answer }, , { setValue: setAnswer }] = useField('answer');
  const handleChoiceToggle = useCallback(
    (idx) => {
      if (!isDisabled) {
        setAnswer(
          answer.map((a) => ({
            idx: a.idx,
            answer:
              a.idx === idx
                ? !a.answer
                : question.content.multipleChoices
                ? a.answer
                : false,
          })),
        );
      }
    },
    [answer, isDisabled, question, setAnswer],
  );
  return (
    <Recto
      answer={answer}
      handleChoiceToggle={handleChoiceToggle}
      isDisabled={isDisabled}
      question={question}
      t={t}
      theme={theme}
      {...props}
    />
  );
};

RectoContainer.propTypes = {
  isDisabled: PropTypes.bool,
  question: PropTypes.object.isRequired,
};

RectoContainer.defaultProps = {
  isDisabled: false,
};

RectoContainer.defaultAnswer = (question) =>
  question.content.choices.map((choice) => ({
    idx: choice.idx,
    answer: false,
  }));

RectoContainer.validationSchema = (t) =>
  yup.object().shape({
    answer: yup
      .array()
      .of(
        yup.object().shape({
          idx: yup.number().required().integer().min(0),
          answer: yup.boolean().required(),
        }),
      )
      .compact()
      .test(
        'answer',
        t('Questions/MCQ/Recto/choices-error-answer'),
        (choices) => choices.filter((choice) => choice.answer).length >= 1,
      ),
  });

export default RectoContainer;
