import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Icon from 'uikit/Icon';
import HelpTooltip from 'uikit/HelpTooltip';

import styles from './ChapterProgress.module.scss';

const cx = classNames.bind(styles);

const ChapterProgress = ({ className, chapter, t }) => (
  <div className={cx('container', className)}>
    <h2 className={cx('title')}>{chapter.title}</h2>
    <div
      aria-label={t(
        'Course/Shared/Progress/Chapters/ChapterProgress/aria-success',
        {
          success: chapter.success,
        },
      )}
      className={cx('line')}
    >
      <Icon className={cx('indicator', 'success')} name="trophy" />
      <p className={cx('name')}>
        {t('Course/Shared/Progress/Chapters/ChapterProgress/success-name')}
        <HelpTooltip
          className={cx('tooltip')}
          tooltip={t(
            'Course/Shared/Progress/Chapters/ChapterProgress/success-tooltip',
          )}
        />
      </p>
      <div className={cx('gauge', 'success')}>
        <div
          style={{
            width: `${chapter.success}%`,
          }}
        />
      </div>
      <p className={cx('percentage')}>{`${chapter.success}%`}</p>
    </div>
    <div
      aria-label={t(
        'Course/Shared/Progress/Chapters/ChapterProgress/aria-progression',
        {
          progression: chapter.progression,
        },
      )}
      className={cx('line')}
    >
      <Icon className={cx('indicator', 'progression')} name="rocket" />
      <p className={cx('name')}>
        {t('Course/Shared/Progress/Chapters/ChapterProgress/progression-name')}
        <HelpTooltip
          className={cx('tooltip')}
          tooltip={t(
            'Course/Shared/Progress/Chapters/ChapterProgress/progression-tooltip',
          )}
        />
      </p>
      <div className={cx('gauge', 'progression')}>
        <div
          style={{
            width: `${chapter.progression}%`,
          }}
        />
      </div>
      <p className={cx('percentage')}>{`${chapter.progression}%`}</p>
    </div>
    <div
      aria-label={t(
        'Course/Shared/Progress/Chapters/ChapterProgress/aria-mastery',
        {
          mastery: chapter.mastery,
        },
      )}
      className={cx('line')}
    >
      <Icon className={cx('indicator', 'mastery')} name="dumbbell" />
      <p className={cx('name')}>
        {t('Course/Shared/Progress/Chapters/ChapterProgress/mastery-name')}
        <HelpTooltip
          className={cx('tooltip')}
          tooltip={t(
            'Course/Shared/Progress/Chapters/ChapterProgress/mastery-tooltip',
          )}
        />
      </p>
      <div className={cx('gauge', 'mastery')}>
        <div
          style={{
            width: `${chapter.mastery}%`,
          }}
        />
      </div>
      <p className={cx('percentage')}>{`${chapter.mastery}%`}</p>
    </div>
  </div>
);

ChapterProgress.propTypes = {
  className: PropTypes.string,
  chapter: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

ChapterProgress.defaultProps = {
  className: undefined,
};

export default ChapterProgress;
