import classNames from 'classnames/bind';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'uikit/Button';
import Icon from 'uikit/Icon';
import InputText from 'uikit/InputText';

import UnInvitedWarning from '../UnInvitedWarning';
import styles from './Email.module.scss';

const cx = classNames.bind(styles);

const Email = ({ className, handleStudentsInvite, t, unInvited }) => (
  <div className={cx(className)}>
    <div className={cx('container')}>
      <div className={cx('icon')}>
        <Icon name="envelope" />
      </div>
      <div className={cx('grow')}>
        <Formik
          initialValues={{
            usernames: '',
          }}
          onSubmit={handleStudentsInvite}
        >
          {({ isSubmitting }) => (
            <Form className={cx('container')}>
              <InputText
                className={cx('field')}
                id="input-email"
                label={t('Course/Shared/Email/email')}
                name="usernames"
                placeholder={t('Course/Shared/Email/emails-placeholder')}
                type="textarea"
              />
              <Button
                aria-label={t('Course/Shared/Email/aria-invite')}
                isLoading={isSubmitting}
                type="submit"
              >
                {t('Course/Shared/Email/invite')}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
    <UnInvitedWarning unInvited={unInvited} />
  </div>
);

Email.propTypes = {
  className: PropTypes.string,
  handleStudentsInvite: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  unInvited: PropTypes.arrayOf(PropTypes.string).isRequired,
};

Email.defaultProps = {
  className: undefined,
};

export default Email;
