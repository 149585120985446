import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { useQueryLocalApp } from 'gql/queries/local';

import MenuElement from './MenuElement';

const MenuElementContainer = ({ target, ...props }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const isActive = pathname === target;
  const {
    data: {
      app: { isMenuExpanded },
    },
  } = useQueryLocalApp();
  return (
    <MenuElement
      {...props}
      isActive={isActive}
      isMenuExpanded={isMenuExpanded}
      target={target}
      t={t}
    />
  );
};

MenuElementContainer.propTypes = {
  target: PropTypes.string.isRequired,
};

export default MenuElementContainer;
