import classNames from 'classnames/bind';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';

import styles from './LightBox.module.scss';

const cx = classNames.bind(styles);

const LightBox = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'App/LightBox',
  });

  const [src, setSrc] = useState(undefined);

  const handleClose = () => {
    setSrc(undefined);
  };

  useEffect(() => {
    const handleOpen = (e) => {
      setSrc(e.detail.src);
    };

    window.addEventListener('lightbox', handleOpen);
    return () => {
      window.removeEventListener('lightbox', handleOpen);
    };
  }, []);

  return (
    <CSSTransition
      classNames={{
        enter: cx('container-enter'),
        enterActive: cx('container-enter-active'),
        exit: cx('container-exit'),
        exitActive: cx('container-exit-active'),
      }}
      in={!!src}
      mountOnEnter
      timeout={250}
      unmountOnExit
    >
      <div
        aria-label={t('aria-close')}
        className={cx('container')}
        onClick={handleClose}
        role="button"
      >
        <img alt="No description" className={cx('image')} src={src} />
      </div>
    </CSSTransition>
  );
};

export default LightBox;
