import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { Droppable } from 'react-beautiful-dnd';

import Students from '../Students';
import styles from './Standalone.module.scss';

const cx = classNames.bind(styles);

const Standalone = ({ className, course }) => (
  <div className={className}>
    <Droppable droppableId="course" type="student">
      {(providedStudents, snapshotStudents) => (
        <div
          className={cx('droppable', {
            isDraggingOver: snapshotStudents.isDraggingOver,
          })}
          ref={providedStudents.innerRef}
          {...providedStudents.droppableProps}
        >
          <Students course={course} groupId={null} />
          {providedStudents.placeholder}
        </div>
      )}
    </Droppable>
  </div>
);

Standalone.propTypes = {
  className: PropTypes.string,
  course: PropTypes.object.isRequired,
};

Standalone.defaultProps = {
  className: undefined,
};

export default Standalone;
