import React, { useContext } from 'react';

import Context from 'lib/context';

import Verso from './Verso';

const VersoContainer = (props) => {
  const { theme } = useContext(Context);
  return <Verso theme={theme} {...props} />;
};

VersoContainer.defaultAnswer = () => ({
  x: -1,
  y: -1,
});

VersoContainer.defaultSolution = (question) => ({
  userX: null,
  userY: null,
  zones: question.content.zones,
});

export default VersoContainer;
