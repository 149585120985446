import classNames from 'classnames/bind';
import { Formik, Form } from 'formik';
import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import storeJS from 'store';
import * as yup from 'yup';

import Container from 'components/Auth/Shared/Container/Container';
import ForceSSO from 'components/Auth/Shared/ForceSSO';
import Social from 'components/Auth/Shared/Social/Social';
import SSO from 'components/Auth/Shared/SSO/SSO';
import GENERIC_EMAILS from 'constants/genericEmails';
import { useMutationAuthRegister } from 'gql/mutations/auth';
import extract from 'helpers/extract';
import { trackEvent, reportError } from 'lib/tracking';
import Button from 'uikit/Button';
import CheckBox from 'uikit/CheckBox';
import FormattedText from 'uikit/FormattedText';
import Icon from 'uikit/Icon';
import InputText from 'uikit/InputText';

import styles from './Register.module.scss';

const cx = classNames.bind(styles);

const Register = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'Auth/Register',
  });

  const { state } = useLocation();

  const defaultUsername = state?.username || '';

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        username: yup
          .string()
          .trim()
          .lowercase()
          .required(t('username-error-required'))
          .max(256, t('username-error-required')),
        password: yup
          .string()
          .trim()
          .required(t('password-error-required'))
          .max(256, t('password-error-required')),
        termsAndConditions: yup
          .boolean()
          .required(t('terms-and-conditions-error-required'))
          .oneOf([true], t('terms-and-conditions-error-required')),
      }),
    [t],
  );

  const mutationAuthRegister = useMutationAuthRegister(
    storeJS.get('redirectTo') || '/',
  );

  const handleRegister = async (values, form) => {
    try {
      form.setStatus(null);
      const variables = extract(values, validationSchema);
      await mutationAuthRegister(variables);
      trackEvent('auth', 'register');
    } catch (err) {
      form.setStatus(err.message);
      reportError('auth', 'register', err);
    }
  };

  return (
    <Container
      action={
        <Button as={Link} to="/auth/login">
          {t('login')}
        </Button>
      }
    >
      <Formik
        initialValues={{
          username: defaultUsername,
          password: '',
          termsAndConditions: false,
          organization: null,
        }}
        onSubmit={handleRegister}
        validateOnBlur={false}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, status, values }) => (
          <Form className={cx('container')}>
            <div className={cx('section')}>
              <h1 className={cx('title')}>{t('title')}</h1>
              <p className={cx('power-subtitle')}>{t('power-subtitle')}</p>
              <div className={cx('subtitle-container')}>
                <p className={cx('subtitle')}>{t('subtitle')}</p>
                <Link
                  to={{
                    pathname: '/auth/login',
                    state: {
                      email: values.username,
                    },
                  }}
                >
                  <p className={cx('link')}>{t('login')}</p>
                </Link>
              </div>
              {status === '409' ? (
                <p className={cx('error')} role="alert">
                  <Link
                    to={{
                      pathname: '/auth/login',
                      state: {
                        username: values.username,
                      },
                    }}
                  >
                    {t('register-error-username-in-use')}
                  </Link>
                </p>
              ) : (
                status && (
                  <p className={cx('error')} role="alert">
                    {t('register-error')}
                  </p>
                )
              )}
              <InputText
                className={cx('field')}
                data-testid="username"
                icon="at"
                name="username"
                placeholder={t('username-label')}
              />
              {GENERIC_EMAILS.some((email) =>
                values.username.includes(email),
              ) && (
                <div className={cx('information')}>
                  <Icon className={cx('information-icon')} name="info-circle" />
                  <FormattedText className={cx('information-text')}>
                    {t('information')}
                  </FormattedText>
                </div>
              )}
              {(!values.organization ||
                (values.organization && !values.organization.forceSSO)) && (
                <>
                  <InputText
                    className={cx('field')}
                    data-testid="password"
                    icon="lock"
                    placeholder={t('password-label')}
                    name="password"
                    type="password"
                  />
                  <CheckBox
                    className={cx('field')}
                    data-testid="terms-and-conditions-accept"
                    name="termsAndConditions"
                  >
                    <Trans i18nKey="terms-and-conditions-label" t={t}>
                      terms-and-conditions-label
                      <a href="/terms-and-conditions" target="blank">
                        terms-and-conditions-label
                      </a>
                      terms-and-conditions-label
                      <a href="/privacy-policy" target="blank">
                        terms-and-conditions-label
                      </a>
                      terms-and-conditions-label
                    </Trans>
                  </CheckBox>
                  {!!values.username &&
                    !!values.password &&
                    values.termsAndConditions && (
                      <Button
                        className={cx('action')}
                        data-testid="register-button"
                        intent="primary"
                        isLoading={isSubmitting}
                        size="large"
                        type="submit"
                      >
                        {t('register')}
                      </Button>
                    )}
                </>
              )}
              <Social />
            </div>
            <SSO />
            <ForceSSO />
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default Register;
