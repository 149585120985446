import Tippy from '@tippyjs/react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import history from 'lib/history';
import Icon from 'uikit/Icon';

import FutureCourse from '../FutureCourse/FutureCourse';
import PastCourse from '../PastCourse/PastCourse';
import styles from './CourseItem.module.scss';

const cx = classNames.bind(styles);

const CourseItem = ({ course, day, isSingle = false }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Shared/Agenda/CourseItem',
  });

  const handleCourseStudy = () => {
    if (day.isToday && course.nQuestionsToStudy > 0 && !course.isPast) {
      history.push(`/study/${course.id}?method=wooflash`);
    }
  };

  return (
    <Tippy
      content={
        <div className={cx('content')}>
          {!course.isPast ? (
            <FutureCourse course={course} isSingle={isSingle} />
          ) : (
            <PastCourse course={course} isSingle={isSingle} />
          )}
        </div>
      }
      duration={0}
      placement="right-start"
    >
      <button
        className={cx('course', `theme-${course.themeBackground}`, {
          disabled:
            course.isPast || !day.isToday || course.nQuestionsToStudy === 0,
          'to-study':
            !course.isPast && day.isToday && course.nQuestionsToStudy > 0,
        })}
        onClick={handleCourseStudy}
        type="button"
      >
        <div className={cx('course-header')}>
          <div className={cx('course-icon')}>
            <Icon name="book" />
          </div>
          {course.nQuestionsToStudy === 0 && (
            <div className={cx('icon-check')}>
              <Icon name="check" />
            </div>
          )}
        </div>
        <p className={cx('course-text')}>{course.title}</p>
        <p className={cx('stat')}>
          {!course.isPast ? (
            <>
              {course.nQuestionsToStudy > 0 && (
                <span>{course.nQuestionsToStudy}</span>
              )}
              {t(
                course.nQuestionsToStudy > 0 ? 'stats-to-study' : 'study-done',
                {
                  count: course.nQuestionsToStudy,
                },
              )}
            </>
          ) : (
            <>
              <span>{course.seen}</span>
              {t('stats-seen', {
                count: course.seen,
              })}
            </>
          )}
        </p>
      </button>
    </Tippy>
  );
};

CourseItem.propTypes = {
  course: PropTypes.object.isRequired,
  day: PropTypes.object.isRequired,
  isSingle: PropTypes.bool,
};

export default CourseItem;
