import classNames from 'classnames/bind';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { mutationEditorQuestionCreate } from 'gql/mutations/editor';
import Button from 'uikit/Button';

import imageCelebration from './images/celebration.svg';
import styles from './Empty.module.scss';

const cx = classNames.bind(styles);

const Empty = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Teacher/Editor/Empty',
  });

  return (
    <div className={cx('container')}>
      <img
        alt=""
        aria-hidden="true"
        className={cx('image')}
        src={imageCelebration}
      />
      <h3 className={cx('title')}>{t('title')}</h3>
      <Button
        intent="primary"
        onClick={mutationEditorQuestionCreate}
        size="large"
      >
        {t('create-question')}
      </Button>
    </div>
  );
};

export default Empty;
