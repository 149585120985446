import { useField } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  getDimensions,
  getDenormalizedZonePosition,
  getNormalizedZonePosition,
  getNormalizedPinPosition,
  getEventCursorPosition,
} from 'questions/Shared/utils/images';
import ImageContainer from './ImageContainer';

const ZONE_MIN_SIZE = 25;

const ImageContainerContainer = (props) => {
  const wrapperRef = useRef(null);
  const imageRef = useRef(null);
  const [image, setImage] = useState({
    width: null,
    height: null,
    diagonal: null,
  });
  const handleImageLoaded = useCallback(() => {
    const [width, height, diagonal] = getDimensions(wrapperRef, imageRef);
    setImage({
      width,
      height,
      diagonal,
    });
  }, []);
  const handleScreenResize = useCallback(() => {
    if (wrapperRef && imageRef) {
      handleImageLoaded();
    }
  }, [handleImageLoaded]);
  const [{ value: zones }, , { setValue: setZones }] = useField('zones');
  const [activeZoneIndex, setActiveZoneIndex] = useState(null);
  const [data, setData] = useState({
    mouse: {
      positionX: null,
      positionY: null,
      fingerId: null,
    },
    draggingMode: null,
    updatedZoneIndex: null,
    updatedZone: null,
    basePosition: {
      baseLeft: null,
      baseTop: null,
      baseHeight: null,
      baseWidth: null,
    },
  });
  const handleZoneDelete = useCallback(
    (index) => {
      const filteredZones = zones.filter(
        (item, itemIndex) => itemIndex !== index,
      );
      setZones(filteredZones);
      setActiveZoneIndex(null);
      setData({
        mouse: {
          positionX: null,
          positionY: null,
          fingerId: null,
        },
        draggingMode: null,
        updatedZoneIndex: null,
        updatedZone: null,
        basePosition: {
          baseLeft: null,
          baseTop: null,
          baseHeight: null,
          baseWidth: null,
        },
      });
    },
    [setZones, zones],
  );
  const handleZoneUpdate = useCallback(
    (index, updatedData) => {
      const normalizedData = getNormalizedZonePosition(
        updatedData,
        image.diagonal,
        zones[index].shapeType,
      );
      const updatedZones = [...zones];
      updatedZones[index] = {
        ...updatedZones[index],
        ...normalizedData,
      };
      setZones(updatedZones);
    },
    [image, setZones, zones],
  );
  const handleMouseDown = useCallback(
    (event, index, dragMode) => {
      event.stopPropagation();
      const [positionX, positionY, fingerId] = getEventCursorPosition(event);
      const zonePosition =
        dragMode === 'create'
          ? imageRef.current.getBoundingClientRect()
          : getDenormalizedZonePosition(zones[index], image.diagonal);
      setActiveZoneIndex(index);
      setData({
        mouse: {
          positionX,
          positionY,
          fingerId,
        },
        draggingMode: dragMode,
        updatedZoneIndex: index,
        updatedZone: dragMode === 'create' ? null : zones[index],
        basePosition: {
          baseLeft: zonePosition.left,
          baseTop: zonePosition.top,
          baseHeight: zonePosition.height,
          baseWidth: zonePosition.width,
        },
      });
    },
    [image, zones],
  );
  const handleMouseUp = useCallback(() => {
    setData({
      mouse: {
        positionX: null,
        positionY: null,
        fingerId: null,
      },
      draggingMode: null,
      updatedZoneIndex: null,
      updatedZone: null,
      basePosition: {
        baseLeft: null,
        baseTop: null,
        baseHeight: null,
        baseWidth: null,
      },
    });
  }, []);
  const handleMouseMove = useCallback(
    (event) => {
      const {
        mouse: { positionX, positionY, fingerId },
        draggingMode,
        updatedZoneIndex,
        updatedZone,
        basePosition,
      } = data;
      const [clientX, clientY] = getEventCursorPosition(event, fingerId);
      if (draggingMode === 'create') {
        const distance = Math.sqrt(
          (clientX - positionX) ** 2 + (clientY - positionY) ** 2,
        );
        if (distance < 10) {
          return;
        }
        const startPosition = getNormalizedPinPosition(
          {
            left: positionX - basePosition.baseLeft,
            top: positionY - basePosition.baseTop,
          },
          image.diagonal,
        );
        const endPosition = getNormalizedPinPosition(
          {
            left: clientX - basePosition.baseLeft,
            top: clientY - basePosition.baseTop,
          },
          image.diagonal,
        );
        const newZone = {
          h: Math.abs(endPosition.y - startPosition.y),
          r: null,
          w: Math.abs(endPosition.x - startPosition.x),
          x: Math.min(startPosition.x, endPosition.x),
          y: Math.min(startPosition.y, endPosition.y),
          shapeType: 'rectangle',
        };
        if (newZone.h < 0.01 || newZone.w < 0.01) {
          return;
        }
        const zonePosition = getDenormalizedZonePosition(
          newZone,
          image.diagonal,
        );
        setActiveZoneIndex(zones.length);
        setData((d) => ({
          ...d,
          draggingMode: 'resize',
          updatedZoneIndex: zones.length,
          updatedZone: newZone,
          basePosition: {
            baseLeft: zonePosition.left,
            baseTop: zonePosition.top,
            baseHeight: zonePosition.height,
            baseWidth: zonePosition.width,
          },
        }));
        setZones([...zones, newZone]);
        return;
      }
      if (!draggingMode || !updatedZone) {
        return;
      }
      const zonePosition = getDenormalizedZonePosition(
        updatedZone,
        image.diagonal,
      );
      const offsetX = clientX - positionX;
      const offsetY = positionY - clientY;
      const zoneWidth = basePosition.baseWidth || 0;
      const zoneHeight = basePosition.baseHeight || 0;
      const maxWidth = wrapperRef.current.offsetWidth;
      const maxHeight = wrapperRef.current.offsetHeight;
      if (draggingMode === 'position') {
        let newLeftPosition = basePosition.baseLeft + offsetX;
        let newTopPosition = basePosition.baseTop - offsetY;
        if (newLeftPosition <= 0 - zoneWidth / 2) {
          newLeftPosition = 0 - zoneWidth / 2;
        } else if (newLeftPosition >= maxWidth - zoneWidth / 2) {
          newLeftPosition = maxWidth - zoneWidth / 2;
        }
        if (newTopPosition <= 0 - zoneHeight / 2) {
          newTopPosition = 0 - zoneHeight / 2;
        } else if (newTopPosition >= maxHeight - zoneHeight / 2) {
          newTopPosition = maxHeight - zoneHeight / 2;
        }
        handleZoneUpdate(updatedZoneIndex, {
          width: zonePosition.width,
          height: zonePosition.height,
          left: newLeftPosition,
          top: newTopPosition,
        });
      } else if (draggingMode === 'resize') {
        const dimensions = {
          left: zonePosition.left,
          top: zonePosition.top,
        };
        let newWidth;
        let newHeight;
        if (updatedZone.shapeType === 'circle') {
          newWidth = basePosition.baseWidth - offsetY * 2;
          newHeight = newWidth;
          if (newWidth < ZONE_MIN_SIZE) {
            newWidth = ZONE_MIN_SIZE;
            newHeight = ZONE_MIN_SIZE;
            dimensions.left =
              zonePosition.left + (zoneWidth - ZONE_MIN_SIZE) / 2;
            dimensions.top = zonePosition.top + (zoneWidth - ZONE_MIN_SIZE) / 2;
          } else {
            dimensions.left = zonePosition.left + offsetY;
            dimensions.top = zonePosition.top + offsetY;
          }
        } else {
          newWidth = basePosition.baseWidth + offsetX;
          newHeight = basePosition.baseHeight - offsetY;
          if (newWidth >= maxWidth - zonePosition.left) {
            newWidth = maxWidth - zonePosition.left;
          } else if (newWidth < ZONE_MIN_SIZE) {
            newWidth = ZONE_MIN_SIZE;
          }
          if (newHeight >= maxHeight - zonePosition.top) {
            newHeight = maxHeight - zonePosition.top;
          } else if (newHeight < ZONE_MIN_SIZE) {
            newHeight = ZONE_MIN_SIZE;
          }
        }
        dimensions.width = newWidth;
        dimensions.height = newHeight;
        handleZoneUpdate(updatedZoneIndex, dimensions);
      }
    },
    [data, image, handleZoneUpdate, setZones, zones],
  );
  useEffect(() => {
    window.addEventListener('resize', handleScreenResize);
    return () => {
      window.removeEventListener('resize', handleScreenResize);
    };
  }, [handleScreenResize]);
  useEffect(() => {
    window.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('touchend', handleMouseUp);
    return () => {
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('touchend', handleMouseUp);
    };
  }, [handleMouseUp]);
  return (
    <ImageContainer
      activeZoneIndex={activeZoneIndex}
      handleImageLoaded={handleImageLoaded}
      handleMouseDown={handleMouseDown}
      handleMouseMove={handleMouseMove}
      handleZoneDelete={handleZoneDelete}
      image={image}
      imageRef={imageRef}
      wrapperRef={wrapperRef}
      zones={zones}
      {...props}
    />
  );
};

export default ImageContainerContainer;
