import classNames from 'classnames/bind';
import React from 'react';
import { useTranslation } from 'react-i18next';

import EDITOR_STATUSES from 'constants/editorStatuses';
import { mutationEditorQuestionTypeChange } from 'gql/mutations/editor';
import { useQueryLocalEditor } from 'gql/queries/local';
import { getQuestionsInfo, getQuestionInfo } from 'questions';
import Icon from 'uikit/Icon';

import styles from './TypeSelector.module.scss';

const cx = classNames.bind(styles);

const TypeSelector = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'Course/Shared/QuestionEdit/TypeSelector',
  });

  const { t: tName } = useTranslation('');

  const {
    data: {
      editor: { status: editorStatus, questionType },
    },
  } = useQueryLocalEditor();

  const questionsInfo = getQuestionsInfo();

  const questionInfo = getQuestionInfo(questionType);

  const handleTypeSelect = (event) => {
    mutationEditorQuestionTypeChange(event.target.value);
  };

  return (
    <div className={cx('container')}>
      {editorStatus === EDITOR_STATUSES.QUESTION_CREATE ? (
        <label className={cx('select-type')} htmlFor="type">
          <div className={cx('icon-container')}>
            {questionInfo ? (
              <img alt="" className={cx('icon')} src={questionInfo.icon} />
            ) : (
              <Icon className={cx('icon')} name="question" />
            )}
          </div>
          <select
            aria-label={t('aria-type')}
            id="type"
            name="type"
            onChange={handleTypeSelect}
            value={questionInfo?.type ?? ''}
          >
            <option disabled value="">
              {t('type-placeholder')}
            </option>
            {questionsInfo.all.map((info) => (
              <option key={info.type} value={info.type}>
                {tName(info.name)}
              </option>
            ))}
          </select>
          <div className={cx('arrow')}>
            <Icon name="chevron-down" />
          </div>
        </label>
      ) : (
        <div className={cx('select-type')}>
          <div className={cx('icon-container')}>
            <img alt="" className={cx('icon')} src={questionInfo.icon} />
          </div>
          <div className={cx('select-type-text')}>
            {tName(questionInfo.name)}
          </div>
        </div>
      )}
    </div>
  );
};

export default TypeSelector;
