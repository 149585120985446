// eslint-disable-next-line import/prefer-default-export
export const formatMatches = (matches) =>
  matches.map((match) => {
    if (/^<p>==(\d+)<\/p>$/.test(match.destination)) {
      const [, x] = match.destination.match(/^<p>==(\d+)<\/p>$/);
      const dest = matches.find((m) => m.idx + 1 === +x);
      if (dest) {
        return {
          ...match,
          destination: dest.destination,
        };
      }
    }
    return match;
  });
