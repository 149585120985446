import {
  WIDTH,
  HEIGHT,
  TITLE_HEIGHT,
  TITLE_WIDTH,
  SUBTITLE_HEIGHT,
  SUBTITLE_WIDTH,
  TEXT_HEIGHT,
  TEXT_WIDTH,
} from './constant';

const DEFAULT_CANVAS_PROPERTIES = {
  editorExtraLargeRatio: {
    reducedMenu: 0.45,
    expandedMenu: 0.3,
  },
  editorLargeRatio: {
    reducedMenu: 0.25,
    expandedMenu: 0.45,
  },
  editorMediumRatio: {
    reducedMenu: 0.45,
    expandedMenu: 0.3,
  },
  editorSmallRatio: {
    reducedMenu: 0.45,
    expandedMenu: 0.65,
  },
  editorExtraSmallRatio: {
    reducedMenu: 0.75,
    expandedMenu: 0.75,
  },
  rectoLargeRatio: {
    reducedMenu: 0.55,
    expandedMenu: 0.44,
  },
  rectoMediumRatio: {
    reducedMenu: 0.5,
    expandedMenu: 0.4,
  },
  rectoSmallRatio: {
    reducedMenu: 0.9,
    expandedMenu: 0.9,
  },
  height: HEIGHT,
  width: WIDTH,
  widthHeightRatio: 1.7,
  zoom: 1,
  titleProps: {
    type: 'i-text',
    version: '5.3.0',
    originX: 'left',
    originY: 'top',
    lockScalingY: true,
    left: WIDTH / 2 - TITLE_WIDTH / 2,
    top: HEIGHT / 2 - TITLE_HEIGHT / 2,
    width: TITLE_WIDTH,
    height: TITLE_HEIGHT,
    fill: '#1d254f',
    stroke: null,
    strokeWidth: 1,
    strokeDashArray: null,
    strokeLineCap: 'butt',
    strokeDashOffset: 0,
    strokeLineJoin: 'miter',
    strokeUniform: false,
    strokeMiterLimit: 4,
    scaleX: 1,
    scaleY: 1,
    angle: 0,
    flipX: false,
    flipY: false,
    opacity: 1,
    shadow: null,
    visible: true,
    backgroundColor: '',
    fillRule: 'nonzero',
    paintFirst: 'fill',
    globalCompositeOperation: 'source-over',
    skewX: 0,
    skewY: 0,
    fontFamily: 'Nunito',
    fontWeight: 900,
    fontSize: 40,
    underline: false,
    overline: false,
    linethrough: false,
    textAlign: 'center',
    fontStyle: 'normal',
    lineHeight: 1.16,
    textBackgroundColor: '',
    charSpacing: 0,
    styles: [],
    direction: 'ltr',
    path: null,
    pathStartOffset: 0,
    pathSide: 'left',
    pathAlign: 'baseline',
    snapAngle: 15,
  },
  subtitleProps: {
    type: 'i-text',
    version: '5.3.0',
    originX: 'left',
    originY: 'top',
    lockScalingY: true,
    left: WIDTH / 2 - SUBTITLE_WIDTH / 2,
    top: HEIGHT / 2 - SUBTITLE_HEIGHT / 2,
    width: SUBTITLE_WIDTH,
    height: SUBTITLE_HEIGHT,
    fill: '#1d254f',
    stroke: null,
    strokeWidth: 1,
    strokeDashArray: null,
    strokeLineCap: 'butt',
    strokeDashOffset: 0,
    strokeLineJoin: 'miter',
    strokeUniform: false,
    strokeMiterLimit: 4,
    scaleX: 1,
    scaleY: 1,
    angle: 0,
    flipX: false,
    flipY: false,
    opacity: 1,
    shadow: null,
    visible: true,
    backgroundColor: '',
    fillRule: 'nonzero',
    paintFirst: 'fill',
    globalCompositeOperation: 'source-over',
    skewX: 0,
    skewY: 0,
    fontFamily: 'Nunito',
    fontWeight: 700,
    fontSize: 30,
    underline: false,
    overline: false,
    linethrough: false,
    textAlign: 'center',
    fontStyle: 'normal',
    lineHeight: 1.16,
    textBackgroundColor: '',
    charSpacing: 0,
    styles: [],
    direction: 'ltr',
    path: null,
    pathStartOffset: 0,
    pathSide: 'left',
    pathAlign: 'baseline',
    snapAngle: 15,
  },
  textProps: {
    type: 'i-text',
    version: '5.3.0',
    originX: 'left',
    originY: 'top',
    lockScalingY: true,
    left: WIDTH / 2 - TEXT_WIDTH / 2,
    top: HEIGHT / 2 - TEXT_HEIGHT / 2,
    width: TEXT_WIDTH,
    height: TEXT_HEIGHT,
    fill: '#1d254f',
    stroke: null,
    strokeWidth: 1,
    strokeDashArray: null,
    strokeLineCap: 'butt',
    strokeDashOffset: 0,
    strokeLineJoin: 'miter',
    strokeUniform: false,
    strokeMiterLimit: 4,
    scaleX: 1,
    scaleY: 1,
    angle: 0,
    flipX: false,
    flipY: false,
    opacity: 1,
    shadow: null,
    visible: true,
    backgroundColor: '',
    fillRule: 'nonzero',
    paintFirst: 'fill',
    globalCompositeOperation: 'source-over',
    skewX: 0,
    skewY: 0,
    fontFamily: 'Nunito',
    fontWeight: 400,
    fontSize: 20,
    underline: false,
    overline: false,
    linethrough: false,
    textAlign: 'left',
    fontStyle: 'normal',
    lineHeight: 1.16,
    textBackgroundColor: '',
    charSpacing: 0,
    styles: [],
    direction: 'ltr',
    path: null,
    pathStartOffset: 0,
    pathSide: 'left',
    pathAlign: 'baseline',
    snapAngle: 15,
  },
  SVGProps: {
    originX: 'center',
    originY: 'center',
    left: WIDTH / 2,
    top: HEIGHT / 2,
    scaleX: 20,
    scaleY: 20,
    snapAngle: 15,
  },
  bulletGroupProps: {
    type: 'group',
    version: '5.3.0',
    originX: 'center',
    originY: 'center',
    width: 40,
    height: 40,
    fill: null,
    stroke: null,
    strokeWidth: 0,
    strokeDashArray: null,
    strokeLineCap: 'butt',
    strokeDashOffset: 0,
    strokeLineJoin: 'miter',
    strokeUniform: false,
    strokeMiterLimit: 4,
    scaleX: 1,
    scaleY: 1,
    angle: 0,
    flipX: false,
    flipY: false,
    opacity: 1,
    shadow: null,
    visible: true,
    backgroundColor: '',
    fillRule: 'nonzero',
    paintFirst: 'fill',
    globalCompositeOperation: 'source-over',
    skewX: 0,
    skewY: 0,
    snapAngle: 15,
  },
  bulletCircleProps: {
    type: 'circle',
    version: '5.3.0',
    originX: 'left',
    originY: 'top',
    left: WIDTH / 2,
    top: HEIGHT / 2,
    radius: 20,
    fill: '#F4EFFE',
    stroke: null,
    strokeWidth: 1,
    strokeDashArray: null,
    strokeLineCap: 'butt',
    strokeDashOffset: 0,
    strokeLineJoin: 'miter',
    strokeUniform: false,
    strokeMiterLimit: 4,
    scaleX: 1,
    scaleY: 1,
    angle: 0,
    flipX: false,
    flipY: false,
    opacity: 1,
    shadow: null,
    visible: true,
    backgroundColor: '',
    fillRule: 'nonzero',
    paintFirst: 'fill',
    globalCompositeOperation: 'source-over',
    skewX: 0,
    skewY: 0,
    rx: 0,
    ry: 0,
    snapAngle: 15,
  },
  bulletNumberProps: {
    type: 'i-text',
    version: '5.3.0',
    originX: 'left',
    originY: 'top',
    top: -11,
    left: -7,
    width: 245.08,
    height: 22.6,
    fill: '#906ef0',
    stroke: null,
    strokeWidth: 1,
    strokeDashArray: null,
    strokeLineCap: 'butt',
    strokeDashOffset: 0,
    strokeLineJoin: 'miter',
    strokeUniform: false,
    strokeMiterLimit: 4,
    scaleX: 1,
    scaleY: 1,
    angle: 0,
    flipX: false,
    flipY: false,
    opacity: 1,
    shadow: null,
    visible: true,
    backgroundColor: '',
    fillRule: 'nonzero',
    paintFirst: 'fill',
    globalCompositeOperation: 'source-over',
    skewX: 0,
    skewY: 0,
    fontFamily: 'Nunito',
    fontWeight: 900,
    fontSize: 20,
    underline: false,
    overline: false,
    linethrough: false,
    textAlign: 'center',
    fontStyle: 'normal',
    lineHeight: 1.16,
    textBackgroundColor: '',
    charSpacing: 0,
    styles: [],
    direction: 'ltr',
    path: null,
    pathStartOffset: 0,
    pathSide: 'left',
    pathAlign: 'baseline',
    snapAngle: 15,
  },
  imagePlaceholderProps: {
    type: 'image',
    version: '5.3.0',
    originX: 'left',
    originY: 'top',
    width: 500,
    height: 1000,
    fill: 'rgb(0,0,0)',
    stroke: null,
    strokeWidth: 0,
    strokeDashArray: null,
    strokeLineCap: 'butt',
    strokeDashOffset: 0,
    strokeLineJoin: 'miter',
    strokeUniform: false,
    strokeMiterLimit: 4,
    scaleX: 1,
    scaleY: 1,
    angle: 0,
    flipX: false,
    flipY: false,
    opacity: 1,
    shadow: null,
    visible: true,
    backgroundColor: '',
    fillRule: 'nonzero',
    paintFirst: 'fill',
    globalCompositeOperation: 'source-over',
    skewX: 0,
    skewY: 0,
    cropX: 0,
    cropY: 0,
    crossOrigin: null,
    filters: [],
    snapAngle: 15,
  },
};

export default DEFAULT_CANVAS_PROPERTIES;
