import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import { getDenormalizedZonePosition } from 'questions/Shared/utils/images';
import Zone from './Zone';
import styles from './ImageContainer.module.scss';

const cx = classNames.bind(styles);

const ImageContainer = ({
  activeZoneIndex,
  className,
  handleImageLoaded,
  handleMouseDown,
  handleMouseMove,
  handleZoneDelete,
  image,
  imageRef,
  imageSrc,
  wrapperRef,
  zones,
}) => (
  <div className={cx('container', className)} ref={wrapperRef}>
    <button
      className={cx('wrapper')}
      onMouseDown={(event) => handleMouseDown(event, -1, 'create')}
      onTouchStart={(event) => handleMouseDown(event, -1, 'create')}
      onMouseMove={handleMouseMove}
      onTouchMove={handleMouseMove}
      type="button"
    >
      <img
        alt="img"
        className={cx('image')}
        draggable="false"
        onLoad={handleImageLoaded}
        ref={imageRef}
        src={imageSrc}
        style={{
          width: image.width,
          height: image.height,
        }}
      />
    </button>
    {zones.map((zone, index) => (
      <Zone
        handleDelete={() => handleZoneDelete(index)}
        handleMouseMove={handleMouseMove}
        handleStartGrab={(event) => handleMouseDown(event, index, 'position')}
        handleStartResize={(event) => handleMouseDown(event, index, 'resize')}
        isActive={activeZoneIndex === index}
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        shapeType={zone.shapeType}
        sizeData={getDenormalizedZonePosition(zone, image.diagonal)}
      />
    ))}
  </div>
);

ImageContainer.propTypes = {
  activeZoneIndex: PropTypes.number,
  className: PropTypes.string,
  handleImageLoaded: PropTypes.func.isRequired,
  handleMouseDown: PropTypes.func.isRequired,
  handleMouseMove: PropTypes.func.isRequired,
  handleZoneDelete: PropTypes.func.isRequired,
  image: PropTypes.object.isRequired,
  imageRef: PropTypes.object.isRequired,
  imageSrc: PropTypes.string.isRequired,
  wrapperRef: PropTypes.object.isRequired,
  zones: PropTypes.arrayOf(PropTypes.object).isRequired,
};

ImageContainer.defaultProps = {
  activeZoneIndex: undefined,
  className: undefined,
};

export default ImageContainer;
