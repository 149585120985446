import { gql, useQuery } from '@apollo/client';

export const QUERY_TEST_STATUS = gql`
  query ($courseId: ID!) {
    testStatus(courseId: $courseId)
  }
`;

export function useQueryTestStatus(options) {
  const { loading, data, ...props } = useQuery(QUERY_TEST_STATUS, {
    ...options,
    fetchPolicy: 'no-cache',
  });
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}
