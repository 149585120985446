import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Chapters from './Chapters';

const ChaptersContainer = ({ chapters, ...props }) => {
  const { t } = useTranslation();
  const [isSectionOpen, SetIsSectionOpen] = useState(false);
  const handleSectionToggle = useCallback(() => {
    SetIsSectionOpen(!isSectionOpen);
  }, [isSectionOpen]);
  const [showMore, setShowMore] = useState(false);
  const handleShowMoreToggle = useCallback(
    () => setShowMore(!showMore),
    [showMore],
  );
  const displayedChapters = chapters.filter((_, i) => i <= 5 || showMore);
  return (
    <Chapters
      {...props}
      chapters={chapters}
      displayedChapters={displayedChapters}
      handleSectionToggle={handleSectionToggle}
      handleShowMoreToggle={handleShowMoreToggle}
      isSectionOpen={isSectionOpen}
      showMore={showMore}
      t={t}
    />
  );
};

ChaptersContainer.propTypes = {
  chapters: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ChaptersContainer;
